import { js as beautifyJs } from "js-beautify";

const INDENT_SIZE = 2;
const INDENT_CHAR = " ";

export const prettifyCode = (code) => {
  try {
    return beautifyJs(code, {
      indent_size: INDENT_SIZE,
      indent_char: INDENT_CHAR,
      eol: "\n",
      indent_level: 0,
      indent_with_tabs: false,
      preserve_newlines: true,
      max_preserve_newlines: 2,
      jslint_happy: false,
      space_after_anon_function: false,
      brace_style: "collapse-preserve-inline", // collapse-preserve-inline | collapse | expand | end-expand | none
      keep_array_indentation: false,
      keep_function_indentation: false,
      space_before_conditional: true,
      break_chained_methods: false,
      eval_code: false,
      unescape_strings: false,
      wrap_line_length: 0,
      wrap_attributes: "auto",
      wrap_attributes_indent_size: 4,
      end_with_newline: false,
    });
  } catch (error) {
    console.error("Error formatting code:", error);
    return code; // Return the original code if there's an error
  }
};

export const indentCode = (
  code: string,
  indent: number,
  options?: { ignoreFirstLine?: boolean }
): string => {
  const indentation = INDENT_CHAR.repeat(indent * INDENT_SIZE); // Create the indentation string
  const lines = code.split("\n"); // Split the code into lines

  return lines
    .map((line, index) => {
      if (options?.ignoreFirstLine && index === 0) {
        return line; // Ignore indenting the first line
      }
      return indentation + line; // Indent the rest of the lines
    })
    .join("\n"); // Join the lines back together
};
