import { EditorType, SceneLayerMeta } from "..";
import { SceneDepthSettings, SceneLayerType } from "game-engine/types";

import { GameObjective } from "game-files/gameObjectives";
import { ImageFileType } from "../components/SectionUploadImages";
import { capitalizeFirstLetter } from "game-engine/utils";

export const getImageSrc = (file) => {
  return file ? URL.createObjectURL(file) : undefined;
};

export const getImageFileByName = (
  imageFiles: ImageFileType[],
  filename: string,
  options?: { avoid?: string }
) => {
  if (options?.avoid?.length) {
    const result = imageFiles
      .filter(
        (fileObj) =>
          !fileObj.file.name
            .toLowerCase()
            .includes(options?.avoid.toLowerCase())
      )
      .find((fileObj) => fileObj.file.name.includes(filename));
    if (result) {
      return result;
    }
  }

  return imageFiles.find((fileObj) => fileObj.file.name.includes(filename));
};

export const getImageSize: (file: any) => Promise<{ width; height }> = (
  file
) => {
  return new Promise((resolve, reject) => {
    let img = new Image();
    img.onload = () => resolve({ width: img.width, height: img.height });
    img.onerror = reject;
    img.src = URL.createObjectURL(file);
  });
};

export const getImportNameFromFilename = (filename) => {
  let name = filename
    .replace("_00.png", "")
    .replace(".png", "")
    .replace(/ - /g, "-")
    .replace(/\s/g, "-")
    .split("-")
    .map((word) => capitalizeFirstLetter(word))
    .join("_");

  return `IMAGE_${name}`;
};

export const getEditorFromImages = (
  imageFiles: ImageFileType[],
  defaultValues: {
    depthSettings?: SceneDepthSettings;
  }
) => {
  const backgroundFilename = "layer - 01 - background";
  const background = getImageFileByName(imageFiles, backgroundFilename, {
    avoid: "sprite",
  });

  const dropMapFilename = "layer - 00 - DROP MAP";
  const dropMap = getImageFileByName(imageFiles, dropMapFilename);

  const walkMapFilename = "layer - 00 - WALK MAP";
  const walkMap = getImageFileByName(imageFiles, walkMapFilename);

  const depthMapFilename = "layer - 00 - DEPTH MAP";
  const depthMap = getImageFileByName(imageFiles, depthMapFilename);

  const previewFilename = "preview";
  const preview = getImageFileByName(imageFiles, previewFilename);

  const layerImageFiles = imageFiles.filter(
    (f) =>
      ![
        background?.file?.name,
        dropMap?.file?.name,
        walkMap?.file?.name,
        depthMap?.file?.name,
        preview?.file?.name,
      ].includes(f.file.name)
  );

  const sceneLayersMeta: SceneLayerMeta[] = [];

  const editor: EditorType = {
    meta: {
      componentName: "Scene_ID",
      previewFileName: preview?.file?.name,
      background: {
        filenames: {
          image: background?.file?.name,
          dropMap: dropMap?.file?.name,
          walkMap: walkMap?.file?.name,
          depthMap: depthMap?.file?.name,
        },
        names: {
          image: getImportNameFromFilename(background?.file?.name),
          dropMap: getImportNameFromFilename(dropMap?.file?.name),
          walkMap: getImportNameFromFilename(walkMap?.file?.name),
          depthMap: getImportNameFromFilename(depthMap?.file?.name),
        },
        dialogActions: {
          sharedDialogOptionIds: [],
          customDialogOptions: [],
        },
      },
      walkPathsBlockedActions: {
        left: {
          sharedDialogOptionIds: [],
          customDialogOptions: [],
        },
        right: {
          sharedDialogOptionIds: [],
          customDialogOptions: [],
        },
        up: {
          sharedDialogOptionIds: [],
          customDialogOptions: [],
        },
        down: {
          sharedDialogOptionIds: [],
          customDialogOptions: [],
        },
      },
    },
    scene: {
      uniqueId: `${Math.random() * 1000}`, // THIS IS USED FOR UPDATES THAT NEED TO COMPLETELY REFRESH THE SCENE (id is taken from meta.componentName in code)
      configId: undefined, // THIS WILL BE FILLED WITH 'COMPONENT NAME' IN EDITOR

      preview: !preview
        ? undefined
        : {
            src: getImageSrc(preview.file),
          },

      sceneNeighbors: undefined,
      sceneWalkPaths: {
        [GameObjective.GAME_START]: {
          left: undefined,
          right: undefined,
          up: undefined,
          down: undefined,
        },
      },

      name: {
        [GameObjective.GAME_START]: {
          en: "",
          cz: "",
        },
      },

      audio: {
        [GameObjective.GAME_START]: {
          music: undefined,
          sound: undefined,
        },
      },

      images: {
        background: {
          dataByCurrentObjective: {
            [GameObjective.GAME_START]: {
              image: !background
                ? undefined
                : {
                    src: getImageSrc(background.file),
                  },
              dropMap: !dropMap
                ? undefined
                : {
                    src: getImageSrc(dropMap.file),
                  },
              walkMap: !walkMap
                ? undefined
                : {
                    src: getImageSrc(walkMap.file),
                  },
              depthMap: !depthMap
                ? undefined
                : {
                    src: getImageSrc(depthMap.file),
                  },
              depthSettings: defaultValues?.depthSettings || {
                scaleMin: undefined,
                scaleMax: undefined,
              },
            },
          },

          actionsByCurrentObjective: undefined,
        },

        layers: layerImageFiles.map((fileObj) => {
          const file = fileObj.file;
          const sceneLayer: SceneLayerType = {
            id: getImportNameFromFilename(file.name),

            depthY: 0,

            dataByCurrentObjective: {
              [GameObjective.GAME_START]: {
                image: {
                  src: getImageSrc(file),
                },
                spriteConfig: undefined,
                offset: { x: 0, y: 0 },
                ignoreOnClick: false,
                isInvisible: false,
                cursorOnHover: undefined,
              },
            },

            actionsByCurrentObjective: undefined,
          };

          const sceneLayerMeta: SceneLayerMeta = {
            sceneLayerId: sceneLayer.id,
            filename: fileObj.file.name,
            isHighlighted: false,
            isInFront:
              file.name.toLowerCase().includes("front") ||
              file.name.toLowerCase().includes("foreground"),
            isInBack: file.name.toLowerCase().includes("background"),
            dialogActions: {
              sharedDialogOptionIds: [],
              customDialogOptions: [],
            },
          };

          sceneLayersMeta.push(sceneLayerMeta);
          return sceneLayer;
        }),
      },

      mainCharacterOptions: {
        isHidden: undefined,
      },

      characters: [],

      onSceneIdle: {
        idleAfterMinSec: undefined,
        idleAfterMaxSec: undefined,
        actionsByCurrentObjective: {
          [GameObjective.GAME_START]: {
            actions: undefined,
          },
        },
      },

      onSceneInit: {
        actionsByCurrentObjective: {
          [GameObjective.GAME_START]: {
            actions: undefined,
          },
        },
      },
    },
    sceneLayersMeta: sceneLayersMeta,
  };

  return editor;
};
