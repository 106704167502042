export type DevToolsConfigType = {
  background: string;
  borderColor: string;
  inputBackground: string;
  inputBackgroundActive: string;
  elemBackground: string;
  elemBackgroundHover: string;
  elemBackgroundError: string;
  jsonBackground: string;
  boxBorderColor: string;
  warning: string;
};

const devToolsConfig: DevToolsConfigType = {
  background: "#24262B",
  borderColor: "#fff2",
  inputBackground: "#15161a88",
  inputBackgroundActive: "#ffffff15",
  elemBackground: "#ffffff08",
  elemBackgroundHover: "#ffffff22",
  elemBackgroundError: "#ff636322",
  jsonBackground: "#ffffff08",
  boxBorderColor: "#000",
  warning: "#f66",
};

export default devToolsConfig;
