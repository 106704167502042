import { ActionId, ActionQueueType, Action_Sequence } from "game-engine/types";

import { createActionName } from ".";
import { getRandomItemFromArray } from "../by-types";

//
// CREATE ACTION WITH NESTED ACTIONS TO BE PLAYED AS A SEQUENCE
//
type ActionSequenceReturn = () => Action_Sequence;

export const sequence: (actions: ActionQueueType) => ActionSequenceReturn =
  (actions) => () => {
    return {
      _name: createActionName({ name: "action sequence" }),
      id: ActionId.sequence,
      actionOrArray: actions,
    };
  };

//
// CREATE ACTION WITH NESTED ACTIONS, ONE OF WHICH WILL BE RANDOMLY PICKED
//
export const random: (actions: ActionQueueType) => ActionSequenceReturn =
  (actions) => () => {
    if (actions?.length) {
      return {
        _name: createActionName({ name: "random action" }),
        id: ActionId.sequence,
        actionOrArray: [getRandomItemFromArray(actions)],
      };
    }
  };
