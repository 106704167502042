import EffectFadeViewport from "./effects/fade-viewport";
import EffectShakeViewport from "./effects/shake-viewport";
import styled from "styled-components";

const Wrapper = styled.div``;

const ViewportEffects = ({ children }) => {
  return (
    <Wrapper>
      <EffectFadeViewport>
        <EffectShakeViewport>{children}</EffectShakeViewport>
      </EffectFadeViewport>
    </Wrapper>
  );
};

export default ViewportEffects;
