import {
  Direction,
  GameItemsType,
  GamePlayCurrentSceneType,
  GamePlayType,
  GameSettingsType,
} from "game-engine/types";
import GAME_ITEMS, { GAME_ITEMS_INIT } from "game-files/gameItems";

import { CinematicSequenceId } from "game-files/common/ids";
import GAMEPLAY from "game-files/gamePlay";
import SCENES_LAYOUTS from "game-files/scenes/SCENE_LAYOUTS";
import { addItemToGameItems } from "../objects";
import { getCinematicSequenceById } from "game-files/cinematics/CINEMATIC_SEQUENCES";

export const getNewGameItems = () => {
  let newGameItems: GameItemsType = {
    ...GAME_ITEMS,
  };
  GAME_ITEMS_INIT.forEach((initItem) => {
    newGameItems = addItemToGameItems({
      gameItemsState: newGameItems,
      item: initItem,
    });
  });

  return newGameItems;
};

export const getNewGamePlay = (options: {
  currentSettings: GameSettingsType;
  skipIntro?: boolean;
}) => {
  const { currentSettings, skipIntro } = options;

  const newGameCinematicSequence = getCinematicSequenceById(
    CinematicSequenceId.NewGame
  );

  const newGameInitCinematicId = newGameCinematicSequence.cinematicIds[0];

  const newGameInitSceneId = SCENES_LAYOUTS.scenes[0].uniqueId; // TODO - SET TO A NEW-GAME INIT SCENE ONCE THE GAME IS READY

  const newGameInitSceneWalkIn = {
    fromDirection: Direction.down,
  };

  const currentScene: GamePlayCurrentSceneType = skipIntro
    ? {
        uniqueSceneId: newGameInitSceneId,
        walkIn: newGameInitSceneWalkIn,
        fadeIn: true,
      }
    : {
        uniqueSceneId: undefined,
        cinematic: {
          id: newGameInitCinematicId,
          onEnd: {
            setCinematicIds: [...newGameCinematicSequence.cinematicIds].slice(
              1
            ),
            pause: newGameCinematicSequence.pauseBetween,
            setSceneId: newGameInitSceneId,
            sceneWalkIn: newGameInitSceneWalkIn,
            sceneFadeIn: true,
          },
        },
        walkIn: undefined,
        isSetByDeveloper: undefined,
      };

  const newGamePlay: GamePlayType = {
    ...GAMEPLAY,
    currentScene,
    hideGui: currentScene.cinematic
      ? {
          isHidden: true,
          durationSec: 0,
        }
      : GAMEPLAY.hideGui,
    settings: {
      ...GAMEPLAY.settings, // keep user settings from previous games
      ...(currentSettings || {}),
    },
  };

  return newGamePlay;
};
