import styled from 'styled-components'

const Label = styled.div`
  color: #fffa;
  font-size: 13px;
  margin-bottom: 4px;
`

const InputLabel = (props: { label?: string }) => {
  //
  // RENDER
  //
  return props.label ? (
    <Label style={{ opacity: props.label?.trim().length ? 1 : 0 }}>
      {props.label}:
    </Label>
  ) : null
}

export default InputLabel
