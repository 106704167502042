/* eslint-disable react-hooks/exhaustive-deps */

import {
  Direction,
  SceneType,
  ScenesLayoutLinkType,
  ScenesLayoutType,
  ScenesLayoutsDefType,
} from "game-engine/types";
import React, { useEffect, useRef, useState } from "react";

import { EditorType } from "../..";
import SceneOverlay from "./SceneOverlay";
import ScenesLayout from "../../../dev-components/ScenesLayout";
import { getRandomId } from "game-engine/utils";
import styled from "styled-components";

export const CONNECTION_LINE_WIDTH = 4;
export const CONNECTION_LINE_LENGTH = 40;

const OverflowWrapper = styled.div`
  position: relative;
  overflow: auto;

  min-width: 100%;
  min-height: 100%;
`;

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 20px;
`;

const LayoutWrapper = styled.div`
  min-width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;

  border-top: 1px solid #fff3;
  padding-bottom: 30px;
`;

const LayoutName = styled.div`
  font-size: 12px;
  color: #fff8;
  padding-top: 20px;
  padding-bottom: 30px;
`;

export type SelectedArea = {
  sceneId: string;
  direction: Direction;
};

export type SceneRef = { sceneId: string; ref: any };

//
// COMPONENT
//
const EditorViewSceneLinks = (props: {
  editor: EditorType;
  setEditor: (e: EditorType) => void;
  updateScenesLayoutsDef: (
    updatedLayoutsDef: ScenesLayoutsDefType,
    options?: { highlightedSceneLinkIds: string[] }
  ) => void;
}) => {
  const { editor, setEditor, updateScenesLayoutsDef } = props;

  //
  // NEIGHBOR AREAS
  //
  const [selectedAreas, setSelectedAreas] = useState<SelectedArea[]>([]);

  useEffect(() => {
    if (editor.highlightedSceneLinkIds?.length) {
      setSelectedAreas([]);
    }
  }, [editor.highlightedSceneLinkIds]);

  const [sceneRefs, setSceneRefs] = useState<SceneRef[]>([]);
  const sceneRefsRef = useRef(sceneRefs);

  const storeSceneRef = (r: SceneRef) => {
    const updatedRefs = [...sceneRefsRef.current, r];
    sceneRefsRef.current = updatedRefs;
    setSceneRefs(updatedRefs);
  };

  /* const getAreaRef = (linkItem: { id: string; direction: Direction }) => {
    return sceneRefs.find((s) => s.sceneId === linkItem.id)?.ref
  } */

  useEffect(() => {
    if (selectedAreas?.length === 2) {
      if (
        selectedAreas[0]?.sceneId === selectedAreas[1]?.sceneId &&
        selectedAreas[0]?.direction === selectedAreas[1]?.direction
      ) {
        // clicked on the same neighbor area that is already selected
        setSelectedAreas([]);
        return;
      }

      // two areas selected => create a new link
      const newSceneLink: ScenesLayoutLinkType = {
        sceneLinkId: `scene-link-${getRandomId()}`,
        scene1: {
          uniqueId: selectedAreas[0].sceneId,
          direction: selectedAreas[0].direction,
        },
        scene2: {
          uniqueId: selectedAreas[1].sceneId,
          direction: selectedAreas[1].direction,
        },
      };

      updateScenesLayoutsDef(
        {
          ...editor.gameScenesData.scenesLayoutsDef,
          sceneLinks: [
            ...editor.gameScenesData.scenesLayoutsDef.sceneLinks,
            newSceneLink,
          ],
        },
        {
          highlightedSceneLinkIds: [newSceneLink.sceneLinkId],
        }
      );

      setSelectedAreas([]);
    }
  }, [selectedAreas]);

  //
  // RENDER SCENES LAYOUT
  //
  const renderScenesLayout = (scenesLayout: ScenesLayoutType) => {
    const renderOverlay = (options: {
      row: number;
      column: number;
      isActive: boolean;
      isHovered: boolean;
      hoverColor: string;
      scene?: SceneType;
    }) => {
      const { scene } = options;

      if (!scene) {
        return null;
      }

      return (
        <SceneOverlay
          editor={editor}
          setEditor={setEditor}
          scene={scene}
          selectedAreas={selectedAreas}
          setSelectedAreas={setSelectedAreas}
          connectionLineLength={CONNECTION_LINE_LENGTH}
          storeSceneRef={storeSceneRef}
          sceneLinks={editor.gameScenesData.scenesLayoutsDef.sceneLinks}
        />
      );
    };

    return (
      <LayoutWrapper>
        <LayoutName>{scenesLayout.id}</LayoutName>
        <ScenesLayout
          scenesLayout={scenesLayout.neighborGrid}
          connectionLineLength={CONNECTION_LINE_LENGTH}
          connectionLineWidth={CONNECTION_LINE_WIDTH}
          sceneIds={editor?.gameScenesData?.sceneIds}
          renderOverlay={renderOverlay}
          itemWidth={editor.sceneItemWidth}
          hover={{
            mode: editor.onSceneClick?.hoverMode,
            color: editor.onSceneClick?.hoverColor,
          }}
          noEventsForNulls
          noScrollOverflow
        />
      </LayoutWrapper>
    );
  };

  //
  // RENDER
  //
  return (
    <OverflowWrapper>
      <Content>
        {editor.gameScenesData.scenesLayouts.map((scenesLayout) => (
          <React.Fragment key={scenesLayout.id}>
            {renderScenesLayout(scenesLayout)}
          </React.Fragment>
        ))}
      </Content>
    </OverflowWrapper>
  );
};

export default EditorViewSceneLinks;
