import { useEffect, useState } from 'react'

const useWindowSize = () => {
  const [size, setSize] = useState({ x: 0, y: 0 })

  useEffect(() => {
    function updateSize() {
      const x =
        document.documentElement.clientWidth || document.body.clientWidth
      const y =
        document.documentElement.clientHeight || document.body.clientHeight
      setSize({ x, y })
    }

    window.addEventListener('resize', updateSize)
    updateSize()

    return () => {
      window.removeEventListener('resize', updateSize)
    }
  }, [])
  return size
}

export default useWindowSize
