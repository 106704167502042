/* eslint-disable react-hooks/exhaustive-deps */

import {
  Direction,
  Position,
  SceneType,
  SceneWalkPathsType,
} from "game-engine/types";
import {
  getAllWalkableSceneWalkPaths,
  getCode_SceneTalkActions,
} from "game-engine/utils";
import { useEffect, useMemo, useState } from "react";

import ActionsTalk from "../scene-editor-create-new/components/ActionsTalk";
import Box from "../basic-components/Box";
import Button from "../basic-components/Button";
import { CanvasClickSettings } from "game-engine/components/game-viewport/GameViewport";
import Code from "../basic-components/Code";
import { DevCursorType } from "game-engine/components/game-elements/Cursor";
import DevSidebarLeft from "game-engine/tabs/TabDeveloper/components/SidebarLeft";
import Divider from "../basic-components/Divider";
import { EditorDialogActions } from "../scene-editor-create-new";
import Footer from "./components/Footer";
import { GameObjective } from "game-files/gameObjectives";
import ScenePreview from "./components/ScenePreview";
import Sidebar from "game-engine/_dev/basic-components/Sidebar";
import styled from "styled-components";
import useGame from "game-engine/hooks/useGame";

const Wrapper = styled.div`
  position: relative;
  flex: 1;
  overflow: hidden;

  display: flex;
  justify-content: stretch;
  flex-wrap: wrap;
`;

const Main = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const ScenePreviewWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #000;
`;

//
// COMPONENT
//
const SceneEditor = (props: {
  sceneConfig: SceneType;
  noDefaultCursor?: boolean;
}) => {
  const { sceneConfig, noDefaultCursor } = props;
  const { gameFns } = useGame();

  const [canvasClickSettings, setCanvasClickSettings] =
    useState<CanvasClickSettings>();

  const [dialogActions, setDialogActions] = useState<EditorDialogActions>({
    sharedDialogOptionIds: [],
    customDialogOptions: [],
  });

  useEffect(() => {
    gameFns.setOverrideLabel({
      label: sceneConfig.name[GameObjective.GAME_START],
      durationSec: "permanent",
    });
  }, []);

  //
  // WALK PREVIEWS
  //
  const sceneWalkPaths: SceneWalkPathsType = useMemo(() => {
    if (!sceneConfig) {
      return undefined;
    }
    return getAllWalkableSceneWalkPaths({
      dataByCurrentObjective: sceneConfig?.sceneWalkPaths,
    });
  }, [sceneConfig?.sceneWalkPaths]);

  const [forceWalkIn, setForceWalkIn] = useState<{
    fromDirection: Direction;
  }>();
  const [forceWalkOut, setForceWalkOut] = useState<{
    walkTo: Position;
    direction: Direction;
  }>();

  useEffect(() => {
    if (forceWalkIn) {
      setForceWalkIn(undefined);
    }
  }, [forceWalkIn]);

  useEffect(() => {
    if (forceWalkOut) {
      setForceWalkOut(undefined);
    }
  }, [forceWalkOut]);

  //
  // RENDER
  //
  return (
    <Wrapper>
      <DevSidebarLeft width="400px" isNotBrowserHeight />

      <Main>
        <ScenePreviewWrapper>
          {sceneConfig && (
            <ScenePreview
              scene={sceneConfig}
              canvasClickSettings={canvasClickSettings}
              forceWalkIn={forceWalkIn}
              forceWalkOut={forceWalkOut}
              noCursor={
                noDefaultCursor ? !canvasClickSettings?.cursor : undefined
              }
            />
          )}
        </ScenePreviewWrapper>

        <Footer />
      </Main>

      <Sidebar name="Scene Editor" position="right" width="500px" isOpen>
        {Object.keys(sceneWalkPaths || {})?.length > 0 ? (
          <Box
            label={"WALK PATHS"}
            isOpen
            childMinWidth="90%"
            contentPaddingBottom="40px"
          >
            {Object.entries(sceneWalkPaths).map(([direction, walkPath]) => (
              <Button
                key={direction}
                onClick={() => {
                  setForceWalkIn({ fromDirection: Direction[direction] });
                }}
              >
                {`Walk-in ${direction}`}
              </Button>
            ))}
          </Box>
        ) : null}

        <Box
          label={"Scene Functions"}
          isOpen
          childMinWidth="90%"
          contentPaddingBottom="40px"
        >
          <Divider name="Copy Coordinates onClick:" />

          <Box renderContentOnly isOpen>
            <Button
              onClick={() => {
                setCanvasClickSettings({
                  cursor: DevCursorType.Coordinates,
                  onClick: ({ scenePosition, canvasPosition }) => {
                    navigator.clipboard.writeText(
                      `{ x: ${scenePosition.x}, y: ${scenePosition.y} }`
                    );
                    setCanvasClickSettings(undefined);
                  },
                });
              }}
            >
              {`{ x, y }`}
            </Button>

            <Button
              onClick={() => {
                setCanvasClickSettings({
                  cursor: DevCursorType.LineVertical,
                  onClick: ({ scenePosition, canvasPosition }) => {
                    navigator.clipboard.writeText(`${scenePosition.x}`);
                    setCanvasClickSettings(undefined);
                  },
                });
              }}
            >
              {`x`}
            </Button>

            <Button
              onClick={() => {
                setCanvasClickSettings({
                  cursor: DevCursorType.LineHorizontal,
                  onClick: ({ scenePosition, canvasPosition }) => {
                    navigator.clipboard.writeText(`${scenePosition.y}`);
                    setCanvasClickSettings(undefined);
                  },
                });
              }}
            >
              {`y`}
            </Button>
          </Box>
        </Box>

        <Box
          label={"Actions Generator"}
          childMinWidth="90%"
          contentPaddingLeft="20px"
          isOpen
        >
          <ActionsTalk
            dialogActions={dialogActions}
            setDialogActions={setDialogActions}
          />

          <Code code={getCode_SceneTalkActions(dialogActions)} />

          <Button
            onClick={() => {
              navigator.clipboard.writeText(
                getCode_SceneTalkActions(dialogActions)
              );
            }}
          >
            Copy actions code
          </Button>
        </Box>
      </Sidebar>
    </Wrapper>
  );
};

export default SceneEditor;
