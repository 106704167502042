import InputBase, { InputBaseError, InputBaseSubmitButton } from '../_InputBase'

import InputLabel from '../_InputLabel'
import styled from 'styled-components'

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

const TextInput = (props: {
  label?: string
  value: string
  prefix?: string
  prefixWidth?: string
  prefixFontSize?: string
  onChange?: (v: string) => void
  onClick?: () => void
  disabled?: boolean
  submitButton?: InputBaseSubmitButton
  errors?: InputBaseError[]
  setErrors?: (e: InputBaseError[]) => void
}) => {
  //
  // RENDER
  //
  return (
    <Wrapper>
      {props.label && <InputLabel label={props.label} />}

      <InputBase
        type="text"
        value={props.value}
        prefix={props.prefix}
        prefixWidth={props.prefixWidth}
        prefixFontSize={props.prefixFontSize}
        onChange={props.onChange as any}
        onClick={props.onClick}
        disabled={props.disabled}
        submitButton={
          props.submitButton ? { label: props.submitButton.label } : undefined
        }
        errors={props.errors}
        setErrors={props.setErrors}
      />
    </Wrapper>
  )
}

export default TextInput
