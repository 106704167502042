import { SpriteEditorType } from "../..";
import styled from "styled-components";

const Wrapper = styled.div`
  width: 90%;
  height: 90%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;

  button {
    align-self: flex-end;
  }
`;

const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  background-color: #fff1;

  image-rendering: optimizeSpeed;
  image-rendering: -moz-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: -o-crisp-edges;
  image-rendering: pixelated;
  -ms-interpolation-mode: nearest-neighbor;
`;

const SpriteImagePreview = (props: { spriteEditor: SpriteEditorType }) => {
  const { spriteEditor } = props;

  return (
    <Wrapper>
      <Image
        src={spriteEditor?.calculated?.spriteUrl}
        alt={spriteEditor.calculated.filename}
      />
    </Wrapper>
  );
};

export default SpriteImagePreview;
