import Box from "../../../../basic-components/Box";
import Button from "game-engine/_dev/basic-components/Button";
import Divider from "game-engine/_dev/basic-components/Divider";
import { SavedDataType } from "game-engine/utils";
import TextInput from "game-engine/_dev/basic-components/inputs/TextInput";
import useGame from "game-engine/hooks/useGame";
import { useState } from "react";

const SectionSaveNew = (props: {
  isOpen?: boolean;
  setSavedData: (s: SavedDataType) => void;
}) => {
  const { gameFns } = useGame();

  const [saveGameName, setSaveGameName] = useState<string>("My saved game");

  const reloadSavedData = () => {
    props.setSavedData(gameFns.getSavedData());
  };

  const newGameConfirm = {
    windowTitle: "Start a New Game?",
    title: "New Game?",
    description: "Starting a new game will lose all unsaved progress.",
    height: "100px",
  };

  //
  // RENDER
  //
  return (
    <Box
      label="Save / New Game"
      childMinWidth="80%"
      contentPaddingBottom="20px"
      isOpen={props.isOpen}
      level={2}
      gap="5px"
    >
      <Button onClick={() => gameFns.newGame()} confirm={newGameConfirm}>
        {"New Game"}
      </Button>

      <Button
        onClick={() => gameFns.newGame({ skipIntro: true })}
        confirm={newGameConfirm}
      >
        {"New Game (Skip Cinematics)"}
      </Button>

      <Divider name="Save Game:" />

      <Box renderContentOnly childMinWidth="80%" isOpen>
        <TextInput value={saveGameName} onChange={setSaveGameName} />

        <Button
          onClick={() => {
            gameFns.saveGame({ name: saveGameName });
            reloadSavedData();
          }}
        >
          {"Save Game"}
        </Button>
      </Box>
    </Box>
  );
};

export default SectionSaveNew;
