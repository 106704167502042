import {
  CinematicScreenSize,
  CinematicScreenType,
  CinematicTypeEnum,
} from "game-engine/types";

import { CinematicId } from "game-files/common/ids";

const TestCinematicScreen02: CinematicScreenType = {
  id: CinematicId.TestCinematicScreen02,
  type: CinematicTypeEnum.screen,
  size: CinematicScreenSize.scene,

  fadeIn: true,
  fadeOut: true,

  sequence: [
    {
      //pauseUntilFinished: true,
      addLayer: {
        text: {
          en: "Text Example 1",
          cz: "Ukázka textu 1",
        },
      },
    },
    {
      pause: {
        durationSec: 1,
      },
    },
    {
      pauseUntilFinished: true,
      addLayer: {
        text: {
          en: "Text Example 2",
          cz: "Ukázka textu 2",
          offsetY: 20,
        },
      },
    },
  ],
};

export default TestCinematicScreen02;
