import { Direction, Position, SceneType } from "game-engine/types";
import GameViewport, {
  CanvasClickSettings,
} from "game-engine/components/game-viewport/GameViewport";
import {
  RunActionsType,
  StopActionsType,
} from "game-engine/components/game-elements/Scene/logic/useSceneActions";
import { useRef, useState } from "react";

import { GUIBlockerProps } from "game-engine/components/game-viewport/GameViewport";
import Scene from "game-engine/components/game-elements/Scene";

const ScenePreview = (props: {
  scene: SceneType;
  canvasClickSettings?: CanvasClickSettings;
  forceWalkIn?: { fromDirection: Direction };
  forceWalkOut?: { walkTo: Position; direction: Direction };
  noCursor?: boolean;
}) => {
  const { scene, canvasClickSettings, forceWalkIn, forceWalkOut, noCursor } =
    props;

  const [guiBlocker, setGuiBlocker] = useState<GUIBlockerProps>(undefined);
  const onGuiBlockerClickRef = useRef<(clickedPosition: Position) => void>();

  const [forceGuiBlocker, setForceGuiBlocker] = useState<boolean>(false);
  const setForceGuiBlockerRef = useRef(setForceGuiBlocker);

  const runActionsRef = useRef<RunActionsType>();
  const stopActionsRef = useRef<StopActionsType>();

  //
  // RENDER
  //
  return (
    <GameViewport
      renderGUI={{ onExitGame: undefined, onNewGame: undefined }}
      noCursor={forceGuiBlocker || noCursor}
      guiBlocker={guiBlocker}
      guiBlockerForced={forceGuiBlocker}
      guiBlockerClickRef={onGuiBlockerClickRef}
      canvasClickSettings={canvasClickSettings}
      runActionsRef={runActionsRef}
      stopActionsRef={stopActionsRef}
    >
      <Scene
        key={scene.uniqueId}
        noAutosave
        ignoreIdle
        scene={scene}
        walkIn={
          !!forceWalkIn
            ? {
                fromDirection: forceWalkIn.fromDirection,
              }
            : undefined
        }
        isVisible={true}
        setForceGuiBlockerRef={setForceGuiBlockerRef}
        setGuiBlocker={setGuiBlocker}
        onGuiBlockerClickRef={onGuiBlockerClickRef}
        runActionsRef={runActionsRef}
        stopActionsRef={stopActionsRef}
        forceWalkIn={!!forceWalkIn}
        forceWalkOut={forceWalkOut}
      />
    </GameViewport>
  );
};

export default ScenePreview;
