import GAME_CONFIG from "game-files/gameConfig";
import ImageInput from "game-engine/_dev/basic-components/inputs/ImageInput";

//
// COMPONENT
//
const LayerImageInput = (props: {
  image: string;
  setImage: (img: string, filename?: string) => void;
  width?: number;
}) => {
  const { image, setImage, width } = props;

  const sceneLayerWidth = width ?? 200;
  const sceneLayerHeight =
    sceneLayerWidth *
    (GAME_CONFIG.scene.dimensions.y / GAME_CONFIG.scene.dimensions.x);

  //
  // RENDER
  //
  return (
    <ImageInput
      width={`${sceneLayerWidth}px`}
      height={`${sceneLayerHeight}px`}
      image={image}
      setImage={setImage}
    />
  );
};

export default LayerImageInput;
