import { Group, Rect } from "react-konva";
import { TextAlign, TranslatedString } from "game-engine/types";

import GAME_CONFIG from "game-files/gameConfig";
import Text from "game-engine/components/basic-elements/Text";

const MenuTitle = (props: {
  x?: number;
  y?: number;
  width: number;
  minHeight?: number;
  text: string | TranslatedString;
  isWarning?: boolean;
  isError?: boolean;
}) => {
  const {
    x = 0,
    y = 0,
    width,
    minHeight = 0,
    text,
    isWarning = false,
    isError = false,
  } = props;

  const colorDefault = GAME_CONFIG.menu.colorTextHighlight;
  const colorWarning = GAME_CONFIG.menu.colorTextWarning;
  const colorError = GAME_CONFIG.menu.colorTextError;

  const color = isError ? colorError : isWarning ? colorWarning : colorDefault;

  return (
    <Group x={x} y={y}>
      <Rect width={0} height={minHeight} fill="#0000" listening={false} />

      <Text
        text={text}
        minWidth={width}
        maxWidth={width}
        align={TextAlign.center}
        color={color}
        outlined={false}
        shadow
      />
    </Group>
  );
};

export default MenuTitle;
