import {
  CinematicScreenSize,
  CinematicScreenType,
  CinematicTypeEnum,
} from "game-engine/types";

import { CinematicId } from "game-files/common/ids";
import GAME_CONFIG from "game-files/gameConfig";

const DeathByDarkness: CinematicScreenType = {
  id: CinematicId.DeathByDarkness,
  type: CinematicTypeEnum.screen,
  size: CinematicScreenSize.scene,

  fadeIn: true,
  fadeOut: true,

  textDefaults: {
    fadeIn: null,
    fadeOut: null,
    maxWidth: GAME_CONFIG.character.dialogConfig.maxWidth,
  },

  sequence: [
    {
      pauseUntilFinished: true,
      addLayer: {
        text: {
          en: "I can't see a thing in here...",
          cz: "V té tmě není nic vidět...",
        },
      },
    },
    {
      pause: {
        durationSec: 1,
      },
    },
    {
      pauseUntilFinished: true,
      addLayer: {
        text: {
          en: "Guess I'll die.",
          cz: "Tak třeba umřu.",
        },
      },
    },
  ],
};

export default DeathByDarkness;
