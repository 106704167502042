import React, { useEffect, useRef } from "react";

import { Group } from "react-konva";

const LayoutColumn = (props: {
  children: React.ReactNode;
  gap?: number;
  x?: number;
  y?: number;
}) => {
  const { children, gap = 0, x = 0, y = 0 } = props;

  const groupRef = useRef<any>(null);

  useEffect(() => {
    const group = groupRef.current;
    if (group) {
      // Use requestAnimationFrame to ensure updates are applied correctly
      requestAnimationFrame(() => {
        const childrenArray = group.getChildren();
        let currentY = 0;

        // Calculate the new position for each child and update it
        childrenArray.forEach((child: any) => {
          const { height } = child.getClientRect();
          child.position({ x: child.x() || 0, y: currentY });
          currentY += height + gap; // Move to the next position with a gap
        });

        // Update the layer to ensure changes are rendered
        group.getLayer()?.batchDraw();
      });
    }
  }, [children, gap]); // Recalculate layout on changes

  return (
    <Group ref={groupRef} x={x} y={y}>
      {React.Children.toArray(children).map((child, i) =>
        child
          ? React.cloneElement(child as React.ReactElement<any>, {
              key: i,
            })
          : null
      )}
    </Group>
  );
};

export default LayoutColumn;
