import { ActionId, Action_Pause } from "game-engine/types";

import { createActionName } from ".";

//
// CREATE BASIC PAUSE ACTION
//
type PauseProps = number | { durationSec: number };

type PauseReturn = () => Action_Pause;

export const pause: (props: PauseProps) => PauseReturn = (props) => () => {
  const durationSec = typeof props === "number" ? props : props.durationSec;

  return {
    _name: createActionName({
      name: `pause`,
      value: `${durationSec}s`,
    }),
    id: ActionId.pause,
    blockGui: true,
    unskippable: true,
    durationSec: durationSec,
  };
};
