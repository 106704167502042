/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from "react";

import { Group } from "react-konva";
import { Position } from "game-engine/types";

export type TransitionPositionAnimation = {
  from: Position;
  to?: Position;
  durationSec?: number;
  onEnd?: () => void;
};

const TransitionPosition = (props: {
  children?: any;
  animatedPosition: TransitionPositionAnimation;
}) => {
  const { animatedPosition } = props;
  const [position, setPosition] = useState<Position>(animatedPosition?.from);

  useEffect(() => {
    if (!animatedPosition) {
      return;
    }

    if (
      animatedPosition.from === animatedPosition.to ||
      animatedPosition.to === undefined
    ) {
      return setPosition({
        x: animatedPosition.from?.x || 0,
        y: animatedPosition.from?.y || 0,
      });
    }

    if (!animatedPosition.durationSec) {
      return setPosition({
        x: animatedPosition.to?.x ?? animatedPosition.from?.x ?? 0,
        y: animatedPosition.to?.x ?? animatedPosition.from?.y ?? 0,
      });
    }

    animatePosition(
      animatedPosition.from,
      animatedPosition.to,
      animatedPosition.durationSec
    );
  }, [animatedPosition]);

  //
  // ANIMATE POSITION
  //
  const animatePosition = (
    _start: Position,
    _end: Position,
    durationSec: number
  ) => {
    const start = {
      x: _start?.x || 0,
      y: _start?.y || 0,
    };
    const end = {
      x: _end?.x || 0,
      y: _end?.y || 0,
    };

    const startTime = performance.now();
    const frameDuration = durationSec * 1000;

    const step = (currentTime: number) => {
      const elapsed = currentTime - startTime;
      const progress = Math.min(elapsed / frameDuration, 1);

      // Calculate the new position based on the progress
      const newPosition = {
        x: start.x + progress * (end.x - start.x),
        y: start.y + progress * (end.y - start.y),
      };

      setPosition(newPosition);

      if (progress < 1) {
        requestAnimationFrame(step);
      }
    };

    // Only start the animation if duration is greater than 0
    if (durationSec > 0) {
      requestAnimationFrame(step);
    } else {
      setPosition(end);
    }
  };

  useEffect(() => {
    if (!animatedPosition) {
      return;
    }
    if (
      (animatedPosition.to
        ? position.x === (animatedPosition.to.x || 0) &&
          position.y === (animatedPosition.to.y || 0)
        : true) &&
      animatedPosition.onEnd
    ) {
      animatedPosition.onEnd();
    }
  }, [position]);

  //
  // RENDER
  //
  return !animatedPosition ? (
    props.children
  ) : (
    <Group position={position}>{props.children}</Group>
  );
};

export default TransitionPosition;
