import { Graph } from 'javascript-astar'
import { Position } from 'game-engine/types'

//
// PRINT GRAPH WITH OPTIONAL PATH TO CONSOLE
//
export const printGraphWithPath = (
  graph: Graph,
  start?: Position,
  end?: Position,
  path?: Position[]
) => {
  const rows = []
  graph.grid.forEach((row, y) => {
    const rowString = row
      .map((node, x) => {
        if (start && start.x === x && start.y === y) {
          return 'S'
        }
        if (end && end.x === x && end.y === y) {
          return 'E'
        }
        if (path && path.find((point) => point.x === x && point.y === y)) {
          return node.weight <= 0 ? 'X' : '°'
        }
        return node.weight <= 0 ? '█' : ' '
      })
      .join('')
    rows.push(`|${rowString}|`)
  })

  const getLine = (isTop?: boolean) =>
    ' ' + (isTop ? '_' : '‾').repeat(rows[0]?.length - 2) + ' '
  const matrixAsString = [
    'MATRIX WITH PATH:',
    getLine(true),
    ...rows,
    getLine(),
    ' ',
  ].join('\n')

  console.info(matrixAsString)
}

//
// PRINT MATRIX WITH OPTIONAL PATH TO CONSOLE
//
export const printMatrix = (
  matrix: number[][],
  props?: { path?: { start: Position; end: Position; points: Position[] } }
) => {
  const graph = new Graph(matrix)
  if (props?.path) {
    const { start, end, points } = props.path
    printGraphWithPath(graph, start, end, points)
  }
  printGraphWithPath(graph)
}

//
// CREATE EMPTY MATRIX
//
export const createMatrix = (props: {
  width: number
  height: number
  value?: number
}) => {
  const row = Array(props.width).fill(props.value || 0)
  const matrix = Array(props.height).fill(row)
  return matrix
}
