import MenuSavedGames, {
  MenuSavedGamesEnum,
} from "../menu-templates/MenuSavedGames";

import { MenuProps } from "..";

const SaveMenu = (props: MenuProps) => {
  return <MenuSavedGames mode={MenuSavedGamesEnum.saveGame} {...props} />;
};

export default SaveMenu;
