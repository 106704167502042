import { ActionId, Action_Effect } from "game-engine/types";

import { createActionName } from ".";

type SetEffectShakeViewportProps = {
  durationSec: number;
  shakeOffset?: number;
  shakeSpeed?: number;
};

type SetEffectShakeViewportReturn = () => Action_Effect;

export const viewportShake: (
  props: SetEffectShakeViewportProps
) => SetEffectShakeViewportReturn = (effectShakeViewport) => () => {
  return {
    _name: createActionName({ name: "shake viewport" }),
    id: ActionId.effect,
    blockGui: true,
    unskippable: true,

    durationSec: effectShakeViewport?.durationSec,
    effectShakeViewport: {
      ...effectShakeViewport,
      isActive: true,
    },
  };
};
