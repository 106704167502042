import AssetBox from "game-engine/_dev/basic-components/Box/AssetBox";
import Box from "../Box";
import Button from "../Button";
import { CharacterDevToolsPreviewType } from "game-engine/types";
import CharacterRenderAssets from "./CharacterRenderAssets";
import InfoTable from "../InfoTable";
import JsonView from "../JsonView";
import { URL_PARAM_CHARACTER_CONFIG_ID } from "game-engine/tabs/TabAssets";
import { stringifyTranslations } from "game-engine/utils";
import styled from "styled-components";

const BoxLabel = styled.div`
  flex: 1;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Name = styled.div``;

const CharacterBox = (props: {
  characterDevPreview: CharacterDevToolsPreviewType;
  thumbnailSettings?: {
    width: number;
    height: number;
  };
  previewSettings?: {
    width: number;
    height: number;
    scale: number;
  };
  isOpen?: boolean;
  isDetailPage?: boolean;
}) => {
  const {
    characterDevPreview,
    thumbnailSettings,
    previewSettings,
    isDetailPage,
  } = props;

  const { width: thumbnailWidth, height: thumbnailHeight } =
    thumbnailSettings || {
      width: 70,
      height: 70,
    };

  const renderBoxLabel = () => {
    const name = stringifyTranslations(characterDevPreview.config.name);

    if (!isDetailPage) {
      return (
        <BoxLabel>
          <Name>{name}</Name>

          <Button
            onClick={() => {
              window.open(
                `${window.location.pathname}?tab=assets&${URL_PARAM_CHARACTER_CONFIG_ID}=${characterDevPreview.config.id}`,
                "_blank"
              );
            }}
          >
            Open in a new tab
          </Button>
        </BoxLabel>
      );
    }

    return name;
  };

  //
  // RENDER
  //
  return (
    <AssetBox
      isOpen={props.isOpen}
      label={renderBoxLabel()}
      thumbnail={{
        src: characterDevPreview.renderAssets[0].render.default.image.src,
        imgFillPercentage: "90%",
        width: thumbnailWidth,
        height: thumbnailHeight,
      }}
      level={2}
      useFlex
      childMinWidth="90%"
    >
      {/* SCENE DATA */}
      {characterDevPreview.sceneCharacter && (
        <Box
          renderContentOnly
          isOpen
          contentPaddingTop="20px"
          contentPaddingBottom="15px"
        >
          <InfoTable
            data={[
              {
                label: "position",
                value: `[${characterDevPreview.sceneCharacter.position.x}, ${characterDevPreview.sceneCharacter.position.y}]`,
              },
              {
                label: "scale",
                value: characterDevPreview.sceneCharacter.scale || 1,
              },
              {
                label: "facing",
                value: characterDevPreview.sceneCharacter.facing,
              },
            ]}
          />
        </Box>
      )}

      {/* PREVIEW */}
      <Box renderContentOnly childMinWidth="90%">
        {characterDevPreview.renderAssets.map((characterRender, i) => {
          return (
            <CharacterRenderAssets
              key={i}
              isOpen={!characterDevPreview.sceneCharacter}
              characterConfig={characterDevPreview.config}
              characterRender={characterRender}
              previewSettings={previewSettings}
            />
          );
        })}
      </Box>

      {/* CONFIG */}
      <Box label="Config" isOpen={isDetailPage} level={3}>
        <JsonView
          json={characterDevPreview.config}
          autoExpandToLevel={2}
          hideRoot
        />
      </Box>
    </AssetBox>
  );
};

export default CharacterBox;
