/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useRef, useState } from "react";

import { Group } from "react-konva";
import { SceneOverlayAnimationType } from ".";
import SpriteAnimated from "game-engine/components/basic-elements/SpriteAnimated";
import Transition from "game-engine/components/basic-elements/Transition";
import { TransitionFadeAnimation } from "game-engine/components/basic-elements/Transition/types/fade";
import useGame from "game-engine/hooks/useGame";

const SceneOverlayAnimation = (props: {
  overlayAnimation: SceneOverlayAnimationType;
}) => {
  const { overlayAnimation } = props;

  const { gameFns } = useGame();

  const [isLoaded, setIsLoaded] = useState(false);

  //
  // PAUSE AND FADE-OUT
  //
  const [fade, setFade] = useState<TransitionFadeAnimation>({ from: 1 });

  const pauseTimerRef = useRef<ReturnType<typeof setTimeout>>();
  useEffect(() => {
    return () => clearTimeout(pauseTimerRef.current);
  }, []);

  const onSpriteAnimationEnd = () => {
    clearTimeout(pauseTimerRef.current);
    if (overlayAnimation) {
      pauseTimerRef.current = setTimeout(() => {
        setFade({
          from: 1,
          to: 0,
          durationSec:
            overlayAnimation.sceneOverlayOptions.fadeOut?.durationSec,
          onEnd: onFadeOutEnd,
        });
      }, overlayAnimation.sceneOverlayOptions?.pause?.durationSec * 1000);
    }
  };

  const onFadeOutEnd = () => {
    gameFns.setSceneOverlayAnimation(undefined);
  };

  //
  // RENDER
  //
  return overlayAnimation ? (
    <Transition fade={fade}>
      <Group listening={false} opacity={!isLoaded ? 0 : 1}>
        <SpriteAnimated
          src={overlayAnimation?.animationConfig?.sprite?.src}
          spriteConfig={{
            ...overlayAnimation?.animationConfig?.spriteConfig,
            playCount: 1,
          }}
          controlledAnimation={{
            playAnimation: isLoaded,
          }}
          blendingMode={overlayAnimation?.animationConfig?.blendingMode}
          onImageLoaded={() => setIsLoaded(true)}
          onAnimationEnd={onSpriteAnimationEnd}
        />
      </Group>
    </Transition>
  ) : null;
};

export default SceneOverlayAnimation;
