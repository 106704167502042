import { ActionId, Action_Audio, GamePlayAudioType } from "game-engine/types";

import { createActionName } from ".";

//
// CREATE SET BACKGROUND MUSIC ACTION
//
type SetAudioProps = GamePlayAudioType & {
  blockGui?: true;
};

type SetAudioReturn = () => Action_Audio;

export const setAudio: (props: SetAudioProps) => SetAudioReturn =
  (props) => () => {
    return {
      ...props,
      _name: createActionName({ name: "set audio" }),
      id: ActionId.audio,
      blockGui: props.blockGui ?? true,
      unskippable: props.blockGui ?? true,
    };
  };
