import MenuDialogConfirm from "../menu-templates/MenuDialogConfirm";
import { MenuProps } from "..";

const SavedGamesImportError = (props: MenuProps) => {
  //
  // RENDER
  //
  return (
    <MenuDialogConfirm
      {...props}
      height={62}
      title={{
        en: "Importing saved games from file FAILED.",
        cz: "Při nahrávání ze souboru došlo k CHYBĚ.",
      }}
      titlePaddingX={10}
      titleIsError
      submitHidden
      cancelLabel={{ en: "Back", cz: "Zpět" }}
    />
  );
};

export default SavedGamesImportError;
