export type EngineConfigType = {
  loggerErrors: boolean;
  loggerErrorsColor: string;

  loggerInfo: boolean;
  loggerInfoColor: string;

  loggerGraphics: boolean;
  loggerGraphicsColor: string;

  loggerActions: boolean;
  loggerActionsColor: string;

  loggerAudio: boolean;
  loggerAudioColor: string;

  renderCharacterOrigin: boolean;
  renderCharacterDepthLine: boolean;
  renderCharacterOutline: boolean;
  renderCharacterFill: boolean;
  characterOriginColor: string;
  characterDepthLineColor: string;
  characterOutlineColor: string;
  characterFillColor: string;

  renderCharacterDialogLong: boolean;
  renderCharacterDialogShort: boolean;
  renderCharacterDialogLimits: boolean;
  characterDialogLimitIdealColor: string;
  characterDialogLimitMinColor: string;

  renderItemOrigin: boolean;
  renderItemDepthLine: boolean;
  renderItemOutline: boolean;
  renderItemFill: boolean;
  itemOriginColor: string;
  itemDepthLineColor: string;
  itemOutlineColor: string;
  itemFillColor: string;

  renderTextOrigin: boolean;
  renderTextOutline: boolean;
  renderTextFill: boolean;
  textOriginColor: string;
  textOutlineColor: string;
  textFillColor: string;

  renderCanvasBackground: boolean; // change canvas color to reveal missing textures
  hideSceneBackground: boolean;
  hideSceneLayers: boolean;
  hideSceneCharacters: boolean;
  hideSceneItems: boolean;

  renderSceneWalkOutAreas: boolean;
  sceneWalkOutAreasColor: string;
  sceneWalkOutAreasBlockedColor: string;
  renderSceneWalkOutPositions: boolean;
  sceneWalkOutPositionsColor: string;

  renderSceneLayerDepthLine: boolean;
  renderSceneLayerOutline: boolean;
  renderSceneLayerFill: boolean;
  sceneLayerDepthLineColor: string;

  renderWalkMap: boolean;
  ignoreWalkMap: boolean;

  renderWalkPath: boolean;
  noDiagonalPaths: boolean;
  walkPathColor: string;

  renderDepthMap: boolean;
  ignoreDepthMap: boolean;

  renderDropMap: boolean;
  ignoreDropMap: boolean;

  renderBrowserCursor: boolean;
  renderSceneCoordinatesCursor: boolean;
  copyScenePositionOnClick: boolean;
  visibleGuiBlocker: boolean;
};

const configDefault: EngineConfigType = {
  loggerErrors: true,
  loggerErrorsColor: "#f004",

  loggerInfo: false,
  loggerInfoColor: "#0f02",

  loggerGraphics: false,
  loggerGraphicsColor: "#a8753222",

  loggerActions: false,
  loggerActionsColor: "#00f2",

  loggerAudio: false,
  loggerAudioColor: "#8932a822",

  renderCharacterOrigin: false,
  characterOriginColor: "#0f0",

  renderCharacterDepthLine: false,
  characterDepthLineColor: "#0f0",

  renderCharacterOutline: false,
  characterOutlineColor: "#0f0",

  renderCharacterFill: false,
  characterFillColor: "#0f05",

  renderCharacterDialogLong: false,
  renderCharacterDialogShort: false,
  renderCharacterDialogLimits: false,
  characterDialogLimitIdealColor: "#0f0",
  characterDialogLimitMinColor: "#f00",

  renderItemOrigin: false,
  itemOriginColor: "#00f",

  renderItemDepthLine: false,
  itemDepthLineColor: "#00f",

  renderItemOutline: false,
  itemOutlineColor: "#00f",

  renderItemFill: false,
  itemFillColor: "#00f5",

  renderTextOrigin: false,
  textOriginColor: "#f00",

  renderTextOutline: false,
  textOutlineColor: "#f00",

  renderTextFill: false,
  textFillColor: "#f005",

  renderCanvasBackground: false,
  hideSceneBackground: false,
  hideSceneLayers: false,
  hideSceneCharacters: false,
  hideSceneItems: false,

  renderSceneWalkOutAreas: false,
  sceneWalkOutAreasColor: "#0f05",
  sceneWalkOutAreasBlockedColor: "#f005",

  renderSceneWalkOutPositions: false,
  sceneWalkOutPositionsColor: "#0f0",

  renderSceneLayerFill: false,
  renderSceneLayerOutline: false,
  renderSceneLayerDepthLine: false,
  sceneLayerDepthLineColor: "#00f",

  renderWalkMap: false,
  ignoreWalkMap: false,

  renderWalkPath: false,
  walkPathColor: "#f00",
  noDiagonalPaths: true,

  renderDepthMap: false,
  ignoreDepthMap: false,

  renderDropMap: false,
  ignoreDropMap: false,

  renderBrowserCursor: false,
  renderSceneCoordinatesCursor: false,
  copyScenePositionOnClick: false,
  visibleGuiBlocker: false,
};

export const engineConfig_Player: EngineConfigType = {
  ...configDefault,
};

export const engineConfig_Developer: EngineConfigType = {
  ...configDefault,
  loggerErrors: true,
  loggerInfo: true,
  loggerGraphics: true,
  loggerActions: true,
  loggerAudio: true,
  renderCharacterOrigin: true,
  renderCharacterDepthLine: true,
  renderCharacterOutline: true,
  renderCharacterFill: true,
  renderTextOrigin: true,
  renderTextFill: true,
  renderSceneLayerDepthLine: true,
  renderSceneLayerOutline: true,
  renderSceneLayerFill: true,
  renderWalkMap: true,
  renderWalkPath: true,
  renderBrowserCursor: true,
  visibleGuiBlocker: true,
  renderItemOutline: true,
  renderItemFill: true,
  renderSceneWalkOutAreas: true,
};

export const engineConfig_Dev_DepthMap: EngineConfigType = {
  ...configDefault,
  hideSceneBackground: true,
  hideSceneLayers: true,
  ignoreWalkMap: true,
  renderDepthMap: true,
};

export const engineConfig_Dev_WalkMap: EngineConfigType = {
  ...configDefault,
  hideSceneBackground: true,
  hideSceneLayers: true,
  renderWalkMap: true,
  renderWalkPath: true,
  ignoreDepthMap: true,
};
