/* eslint-disable react-hooks/exhaustive-deps */

import {
  ACTION,
  getDirection,
  getLineWalkPath,
  getOppositeDirection,
  getSceneWalkInPath,
  getSceneWalkInPosition,
  isBlockedWalkPath,
  isFunction,
  isNumber,
  storeActionInDevTools,
} from "game-engine/utils";
import {
  ActionAudioTypeEnum,
  ActionFacingProps,
  ActionId,
  ActionQueueType,
  ActionType,
  Action_Audio,
  Action_CharacterAnimation,
  Action_CharacterDeath,
  Action_CharacterFace,
  Action_CharacterTalk,
  Action_CharacterWalk,
  Action_Cinematic,
  Action_Effect,
  Action_Event,
  Action_Execute,
  Action_GuiCustomPopUp,
  Action_ItemInCursorSwapConfig,
  Action_ItemInCursorToSceneOverlay,
  Action_ItemInCursorToTrash,
  Action_Objective,
  Action_Pause,
  Action_PutNewItemIntoCursor,
  Action_SaveGame,
  Action_SaveGameTypeEnum,
  Action_Scene,
  Action_SceneOverlayAnimation,
  Action_Skill,
  Action_Sound,
  CharacterPathPoint,
  CharacterRenderMode,
  CharacterWalkPath,
  CinematicOnEnd,
  Direction,
  ExtendWalkPathType,
  GamePlayCurrentSceneType,
  Position,
  SceneCharacterDataType,
  SceneType,
  SceneWalkPathAnyType,
  SceneWalkPathHorizontalType,
  SceneWalkPathVerticalType,
  SceneWalkPathWalkableType,
  SceneWalkPathsType,
  SkillActionTypeEnum,
  WalkConfigType,
} from "game-engine/types";
import { useContext, useEffect, useRef, useState } from "react";

import { CharacterId } from "game-files/common/ids";
import { DevToolsContext } from "game-engine";
import GAME_CONFIG from "game-files/gameConfig";
import { GUIBlockerProps } from "game-engine/components/game-viewport/GameViewport";
import Konva from "konva";
import { generateItem } from "game-engine/utils/objects/item";
import { getCharacterRenderByCurrentObjective } from "game-engine/utils/get-by-current-objective";
import { getItemConfigById } from "game-files/items/ITEM_CONFIGS";
import useGame from "game-engine/hooks/useGame";

export type ActionQueueMetaData = {
  clickedPosition?: Position;
  clickedDepthY?: number;
  isIdle?: boolean;
  prependToCurrentActionQueue?: boolean; // allows adding new actions to action queue without overriding it
  appendToCurrentActionQueue?: boolean; // allows adding new actions to action queue without overriding it
};

export type RunActionsType = (
  actionOrArray: ActionQueueType,
  actionQueueMetaData?: ActionQueueMetaData
) => void;

export type StopActionsType = () => void;

const useSceneActions = (props: {
  scene: SceneType;
  sceneRef: { current: Konva.Group };
  sceneWalkPaths: SceneWalkPathsType;
  isVisible: boolean;
  mainCharacter: SceneCharacterDataType;
  setMainCharacter: (c: SceneCharacterDataType) => void;
  getSceneCharacter: (characterId: CharacterId) => SceneCharacterDataType;
  setSceneCharacter: (c: SceneCharacterDataType) => void;
  getWalkPath: (data: {
    from: Position;
    to: Position;
    walkConfig: WalkConfigType;
    extendPath?: ExtendWalkPathType;
    ignoreWalkMap?: boolean;
  }) => CharacterWalkPath;
  getPixelDepth: (p: Position) => number;
  setGuiBlocker?: (data: GUIBlockerProps) => void;
  onGuiBlockerClickRef?: any;
}) => {
  const {
    /* scene, */
    sceneRef,
    sceneWalkPaths,
    isVisible,
    mainCharacter,
    setMainCharacter,
    getSceneCharacter,
    setSceneCharacter,
    getWalkPath,
    getPixelDepth,
    onGuiBlockerClickRef,
  } = props;

  const { engineConfig, gamePlay, gameItems, logger, gameFns } = useGame();

  const devTools = useContext(DevToolsContext);

  const { millisecondsBase, millisecondsPerCharInText } =
    GAME_CONFIG.actions.talkSpeed;

  const [actionQueue, setActionQueueRaw] = useState<ActionType[]>([]);
  const [currentAction, setCurrentAction] = useState<ActionType>();

  const actionQueueMetaDataRef = useRef<ActionQueueMetaData>();

  const [sceneAnimation, setSceneAnimation] = useState<Konva.Animation>();

  const isVisibleRef = useRef(isVisible);
  useEffect(() => {
    isVisibleRef.current = isVisible;
  }, [isVisible]);

  const [timer, setTimer] = useState<number>(undefined); // time in milliseconds since each action start
  const timerRef = useRef(timer);
  const timeOffsetRef = useRef(0);

  const [actionTimer, setActionTimer] = useState<number>(undefined);
  const setTimerRef = useRef(setTimer);

  useEffect(() => {
    return () => {
      clearTimeout(timer);
      clearTimeout(timerRef.current);
    };
  }, []);

  const setGuiBlocker = (data: GUIBlockerProps) => {
    if (props.setGuiBlocker) {
      props.setGuiBlocker(data);
    }
  };

  const setActionQueue = (aq: ActionType[]) => {
    setActionQueueRaw(aq);
    storeDevToolsActionQueue(aq);
  };

  const clearActionQueue = () => {
    setActionQueue([]);
    actionQueueMetaDataRef.current = undefined;
  };

  //
  // DATA REFS
  //
  const gamePlayRef = useRef(gamePlay.state);
  useEffect(() => {
    gamePlayRef.current = gamePlay.state;
  }, [gamePlay.state]);

  const gameItemsRef = useRef(gameItems.state);
  useEffect(() => {
    gameItemsRef.current = gameItems.state;
  }, [gameItems.state]);

  //
  // RUN ACTIONS FROM GAMEPLAY
  //
  const runActions: RunActionsType = (
    actionOrArray: ActionQueueType,
    actionQueueMetaData?: ActionQueueMetaData
  ) => {
    // Parse action queue into a flat array of actions
    const newActionQueue = parseActionQueue(actionOrArray);

    // Set optional meta data (position clicked in the scene to trigger the actions etc.)
    actionQueueMetaDataRef.current = actionQueueMetaData;

    // Get characters used in action queue and turn off their idle animations
    const characterIdsForIdleIgnore = [CharacterId.MainCharacter];
    newActionQueue.forEach((action) => {
      const actionCharacterId = (action as any)?.characterId;
      if (
        actionCharacterId &&
        !characterIdsForIdleIgnore?.includes(actionCharacterId)
      ) {
        characterIdsForIdleIgnore.push(actionCharacterId);
      }
    });

    gameFns.setCharacterIdsForIdleIgnore(characterIdsForIdleIgnore);

    // Set action queue
    if (actionQueueMetaData?.appendToCurrentActionQueue) {
      setActionQueue([...(actionQueue || []), ...(newActionQueue || [])]);
    } else if (actionQueueMetaData?.prependToCurrentActionQueue) {
      setActionQueue([...(newActionQueue || []), ...(actionQueue || [])]);
    } else {
      setActionQueue(newActionQueue);
    }

    logger.action(
      `action queue set (${newActionQueue.length} actions)`,
      newActionQueue
    );
  };

  const stopActions: StopActionsType = () => {
    logger.action(
      `action queue emptied (${actionQueue.length} actions)`,
      actionQueue
    );
    clearActionQueue();
  };

  //
  // PARSE ACTIONS TO QUEUE
  //
  const parseActionQueue: (actionOrArray: ActionQueueType) => ActionType[] = (
    actionOrArray
  ) => {
    // accepts mix of action and action[] creators (TO ENSURE THAT ACTION CREATION IS RUN HERE, ACTIONS ARE GIVEN AS CONSTRUCTOR FUNCTIONS)
    // => process nested arrays to flatten the queue
    // (nested array is for conversation actions and similar, e.g. [walk, [talk, talk], talk] => [walk, talk, talk, talk]
    const newActionQueue: ActionType[] = [];

    // create new action queue
    actionOrArray?.forEach((constructorOrArray) => {
      if (!isFunction(constructorOrArray)) {
        return;
      }

      // run the func to retrieve final action object or array
      const actionOrArray: ActionType | ActionType[] = constructorOrArray();
      const array: ActionType[] = Array.isArray(actionOrArray)
        ? actionOrArray
        : [actionOrArray];

      // pre-process actions (unpack actions, calculate walkPath, ...)
      array.forEach((action) => {
        switch (action.id) {
          case ActionId.sequence:
            // unpack actions (for random, action is randomly picked in action constructor and returned as an array)
            newActionQueue.push(...parseActionQueue(action.actionOrArray));
            break;

          case ActionId.conditional:
            // unpack actions with satisfied conditions
            const options = action.getConditionalActions({
              gamePlay: gamePlayRef.current,
              gameItems: gameItemsRef.current,
            });

            const conditionalActions = options
              .filter((opt) => opt.condition)
              .map((opt) => opt.actions)
              .flat();

            newActionQueue.push(...parseActionQueue(conditionalActions));
            break;

          case ActionId.characterWalk:
            if (!action.walkPath) {
              const character = getCharacterById(action.characterId).character;

              const characterRender = getCharacterRenderByCurrentObjective({
                dataByCurrentObjective: character?.config?.render,
                currentObjective: gameFns.getCurrentObjective(),
                events: gameFns.getEvents(),
              });

              const walkConfig: WalkConfigType = {
                horizontal: {
                  frameCount:
                    characterRender?.walkLeft?.spriteConfig?.frameCount,
                  pixelsWalked: characterRender?.walkLeft?.pixelsWalked,
                },
                vertical: {
                  frameCount:
                    characterRender?.walkDown?.spriteConfig?.frameCount,
                  pixelsWalked: characterRender?.walkDown?.pixelsWalked,
                },
              };

              // calculate walkPath
              if (action.walkToPosition) {
                newActionQueue.push({
                  ...action,
                  walkPath: getWalkPath({
                    from: action.walkFromPosition || character?.position,
                    to: action.walkToPosition,
                    walkConfig,
                    extendPath: action.extendPath,
                    ignoreWalkMap: action.ignoreWalkMap,
                  }),
                });
              }

              // calculate direct lineWalkPath
              else if (action.walkInDirection) {
                newActionQueue.push({
                  ...action,
                  walkPath: getLineWalkPath({
                    walkConfig,
                    startPosition: action.walkInDirectionFrom, // either start
                    endPosition: action.walkInDirectionTo, //         or end position is used, not both
                    direction: action.walkInDirection,
                    distance: action.walkInDirectionDistance,
                    engineConfig: engineConfig.state,
                    getPixelDepth,
                  }),
                });
              }

              // calculate walk into scene path
              else if (action.walkIntoSceneFromDirection) {
                const walkPath = getSceneWalkInPath({
                  characterRender,
                  sceneWalkPaths,
                  engineConfig: engineConfig.state,
                  getPixelDepth,
                  getWalkPath,
                  fromDirection: action.walkIntoSceneFromDirection,
                });

                const sceneEntryPosition: CharacterPathPoint =
                  walkPath[walkPath.length - 1];

                if (action.walkIntoSceneToPosition && sceneEntryPosition) {
                  const walkInsideScenePath = getWalkPath({
                    from: sceneEntryPosition.position,
                    to: action.walkIntoSceneToPosition,
                    walkConfig,
                    extendPath: action.extendPath,
                    ignoreWalkMap: action.ignoreWalkMap,
                  });

                  if (walkInsideScenePath?.length > 1) {
                    walkPath.push(...walkInsideScenePath.slice(1));
                  }
                }

                newActionQueue.push({
                  ...action,
                  walkPath,
                });
              }

              // calculate walk out of scene path
              else if (action.walkOutOfSceneToDirection) {
                const toDirection = action.walkOutOfSceneToDirection;
                const fromDirection = getOppositeDirection(toDirection);

                let walkPathData: SceneWalkPathAnyType = (sceneWalkPaths || {})[
                  toDirection
                ];

                if (!isBlockedWalkPath(walkPathData)) {
                  walkPathData = walkPathData as
                    | SceneWalkPathHorizontalType
                    | SceneWalkPathVerticalType;

                  // step 0: get walkPath data
                  const walkTo = (walkPathData as SceneWalkPathWalkableType)
                    .walkTo;

                  // step 1: walkPath from current position to walkPath entry position
                  const walkInScenePath = getWalkPath({
                    from: action.walkFromPosition || character?.position,
                    to: walkTo,
                    walkConfig,
                    extendPath: action.extendPath,
                    ignoreWalkMap: action.ignoreWalkMap,
                  });

                  // step 2: walkPath from entry position to the edge of the scene
                  const sceneEdgePosition = getSceneWalkInPosition({
                    fromDirection: fromDirection,
                    walkInData: walkPathData,
                  });

                  const walkToEdgePath = getWalkPath({
                    from: walkTo,
                    to: sceneEdgePosition,
                    walkConfig,
                    extendPath: action.extendPath,
                    ignoreWalkMap: action.ignoreWalkMap,
                  });

                  // step 3: walkPath in direct line out of the scene
                  const walkOutOfScenePath = getLineWalkPath({
                    walkConfig,
                    startPosition: sceneEdgePosition,
                    direction: toDirection,
                    distance:
                      toDirection === Direction.down
                        ? 34 // TODO - PERHAPS GET THE DISTANCE FROM CHARACTER IMAGE DIMENSIONS TO BE MORE PRECISE (+ include scale)
                        : 20, // TODO - THIS IS IN MULTIPLE PLACES, MOVE TO CONFIG AND GET IT LIKE CONFIG.sceneWalkOutDistance[direction]
                    engineConfig: engineConfig.state,
                    getPixelDepth,
                  });

                  // step 4: merge the paths / take into account blockGui for walkout
                  if (!action.blockGui && action.walkOutOfSceneWithBlockedGui) {
                    // allow clicking until character is out of bounds
                    newActionQueue.push({
                      ...action,
                      walkPath: walkInScenePath,
                    });

                    if (action.walkOutOfSceneWithBlockedGuiInitiatedRef) {
                      newActionQueue.push(
                        ACTION.execute({
                          funcName: "unskippable scene walk out initiated",
                          func: () => {
                            action.walkOutOfSceneWithBlockedGuiInitiatedRef.current =
                              true;
                          },
                        })()
                      );
                    }

                    // block clicking for scene walk out
                    newActionQueue.push({
                      ...action,
                      blockGui: true,
                      unskippable: true,
                      walkPath: [
                        ...walkToEdgePath.slice(1), // first point is equal to previous one
                        ...walkOutOfScenePath.slice(1), // first point is equal to previous one
                      ],
                    });
                  } else {
                    newActionQueue.push({
                      ...action,
                      walkPath: [
                        ...walkInScenePath,
                        ...walkToEdgePath.slice(1), // first point is equal to previous one
                        ...walkOutOfScenePath.slice(1), // first point is equal to previous one
                      ],
                    });
                  }
                }
              }
            }

            // otherwise store walk action
            else {
              newActionQueue.push(action);
            }
            break;

          default:
            newActionQueue.push(action);
        }
      });
    });

    return newActionQueue;
  };

  //
  // GUI BLOCKER
  //
  if (onGuiBlockerClickRef) {
    onGuiBlockerClickRef.current = () => {
      // end action (skip to next action) on gui blocker click
      actionCallback();
    };
  }

  //
  // KONVA ANIMATION (FOR TIMING)
  //
  useEffect(() => {
    if (isVisible && sceneRef?.current) {
      const node: Konva.Node = sceneRef?.current;
      if (node && !sceneAnimation) {
        setSceneAnimation(
          new Konva.Animation(
            (frame) => {
              if (isVisibleRef.current) {
                setTimerRef.current(frame.time - timeOffsetRef.current);
              }
            },
            [node.getLayer()]
          )
        );
      }
    } else if (sceneAnimation) {
      sceneAnimation.stop();
    }
  }, [sceneRef, isVisible]);

  useEffect(() => {
    return () => {
      if (sceneAnimation) {
        sceneAnimation.stop();
      }
    };
  }, []);

  //
  // TIMER
  //
  useEffect(() => {
    timerRef.current = timer;
    if (timer && currentAction) {
      setActionTimer(getActionTime(timer));
    }
  }, [timer]);

  //
  // ACTION QUEUE MANAGEMENT
  //
  useEffect(() => {
    if (actionQueue.length > 0) {
      onActionStart();
    } else {
      onActionQueueEmpty();
    }
  }, [actionQueue]);

  const actionCallback = () => {
    onActionEnd();
  };

  const onActionStart = () => {
    actionCleanup();
    const action = actionQueue[0];
    setGuiBlocker({
      blockGui: action.blockGui,
      unskippable: action.unskippable,
    });
    setCurrentAction(action);
    sceneAnimation?.start();
    logger.action(
      `action started (${ActionId[action?.id]}), ${Math.round(timer || 0)} ms`,
      action
    );
  };

  const onActionEnd = () => {
    logger.action(
      `action ended (${ActionId[currentAction?.id]}), ${Math.round(
        timer || 0
      )} ms`,
      currentAction
    );
    storeActionInDevToolsActionHistory(currentAction);
    actionCleanup();
    timeOffsetRef.current = timeOffsetRef.current + timer;
    sceneAnimation?.stop();
    setTimer(0);
    setActionTimer(undefined);
    setCurrentAction(undefined);

    const nextAction = actionQueue[1];
    if (!nextAction || !nextAction?.blockGui) {
      // unblock gui blocker only if no more actions, or if next action does not block gui
      setGuiBlocker(undefined);
    }
    removeFirstActionFromQueue();
  };

  const onActionQueueEmpty = () => {
    setGuiBlocker(undefined);
    gameFns.setCharacterIdsForIdleIgnore([]);
    logger.action(`action queue empty`, actionQueue);
  };

  const removeFirstActionFromQueue = () => {
    // remove first action from queue (it's the action that was just finished)
    if (actionQueue?.length) {
      const updatedActionQueue = [...actionQueue];
      updatedActionQueue.shift();
      setActionQueue(updatedActionQueue);
    } else {
      clearActionQueue();
    }
  };

  //
  // UTILS
  //
  const getCharacterById: (characterId: CharacterId) => {
    character: SceneCharacterDataType;
    setCharacter: (c: SceneCharacterDataType) => void;
  } = (characterId) => {
    let character: SceneCharacterDataType;
    let setCharacter: (c: SceneCharacterDataType) => void;

    if (characterId === undefined || characterId === mainCharacter.config.id) {
      // undefined characterId returns mainCharacter as a default setting of actions without explicitly specified characterId
      character = mainCharacter;
      setCharacter = setMainCharacter;
    } else {
      character = getSceneCharacter(characterId);
      if (character) {
        setCharacter = setSceneCharacter;
      }
    }

    if (!character || !setCharacter) {
      logger.error(`action character not found (${characterId})`);
    }
    return { character, setCharacter };
  };

  const getCharacterWithFacing: (options: {
    action: ActionType & ActionFacingProps;
    character: SceneCharacterDataType;
  }) => SceneCharacterDataType = ({ action, character }) => {
    let facing;
    if (action.faceCharacterId) {
      const characterToFace = getCharacterById(action.faceCharacterId);
      if (action.faceCharacterId === character.config.id || !characterToFace) {
        // do not adjust facing for character to face itself
        return character;
      }
      facing = getDirection(
        character.position,
        characterToFace.character.position
      );
    }
    if (action.faceDirection) {
      facing = action.faceDirection;
    }
    if (action.faceItemId) {
      const item = gameFns.getItemById(action.faceItemId);
      if (item) {
        facing = getDirection(
          character.position,
          item.inScene?.position ?? item.inSceneOverlay?.position
        );
      }
    }
    if (action.facePosition) {
      facing = getDirection(character.position, action.facePosition);
    }

    // set facing  (needed to be done explicitly like this to pass 'undefined' to keep previous facing if not specified)
    character.facing = facing;
    return character;
  };

  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //
  // RUN ACTIONS
  //
  useEffect(() => {
    const action = currentAction;
    const callback = actionCallback;

    if (action && isNumber(actionTimer)) {
      switch (action.id) {
        case ActionId.characterWalk:
          return runAction_CharacterWalk({ action, callback });

        case ActionId.characterTalk:
          return runAction_CharacterTalk({ action, actionTimer, callback });

        case ActionId.characterFace:
          return runAction_CharacterFace({ action, callback });

        case ActionId.characterDeath:
          return runAction_CharacterDeath({ action, callback });

        case ActionId.characterAnimation:
          return runAction_CharacterAnimation({ action, callback });

        case ActionId.pause:
          return runAction_Pause({ action, callback });

        case ActionId.execute:
          return runAction_Execute({ action, callback });

        case ActionId.itemInCursorSwapConfig:
          return runAction_ItemInCursorSwapConfig({ action, callback });

        case ActionId.itemInCursorToTrash:
          return runAction_ItemInCursorToTrash({ action, callback });

        case ActionId.itemInCursorToSceneOverlay:
          return runAction_ItemInCursorToSceneOverlay({ action, callback });

        case ActionId.putNewItemIntoCursor:
          return runAction_PutNewItemIntoCursor({ action, callback });

        case ActionId.saveGame:
          return runAction_SaveGame({ action, callback });

        case ActionId.audio:
          return runAction_Audio({ action, callback });

        case ActionId.sound:
          return runAction_Sound({ action, callback });

        case ActionId.scene:
          return runAction_Scene({ action, callback });

        case ActionId.sceneOverlayAnimation:
          return runAction_SceneOverlayAnimation({ action, callback });

        case ActionId.cinematic:
          return runAction_Cinematic({ action, callback });

        case ActionId.objective:
          return runAction_Objective({ action, callback });

        case ActionId.event:
          return runAction_Event({ action, callback });

        case ActionId.effect:
          return runAction_Effect({ action, callback });

        case ActionId.skill:
          return runAction_Skill({ action, callback });

        case ActionId.guiCustomPopup:
          return runAction_GuiCustomPopUp({ action, callback });

        default:
          return logger.error(
            `action not recognized (${(action as any)?.id})`,
            action
          );
      }
    }
  }, [actionTimer]);

  const actionCleanup = () => {
    if (currentAction) {
      const action = currentAction;

      logger.action(`action cleanup (${ActionId[action?.id]})`, action);

      switch (action.id) {
        case ActionId.characterWalk:
          return cleanup_CharacterWalk({ action });

        case ActionId.characterTalk:
          return cleanup_CharacterTalk({ action });

        case ActionId.characterFace:
          return cleanup_CharacterFace();

        case ActionId.characterDeath:
          return cleanup_CharacterDeath();

        case ActionId.characterAnimation:
          return cleanup_CharacterAnimation({ action });

        case ActionId.pause:
          return cleanup_Pause();

        case ActionId.execute:
          return cleanup_Execute();

        case ActionId.itemInCursorSwapConfig:
          return cleanup_ItemInCursorSwapConfig();

        case ActionId.itemInCursorToTrash:
          return cleanup_ItemInCursorToTrash();

        case ActionId.itemInCursorToSceneOverlay:
          return cleanup_ItemInCursorToSceneOverlay();

        case ActionId.putNewItemIntoCursor:
          return cleanup_PutNewItemIntoCursor();

        case ActionId.saveGame:
          return cleanup_SaveGame();

        case ActionId.audio:
          return cleanup_Audio();

        case ActionId.sound:
          return cleanup_Sound();

        case ActionId.scene:
          return cleanup_Scene();

        case ActionId.sceneOverlayAnimation:
          return cleanup_SceneOverlayAnimation();

        case ActionId.cinematic:
          return cleanup_Cinematic();

        case ActionId.objective:
          return cleanup_Objective();

        case ActionId.event:
          return cleanup_Event();

        case ActionId.effect:
          return cleanup_Effect();

        case ActionId.skill:
          return cleanup_Skill();

        case ActionId.guiCustomPopup:
          return cleanup_GuiCustomPopUp();

        default:
          return logger.error(
            `action cleanup not found (${(action as any)?.id})`,
            action
          );
      }
    }
  };

  const getActionTime = (timeMilliseconds) => {
    // NOTE:
    // timers are generally timeMilliseconds/<timer>, because the point is to increment actionTimer by 1 with <timer> as a step
    if (currentAction) {
      const action = currentAction;
      switch (action.id) {
        case ActionId.characterWalk:
          return Math.floor(
            timeMilliseconds / gamePlay.state.settings.walkSpeed
          );

        case ActionId.characterTalk:
          const dialogCharacterCount = gameFns.t(action.dialog).length;
          const dialogDurationMilliseconds =
            millisecondsBase +
            dialogCharacterCount *
              millisecondsPerCharInText *
              gamePlay.state.settings.talkSpeed;
          return Math.floor(timeMilliseconds / dialogDurationMilliseconds);

        case ActionId.pause:
          return Math.floor(timeMilliseconds / (action.durationSec * 1000));

        case ActionId.effect:
          return Math.floor(timeMilliseconds / (action.durationSec * 1000));

        case ActionId.characterFace:
        case ActionId.characterDeath:
        case ActionId.characterAnimation:
        case ActionId.execute:
        case ActionId.itemInCursorSwapConfig:
        case ActionId.itemInCursorToTrash:
        case ActionId.itemInCursorToSceneOverlay:
        case ActionId.putNewItemIntoCursor:
        case ActionId.saveGame:
        case ActionId.audio:
        case ActionId.scene:
        case ActionId.sceneOverlayAnimation:
        case ActionId.cinematic:
        case ActionId.sound:
        case ActionId.objective:
        case ActionId.event:
        case ActionId.skill:
        case ActionId.guiCustomPopup:
          return 1;

        default:
          logger.error(
            `action timer func not found (${(action as any)?.id})`,
            action
          );
          return 1;
      }
    }
    return 0;
  };

  ///////////////////////////////////////////////////////////////////////////////////////////////////////
  //
  // ACTION TYPE - CHARACTER WALK
  //
  const runAction_CharacterWalk: (options: {
    action: Action_CharacterWalk;
    callback: () => void;
  }) => void = ({ action, callback }) => {
    const { character, setCharacter } = getCharacterById(action.characterId);

    if (!character) {
      return callback();
    }

    if (!action.walkPath.length || !actionQueue?.length) {
      // empty walk path === end of walk action
      return callback();
    }

    const walkPoint = action.walkPath[0];

    if (walkPoint) {
      const { position, scale, facing } = walkPoint;

      setCharacter({
        ...character,
        position,
        scale,
        facing,
        renderMode:
          action.walkPath?.length <= 1
            ? CharacterRenderMode.default
            : CharacterRenderMode.walk,
        walkAnimationFrame: action.walkAnimationFrame,
      });

      action.walkPath.shift(); // remove point from path

      setCurrentAction({
        ...currentAction,
        walkPath: action.walkPath,
        walkAnimationFrame: action.walkAnimationFrame + 1,
      } as Action_CharacterWalk);
    } else {
      return callback();
    }
  };

  const cleanup_CharacterWalk = (options: { action: Action_CharacterWalk }) => {
    const { character, setCharacter } = getCharacterById(
      options.action.characterId
    );
    if (character) {
      setCharacter({
        ...character,
        renderMode: CharacterRenderMode.default,
        facing: undefined,
      });
    }
  };

  ///////////////////////////////////////////////////////////////////////////////////////////////////////
  //
  // ACTION TYPE - CHARACTER TALK
  //
  const runAction_CharacterTalk: (options: {
    action: Action_CharacterTalk;
    actionTimer: number;
    callback: () => void;
  }) => void = ({ action, actionTimer, callback }) => {
    // this func only runs twice (depending on getActionTime)
    //  -> actionTimer is 0, until specified time passes and updates it to 1

    const { character, setCharacter } = getCharacterById(action.characterId);
    if (!character) {
      return callback();
    }

    // run action
    if (actionTimer <= 0) {
      const updatedCharacter: SceneCharacterDataType = {
        ...character,
        renderMode: actionQueueMetaDataRef.current?.isIdle
          ? CharacterRenderMode.talkIdle
          : CharacterRenderMode.talk,
        talkRenderOptions: action.talkRenderOptions,
        dialog: action.dialog,
      };

      setCharacter(
        getCharacterWithFacing({ action, character: updatedCharacter })
      );
    } else {
      return callback();
    }
  };

  const cleanup_CharacterTalk = (options: { action: Action_CharacterTalk }) => {
    const { character, setCharacter } = getCharacterById(
      options.action.characterId
    );
    if (character) {
      setCharacter({
        ...character,
        renderMode: CharacterRenderMode.default,
        talkRenderOptions: undefined,
        facing: undefined,
        dialog: undefined,
      });
    }
  };

  ///////////////////////////////////////////////////////////////////////////////////////////////////////
  //
  // ACTION TYPE - CHARACTER FACING
  //
  const runAction_CharacterFace: (options: {
    action: Action_CharacterFace;
    callback: () => void;
  }) => void = ({ action, callback }) => {
    // function runs only once -> callback at the end

    const { character, setCharacter } = getCharacterById(action.characterId);
    if (character) {
      setCharacter(getCharacterWithFacing({ action, character }));
    }
    return callback();
  };

  const cleanup_CharacterFace = () => {
    return null;
  };

  ///////////////////////////////////////////////////////////////////////////////////////////////////////
  //
  // ACTION TYPE - CHARACTER DEATH
  //
  const runAction_CharacterDeath: (options: {
    action: Action_CharacterDeath;
    callback: () => void;
  }) => void = ({ action, callback }) => {
    // function runs only once -> callback at the end

    gameFns.killMainCharacter();

    return callback();
  };

  const cleanup_CharacterDeath = () => {
    return null;
  };

  ///////////////////////////////////////////////////////////////////////////////////////////////////////
  //
  // ACTION TYPE - CHARACTER CUSTOM ANIMATION
  //
  const runAction_CharacterAnimation: (options: {
    action: Action_CharacterAnimation;
    callback: () => void;
  }) => void = ({ action, callback }) => {
    // function runs only once, callback will be called by the Character onAnimationEnd

    const { character, setCharacter } = getCharacterById(action.characterId);
    if (!character) {
      return callback();
    }

    const updatedCharacter: SceneCharacterDataType = {
      ...character,
      renderMode: CharacterRenderMode.animation,
      playAnimation: {
        id: action.animationId,
        onAnimationEnd: callback,
        keepLastFrame: action.keepLastFrame,
      },
    };

    setCharacter(
      getCharacterWithFacing({ action, character: updatedCharacter })
    );
  };

  const cleanup_CharacterAnimation = (options: {
    action: Action_CharacterAnimation;
  }) => {
    if (!options.action.keepLastFrame) {
      const { character, setCharacter } = getCharacterById(
        options.action.characterId
      );

      if (character) {
        setCharacter({
          ...character,
          facing: undefined, // will use previous facing
          playAnimation: undefined,
        });
      }
    }
  };

  ///////////////////////////////////////////////////////////////////////////////////////////////////////
  //
  // ACTION TYPE - PAUSE
  //
  const runAction_Pause: (options: {
    action: Action_Pause;
    callback: () => void;
  }) => void = ({ /* action, */ callback }) => {
    // this func only runs twice (depending on getActionTime)
    //  -> actionTimer is 0, until specified time passes and updates it to 1

    if (actionTimer <= 0) {
      // run action
      /* paused automatically via guiBlocker detection and timer setup */
    } else {
      // end action
      return callback();
    }
  };

  const cleanup_Pause = () => {
    return null;
  };

  ///////////////////////////////////////////////////////////////////////////////////////////////////////
  //
  // ACTION TYPE - SAVE GAME
  //
  const runAction_SaveGame: (options: {
    action: Action_SaveGame;
    callback: () => void;
  }) => void = ({ action, callback }) => {
    // function runs only once -> callback at the end

    switch (action.type) {
      case Action_SaveGameTypeEnum.deathsave: {
        gameFns.deathsaveGame();
        break;
      }

      default:
        logger.error(
          `save game action type not supported (${action.type})`,
          action
        );
    }

    return callback();
  };

  const cleanup_SaveGame = () => {
    return null;
  };

  ///////////////////////////////////////////////////////////////////////////////////////////////////////
  //
  // ACTION TYPE - EXECUTE
  //
  const runAction_Execute: (options: {
    action: Action_Execute;
    callback: () => void;
  }) => void = ({ action, callback }) => {
    action.execute();
    return callback();
  };

  const cleanup_Execute = () => {
    return null;
  };

  ///////////////////////////////////////////////////////////////////////////////////////////////////////
  //
  // ACTION TYPE - ITEM - SWAP CONFIG (to replace item graphics - e.g. swap full vs empty flask)
  //
  const runAction_ItemInCursorSwapConfig: (options: {
    action: Action_ItemInCursorSwapConfig;
    callback: () => void;
  }) => void = ({ action, callback }) => {
    const { newItemConfigId } = action;

    if (gameItems.state.itemInCursor) {
      gameFns.swapItemConfig({
        item: gameItems.state.itemInCursor,
        newConfigId: newItemConfigId,
      });
    }

    return callback();
  };

  const cleanup_ItemInCursorSwapConfig = () => {
    return null;
  };

  ///////////////////////////////////////////////////////////////////////////////////////////////////////
  //
  // ACTION TYPE - ITEM - DELETE ITEM IN CURSOR
  //
  const runAction_ItemInCursorToTrash: (options: {
    action: Action_ItemInCursorToTrash;
    callback: () => void;
  }) => void = ({ action, callback }) => {
    gameFns.deleteItemInCursor();

    return callback();
  };

  const cleanup_ItemInCursorToTrash = () => {
    return null;
  };

  ///////////////////////////////////////////////////////////////////////////////////////////////////////
  //
  // ACTION TYPE - ITEM - PUT ITEM IN CURSOR TO SCENE OVERLAY
  //
  const runAction_ItemInCursorToSceneOverlay: (options: {
    action: Action_ItemInCursorToSceneOverlay;
    callback: () => void;
  }) => void = ({ action, callback }) => {
    const item = gameItems.state.itemInCursor;
    const clickedPosition = actionQueueMetaDataRef.current?.clickedPosition;
    const clickedDepthY = actionQueueMetaDataRef.current?.clickedDepthY;

    const position = action.position ?? clickedPosition;
    const depthY =
      action.depthY ??
      (clickedDepthY ? clickedDepthY + 1 : undefined) ??
      position?.y;

    if (item) {
      gameFns.putItemInSceneOverlay(item, {
        ...action,
        sceneId: gamePlay.state.currentScene?.uniqueSceneId,
        position: position,
        depthY: depthY,
        scale: action.scale ?? 1,
      });
    }

    return callback();
  };

  const cleanup_ItemInCursorToSceneOverlay = () => {
    return null;
  };

  ///////////////////////////////////////////////////////////////////////////////////////////////////////
  //
  // ACTION TYPE - ITEM - PUT NEW ITEM INTO CURSOR
  //
  const runAction_PutNewItemIntoCursor: (options: {
    action: Action_PutNewItemIntoCursor;
    callback: () => void;
  }) => void = ({ action, callback }) => {
    const { newItemConfigId } = action;

    generateItem({
      gameItems,
      config: getItemConfigById(newItemConfigId),
      inCursor: true,
    });

    // TODO - CHECK IF THE ABOVE WORKS (generateItem already puts the item into cursor)
    /* const newItem = generateItem({
      gameItems,
      config: getItemConfigById(newItemConfigId),
      inCursor: true,
    });
    gameFns.setItemInCursor(newItem); */

    return callback();
  };

  const cleanup_PutNewItemIntoCursor = () => {
    return null;
  };

  ///////////////////////////////////////////////////////////////////////////////////////////////////////
  //
  // ACTION TYPE - SCENE
  //
  const runAction_Scene: (options: {
    action: Action_Scene;
    callback: () => void;
  }) => void = ({ action, callback }) => {
    const currentScene = action;
    gameFns.setCurrentScene(currentScene);

    return callback();
  };

  const cleanup_Scene = () => {
    return null;
  };

  ///////////////////////////////////////////////////////////////////////////////////////////////////////
  //
  // ACTION TYPE - SCENE OVERLAY ANIMATION
  //
  const runAction_SceneOverlayAnimation: (options: {
    action: Action_SceneOverlayAnimation;
    callback: () => void;
  }) => void = ({ action, callback }) => {
    const sceneOverlayAnimation = action;
    if (
      gamePlay.state.sceneOverlayAnimation?.sceneOverlayAnimationId ===
      action.sceneOverlayAnimationId
    ) {
      gameFns.setSceneOverlayAnimation(undefined);
      setTimeout(() => {
        gameFns.setSceneOverlayAnimation(sceneOverlayAnimation);
      }, 0); // ensures the reset happens first (react optimizes setState)
    } else {
      gameFns.setSceneOverlayAnimation(sceneOverlayAnimation);
    }
    return callback();
  };

  const cleanup_SceneOverlayAnimation = () => {
    return null;
  };

  ///////////////////////////////////////////////////////////////////////////////////////////////////////
  //
  // ACTION TYPE - CINEMATICS
  //
  const runAction_Cinematic: (options: {
    action: Action_Cinematic;
    callback: () => void;
  }) => void = ({ action, callback }) => {
    let onEnd: CinematicOnEnd = action.onEnd;

    if (action.onEnd?.returnToThisScene) {
      onEnd = {
        ...onEnd,
        setSceneId: gamePlay.state.currentScene.uniqueSceneId,
      };
    }

    const currentScene: GamePlayCurrentSceneType = {
      uniqueSceneId: undefined,
      cinematic: { id: action.cinematicId, onEnd },
      walkIn: undefined,
      isSetByDeveloper: undefined,
    };

    gameFns.setCurrentScene(currentScene);

    return callback();
  };

  const cleanup_Cinematic = () => {
    return null;
  };

  ///////////////////////////////////////////////////////////////////////////////////////////////////////
  //
  // ACTION TYPE - OBJECTIVE
  //
  const runAction_Objective: (options: {
    action: Action_Objective;
    callback: () => void;
  }) => void = ({ action, callback }) => {
    // function runs only once -> callback at the end

    const currentObjective = gameFns.getCurrentObjective();

    // Setting next objective
    if (action.nextObjective !== undefined) {
      if (action.nextObjective !== currentObjective + 1) {
        // ERROR - NEXT OBJECTIVE ALWAYS HAS TO BE 1 HIGHER THAN CURRENT OBJECTIVE
        //       - otherwise objectives are skipped or returned to earlier state on accidental fallback functions
        console.error(
          `

TRYING TO SET OBJECTIVE OUT OF ORDER!

-----------------------
Expected objective: ${currentObjective + 1}
Received objective: ${action.nextObjective}
-----------------------

          `,
          {
            currentObjective,
            nextObjective: action.nextObjective,
            action,
          }
        );
      } else {
        gameFns.setCurrentObjective(action.nextObjective);
      }
    }

    // Setting new objective
    else if (action.newObjective !== undefined) {
      gameFns.setCurrentObjective(action.newObjective);
    }

    return callback();
  };

  const cleanup_Objective = () => {
    return null;
  };

  ///////////////////////////////////////////////////////////////////////////////////////////////////////
  //
  // ACTION TYPE - EVENT
  //
  const runAction_Event: (options: {
    action: Action_Event;
    callback: () => void;
  }) => void = ({ action, callback }) => {
    // function runs only once -> callback at the end

    const events = gameFns.getEvents();

    // Update events
    if (action.getUpdatedEvents) {
      const newEvents = action.getUpdatedEvents(events);
      gameFns.setEvents(newEvents);
    }

    // Update single event
    else if (action.setEvent) {
      const newEvents = {
        ...events,
        [action.setEvent.key]: action.setEvent.value,
      };
      gameFns.setEvents(newEvents);
    }

    return callback();
  };

  const cleanup_Event = () => {
    return null;
  };

  ///////////////////////////////////////////////////////////////////////////////////////////////////////
  //
  // ACTION TYPE - EVENT
  //
  const runAction_Effect: (options: {
    action: Action_Effect;
    callback: () => void;
  }) => void = ({ action, callback }) => {
    // run action
    if (actionTimer <= 0) {
      if (action.effectShakeViewport) {
        gameFns.setEffectShakeViewport(action.effectShakeViewport);
      }
      if (action.effectFadeViewport) {
        gameFns.setEffectFadeViewport(action.effectFadeViewport);
      }
      if (action.effectFadeScene) {
        gameFns.setEffectFadeScene(action.effectFadeScene);
      }
    } else {
      return callback();
    }
  };

  const cleanup_Effect = () => {
    return null;
  };

  ///////////////////////////////////////////////////////////////////////////////////////////////////////
  //
  // ACTION TYPE - SKILL
  //
  const runAction_Skill: (options: {
    action: Action_Skill;
    callback: () => void;
  }) => void = ({ action, callback }) => {
    const { skillId, skillActionType, appendStopActions, prependStopActions } =
      action;

    switch (skillActionType) {
      case SkillActionTypeEnum.StartSkill:
        gameFns.startSkill(skillId);
        break;
      case SkillActionTypeEnum.StopSkill:
        gameFns.stopSkill(skillId, { appendStopActions, prependStopActions });
        break;
      case SkillActionTypeEnum.StopAllSkills:
        gameFns.stopAllSkills({ appendStopActions, prependStopActions });
        break;
      case SkillActionTypeEnum.ResetSkill:
        gameFns.resetSkill(skillId);
        break;
    }

    return callback();
  };

  const cleanup_Skill = () => {
    return null;
  };

  ///////////////////////////////////////////////////////////////////////////////////////////////////////
  //
  // ACTION TYPE - GUI - CUSTOM POP-UP
  //
  const runAction_GuiCustomPopUp: (options: {
    action: Action_GuiCustomPopUp;
    callback: () => void;
  }) => void = ({ action, callback }) => {
    // function runs only once -> callback at the end
    gameFns.setCustomPopUp(action.customPopUp);
    return callback();
  };

  const cleanup_GuiCustomPopUp = () => {
    return null;
  };

  ///////////////////////////////////////////////////////////////////////////////////////////////////////
  //
  // ACTION TYPE - AUDIO
  //
  const runAction_Audio: (options: {
    action: Action_Audio;
    callback: () => void;
  }) => void = ({ action, callback }) => {
    // function runs only once -> callback at the end

    switch (action.type) {
      case ActionAudioTypeEnum.setOverrideMusic:
        gameFns.setOverrideMusic(action.overrideMusic);
        break;
      default:
        gameFns.setAudio(action);
    }
    return callback();
  };

  const cleanup_Audio = () => {
    return null;
  };

  ///////////////////////////////////////////////////////////////////////////////////////////////////////
  //
  // ACTION TYPE - SOUND
  //
  const runAction_Sound: (options: {
    action: Action_Sound;
    callback: () => void;
  }) => void = ({ action, callback }) => {
    // function runs only once -> callback at the end

    gameFns.playSound(action);

    return callback();
  };

  const cleanup_Sound = () => {
    return null;
  };

  ///////////////////////////////////////////////////////////////////////////////////////////////////////
  //
  // DEV TOOLS
  //
  const runActionsRef = useRef(runActions);
  const stopActionsRef = useRef(stopActions);

  useEffect(() => {
    if (devTools.state.isDeveloper) {
      devTools.setState((prevState) => ({
        ...prevState,
        actions: {
          ...prevState.actions,
          runActionsRef,
          stopActionsRef,
        },
      }));
    }
  }, [devTools.state.isDeveloper]);

  const storeDevToolsActionQueue = (actionQueue: ActionType[]) => {
    if (devTools.state.isDeveloper && actionQueue) {
      devTools.setState((prevState) => ({
        ...prevState,
        actions: {
          ...devTools.state.actions,
          runActionsRef,
          stopActionsRef,
          actionQueue: JSON.parse(JSON.stringify(actionQueue)),
        },
      }));
    }
  };

  const storeActionInDevToolsActionHistory = (action: ActionType) => {
    storeActionInDevTools({ devTools, action });
  };

  ///////////////////////////////////////////////////////////////////////////////////////////////////////
  //
  // HOOK RETURN
  //

  return {
    runActions,
    stopActions,
    actionQueue,
  };
};

export default useSceneActions;
