import { ItemSuffixType } from "game-engine/types";

/* 
  !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

  EXTREMELY IMPORTANT:

  THESE FUNCTIONS ABSOLUTELY NEED TO BE PLACED IN 
  THIS SEPARATE FILE, OTHERWISE IT CREATES CIRCULAR 
  DEPENDENCIES IN ITEM CONFIGS

  !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
*/

export enum ITEM_GENDER {
  "masculine" = "masculine",
  "feminine" = "feminine",
  "neuter" = "neuter",
}

export const getItemSuffixes = (props: { gender: ITEM_GENDER }) => {
  let czGenderSuffix = "";
  switch (props.gender) {
    case ITEM_GENDER.feminine:
      czGenderSuffix = "a";
      break;
    case ITEM_GENDER.neuter:
      czGenderSuffix = "o";
      break;
    case ITEM_GENDER.masculine:
      czGenderSuffix = "";
      break;
  }

  return {
    [ItemSuffixType.grab]: { en: "grabbed", cz: `sebrán${czGenderSuffix}` },
    [ItemSuffixType.drop]: { en: "dropped", cz: `zahozen${czGenderSuffix}` },
    [ItemSuffixType.store]: { en: "stored", cz: `uložen${czGenderSuffix}` },
  };
};
