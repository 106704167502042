/* eslint-disable react-hooks/exhaustive-deps */

import styled, { css } from "styled-components";
import { useEffect, useRef, useState } from "react";

import Button from "game-engine/_dev/basic-components/Button";
import { ReactComponent as SpeakerOff } from "assets/icons/speaker-off.svg";
import { ReactComponent as SpeakerOn } from "assets/icons/speaker-on.svg";
import devToolsConfig from "game-engine/configs/devtools-config";

const Icon = styled.div<{ isPlaying: boolean }>`
  --size: 15px;

  width: var(--size);
  min-width: var(--size);
  max-width: var(--size);
  height: var(--size);
  min-height: var(--size);
  max-height: var(--size);

  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;

  svg {
    width: 100%;
  }

  ${(props) =>
    props.isPlaying &&
    css`
      color: ${devToolsConfig.warning};
    `}
`;

const Name = styled.div``;

const ButtonPlayAudio = (props: {
  name: string;
  src: string;
  volume?: number;
  loop?: boolean;
  backgroundColor?: string;
}) => {
  const { name, src, volume = 1, loop = false } = props;
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);

  // Effect to handle volume changes
  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.volume = Math.min(volume, 1);
    }
  }, [volume]);

  // Effect to handle changes to the src prop
  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current = new Audio(src);
      audioRef.current.volume = Math.min(volume, 1);
      audioRef.current.loop = loop;
      audioRef.current.onended = () => setIsPlaying(false);
      if (isPlaying) {
        audioRef.current.currentTime = 0;
        audioRef.current.play();
      }
    }
  }, [src, loop]);

  useEffect(() => {
    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
      }
    };
  }, []);

  const toggleAudioPlayback = () => {
    if (!audioRef.current) {
      audioRef.current = new Audio(src);
      audioRef.current.volume = Math.min(volume, 1);
      audioRef.current.loop = loop;
      audioRef.current.onended = () => setIsPlaying(false);
    }

    if (isPlaying) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    } else {
      audioRef.current.play();
    }

    setIsPlaying(!isPlaying);
  };

  return (
    <Button
      onClick={toggleAudioPlayback}
      //isActive={isPlaying}
      alignLeft
      noBorder
      backgroundColor={props.backgroundColor}
    >
      <Icon isPlaying={isPlaying}>
        {isPlaying ? <SpeakerOff /> : <SpeakerOn />}
      </Icon>
      <Name>{name}</Name>
    </Button>
  );
};

export default ButtonPlayAudio;
