import { ReactNode, useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";

import GAME_CONFIG from "game-files/gameConfig";
import { SceneType } from "game-engine/types";
import { arrayOfNonUniqueItems } from "game-engine/utils";
import { getAllWalkableSceneWalkPaths } from "game-engine/utils/get-by-current-objective/get-scene-walk-paths";

const COLOR_WARNING = "#ff6363";

const SceneGrid = styled.div<{ scenesInRow: number; gap?: string }>`
  display: grid;
  grid-template-columns: repeat(${(props) => props.scenesInRow}, min-content);
  grid-auto-rows: 1fr;
  gap: ${(props) => props.gap || "0px"};
`;

const SceneItem = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: stretch;
  gap: 5px;
`;

const Overlay = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
`;

const SceneItemRow = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
`;

const Feedback = styled.div`
  flex: 1;
  position: absolute;
  background: #000a;
  color: #fff;
  border-radius: 2px;
  padding: 4px;
  font-weight: 600;
  font-size: 12px;
`;

const Errors = styled.div`
  flex: 1;
  position: absolute;
  bottom: 0;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 4px;

  padding: 4px;
  background: #000;
  color: ${COLOR_WARNING};
  font-weight: 600;
  font-size: 10px;
  word-break: break-all;
`;

const ScenePreview = styled.div<{
  isActive?: boolean;
  isHovered?: boolean;
  noNeighbors: boolean;
  hoverColor?: string;
  frameWidth: number;
  aspectRatio: number;
  isClickable?: boolean;
}>`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  gap: 10px;

  width: ${(props) => props.frameWidth}px;

  img {
    width: 100%;
    aspect-ratio: ${(props) => props.aspectRatio};
    image-rendering: auto;
    image-rendering: crisp-edges;
    image-rendering: pixelated;
  }

  div.text {
    font-size: 10px;
    opacity: 0.2;
  }

  ${(props) =>
    props.isActive &&
    css`
      img {
        outline: 1px solid #fff;
        opacity: 1;
      }
      div.text {
        opacity: 1;
      }
    `}

  ${(props) =>
    props.isHovered &&
    css`
      ${props.isClickable &&
      css`
        cursor: pointer;
      `}

      background: ${props.hoverColor || "#ffffff11"};
      img {
        outline: 1px solid #fff6;
        outline: 1px solid ${props.hoverColor || "#fff8"};
        opacity: ${props.hoverColor ? 0.5 : 1};
      }
    `}
    
  ${(props) =>
    props.noNeighbors &&
    css`
      img {
        outline: 1px solid ${COLOR_WARNING}88;
      }

      ${props.isActive &&
      css`
        img {
          outline: 1px solid ${COLOR_WARNING};
        }
      `}
    `}
`;

const Line = styled.div<{
  connectionLineWidth: number;
  connectionLineLength: number;
  isVisible: boolean;
  isConnected?: boolean;
  isActive?: boolean;
  direction: "horizontal" | "vertical";
}>`
  --lineWidth: ${(props) => props.connectionLineWidth}px;
  --lineLength: ${(props) => props.connectionLineLength / 2}px;
  --color: #f005;
  --colorConnected: #fff8;
  --colorActive: #fff;
  --colorActiveWithoutConnection: #f00;

  background: var(--color);

  ${(props) =>
    props.direction === "horizontal"
      ? css`
          height: var(--lineWidth);
          width: var(--lineLength);
        `
      : css`
          height: var(--lineLength);
          width: var(--lineWidth);
        `}

  ${(props) =>
    props.isConnected &&
    css`
      background: var(--colorConnected);
    `}

${(props) =>
    props.isActive &&
    css`
      background: var(--colorActive);
    `}

${(props) =>
    props.isActive &&
    !props.isConnected &&
    css`
      background: var(--colorActiveWithoutConnection);
    `}

  ${(props) =>
    !props.isVisible &&
    css`
      opacity: 0;
    `}
`;

export enum ScenesLayoutGridHoverMode {
  "item" = "item",
  "row" = "row",
  "column" = "column",
}

export type ScenesLayoutGridProps = {
  scenesLayout: SceneType[][];
  sceneIds?: string[];
  itemWidth?: number;
  connectionLineWidth?: number;
  connectionLineLength?: number;
  activeSceneId?: string;
  activePosition?: { row: number; column: number };
  onSceneClick?: (options: {
    scene: SceneType;
    row?: number;
    column?: number;
    isActive?: boolean;
  }) => void;
  onSceneClickFeedback?: string;
  renderEmptySceneItem?: (options: {
    row: number;
    column: number;
    isActive: boolean;
    isHovered: boolean;
    hoverColor: string;
  }) => ReactNode;
  renderOverlay?: (options: {
    row: number;
    column: number;
    isActive: boolean;
    isHovered: boolean;
    hoverColor: string;
    scene?: SceneType;
  }) => ReactNode;
  hover?: { mode: ScenesLayoutGridHoverMode; color?: string };
  noEventsForNulls?: boolean;
  ignoreErrors?: boolean;
};

//
// COMPONENT
//
const ScenesLayoutGrid = (props: ScenesLayoutGridProps) => {
  const { scenesLayout, sceneIds, hover, noEventsForNulls, ignoreErrors } =
    props;

  const connectionLineWidth = props.connectionLineWidth || 3;
  const connectionLineLength = props.connectionLineLength || 12;

  const nonUniqueIds = sceneIds ? arrayOfNonUniqueItems(sceneIds) : [];

  const frameWidth = props.itemWidth || 180;
  const aspectRatio =
    GAME_CONFIG.scene.dimensions.x / GAME_CONFIG.scene.dimensions.y;

  //
  // HOVER
  //
  const hoverMode = hover?.mode;
  const [hoverData, setHoverData] = useState<{
    row: number;
    column: number;
  }>();

  //
  // FEEDBACK MESSAGE
  //
  const [feedback, setFeedback] = useState<{ sceneId: string; text: string }>();
  const setFeedbackRef = useRef(setFeedback);

  let copyFeedbackTimer = useRef<any>();

  useEffect(() => {
    if (feedback) {
      clearTimeout(copyFeedbackTimer.current);
      copyFeedbackTimer.current = setTimeout(
        () => setFeedbackRef.current(undefined),
        600
      );
    }

    return () => {
      clearTimeout(copyFeedbackTimer.current);
    };
  }, [feedback]);

  //
  // SCENE
  //
  const onSceneClick = (options: {
    scene: SceneType;
    row?: number;
    column?: number;
    isActive?: boolean;
  }) => {
    if (props.onSceneClick) {
      if (props.onSceneClickFeedback) {
        setFeedback({
          sceneId: options.scene?.uniqueId,
          text: props.onSceneClickFeedback,
        });
      }
      props.onSceneClick(options);
    }
  };

  const getIsActive = (scene: SceneType, row: number, column: number) => {
    return (
      (scene && scene?.uniqueId === props.activeSceneId) ||
      (props.activePosition &&
        row === props.activePosition.row &&
        column === props.activePosition.column)
    );
  };

  const getIsHovered = (row, column) => {
    if (!hoverMode || !hoverData) {
      return false;
    }
    if (hoverMode === ScenesLayoutGridHoverMode.column) {
      return column === hoverData.column;
    }
    if (hoverMode === ScenesLayoutGridHoverMode.row) {
      return row === hoverData.row;
    }
    return row === hoverData.row && column === hoverData.column;
  };

  //
  // SCENE PREVIEW
  //
  const renderScenePreview = (options: {
    scene: SceneType;
    row: number;
    column: number;
    isHovered: boolean;
    noNeighbors: boolean;
  }) => {
    const { scene, row, column, isHovered, noNeighbors } = options;
    const isActive = getIsActive(scene, row, column);

    const errors = [];

    if (!ignoreErrors && scene) {
      if (noNeighbors) {
        errors.push("No neighbors");
      }
      if (nonUniqueIds.includes(scene.uniqueId)) {
        errors.push("Duplicate ID");
      }
    }

    const onClick = isHovered
      ? () => onSceneClick({ ...options, isActive })
      : undefined;

    return (
      <ScenePreview
        key={`${row}_${column}`}
        frameWidth={frameWidth}
        aspectRatio={aspectRatio}
        isActive={isActive}
        isHovered={isHovered}
        noNeighbors={noNeighbors}
        hoverColor={hover?.color}
        isClickable={!!onClick}
        onClick={onClick}
        onMouseEnter={() => setHoverData({ row, column })}
        onMouseLeave={() => setHoverData(undefined)}
      >
        {scene ? (
          <img src={scene.preview?.src} alt={scene.uniqueId} />
        ) : props.renderEmptySceneItem ? (
          props.renderEmptySceneItem({
            row,
            column,
            isActive,
            isHovered,
            hoverColor: hover?.color,
          })
        ) : (
          <div></div>
        )}

        {scene && feedback?.sceneId === scene?.uniqueId ? (
          <Feedback>{feedback?.text}</Feedback>
        ) : null}

        {errors?.length ? (
          <Errors>
            {errors.map((e, i) => (
              <div key={i}>{e}</div>
            ))}
          </Errors>
        ) : null}
      </ScenePreview>
    );
  };

  //
  // RENDER
  //
  return scenesLayout && scenesLayout[0] && scenesLayout[0].length ? (
    <SceneGrid scenesInRow={scenesLayout[0]?.length}>
      {scenesLayout?.map((scenesDefsInRow, row) =>
        scenesDefsInRow
          ?.map((scene, column) => {
            const isActive = getIsActive(scene, row, column);
            let isHovered = getIsHovered(row, column);

            if (!scene && noEventsForNulls) {
              isHovered = false;
            }

            const sceneWalkPaths = getAllWalkableSceneWalkPaths({
              dataByCurrentObjective: scene?.sceneWalkPaths,
            });

            const noNeighbors =
              !scene?.sceneNeighbors ||
              !Object.values(scene?.sceneNeighbors).filter((v) => v).length;

            return (
              <SceneItem key={column}>
                <Line
                  direction="vertical"
                  isVisible={!!sceneWalkPaths?.up}
                  isConnected={!!scene?.sceneNeighbors?.sceneId_up}
                  isActive={
                    props.activeSceneId &&
                    (isActive ||
                      scene?.sceneNeighbors?.sceneId_up === props.activeSceneId)
                  }
                  connectionLineWidth={connectionLineWidth}
                  connectionLineLength={connectionLineLength}
                />

                <SceneItemRow>
                  <Line
                    direction="horizontal"
                    isVisible={!!sceneWalkPaths?.left}
                    isConnected={!!scene?.sceneNeighbors?.sceneId_left}
                    isActive={
                      props.activeSceneId &&
                      (isActive ||
                        scene?.sceneNeighbors?.sceneId_left ===
                          props.activeSceneId)
                    }
                    connectionLineWidth={connectionLineWidth}
                    connectionLineLength={connectionLineLength}
                  />

                  {renderScenePreview({
                    scene,
                    row,
                    column,
                    isHovered,
                    noNeighbors,
                  })}

                  <Line
                    direction="horizontal"
                    isVisible={!!sceneWalkPaths?.right}
                    isConnected={!!scene?.sceneNeighbors?.sceneId_right}
                    isActive={
                      props.activeSceneId &&
                      (isActive ||
                        scene?.sceneNeighbors?.sceneId_right ===
                          props.activeSceneId)
                    }
                    connectionLineWidth={connectionLineWidth}
                    connectionLineLength={connectionLineLength}
                  />
                </SceneItemRow>

                <Line
                  direction="vertical"
                  isVisible={!!sceneWalkPaths?.down}
                  isConnected={!!scene?.sceneNeighbors?.sceneId_down}
                  isActive={
                    props.activeSceneId &&
                    (isActive ||
                      scene?.sceneNeighbors?.sceneId_down ===
                        props.activeSceneId)
                  }
                  connectionLineWidth={connectionLineWidth}
                  connectionLineLength={connectionLineLength}
                />

                {props.renderOverlay && (
                  <Overlay>
                    {props.renderOverlay({
                      row,
                      column,
                      isActive,
                      isHovered,
                      hoverColor: hover?.color,
                      scene,
                    })}
                  </Overlay>
                )}
              </SceneItem>
            );
          })
          .flat()
      )}
    </SceneGrid>
  ) : null;
};

export default ScenesLayoutGrid;
