import styled, { css } from "styled-components";

import InputLabel from "../_InputLabel";
import devToolsConfig from "game-engine/configs/devtools-config";

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const StyledTextArea = styled.textarea<{
  disabled?: boolean;
  padding?: string;
  height: string;
  onChange?: any;
  noTextWrap?: boolean;
}>`
  flex: 1;
  padding: ${(props) => props.padding || "8px"};
  font-size: 13px;
  color: #fff8;
  background: ${devToolsConfig.inputBackground};
  border: 1px solid ${devToolsConfig.borderColor};
  min-height: ${(props) => props.height};
  overflow: auto;
  outline: none;
  resize: none;

  &:focus {
    border-color: #fff4;
  }

  &:disabled {
    background: ${devToolsConfig.inputBackground};
    color: #fff4;
    min-height: 0px;
    padding: 8px;
  }

  ${(props) =>
    !props.onChange &&
    css`
      cursor: default;
    `}

  ${(props) =>
    props.noTextWrap &&
    css`
      white-space: pre;
    `}
`;

const CodeInput = (props: {
  label?: string;
  value: string;
  onChange?: (v: string) => void;
  disabled?: boolean;
  height: string;
  padding?: string;
  noTextWrap?: boolean;
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (props.onChange) {
      props.onChange(e.target.value);
    }
  };

  return (
    <Wrapper>
      {props.label && <InputLabel label={props.label} />}
      <StyledTextArea
        value={
          props.disabled && !props.value?.length
            ? "Code needs be edited directly in .ts files."
            : props.value
        }
        onChange={handleChange}
        disabled={props.disabled}
        height={props.height}
        padding={props.padding}
        noTextWrap={props.noTextWrap}
      />
    </Wrapper>
  );
};

export default CodeInput;
