/* eslint-disable react-hooks/exhaustive-deps */

import { CinematicType, CinematicTypeEnum, SceneType } from "game-engine/types";
import { useEffect, useMemo, useState } from "react";

import { GameSectionEnum } from "game-engine/components/Game";
import Music from "./Music";
import Sounds from "./Sounds";
import { getCinematicById } from "game-files/cinematics/CINEMATIC_CONFIGS";
import { getGameAudioByCurrentObjective } from "game-engine/utils";
import { getSceneByUniqueId } from "game-files/scenes/SCENE_LAYOUTS";
import useGame from "game-engine/hooks/useGame";

const GameAudio = (props: { activeGameSection: GameSectionEnum }) => {
  const { activeGameSection } = props;
  const { gamePlay, gameFns } = useGame();

  const currentScene = useMemo<SceneType>(() => {
    return getSceneByUniqueId(gamePlay?.state?.currentScene?.uniqueSceneId);
  }, [gamePlay?.state?.currentScene?.uniqueSceneId]);

  const currentCinematic = useMemo<CinematicType>(() => {
    return getCinematicById(gamePlay?.state?.currentScene?.cinematic?.id);
  }, [gamePlay?.state?.currentScene?.cinematic]);

  //
  // WATCH-OUT FOR CHARACTER DEATH
  //
  const [isDeathAudio, setIsDeathAudio] = useState(false);

  useEffect(() => {
    // TURN OFF GAMEPLAY AUDIO ON DEATH
    if (gamePlay.state.mainCharacterIsDead) {
      setIsDeathAudio(true);
    }
  }, [gamePlay.state.mainCharacterIsDead]);

  useEffect(() => {
    // RESTORE GAMEPLAY AUDIO ON LOAD
    setIsDeathAudio(false);
  }, [gamePlay.state.isLoadGame]);

  //
  // GAME AUDIO
  //
  const gameAudio = useMemo(() => {
    switch (activeGameSection) {
      case GameSectionEnum.startScreen:
        // TODO - MUSIC FOR START SCREEN
        return undefined;

      case GameSectionEnum.gameIntro:
        // TODO - MUSIC FOR INTRO
        return undefined;

      case GameSectionEnum.gamePlay:
        if (currentScene) {
          if (isDeathAudio) {
            // TODO - MUSIC FOR DEATH MENU (?)
            return undefined;
          }

          // MUSIC FOR SCENES
          const audio = getGameAudioByCurrentObjective({
            dataByCurrentObjective: currentScene?.audio,
            currentObjective: gameFns.getCurrentObjective(),
            events: gameFns.getEvents(),
          });

          return audio;
        }
        if (currentCinematic) {
          // MUSIC FOR CINEMATICS
          switch (currentCinematic.type) {
            case CinematicTypeEnum.scene:
              const sceneOverrideAudio = currentCinematic.audio;
              if (sceneOverrideAudio) {
                return sceneOverrideAudio;
              }
              if (
                !sceneOverrideAudio &&
                typeof sceneOverrideAudio === "boolean"
              ) {
                return undefined;
              }

              const sceneAudio = getGameAudioByCurrentObjective({
                dataByCurrentObjective: currentCinematic.scene?.audio,
                currentObjective: gameFns.getCurrentObjective(),
                events: gameFns.getEvents(),
              });
              return sceneAudio;

            case CinematicTypeEnum.screen:
              const screenAudio = currentCinematic.audio;
              if (typeof screenAudio !== "boolean") {
                return screenAudio;
              }
              return undefined;
          }
        }
        return undefined;

      default:
        return undefined;
    }
  }, [
    activeGameSection,
    currentScene,
    currentCinematic,
    gamePlay.state.currentObjective,
    gamePlay.state.events,
    gamePlay.state.audio,
    isDeathAudio,
  ]);

  //
  // RENDER
  //
  return (
    <>
      <Music gameAudio={gameAudio} />
      <Sounds gameAudio={gameAudio} />
    </>
  );
};

export default GameAudio;
