import { GamePlayType, LoggerType } from "game-engine/types";

import GAME_CONFIG from "game-files/gameConfig";
import { getSoundByConfigId } from "game-files/audio/SOUND_CONFIGS";
import { playTone } from "./tone";

//
// PLAY-SOUND FUNCTION - SHOULDN'T BE USED DIRECTLY, BUT VIA gameFns -> NEEDS TO TAKE INTO ACCOUNT CURRENT SETTINGS
//
export const playSound = (props: {
  soundConfigId?: string;
  volume?: number;
  repeat?: number;
  onEnd?: () => void;
  gamePlay: GamePlayType;
  logger: LoggerType;
}) => {
  const {
    logger,
    gamePlay,
    soundConfigId,
    volume = 1,
    repeat = 1,
    onEnd,
  } = props;

  if (!gamePlay.settings.playSounds) {
    logger.audio(`play sound (${soundConfigId}) disabled from settings`);
    return;
  }

  const soundConfig = getSoundByConfigId(soundConfigId);
  const volumeTotal = Math.min(
    (soundConfig?.volume ?? 1) * volume * (GAME_CONFIG.audio.globalVolume ?? 1),
    1
  );

  if (!soundConfig?.src) {
    playTone({ frequency: 440, volume: volumeTotal }); // TODO - DEFINE TONE TYPES? (FUNC TO PLAY ERROR TONE ETC.)
    logger.error(`sound config with id '${soundConfigId}' not found.`);
    return;
  }

  // Create an Audio object for each call to play sound independently
  const audio = new Audio(soundConfig.src);
  audio.volume = volumeTotal;

  let playCount = 0;

  // Play sound and repeat it if necessary
  const playAudio = () => {
    if (playCount < repeat) {
      audio
        .play()
        .then(() => {
          playCount += 1;

          // On sound end, check if we need to repeat
          audio.onended = () => {
            if (playCount < repeat) {
              playAudio(); // Replay the sound
            } else {
              // Call onEnd callback after final play
              if (onEnd) {
                onEnd();
              }
            }
          };
        })
        .catch((err) => {
          logger.error(
            `play sound (${soundConfig?.id}) error`,
            soundConfig,
            err
          );
        });
    }
  };

  // Play
  playAudio();
  logger.audio(`play sound (${soundConfig?.id})`, soundConfig);
};
