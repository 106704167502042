import AssetBox from "game-engine/_dev/basic-components/Box/AssetBox";
import AssetPreview from "game-engine/_dev/basic-components/AssetPreview";
import Box from "game-engine/_dev/basic-components/Box";
import JsonView from "game-engine/_dev/basic-components/JsonView";
import MenuButton from "game-engine/components/gui-elements/Menu/menu-elements/MenuButton";
import MenuScrollable from "game-engine/components/gui-elements/Menu/menu-elements/MenuScrollable";
import styled from "styled-components";

const PreviewWrapper = styled.div<{ minWidth?: string }>`
  min-width: ${(props) => props.minWidth};
`;

const JsonWrapper = styled.div<{ minWidth?: string }>`
  min-width: ${(props) => props.minWidth};
  display: flex;
  & > * {
    flex: 1;
  }
`;

const AssetMenuScrollable = (props: { isOpen?: boolean }) => {
  const menuWidth = 120;
  const menuHeight = 100;
  const scale = 2;

  const getPreviewButtons = (params: { count: number; width?: number }) => {
    const btns = [];
    const width = params.width || menuWidth;

    for (let i = 0; i < params.count; i++) {
      btns.push(
        <MenuButton
          key={i}
          x={Math.round(width / 2)}
          width={width}
          centered
          label={`Item ${i + 1}`}
          onClick={() => {}}
        />
      );
    }
    return btns;
  };

  const previewProps = [
    {
      exampleName: "More items than visible",
      itemCount: 5,
      visibleItemCount: 3,
    },
    {
      exampleName: "Equal items and visible count",
      itemCount: 3,
      visibleItemCount: 3,
    },
    {
      exampleName: "Less items than visible",
      itemCount: 2,
      visibleItemCount: 3,
    },
    {
      exampleName: "No items",
      itemCount: 0,
      visibleItemCount: 3,
    },
    {
      exampleName: "No items + Custom text if empty",
      itemCount: 0,
      ifEmpty: {
        text: "Custom no items text",
      },
    },
  ];

  const thumbnailWidth = 100;

  //
  // RENDER
  //
  return (
    <AssetBox
      label="Menu Scrollable"
      subLabel="Menu element to render scrollable lists"
      level={2}
      isOpen={props.isOpen}
      childMinWidth={`${menuWidth * scale + 20}px`}
      canvasThumbnail={{
        scale: 0.5,
        contentWidth: 50,
        contentHeight: 50,
        children: (
          <MenuScrollable width={thumbnailWidth} visibleChildrenCount={3}>
            {getPreviewButtons({
              count: 4,
              width: thumbnailWidth,
            })}
          </MenuScrollable>
        ),
      }}
      contentPaddingLeft="20px"
      contentPaddingRight="20px"
      contentPaddingBottom="30px"
    >
      {previewProps.map((p, i) => {
        const json = { ...p };
        delete json.exampleName;

        return (
          <Box
            key={i}
            label={p.exampleName}
            level={3}
            isOpen
            useFlex
            contentPaddingLeft="0px"
            contentPaddingTop="10px"
            gap="20px"
          >
            <PreviewWrapper minWidth={`${menuWidth * scale}px`}>
              <AssetPreview
                width={menuWidth}
                height={menuHeight}
                scale={scale}
                background="#0000"
              >
                <MenuScrollable
                  width={menuWidth}
                  visibleChildrenCount={p.visibleItemCount}
                  ifEmpty={p.ifEmpty}
                >
                  {getPreviewButtons({ count: p.itemCount })}
                </MenuScrollable>
              </AssetPreview>
            </PreviewWrapper>

            <JsonWrapper minWidth="200px">
              <JsonView json={json} autoExpandToLevel={1} hideRoot />
            </JsonWrapper>
          </Box>
        );
      })}
    </AssetBox>
  );
};

export default AssetMenuScrollable;
