import Box from "../../../../basic-components/Box";
import Button from "game-engine/_dev/basic-components/Button";
import JsonView from "game-engine/_dev/basic-components/JsonView";
import { SavedDataType } from "game-engine/utils";
import devToolsConfig from "game-engine/configs/devtools-config";
import useGame from "game-engine/hooks/useGame";

const SectionSavedGames = (props: {
  isOpen?: boolean;
  savedData: SavedDataType;
  setSavedData: (s: SavedDataType) => void;
}) => {
  const { gameFns } = useGame();

  const reloadSavedData = () => {
    props.setSavedData(gameFns.getSavedData());
  };

  //
  // RENDER
  //
  return (
    <Box
      label="Saved Games"
      childMinWidth="80%"
      contentPaddingTop="10px"
      contentPaddingBottom="20px"
      level={2}
      isOpen={props.isOpen}
    >
      <Box renderContentOnly childMinWidth="20%" isOpen>
        <Button
          onClick={() => {
            gameFns.downloadSavedData();
          }}
        >
          {"Download"}
        </Button>

        <Button
          onClick={async () => {
            const success = await gameFns.uploadSavedData();
            console.info(
              `Saved Data upload ${success ? "succeeded" : "failed"}`
            );
          }}
          confirm={{
            windowTitle: "Overwrite Saved Data?",
            title: "Careful!",
            description: "This action will overwrite all saved games.",
          }}
        >
          {"Upload"}
        </Button>

        <Button
          onClick={() => {
            gameFns.clearSavedData();
            reloadSavedData();
          }}
          confirm={{
            windowTitle: "Clear Saved Data?",
            title: "Careful!",
            description: "This action will clear all saved games.",
          }}
        >
          {"Clear"}
        </Button>
      </Box>

      <Button onClick={reloadSavedData}>{"Reload"}</Button>

      {props.savedData?.savedGames?.map((savedGame) => (
        <Box
          key={savedGame.id}
          label={`" ${savedGame.name} "`}
          childMinWidth="80%"
          level={2}
          backgroundColor={devToolsConfig.elemBackground}
          borderColor="#0000"
        >
          <Box renderContentOnly childMinWidth="40%" isOpen>
            <Button
              onClick={() => {
                gameFns.loadGame(savedGame);
              }}
              confirm={{
                windowTitle: "Load Game?",
                title: "Load Game?",
                description: "Loading a game will lose all unsaved progress.",
              }}
            >
              {"Load Game"}
            </Button>

            <Button
              onClick={() => {
                gameFns.deleteSavedGame(savedGame);
                reloadSavedData();
              }}
              confirm={{
                windowTitle: "Delete Saved Game?",
                title: "Delete Saved Game?",
                description: `Are you sure you want to delete game "${savedGame.name}"`,
              }}
            >
              {"Delete Game"}
            </Button>
          </Box>

          <Box label="JSON" childMinWidth="80%" level={3}>
            <JsonView json={savedGame} hideRoot autoExpandToLevel={1} />
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default SectionSavedGames;
