import {
  ActionId,
  Action_GuiCustomPopUp,
  CustomPopUpOptionsType,
} from "game-engine/types";

import { createActionName } from ".";

export const showPopUp: (
  props: CustomPopUpOptionsType
) => () => Action_GuiCustomPopUp = (props) => () => {
  return {
    _name: createActionName({ name: "show pop-up", value: props.title.en }),
    id: ActionId.guiCustomPopup,
    customPopUp: props,
  };
};
