const DIALOG_OPTIONS = {
  ////////////////////////////////////////////////////////////////////
  //
  // SCENE LAYERS
  //
  ////////////////////////////////////////////////////////////////////

  sceneLayers: {
    tree: [
      {
        en: `That's a tree.`,
        cz: "To je strom.",
      },
      {
        en: `What a nice tree.`,
        cz: "Docela pěkný strom.",
      },
      {
        en: `That's a tree right there.`,
        cz: `Tomu se říká strom.`,
      },
      {
        en: `I once saw two squirrels fight on a tree like this.`,
        cz: `Jednou jsem viděl jak se na takovém stromě perou dvě veverky.`,
      },
      {
        en: `Who wouldn't like a nice tree?`,
        cz: `Komu by se nelíbil takový pěkný strom?`,
      },
      {
        en: `It's quite tall... taller than me for sure.`,
        cz: `Ten je vysoký... určitě vyšší než já.`,
      },
      {
        en: `I guess I'll just take a minute to admire this tree.`,
        cz: "Co bych neobětoval chvíli abych mohl obdivovat stromy.",
      },
      [
        {
          en: "I wonder if I could climb that tree.",
          cz: "Jestlipak bych na ten strom dokázal vylézt?",
        },
        {
          en: `On second thought, I'd rather not.`,
          cz: "Raději nebudu pokoušet štěstí.",
        },
      ],
      {
        en: "It reminds me of that tree that almost fell on me.",
        cz: "Připomíná mi strom co na mě jednou málem spadl.",
      },
      {
        en: `I used to climb trees as a kid.`,
        cz: `Jako malý jsem rád lozil na stromy.`,
      },
      {
        en: `I like trees.`,
        cz: `Stromy mám rád.`,
      },
    ],

    //////////////////////////////////////////////////////////////////

    treeOld: [
      {
        en: "That tree is not getting any younger.",
        cz: "Ten strom už mladší nebude.",
      },
      {
        en: `That tree must be centuries old.`,
        cz: `Ten musí být starý celá staletí.`,
      },
      [
        {
          en: "I bet that tree is older than me.",
          cz: "Vsadím se, že je starší než já.",
        },
        {
          en: "At least five years for sure.",
          cz: "Určitě tak alespoň o pět let.",
        },
      ],
      {
        en: "The trees around here are ancient.",
        cz: "Stromy tady v okolí jsou staré celá staletí.",
      },
    ],

    //////////////////////////////////////////////////////////////////

    treeYoung: [
      {
        en: `That's probably one of the youngest trees around here.`,
        cz: `To je snad nejmladší strom tady v okolí.`,
      },
      {
        en: `What a young tree.`,
        cz: `Jaký to mladý strom.`,
      },
    ],

    //////////////////////////////////////////////////////////////////

    forest: [
      {
        en: `There sure is a lot of trees around here.`,
        cz: `Je tu okolo docela dost stromů.`,
      },
      {
        en: `This forest is ancient.`,
        cz: `Jaký to prastarý les.`,
      },
      {
        en: `It's difficult to not get lost among all these trees.`,
        cz: `Neztratit se mezi tolika stromy není úplně hračka.`,
      },
      {
        en: `I could look around if I was able to climb that tree.`,
        cz: `Kdybych na něj dokázal vyšplhat, mohl bych se rozhlédnout okolo.`,
      },
      {
        en: `Trees seem to prosper here.`,
        cz: `Stromům se tu zjevně daří.`,
      },
      {
        en: `That's one of the trees around here.`,
        cz: `To je jeden ze stromů tady okolo.`,
      },
      [
        {
          en: "Maybe I could climb one of the trees?",
          cz: "Možná bych mohl na jeden z těch stromů vylézt?",
        },
        {
          en: `On second thought, no, I couldn't`,
          cz: "Když o tom tak přemýšlím, ne, nemohl.",
        },
      ],
      {
        en: "A tree... what a surprise to find it in a forest.",
        cz: "Strom... kdo by to jen čekal uprostřed lesa.",
      },
      {
        en: `One would almost think that this is a forest, considering all the trees around here.`,
        cz: `Jeden by si skoro myslel že je v lese, když se vezme v úvahu kolik je tu stromů.`,
      },
      [
        {
          en: `There are so many trees in this forest...`,
          cz: `V tomhle lese je tolik stromů...`,
        },
        {
          en: `At least six or eight for sure.`,
          cz: `Tak šest nebo osm určitě.`,
        },
      ],
    ],

    //////////////////////////////////////////////////////////////////

    forestBackground: [
      {
        en: `I better not get lost in there.`,
        cz: `Nechtěl bych se tam ztratit.`,
      },
      {
        en: `I hope I don't get lost in here.`,
        cz: `Doufám, že se tu neztratím.`,
      },
      {
        en: `More sunlight wouldn't hurt.`,
        cz: `Trochu více světla by neuškodilo.`,
      },
      {
        en: `This place could use a bit more sunlight.`,
        cz: `Trochu více světla by to tu určitě zútulnilo.`,
      },
      {
        en: `There would be much more light in here if it wasn't for those trees.`,
        cz: "Nebýt těch stromů, bylo by tu mnohem více světla.",
      },
      {
        en: `I wonder how many trees are in this forest.`,
        cz: `Kolik je tu asi stromů?`,
      },
      {
        en: "This forest is ancient.",
        cz: "Tohle je opravdu starý les.",
      },
      {
        en: `I think I should stay on the path.`,
        cz: "Radši se budu držet u cesty.",
      },
      {
        en: `I heard that people got lost in there.`,
        cz: "Slyšel jsem, že se tu ztratila spousta lidí.",
      },
    ],

    //////////////////////////////////////////////////////////////////

    rockSmall: [
      {
        en: `A piece of rock...`,
        cz: `Kus skály...`,
      },
      {
        en: "A piece of rock... Perhaps it fell from the sky?",
        cz: "Kus kamene... Možná sem spadl z nebe?",
      },
      {
        en: `I don't think I'm strong enough to lift that.`,
        cz: `To nezvednu, nemám tolik síly.`,
      },
      {
        en: `I better be careful not to stub my toe.`,
        cz: `Radši pozor abych si neukopl palec.`,
      },
      {
        en: "I have once broken my toe on a rock like this.",
        cz: "Jednou jsem si o takový kámen zlomil palec.",
      },
      {
        en: `What a nice piece of rock.`,
        cz: `Jaký to pěkný kus kamene.`,
      },
      {
        en: `It would be impossible to move it.`,
        cz: `S tím určitě nehnu.`,
      },
    ],

    //////////////////////////////////////////////////////////////////

    rockLarge: [
      {
        en: `I'd climb that if I were younger.`,
        cz: `Přelezl bych to, ale už nejsem nejmladší.`,
      },
      {
        en: `I used to climb rocks like these when I was a kid.`,
        cz: `Jako dítě jsem rád po takových kamenech šplhal.`,
      },
      {
        en: `My grandma used to say that the rocks were brought to these lands by giants.`,
        cz: `Moje babička říkávala, že kameny tady v okolí sem nanosili obři.`,
      },
      {
        en: `I can't climb that.`,
        cz: `To nepřelezu.`,
      },
      {
        en: `That rock is in my way.`,
        cz: `Ta skála mi stojí v cestě.`,
      },
      {
        en: `I'm not going to climb that.`,
        cz: `Tam se mi šplhat nechce.`,
      },
      {
        en: `I once broke my knife on a rock like this.`,
        cz: `O takovou skálu jsem si jednou zlomil nůž.`,
      },
    ],

    //////////////////////////////////////////////////////////////////

    rocksAndBushes: [
      {
        en: `There sure is a lot of rocks and bushes around here.`,
        cz: "Je to tu samé křoví a skála.",
      },
    ],

    //////////////////////////////////////////////////////////////////

    bush: [
      {
        en: `A bush...`,
        cz: `Křoví...`,
      },
      {
        en: `I bet it's full of spiders.`,
        cz: `Vsadím se, že je to křoví plné pavouků.`,
      },
      {
        en: `I once fell into one of these.`,
        cz: `Jednou jsem do podobného křoví spadnul.`,
      },
    ],

    //////////////////////////////////////////////////////////////////

    notGoingToWalk: [
      {
        en: `I'm not going there.`,
        cz: `Tam nepůjdu.`,
      },
      {
        en: `I'm not going there, I'd get dirty.`,
        cz: `Tam nepůjdu, zašpinil bych se.`,
      },
      {
        en: `No way I'm going there.`,
        cz: `Tam nelezu.`,
      },
      {
        en: `I don't want to go there.`,
        cz: `Nechce se mi tam.`,
      },
    ],

    //////////////////////////////////////////////////////////////////

    obstacleNoSceneWalkOut: [
      {
        en: `I don't think there's a way around that.`,
        cz: `Myslím, že tohle obejít nepůjde.`,
      },
      {
        en: `That's a dead end.`,
        cz: `Tudy cesta nevede.`,
      },
      {
        en: `I can't go through that.`,
        cz: `Tudy cesta nevede.`,
      },
    ],
  },

  ////////////////////////////////////////////////////////////////////
  //
  // DEATH
  //
  ////////////////////////////////////////////////////////////////////

  death: {
    byDarkness: {
      warningDialogOptions: [
        {
          en: "It's pitch black ahead. I need a light before I go any further.",
          cz: "Je tam tma jak v pytli. Nejdřív bych měl najít nějaké světlo.",
        },
        {
          en: "I can't see a thing in there. I should find some light.",
          cz: "V té tmě tam nic nevidím. Měl bych najít nějaké světlo.",
        },
        {
          en: "I won't make it far without a light.",
          cz: "Bez světla se daleko nedostanu.",
        },
      ],

      beforeLeavingDialogOptions: [
        {
          en: "What's the worst that could happen?",
          cz: "Ále, co by se tak mohlo stát?",
        },
      ],
    },
  },
};

export default DIALOG_OPTIONS;
