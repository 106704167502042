import { ActionId, Action_SceneOverlayAnimation } from "game-engine/types";

import { SceneOverlayAnimationId } from "game-files/common/ids";
import { createActionName } from ".";

//
// CREATE BASIC SCENE ACTION
//
type SetSceneOverlayAnimationReturn = () => Action_SceneOverlayAnimation;

export const playSceneOverlayAnimation: (
  sceneOverlayAnimationId: SceneOverlayAnimationId
) => SetSceneOverlayAnimationReturn = (sceneOverlayAnimationId) => () => {
  return {
    _name: createActionName({
      name: `play scene overlay animation`,
      value: sceneOverlayAnimationId,
    }),
    id: ActionId.sceneOverlayAnimation,
    sceneOverlayAnimationId,
  };
};
