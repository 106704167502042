import styled, { css } from "styled-components";

import ButtonMuteAll from "../ButtonMuteAll";
import ExpandButton from "../ExpandButton";
import { GameDisplayModeEnum } from "game-engine/types";
import devToolsConfig from "game-engine/configs/devtools-config";
import useGame from "game-engine/hooks/useGame";
import { useState } from "react";

const BORDER_WIDTH = "1px";

const CSS_SidebarAbsoluteLeft = css`
  z-index: 200;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
`;

const CSS_SidebarAbsoluteRight = css`
  z-index: 100;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
`;

const Wrapper = styled.div<{
  isFullscreen?: boolean;
  isOpen?: boolean;
  width: string;
  forcedHeight: string;
  position: "left" | "right";
}>`
  position: relative;
  overflow-x: hidden;
  overflow-y: scroll;
  background: ${devToolsConfig.background};

  height: 100%;
  min-height: ${(props) => props.forcedHeight || "auto"};
  max-height: ${(props) => props.forcedHeight || "auto"};

  width: calc(100% - ${BORDER_WIDTH});
  max-width: ${(props) => props.width};

  transition: all 0.2s ease;
  ${(props) =>
    !props.isOpen &&
    css`
      opacity: 0;
      max-width: 0 !important;
    `}

  ${(props) =>
    props.position === "left"
      ? css`
          border-right: ${BORDER_WIDTH} solid ${devToolsConfig.borderColor};

          // scrollbar on the left side
          direction: rtl;
          & > * {
            direction: ltr;
          }
        `
      : css`
          border-left: ${BORDER_WIDTH} solid ${devToolsConfig.borderColor};
        `}

  ${(props) =>
    props.isFullscreen &&
    css`
      ${props.position === "left"
        ? CSS_SidebarAbsoluteLeft
        : CSS_SidebarAbsoluteRight}
    `}
  ${(props) => props.theme.breakpoints.XL} {
    ${(props) =>
      props.position === "left"
        ? CSS_SidebarAbsoluteLeft
        : CSS_SidebarAbsoluteRight}
  }
`;

const SidebarContent = styled.div`
  padding: 10px;
  padding-top: 46px;
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 10px;
`;

const SidebarButton = styled.div<{ position: "left" | "right" }>`
  position: absolute;
  top: 10px;
  ${(props) =>
    props.position === "left"
      ? css`
          z-index: 210;
          left: 10px;
        `
      : css`
          z-index: 110;
          right: 10px;
        `}
`;

const MuteButton = styled.div`
  position: absolute;
  top: 0px;
  margin: 8px;
`;

const Sidebar = (props: {
  name?: string;
  position: "left" | "right";
  width: string;
  forcedHeight?: string;
  isOpen?: boolean;
  children?: any;
  muteButton?: boolean;
}) => {
  const { gamePlay } = useGame();

  const [sidebarIsOpen, setSidebarIsOpen] = useState(props.isOpen);

  const isFullscreen =
    gamePlay.state.settings.displayMode === GameDisplayModeEnum.fullscreen;

  //
  // RENDER
  //
  return (
    <>
      <Wrapper
        isFullscreen={isFullscreen}
        isOpen={sidebarIsOpen}
        width={props.width}
        forcedHeight={props.forcedHeight}
        position={props.position}
      >
        <SidebarContent>{props.children}</SidebarContent>

        {props.muteButton && (
          <MuteButton>
            <ButtonMuteAll />
          </MuteButton>
        )}
      </Wrapper>

      <SidebarButton position={props.position}>
        <ExpandButton
          name={props.name}
          namePosition={props.position === "left" ? "right" : "left"}
          isOpen={sidebarIsOpen}
          onClick={() => setSidebarIsOpen(!sidebarIsOpen)}
        />
      </SidebarButton>
    </>
  );
};

export default Sidebar;
