import { ChangeEvent, useState } from "react";

import devToolsConfig from "game-engine/configs/devtools-config";
import styled from "styled-components";

const Wrapper = styled.div<{ width: string; height: string }>`
  position: relative;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Button = styled.label<{ width: string; height: string }>`
  position: relative;
  width: ${(props) => props.width};
  height: ${(props) => props.height};

  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  font-size: 12px;
  outline: none;
  border: none;
  color: #fff5;
  padding: var(--padding);

  background: ${devToolsConfig.elemBackground};
  border: 1px solid ${devToolsConfig.borderColor};

  &:hover {
    img {
      opacity: 0.1;
    }
  }
`;

const ImagePreview = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  pointer-events: none; /* Ensure the image does not block click events */

  background: #000;
  transition: 0.2s ease all;
`;

const Input = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  cursor: pointer;
`;

const ImageInput = (props: {
  width: string;
  height: string;
  image: string;
  setImage: (img: string, filename?: string) => void;
}) => {
  const { width, height, image, setImage } = props;

  const [preview, setPreview] = useState<string>(image);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const newImageUrl = reader.result as string;
        setPreview(newImageUrl);
        setImage(newImageUrl, file.name);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <Wrapper width={width} height={height}>
      <Button width={width} height={height}>
        {!!image && <ImagePreview src={preview} alt="Preview" />}

        <Input type="file" accept="image/*" onChange={handleFileChange} />
        <span>Upload Image</span>
      </Button>
    </Wrapper>
  );
};

export default ImageInput;
