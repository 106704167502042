import { ActionId, Action_Objective } from "game-engine/types";

import { GameObjective } from "game-files/gameObjectives";
import { createActionName } from ".";

//
// CREATE SET BACKGROUND MUSIC ACTION
//
type SetObjectiveReturn = () => Action_Objective;

export const setCurrentObjective: (
  newObjective: GameObjective
) => SetObjectiveReturn = (newObjective) => () => {
  return {
    _name: createActionName({
      name: "set new objective",
    }),
    id: ActionId.objective,
    newObjective,
    blockGui: true,
    unskippable: true,
  };
};

export const setNextObjective: (
  nextObjective: GameObjective
) => SetObjectiveReturn = (nextObjective) => () => {
  return {
    _name: createActionName({
      name: "set next objective",
    }),
    id: ActionId.objective,
    nextObjective,
    blockGui: true,
    unskippable: true,
  };
};
