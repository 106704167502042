import {
  deleteFromLocalStorage,
  loadFromLocalStorage,
  storeInLocalStorage,
} from "../local-storage";

import { GameSettingsType } from "game-engine/types";

const SAVED_SETTINGS_STORAGE_KEY = "settings";

//
// SETTINGS
//
export const saveSettings = (props: { gameSettings: GameSettingsType }) => {
  storeInLocalStorage(
    SAVED_SETTINGS_STORAGE_KEY,
    JSON.stringify(props.gameSettings)
  );
};

export const getSavedSettings = () => {
  const savedSettingsJson = loadFromLocalStorage(SAVED_SETTINGS_STORAGE_KEY);
  const savedSettings: GameSettingsType = JSON.parse(savedSettingsJson);
  return savedSettings;
};

export const deleteSavedSettings = () => {
  deleteFromLocalStorage(SAVED_SETTINGS_STORAGE_KEY);
};
