import {
  CinematicScreenSize,
  CinematicScreenType,
  CinematicTypeEnum,
  SpriteDirection,
} from "game-engine/types";

import { CinematicId } from "game-files/common/ids";
import GAME_CONFIG from "game-files/gameConfig";
import MUSIC_CONFIGS from "game-files/audio/MUSIC_CONFIGS";
import SOUND_CONFIGS from "game-files/audio/SOUND_CONFIGS";
import TestBackgroundSky02_Background from "./assets/test-background-sky-02.jpg";
import TestBackgroundSky02_BirdSprite from "./assets/test-background-sky-02-bird-sprite.png";
import TestBackgroundSky02_Cloud_01 from "./assets/test-background-sky-02-cloud-01.png";
import TestBackgroundSky02_Cloud_02 from "./assets/test-background-sky-02-cloud-02.png";
import TestBackgroundSky02_StarsSprite from "./assets/test-background-sky-02-stars-sprite.png";

const TestCinematicScreen05: CinematicScreenType = {
  id: CinematicId.TestCinematicScreen05,
  type: CinematicTypeEnum.screen,
  size: CinematicScreenSize.scene,
  hideGui: true,

  fadeIn: true,
  fadeOut: true,

  audio: {
    music: {
      musicConfigId: MUSIC_CONFIGS.Test_01.id,
      volume: undefined,
    },
    sound: {
      soundConfigId: SOUND_CONFIGS.Test_Bird.id,
      volume: undefined,
      loop: undefined,
    },
  },

  background: {
    color: "red",

    position: { x: 0, y: 0 },

    imageComposition: {
      layers: [
        {
          image: {
            src: TestBackgroundSky02_Background,
            width: 320,
            height: 250, // necessary for background transforms, otherwise the image is clipped to scene size
          },
        },
        {
          image: { src: TestBackgroundSky02_StarsSprite },
          spriteConfig: {
            direction: SpriteDirection.Vertical,
            frameWidth: 320,
            frameHeight: 164,
            frameCount: 30,
            frameDurationMilliseconds: 50,
          },
        },
        {
          zIndex: 10,
          image: {
            src: TestBackgroundSky02_Cloud_01,
            width: 320,
            height: 250,
          },
        },
        {
          zIndex: 10,
          image: {
            src: TestBackgroundSky02_Cloud_02,
            width: 320,
            height: 250,
          },
        },
      ],
    },
  },

  sequence: [
    {
      pauseUntilFinished: true,
      addLayer: {
        text: {
          en: "Test",
          cz: "Test",
        },
      },
    },
    {
      //pauseUntilFinished: true,
      addLayer: {
        text: {
          en: "attached text",
          cz: "přilepený text",
          moveWithBackground: true,
        },
      },
    },
    {
      pause: { durationSec: 0.5 },
    },
    {
      animateBackground: {
        durationSec: 2,
        offsetY: GAME_CONFIG.scene.dimensions.y - 250,
      },
    },
    {
      pause: { durationSec: 0.5 },
    },
    {
      addLayer: {
        zIndex: 2,
        image: { src: TestBackgroundSky02_BirdSprite },
        spriteConfig: {
          direction: SpriteDirection.Vertical,
          frameWidth: 320,
          frameHeight: 55,
          frameCount: 90,
          frameDurationMilliseconds: 40,
          originOffset: { x: 0, y: 100 }, //{ x: 0, y: 117 },
          playCount: 1,
        },
        // pauseUntilFinished: true,
      },
    },
    {
      pause: { durationSec: 1 },
    },
    {
      pauseUntilFinished: true,
      addLayer: {
        zIndex: 1,
        text: {
          en: "Text with z-index and offset-y",
          cz: "Text s nastaveným z-index a offset-y",
          offsetY: -20,
        },
      },
    },
  ],
};

export default TestCinematicScreen05;
