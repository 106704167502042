import styled, { css } from "styled-components";
import { useEffect, useState } from "react";

import Button from "../../Button";
import ShapePlus from "../../ShapePlus";
import devToolsConfig from "game-engine/configs/devtools-config";

const Wrapper = styled.div<{ hasErrors: boolean; noBorder: boolean }>`
  flex: 1;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  gap: 4px;

  background: ${(props) =>
    props.hasErrors
      ? devToolsConfig.elemBackgroundError
      : devToolsConfig.inputBackground};
  border: 1px solid ${devToolsConfig.borderColor};

  ${(props) =>
    props.noBorder &&
    css`
      border: none;
    `}
`;

const Row = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: stretch;
`;

const Prefix = styled.span<{ width?: string; fontSize: string }>`
  padding-left: 5px;
  padding-right: 5px;
  color: #fff4;
  font-size: 13px;

  ${(props) =>
    props.width &&
    css`
      width: ${props.width};
    `}

  ${(props) =>
    props.fontSize &&
    css`
      font-size: ${props.fontSize};
    `}
`;

const InputComponent = styled.input<{ prefix: string }>`
  flex: 1;
  border: none;
  outline: none;
  background: none;
  padding: 8px;
  overflow: hidden;

  font-size: 13px;
  color: #fffa;

  &:focus {
    ${Wrapper} {
      outline: none !important;
      border-color: #fff4;
    }
  }

  ${(props) =>
    props.prefix &&
    css`
      padding-left: 2px;
    `}
`;

const InputRange = styled.input`
  appearance: none;
  -webkit-appearance: none;

  margin-top: -5px;
  height: 13px;
  background: #fff1;

  &::-webkit-slider-thumb {
    appearance: none;
    -webkit-appearance: none;
    cursor: pointer;

    height: 14px;
    width: 20px;
    background: ${devToolsConfig.borderColor};

    &:hover {
      background: #fff8;
    }
  }
`;

const Errors = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: -4px;
`;

const Error = styled.div`
  padding: 4px 10px;
  color: #ff6363;
  font-size: 12px;
  font-weight: 600;
`;

export type InputBaseSubmitButton = {
  label?: string;
};

export type InputBaseError = {
  message?: string;
};

const InputBase = (
  props: React.HTMLProps<HTMLInputElement> & {
    prefix?: string;
    prefixWidth?: string;
    prefixFontSize?: string;

    submitButton?: InputBaseSubmitButton;

    errors?: InputBaseError[];
    setErrors?: (e: InputBaseError[]) => void;

    rangeController?: {
      value: number;
      min: number;
      max: number;
      step?: number;
      onChange: (v) => void;
    };

    noBorder?: boolean;
  }
) => {
  const [localValue, setLocalValue] = useState(props.value);

  useEffect(() => {
    setLocalValue(props.value);
  }, [props.value]);

  const onInputChange = (e) => {
    const v = (e.target as any).value;

    if (props.setErrors) {
      props.setErrors([]);
    }

    if (props.submitButton) {
      setLocalValue(v);
    } else {
      props.onChange(v);
    }
  };

  const onSubmitButtonClick = () => {
    props.onChange(localValue as any);
  };

  const isSubmitActive = () => {
    return JSON.stringify(props.value) !== JSON.stringify(localValue);
  };

  //
  // RENDER
  //
  return (
    <Wrapper hasErrors={!!props.errors?.length} noBorder={props.noBorder}>
      <Row>
        {props.prefix && (
          <Prefix width={props.prefixWidth} fontSize={props.prefixFontSize}>
            {`${props.prefix}:`}
          </Prefix>
        )}
        <InputComponent
          {...(props as any)}
          prefix={props.prefix}
          value={props.submitButton ? localValue : props.value}
          onChange={onInputChange}
        />

        {props.submitButton ? (
          <>
            <Button
              onClick={onSubmitButtonClick}
              noBorder
              isActive={isSubmitActive()}
            >
              {props.submitButton.label || "Submit"}
            </Button>

            <Button
              onClick={() => {
                setLocalValue(props.value);
                if (props.setErrors) {
                  props.setErrors([]);
                }
              }}
              noBorder
              padding="0px"
              isActive={isSubmitActive()}
            >
              <ShapePlus
                rotate={45}
                opacity={isSubmitActive() ? 0.8 : 0.3}
                width="33px"
                height="33px"
              />
            </Button>
          </>
        ) : null}
      </Row>

      {props.rangeController ? (
        <InputRange
          type="range"
          onChange={(e) =>
            props.rangeController.onChange(parseFloat(e.target?.value))
          }
          value={props.rangeController.value}
          min={props.rangeController.min}
          max={props.rangeController.max}
          step={props.rangeController.step}
        />
      ) : null}

      {props.errors?.length ? (
        <Errors>
          {props.errors.map((e, i) => (
            <Error key={i}>{e.message}</Error>
          ))}
        </Errors>
      ) : null}
    </Wrapper>
  );
};

export default InputBase;
