import MenuSavedGames, {
  MenuSavedGamesEnum,
} from "../menu-templates/MenuSavedGames";

import { MenuProps } from "..";
import { SavedGameType } from "game-engine/utils";

const LoadMenu = (
  props: MenuProps & {
    onLoadGameOverride?: (savedGame: SavedGameType) => void;
    onImportOverride?: () => void;
  }
) => {
  return <MenuSavedGames mode={MenuSavedGamesEnum.loadGame} {...props} />;
};

export default LoadMenu;
