import { CharacterRenderType, Direction } from "game-engine/types";

import IMG_healthy_animation_kick from "./animation-kick.png";
import IMG_healthy_animation_shrug from "./animation-shrug.png";
import IMG_healthy_animation_stretch from "./animation-stretch.png";
import IMG_healthy_animation_yawn from "./animation-yawn.png";
import IMG_healthy_default from "./default.png";
import IMG_healthy_talk_body from "./talk-body.png";
import IMG_healthy_talk_head from "./talk-head.png";
import IMG_healthy_walk_down from "./walk-down.png";
import IMG_healthy_walk_left from "./walk-left.png";
import IMG_healthy_walk_up from "./walk-up.png";

export const AnimationId_Default = {
  kick: "kick",
  yawn: "yawn",
  stretch: "stretch",
  shrug: "shrug",
};

const RenderDefault: CharacterRenderType = {
  facing: Direction.left,
  offsetY: 3,

  default: {
    image: { src: IMG_healthy_default },
    offsetY: 0,
    width: 24,
    height: 66,
  },

  talk: {
    offsetY: 0,
    bodyImage: { src: IMG_healthy_talk_body },
    headSprite: { src: IMG_healthy_talk_head },
    headSpriteConfig: {
      frameWidth: 18,
      frameHeight: 18,
      frameCount: 12,
    },
    headOffsetY: -45,
  },

  walkLeft: {
    offsetY: 0,
    sprite: { src: IMG_healthy_walk_left },
    spriteConfig: {
      frameWidth: 30,
      frameHeight: 66,
      frameCount: 8,
    },
    pixelsWalked: 32, // how many pixels the character travels in one loop
  },

  walkUp: {
    offsetY: 0,
    sprite: { src: IMG_healthy_walk_up },
    spriteConfig: {
      frameWidth: 26,
      frameHeight: 66,
      frameCount: 6,
    },
    pixelsWalked: 18, // how many pixels the character travels in one loop
  },

  walkDown: {
    offsetY: 0,
    sprite: { src: IMG_healthy_walk_down },
    spriteConfig: {
      frameWidth: 26,
      frameHeight: 66,
      frameCount: 6,
    },
    pixelsWalked: 18, // how many pixels the character travels in one loop
  },

  animations: [
    {
      animationId: AnimationId_Default.kick,
      isIdle: true,
      offsetY: 0,
      sprite: { src: IMG_healthy_animation_kick },
      spriteConfig: {
        frameWidth: 32,
        frameHeight: 66,
        frameCount: 10,
      },
    },

    {
      animationId: AnimationId_Default.yawn,
      isIdle: true,
      offsetY: 0,
      sprite: { src: IMG_healthy_animation_yawn },
      spriteConfig: {
        frameWidth: 32,
        frameHeight: 66,
        frameCount: 20,
      },
    },

    {
      animationId: AnimationId_Default.stretch,
      isIdle: true,
      offsetY: 0,
      sprite: { src: IMG_healthy_animation_stretch },
      spriteConfig: {
        frameWidth: 44,
        frameHeight: 66,
        frameCount: 14,
      },
    },

    {
      animationId: AnimationId_Default.shrug,
      isIdle: false,
      offsetY: 0,
      sprite: { src: IMG_healthy_animation_shrug },
      spriteConfig: {
        frameWidth: 50,
        frameHeight: 66,
        frameCount: 20,
      },
    },
  ],
};

export default RenderDefault;
