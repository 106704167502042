import { DevEventType, DevToolsType } from "game-engine/types/devTools";

import { ActionType } from "game-engine/types";

type DevToolsPropsType = {
  state: DevToolsType;
  setState: (s: DevToolsType) => void;
};

//
// ACTION HISTORY
//
export const storeActionInDevTools = (props: {
  devTools: DevToolsPropsType;
  action: ActionType;
}) => {
  const { devTools, action } = props;

  if (devTools.state.isDeveloper && action) {
    const updatedDevTools = { ...devTools.state };

    updatedDevTools.actions.actionOrEventHistory.push(
      JSON.parse(JSON.stringify(action))
    );

    devTools.setState(updatedDevTools);
  }
};

//
// EVENT HISTORY
//
export const storeEventInDevTools = (props: {
  devTools: DevToolsPropsType;
  event: DevEventType;
}) => {
  const { devTools, event } = props;

  if (devTools.state.isDeveloper && event) {
    const updatedDevTools = { ...devTools.state };

    updatedDevTools.actions.actionOrEventHistory.push(
      JSON.parse(JSON.stringify({ ...event, isEvent: true }))
    );

    devTools.setState(updatedDevTools);
  }
};
