import ExpandButton from "../ExpandButton";
import React from "react";
import devToolsConfig from "game-engine/configs/devtools-config";
import styled from "styled-components";

const Wrapper = styled.div`
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(3px);
  background: #0005;
`;

const Window = styled.div<{ width: string; height: string }>`
  cursor: default;
  overflow: hidden;

  width: 100%;
  height: 100%;
  max-width: ${(props) => props.width};
  max-height: ${(props) => props.height};

  display: flex;
  flex-direction: column;

  background: ${devToolsConfig.background};
  box-shadow: 0px 10px 20px 0px #0004;
`;

const Header = styled.div`
  height: 30px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff5;
`;

const HeaderTitle = styled.div``;

const CloseButton = styled.div``;

const Content = styled.div`
  flex: 1;
  overflow: auto;
`;

const Footer = styled.div`
  background: #fff1;
`;

const ModalWindow = (props: {
  windowTitle: string;
  isOpen: boolean;
  close: () => void;
  width?: string;
  height?: string;
  children?: any;
  footer?: React.ReactNode;
}) => {
  const width = props.width || "1000px";
  const height = props.height || "800px";

  return props.isOpen ? (
    <Wrapper>
      <Window width={width} height={height}>
        <Header>
          <HeaderTitle>{props.windowTitle}</HeaderTitle>
          <CloseButton>
            <ExpandButton isOpen onClick={props.close} />
          </CloseButton>
        </Header>

        {props.children?.length > 0 && <Content>{props.children}</Content>}

        {props.footer && <Footer>{props.footer}</Footer>}
      </Window>
    </Wrapper>
  ) : null;
};

export default ModalWindow;
