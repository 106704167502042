import {
  RunActionsType,
  StopActionsType,
} from "game-engine/components/game-elements/Scene/logic/useSceneActions";

import GAME_CONFIG from "game-files/gameConfig";
import { Group } from "react-konva";
import { SkillId } from "game-files/common/ids";
import SkillSlot from "./SkillSlot";

const Skills = (props: {
  runActions: RunActionsType;
  stopActions: StopActionsType;
}) => {
  const { runActions, stopActions } = props;

  //
  // RENDER
  //
  return (
    <Group
      position={{
        x: Math.round(GAME_CONFIG.gui.skillSlots.origin.x),
        y: Math.round(GAME_CONFIG.gui.skillSlots.origin.y),
      }}
    >
      {Object.keys(SkillId).map((skillId, i) => {
        return (
          <Group
            key={skillId}
            position={{
              x:
                i *
                (GAME_CONFIG.gui.skillSlots.slotWidth +
                  GAME_CONFIG.gui.skillSlots.gap),
              y: 0,
            }}
          >
            <SkillSlot
              skillId={skillId as SkillId}
              runActions={runActions}
              stopActions={stopActions}
            />
          </Group>
        );
      })}
    </Group>
  );
};

export default Skills;
