import { TextAlign, TranslatedString } from "game-engine/types";

import GAME_CONFIG from "game-files/gameConfig";
import { Group } from "react-konva";
import MenuBevelBox from "./MenuBevelBox";
import Text from "game-engine/components/basic-elements/Text";
import { useState } from "react";

export const MENU_BUTTON_HEIGHT = 17;

export type MenuButtonProps = {
  x?: number;
  y?: number;
  paddingX?: number;
  paddingY?: number;
  label: string | TranslatedString;
  width: number;
  height?: number;
  centered?: boolean;
  centeredText?: boolean;
  opacity?: number;
  color?: string;
  onClick?: () => void;
  disabled?: boolean;
};

const MenuButton = (props: MenuButtonProps) => {
  const {
    x = 0,
    y = 0,
    width,
    height = MENU_BUTTON_HEIGHT,
    label,
    centered,
    centeredText,
    opacity = 1,
    color,
    disabled,
  } = props;

  const [isHovered, setIsHovered] = useState(false);
  const [isPushed, setIsPushed] = useState(false);

  //
  // SETTINGS
  //
  const paddingX = centeredText
    ? 0
    : props.paddingX !== undefined
    ? props.paddingX
    : 6;
  const paddingY = props.paddingY !== undefined ? props.paddingY : 1;

  const textColor = GAME_CONFIG.menu.colorText;

  // Auxiliary Vars
  const textX = 2 + paddingX;
  const textY = 3 - paddingY;
  const textWidth = centeredText ? width - textX * 2 : width - textX - 2;

  //
  // LOGIC
  //
  const onMouseDown = () => {
    if (props.onClick) {
      setIsPushed(true);
    }
  };
  const onMouseUp = () => {
    if (props.onClick) {
      props.onClick();
      setIsPushed(false);
    }
  };
  const onMouseEnter = () => {
    setIsHovered(true);
  };
  const onMouseLeave = () => {
    setIsHovered(false);
    setIsPushed(false);
  };

  //
  // RENDER
  //
  return (
    <Group
      x={centered ? x - Math.round(width / 2) : x}
      y={y}
      opacity={opacity * (disabled ? 0.5 : 1)}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onTouchStart={onMouseDown}
      onTouchEnd={onMouseUp}
      listening={!disabled}
    >
      <MenuBevelBox
        width={width}
        height={height}
        isPushed={isPushed}
        color={color}
      />

      <Text
        text={label}
        position={{ x: centeredText ? Math.round(width / 2) : textX, y: textY }}
        minWidth={textWidth}
        maxWidth={textWidth}
        outlined={false}
        shadow={true}
        color={textColor}
        opacity={
          isHovered
            ? GAME_CONFIG.menu.textOpacityActive
            : GAME_CONFIG.menu.textOpacityDefault
        }
        align={centeredText ? TextAlign.center : TextAlign.left}
      />
    </Group>
  );
};

export default MenuButton;
