import Konva from 'konva'
import { Layer } from 'react-konva'

const PixelatedLayer = (props: { children?: any } & Konva.LayerConfig) => {
  return (
    <Layer {...props} imageSmoothingEnabled={false}>
      {props.children}
    </Layer>
  )
}

export default PixelatedLayer
