import Button from "../Button";
import ModalWindow from ".";
import styled from "styled-components";

const Content = styled.div`
  margin: auto;
  width: 90%;
  min-height: 80%;
  padding: 30px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
  white-space: pre;
  text-align: center;
`;

const Title = styled.div`
  font-size: 20px;
  color: #fffc;
`;

const Description = styled.div`
  max-width: 100%;
  font-size: 14px;
  word-wrap: break-word;
  white-space: pre-line;
  line-height: 1.5em;
  color: #fff6;
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 20px 10px;

  & > * {
    width: 30%;
    padding: 12px;
  }
`;

const ModalConfirm = (props: {
  isOpen: boolean;
  close: () => void;
  onConfirm: () => void;
  windowTitle: string;
  title?: string;
  description?: string;
  width?: string;
  height?: string;
}) => {
  const {
    isOpen,
    close,
    windowTitle,
    title,
    description,
    width = "800px",
    height = "600px",
  } = props;

  return (
    <ModalWindow
      windowTitle={windowTitle}
      isOpen={isOpen}
      close={close}
      width={`min(95%, ${width})`}
      height={`min(95vh, ${height})`}
      footer={
        <Footer>
          <Button isActive onClick={props.close}>
            {"Cancel"}
          </Button>
          <Button isActive onClick={props.onConfirm}>
            {"Confirm"}
          </Button>
        </Footer>
      }
    >
      {title || description ? (
        <Content>
          {title && <Title>{title}</Title>}
          {description && <Description>{description}</Description>}
        </Content>
      ) : null}
    </ModalWindow>
  );
};

export default ModalConfirm;
