import { FontGlyphType, FontType, TextAlign } from "game-engine/types";
import {
  PixelMatrixType,
  PixelValueType,
  getMatrixHeight,
  getMatrixWidth,
  getOutlinedMatrix,
  getShadowMatrix,
} from "./pixels";

import { arrayOf } from "../by-types";

// Optimized matrix to string conversion
export const getTextMatrixPrintData = (matrix: number[][]) =>
  matrix
    .map((row) =>
      row.map((value) => (value < 0 ? "X" : value === 0 ? " " : "█")).join("")
    )
    .join("\n");

// Optimized matrix printer
export const printTextMatrix = (matrix: number[][]) => {
  console.info(getTextMatrixPrintData(matrix));
};

//
// GET GLYPH FROM FONT, HANDLES NON-EXISTENT GLYPHS
//
export const getGlyphFromFont = (props: {
  font: FontType;
  glyphKey: string;
}) => {
  const { font, glyphKey } = props;
  let glyph: FontGlyphType = font.glyphs[glyphKey];

  if (!glyph) {
    glyph = {
      pixels: [
        [1, 1, 1, 1],
        [1, 0, 0, 1],
        [1, 0, 0, 1],
        [1, 1, 1, 1],
      ],
    };
  }

  if (glyph.color) {
    const updatedPixels = glyph.pixels.map((row) =>
      row.map((pixel) => (pixel === 1 ? glyph.color : pixel))
    );
    glyph = { ...glyph, pixels: updatedPixels as PixelValueType[][] };
  }

  return glyph;
};

//
// GET GLYPH MATRIX NORMALIZED TO LINE-HEIGHT
//
export const getNormalizedGlyphMatrix = (props: {
  lineHeight?: number;
  underlineHeight?: number;
  glyph: {
    pixels: PixelMatrixType;
    offsetY?: number;
  };
}) => {
  const { glyph, lineHeight, underlineHeight } = props;
  const frameHeight = lineHeight || 0;
  const pixelsUnderLine = underlineHeight || 0;

  let matrix = glyph.pixels;
  const matrixHeight = getMatrixHeight(matrix);
  const offsetY = glyph.offsetY || 0;

  // Normalize glyph to frameHeight
  const emptyLinesBottom = pixelsUnderLine + offsetY;
  const emptyLinesTop = frameHeight - matrixHeight - emptyLinesBottom;
  const emptyRow = arrayOf(getMatrixWidth(matrix), 0);

  if (emptyLinesTop > 0) {
    matrix = [...arrayOf(emptyLinesTop, emptyRow), ...matrix];
  }
  if (emptyLinesBottom > 0) {
    matrix = [...matrix, ...arrayOf(emptyLinesBottom, emptyRow)];
  }

  return matrix;
};

//
// CONCATENATE MATRICES TO A SINGLE ROW
//
export const joinMatricesToRow = (props: {
  font: FontType;
  matrixArray: number[][][];
}) => {
  const { font, matrixArray } = props;

  if (!matrixArray?.length) {
    return null;
  }

  let joinedMatrix = [...matrixArray[0]];

  if (matrixArray.length === 1) {
    return joinedMatrix;
  }

  // Join individual rows of matrices
  for (let row = 0; row < font.lineHeight; row++) {
    for (let i = 1; i < matrixArray.length; i++) {
      joinedMatrix[row] = [...joinedMatrix[row], ...matrixArray[i][row]];
    }
  }

  return joinedMatrix;
};

//
// CONCATENATE MATRICES TO A SINGLE COLUMN
//
export const joinMatricesToCol = (props: {
  font: FontType;
  matrixArray: number[][][];
}) => {
  const { font, matrixArray } = props;
  let lineSpacing = font.lineSpacing;

  if (lineSpacing < 0) {
    // negative line spacing cannot go further than line height
    if (Math.abs(lineSpacing) > font.lineHeight) {
      lineSpacing = -font.lineHeight;
    }
  }

  if (lineSpacing > 0) {
    // line spacing required - insert empty rows
    const spacingRows = arrayOf(
      lineSpacing,
      arrayOf(getMatrixWidth(matrixArray[0]), 0)
    );

    const finalMatrix = [];
    matrixArray.forEach((matrix, i) => {
      if (i > 0) {
        finalMatrix.push(...spacingRows);
      }
      finalMatrix.push(...matrix);
    });
    return finalMatrix;
  }

  if (lineSpacing < 0) {
    // negative line spacing required - merge overlapping rows
    const finalMatrix = [];

    matrixArray.forEach((matrix, i) => {
      if (i === 0) {
        finalMatrix.push(...matrix);
        return;
      }

      // next steps have to overlap first 'lineSpacing' rows with the end of the finalMatrix
      matrix.forEach((row, rowIndex) => {
        if (rowIndex < Math.abs(lineSpacing)) {
          const prevIndex =
            finalMatrix.length - 1 - (Math.abs(lineSpacing) - 1 - rowIndex);
          const prevRow = finalMatrix[prevIndex];
          finalMatrix[prevIndex] = prevRow.map((v1, valueIndex) => {
            const v2 = row[valueIndex];
            return v1 || v2 ? 1 : 0;
          });
        } else {
          finalMatrix.push(row);
        }
      });
    });
    return finalMatrix;
  }

  return matrixArray.flat();
};

//
// GET TEXT MATRIX BY CONCATENATING TEXT GLYPH MATRICES
//
export const getPixelMatrixLineFromText = (props: {
  font: FontType;
  text: string;
}) => {
  const { font, text } = props;

  const letterSpacing = font.letterSpacing;
  const frameHeight = font.lineHeight;

  const letterSpacingMatrix =
    letterSpacing > 0 ? arrayOf(frameHeight, arrayOf(letterSpacing, 0)) : [];

  // Parse text into individual characters and retrieve glyphs
  const glyphDataArray = text
    .split("")
    .map((glyphKey) => getGlyphFromFont({ font, glyphKey }));

  let textMatrix: number[][] = [];

  // Create matrix from glyphs
  glyphDataArray.forEach((glyph) => {
    const glyphMatrix = getNormalizedGlyphMatrix({
      lineHeight: font.lineHeight,
      underlineHeight: font.underlineHeight,
      glyph,
    });

    // Add glyph to matrix of previous glyphs
    if (textMatrix.length === 0) {
      // first letter
      textMatrix = glyphMatrix;
    } else {
      // other letters
      for (let rowIndex = 0; rowIndex < frameHeight; rowIndex++) {
        textMatrix[rowIndex] = [
          ...(textMatrix[rowIndex] || []),
          ...(letterSpacingMatrix[rowIndex] || []),
          ...glyphMatrix[rowIndex],
        ];
      }
    }
  });

  return textMatrix;
};

//
// GET TEXT MATRIX WITH WIDTH OPTIONS AND WORD WRAPPING
//
export const getPixelMatrixLinesFromText = (props: {
  font: FontType;
  text: string;
  maxWidth?: number;
}) => {
  const { font, text, maxWidth } = props;
  const letterSpacing =
    (typeof font.letterSpacing === "number" && font.letterSpacing) || 0;

  if (!maxWidth) {
    // return single line
    return getPixelMatrixLineFromText({ font, text });
  }

  // split text into words and retrieve text matrix for each
  const wordMatrices = text
    .split(" ")
    .map((word) => getPixelMatrixLineFromText({ font, text: word }));

  // get matrix for space character
  const spaceMatrix = getNormalizedGlyphMatrix({
    lineHeight: font.lineHeight,
    underlineHeight: font.underlineHeight,
    glyph: font.glyphs[" "],
  });
  const spaceWidth = getMatrixWidth(spaceMatrix);

  // put together array of matrices for each line of text to fit given width
  const textLineMatrices: { matrices: number[][][]; width: number }[] = [
    { matrices: [], width: 0 },
  ];
  let lineIndex = 0;

  wordMatrices.forEach((wordMatrix, i) => {
    const wordMatrixWidth = getMatrixWidth(wordMatrix);

    const line = textLineMatrices[lineIndex];
    const localSpaceWidth = i === 0 ? 0 : spaceWidth;

    if (
      line?.width === 0 ||
      line?.width < maxWidth - localSpaceWidth - wordMatrixWidth - letterSpacing
    ) {
      // wordMatrix fits into currentLine, or is the first word (=== also handles longer unbreakable words)
      if (localSpaceWidth) {
        textLineMatrices[lineIndex].matrices.push(spaceMatrix);
      }
      textLineMatrices[lineIndex].matrices.push(wordMatrix);

      textLineMatrices[lineIndex].width =
        line.width + localSpaceWidth + wordMatrixWidth + letterSpacing;
    } else {
      // put word into next line
      lineIndex++;
      textLineMatrices[lineIndex] = {
        matrices: [wordMatrix],
        width: wordMatrixWidth,
      };
    }
  });

  return textLineMatrices.map((data) => ({
    ...data,
    matrices: data.matrices.filter((m) => m.length > 0),
  }));
};

//
// GET LINE MATRIX EXTENDED TO GIVEN WIDTH
//
export const getPaddedLineMatrix = (props: {
  matrix: number[][];
  width: number;
  align?: TextAlign;
}) => {
  const { matrix, width, align } = props;

  const paddingCount = width - getMatrixWidth(matrix);
  if (paddingCount <= 0) {
    return matrix;
  }

  const padding = arrayOf(paddingCount, 0);

  return matrix.map((row) => {
    if (align === TextAlign.right) {
      return [...padding, ...row];
    }

    if (align === TextAlign.center) {
      const paddingHalfCount = Math.floor(paddingCount / 2);
      const padding1 = arrayOf(paddingHalfCount, 0);
      const padding2 = arrayOf(paddingCount - paddingHalfCount, 0);

      return [...padding1, ...row, ...padding2];
    }

    return [...row, ...padding];
  });
};

//
// GET TEXT MATRIX WITH WIDTH OPTIONS AND WORD WRAPPING
//
export type TextPixelData = {
  matrix: number[][];
  width: number;
  height: number;
};

export const getPixelMatrixDataFromText = (props: {
  font: FontType;
  text: string;
  minWidthPx?: number;
  maxWidthPx?: number;
  outlined?: boolean;
  shadow?: boolean;
  align?: TextAlign;
}): TextPixelData => {
  const { font, outlined, shadow, minWidthPx, maxWidthPx, align } = props;
  const minWidth = minWidthPx || 0;
  const maxWidth = maxWidthPx;
  const text = props.text?.trim()?.replace(/\s+/g, " ");
  const alignment = align || TextAlign.left;

  if (!text?.length) {
    return {
      matrix: [],
      width: 0,
      height: 0,
    };
  }

  let finalMatrix: number[][] = [];

  if (!maxWidth) {
    // return single line
    finalMatrix = getPixelMatrixLineFromText({ font, text });
  } else {
    // split text into line matrices of given maxWidth
    const lineMatricesData = getPixelMatrixLinesFromText({
      font,
      text,
      maxWidth,
    });

    const maxLineWidth = Math.max(...lineMatricesData.map((m) => m.width));
    const normalizedLineMatrices = lineMatricesData.map((data) =>
      getPaddedLineMatrix({
        matrix: joinMatricesToRow({ font, matrixArray: data.matrices }),
        width: Math.max(minWidth, maxLineWidth),
        align: alignment,
      })
    );

    finalMatrix = joinMatricesToCol({
      font,
      matrixArray: normalizedLineMatrices,
    });
  }

  if (finalMatrix.length && outlined) {
    finalMatrix = getOutlinedMatrix(finalMatrix);
  }

  if (finalMatrix.length && shadow) {
    finalMatrix = getShadowMatrix(finalMatrix);
  }

  return {
    matrix: finalMatrix,
    width: getMatrixWidth(finalMatrix),
    height: getMatrixHeight(finalMatrix),
  };
};
