import {
  AnimationAsSceneOverlayAnimation,
  AnimationColorEnum,
  AnimationColorType,
  AnimationConfigType,
  BlendingMode,
  SpriteConfigType,
} from "game-engine/types";

// Declare require.context for TypeScript
declare const require: {
  context: (
    directory: string,
    useSubdirectories: boolean,
    regExp: RegExp
  ) => {
    keys: () => string[];
    (id: string): string;
  };
};

// Dynamic import of all .mp3 files from current directory
const imageFilesContext = require.context("./", false, /\.png$/);

// Create a mapping of file names to imported mp3 files
const imageFiles = imageFilesContext.keys().reduce((acc, fileName) => {
  const key = fileName.replace("./", "").replace(".png", "");
  acc[key] = imageFilesContext(fileName);
  return acc;
}, {} as Record<string, string>);

const getImageFile = (filename: string) => {
  const file = imageFiles[filename];
  if (!file) {
    console.error(`IMAGE FILE '${filename}' IS MISSING!`);
  }
  return file;
};

export const createAnimationConfig: (props: {
  name: string;
  filename: string;
  spriteConfig: SpriteConfigType;
  colors?: { [color in AnimationColorEnum]?: AnimationColorType };
  blendingMode?: BlendingMode;
  asSceneOverlayAnimation?: AnimationAsSceneOverlayAnimation;
}) => AnimationConfigType = ({
  name,
  filename,
  spriteConfig,
  colors,
  blendingMode,
  asSceneOverlayAnimation,
}) => {
  return {
    id: filename,
    name: name,
    sprite: { src: getImageFile(filename) },
    spriteConfig,
    colors,
    blendingMode,
    asSceneOverlayAnimation,
  };
};
