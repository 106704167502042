import styled from 'styled-components'

const Wrapper = styled.div<{
  width: string
  height: string
  imgFillPercentage: string
}>`
  background: #fff6;

  width: ${(props) => props.width};
  max-width: ${(props) => props.width};
  height: ${(props) => props.height};
  max-height: ${(props) => props.height};

  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: ${(props) => props.imgFillPercentage || '90%'};
    height: ${(props) => props.imgFillPercentage || '90%'};
    object-fit: contain;

    image-rendering: optimizeSpeed;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: pixelated;
    image-rendering: optimize-contrast;
    -ms-interpolation-mode: nearest-neighbor;
  }
`

export type ThumbnailProps = {
  src: any
  width?: number
  height?: number
  imgFillPercentage?: string
}

const Thumbnail = (props: ThumbnailProps) => {
  return (
    <Wrapper
      width={`${props.width || 70}px`}
      height={`${props.height || 70}px`}
      imgFillPercentage={props.imgFillPercentage}
    >
      <img src={props.src} alt={'thumbnail'} />
    </Wrapper>
  )
}

export default Thumbnail
