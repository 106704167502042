import {
  Direction,
  LayoutSceneType,
  SceneType,
  ScenesLayoutType,
} from "game-engine/types";
import { EditorActiveSceneItem, EditorType, OnSceneClickMode } from "../..";
import {
  addScenesLayoutColumn,
  addScenesLayoutRow,
  deleteScenesLayoutColumn,
  deleteScenesLayoutRow,
  extendScenesLayoutDef,
  processScenesLayout,
} from "game-engine/utils";
import styled, { css } from "styled-components";

import SCENE_CONFIGS from "game-files/scenes/SCENE_CONFIGS";
import ScenesLayout from "../../../dev-components/ScenesLayout";
import devToolsConfig from "game-engine/configs/devtools-config";

const CONNECTION_LINE_WIDTH = 4;
const CONNECTION_LINE_LENGTH = 40;

const EmptySceneItem = styled.div<{
  isActive: boolean;
  isHovered: boolean;
  hoverColor: string;
}>`
  flex: 1;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff08;
  color: ${devToolsConfig.borderColor};
  font-size: 12px;

  img {
    opacity: 0 !important;
  }

  ${(props) =>
    props.isHovered &&
    css`
      //background: ${props.hoverColor ||
      "#ffffff11"}; // takes background from styled grid item
      outline: 1px solid ${props.hoverColor || devToolsConfig.borderColor};
    `}

  ${(props) =>
    props.isActive &&
    css`
      color: #fffc;
      background: #ffffff11;
      outline: 1px solid #fff6;
    `}
`;

const Overlay = styled.div<{
  isActive: boolean;
  isHovered: boolean;
  hoverColor: string;
}>`
  position: relative;
  pointer-events: none;
  width: 100%;
  height: 100%;

  transition: all 0.1s ease;
  ${(props) =>
    !props.isHovered &&
    css`
      opacity: 0 !important;
    `}
`;

const InsertHighlight = styled.div<{
  direction: Direction;
}>`
  position: absolute;
  pointer-events: none;
  width: 100%;
  height: 100%;

  background: #444;

  --size: ${CONNECTION_LINE_LENGTH}px;
  --offset: 30px;

  ${(props) =>
    props.direction === Direction.up
      ? css`
          max-height: var(--size);
          transform: translateY(${-CONNECTION_LINE_LENGTH / 2}px);
        `
      : props.direction === Direction.down
      ? css`
          max-height: var(--size);
          transform: translateY(${CONNECTION_LINE_LENGTH / 2}px);
          bottom: 0;
        `
      : props.direction === Direction.left
      ? css`
          max-width: var(--size);
          transform: translateX(${-CONNECTION_LINE_LENGTH / 2}px);
        `
      : css`
          max-width: var(--size);
          transform: translateX(${CONNECTION_LINE_LENGTH / 2}px);
          right: 0;
        `}
`;

//
// COMPONENT
//
const EditorViewScenesLayout = (props: {
  editor: EditorType;
  setEditor: (e: EditorType) => void;
  updateScenesLayout: (options: {
    updatedScenesLayout: ScenesLayoutType;
    activeSceneItem?: EditorActiveSceneItem;
    resetOnSceneClick?: boolean;
  }) => void;
  getActiveScenesLayout: () => ScenesLayoutType;
  updateLayoutScene: (options: {
    updatedLayoutScene: LayoutSceneType;
    row: number;
    column: number;
  }) => void;
}) => {
  const {
    editor,
    setEditor,
    updateScenesLayout,
    getActiveScenesLayout,
    updateLayoutScene,
  } = props;

  //
  // HANDLERS
  //

  const onExtendScenesLayoutClick = (direction: Direction) => {
    const activeScenesLayout = getActiveScenesLayout();
    const updatedScenesLayout = extendScenesLayoutDef(
      activeScenesLayout,
      direction
    );

    let activeSceneItem = editor.activeSceneItem
      ? { ...editor.activeSceneItem }
      : undefined;
    if (activeSceneItem) {
      activeSceneItem = {
        ...activeSceneItem,
        row: activeSceneItem.row + (direction === Direction.up ? 1 : 0),
        column: activeSceneItem.column + (direction === Direction.left ? 1 : 0),
      };
    }

    updateScenesLayout({ updatedScenesLayout, activeSceneItem });
  };

  //
  // ACTIVE SCENE ITEM
  //
  const onSceneClick = (options: {
    scene: SceneType;
    row?: number;
    column?: number;
    isActive?: boolean;
  }) => {
    const { row, column, isActive } = options;

    const activeSceneItem = editor.activeSceneItem;
    const activeScenesLayout = getActiveScenesLayout();

    const moveActiveRow = (affectedRow: number, type: "add" | "delete") => {
      if (activeSceneItem) {
        const activeRow = activeSceneItem.row;
        if (
          activeRow !== undefined &&
          type === "add" &&
          activeRow > affectedRow
        ) {
          return { ...activeSceneItem, row: activeSceneItem.row + 1 };
        }
        if (
          activeRow !== undefined &&
          type === "delete" &&
          activeRow === affectedRow
        ) {
          return undefined;
        }
        if (
          activeRow !== undefined &&
          type === "delete" &&
          activeRow > affectedRow
        ) {
          return { ...activeSceneItem, row: activeSceneItem.row - 1 };
        }
      }
      return activeSceneItem;
    };

    const moveActiveColumn = (
      affectedColumn: number,
      type: "add" | "delete"
    ) => {
      if (activeSceneItem) {
        const activeColumn = activeSceneItem.column;
        if (
          activeColumn !== undefined &&
          type === "add" &&
          activeColumn > affectedColumn
        ) {
          return { ...activeSceneItem, column: activeSceneItem.column + 1 };
        }
        if (
          activeColumn !== undefined &&
          type === "delete" &&
          activeColumn === affectedColumn
        ) {
          return undefined;
        }
        if (
          activeColumn !== undefined &&
          type === "delete" &&
          activeColumn > affectedColumn
        ) {
          return { ...activeSceneItem, column: activeSceneItem.column - 1 };
        }
      }
      return activeSceneItem;
    };

    switch (editor.onSceneClick?.mode) {
      case OnSceneClickMode.select:
        return isActive
          ? setEditor({ ...editor, activeSceneItem: undefined })
          : setEditor({
              ...editor,
              activeSceneItem: {
                layoutScene: {
                  ...(getActiveScenesLayout()?.grid[row][column] || {}),
                  scene: processScenesLayout(getActiveScenesLayout())
                    ?.neighborGrid[row][column],
                },
                row: row !== undefined ? row : -1,
                column: column !== undefined ? column : -1,
              },
            });

      case OnSceneClickMode.reset:
        return updateLayoutScene({
          updatedLayoutScene: undefined,
          row,
          column,
        });

      case OnSceneClickMode.rowDelete:
        return updateScenesLayout({
          updatedScenesLayout: deleteScenesLayoutRow(activeScenesLayout, row),
          activeSceneItem: moveActiveRow(row, "delete"),
        });

      case OnSceneClickMode.rowInsertAbove:
        return updateScenesLayout({
          updatedScenesLayout: addScenesLayoutRow(activeScenesLayout, row),
          activeSceneItem: moveActiveRow(row - 1, "add"),
        });

      case OnSceneClickMode.rowInsertBelow:
        return updateScenesLayout({
          updatedScenesLayout: addScenesLayoutRow(activeScenesLayout, row + 1),
          activeSceneItem: moveActiveRow(row, "add"),
        });

      case OnSceneClickMode.columnDelete:
        return updateScenesLayout({
          updatedScenesLayout: deleteScenesLayoutColumn(
            activeScenesLayout,
            column
          ),
          activeSceneItem: moveActiveColumn(column, "delete"),
        });

      case OnSceneClickMode.columnInsertLeft:
        return updateScenesLayout({
          updatedScenesLayout: addScenesLayoutColumn(
            activeScenesLayout,
            column
          ),
          activeSceneItem: moveActiveColumn(column - 1, "add"),
        });

      case OnSceneClickMode.columnInsertRight:
        return updateScenesLayout({
          updatedScenesLayout: addScenesLayoutColumn(
            activeScenesLayout,
            column + 1
          ),
          activeSceneItem: moveActiveColumn(column, "add"),
        });
    }
  };

  //
  // SUB RENDERS
  //
  const renderEmptySceneItem = (options: {
    row: number;
    column: number;
    isActive: boolean;
    isHovered: boolean;
    hoverColor: string;
  }) => {
    return (
      <EmptySceneItem
        isActive={options.isActive}
        isHovered={options.isHovered}
        hoverColor={options.hoverColor}
      >
        <img
          src={Object.values(SCENE_CONFIGS)[0]?.preview?.src}
          alt="preview of a scene to get the same dimensions when no other scene is defined"
        />
      </EmptySceneItem>
    );
  };

  const renderOverlay = (options: {
    row: number;
    column: number;
    isActive: boolean;
    isHovered: boolean;
    hoverColor: string;
  }) => {
    let overlayDirection;

    switch (editor.onSceneClick.mode) {
      case OnSceneClickMode.rowInsertAbove:
        overlayDirection = Direction.up;
        break;
      case OnSceneClickMode.rowInsertBelow:
        overlayDirection = Direction.down;
        break;
      case OnSceneClickMode.columnInsertLeft:
        overlayDirection = Direction.left;
        break;
      case OnSceneClickMode.columnInsertRight:
        overlayDirection = Direction.right;
        break;

      default:
        break;
    }

    return overlayDirection ? (
      <Overlay
        isActive={options.isActive}
        isHovered={options.isHovered}
        hoverColor={options.hoverColor}
      >
        <InsertHighlight direction={overlayDirection} />
      </Overlay>
    ) : null;
  };

  //
  // RENDER
  //
  return (
    <ScenesLayout
      isEditable
      padding="40px"
      connectionLineLength={CONNECTION_LINE_LENGTH}
      connectionLineWidth={CONNECTION_LINE_WIDTH}
      scenesLayout={processScenesLayout(getActiveScenesLayout())?.neighborGrid}
      sceneIds={editor?.gameScenesData?.sceneIds}
      onSceneClick={onSceneClick}
      onExtendScenesLayoutClick={onExtendScenesLayoutClick}
      activeSceneId={editor.activeSceneItem?.layoutScene?.scene?.uniqueId}
      activePosition={editor.activeSceneItem}
      renderEmptySceneItem={renderEmptySceneItem}
      renderOverlay={renderOverlay}
      itemWidth={editor.sceneItemWidth}
      hover={{
        mode: editor.onSceneClick?.hoverMode,
        color: editor.onSceneClick?.hoverColor,
      }}
    />
  );
};

export default EditorViewScenesLayout;
