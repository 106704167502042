import { BlendingMode, ImageType, SpriteConfigType } from ".";

import { SceneOverlayAnimationId } from "game-files/common/ids";

export enum AnimationColorEnum {
  red = "red",
  orange = "orange",
  yellow = "yellow",
  green = "green",
  blue = "blue",
  purple = "purple",
  pink = "pink",
  white = "white",
  cyan = "cyan",
}

export type AnimationColorType = {
  colorWhite: string; // hex
  colorBlack: string; // hex
};

export type AnimationConfigType = {
  id: string;
  name: string;
  sprite: ImageType;
  spriteConfig: SpriteConfigType;
  colors?: { [color in AnimationColorEnum]?: AnimationColorType };
  asSceneOverlayAnimation?: AnimationAsSceneOverlayAnimation;
  blendingMode?: BlendingMode;
};

export type AnimationAsSceneOverlayAnimation = {
  sceneOverlayAnimationId: SceneOverlayAnimationId;
} & SceneOverlayAnimationOptions;

export type SceneOverlayAnimationOptions = {
  pause?: { durationSec: number };
  fadeOut?: { durationSec: number };
};
