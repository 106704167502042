import { useEffect, useState } from "react";

import GAME_CONFIG from "game-files/gameConfig";
import MenuButtonsLeftRight from "../menu-elements/MenuButtonsLeftRight";
import MenuInputText from "../menu-elements/MenuInputText";
import { MenuProps } from "..";
import MenuWrapper from "../menu-elements/MenuWrapper";
import { TranslatedString } from "game-engine/types";

const MenuDialogInputText = (
  props: MenuProps & {
    onSubmit?: (v: string) => void;
    title: TranslatedString;
    defaultValue?: string;
    submitLabel?: TranslatedString;
    submitWidth?: number;
    cancelLabel?: TranslatedString;
    cancelWidth?: number;
  }
) => {
  const {
    isActive,
    closeSubMenu,
    onSubmit,
    title,
    defaultValue = "",
    submitLabel = { en: "Save", cz: "Uložit" },
    submitWidth = 88,
    cancelLabel = { en: "Cancel", cz: "Zrušit" },
    cancelWidth = 88,
  } = props;

  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const canvasX = GAME_CONFIG.window.canvasDimensions.x;

  const menuWidth = canvasX - 30;
  const menuHeight = 90;

  const contentPaddingX = 20;
  const contentWidth = menuWidth - contentPaddingX * 2;

  //
  // RENDER
  //
  return (
    <MenuWrapper
      isActive={isActive}
      width={menuWidth}
      height={menuHeight}
      title={title}
      titleGap={0}
      gap={10}
      paddingTop={12}
      paddingLeft={contentPaddingX}
    >
      <MenuInputText
        listenToKeyboard={isActive}
        value={value}
        maxLength={38}
        onChange={setValue}
        onSubmit={() => onSubmit(value)}
        width={contentWidth}
      />

      <MenuButtonsLeftRight
        width={contentWidth}
        button1={{
          label: submitLabel,
          width: submitWidth,
          onClick: () => onSubmit(value),
        }}
        button2={{
          label: cancelLabel,
          width: cancelWidth,
          onClick: closeSubMenu,
        }}
      />
    </MenuWrapper>
  );
};

export default MenuDialogInputText;
