import {
  EngineConfigType,
  engineConfig_Dev_DepthMap,
  engineConfig_Dev_WalkMap,
  engineConfig_Developer,
  engineConfig_Player,
} from "game-engine/configs/engine-config";

import Box from "game-engine/_dev/basic-components/Box";
import Checkbox from "game-engine/_dev/basic-components/inputs/Checkbox";
import ColorInput from "game-engine/_dev/basic-components/inputs/ColorInput";
import Divider from "game-engine/_dev/basic-components/Divider";
import React from "react";
import useGame from "game-engine/hooks/useGame";

type SettingDivider = { isDivider: true; name?: string };
type SettingType = {
  key: string;
  name?: string;
  note?: string;
  type?: "checkbox" | "color";
  onChange?: (value: any, config: EngineConfigType) => EngineConfigType;
};

const GameEngine = (props: { isOpen?: boolean }) => {
  const { engineConfig } = useGame();

  const ignoreKeys = [
    "loggerErrorsColor",
    "loggerInfoColor",
    "loggerGraphicsColor",
    "loggerActionsColor",
    "loggerAudioColor",
  ];

  const sections: {
    section: string;
    isOpen?: boolean;
    settings: (SettingType | SettingDivider)[];
  }[] = [
    {
      section: "Logger",
      isOpen: true,
      settings: [
        { key: "loggerErrors", name: "Errors" },
        { key: "loggerInfo", name: "Info" },
        { key: "loggerGraphics", name: "Graphics" },
        { key: "loggerActions", name: "Actions" },
        { key: "loggerAudio", name: "Audio" },
      ],
    },
    {
      section: "Cursor",
      settings: [
        { key: "renderBrowserCursor", name: "Render Browser Cursor" },
        {
          key: "renderSceneCoordinatesCursor",
          name: "Coordinates - Render In Cursor",
        },
        {
          key: "copyScenePositionOnClick",
          name: "Coordinates - Copy On Click",
        },
      ],
    },
    {
      section: "Scene",
      settings: [
        { key: "hideSceneBackground", name: "Hide Background" },
        { key: "hideSceneLayers", name: "Hide Scene Layers" },
        { key: "hideSceneCharacters", name: "Hide Characters" },
        { key: "hideSceneItems", name: "Hide Items" },

        { isDivider: true, name: "Scene Layers" },
        {
          key: "renderSceneLayerDepthLine",
          name: "Scene Layer - Render Depth Line",
        },
        {
          key: "sceneLayerDepthLineColor",
          name: "Scene Layer - Depth Line Color",
          type: "color",
        },
        {
          key: "renderSceneLayerOutline",
          name: "Scene Layer - Render Outline",
          note: "only for scene layers with offset",
        },
        {
          key: "renderSceneLayerFill",
          name: "Scene Layer - Render Fill ",
          note: "only for scene layers with offset",
        },

        { isDivider: true, name: "Scene Transitions" },
        {
          key: "renderSceneWalkOutAreas",
          name: "Scene Transitions - Render",
        },
        {
          key: "sceneWalkOutAreasColor",
          name: "Scene Transitions - Color",
          type: "color",
        },
        {
          key: "renderSceneWalkOutPositions",
          name: "Scene Transitions - Walk Positions - Render",
        },
        {
          key: "sceneWalkOutPositionsColor",
          name: "Scene Transitions - Walk Positions - Color",
          type: "color",
        },

        { isDivider: true, name: "Walk Paths" },
        { key: "renderWalkPath", name: "Walk Path - Render" },
        { key: "noDiagonalPaths", name: "Walk Path - No Diagonal Paths" },
        {
          key: "walkPathColor",
          name: "Walk Path - Color",
          type: "color",
        },

        { isDivider: true, name: "Walk Map" },
        { key: "renderWalkMap", name: "Walk Map - Render" },
        { key: "ignoreWalkMap", name: "Walk Map - Ignore" },

        { isDivider: true, name: "Drop Map" },
        { key: "renderDropMap", name: "Drop Map - Render" },
        { key: "ignoreDropMap", name: "Drop Map - Ignore" },

        { isDivider: true, name: "Depth Map" },
        { key: "renderDepthMap", name: "Depth Map - Render" },
        { key: "ignoreDepthMap", name: "Depth Map - Ignore" },
      ],
    },
    {
      section: "Character",
      settings: [
        { key: "renderCharacterOrigin", name: "Render Origin" },
        { key: "renderCharacterDepthLine", name: "Render Depth Line" },
        { key: "renderCharacterOutline", name: "Render Outline" },
        { key: "renderCharacterFill", name: "Render Fill" },

        { isDivider: true, name: "Dialog Text" },
        {
          key: "renderCharacterDialogLong",
          name: "Dialog - Render Long Text",
          onChange: (value, config) =>
            value ? { ...config, renderCharacterDialogShort: false } : config,
        },
        {
          key: "renderCharacterDialogShort",
          name: "Dialog - Render Short Text",
          onChange: (value, config) =>
            value ? { ...config, renderCharacterDialogLong: false } : config,
        },
        { key: "renderCharacterDialogLimits", name: "Dialog - Render Limits" },

        { isDivider: true, name: "Colors" },
        { key: "characterOriginColor", name: "Origin", type: "color" },
        {
          key: "characterDepthLineColor",
          name: "Depth Line",
          type: "color",
        },
        {
          key: "characterOutlineColor",
          name: "Outline",
          type: "color",
        },
        { key: "characterFillColor", name: "Fill", type: "color" },
        {
          key: "characterDialogLimitIdealColor",
          name: "Dialog Limit (ideal)",
          type: "color",
        },
        {
          key: "characterDialogLimitMinColor",
          name: "Dialog Limit (min)",
          type: "color",
        },
      ],
    },
    {
      section: "Item",
      settings: [
        { key: "renderItemOrigin", name: "Render Origin" },
        { key: "renderItemDepthLine", name: "Render Depth Line" },
        { key: "renderItemOutline", name: "Render Outline" },
        { key: "renderItemFill", name: "Render Fill" },

        { isDivider: true, name: "Colors" },
        { key: "itemOriginColor", name: "Origin", type: "color" },
        {
          key: "itemDepthLineColor",
          name: "Depth Line",
          type: "color",
        },
        {
          key: "itemOutlineColor",
          name: "Outline",
          type: "color",
        },
        { key: "itemFillColor", name: "Fill", type: "color" },
      ],
    },
    {
      section: "Text",
      settings: [
        { key: "renderTextOrigin", name: "Render Origin" },
        { key: "renderTextOutline", name: "Render Outline" },
        { key: "renderTextFill", name: "Render Fill" },

        { isDivider: true, name: "Colors" },
        { key: "textOriginColor", name: "Origin", type: "color" },
        {
          key: "textOutlineColor",
          name: "Outline",
          type: "color",
        },
        { key: "textFillColor", name: "Fill", type: "color" },
      ],
    },
  ];

  const sortedKeys: string[] = sections
    .map((s) =>
      s.settings.map((st) => (st as SettingType).key).filter((key) => key)
    )
    .flat();
  const unsortedKeys = Object.keys(engineConfig.state).filter(
    (key) => !sortedKeys.includes(key) && !ignoreKeys.includes(key)
  );
  if (unsortedKeys.length) {
    sections.push({
      section: "Misc",
      settings: unsortedKeys.map((key) => {
        return { key };
      }),
    });
  }

  //
  // SUB-RENDERS
  //
  const renderDivider = (divider: SettingDivider) => {
    return <Divider name={divider.name} />;
  };

  const renderSetting = (setting: SettingType) => {
    const value = engineConfig.state[setting.key];

    let label = setting.name;
    if (!label?.length) {
      const generatedLabel = setting.key.replace(/([a-z])([A-Z])/g, "$1 $2");
      label = generatedLabel.charAt(0).toUpperCase() + generatedLabel.slice(1);
    }

    const onChange = (valueToSet: any) => {
      let updatedConfig = { ...engineConfig.state };
      if (setting.onChange) {
        updatedConfig = setting.onChange(valueToSet, updatedConfig);
      }
      updatedConfig[setting.key] = valueToSet;
      engineConfig.setState(updatedConfig);
    };

    if (setting.type === "color") {
      return (
        <ColorInput
          label={label}
          value={value}
          subLabel={setting.note}
          onChange={onChange}
        />
      );
    }

    return (
      <Checkbox
        label={label}
        subLabel={setting.note}
        value={value}
        onChange={() => onChange(!value)}
      />
    );
  };

  const renderTemplate = (options: {
    name: string;
    config: EngineConfigType;
  }) => {
    const isActive =
      JSON.stringify(options.config) === JSON.stringify(engineConfig.state);

    return (
      <Checkbox
        label={options.name}
        value={isActive}
        onChange={(v) => engineConfig.setState(options.config)}
      />
    );
  };

  //
  // RENDER
  //
  return (
    <Box
      label="Game Engine"
      childMinWidth="80%"
      isOpen={props.isOpen}
      level={1}
    >
      <Box
        renderContentOnly
        label="Config Templates"
        level={2}
        childMinWidth="60%"
        gap="2px"
        isOpen
      >
        {[
          { name: "Config - Player", config: engineConfig_Player },
          { name: "Config - Development", config: engineConfig_Developer },
          { name: "Config - Depth Map", config: engineConfig_Dev_DepthMap },
          { name: "Config - Walk Map", config: engineConfig_Dev_WalkMap },
        ].map((p, i) => (
          <React.Fragment key={i}>{renderTemplate(p)}</React.Fragment>
        ))}
      </Box>

      {sections.map((s, i) => {
        return (
          <Box
            key={i}
            label={s.section}
            level={2}
            childMinWidth="60%"
            gap="2px"
            isOpen={s.isOpen}
          >
            {s.settings.map((item, i) => {
              let render;
              if ((item as SettingDivider).isDivider) {
                render = () => renderDivider(item as SettingDivider);
              } else {
                render = () => renderSetting(item as SettingType);
              }
              return <React.Fragment key={i}>{render()}</React.Fragment>;
            })}
          </Box>
        );
      })}
    </Box>
  );
};

export default GameEngine;
