import SpriteEditor from "game-engine/_dev/sprite-editor";
import devToolsConfig from "game-engine/configs/devtools-config";
import styled from "styled-components";

const Wrapper = styled.div`
  flex: 1;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  width: 100%;
  background: ${devToolsConfig.background};
`;

const TabSpriteEditor = () => {
  //
  // RENDER
  //
  return (
    <Wrapper>
      <SpriteEditor />
    </Wrapper>
  );
};

export default TabSpriteEditor;
