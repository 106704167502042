/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from "react";

import FileInput from "game-engine/_dev/basic-components/inputs/FileInput";
import { SpriteDirection } from "game-engine/types";
import { SpriteEditorType } from "../..";
import { getImageSize } from "game-engine/_dev/scene-editor-create-new/utils/files";
import { removeIndexFromFilename } from "game-engine/utils";
import styled from "styled-components";

const Wrapper = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
`;

const Warning = styled.div`
  color: #ff6b6b99;
`;

const InputFilesDescription = styled.div`
  padding: 10px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

export type ImageFileType = {
  src: string;
  file: any;
  imgWidth: number;
  imgHeight: number;
};

//
// COMPONENT
//
const SectionUploadImages = (props: {
  setSpriteEditor: (e: SpriteEditorType) => void;
}) => {
  const { setSpriteEditor } = props;

  const [filesWarning, setFilesWarning] = useState<string>();

  //
  // DATA - MULTIPLE IMAGES (SPRITE FRAMES)
  //
  const [imageFiles, setImageFiles] = useState<ImageFileType[]>();

  const onImagesLoaded = async (files: any[]) => {
    const processedFiles = await Promise.all(
      files
        .sort((a, b) => (a.name < b.name ? -1 : 1))
        .map(async (file) => {
          const { width, height } = await getImageSize(file);
          return {
            src: file ? URL.createObjectURL(file) : undefined,
            file: file,
            imgWidth: width,
            imgHeight: height,
          };
        })
    );
    setImageFiles(processedFiles);
  };

  useEffect(() => {
    if (imageFiles?.length) {
      setFilesWarning(undefined);

      const spriteEditor: SpriteEditorType = {
        frameDurationMilliseconds: undefined,

        frameSequence: [],

        uploadedSpriteImage: undefined,

        frames: imageFiles.map((imageFile) => {
          return {
            src: imageFile.src,
            file: imageFile.file,
            width: imageFile.imgWidth,
            height: imageFile.imgHeight,
          };
        }),

        calculated: {
          filename: removeIndexFromFilename(imageFiles[0].file.name),
          spriteDirection: undefined,
          frameWidth: undefined,
          frameHeight: undefined,
          frameCount: imageFiles.length,
          spriteUrl: undefined,
        },
      };

      setSpriteEditor(spriteEditor);
    }
  }, [imageFiles]);

  //
  // DATA - SINGLE IMAGE (SPRITE SHEET)
  //
  const [spriteImageFile, setSpriteImageFile] = useState<ImageFileType>();

  const onSpriteImageLoaded = async (file: any) => {
    const { width, height } = await getImageSize(file);
    setSpriteImageFile({
      src: file ? URL.createObjectURL(file) : undefined,
      file: file,
      imgWidth: width,
      imgHeight: height,
    });
  };

  useEffect(() => {
    if (spriteImageFile) {
      setFilesWarning(undefined);

      const spriteEditor: SpriteEditorType = {
        frameDurationMilliseconds: undefined,

        frameSequence: [],

        uploadedSpriteImage: {
          src: spriteImageFile.src,
          file: spriteImageFile.file,
          width: spriteImageFile.imgWidth,
          height: spriteImageFile.imgHeight,
        },

        frames: undefined,

        calculated: {
          filename: removeIndexFromFilename(spriteImageFile.file.name),
          spriteDirection:
            spriteImageFile.imgWidth > spriteImageFile.imgHeight
              ? SpriteDirection.Horizontal
              : SpriteDirection.Vertical,
          frameWidth: spriteImageFile.imgWidth,
          frameHeight: spriteImageFile.imgHeight,
          frameCount: 1,
          spriteUrl: spriteImageFile.src,
        },
      };

      setSpriteEditor(spriteEditor);
    }
  }, [spriteImageFile]);

  //
  // RENDER
  //
  return (
    <Wrapper>
      <Section>
        <FileInput onFilesLoaded={onImagesLoaded}>
          <InputFilesDescription>Upload Sprite Frames</InputFilesDescription>
        </FileInput>

        {/* <Tutorial
          items={[
            "Upload image frame sequence to create optimized sprite image.",
            "Specify sprite settings",
            "Export sprite image and spriteConfig",
          ]}
        /> */}

        {filesWarning && <Warning>{filesWarning}</Warning>}
      </Section>

      <Section>
        <FileInput onFileLoaded={onSpriteImageLoaded}>
          <InputFilesDescription>Upload Sprite Sheet</InputFilesDescription>
        </FileInput>

        {filesWarning && <Warning>{filesWarning}</Warning>}
      </Section>
    </Wrapper>
  );
};

export default SectionUploadImages;
