import {
  CharacterRenderType,
  Direction,
  SpriteDirection,
} from "game-engine/types";

import IMG_animation_deathChoke from "./animation-death-choke.png";
import IMG_animation_kick from "./animation-kick.png";
import IMG_animation_shrug from "./animation-shrug.png";
import IMG_animation_stretch from "./animation-stretch.png";
import IMG_animation_yawn from "./animation-yawn.png";
import IMG_default from "./default.png";
import IMG_talk_body from "./talk-body.png";
import IMG_talk_head from "./talk-head.png";
import IMG_walk_down from "./walk-down.png";
import IMG_walk_left from "./walk-left.png";
import IMG_walk_up from "./walk-up.png";

export const AnimationId_Default = {
  kick: "kick",
  yawn: "yawn",
  stretch: "stretch",
  shrug: "shrug",
  deathChoke: "deathChoke",
};

const RenderDefault: CharacterRenderType = {
  facing: Direction.left,
  offsetX: 0,
  offsetY: 1,

  filters: {
    skin: {
      color: "#BF554C",
      threshold: 60,
    },
    poison: {
      color: "#2bff71",
      amount: 1,
      brightness: 1.1,
    },
  },

  default: {
    image: { src: IMG_default },
    offsetX: 0,
    offsetY: 0,
    width: 21,
    height: 58,
  },

  talk: {
    offsetX: 0,
    offsetY: 11,
    headOffsetX: -1,
    headOffsetY: -57,
    bodyImage: { src: IMG_talk_body },
    headSprite: { src: IMG_talk_head },
    headSpriteConfig: {
      direction: SpriteDirection.Horizontal,
      frameWidth: 13,
      frameHeight: 13,
      frameCount: 12,
    },
  },

  walkLeft: {
    offsetX: -1,
    offsetY: 0,
    sprite: { src: IMG_walk_left },
    spriteConfig: {
      direction: SpriteDirection.Horizontal,
      frameWidth: 25,
      frameHeight: 59,
      frameCount: 8,
    },
    pixelsWalked: 32, // how many pixels the character travels in one loop
  },

  walkUp: {
    offsetX: 0,
    offsetY: 2,
    sprite: { src: IMG_walk_up },
    spriteConfig: {
      direction: SpriteDirection.Horizontal,
      frameWidth: 24,
      frameHeight: 59,
      frameCount: 6,
    },
    pixelsWalked: 18, // how many pixels the character travels in one loop
  },

  walkDown: {
    offsetX: 0,
    offsetY: 1,
    sprite: { src: IMG_walk_down },
    spriteConfig: {
      direction: SpriteDirection.Horizontal,
      frameWidth: 22,
      frameHeight: 59,
      frameCount: 6,
    },
    pixelsWalked: 18, // how many pixels the character travels in one loop
  },

  animations: [
    {
      animationId: AnimationId_Default.kick,
      isIdle: true,
      offsetX: 1,
      offsetY: 1,
      sprite: { src: IMG_animation_kick },
      spriteConfig: {
        direction: SpriteDirection.Horizontal,
        frameWidth: 25,
        frameHeight: 59,
        frameCount: 9,
      },
    },

    {
      animationId: AnimationId_Default.yawn,
      isIdle: true,
      offsetX: -2,
      offsetY: 0,
      sprite: { src: IMG_animation_yawn },
      spriteConfig: {
        direction: SpriteDirection.Horizontal,
        frameWidth: 26,
        frameHeight: 59,
        frameCount: 20,
      },
    },

    {
      animationId: AnimationId_Default.stretch,
      isIdle: true,
      offsetX: 2,
      offsetY: 0,
      sprite: { src: IMG_animation_stretch },
      spriteConfig: {
        direction: SpriteDirection.Horizontal,
        frameWidth: 41,
        frameHeight: 59,
        frameCount: 15,
      },
    },

    {
      animationId: AnimationId_Default.shrug,
      isIdle: false,
      offsetX: -5,
      offsetY: 0,
      sprite: { src: IMG_animation_shrug },
      spriteConfig: {
        direction: SpriteDirection.Horizontal,
        frameWidth: 39,
        frameHeight: 58,
        frameCount: 20,
      },
    },

    {
      animationId: AnimationId_Default.deathChoke,
      isIdle: false,
      offsetX: -22,
      offsetY: 9,
      sprite: { src: IMG_animation_deathChoke },
      spriteConfig: {
        direction: SpriteDirection.Horizontal,
        frameWidth: 65,
        frameHeight: 67,
        frameCount: 57,
        frameDurationMilliseconds: 80,
      },
    },
  ],
};

export default RenderDefault;
