import { SceneOverlayAnimationId, SkillId } from "game-files/common/ids";

import { ACTION } from "game-engine/utils";
import GAME_CONFIG from "game-files/gameConfig";
import { GameObjective } from "game-files/gameObjectives";
import { SkillType } from "game-engine/types";

const Frost: SkillType = {
  id: SkillId.Frost,

  name: { en: "Scroll of Frost", cz: "Svitek mrazu" },

  guiSkillSlot: undefined, // TODO

  states: {
    [GameObjective.GAME_START]: {
      state_start: {
        getActions: ({ gamePlay, gameItems }) => [
          // TODO - ADD ACTION.characterAnimation FOR READING A SKILL SCROLL
          ACTION.talk({
            dialog: { en: "Frost started", cz: "Mráz začíná" },
          }),

          ACTION.playSceneOverlayAnimation(SceneOverlayAnimationId.frost),
        ],
      },

      state_active: {
        duration: undefined,
      },

      state_stop: {
        getActions: ({ gamePlay, gameItems }) => [
          ACTION.talk({
            dialog: { en: "Frost stopped", cz: "Mráz končí" },
          }),
        ],
      },

      state_waitForReset: {
        duration: GAME_CONFIG.skills.resetAfter,
      },
    },
  },
};

export default Frost;
