import {
  CharacterRenderByCurrentObjective,
  CharacterRenderType,
} from "game-engine/types";

import { GameEventsType } from "game-files/gameEvents";
import { GameObjective } from "game-files/gameObjectives";
import { getDataByCurrentObjective } from "./getters";

export const getCharacterRenderByCurrentObjective = (props: {
  dataByCurrentObjective: CharacterRenderByCurrentObjective;
  currentObjective: GameObjective;
  events: GameEventsType;
}) => {
  return getDataByCurrentObjective<CharacterRenderType>(
    { ...props, aggregateConditionals: true },
    {} as any
  );
};
