import AssetPreviewCursor from "game-engine/_dev/basic-components/AssetPreviewCursor";
import Box from "game-engine/_dev/basic-components/Box";
import CursorGlyphs from "game-engine/glyphs/Cursor";
import styled from "styled-components";

const Glyphs = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

const AssetsCursor = (props: { isOpen?: boolean }) => {
  const cursorWidth = 28;
  const cursorHeight = 24;
  const cursorScale = 4;

  //
  // RENDER
  //
  return (
    <Box
      label="Cursor"
      useFlex
      childMinWidth="40%"
      isOpen={props.isOpen}
      level={1}
    >
      <Glyphs>
        {Object.entries(CursorGlyphs).map(([key, cursorGlyph]) => (
          <Box
            key={key}
            label={key}
            level={2}
            isOpen
            childMinWidth="50px"
            gap="0px"
            contentPaddingTop="-10px"
            contentPaddingBottom="8px"
          >
            <AssetPreviewCursor
              cursorGlyph={cursorGlyph}
              width={cursorWidth}
              height={cursorHeight}
              scale={cursorScale}
            />
          </Box>
        ))}
      </Glyphs>
    </Box>
  );
};

export default AssetsCursor;
