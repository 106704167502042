import { useEffect, useState } from "react";

import GAME_CONFIG from "game-files/gameConfig";
import { GameEffectFadeEnum } from "game-engine/types";
import SceneFill from "game-engine/components/game-elements/SceneFill";
import Transition from "game-engine/components/basic-elements/Transition";
import { TransitionFadeAnimation } from "game-engine/components/basic-elements/Transition/types/fade";
import useGame from "game-engine/hooks/useGame";

const SceneEffectFade = (props: {
  sceneLoaded?: boolean;
  isActiveOnInit?: boolean;
}) => {
  const { sceneLoaded, isActiveOnInit } = props;
  const { gamePlay } = useGame();

  const [fade, setFade] = useState<TransitionFadeAnimation>({
    from: isActiveOnInit ? 1 : 0,
  });

  useEffect(() => {
    if (sceneLoaded && gamePlay.state?.effects?.fadeScene) {
      const from =
        gamePlay.state?.effects?.fadeScene?.type === GameEffectFadeEnum.fadeIn
          ? 1
          : 0;
      const to = 1 - from;

      setFade({
        from,
        to,
        durationSec: gamePlay.state?.effects?.fadeScene.durationSec,
      });
    }
  }, [gamePlay.state?.effects?.fadeScene, sceneLoaded]);

  return (
    <Transition fade={fade}>
      <SceneFill color={GAME_CONFIG.cinematics.fadeFillColor} />
    </Transition>
  );
};

export default SceneEffectFade;
