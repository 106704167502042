import { SceneNameByCurrentObjective, SceneNameType } from "game-engine/types";

import { GameEventsType } from "game-files/gameEvents";
import { GameObjective } from "game-files/gameObjectives";
import { getDataByCurrentObjective } from "./getters";

export const getSceneNameByCurrentObjective = (props: {
  dataByCurrentObjective: SceneNameByCurrentObjective;
  currentObjective: GameObjective;
  events: GameEventsType;
}) => {
  return getDataByCurrentObjective<SceneNameType>(props, {
    en: undefined,
    cz: undefined,
  });
};
