/* eslint-disable react-hooks/exhaustive-deps */

import { Group, Rect } from "react-konva";
import { useEffect, useRef } from "react";

import GAME_CONFIG from "game-files/gameConfig";
import Text from "game-engine/components/basic-elements/Text";
import { TextAlign } from "game-engine/types";

export const MENU_INPUT_TEXT_HEIGHT = 17;

export type MenuInputTextProps = {
  value: string;
  maxLength?: number;
  onChange?: (v: string) => void;
  onSubmit?: () => void;
  width: number;
  height?: number;
  listenToKeyboard: boolean;
  x?: number;
  y?: number;
  paddingX?: number;
  paddingY?: number;
  centered?: boolean;
  centeredText?: boolean;
  opacity?: number;
};

const MenuInputText = (props: MenuInputTextProps) => {
  const {
    value,
    maxLength = 100,
    onChange = (v) => {
      console.info("input changed", v);
    },
    onSubmit = () => {
      console.info("input submitted");
    },
    listenToKeyboard = false,
    x = 0,
    y = 0,
    width,
    height = MENU_INPUT_TEXT_HEIGHT,
    centered,
    centeredText = false,
    opacity,
  } = props;

  const paddingX = props.paddingX !== undefined ? props.paddingX : 4;
  const paddingY = props.paddingY !== undefined ? props.paddingY : 2;

  const textColor = GAME_CONFIG.menu.colorText;
  const backgroundColor = GAME_CONFIG.menu.colorShadow;

  const textX = paddingX;
  const textY = paddingY;
  const textWidth = width - textX - 2;

  // Ref to hold the current value
  const valueRef = useRef(value);

  // Update the ref when value changes
  useEffect(() => {
    valueRef.current = value;
  }, [value]);

  const handleKeyDown = (e: KeyboardEvent) => {
    if (!listenToKeyboard) return;

    const currentValue = valueRef.current;

    switch (e.key) {
      case "Backspace":
        onChange(currentValue.slice(0, -1));
        break;
      case "Enter":
        e.preventDefault(); // Prevent default behavior of Enter key if needed
        if (onSubmit) {
          onSubmit();
        }
        break;
      default:
        if (currentValue.length < maxLength && e.key.length === 1) {
          onChange(currentValue + e.key);
        }
        break;
    }
  };

  useEffect(() => {
    if (listenToKeyboard) {
      window.addEventListener("keydown", handleKeyDown);
    }
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [listenToKeyboard]);

  return (
    <Group x={centered ? x - Math.round(width / 2) : x} y={y} opacity={opacity}>
      <Rect width={width} height={height} fill={backgroundColor} />
      <Text
        text={`${value}␃`}
        position={{
          x: centeredText ? Math.round(textWidth / 2) : textX,
          y: textY,
        }}
        minWidth={textWidth}
        maxWidth={textWidth}
        outlined={false}
        shadow={true}
        color={textColor}
        align={centeredText ? TextAlign.center : TextAlign.left}
      />
    </Group>
  );
};

export default MenuInputText;
