import { ACTION } from "game-engine/utils";
import GAME_CONFIG from "game-files/gameConfig";
import { GameObjective } from "game-files/gameObjectives";
import IMAGE_Layer_00_DEPTH_MAP from "./assets/layer - 00 - DEPTH MAP.png";
import IMAGE_Layer_00_DROP_MAP from "./assets/layer - 00 - DROP MAP.png";
import IMAGE_Layer_00_WALK_MAP from "./assets/layer - 00 - WALK MAP.png";
import IMAGE_Layer_01_Background from "./assets/layer - 01 - background.png";
import IMAGE_Layer_02_Background_Assets from "./assets/layer - 02 - background - assets.png";
import IMAGE_Layer_03_Bush from "./assets/layer - 03 - bush.png";
import IMAGE_Layer_04_Tree_Left from "./assets/layer - 04 - tree left.png";
import IMAGE_Layer_05_Foreground from "./assets/layer - 05 - foreground.png";
import IMAGE_Preview from "./assets/preview.png";
import MUSIC_CONFIGS from "game-files/audio/MUSIC_CONFIGS";
import SHARED_DATA from "game-files/scenes/SHARED_DATA";
import { SceneTag } from "game-files/common/ids";
import { SceneType } from "game-engine/types";

const Test_Night_Forest_L_R_U_D: SceneType = {
  configId: "Test_Night_Forest_L_R_U_D", // --------- KEEP SAME AS COMPONENT NAME
  isDev: false, // ----------------- DEV SCENES ARE HIDDEN IN SCENES LAYOUT EDITOR
  isUsingTestObjectives: false, // - OBJECTIVES WILL BE DISPLAYED UNDER TEST NAMES IN DEV TOOLS
  sceneNeighbors: undefined, // ---- GENERATED FROM SCENES LAYOUT
  uniqueId: undefined, // ---------- GENERATED FROM SCENES LAYOUT (put manual id here for scenes out of scenes layout)
  preview: { src: IMAGE_Preview },

  tags: [SceneTag.Test],

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //
  // Scene Name
  //
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  name: {
    [GameObjective.GAME_START]: {
      en: "Dark Forest",
      cz: "Temný les",
    },
  },

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //
  // Scene Audio
  //
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  audio: {
    [GameObjective.GAME_START]: {
      music: {
        musicConfigId: MUSIC_CONFIGS.Test_02.id,
        volume: undefined,
      },
      sound: {
        soundConfigId: undefined,
        volume: undefined,
        loop: undefined,
      },
    },
  },

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //
  // Scene Walk Paths
  //
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  sceneWalkPaths: {
    [GameObjective.GAME_START]: {
      left: {
        edgeWalkY: 117,
        walkTo: { x: 37, y: 117 },
      },
      right: {
        edgeWalkY: 117,
        walkTo: { x: 273, y: 117 },
      },
      up: {
        edgeWalkX: 167,
        walkTo: { x: 160, y: 40 },
      },
      down: {
        edgeWalkX: 189,
        walkTo: { x: 189, y: 135 },
      },
    },
  },

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //
  // Dynamic Lighting
  //
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  dynamicLighting: {
    [GameObjective.GAME_START]: {
      deathByDarkness: true,
      vignette: GAME_CONFIG.scene.dynamicLighting.vignette,
      filters: GAME_CONFIG.scene.dynamicLighting.filters,
    },
  },

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //
  // SCENE IMAGES
  //
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  images: {
    background: {
      /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      //
      // Scene Background
      //
      /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

      dataByCurrentObjective: {
        [GameObjective.GAME_START]: {
          fillColor: undefined,
          image: {
            src: IMAGE_Layer_01_Background,
            transition: undefined,
          },
          dropMap: { src: IMAGE_Layer_00_DROP_MAP },
          walkMap: { src: IMAGE_Layer_00_WALK_MAP },
          depthMap: { src: IMAGE_Layer_00_DEPTH_MAP },
          depthSettings: {
            scaleMin: undefined,
            scaleMax: undefined,
          },
        },
      },

      actionsByCurrentObjective: {
        [GameObjective.GAME_START]: {
          actionsWithItem: undefined,
          actions: [
            ACTION.talkRandom({
              dialogOptions: [...SHARED_DATA.dialogOptions.forestBackground],
            }),
          ],
        },
      },
    },

    layers: [
      /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      //
      // Scene Layer
      //
      /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

      {
        id: "IMAGE_Layer_02_Background_Assets",
        depthY: 38,
        dataByCurrentObjective: {
          [GameObjective.GAME_START]: {
            image: { src: IMAGE_Layer_02_Background_Assets },
            spriteConfig: undefined,
            ignoreOnClick: false,
            isInvisible: false,
            cursorOnHover: undefined,
            offset: undefined,
          },
        },
        actionsByCurrentObjective: {
          [GameObjective.GAME_START]: {
            actionsWithItem: undefined,
            actions: [
              ACTION.talkRandom({
                dialogOptions: [...SHARED_DATA.dialogOptions.forest],
              }),
            ],
          },
        },
      },

      /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      //
      // Scene Layer
      //
      /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

      {
        id: "IMAGE_Layer_03_Bush",
        depthY: 89,
        dataByCurrentObjective: {
          [GameObjective.GAME_START]: {
            image: { src: IMAGE_Layer_03_Bush },
            spriteConfig: undefined,
            ignoreOnClick: false,
            isInvisible: false,
            cursorOnHover: undefined,
            offset: undefined,
          },
        },
        actionsByCurrentObjective: {
          [GameObjective.GAME_START]: {
            actionsWithItem: undefined,
            actions: [
              ACTION.talkRandom({
                dialogOptions: [...SHARED_DATA.dialogOptions.bush],
              }),
            ],
          },
        },
      },

      /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      //
      // Scene Layer
      //
      /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

      {
        id: "IMAGE_Layer_04_Tree_Left",
        depthY: 88,
        dataByCurrentObjective: {
          [GameObjective.GAME_START]: {
            image: { src: IMAGE_Layer_04_Tree_Left },
            spriteConfig: undefined,
            ignoreOnClick: false,
            isInvisible: false,
            cursorOnHover: undefined,
            offset: undefined,
          },
        },
        actionsByCurrentObjective: {
          [GameObjective.GAME_START]: {
            actionsWithItem: undefined,
            actions: [
              ACTION.talkRandom({
                dialogOptions: [
                  ...SHARED_DATA.dialogOptions.tree,
                  ...SHARED_DATA.dialogOptions.treeOld,
                ],
              }),
            ],
          },
        },
      },

      /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      //
      // Scene Layer
      //
      /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

      {
        id: "IMAGE_Layer_05_Foreground",
        depthY: 1000,
        dataByCurrentObjective: {
          [GameObjective.GAME_START]: {
            image: { src: IMAGE_Layer_05_Foreground },
            spriteConfig: undefined,
            ignoreOnClick: false,
            isInvisible: false,
            cursorOnHover: undefined,
            offset: undefined,
          },
        },
        actionsByCurrentObjective: {
          [GameObjective.GAME_START]: {
            actionsWithItem: undefined,
            actions: [
              ACTION.talkRandom({
                dialogOptions: [...SHARED_DATA.dialogOptions.tree],
              }),
            ],
          },
        },
      },
    ],
  },

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //
  // SCENE ACTIONS
  //
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  onSceneIdle: {
    // idleAfterMinSec: 1,
    // idleAfterMaxSec: 2,
    actionsByCurrentObjective: {
      [GameObjective.GAME_START]: {
        // IDLE ACTIONS ARE NOT EXECUTED AS A SEQUENCE, BUT PLACED INTO A POOL OF
        // ACTIONS TO BE RANDOMLY PICKED FROM AMONG OTHER IDLES (CHARACTER IDLE ETC.)
        actions: [],
      },
    },
  },

  onSceneInit: {
    actionsByCurrentObjective: {
      [GameObjective.GAME_START]: {
        // ACTIONS TO BE RUN IN SEQUENCE AFTER SCENE IS INITIALIZED
        actions: [],
      },
    },
  },

  onSkillStart: {},

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //
  // SCENE CHARACTERS
  //
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  characters: [],
};

export default Test_Night_Forest_L_R_U_D;
