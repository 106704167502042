import {
  AnimationFrameSequence,
  AnimationFrameSequenceItem,
  FrameSequenceValue,
} from "game-engine/types";
import { useEffect, useState } from "react";

import Dropdown from "game-engine/_dev/basic-components/inputs/Dropdown";
import NumberInput from "game-engine/_dev/basic-components/inputs/NumberInput";
import PositionInput from "game-engine/_dev/basic-components/inputs/PositionInput";
import ShapePlus from "game-engine/_dev/basic-components/ShapePlus";
import { ReactComponent as TrashIcon } from "assets/icons/trash.svg";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const FrameSequenceItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 10px;
`;

const FrameSequenceItem = styled.div`
  display: flex;
  //flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  padding-bottom: 8px;
  border-bottom: 1px solid #fff1;
  margin-left: 30px;
`;

const FrameSequenceItemAddNew = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 14px;
  margin-left: 3px;

  color: #fff5;

  &:hover {
    color: #fff8;
  }
`;

const ItemIndex = styled.div`
  font-size: 14px;
  font-weight: 600;
  opacity: 0.5;
  margin-top: 13px;
  margin-left: -22px;
`;

const ItemOptions = styled.div`
  width: 250px;
  display: flex;
  align-items: center;

  input {
    padding: 3px 0px;
  }
`;

const DropdownWrapper = styled.div`
  flex: 1;
`;

const ItemButtons = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
`;

const Icon = styled.div`
  --size: 26px;

  width: var(--size);
  min-width: var(--size);
  max-width: var(--size);
  height: var(--size);
  min-height: var(--size);
  max-height: var(--size);

  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 60%;
    fill: #fff5;
  }

  &:hover {
    svg {
      fill: #fff8;
    }
  }
`;

const Arrows = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff5;

  & > div {
    width: 26px;
    height: 20px;
    font-size: 12px;

    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      color: #fff8;
    }
  }
`;

type FrameSequenceMetaItem = {
  id: "frame" | "pause" | "from-to" | FrameSequenceValue;
  item: AnimationFrameSequenceItem;
};

const convertFrameSequenceToMeta = (frameSequence: AnimationFrameSequence) => {
  const meta: FrameSequenceMetaItem[] = (frameSequence || []).map(
    (frameSequenceItem) => {
      if (typeof frameSequenceItem === "number") {
        return { id: "frame", item: frameSequenceItem };
      }
      if (typeof (frameSequenceItem as any).pause === "number") {
        return { id: "pause", item: frameSequenceItem };
      }
      if (
        typeof (frameSequenceItem as any).from === "number" &&
        typeof (frameSequenceItem as any).to === "number"
      ) {
        return { id: "from-to", item: frameSequenceItem };
      }
      return {
        id: frameSequenceItem as FrameSequenceValue,
        item: frameSequenceItem,
      };
    }
  );
  return meta;
};

const FrameSequenceEditor = (props: {
  frameCount: number;
  frameSequence: AnimationFrameSequence;
  setFrameSequence: (frameSequence: AnimationFrameSequence) => void;
}) => {
  const { frameSequence, setFrameSequence, frameCount } = props;

  const [frameSequenceMeta, setFrameSequenceMeta] = useState<
    FrameSequenceMetaItem[]
  >(convertFrameSequenceToMeta(frameSequence));

  useEffect(() => {
    setFrameSequenceMeta(convertFrameSequenceToMeta(frameSequence));
  }, [frameSequence]);

  const prefixWidth = "40px";

  const renderFrameSequenceValue = (
    frameSequenceMetaItem: FrameSequenceMetaItem,
    index: number
  ) => {
    // SEQUENCE ITEM - FRAME INDEX
    switch (frameSequenceMetaItem.id) {
      case "frame":
        return (
          <ItemOptions>
            <NumberInput
              prefix="frame"
              prefixWidth={prefixWidth}
              value={frameSequenceMetaItem.item as number}
              onChange={(v) => {
                const updatedFrameSequence = [...frameSequence];
                updatedFrameSequence[index] = v;
                setFrameSequence(updatedFrameSequence);
              }}
              rangeController={{ min: 0, max: frameCount, step: 1 }}
              noBorder
            />
          </ItemOptions>
        );

      case "pause":
        return (
          <ItemOptions>
            <NumberInput
              prefix="pause"
              prefixWidth={prefixWidth}
              value={(frameSequenceMetaItem.item as any).pause}
              onChange={(v) => {
                const updatedFrameSequence = [...frameSequence];
                updatedFrameSequence[index] = { pause: v };
                setFrameSequence(updatedFrameSequence);
              }}
              rangeController={{ min: 0, max: 60, step: 1 }}
              noBorder
            />
          </ItemOptions>
        );

      case "from-to":
        return (
          <ItemOptions>
            <PositionInput
              prefixes={{ x: "from", y: "to" }}
              value={{
                x: (frameSequenceMetaItem.item as any).from,
                y: (frameSequenceMetaItem.item as any).to,
              }}
              onChange={(v) => {
                const updatedFrameSequence = [...frameSequence];
                updatedFrameSequence[index] = { from: v.x, to: v.y };
                setFrameSequence(updatedFrameSequence);
              }}
              rangeControllerX={{ min: 0, max: frameCount, step: 1 }}
              rangeControllerY={{ min: 0, max: frameCount, step: 1 }}
              noBorder
            />
          </ItemOptions>
        );

      default:
        return null;
    }
  };

  return (
    <Wrapper>
      {frameSequence?.length ? (
        <FrameSequenceItems>
          {frameSequenceMeta?.map((frameSequenceMetaItem, index) => (
            <FrameSequenceItem key={index}>
              <ItemIndex>{index}.</ItemIndex>

              <DropdownWrapper>
                <Dropdown
                  showCaret
                  selectedId={frameSequenceMetaItem.id}
                  items={[
                    "frame",
                    "pause",
                    "from-to",
                    ...Object.keys(FrameSequenceValue),
                  ].map((key) => {
                    return {
                      id: FrameSequenceValue[key as any] || key,
                      data: key,
                      label: key,
                    };
                  })}
                  onChange={(key) => {
                    const updatedFrameSequence = [...frameSequence];
                    if (key === "frame") {
                      updatedFrameSequence[index] = 0;
                    } else if (key === "pause") {
                      updatedFrameSequence[index] = { pause: 10 };
                    } else if (key === "from-to") {
                      updatedFrameSequence[index] = {
                        from: 0,
                        to: frameCount,
                      };
                    } else {
                      updatedFrameSequence[index] = FrameSequenceValue[key];
                    }
                    setFrameSequence(updatedFrameSequence);
                  }}
                />
              </DropdownWrapper>

              {renderFrameSequenceValue(frameSequenceMetaItem, index)}

              <ItemButtons>
                <Arrows>
                  <div
                    onClick={() => {
                      if (index > 0) {
                        const updatedSequence = [...frameSequence];
                        updatedSequence[index - 1] = frameSequence[index];
                        updatedSequence[index] = frameSequence[index - 1];
                        setFrameSequence(updatedSequence);
                      }
                    }}
                  >
                    {`▲`}
                  </div>

                  <div
                    onClick={() => {
                      if (index < frameSequence.length - 1) {
                        const updatedSequence = [...frameSequence];
                        updatedSequence[index + 1] = frameSequence[index];
                        updatedSequence[index] = frameSequence[index + 1];
                        setFrameSequence(updatedSequence);
                      }
                    }}
                  >
                    {`▼`}
                  </div>
                </Arrows>

                <Icon>
                  <TrashIcon
                    onClick={() => {
                      setFrameSequence(
                        frameSequence.filter((fs, i) => i !== index)
                      );
                    }}
                  />
                </Icon>
              </ItemButtons>
            </FrameSequenceItem>
          ))}
        </FrameSequenceItems>
      ) : null}

      <FrameSequenceItemAddNew
        onClick={() =>
          setFrameSequence([
            ...(frameSequence || []),
            FrameSequenceValue.original,
          ])
        }
      >
        <ShapePlus color="#fff5" lineLength="14px" />
        add frame sequence item
      </FrameSequenceItemAddNew>
    </Wrapper>
  );
};

export default FrameSequenceEditor;
