/* eslint-disable react-hooks/exhaustive-deps */

import { Direction, SceneType, ScenesLayoutLinkType } from "game-engine/types";
import { SceneRef, SelectedArea } from ".";
import { useEffect, useRef } from "react";

import { EditorType } from "../..";
import NeighborArea from "./NeighborArea";
import styled from "styled-components";

const Overlay = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  min-width: 100%;
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const OverlayResize = styled.div<{ connectionLineLength: number }>`
  position: relative;
  width: calc(100% - ${(props) => props.connectionLineLength}px - 8px);
  height: calc(100% - ${(props) => props.connectionLineLength}px - 8px);
`;

//
// COMPONENT
//

const SceneOverlay = (props: {
  editor?: EditorType;
  setEditor?: (e: EditorType) => void;
  scene?: SceneType;
  selectedAreas?: SelectedArea[];
  setSelectedAreas?: (a: SelectedArea[]) => void;
  connectionLineLength: number;
  storeSceneRef?: (r: SceneRef) => void;
  sceneLinks: ScenesLayoutLinkType[];
  onSceneLinkClick?: (options: { neighborId: string }) => void;
}) => {
  const {
    editor,
    setEditor,
    scene,
    selectedAreas,
    setSelectedAreas,
    connectionLineLength,
    storeSceneRef,
    sceneLinks,
    onSceneLinkClick,
  } = props;

  const ref = useRef();

  useEffect(() => {
    if (ref?.current && storeSceneRef) {
      storeSceneRef({ sceneId: scene.uniqueId, ref });
    }
  }, []);

  if (!scene) {
    return null;
  }

  return (
    <Overlay ref={ref}>
      <OverlayResize connectionLineLength={connectionLineLength}>
        {Object.values(Direction).map((d) => (
          <NeighborArea
            key={d}
            editor={editor}
            setEditor={setEditor}
            scene={scene}
            direction={d}
            selectedAreas={selectedAreas}
            setSelectedAreas={setSelectedAreas}
            connectionLineLength={connectionLineLength}
            sceneLinks={sceneLinks}
            onSceneLinkClick={onSceneLinkClick}
          />
        ))}
      </OverlayResize>
    </Overlay>
  );
};

export default SceneOverlay;
