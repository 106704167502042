import { MENU_IDS, MenuProps } from "..";

import GAME_CONFIG from "game-files/gameConfig";
import { Group } from "react-konva";
import LayoutColumn from "game-engine/components/basic-elements/LayoutColumn";
import MenuButton from "../menu-elements/MenuButton";
import MenuScrollable from "../menu-elements/MenuScrollable";
import MenuSpacer from "../menu-elements/MenuSpacer";
import MenuWrapper from "../menu-elements/MenuWrapper";
import { SavedGameType } from "game-engine/utils";
import useGame from "game-engine/hooks/useGame";

export enum MenuSavedGamesEnum {
  "saveGame" = "saveGame",
  "loadGame" = "loadGame",
}

const MenuSavedGames = (
  props: MenuProps & {
    mode: MenuSavedGamesEnum;
    onLoadGameOverride?: (savedGame: SavedGameType) => void;
    onImportOverride?: () => void;
  }
) => {
  const {
    mode,
    isActive,
    closeSubMenu,
    openSubMenu,
    onLoadGameOverride,
    onImportOverride,
  } = props;
  const { gameFns } = useGame();

  const savedData = gameFns.getSavedData();
  const isSaveMode = mode === MenuSavedGamesEnum.saveGame;

  const canvasX = GAME_CONFIG.window.canvasDimensions.x;
  const canvasY = GAME_CONFIG.window.canvasDimensions.y;

  const menuWidth = canvasX - 30;
  const menuHeight = canvasY - 14;

  const paddingX = 20;
  const contentWidth = menuWidth - paddingX * 2;
  const buttonPaddingX = 4;
  const fileButtonWidth = 142;
  const footerButtonWidth = 88;
  const footerButtonPaddingX = 4;

  //
  // LOGIC
  //
  const onOverwriteSaveGame = (savedGame: SavedGameType) => {
    openSubMenu({
      menuId: MENU_IDS.saveGameDialog,
      data: { overwriteSavedGame: savedGame },
    });
  };

  const onSaveGame = () => {
    openSubMenu({ menuId: MENU_IDS.saveGameDialog });
  };

  const onLoadGame = (savedGame: SavedGameType) => {
    if (onLoadGameOverride) {
      onLoadGameOverride(savedGame);
    } else {
      openSubMenu({ menuId: MENU_IDS.loadGameDialog, data: { savedGame } });
    }
  };

  const onImportFromFile = () => {
    if (onImportOverride) {
      onImportOverride();
    } else {
      openSubMenu({ menuId: MENU_IDS.savedGamesImportDialog });
    }
  };

  //
  // RENDER
  //
  return (
    <MenuWrapper
      isActive={isActive}
      width={menuWidth}
      height={menuHeight}
      titleGap={0}
      title={
        isSaveMode
          ? {
              en: "Select a position to save to:",
              cz: "Vyberte pozici pro uložení:",
            }
          : {
              en: "Select a position to load:",
              cz: "Vyberte kterou pozici nahrát:",
            }
      }
    >
      <MenuScrollable
        width={menuWidth}
        visibleChildrenCount={6}
        minHeight={150}
        ifEmpty={{
          text: {
            en: "You don't have any saved games yet.",
            cz: "Nemáte žádné uložené hry.",
          },
          height: 90,
        }}
      >
        {isSaveMode ? (
          <MenuButton
            x={Math.round(menuWidth / 2)}
            width={contentWidth}
            centered
            paddingX={buttonPaddingX}
            label={{
              en: "[ Empty Slot ]",
              cz: "[ Prázdná pozice ]",
            }}
            onClick={onSaveGame}
          />
        ) : null}

        {savedData?.savedGames?.map((savedGame) => {
          return (
            <MenuButton
              key={savedGame.dateIso}
              x={Math.round(menuWidth / 2)}
              width={contentWidth}
              centered
              paddingX={buttonPaddingX}
              label={savedGame.name}
              onClick={() =>
                isSaveMode
                  ? onOverwriteSaveGame(savedGame)
                  : onLoadGame(savedGame)
              }
            />
          );
        })}
      </MenuScrollable>

      <MenuSpacer height={3} />

      <Group x={paddingX}>
        <LayoutColumn gap={3}>
          <MenuButton
            width={fileButtonWidth}
            paddingX={buttonPaddingX}
            label={{
              en: "Import from a file",
              cz: "Nahrát ze souboru",
            }}
            onClick={onImportFromFile}
          />

          <MenuButton
            width={fileButtonWidth}
            paddingX={buttonPaddingX}
            label={{
              en: "Download as a file",
              cz: "Stáhnout jako soubor",
            }}
            onClick={gameFns.downloadSavedData}
          />
        </LayoutColumn>

        <MenuButton
          x={contentWidth - footerButtonWidth}
          width={footerButtonWidth}
          paddingX={footerButtonPaddingX}
          label={{ en: "Cancel", cz: "Zrušit" }}
          onClick={closeSubMenu}
          centeredText
        />
      </Group>
    </MenuWrapper>
  );
};

export default MenuSavedGames;
