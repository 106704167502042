import MUSIC_CONFIGS, {
  getMusicByConfigId,
} from "game-files/audio/MUSIC_CONFIGS";
import SOUND_CONFIGS, {
  getSoundByConfigId,
} from "game-files/audio/SOUND_CONFIGS";

import Box from "game-engine/_dev/basic-components/Box";
import ButtonPlayAudio from "game-engine/_dev/basic-components/ButtonPlayAudio";
import Checkbox from "game-engine/_dev/basic-components/inputs/Checkbox";
import Divider from "game-engine/_dev/basic-components/Divider";
import Dropdown from "game-engine/_dev/basic-components/inputs/Dropdown";
import { EditorType } from "../..";
import { GameObjective } from "game-files/gameObjectives";
import NumberInput from "game-engine/_dev/basic-components/inputs/NumberInput";
import { SceneType } from "game-engine/types";
import devToolsConfig from "game-engine/configs/devtools-config";

const SectionSceneAudio = (props: {
  isOpen?: boolean;
  onOpenChange?: (v) => void;
  editor: EditorType;
  updateScene: (scene: SceneType) => void;
}) => {
  const { editor, updateScene } = props;

  const sceneMusic = editor.scene?.audio[GameObjective.GAME_START].music;
  const musicConfig = getMusicByConfigId(sceneMusic?.musicConfigId);

  const sceneSound = editor.scene?.audio[GameObjective.GAME_START].sound;
  const soundConfig = getSoundByConfigId(sceneSound?.soundConfigId);

  //
  // RENDER
  //
  return (
    <Box
      isOpen={props.isOpen}
      onOpenChange={props.onOpenChange}
      label="Audio"
      level={1}
      useFlex
      childMinWidth="90%"
      gap="20px"
      contentPaddingBottom="50px"
      contentPaddingLeft="20px"
      contentPaddingRight="0px"
    >
      <Box renderContentOnly childMinWidth="90%">
        <Divider name="Scene Music:" />

        <Dropdown
          isActive={
            !!editor.scene.audio[GameObjective.GAME_START].music?.musicConfigId
          }
          selectedId={
            editor.scene.audio[GameObjective.GAME_START].music?.musicConfigId ||
            "no-music"
          }
          items={[
            {
              id: "no-music",
              data: undefined,
              label: "No Music",
            },
            ...Object.values(MUSIC_CONFIGS).map((musicConfig) => {
              return {
                id: musicConfig.id,
                data: musicConfig,
                label: musicConfig.name,
              };
            }),
          ]}
          onChange={(selectedId) =>
            updateScene({
              ...editor.scene,
              audio: {
                [GameObjective.GAME_START]: {
                  ...editor.scene?.audio[GameObjective.GAME_START],
                  music: {
                    ...editor.scene?.audio[GameObjective.GAME_START].music,
                    musicConfigId:
                      selectedId === "no-music" ? undefined : selectedId,
                  },
                },
              },
            })
          }
        />

        {sceneMusic?.musicConfigId && (
          <Box renderContentOnly childMinWidth="90%">
            <ButtonPlayAudio
              name="Play Music"
              src={musicConfig?.src}
              volume={sceneMusic?.volume}
              loop
              backgroundColor={devToolsConfig.inputBackground}
            />

            <NumberInput
              prefix="volume"
              value={
                editor.scene?.audio[GameObjective.GAME_START]?.music?.volume ??
                1
              }
              onChange={(v) =>
                updateScene({
                  ...editor.scene,
                  audio: {
                    [GameObjective.GAME_START]: {
                      ...editor.scene?.audio[GameObjective.GAME_START],
                      music: {
                        ...editor.scene?.audio[GameObjective.GAME_START].music,
                        volume: v,
                      },
                    },
                  },
                })
              }
              rangeController={{
                min: 0,
                max: 1,
                step: 0.1,
              }}
              noBorder
            />
          </Box>
        )}
      </Box>

      <Box renderContentOnly childMinWidth="90%">
        <Divider name="Scene Sounds:" />
        <Dropdown
          isActive={
            editor.scene.audio[GameObjective.GAME_START].sound
              ?.soundConfigId !== undefined
          }
          selectedId={
            editor.scene.audio[GameObjective.GAME_START].sound?.soundConfigId ||
            "no-sound"
          }
          items={[
            {
              id: "no-sound",
              data: undefined,
              label: "No Sounds",
            },
            ...Object.values(SOUND_CONFIGS).map((soundConfig) => {
              return {
                id: soundConfig.id,
                data: soundConfig,
                label: soundConfig.id,
              };
            }),
          ]}
          onChange={(selectedId) =>
            updateScene({
              ...editor.scene,
              audio: {
                [GameObjective.GAME_START]: {
                  ...editor.scene?.audio[GameObjective.GAME_START],
                  sound: {
                    ...editor.scene?.audio[GameObjective.GAME_START].sound,
                    soundConfigId:
                      selectedId === "no-sound" ? undefined : selectedId,
                  },
                },
              },
            })
          }
        />

        {sceneSound?.soundConfigId && (
          <Box renderContentOnly childMinWidth="90%">
            <ButtonPlayAudio
              name="Play Sounds"
              src={soundConfig?.src}
              volume={sceneSound?.volume}
              loop={sceneSound?.loop}
              backgroundColor={devToolsConfig.inputBackground}
            />

            <Checkbox
              label="Loop"
              value={editor.scene?.audio[GameObjective.GAME_START]?.sound?.loop}
              onChange={(v) =>
                updateScene({
                  ...editor.scene,
                  audio: {
                    [GameObjective.GAME_START]: {
                      ...editor.scene?.audio[GameObjective.GAME_START],
                      sound: {
                        ...editor.scene?.audio[GameObjective.GAME_START].sound,
                        loop: v,
                      },
                    },
                  },
                })
              }
            />

            <NumberInput
              prefix="volume"
              value={
                editor.scene?.audio[GameObjective.GAME_START]?.sound?.volume ??
                1
              }
              onChange={(v) =>
                updateScene({
                  ...editor.scene,
                  audio: {
                    [GameObjective.GAME_START]: {
                      ...editor.scene?.audio[GameObjective.GAME_START],
                      sound: {
                        ...editor.scene?.audio[GameObjective.GAME_START].sound,
                        volume: v,
                      },
                    },
                  },
                })
              }
              rangeController={{
                min: 0,
                max: 1,
                step: 0.1,
              }}
              noBorder
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default SectionSceneAudio;
