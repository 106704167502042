import devToolsConfig from "game-engine/configs/devtools-config";
import styled from "styled-components";

const Wrapper = styled.div<{ marginTop?: string; marginBottom?: string }>`
  display: flex;
  flex-direction: column;
  margin-top: ${(props) => props.marginTop || "16px"};
  margin-bottom: ${(props) => props.marginBottom || "6px"};
`;

const DividerLine = styled.div`
  width: 100%;
  height: 1px;
  //background: ${devToolsConfig.borderColor};
`;

const DividerName = styled.div`
  margin-top: 8px;
  color: #fff9;
  font-size: 12px;
  //font-style: italic;
`;

const Divider = (props: {
  name?: string;
  marginTop?: string;
  marginBottom?: string;
}) => {
  return (
    <Wrapper marginTop={props.marginTop} marginBottom={props.marginBottom}>
      <DividerLine />
      {props.name && <DividerName>{props.name}</DividerName>}
    </Wrapper>
  );
};

export default Divider;
