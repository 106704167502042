import { StateConditionalData, StateConditionalProps } from "game-engine/types";

import { GameEventsType } from "game-files/gameEvents";
import { GameObjective } from "game-files/gameObjectives";

//
// Check if all values in the object are assigned
//
export const allValuesAssigned = (obj: object): boolean => {
  return Object.values(obj).every((val) => val !== undefined);
};

//
// Assign values from source to target if the target value is undefined
//
export const assignAndCheckValues = <T extends object>(
  target: Partial<T>,
  source: Partial<T>,
  isValid: (val: any) => boolean = (val) => val !== undefined // Default comparator: check if value is not undefined
): boolean => {
  for (const key in source) {
    if (isValid(source[key]) && !isValid(target[key])) {
      target[key] = source[key];
    }
  }
  return allValuesAssigned(target);
};

//
// Handle conditional assignments based on state
//
export const assignAndCheckConditionalValues = <T extends object>(
  target: Partial<T>,
  data: {
    conditional?: (props: StateConditionalProps) => StateConditionalData[];
  },
  props: StateConditionalProps,
  isValid: (val: any) => boolean = (val) => val !== undefined, // Default comparator: check if value is not undefined
  aggregateConditionals: boolean = false,
  includeAllConditionals: boolean = false
): boolean => {
  if (data.conditional?.length) {
    const condArray = data.conditional(props);
    for (const cond of condArray) {
      if (includeAllConditionals) {
        // unconditionally assign values if aggregating
        assignAndCheckValues(target, cond.state, isValid);
      } else if (
        cond.condition &&
        assignAndCheckValues(target, cond.state, isValid)
      ) {
        if (!aggregateConditionals) {
          return true; // Stop further processing if all values are assigned
        }
      }
    }
  }
  return false; // No condition met or incomplete data
};

//
// GENERIC FUNCTIONS FOR GETTING DATA BY CURRENT OBJECTIVE AND CONDITIONAL VALUES
//
type DataByCurrentObjective<T> = {
  [key in GameObjective]?: T & {
    conditional?: (props: {
      events: GameEventsType;
    }) => { condition: boolean; state: T }[];
  };
};

export const getDataByCurrentObjective = <T>(
  props: {
    dataByCurrentObjective: DataByCurrentObjective<T>;
    currentObjective: GameObjective;
    events: GameEventsType;
    includeAllConditionals?: boolean;
    aggregateConditionals?: boolean;
    isValid?: (val: any) => boolean; // Optional comparator function for checking value validity
  },
  defaultType: T
): T => {
  const {
    dataByCurrentObjective,
    currentObjective,
    events,
    isValid = (val) => val !== undefined,
    aggregateConditionals,
    includeAllConditionals,
  } = props;

  // Initialize the result with default values
  let resultData: Partial<T> = { ...defaultType };

  if (!dataByCurrentObjective) {
    return resultData as T;
  }

  // Loop through objectives from current to 0
  for (let obj = currentObjective; obj >= 0; obj--) {
    const objData = dataByCurrentObjective[obj];

    if (objData) {
      // Check and assign values based on conditions, using the custom comparator if provided

      const handleConditional = () =>
        assignAndCheckConditionalValues(
          resultData,
          objData,
          { events },
          isValid,
          aggregateConditionals,
          includeAllConditionals
        );

      const handleDefault = () =>
        assignAndCheckValues(resultData, objData, isValid);

      if (handleConditional() || handleDefault()) {
        // If data is assigned, continue to the next objective
        if (
          Object.keys(resultData).length === Object.keys(defaultType).length
        ) {
          // If all fields are filled, break the loop
          break;
        }
      }
    }
  }

  return resultData as T;
};
