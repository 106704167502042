import { BlendingMode } from "game-engine/types";
import GAME_CONFIG from "game-files/gameConfig";
import Konva from "konva";
import { Rect } from "react-konva";

const SceneFill = (
  props: Konva.RectConfig & { blendingMode?: BlendingMode }
) => {
  return (
    <Rect
      {...props}
      onTap={props.onClick}
      x={0}
      y={0}
      width={GAME_CONFIG.scene.dimensions.x}
      height={GAME_CONFIG.scene.dimensions.y}
      fill={props.color || "white"}
      globalCompositeOperation={props.blendingMode}
    />
  );
};

export default SceneFill;
