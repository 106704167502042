import {
  AnimationColorEnum,
  ItemDeleteAnimation,
  SceneType,
  SpriteDirection,
} from "game-engine/types";

import { ACTION } from "game-engine/utils";
import GAME_CONFIG from "game-files/gameConfig";
import { GameEvent } from "game-files/gameEvents";
import { GameObjective } from "game-files/gameObjectives";
import IMAGE_Layer_00_DEPTH_MAP from "./assets/layer - 00 - DEPTH MAP.png";
import IMAGE_Layer_00_DROP_MAP from "./assets/layer - 00 - DROP MAP.png";
import IMAGE_Layer_00_WALK_MAP from "./assets/layer - 00 - WALK MAP.png";
import IMAGE_Layer_01_Background_A from "./assets/layer - 01 - background A.png";
import IMAGE_Layer_01_Background_A_B_Sprite from "./assets/layer - 01 - background A-B sprite.png";
import IMAGE_Layer_01_Background_B from "./assets/layer - 01 - background B.png";
import IMAGE_Layer_02_Tree from "./assets/layer - 02 - tree.png";
import IMAGE_Layer_03_Main_Tree_A from "./assets/layer - 03 - main tree A.png";
import IMAGE_Layer_03_Main_Tree_A_B_Sprite from "./assets/layer - 03 - main tree A-B sprite.png";
import IMAGE_Layer_03_Main_Tree_B from "./assets/layer - 03 - main tree B.png";
import IMAGE_Layer_04_Rocks from "./assets/layer - 04 - rocks.png";
import IMAGE_Layer_05_Foreground_Trees from "./assets/layer - 05 - foreground trees.png";
import IMAGE_Preview from "./assets/preview.png";
import { ItemConfigId } from "game-files/common/ids";
import MUSIC_CONFIGS from "game-files/audio/MUSIC_CONFIGS";
import { MainCharacterAnimationId } from "game-files/characters/MainCharacter";
import SHARED_DATA from "game-files/scenes/SHARED_DATA";
import SOUND_CONFIGS from "game-files/audio/SOUND_CONFIGS";

const DevScene03: SceneType = {
  configId: "DevScene03", // --------- KEEP SAME AS COMPONENT NAME
  isDev: false, // ----------------- DEV SCENES ARE HIDDEN IN SCENES LAYOUT EDITOR
  isUsingTestObjectives: false, // - OBJECTIVES WILL BE DISPLAYED UNDER TEST NAMES IN DEV TOOLS
  sceneNeighbors: undefined, // ---- GENERATED FROM SCENES LAYOUT
  uniqueId: "DevScene03", // ---------- GENERATED FROM SCENES LAYOUT (put manual id here for scenes out of scenes layout)
  preview: { src: IMAGE_Preview },

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //
  // Scene Name
  //
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  name: {
    [GameObjective.GAME_START]: {
      en: "Sick Tree",
      cz: "Nemocný strom",

      // EXAMPLE OF CONDITIONAL SCENE NAME
      conditional: ({ events }) => [
        {
          condition: events.test_sickTreeState > 1,
          state: {
            en: "Healed Tree",
            cz: "Uzdravený strom",
          },
        },
      ],
    },
  },

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //
  // Scene Audio
  //
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  audio: {
    [GameObjective.GAME_START]: {
      music: {
        musicConfigId: MUSIC_CONFIGS.Test_02.id,
        volume: undefined,
      },
      sound: {
        soundConfigId: undefined,
        volume: undefined,
        loop: undefined,
      },

      // EXAMPLE OF CONDITIONAL SCENE AUDIO
      conditional: ({ events }) => [
        {
          condition: events.test_sickTreeState > 0,
          state: {
            music: {
              musicConfigId: MUSIC_CONFIGS.Test_01.id,
            },
          },
        },
      ],
    },
  },

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //
  // Scene Walk Paths
  //
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  sceneWalkPaths: {
    [GameObjective.GAME_START]: {
      left: {
        edgeWalkY: 112,
        walkTo: { x: 76, y: 123 },
      },
      right: {
        edgeWalkY: 112,
        walkTo: { x: 265, y: 124 },
      },
      up: {
        isBlocked: true,
        actions: [
          ACTION.talkRandom({
            dialogOptions: [
              {
                en: `I'm not going up there.`,
                cz: `Nahoru nepůjdu.`,
              },
            ],
          }),
        ],
      },
      down: {
        edgeWalkX: 205,
        walkTo: { x: 201, y: 141 },
      },

      /*
      // EXAMPLE OF CONDITIONAL SCENE WALK-PATHS
      conditional: ({ events }) => [
      
        {
          condition: events.test_treeClickCount > 1,
          state: {
            right: {
              edgeWalkY: 100,
              walkTo: { x: 278, y: 106 },
            },
          },
        }

      ]
      */
    },
  },

  images: {
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //
    // SCENE BACKGROUND
    //
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    background: {
      dataByCurrentObjective: {
        [GameObjective.GAME_START]: {
          fillColor: undefined,
          image: {
            src: IMAGE_Layer_01_Background_A,
            transition: undefined,
          },
          dropMap: { src: IMAGE_Layer_00_DROP_MAP },
          walkMap: { src: IMAGE_Layer_00_WALK_MAP },
          depthMap: { src: IMAGE_Layer_00_DEPTH_MAP },
          depthSettings: {
            scaleMin: undefined,
            scaleMax: undefined,
          },

          // EXAMPLE OF CONDITIONAL SCENE BACKGROUND
          conditional: ({ events }) => [
            {
              condition: events.test_sickTreeState === 2,
              state: {
                image: { src: IMAGE_Layer_01_Background_A_B_Sprite },
                imageSpriteConfig: {
                  direction: SpriteDirection.Vertical,
                  playCount: 1,
                  frameCount: 10,
                  frameWidth: GAME_CONFIG.scene.dimensions.x,
                  frameHeight: GAME_CONFIG.scene.dimensions.y,
                  frameDurationMilliseconds: 100,
                },

                dropMap: { src: IMAGE_Layer_00_DROP_MAP },
                walkMap: { src: IMAGE_Layer_00_WALK_MAP },
                depthMap: { src: IMAGE_Layer_00_DEPTH_MAP },
              },
            },

            {
              condition: events.test_sickTreeState > 2,
              state: {
                image: { src: IMAGE_Layer_01_Background_B },

                dropMap: { src: IMAGE_Layer_00_DROP_MAP },
                walkMap: { src: IMAGE_Layer_00_WALK_MAP },
                depthMap: { src: IMAGE_Layer_00_DEPTH_MAP },
              },
            },
          ],
        },
      },

      actionsByCurrentObjective: {
        [GameObjective.GAME_START]: {
          actionsWithItem: undefined,
          actions: [
            ACTION.talkRandom({
              dialogOptions: [...SHARED_DATA.dialogOptions.forestBackground],
            }),
          ],
        },
      },
    },

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //
    // SCENE LAYERS
    //
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    layers: [
      /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      //
      // Scene Layer
      //
      /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

      {
        id: "IMAGE_Layer_02_Tree",
        depthY: 73,
        dataByCurrentObjective: {
          [GameObjective.GAME_START]: {
            image: { src: IMAGE_Layer_02_Tree },
            spriteConfig: undefined,
            ignoreOnClick: false,
            isInvisible: false,
            cursorOnHover: undefined,
            offset: undefined,

            /* 
            // EXAMPLE OF CONDITIONAL SCENE LAYER IMAGE
            conditional: ({ events }) => [
            
              {
                condition: events.test_treeClickCount > 0,
                state: {
                  image: {
                    src: ImageLayerTree1_State2,
                    transition: { durationSec: 1 },
                  },
                },
              },

            ],
            */
          },
        },
        actionsByCurrentObjective: {
          [GameObjective.GAME_START]: {
            actionsWithItem: undefined,
            actions: [
              ACTION.talkRandom({
                dialogOptions: [
                  ...SHARED_DATA.dialogOptions.forest,
                  ...SHARED_DATA.dialogOptions.tree,
                ],
              }),
            ],
          },
        },
      },

      /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      //
      // Scene Layer
      //
      /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

      {
        id: "IMAGE_Layer_03_Main_Tree_A",
        depthY: 88,
        dataByCurrentObjective: {
          [GameObjective.GAME_START]: {
            image: { src: IMAGE_Layer_03_Main_Tree_A },
            spriteConfig: undefined,
            ignoreOnClick: false,
            isInvisible: false,
            cursorOnHover: undefined,
            offset: undefined,

            // EXAMPLE OF CONDITIONAL SCENE LAYER IMAGE
            conditional: ({ events }) => [
              {
                condition: events.test_sickTreeState === 1,
                state: {
                  image: {
                    src: IMAGE_Layer_03_Main_Tree_A_B_Sprite,
                  },
                  spriteConfig: {
                    direction: SpriteDirection.Vertical,
                    playCount: 1,
                    frameCount: 10,
                    frameWidth: GAME_CONFIG.scene.dimensions.x,
                    frameHeight: GAME_CONFIG.scene.dimensions.y,
                    frameDurationMilliseconds: 100,
                  },
                },
              },
              {
                condition: events.test_sickTreeState > 1,
                state: {
                  image: {
                    src: IMAGE_Layer_03_Main_Tree_B,
                  },
                },
              },
            ],
          },
        },
        actionsByCurrentObjective: {
          [GameObjective.GAME_START]: {
            actionsWithItem: {
              default: [
                ACTION.talkRandom({
                  dialogOptions: [
                    {
                      en: `That doesn't seem to fit.`,
                      cz: `Tohle nic nedělá.`,
                    },
                  ],
                }),
              ],

              [ItemConfigId.Test_FlaskWater]: [
                ACTION.conditional(({ gamePlay, gameItems }) => [
                  {
                    condition: gamePlay.events.test_sickTreeState === 0,
                    actions: [
                      ACTION.playSound({
                        soundConfigId: SOUND_CONFIGS.Test_Vial_Full_Grab.id,
                      }),

                      ACTION.putItemInCursorToSceneOverlay({
                        position: { x: 168, y: 54 },
                        deleteAfterSec: 1,
                        deleteItemFadeOutSec: 1,
                        deleteItemScaleOutSec: 1,
                        deleteAnimation: ItemDeleteAnimation.stars,
                        deleteAnimationColor: AnimationColorEnum.cyan,
                      }),

                      ACTION.pause({ durationSec: 3 }),

                      ACTION.playMusic({
                        musicConfigId: MUSIC_CONFIGS.Test_Fanfare.id,
                        volume: 0.6,
                      }),

                      ACTION.talkRandom({
                        dialogOptions: [
                          {
                            en: `Let's hope a bit of water helps.`,
                            cz: `Snad ta troška vody zabere.`,
                          },
                        ],
                      }),

                      ACTION.playSound({
                        soundConfigId: SOUND_CONFIGS.TreeHealing.id,
                        volume: 0.6,
                      }),

                      ACTION.setEvent({
                        key: GameEvent.test_sickTreeState,
                        value: 1,
                      }),

                      ACTION.pause({ durationSec: 1 }),

                      ACTION.setEvent({
                        key: GameEvent.test_sickTreeState,
                        value: 2,
                      }),

                      ACTION.pause({ durationSec: 2 }),

                      ACTION.characterAnimation({
                        animationId: MainCharacterAnimationId.shrug,
                      }),

                      ACTION.pause({ durationSec: 1 }),

                      ACTION.talkRandom({
                        dialogOptions: [
                          {
                            en: `Whoa! Did I do that?`,
                            cz: `Páni! To jsem udělal já?`,
                          },
                        ],
                      }),

                      ACTION.setEvent({
                        key: GameEvent.test_sickTreeState,
                        value: 3,
                      }),
                    ],
                  },

                  {
                    condition: gamePlay.events.test_sickTreeState > 0,
                    actions: [
                      ACTION.talkRandom({
                        dialogOptions: [
                          [
                            {
                              en: `I think it has enough water for now.`,
                              cz: `Myslím že už má vody dostatek.`,
                            },
                          ],
                        ],
                      }),
                    ],
                  },
                ]),
              ],
            },

            actions: [
              // EXAMPLE OF CONDITIONAL ACTIONS
              ACTION.conditional(({ gamePlay, gameItems }) => [
                {
                  condition: gamePlay.events.test_sickTreeState === 0,
                  actions: [
                    ACTION.talkRandom({
                      dialogOptions: [
                        [
                          {
                            en: `That tree looks sick.`,
                            cz: `Ten strom vypadá nemocně.`,
                          },
                          {
                            en: `I wonder what happened to it.`,
                            cz: `Co se mu asi stalo?`,
                          },
                        ],
                      ],
                    }),
                  ],
                },
                {
                  condition: gamePlay.events.test_sickTreeState > 0,
                  actions: [
                    ACTION.talkRandom({
                      dialogOptions: [
                        [
                          {
                            en: `Now that's one healthy tree!`,
                            cz: `Teď už je to pořádný zdravý strom!`,
                          },
                        ],
                      ],
                    }),
                  ],
                },
              ]),
            ],
          },
        },
      },

      /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      //
      // Scene Layer
      //
      /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

      {
        id: "IMAGE_Layer_04_Rocks",
        depthY: 144,
        dataByCurrentObjective: {
          [GameObjective.GAME_START]: {
            image: { src: IMAGE_Layer_04_Rocks },
            spriteConfig: undefined,
            ignoreOnClick: false,
            isInvisible: false,
            cursorOnHover: undefined,
            offset: undefined,
          },
        },
        actionsByCurrentObjective: {
          [GameObjective.GAME_START]: {
            actionsWithItem: undefined,
            actions: [
              ACTION.talkRandom({
                dialogOptions: [...SHARED_DATA.dialogOptions.rockSmall],
              }),
            ],
          },
        },
      },

      /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      //
      // Scene Layer
      //
      /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

      {
        id: "IMAGE_Layer_05_Foreground_Trees",
        depthY: 1000,
        dataByCurrentObjective: {
          [GameObjective.GAME_START]: {
            image: { src: IMAGE_Layer_05_Foreground_Trees },
            spriteConfig: undefined,
            ignoreOnClick: false,
            isInvisible: false,
            cursorOnHover: undefined,
            offset: undefined,
          },
        },
        actionsByCurrentObjective: {
          [GameObjective.GAME_START]: {
            actionsWithItem: undefined,
            actions: [
              ACTION.talkRandom({
                dialogOptions: [
                  ...SHARED_DATA.dialogOptions.tree,
                  ...SHARED_DATA.dialogOptions.treeYoung,
                  ...SHARED_DATA.dialogOptions.forest,
                ],
              }),
            ],
          },
        },
      },
    ],
  },

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //
  // SCENE ACTIONS
  //
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  onSceneIdle: {
    // idleAfterMinSec: 1,
    // idleAfterMaxSec: 2,
    actionsByCurrentObjective: {
      [GameObjective.GAME_START]: {
        // IDLE ACTIONS ARE NOT EXECUTED AS A SEQUENCE, BUT PLACED INTO A POOL OF
        // ACTIONS TO BE RANDOMLY PICKED FROM AMONG OTHER IDLES (CHARACTER IDLE ETC.)
        actions: [
          /*
          // EXAMPLE OF IDLE ACTIONS
          ACTION.sequence([
            ACTION.talk({
              characterId: TestCharacterConfig.id,
              dialog: [
                {
                  en: `ACTION 1 - CHAR A`,
                  cz: `ACTION 1 - CHAR A`,
                },
              ],
            }),
            ACTION.talk({
              dialog: [
                {
                  en: `ACTION 1 - CHAR B`,
                  cz: `ACTION 1 - CHAR B`,
                },
              ],
            }),
          ]),

          ACTION.talk({
            characterId: TestCharacterConfig.id,
            dialog: [
              {
                en: `ACTION 2 - CHAR A`,
                cz: `ACTION 2 - CHAR A`,
              },
            ],
          }),
          */
        ],
      },
    },
  },

  onSceneInit: {
    actionsByCurrentObjective: {
      [GameObjective.GAME_START]: {
        // ACTIONS TO BE RUN IN SEQUENCE AFTER SCENE IS INITIALIZED
        actions: [
          /*
          // EXAMPLE OF CONDITIONAL INIT ACTIONS
          ACTION.conditional(({ gamePlay, gameItems }) => [
            {
              condition: gamePlay.events.test_treeClickCount === 0,
              actions: [
                ACTION.talkRandom({
                  characterId: TestCharacterConfig.id,
                  dialogOptions: [
                    {
                      en: `ACTION FOR treeClickCount === 0.`,
                      cz: `ACTION FOR treeClickCount === 0.`,
                    },
                  ],
                }),
              ],
            },

            {
              condition: gamePlay.events.test_treeClickCount > 0,
              actions: [
                ACTION.talkRandom({
                  characterId: TestCharacterConfig.id,
                  dialogOptions: [
                    {
                      en: `ACTION FOR treeClickCount > 0.`,
                      cz: `ACTION FOR treeClickCount > 0.`,
                    },
                  ],
                }),
              ],
            },
            
          ]),
          */
        ],
      },
    },
  },

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //
  // SCENE CHARACTERS
  //
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  characters: [
    /*
    {
      config: TestCharacterConfig,
      dataByCurrentObjective: {
        [GameObjective.GAME_START]: {
          position: { x: 100, y: 100 },
          facing: Direction.right,
    
          onClickOverride: {
            actionsByCurrentObjective: {
              [GameObjective.GAME_START]: {
                actions: [],
                actionsWithItem: {
                  default: [],
                  // EXAMPLE OF ASSIGNING DIFFERENT ACTIONS TO SPECIFIC ITEMS
                  // [ItemConfigId.Test_Key]: [],
                  // [ItemConfigId.Test_Flask]: [],
                  // [ItemConfigId.Test_FlaskWater]: [],
                },
              },
            },
          },
    
          // EXAMPLE OF CONDITIONAL CHARACTER OPTIONS
          conditional: ({ events }) => [
    
            {
              condition: events.test_treeClickCount > 3,
              state: {
                facing: Direction.left,
                position: { x: 200, y: 100 },
              },
            },
    
          ],
        },
      },
    },
    */
  ],
};

export default DevScene03;
