import { EditorType, OnSceneMouseClick, SceneLayerMeta } from "../..";
import {
  SceneLayerDataType,
  SceneLayerType,
  SceneType,
} from "game-engine/types";
import { useEffect, useState } from "react";

import Box from "game-engine/_dev/basic-components/Box";
import Checkbox from "game-engine/_dev/basic-components/inputs/Checkbox";
import { GameObjective } from "game-files/gameObjectives";
import SceneLayerSettings from "./SceneLayerSettings";
import styled from "styled-components";
import useGame from "game-engine/hooks/useGame";

const BoxHeader = styled.div`
  flex: 1;
  display: flex;
  align-items: flex-start;
  gap: 10px;
`;

const BoxName = styled.div`
  min-width: 50%;
`;

const SectionSceneLayers = (props: {
  isOpen?: boolean;
  onOpenChange?: (v) => void;
  editor: EditorType;
  setEditor: (e: EditorType) => void;
  updateScene: (scene: SceneType) => void;
  onSceneMouseClick: OnSceneMouseClick;
  setOnSceneMouseClick: (v: OnSceneMouseClick) => void;
}) => {
  const { engineConfig } = useGame();

  const [isOpen, setIsOpen] = useState(props.isOpen);

  useEffect(() => {
    setIsOpen(props.isOpen);
  }, [props.isOpen]);

  const {
    editor,
    setEditor,
    updateScene,
    onSceneMouseClick,
    setOnSceneMouseClick,
  } = props;

  const updateSceneLayer = (sceneLayer: SceneLayerType) => {
    updateScene({
      ...editor.scene,
      images: {
        ...editor.scene.images,
        layers: [...(editor.scene.images.layers || [])].map((l) =>
          l.id === sceneLayer.id ? sceneLayer : l
        ),
      },
    });
  };

  const updateSceneLayerData = (
    sceneLayerId: string,
    layerData: SceneLayerDataType
  ) => {
    const updatedScene = {
      ...editor.scene,
      images: {
        ...editor.scene.images,
        layers: [...(editor.scene.images.layers || [])].map((l) =>
          l.id === sceneLayerId
            ? {
                ...l,
                dataByCurrentObjective: {
                  [GameObjective.GAME_START]: layerData,
                },
              }
            : l
        ),
      },
    };
    updateScene(updatedScene);
  };

  const updateSceneLayerMeta = (sceneLayerMeta: SceneLayerMeta) => {
    setEditor({
      ...editor,
      sceneLayersMeta: [...(editor.sceneLayersMeta || [])].map((l) =>
        l.sceneLayerId === sceneLayerMeta.sceneLayerId ? sceneLayerMeta : l
      ),
    });
  };

  const deleteSceneLayer = (sceneLayer: SceneLayerType) => {
    setEditor({
      ...editor,
      scene: {
        ...editor.scene,
        images: {
          ...editor.scene.images,
          layers: editor.scene.images?.layers?.filter(
            (layer) => layer.id !== sceneLayer.id
          ),
        },
      },
      sceneLayersMeta: [...(editor.sceneLayersMeta || [])].filter(
        (layer) => layer.sceneLayerId !== sceneLayer.id
      ),
    });
  };

  const changeSceneLayerImage = (
    sceneLayer: SceneLayerType,
    newImage: { src: string; filename: string }
  ) => {
    setEditor({
      ...editor,
      scene: {
        ...editor.scene,
        images: {
          ...editor.scene.images,
          layers: editor.scene.images?.layers?.map((layer) =>
            layer.id === sceneLayer.id
              ? {
                  ...layer,
                  dataByCurrentObjective: {
                    [GameObjective.GAME_START]: {
                      ...layer.dataByCurrentObjective[GameObjective.GAME_START],
                      image: { src: newImage?.src },
                    },
                  },
                }
              : layer
          ),
        },
      },
      sceneLayersMeta: [...(editor.sceneLayersMeta || [])].map((layer) =>
        layer.sceneLayerId === sceneLayer.id
          ? { ...layer, filename: newImage.filename }
          : layer
      ),
    });
  };

  const renderBoxName = () => {
    const title = "Scene Layers";

    if (isOpen) {
      return (
        <BoxHeader>
          <BoxName>{title}</BoxName>

          <Checkbox
            label="Render Scene Layer Depth Lines"
            value={engineConfig.state.renderSceneLayerDepthLine}
            onChange={(v) =>
              engineConfig.setState((prevState) => ({
                ...prevState,
                renderSceneLayerDepthLine: v,
              }))
            }
          />
        </BoxHeader>
      );
    }
    return title;
  };

  //
  // RENDER
  //
  return (
    <Box
      label={renderBoxName()}
      level={1}
      childMinWidth="60%"
      gap="10px"
      isOpen={isOpen}
      onOpenChange={(isOpen) => {
        setIsOpen(isOpen);
        if (props.onOpenChange) {
          props.onOpenChange(isOpen);
        }
      }}
    >
      {editor.scene?.images?.layers?.map((layer) => {
        const layerMeta = editor.sceneLayersMeta.find(
          (l) => l.sceneLayerId === layer.id
        );

        return (
          <SceneLayerSettings
            key={layer.id}
            layer={layer}
            changeSceneLayerImage={changeSceneLayerImage}
            deleteSceneLayer={deleteSceneLayer}
            updateLayer={updateSceneLayer}
            updateLayerData={updateSceneLayerData}
            layerMeta={layerMeta}
            updateLayerMeta={updateSceneLayerMeta}
            onSceneMouseClick={onSceneMouseClick}
            setOnSceneMouseClick={setOnSceneMouseClick}
          />
        );
      })}
    </Box>
  );
};

export default SectionSceneLayers;
