import { Group, Rect } from "react-konva";
import {
  SavedGameType,
  getAutosavedGame,
  getSavedData,
} from "game-engine/utils";

import Canvas from "../basic-elements/Canvas";
import GAME_CONFIG from "game-files/gameConfig";
import LayoutColumn from "../basic-elements/LayoutColumn";
import LoadMenu from "../gui-elements/Menu/menus/LoadMenu";
import { MENU_IDS } from "../gui-elements/Menu";
import MenuButton from "../gui-elements/Menu/menu-elements/MenuButton";
import PixelatedLayer from "../basic-elements/PixelatedLayer";
import SavedGamesImportDialog from "../gui-elements/Menu/menus/SavedGamesImportDialog";
import { useState } from "react";

enum ActiveMenuEnum {
  "startMenu" = "startMenu",
  "loadMenu" = "loadMenu",
  "importMenu" = "importMenu",
}

const StartScreen = (props: {
  onNewGame: () => void;
  onQuickLoadGame: () => void;
  onLoadGame: (savedGame: SavedGameType) => void;
}) => {
  const { onNewGame, onQuickLoadGame, onLoadGame } = props;

  const canvasX = GAME_CONFIG.window.canvasDimensions.x;
  const canvasY = GAME_CONFIG.window.canvasDimensions.y;

  const menuWidth = 190;
  const buttonPaddingX = 4;

  const autosavedGame = getAutosavedGame();
  const savedData = getSavedData();

  const [activeMenu, setActiveMenu] = useState<ActiveMenuEnum>(
    ActiveMenuEnum.startMenu
  );

  //
  // MENUS
  //
  const renderMenu = () => {
    switch (activeMenu) {
      case ActiveMenuEnum.loadMenu:
        return (
          <LoadMenu
            {...{
              isActive: true,
              closeMenu: () => setActiveMenu(ActiveMenuEnum.startMenu),
              closeSubMenu: () => setActiveMenu(ActiveMenuEnum.startMenu),
              replaceMenu: () => {},
              openSubMenu: () => {},
              breadcrumb: {
                menuId: MENU_IDS.loadGame,
              },
              onLoadGameOverride: onLoadGame,
              onImportOverride: () => setActiveMenu(ActiveMenuEnum.importMenu),
            }}
          />
        );

      case ActiveMenuEnum.importMenu:
        return (
          <SavedGamesImportDialog
            {...{
              isActive: true,
              closeMenu: () => setActiveMenu(ActiveMenuEnum.startMenu),
              closeSubMenu: () => setActiveMenu(ActiveMenuEnum.loadMenu),
              replaceMenu: () => {},
              openSubMenu: () => {},
              breadcrumb: {
                menuId: MENU_IDS.savedGamesImportDialog,
              },
            }}
          />
        );

      default:
        return (
          <Group x={Math.round((canvasX - menuWidth) / 2)} y={90}>
            <LayoutColumn gap={6}>
              {autosavedGame ? (
                <MenuButton
                  width={menuWidth}
                  paddingX={buttonPaddingX}
                  label={{
                    en: "Continue your last game",
                    cz: "Pokračovat v poslední hře",
                  }}
                  onClick={onQuickLoadGame}
                />
              ) : null}

              {savedData?.savedGames?.length ? (
                <MenuButton
                  width={menuWidth}
                  paddingX={buttonPaddingX}
                  label={{
                    en: "Load a game",
                    cz: "Nahrát uloženou hru",
                  }}
                  onClick={() => setActiveMenu(ActiveMenuEnum.loadMenu)}
                />
              ) : null}

              <MenuButton
                width={menuWidth}
                paddingX={buttonPaddingX}
                label={{ en: "Start a new game", cz: "Začít novou hru" }}
                onClick={onNewGame}
              />

              <MenuButton
                width={menuWidth}
                paddingX={buttonPaddingX}
                label={{ en: "TODO - LANGUAGE SWITCH", cz: undefined }}
                onClick={() => console.log("TODO")}
              />
            </LayoutColumn>
          </Group>
        );
    }
  };

  //
  // RENDER
  //
  return (
    <Canvas>
      <PixelatedLayer>
        <Rect x={0} y={0} width={canvasX} height={canvasY} fill={"#00f4"} />

        {renderMenu()}
      </PixelatedLayer>
    </Canvas>
  );
};

export default StartScreen;
