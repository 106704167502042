import {
  CharacterConfigType,
  CharacterDevToolsPreviewType,
} from "game-engine/types";
import { CharacterId, ItemConfigId, SkillId } from "game-files/common/ids";
import RenderDefault, { AnimationId_Default } from "./render-default/_render";

import { ACTION } from "game-engine/utils";
import { GameEvent } from "game-files/gameEvents";
import { GameObjective } from "game-files/gameObjectives";
import MUSIC_CONFIGS from "game-files/audio/MUSIC_CONFIGS";
import TestItemConfig_Key from "game-files/items/_test/Test_Key";

const THIS_ID = CharacterId.MainCharacter;

export const MainCharacterAnimationId = {
  ...AnimationId_Default,
};

const MainCharacterConfig: CharacterConfigType = {
  id: THIS_ID,
  name: { en: "Main Character", cz: "Hlavní postava" },

  /* 
  dialogConfig: {
    [GameObjective.GAME_START]: {
      aboveOffsetMax: 70,
      aboveOffsetMin: 50,
      offsetBelowMax: -5,
      verticalPosition: CharacterDialogPosition.auto,
      align: TextAlign.center,
      maxWidth: 160,
      color: `#fff`,
      outlined: true,
    },
  },
  */

  idleConfig: {
    idleAfterMinSec: 6,
    idleAfterMaxSec: 12,
  },

  render: {
    [GameObjective.GAME_START]: RenderDefault,
  },

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //
  // ON SCENE IDLE
  //
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  onSceneIdle: {
    actionsByCurrentObjective: {
      [GameObjective.GAME_START]: {
        actions: [
          ACTION.talk({
            characterId: THIS_ID,
            dialog: [
              {
                en: "What a beautiful day for an adventure!",
                cz: "Jaký to pěkný den na dobrodružství!",
              },
            ],
          }),

          ACTION.talk({
            characterId: THIS_ID,
            dialog: [
              {
                en: "Maybe I should keep going.",
                cz: "Měl bych se dát na cestu.",
              },
            ],
          }),

          ACTION.talk({
            characterId: THIS_ID,
            dialog: [
              {
                en: "Where was I going again?",
                cz: "Kam jsem to měl vlastně namířeno?",
              },
            ],
          }),
        ],
      },
    },
  },

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //
  // ON POISON
  //
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  onPoison: {
    isPoisonedGameEvent: GameEvent.mainCharacterIsPoisoned, // true-false is stored in this gameEvent

    actionsOnHeal: [
      //
      // POISON - ACTIONS ON HEAL
      //
      ACTION.poisonMainCharacter({ isPoisoned: false }),
      ACTION.pause({
        durationSec: 1,
      }),
      ACTION.talkRandom({
        dialogOptions: [
          {
            en: `Wow, that was close.`,
            cz: `Uf, tak to bylo těsně.`,
          },
        ],
      }),
    ],

    //
    // POISON - ACTIONS ON DEATH
    //
    actionsOnDeath: [
      ACTION.stopAllSkills({ prependStopActions: true }),
      ACTION.playMusic({
        musicConfigId: MUSIC_CONFIGS.Test_03.id,
        stopBackgroundMusic: true,
      }),
      ACTION.pause({
        durationSec: 1,
      }),
      ACTION.talkRandom({
        dialogOptions: [
          {
            en: `I'm not feeling good.`,
            cz: `Není mi dobře.`,
          },
        ],
      }),
      ACTION.pause({
        durationSec: 2,
      }),
      ACTION.talkRandom({
        dialogOptions: [
          {
            en: `I think I'm dying actually...`,
            cz: `Nechci se plést, ale myslím že umírám...`,
          },
        ],
      }),
      ACTION.pause({
        durationSec: 1,
      }),
      ACTION.characterAnimation({
        characterId: THIS_ID,
        animationId: MainCharacterAnimationId.deathChoke,
        keepLastFrame: true,
      }),
      ACTION.pause({
        durationSec: 3,
      }),
      ACTION.killMainCharacter(),
    ],
  },

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //
  // ON CLICK
  //
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  onClick: {
    actionsByCurrentObjective: {
      [GameObjective.GAME_START]: {
        actions: [
          ACTION.talkRandom({
            dialogOptions: [
              {
                en: "Hey! Stop poking me!",
                cz: "Hej! Přestaň do mě šťouchat!",
              },
              {
                en: "I feel kinda pixelated.",
                cz: "Připadám si pixelovaně.",
              },
              [
                {
                  en: "What was I thinking about again?",
                  cz: "O čem že jsem to přemýšlel?",
                },
                {
                  en: "Ah! I forgot to eat my lunch!",
                  cz: "Ah! Zapomněl jsem se naobědvat!",
                },
              ],
            ],
          }),
        ],

        actionsWithItem: {
          //
          // ON CLICK WITH ITEM - DEFAULT
          //
          default: [
            ACTION.talkRandom({
              dialogOptions: [
                {
                  en: `What do you expect me to do with it?`,
                  cz: "Co s tím mám dělat?",
                },
                {
                  en: `Correct, that's an item.`,
                  cz: `Správně, tohle je předmět.`,
                },
              ],
            }),
          ],

          //
          // ITEM - LANTERN WITH CELESTIAL FLAME
          //
          [ItemConfigId.LanternCelestialFlame]: [
            ACTION.startSkill(SkillId.CelestialFlame),
          ],

          //
          // ITEM - KEY
          //
          [ItemConfigId.Test_Key]: [
            ACTION.talkRandom({
              dialogOptions: [
                {
                  en: `That's a ${TestItemConfig_Key.name.en.toLowerCase()}.`,
                  cz: `To je ${TestItemConfig_Key.name.cz.toLowerCase()}.`,
                },
                {
                  en: `Yea I can't eat that.`,
                  cz: `Toho se nenajím.`,
                },
              ],
            }),
          ],

          //
          // ITEM - FLASK OF WATER
          //
          [ItemConfigId.Test_FlaskWater]: [
            ACTION.swapItemInCursorConfig({
              newItemConfigId: ItemConfigId.Test_Flask,
              blockGui: true,
            }),
            ACTION.talkRandom({
              dialogOptions: [
                {
                  en: `What a refreshing feeling!`,
                  cz: `Jak osvěžující!`,
                },
                {
                  en: `Water is not my cup of tea.`,
                  cz: `Voda není můj šálek čaje.`,
                },
              ],
            }),
          ],

          //
          // ITEM - FLASK WITH TOXIC LIQUID
          //
          [ItemConfigId.Test_FlaskToxic]: [
            ACTION.characterAnimation({
              characterId: THIS_ID,
              animationId: MainCharacterAnimationId.yawn,
            }),
            ACTION.poisonMainCharacter(),
            ACTION.deleteItemInCursor(),
            ACTION.pause({
              durationSec: 1,
            }),
            ACTION.talkRandom({
              dialogOptions: [
                {
                  en: `That's didn't feel quite right.`,
                  cz: `Neudělalo mi to moc dobře.`,
                },
                {
                  en: `That tasted about as good as it looked.`,
                  cz: `Chutnalo to stejně jedovatě jako to vypadalo.`,
                },
              ],
            }),
          ],
        },
      },
    },
  },
};

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//
// PREVIEW
//
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const MainCharacterDevtoolsPreview: CharacterDevToolsPreviewType = {
  config: MainCharacterConfig,
  renderAssets: [
    {
      name: "Default",
      render: RenderDefault,
      animationIds: AnimationId_Default,
    },
  ],
};

export default MainCharacterConfig;
