/* eslint-disable react-hooks/exhaustive-deps */

import styled, { css, keyframes } from "styled-components";
import { useEffect, useRef, useState } from "react";

import { GameEffectShakeViewport } from "game-engine/types";
import useGame from "game-engine/hooks/useGame";

const shake = (offset: number) => keyframes`
  0% { transform: translate(0px, 0px); }
  25% { transform: translate(${Math.random() * offset}px, ${
  Math.random() * offset
}px); }
  50% { transform: translate(${Math.random() * offset}px, ${
  Math.random() * offset
}px); }
  75% { transform: translate(${Math.random() * offset}px, ${
  Math.random() * offset
}px); }
  100% { transform: translate(0px, 0px); }
`;

const ShakingWrapper = styled.div<{
  isShaking: boolean;
  shakeOffset: number;
  shakeSpeed: number;
}>`
  ${(props) =>
    props.isShaking &&
    css`
      /* steps(x) reduces the smoothness of the animation to x steps per animation cycle */
      animation: ${shake(props.shakeOffset)} ${props.shakeSpeed}ms steps(1)
        infinite;
    `}
`;

const EffectShakeViewport = (props: { children: any }) => {
  const { gamePlay, gameFns } = useGame();

  const defaultEffect: GameEffectShakeViewport = {
    isActive: false,
    shakeOffset: 20,
    shakeSpeed: 200,
    durationSec: 5,
  };

  const [effect, setEffect] = useState<GameEffectShakeViewport>({
    ...defaultEffect,
  });
  const effectRef = useRef(effect);

  //
  // OUTSIDE CONTROL VIA GAMEPLAY
  //
  useEffect(() => {
    const storedEffect = gamePlay.state.effects?.viewportShake;

    if (storedEffect?.isActive) {
      const newEffect: GameEffectShakeViewport = {
        ...storedEffect,
        shakeOffset: storedEffect?.shakeOffset ?? defaultEffect.shakeOffset,
        shakeSpeed: storedEffect?.shakeSpeed ?? defaultEffect.shakeSpeed,
        durationSec: storedEffect?.durationSec ?? defaultEffect.durationSec,
      };

      setEffect(newEffect);
      gameFns.setEffectShakeViewport({ ...newEffect, isActive: false });
    }
  }, [gamePlay.state.effects?.viewportShake]);

  //
  // EFFECT MANAGEMENT
  //
  useEffect(() => {
    effectRef.current = effect;

    let shakeTimeout: ReturnType<typeof setTimeout>;

    if (effect.isActive) {
      clearTimeout(shakeTimeout);
      shakeTimeout = setTimeout(
        () => setEffect({ ...effect, isActive: false }),
        effect.durationSec * 1000
      );
    }
    return () => clearTimeout(shakeTimeout);
  }, [effect]);

  //
  // RENDER
  //
  return (
    <ShakingWrapper
      isShaking={effect.isActive}
      shakeOffset={effect.shakeOffset}
      shakeSpeed={effect.shakeSpeed}
    >
      {props.children}
    </ShakingWrapper>
  );
};

export default EffectShakeViewport;
