import styled, { css } from "styled-components";

import AnimateHeight from "react-animate-height";
import ExpandButton from "../../../basic-components/ExpandButton";
import PutItemIntoCursorList from "game-engine/_dev/basic-components/PutItemIntoCursorList";
import devToolsConfig from "game-engine/configs/devtools-config";
import { useState } from "react";

const Wrapper = styled.div<{ isOpen: boolean }>`
  width: 100%;
  position: relative;

  min-height: 4px;
  background: ${devToolsConfig.background};

  /* ${(props) =>
    props.isOpen &&
    css`
      flex: 1;
    `} */
`;

const ContentHeight = styled.div`
  overflow: auto;
  height: 500px;
`;

const AnimateHeightStyled = styled(AnimateHeight)`
  width: 100%;
  flex: 1;
  display: flex;

  & > * {
    width: 100%;
    flex: 1;
    display: flex;
  }
`;

const Content = styled.div`
  flex: 1;
  padding: 10px;
  padding-bottom: 50px;
  background: ${devToolsConfig.background};
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 10px;
`;

const SectionButton = styled.div`
  z-index: 50;
  position: absolute;
  bottom: calc(100% + 8px);
  right: 10px;
`;

const Footer = () => {
  const [sectionIsOpen, setSectionIsOpen] = useState(true);

  //
  // RENDER
  //
  return (
    <Wrapper isOpen={sectionIsOpen}>
      <ContentHeight>
        <AnimateHeightStyled duration={200} height={sectionIsOpen ? "auto" : 0}>
          <Content>
            <PutItemIntoCursorList />
          </Content>
        </AnimateHeightStyled>
      </ContentHeight>

      <SectionButton>
        <ExpandButton
          name="Items (Cursor)"
          namePosition="left"
          isOpen={sectionIsOpen}
          onClick={() => setSectionIsOpen(!sectionIsOpen)}
        />
      </SectionButton>
    </Wrapper>
  );
};

export default Footer;
