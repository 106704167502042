const PIXEL_BLACK_VALUE = -1;
export type PixelValueType = typeof PIXEL_BLACK_VALUE | 0 | 1;
export type PixelMatrixType = PixelValueType[][];
export type PixelColorMapType = { [key in PixelValueType]: string };

const isFilledPixel = (
  pixel: PixelValueType,
  options?: { allowBlackPixels?: boolean }
) => {
  if (options?.allowBlackPixels && pixel === PIXEL_BLACK_VALUE) {
    return true;
  }
  return pixel === 1 || typeof pixel !== "number";
};

export const isPixelOutline = (props: {
  preOutlinedMatrix: PixelMatrixType;
  allowDiagonal?: boolean;
  pixel: PixelValueType;
  x: number;
  y: number;
}) => {
  const { preOutlinedMatrix, allowDiagonal, pixel, x, y } = props;

  // Helper function to check if a pixel is filled, allowing black pixels
  const isFilledPixelLocal = (pixel) =>
    isFilledPixel(pixel, { allowBlackPixels: true });

  if (isFilledPixelLocal(pixel)) {
    return false;
  }

  const rowAbove = preOutlinedMatrix[y - 1];
  const pixelRow = preOutlinedMatrix[y];
  const rowBelow = preOutlinedMatrix[y + 1];

  // ROW ABOVE
  if (y > 0 && rowAbove) {
    /**
     * x - -
     * - o -
     * - - -
     * */
    if (allowDiagonal && x > 0 && isFilledPixelLocal(rowAbove[x - 1])) {
      return true;
    }
    /**
     * - x -
     * - o -
     * - - -
     * */
    if (isFilledPixelLocal(rowAbove[x])) {
      return true;
    }
    /**
     * - - x
     * - o -
     * - - -
     * */
    if (
      allowDiagonal &&
      x < rowAbove.length - 1 &&
      isFilledPixelLocal(rowAbove[x + 1])
    ) {
      return true;
    }
  }

  // CURRENT ROW
  /**
   * - - -
   * x o -
   * - - -
   * */
  if (x > 0 && isFilledPixelLocal(pixelRow[x - 1])) {
    return true;
  }
  /**
   * - - -
   * - o x
   * - - -
   * */
  if (x < pixelRow.length - 1 && isFilledPixelLocal(pixelRow[x + 1])) {
    return true;
  }

  // ROW BELOW
  if (rowBelow) {
    /**
     * - - -
     * - o -
     * x - -
     * */
    if (allowDiagonal && x > 0 && isFilledPixelLocal(rowBelow[x - 1])) {
      return true;
    }
    /**
     * - - -
     * - o -
     * - x -
     * */
    if (isFilledPixelLocal(rowBelow[x])) {
      return true;
    }
    /**
     * - - -
     * - o -
     * - - x
     * */
    if (
      allowDiagonal &&
      x < rowBelow.length - 1 &&
      isFilledPixelLocal(rowBelow[x + 1])
    ) {
      return true;
    }
  }
  return false;
};

export const isPixelShadow = (props: {
  preShadowMatrix: PixelMatrixType;
  pixel: PixelValueType;
  x: number;
  y: number;
}) => {
  const { preShadowMatrix, pixel, x, y } = props;

  const isFilledPixelLocal = (pixel) =>
    isFilledPixel(pixel, { allowBlackPixels: true });

  if (isFilledPixelLocal(pixel)) {
    return false;
  }

  const rowAbove = preShadowMatrix[y - 1];
  const pixelRow = preShadowMatrix[y];

  // ROW ABOVE
  if (y > 0 && rowAbove) {
    /**
     * - x -
     * - o -
     * - - -
     * */
    if (isFilledPixelLocal(rowAbove[x])) {
      return true;
    }
    /**
     * - - x
     * - o -
     * - - -
     * */
    if (x < rowAbove.length - 1 && isFilledPixelLocal(rowAbove[x + 1])) {
      return true;
    }
  }

  // CURRENT ROW
  /**
   * - - -
   * - o x
   * - - -
   * */
  if (x < pixelRow.length - 1 && isFilledPixelLocal(pixelRow[x + 1])) {
    return true;
  }

  return false;
};

export const getOutlinedMatrix = (
  matrix: number[][],
  options?: { noDiagonalOutline?: boolean }
) => {
  const { noDiagonalOutline } = options || {};

  const matrixHeight = matrix.length;
  const matrixWidth = matrix[0].length;

  // Preallocate matrix with padding
  const preOutlinedMatrix = Array(matrixHeight + 2)
    .fill(null)
    .map(() => new Array(matrixWidth + 2).fill(0));

  // Copy original matrix into the center of the padded matrix
  for (let y = 0; y < matrixHeight; y++) {
    for (let x = 0; x < matrixWidth; x++) {
      preOutlinedMatrix[y + 1][x + 1] = matrix[y][x];
    }
  }

  // Map through preOutlinedMatrix to detect outlines
  const outlinedMatrix = preOutlinedMatrix.map((pixelRow, y) =>
    pixelRow.map((pixel, x) =>
      isPixelOutline({
        preOutlinedMatrix,
        allowDiagonal: !noDiagonalOutline,
        pixel,
        x,
        y,
      })
        ? PIXEL_BLACK_VALUE
        : pixel
    )
  );

  return outlinedMatrix;
};

export const getShadowMatrix = (matrix: number[][]) => {
  const matrixHeight = matrix.length;
  const matrixWidth = matrix[0].length;

  // Preallocate matrix with padding
  const preShadowMatrix = Array(matrixHeight + 1)
    .fill(null)
    .map(() => new Array(matrixWidth + 2).fill(0));

  // Copy original matrix into the center of the padded matrix
  for (let y = 0; y < matrixHeight; y++) {
    for (let x = 0; x < matrixWidth; x++) {
      preShadowMatrix[y][x + 1] = matrix[y][x];
    }
  }

  // Map through preShadowMatrix to detect shadows
  const shadowMatrix = preShadowMatrix.map((pixelRow, y) =>
    pixelRow.map((pixel, x) =>
      isPixelShadow({
        preShadowMatrix,
        pixel,
        x,
        y,
      })
        ? PIXEL_BLACK_VALUE
        : pixel
    )
  );

  return shadowMatrix;
};

export const getMatrixHeight = (matrix: number[][]) => {
  return matrix?.length || 0;
};

export const getMatrixWidth = (matrix: number[][]) => {
  return matrix && matrix[0] ? matrix[0].length : 0;
};
