//
// SPRITE

import { Position } from ".";

//
export enum SpriteDirection {
  Vertical = "vertical",
  Horizontal = "horizontal",
}

export type SpriteConfigType = {
  frameWidth?: number; // width of individual frames in animation sprite
  frameHeight?: number;
  frameCount?: number;
  frameDurationMilliseconds?: number;
  frameSequence?: AnimationFrameSequence;
  originOffset?: Position; // for trimmed sprites to move them to the correct position before the image was trimmed (e.g. to be correctly placed in a scene)
  clipOffset?: Position; // offset of clip mask
  direction?: SpriteDirection;
  playCount?: number;
};

// allows to specify custom sequence of animation frames
//    - e.g. [0,1,2,2,2,2,2,1,0] for ping-pong style animation with a pause on 2
//
//  'original', 'reversed' etc. will auto-generate frame indexes to avoid the need to write them by hand
//    - previous example = ['original', {pause: 3}, 'reversed']
//
export enum FrameSequenceValue {
  "original" = "original",
  "reversed" = "reversed",
  "firstFrame" = "firstFrame",
  "lastFrame" = "lastFrame",
  "fadeOut" = "fadeOut",
  "fadeOutSmooth" = "fadeOutSmooth",
  "fadeIn" = "fadeIn",
  "fadeInSmooth" = "fadeInSmooth",
  "fadeInAndOut" = "fadeInAndOut",
  "fadeInAndOutSmooth" = "fadeInAndOutSmooth",
}

export type AnimationFrameSequenceItem =
  | number // frame index
  | FrameSequenceValue
  | {
      pause: number; // will copy previous frame 'pause' number of times
    }
  | {
      // will generate sub-sequence of frames (e.g. [4,5,6,7])
      from: number | "firstFrame" | "lastFrame";
      to: number | "firstFrame" | "lastFrame";
    };

export type AnimationFrameSequence = AnimationFrameSequenceItem[];
