import ScenesLayoutEditor from "game-engine/_dev/scenes-layout-editor";
import styled from "styled-components";

const Wrapper = styled.div`
  flex: 1;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  width: 100%;
`;
const TabScenesLayoutEditor = () => {
  //
  // RENDER
  //
  return (
    <Wrapper>
      <ScenesLayoutEditor />
    </Wrapper>
  );
};

export default TabScenesLayoutEditor;
