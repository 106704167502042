/* eslint-disable react-hooks/exhaustive-deps */

import {
  Direction,
  Position,
  SceneType,
  TranslatedString,
} from "game-engine/types";
import { useEffect, useRef, useState } from "react";

import Box from "game-engine/_dev/basic-components/Box";
import Code from "game-engine/_dev/basic-components/Code";
import DevSidebarLeft from "game-engine/tabs/TabDeveloper/components/SidebarLeft";
import EditorFooter from "./components/EditorFooter";
import { EngineConfigType } from "game-engine/configs/engine-config";
import GAME_CONFIG from "game-files/gameConfig";
import NewScenePreview from "./components/NewScenePreview";
import SectionImages from "./components/SectionImages";
import SectionSceneAudio from "./components/SectionSceneAudio";
import SectionSceneLayers from "./components/SectionSceneLayers";
import SectionSceneSettings from "./components/SectionSceneSettings";
import SectionSceneTransitions from "./components/SectionSceneTransitions";
import SectionUploadImages from "./components/SectionUploadImages";
import Sidebar from "game-engine/_dev/basic-components/Sidebar";
import devToolsConfig from "game-engine/configs/devtools-config";
import { getSceneCode } from "./utils/code";
import styled from "styled-components";
import useGame from "game-engine/hooks/useGame";

const FOOTER_HEIGHT = "100px";

const Wrapper = styled.div<{ isEditing: boolean }>`
  position: relative;
  flex: 1;
  overflow: hidden;

  display: flex;
  justify-content: stretch;
  flex-wrap: wrap;

  padding-bottom: ${FOOTER_HEIGHT};

  background: ${(props) =>
    props.isEditing ? devToolsConfig.background : "#000"};
`;

const FooterWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: ${FOOTER_HEIGHT};
  background: ${devToolsConfig.background};
  border-top: 1px solid ${devToolsConfig.borderColor};

  padding: 0 2%;

  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const Main = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: auto;
`;

const ScenePreviewWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-height: ${GAME_CONFIG.window.displayDimensions.y + 20}px;
  min-height: ${GAME_CONFIG.window.displayDimensions.y + 20}px;
  margin-bottom: auto;
`;

const CodeWrapper = styled.div`
  padding: 10px;
  padding-top: 10px;
  padding-bottom: 20px;
  background: ${devToolsConfig.background};
  border-top: 1px solid ${devToolsConfig.borderColor};
`;

export type SceneMeta = {
  componentName: string;
  previewFileName: string;
  background: {
    filenames: {
      image: string;
      dropMap: string;
      walkMap: string;
      depthMap: string;
    };
    names: {
      image: string;
      dropMap: string;
      walkMap: string;
      depthMap: string;
    };
    dialogActions: EditorDialogActions;
  };
  walkPathsBlockedActions: {
    left: EditorDialogActions;
    right: EditorDialogActions;
    up: EditorDialogActions;
    down: EditorDialogActions;
  };
};

export type SceneLayerMeta = {
  sceneLayerId: string;
  filename: string;
  isHighlighted: boolean;
  isInFront: boolean;
  isInBack: boolean;
  dialogActions: EditorDialogActions;
};

export type EditorDialogActions = {
  sharedDialogOptionIds: string[];
  customDialogOptions: TranslatedString[][]; // takes even single dialog option as an array for ease of adding/removing multi-string dialog options
};

export type EditorType = {
  meta: SceneMeta;
  scene: SceneType;
  sceneLayersMeta: SceneLayerMeta[];
  noCodeExamples?: boolean;
};

export type OnSceneMouseClick = {
  func: (p: Position) => void;
  paramId: string;
  sceneLayerId?: string;
};

enum SidebarSection {
  Settings = "scene-settings",
  Audio = "audio",
  WalkOutAreas = "walk-out-areas",
  Images = "images",
  SceneLayers = "layers",
}

//
// COMPONENT
//
const SceneEditorCreateNew = () => {
  const { engineConfig } = useGame();

  const [onSceneMouseClick, setOnSceneMouseClick] =
    useState<OnSceneMouseClick>();

  const [activeSection, setActiveSection] = useState(SidebarSection.Settings);

  //
  // ENGINE CONFIG
  //
  const engineConfigOriginalRef = useRef<EngineConfigType>();

  //
  // DATA
  //
  const [editor, setEditor] = useState<EditorType>();

  useEffect(() => {
    engineConfigOriginalRef.current = engineConfig.state;

    // update engine config settings
    engineConfig.setState((prevState) => ({
      ...prevState,
      renderSceneWalkOutPositions: true,
      renderSceneLayerDepthLine: true,
    }));

    return () => {
      // reset engine config
      setEditor(undefined);
      if (engineConfigOriginalRef?.current) {
        engineConfig.setState(engineConfigOriginalRef.current);
      }
    };
  }, []);

  //
  // HELPERS
  //
  const updateMeta = (meta: SceneMeta) => {
    setEditor({ ...editor, meta });
  };

  const updateScene = (scene: SceneType) => {
    setEditor({ ...editor, scene });
  };

  const getCode = () => {
    return getSceneCode({
      editor,
    });
  };

  //
  // WALK PREVIEWS
  //
  const [forceWalkIn, setForceWalkIn] = useState<{
    fromDirection: Direction;
  }>();

  useEffect(() => {
    if (forceWalkIn) {
      setForceWalkIn(undefined);
    }
  }, [forceWalkIn]);

  const [forceWalkOut, setForceWalkOut] = useState<{
    walkTo: Position;
    direction: Direction;
  }>();

  useEffect(() => {
    if (forceWalkOut) {
      setForceWalkOut(undefined);
    }
  }, [forceWalkOut]);

  //
  // RENDER
  //
  return (
    <Wrapper isEditing={!editor?.scene}>
      {!editor?.scene ? (
        <SectionUploadImages setEditor={setEditor} />
      ) : (
        <>
          <DevSidebarLeft width="280px" isNotBrowserHeight />

          <Main>
            {editor?.scene && (
              <>
                <ScenePreviewWrapper>
                  <NewScenePreview
                    editor={editor}
                    onSceneMouseClick={onSceneMouseClick}
                    setOnSceneMouseClick={setOnSceneMouseClick}
                    forceWalkIn={forceWalkIn}
                    forceWalkOut={forceWalkOut}
                  />
                </ScenePreviewWrapper>

                <CodeWrapper>
                  <Box label="Code Preview" level={2} isOpen>
                    <Code code={getCode()} />
                  </Box>
                </CodeWrapper>
              </>
            )}
          </Main>

          <Sidebar name="Scene Editor" position="right" width="600px" isOpen>
            <SectionSceneSettings
              isOpen={activeSection === SidebarSection.Settings}
              onOpenChange={(v) => {
                if (v) {
                  setActiveSection(SidebarSection.Settings);
                }
              }}
              editor={editor}
              updateScene={updateScene}
              updateMeta={updateMeta}
            />

            <SectionSceneAudio
              isOpen={activeSection === SidebarSection.Audio}
              onOpenChange={(v) => {
                if (v) {
                  setActiveSection(SidebarSection.Audio);
                }
              }}
              editor={editor}
              updateScene={updateScene}
            />

            <SectionSceneTransitions
              isOpen={activeSection === SidebarSection.WalkOutAreas}
              onOpenChange={(v) => {
                if (v) {
                  setActiveSection(SidebarSection.WalkOutAreas);
                }
              }}
              editor={editor}
              setEditor={setEditor}
              updateScene={updateScene}
              onSceneMouseClick={onSceneMouseClick}
              setOnSceneMouseClick={setOnSceneMouseClick}
              setForceWalkIn={setForceWalkIn}
              setForceWalkOut={setForceWalkOut}
            />

            <SectionImages
              isOpen={activeSection === SidebarSection.Images}
              onOpenChange={(v) => {
                if (v) {
                  setActiveSection(SidebarSection.Images);
                }
              }}
              editor={editor}
              setEditor={setEditor}
            />

            <SectionSceneLayers
              isOpen={activeSection === SidebarSection.SceneLayers}
              onOpenChange={(v) => {
                if (v) {
                  setActiveSection(SidebarSection.SceneLayers);
                }
              }}
              editor={editor}
              setEditor={setEditor}
              updateScene={updateScene}
              onSceneMouseClick={onSceneMouseClick}
              setOnSceneMouseClick={setOnSceneMouseClick}
            />
          </Sidebar>

          <FooterWrapper>
            <EditorFooter editor={editor} setEditor={setEditor} />
          </FooterWrapper>
        </>
      )}
    </Wrapper>
  );
};

export default SceneEditorCreateNew;
