import { CHARACTER_DEVTOOLS_PREVIEWS } from "game-files/characters/CHARACTER_CONFIGS";
import CharacterBox from "game-engine/_dev/basic-components/CharacterList/CharacterBox";

const AssetsCharacterPage = (props: { characterConfigId: string }) => {
  const { characterConfigId } = props;

  const thumbnailSize = 46;
  const characterDevPreview = CHARACTER_DEVTOOLS_PREVIEWS.find(
    (c) => c.config?.id === characterConfigId
  );

  //
  // RENDER
  //
  return (
    <CharacterBox
      characterDevPreview={characterDevPreview}
      thumbnailSettings={{ width: thumbnailSize, height: thumbnailSize }}
      previewSettings={{ width: 100, height: 100, scale: 3 }}
      isOpen
      isDetailPage
    />
  );
};

export default AssetsCharacterPage;
