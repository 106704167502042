import { ITEM_GENDER, getItemSuffixes } from "game-files/items/_utils";

import IMG_decay_01 from "./assets/decay-01.png";
import IMG_decay_02 from "./assets/decay-02.png";
import IMG_decay_03 from "./assets/decay-03.png";
import IMG_decay_end_sprite from "./assets/decay-end-sprite.png";
import IMG_default from "./assets/default.png";
import { ItemConfigId } from "game-files/common/ids";
import { ItemConfigType } from "game-engine/types/item";
import { SpriteDirection } from "game-engine/types";

const WIDTH = 16;
const HEIGHT = 16;
const OFFSET_Y = 4;

const Glowcap: ItemConfigType = {
  configId: ItemConfigId.Glowcap,
  name: { en: "Glowcap", cz: "Světlohlávka" },

  suffix: getItemSuffixes({ gender: ITEM_GENDER.feminine }),

  // TODO - SOUNDS
  /* sounds: {
    grab: {
      soundConfigId: SOUND_CONFIGS.Test_Vial_Full_Grab.id,
    },
    drop: {
      soundConfigId: SOUND_CONFIGS.Test_Vial_Full_Drop.id,
    },
  }, */

  render: {
    image: { src: IMG_default },
    width: WIDTH,
    height: HEIGHT,
    offsetY: OFFSET_Y,
  },

  lightSource: {
    tint: {
      color: `#FF6A00`,
      amount: 1,
    },
    flicker: {
      color: `#FFCFAD`,
      amount: 0.05,
      stepDurationSec: 0.5,
    },
  },

  lightSourceDecay: {
    bySceneChange: [
      {
        render: {
          image: { src: IMG_decay_01 },
          width: WIDTH,
          height: HEIGHT,
          offsetY: OFFSET_Y,
        },

        lightSource: {
          tint: {
            color: `#FF6A00`,
            amount: 1,
          },
          flicker: {
            color: `#FFCFAD`,
            amount: 0.05,
            stepDurationSec: 0.5,
          },
        },
      },

      {
        render: {
          image: { src: IMG_decay_02 },
          width: WIDTH,
          height: HEIGHT,
          offsetY: OFFSET_Y,
        },

        lightSource: {
          tint: {
            color: `#FF6A00`,
            amount: 1,
          },
          flicker: {
            color: `#FFCFAD`,
            amount: 0.05,
            stepDurationSec: 0.5,
          },
        },
      },

      {
        render: {
          image: { src: IMG_decay_03 },
          width: WIDTH,
          height: HEIGHT,
          offsetY: OFFSET_Y,
        },

        lightSource: {
          tint: {
            color: `#FF6A00`,
            amount: 1,
          },
          flicker: {
            color: `#FFCFAD`,
            amount: 0.05,
            stepDurationSec: 0.5,
          },
        },
      },

      {
        render: {
          image: { src: IMG_decay_end_sprite },
          spriteConfig: {
            direction: SpriteDirection.Horizontal,
            frameWidth: 10,
            frameHeight: 12,
            frameCount: 21,
            frameDurationMilliseconds: 60,
            playCount: 1,
          },
          offsetY: 3,
          deleteItemOnAnimationEnd: true,
        },

        lightSource: undefined,
      },
    ],
  },
};

export default Glowcap;
