import Box from "../../../basic-components/Box";
import CharacterList from "game-engine/_dev/basic-components/CharacterList";

const AssetsCharacters = (props: { isOpen?: boolean }) => {
  //
  // RENDER
  //
  return (
    <Box
      label="Characters"
      useFlex
      gap="10px"
      childMinWidth="60%"
      isOpen={props.isOpen}
      level={1}
    >
      <CharacterList />
    </Box>
  );
};

export default AssetsCharacters;
