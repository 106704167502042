import Box, { BoxProps } from "game-engine/_dev/basic-components/Box";

import LayerImageInput from "../inputs/LayerImageInput";
import styled from "styled-components";

const BoxTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const TitleColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
`;

const Title = styled.div`
  color: #fffa;
`;

const SubTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  opacity: 0.5;
`;

const ImageThumbnail = styled.div`
  width: 100%;
  max-width: 120px;
  min-width: 120px;
  max-height: 150px;
  overflow: hidden;
  background: black;

  ${(props) => props.theme.breakpoints.S} {
    max-width: 80px;
    min-width: 80px;
  }
  ${(props) => props.theme.breakpoints.XS} {
    max-width: 60px;
    min-width: 60px;
  }

  display: flex;
  align-items: center;
  justify-content: center;
`;

const ImageFull = styled.div`
  min-width: 100%;
  background: black;
`;

const Img = styled.img`
  width: 100%;
  height: 100%;
  image-rendering: auto;
  image-rendering: crisp-edges;
  image-rendering: pixelated;
`;

const BoxWithThumbnail = (
  props: {
    label?: string;
    subLabel?: string;
    imageSrc: any;
    largeImageWhenOpen?:
      | boolean
      | { onImageChange?: (image: string, filename?: string) => void };
    children?: any;
  } & BoxProps
) => {
  //
  // RENDER
  //
  return (
    <Box
      {...props}
      label={
        <BoxTitle>
          <ImageThumbnail>
            <Img src={props.imageSrc} />
          </ImageThumbnail>
          <TitleColumn>
            <Title>{props.label}</Title>
            {props.subLabel && <SubTitle>{props.subLabel}</SubTitle>}
          </TitleColumn>
        </BoxTitle>
      }
    >
      {props.largeImageWhenOpen && (
        <Box renderContentOnly minWidth="90%">
          {(props.largeImageWhenOpen as any).onImageChange ? (
            <LayerImageInput
              width={513}
              image={props.imageSrc}
              setImage={(props.largeImageWhenOpen as any).onImageChange}
            />
          ) : (
            <ImageFull>
              <Img src={props.imageSrc} />
            </ImageFull>
          )}{" "}
        </Box>
      )}
      {props.children}
    </Box>
  );
};

export default BoxWithThumbnail;
