import breakpoints from './breakpoints'

const baseColors = {
  white: '#ffffff',
  black: '#000000',
  red: '#f03c3c',
}

const typeColors = {
  primary: baseColors.red,
}

const theme = {
  breakpoints,
  colors: {
    ...baseColors,
    ...typeColors,
  },
}

export default theme
