import NumberInput, { NumberInputRangeControllerProps } from "../NumberInput";

import InputLabel from "../_InputLabel";
import { Position } from "game-engine/types";
import styled from "styled-components";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1px;

  & > * {
    width: 45%;
    flex: 1;
  }
`;

const PositionInput = (props: {
  label?: string;
  prefixes?: { x: string; y: string };
  prefixWidth?: string;
  value: Position;
  onChange: (p: Position) => void;
  onClick?: () => void;
  style?: any;
  rangeControllerX?: NumberInputRangeControllerProps;
  rangeControllerY?: NumberInputRangeControllerProps;
  noBorder?: boolean;
}) => {
  //
  // RENDER
  //
  return (
    <Wrapper style={props.style}>
      {props.label && <InputLabel label={props.label} />}

      <Row>
        <NumberInput
          prefix={props.prefixes?.x || "x"}
          prefixWidth={props.prefixWidth}
          value={(props.value || { x: 0, y: 0 }).x}
          onChange={(v) =>
            props.onChange({
              ...(props.value || { x: 0, y: 0 }),
              x: v,
            })
          }
          onClick={props.onClick}
          rangeController={props.rangeControllerX}
          noBorder={props.noBorder}
        />
        <NumberInput
          prefix={props.prefixes?.y || "y"}
          prefixWidth={props.prefixWidth}
          value={(props.value || { x: 0, y: 0 }).y}
          onChange={(v) =>
            props.onChange({
              ...(props.value || { x: 0, y: 0 }),
              y: v,
            })
          }
          onClick={props.onClick}
          rangeController={props.rangeControllerY}
          noBorder={props.noBorder}
        />
      </Row>
    </Wrapper>
  );
};

export default PositionInput;
