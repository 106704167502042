/* eslint-disable react-hooks/exhaustive-deps */

import { getKonvaImageFilters, hexToRgba } from "game-engine/utils";
import { useCallback, useEffect, useRef, useState } from "react";

import { ImageFilters } from "game-engine/types";
import Konva from "konva";

//
// CUSTOM FILTER - COLORIZE-BW
//
(Konva.Filters as any).ColorizeBW = function (imageData) {
  const { data } = imageData;
  const [rBlack, gBlack, bBlack] = this.colorBlack || [0, 0, 0];
  const [rWhite, gWhite, bWhite] = this.colorWhite || [255, 255, 255]; // Use white default if not set

  for (let i = 0; i < data.length; i += 4) {
    // Calculate grayscale value (0-255)
    const avg = (data[i] + data[i + 1] + data[i + 2]) / 3;

    // Normalize grayscale value to the range 0-1
    const ratio = avg / 255; // 0 for black, 1 for white

    // Interpolate between colorBlack and colorWhite
    data[i] = rBlack * (1 - ratio) + rWhite * ratio; // Red
    data[i + 1] = gBlack * (1 - ratio) + gWhite * ratio; // Green
    data[i + 2] = bBlack * (1 - ratio) + bWhite * ratio; // Blue
  }

  return imageData;
};

//
// CUSTOM FILTER - TINT
//
(Konva.Filters as any).Tint = function (imageData) {
  const { data } = imageData;

  const tintColor = this.tintColor || [255, 0, 0];
  const tintAmount = this.tintAmount !== undefined ? this.tintAmount : 1;
  const tintTargetColor = this.tintTargetColor;
  const tintTargetColorBrightness = this.tintTargetColorBrightness ?? 1;
  const tintTargetColorThreshold = this.tintTargetColorThreshold ?? 50;

  // Helper function to check if two colors are within the specified threshold
  function isColorClose(originalColor, tintTargetColor, threshold) {
    return (
      Math.abs(originalColor[0] - tintTargetColor[0]) < threshold &&
      Math.abs(originalColor[1] - tintTargetColor[1]) < threshold &&
      Math.abs(originalColor[2] - tintTargetColor[2]) < threshold
    );
  }

  for (let i = 0; i < data.length; i += 4) {
    const originalRed = data[i];
    const originalGreen = data[i + 1];
    const originalBlue = data[i + 2];

    // Create an array for the original color
    const originalColor = [originalRed, originalGreen, originalBlue];

    // Check if the original color should be tinted
    const shouldTint =
      !tintTargetColor ||
      isColorClose(originalColor, tintTargetColor, tintTargetColorThreshold);

    if (shouldTint) {
      // Calculate brightness of the pixel (relative luminance)
      const brightness =
        ((0.299 * originalRed + 0.587 * originalGreen + 0.114 * originalBlue) /
          255) *
        tintTargetColorBrightness;

      // Apply tint effect: blend original color with tint color based on the tint amount
      data[i] =
        originalRed * (1 - tintAmount) + tintColor[0] * tintAmount * brightness; // Red
      data[i + 1] =
        originalGreen * (1 - tintAmount) +
        tintColor[1] * tintAmount * brightness; // Green
      data[i + 2] =
        originalBlue * (1 - tintAmount) +
        tintColor[2] * tintAmount * brightness; // Blue
    }
  }

  return imageData;
};

//
// HOOK
//
const useLocalImage = (props: {
  src: any;
  isDevMode?: boolean;
  clickThroughAlpha?: boolean;
  imageRef?: { current: Konva.Image };
  filters?: ImageFilters;
}) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const imageRef = useRef<any>();
  const [imageData, setImageData] = useState<{
    width: number;
    height: number;
  }>();

  // Store the actual image instance in a ref for access outside of the hook
  const imgRef = useRef<HTMLImageElement | null>(null);

  useEffect(() => {
    // Reset image loaded state
    setImageLoaded(false);

    // Create a new Image instance
    const newImage = new window.Image();
    newImage.src = props.src;

    // Store the image in a ref for external access
    imgRef.current = newImage;

    const handleLoad = () => {
      if (!imageLoaded) {
        // Only set it to true if it's currently false
        setImageLoaded(true);
      }
    };

    const handleError = (e) => {
      console.error("Image load error", props.src, e);
    };

    newImage.onload = handleLoad;
    newImage.onerror = handleError;

    // Cleanup function to prevent memory leaks
    return () => {
      newImage.onload = null; // Clear the onload handler
      newImage.onerror = null; // Clear the onerror handler
      imgRef.current = null; // Clear the image reference
    };
  }, [props.src]); // Dependency on props.src

  const getImageRef = () => {
    if (props.imageRef !== undefined) {
      return props.imageRef;
    }
    return imageRef;
  };

  const drawHitFromCache = useCallback(
    (img: Konva.Image) => {
      if (img && imageLoaded) {
        const alphaThreshold = props.clickThroughAlpha ? 0 : 255;
        img.cache();
        img.drawHitFromCache(alphaThreshold);

        setImageData({
          width: img.width(),
          height: img.height(),
        });
      }
    },
    [imageLoaded, props.clickThroughAlpha]
  );

  useEffect(() => {
    if (props.clickThroughAlpha && imageLoaded && getImageRef()?.current) {
      drawHitFromCache(getImageRef().current);
    }
  }, [imageLoaded, props.isDevMode]);

  // New useEffect to apply color and other filters
  useEffect(() => {
    const imageNode = imageRef.current;

    if (imageNode && imageNode.width() > 0 && imageNode.height() > 0) {
      // Reset filters before applying new ones
      imageNode.filters([]);

      /**
       * APPLY FILTERS
       * - the order in which orders are applied MATTERS!
       */
      if (props.filters) {
        let activeFilters = [];

        //
        // CUSTOM FILTER - COLORIZE BW
        //
        if (props.filters?.colorizeBW) {
          imageNode.cache();
          imageNode.colorBlack = hexToRgba(
            props.filters?.colorizeBW.colorBlack
          );
          imageNode.colorWhite = hexToRgba(
            props.filters?.colorizeBW.colorWhite
          );

          activeFilters.push((Konva.Filters as any).ColorizeBW);
        }

        //
        // KONVA FILTERS
        //
        const { normalizedFilters, activeFilters: activeKonvaFilters } =
          getKonvaImageFilters(props.filters);

        // Apply the active filters to the image
        activeFilters.push(...activeKonvaFilters);

        // Set the normalized filter properties using setter methods
        if (normalizedFilters.brightness !== undefined) {
          imageNode.brightness(normalizedFilters.brightness);
        }
        if (normalizedFilters.contrast !== undefined) {
          imageNode.contrast(normalizedFilters.contrast);
        }
        if (normalizedFilters.grayscale !== undefined) {
          imageNode.grayscale(normalizedFilters.grayscale);
        }
        if (normalizedFilters.hue !== undefined) {
          imageNode.hue(normalizedFilters.hue);
        }
        if (normalizedFilters.saturation !== undefined) {
          imageNode.saturation(normalizedFilters.saturation);
        }
        if (normalizedFilters.luminance !== undefined) {
          imageNode.luminance(normalizedFilters.luminance);
        }
        if (normalizedFilters.invert !== undefined) {
          imageNode.invert(normalizedFilters.invert);
        }
        if (normalizedFilters.pixelate !== undefined) {
          imageNode.pixelate(normalizedFilters.pixelate);
        }
        if (normalizedFilters.sepia !== undefined) {
          imageNode.sepia(normalizedFilters.sepia);
        }
        if (normalizedFilters.threshold !== undefined) {
          imageNode.threshold(normalizedFilters.threshold);
        }
        if (normalizedFilters.emboss !== undefined) {
          imageNode.emboss(normalizedFilters.emboss);
        }
        if (normalizedFilters.enhance !== undefined) {
          imageNode.enhance(normalizedFilters.enhance);
        }
        if (normalizedFilters.noise !== undefined) {
          imageNode.noise(normalizedFilters.noise);
        }
        if (normalizedFilters.red !== undefined) {
          imageNode.red(normalizedFilters.red);
        }
        if (normalizedFilters.green !== undefined) {
          imageNode.green(normalizedFilters.green);
        }
        if (normalizedFilters.blue !== undefined) {
          imageNode.blue(normalizedFilters.blue);
        }

        //
        // CUSTOM FILTER - TINT
        //
        if (props.filters?.tint) {
          const { color, amount, tintTargetColor } = props.filters.tint;
          imageNode.tintColor = hexToRgba(color);
          imageNode.tintAmount = amount;
          imageNode.tintTargetColor = hexToRgba(tintTargetColor?.color);
          imageNode.tintTargetColorThreshold = tintTargetColor?.threshold;
          imageNode.tintTargetColorBrightness = tintTargetColor?.brightness;

          activeFilters.push((Konva.Filters as any).Tint);
        }

        imageNode.filters(activeFilters);
      }

      imageNode.getLayer()?.batchDraw();
    }
  }, [imageLoaded, props.filters]);

  return {
    image: imgRef.current,
    imageLoaded,
    imageRef: getImageRef(),
    imageData,
  };
};

export default useLocalImage;
