const COLORS = {
  white: `#ffffff`,
  black: `#000000`,

  blue: "#0F52BA",
  blueLight: "#16a5c9",
  blueShadow: "#00115f",
  cyan: `#78F5FF`,
  orange: `#FFD2B4`,
  red: `#FFB4B4`,
};

export default COLORS;
