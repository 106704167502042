import Box, { BoxMessage } from "game-engine/_dev/basic-components/Box";

import AssetBox from "game-engine/_dev/basic-components/Box/AssetBox";
import AssetPreview from "game-engine/_dev/basic-components/AssetPreview";
import Button from "../Button";
import Checkbox from "../inputs/Checkbox";
import Divider from "../Divider";
import InfoTable from "../InfoTable";
import Item from "game-engine/components/game-elements/Item";
import { ItemType } from "game-engine/types";
import JsonView from "game-engine/_dev/basic-components/JsonView";
import { URL_PARAM_ITEM_CONFIG_ID } from "game-engine/tabs/TabAssets";
import { getItemConfigById } from "game-files/items/ITEM_CONFIGS";
import { stringifyTranslations } from "game-engine/utils";
import styled from "styled-components";
import { useState } from "react";

const BoxLabel = styled.div`
  flex: 1;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Name = styled.div``;

const ItemBox = (props: {
  item: ItemType & { messages?: BoxMessage[] };
  thumbnail?: { width?: number; height?: number; imgFillPercentage?: string };
  preview?: { width?: number; height?: number; scale?: number };
  infoInScene?: {
    noSceneId?: boolean;
    noPosition?: boolean;
  };
  isOpen?: boolean;
  isAssetPreview?: boolean;
  isDetailPage?: boolean;
}) => {
  const { item, preview, infoInScene, isAssetPreview, isDetailPage } = props;

  const itemConfig = getItemConfigById(item.configId);

  const hasAdditionalInfo =
    item.inInventory ||
    item.inScene ||
    item.inSceneOverlay ||
    item.inTrash ||
    item.inCursor;

  const previewWidth = preview?.width || 60;
  const previewHeight = preview?.height || 60;
  const previewScale = preview?.scale || 4;

  const finalWidth = previewWidth * previewScale;
  const imgFillPercentage = props.thumbnail?.imgFillPercentage || "70%";

  const [lightSourceDecayIndex, setLightSourceDecayIndex] = useState<number>();

  const renderBoxLabel = () => {
    const name = stringifyTranslations(itemConfig.name);

    if (!isDetailPage) {
      return (
        <BoxLabel>
          <Name>{name}</Name>

          <Button
            onClick={() => {
              window.open(
                `${window.location.pathname}?tab=assets&${URL_PARAM_ITEM_CONFIG_ID}=${itemConfig.configId}`,
                "_blank"
              );
            }}
          >
            Open in a new tab
          </Button>
        </BoxLabel>
      );
    }

    return name;
  };

  //
  // RENDER
  //
  return (
    <AssetBox
      label={renderBoxLabel()}
      /* subLabel={item.id} */
      thumbnail={
        !itemConfig.render.spriteConfig
          ? {
              src: itemConfig.render.image.src,
              imgFillPercentage,
              width: props.thumbnail?.width,
              height: props.thumbnail?.height,
            }
          : undefined
      }
      spriteThumbnail={
        itemConfig.render.spriteConfig
          ? {
              src: itemConfig.render.image.src,
              spriteConfig: itemConfig.render.spriteConfig,
              width: props.thumbnail?.width,
              height: props.thumbnail?.height,
              imgFillPercentage,
            }
          : undefined
      }
      level={2}
      childMinWidth={`${finalWidth + 20}px`}
      messages={item.messages}
      isOpen={props.isOpen}
    >
      {!isAssetPreview && (
        <>
          {/* DATA IF NOT IN-SCENE OR IN-INVENTORY */}
          {!item.inScene && !item.inSceneOverlay && !item.inInventory && (
            <Box
              renderContentOnly
              level={3}
              isOpen
              contentPaddingTop="20px"
              contentPaddingBottom="15px"
            >
              <InfoTable
                data={[
                  item.id && {
                    label: "item id",
                    value: item.id,
                  },
                ]}
              />
            </Box>
          )}

          {/* SCENE DATA */}
          {item.inScene && (
            <Box
              renderContentOnly
              label="Scene Data"
              level={3}
              isOpen
              contentPaddingTop="20px"
              contentPaddingBottom="15px"
            >
              <InfoTable
                data={[
                  item.id && {
                    label: "item id",
                    value: item.id,
                  },
                  !infoInScene?.noSceneId && {
                    label: "scene id",
                    value: item.inScene.sceneId,
                  },
                  !infoInScene?.noPosition && {
                    label: "position",
                    value: `[${item.inScene.position.x}, ${item.inScene.position.y}]`,
                  },
                  item.sceneChangeCounter !== undefined && {
                    label: "scene change counter",
                    value: item.sceneChangeCounter,
                  },
                ]}
              />
            </Box>
          )}

          {/* SCENE OVERLAY DATA */}
          {item.inSceneOverlay && (
            <Box
              renderContentOnly
              label="Scene Overlay Data"
              level={3}
              isOpen
              contentPaddingTop="20px"
              contentPaddingBottom="15px"
            >
              <InfoTable
                data={[
                  item.id && {
                    label: "item id",
                    value: item.id,
                  },
                  {
                    label: "scene id",
                    value: item.inSceneOverlay.sceneId,
                  },
                  {
                    label: "position",
                    value: `[${item.inSceneOverlay.position?.x}, ${item.inSceneOverlay.position?.y}]`,
                  },
                  {
                    label: "depthY",
                    value: item.inSceneOverlay.depthY,
                  },
                  {
                    label: "scale",
                    value: item.inSceneOverlay.scale,
                  },
                  {
                    label: "deleteAfterSec",
                    value: item.inSceneOverlay.deleteAfterSec,
                  },
                  item.sceneChangeCounter !== undefined && {
                    label: "scene change counter",
                    value: item.sceneChangeCounter,
                  },
                ]}
              />
            </Box>
          )}

          {/* INVENTORY DATA */}
          {item.inInventory && (
            <Box
              renderContentOnly
              label="Inventory Data"
              level={3}
              isOpen
              contentPaddingTop="20px"
              contentPaddingBottom="15px"
            >
              <InfoTable
                data={[
                  item.id && {
                    label: "item id",
                    value: item.id,
                  },
                  {
                    label: "inventory index",
                    value: item.inInventory.index,
                  },
                  item.sceneChangeCounter !== undefined && {
                    label: "scene change counter",
                    value: item.sceneChangeCounter,
                  },
                ]}
              />
            </Box>
          )}
        </>
      )}

      {/* PREVIEW */}
      <Box
        label="Preview"
        level={3}
        isOpen={!hasAdditionalInfo}
        useFlex
        childMinWidth={`${previewWidth * previewScale}px`}
      >
        <AssetPreview
          width={previewWidth}
          height={previewHeight}
          scale={previewScale}
        >
          <Item
            item={{
              id: itemConfig.configId,
              configId: itemConfig.configId,
              sceneChangeCounter: lightSourceDecayIndex,
            }}
            position={{
              x: Math.round(previewWidth * 0.5),
              y: Math.round(previewHeight * 0.7),
            }}
            renderOrigin
          />
        </AssetPreview>

        {/* LIGHT SOURCE DECAY */}
        {itemConfig?.lightSourceDecay ? (
          <Box label="Light Source Decay" level={3} isOpen childMinWidth="90%">
            {itemConfig?.lightSourceDecay?.bySceneChange?.map(
              (lightSourceDecayItem, i) => {
                return (
                  <Checkbox
                    key={i}
                    label={`Decay ${i + 1}`}
                    value={lightSourceDecayIndex === i}
                    onChange={(v) => {
                      if (v) {
                        setLightSourceDecayIndex(i);
                      } else {
                        setLightSourceDecayIndex(undefined);
                      }
                    }}
                  />
                );
              }
            )}
          </Box>
        ) : null}
      </Box>

      {/* CONFIG */}
      <Box
        label="Config"
        level={3}
        isOpen={!hasAdditionalInfo}
        childMinWidth="90%"
      >
        {hasAdditionalInfo && (
          <>
            <Divider name="item:" marginTop="0" />
            <JsonView json={item} autoExpandToLevel={1} hideRoot />{" "}
            <Divider name="config:" />
          </>
        )}

        <JsonView
          json={itemConfig}
          autoExpandToLevel={isDetailPage ? 2 : 1}
          hideRoot
        />
      </Box>
    </AssetBox>
  );
};

export default ItemBox;
