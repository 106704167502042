/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useRef, useState } from "react";

import { randomInRange } from "game-engine/utils";

const useIdle = (props: { timeoutMinSec: number; timeoutMaxSec: number }) => {
  const idleTimeoutRef = useRef<any>();

  const [isIdle, setIsIdle] = useState(false);
  const setIsIdleRef = useRef(setIsIdle);

  useEffect(() => {
    // clear on unmount
    return clearIdleTimer;
  }, []);

  const clearIdleTimer = () => {
    if (idleTimeoutRef?.current) {
      clearTimeout(idleTimeoutRef.current);
    }
  };

  const startIdleTimer = () => {
    setIsIdleRef.current(false);

    clearIdleTimer();

    // generate random wait time from given range
    const timeout =
      randomInRange({
        min: props.timeoutMinSec,
        max: props.timeoutMaxSec,
      }) * 1000; // convert to milliseconds

    idleTimeoutRef.current = setTimeout(
      () => setIsIdleRef.current(true),
      timeout
    );
  };

  //
  // RETURN
  //
  return {
    isIdle,
    startIdleTimer,
    clearIdleTimer,
  };
};

export default useIdle;
