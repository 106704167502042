import AssetsAnimations from "./AssetsAnimations";
import AssetsAudio from "./AssetsAudio";
import AssetsCharacters from "./AssetsCharacters";
import AssetsCursor from "./AssetsCursor";
import AssetsFont from "./AssetsFont";
import AssetsGuiElements from "./AssetsGuiElements";
import AssetsItems from "./AssetsItems";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const Assets = () => {
  return (
    <Wrapper>
      <AssetsCharacters />
      <AssetsItems />
      <AssetsAnimations />
      <AssetsGuiElements />
      <AssetsCursor />
      <AssetsFont />
      <AssetsAudio />
    </Wrapper>
  );
};

export default Assets;
