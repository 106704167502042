import { ACTION } from "game-engine/utils";
import { GameObjective } from "game-files/gameObjectives";
import ImagePreview from "./assets/preview_00.png";
import Layer_01_Background from "./assets/layer - 01 - background_00.png";
import Layer_02_Background_Rocks from "./assets/layer - 02 - background - rocks_00.png";
import Layer_03_Background_Trees from "./assets/layer - 03 - background - trees_00.png";
import Layer_04_Background_Bushes from "./assets/layer - 04 - background - bushes_00.png";
import Layer_05_Trees from "./assets/layer - 05 - trees_00.png";
import Layer_06_Rock from "./assets/layer - 06 - rock_00.png";
import Layer_07_Tree_Right from "./assets/layer - 07 - tree - right_00.png";
import Layer_08_Rock_2 from "./assets/layer - 08 - rock-2_00.png";
import Layer_09_Front_Trees from "./assets/layer - 09 - front - trees_00.png";
import Layer_Map_Depth from "./assets/layer - 00 - DEPTH MAP_00.png";
import Layer_Map_Drop from "./assets/layer - 00 - DROP MAP_00.png";
import Layer_Map_Walk from "./assets/layer - 00 - WALK MAP_00.png";
import SHARED_DATA from "../../SHARED_DATA";
import SOUND_CONFIGS from "game-files/audio/SOUND_CONFIGS";
import { SceneType } from "game-engine/types";
import { TestScenesMusic } from "../_shared/music";

const Test_Forest_L_R_D: SceneType = {
  configId: "Test_Forest_L_R_D",
  name: {
    [GameObjective.GAME_START]: {
      en: "Ancient Forest 02",
      cz: "Prastarý les 02",
    },
  },

  preview: { src: ImagePreview },
  uniqueId: undefined, // IS GENERATED FROM SCENES LAYOUT
  sceneNeighbors: undefined, // IS GENERATED FROM SCENES LAYOUT

  sceneWalkPaths: {
    [GameObjective.GAME_START]: {
      // NOT ALL WALK-PATHS ARE NECESSARILY USED (mainly for reusable scenes)
      // -> ACCESSIBLE WALK-PATHS ARE SPECIFIED IN SCENES LAYOUT MATRIX
      left: {
        edgeWalkY: 120,
        walkTo: { x: 80, y: 120 },
      },
      right: {
        edgeWalkY: 128,
        walkTo: { x: 292, y: 128 },
      },
      down: {
        edgeWalkX: 180,
        walkTo: { x: 180, y: 140 },
      },
    },
  },

  audio: {
    [GameObjective.GAME_START]: {
      music: TestScenesMusic,
      sound: {
        soundConfigId: SOUND_CONFIGS.Test_Bird.id,
        volume: 0.5,
        loop: true,
      },
    },
  },

  images: {
    background: {
      dataByCurrentObjective: {
        [GameObjective.GAME_START]: {
          image: { src: Layer_01_Background },
          dropMap: { src: Layer_Map_Drop },
          walkMap: { src: Layer_Map_Walk },
          depthMap: { src: Layer_Map_Depth },
          depthSettings: {
            //scaleMin: 0.5,
            //scaleMax: 1,
          },
        },
      },
      actionsByCurrentObjective: undefined,
    },

    layers: [
      {
        id: "Layer_02_Background_Rocks",
        dataByCurrentObjective: {
          [GameObjective.GAME_START]: {
            image: { src: Layer_02_Background_Rocks },
          },
        },
        actionsByCurrentObjective: {
          [GameObjective.GAME_START]: {
            actions: [
              ACTION.talkRandom({
                dialogOptions: [
                  ...SHARED_DATA.dialogOptions.rockLarge,
                  ...SHARED_DATA.dialogOptions.obstacleNoSceneWalkOut,
                ],
              }),
            ],
          },
        },
      },

      {
        id: "Layer_03_Background_Trees",
        dataByCurrentObjective: {
          [GameObjective.GAME_START]: {
            image: { src: Layer_03_Background_Trees },
          },
        },
        actionsByCurrentObjective: {
          [GameObjective.GAME_START]: {
            actions: [
              ACTION.talkRandom({
                dialogOptions: [
                  ...SHARED_DATA.dialogOptions.tree,
                  ...SHARED_DATA.dialogOptions.forest,
                  ...SHARED_DATA.dialogOptions.forestBackground,
                ],
              }),
            ],
          },
        },
      },

      {
        id: "Layer_04_Background_Bushes",
        dataByCurrentObjective: {
          [GameObjective.GAME_START]: {
            image: { src: Layer_04_Background_Bushes },
          },
        },
        actionsByCurrentObjective: {
          [GameObjective.GAME_START]: {
            actions: [
              ACTION.talkRandom({
                dialogOptions: [...SHARED_DATA.dialogOptions.bush],
              }),
            ],
          },
        },
      },

      {
        id: "Layer_05_Trees",
        depthY: 66,
        dataByCurrentObjective: {
          [GameObjective.GAME_START]: {
            image: { src: Layer_05_Trees },
          },
        },
        actionsByCurrentObjective: {
          [GameObjective.GAME_START]: {
            actions: [
              ACTION.talkRandom({
                dialogOptions: [
                  ...SHARED_DATA.dialogOptions.tree,
                  ...SHARED_DATA.dialogOptions.forest,
                ],
              }),
            ],
          },
        },
      },

      {
        id: "Layer_06_Rock",
        depthY: 93,
        dataByCurrentObjective: {
          [GameObjective.GAME_START]: {
            image: { src: Layer_06_Rock },
          },
        },
        actionsByCurrentObjective: {
          [GameObjective.GAME_START]: {
            actions: [
              ACTION.talkRandom({
                dialogOptions: [...SHARED_DATA.dialogOptions.rockSmall],
              }),
            ],
          },
        },
      },

      {
        id: "Layer_07_Tree_Right",
        depthY: 102,
        dataByCurrentObjective: {
          [GameObjective.GAME_START]: {
            image: { src: Layer_07_Tree_Right },
          },
        },
        actionsByCurrentObjective: {
          [GameObjective.GAME_START]: {
            actions: [
              ACTION.talkRandom({
                dialogOptions: [
                  ...SHARED_DATA.dialogOptions.tree,
                  ...SHARED_DATA.dialogOptions.treeOld,
                  ...SHARED_DATA.dialogOptions.forest,
                ],
              }),
            ],
          },
        },
      },

      {
        id: "Layer_08_Rock_2",
        depthY: 137,
        dataByCurrentObjective: {
          [GameObjective.GAME_START]: {
            image: { src: Layer_08_Rock_2 },
          },
        },
        actionsByCurrentObjective: {
          [GameObjective.GAME_START]: {
            actions: [
              ACTION.talkRandom({
                dialogOptions: [...SHARED_DATA.dialogOptions.rockSmall],
              }),
            ],
          },
        },
      },

      {
        id: "Layer_09_Front_Trees",
        depthY: 10000,
        dataByCurrentObjective: {
          [GameObjective.GAME_START]: {
            image: { src: Layer_09_Front_Trees },
          },
        },
        actionsByCurrentObjective: {
          [GameObjective.GAME_START]: {
            actions: [
              ACTION.talkRandom({
                dialogOptions: [
                  ...SHARED_DATA.dialogOptions.tree,
                  ...SHARED_DATA.dialogOptions.forest,
                ],
              }),
            ],
          },
        },
      },
    ],
  },
};

export default Test_Forest_L_R_D;
