import { MENU_IDS, MenuProps } from "..";

import GAME_CONFIG from "game-files/gameConfig";
import MenuButton from "../menu-elements/MenuButton";
import MenuWrapper from "../menu-elements/MenuWrapper";
import useGame from "game-engine/hooks/useGame";

const DeathMenu = (props: MenuProps) => {
  const { isActive, openSubMenu, closeMenu, onExitGame } = props;
  const { gameFns } = useGame();

  const canvasX = GAME_CONFIG.window.canvasDimensions.x;

  const menuWidth = canvasX - 90;
  const menuHeight = 110;

  const buttonWidth = menuWidth - 40;
  const buttonPaddingX = 18;

  const onLoadDeathsave = () => {
    gameFns.loadDeathsavedGame();
    closeMenu();
  };

  return (
    <MenuWrapper
      isActive={isActive}
      width={menuWidth}
      height={menuHeight}
      title={{ en: "Rest in peace, Felix.", cz: "Odpočívej v pokoji, Felixi." }}
      paddingTop={12}
      titleGap={10}
    >
      {[
        {
          label: {
            en: "Load position before death",
            cz: "Nahrát pozici před úmrtím",
          },
          onClick: onLoadDeathsave,
        },
        {
          label: {
            en: "Load saved game",
            cz: "Nahrát uloženou hru",
          },
          onClick: () => openSubMenu({ menuId: MENU_IDS.loadGame }),
        },
        {
          label: { en: "Quit playing", cz: "Opustit hru" },
          onClick: () => onExitGame(),
        },
      ].map((p, i) => (
        <MenuButton
          key={i}
          x={Math.round(menuWidth / 2)}
          width={buttonWidth}
          centered
          paddingX={buttonPaddingX}
          label={p.label}
          onClick={p.onClick}
        />
      ))}
    </MenuWrapper>
  );
};

export default DeathMenu;
