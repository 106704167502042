import {
  ItemConfigType,
  ItemRenderType,
  ItemType,
} from "game-engine/types/item";

import SpriteAnimated from "game-engine/components/basic-elements/SpriteAnimated";
import { SpriteConfigType } from "game-engine/types";
import { getImageOffset } from "game-engine/utils";
import useGame from "game-engine/hooks/useGame";

const ItemImageDefault = (props: {
  item: ItemType;
  itemConfig: ItemConfigType;
  render: ItemRenderType;
  width: number;
  height: number;
  onImageLoaded?: () => void;
  isHidden?: boolean;
}) => {
  const { item, itemConfig, render, width, height, onImageLoaded, isHidden } =
    props;
  const { gameFns, engineConfig, logger } = useGame();

  if (!render.image?.src) {
    logger.error(
      `default item image missing (${itemConfig.configId})`,
      itemConfig
    );
    return null;
  }

  const spriteConfig: SpriteConfigType = {
    frameCount: 1,
    frameWidth: width,
    frameHeight: height,
    ...(render.spriteConfig || {}),
  };

  //
  // RENDER - HANDLE STATIC IMAGES AS SINGLE-FRAME SPRITES FOR UNIFIED APPROACH
  //
  return (
    <SpriteAnimated
      src={render.image.src}
      spriteConfig={spriteConfig}
      onImageLoaded={onImageLoaded}
      onAnimationEnd={() => {
        if (render.deleteItemOnAnimationEnd) {
          gameFns.deleteItem(item);
        }
      }}
      controlledAnimation={{ playAnimation: !isHidden }}
      renderOutline={engineConfig.state.renderItemOutline}
      renderFill={engineConfig.state.renderItemFill}
      outlineColor={engineConfig.state.itemOutlineColor}
      fillColor={engineConfig.state.itemFillColor}
      loggerString={`item animation (${itemConfig.configId})`}
      position={getImageOffset({ width, height, offsetY: render.offsetY })}
    />
  );
};

export default ItemImageDefault;
