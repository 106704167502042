import { useCallback, useEffect, useRef } from "react";

type CallbackFunction = (...args: any[]) => void;

//
// DEBOUNCE - AGGREGATES FREQUENT CALLS TO AVOID EXECUTING A FUNCTION TOO OFTEN
//
const useDebounce = (callback: CallbackFunction, delay: number) => {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const debouncedCallback = useCallback(
    (...args: any[]) => {
      // Clear the previous timeout if any
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      // Set the new timeout
      timeoutRef.current = setTimeout(() => {
        callback(...args);
      }, delay);
    },
    [callback, delay] // Recreate if callback or delay changes
  );

  // Clean up on unmount
  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return debouncedCallback;
};

export default useDebounce;
