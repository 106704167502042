import { Group } from "react-konva";
import MenuButton from "./MenuButton";
import { TranslatedString } from "game-engine/types";

type InnerButtonProps = {
  label: string | TranslatedString;
  width?: number;
  onClick?: () => void;
  isHidden?: boolean;
};

const MenuButtonsLeftRight = (props: {
  x?: number;
  y?: number;
  width: number;
  button1: InnerButtonProps;
  button2: InnerButtonProps;
}) => {
  const { x = 0, y = 0, width, button1, button2 } = props;

  const btnDefaultWidth = 88;

  //
  // RENDER
  //
  return (
    <Group x={x} y={y}>
      {button1.isHidden ? null : (
        <MenuButton
          width={button1.width || btnDefaultWidth}
          label={button1.label}
          onClick={button1.onClick}
          centeredText
        />
      )}

      {button2.isHidden ? null : (
        <MenuButton
          x={width - (button2.width || btnDefaultWidth)}
          width={button2.width || btnDefaultWidth}
          label={button2.label}
          onClick={button2.onClick}
          centeredText
        />
      )}
    </Group>
  );
};

export default MenuButtonsLeftRight;
