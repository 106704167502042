import { ActionQueueType } from "game-engine/types";
import { GameObjective } from "game-files/gameObjectives";

//
// GET ACTIONS BY CURRENT OBJECTIVE
//
export const getSceneSkillActionsByCurrentObjective = (props: {
  actionsByCurrentObjective: {
    [key in GameObjective]?: {
      actionsBeforeSkill?: ActionQueueType;
      actions?: ActionQueueType;
    };
  };
  currentObjective: GameObjective;
}) => {
  const { actionsByCurrentObjective, currentObjective } = props;

  let actionsBeforeSkill: ActionQueueType = undefined;
  let actions: ActionQueueType = undefined;

  if (actionsByCurrentObjective) {
    for (let obj = currentObjective; obj >= 0; obj--) {
      const currentActionsBeforeSkill =
        actionsByCurrentObjective[obj]?.actionsBeforeSkill;
      const currentActions = actionsByCurrentObjective[obj]?.actions;

      if (!actionsBeforeSkill && currentActionsBeforeSkill) {
        actionsBeforeSkill = currentActionsBeforeSkill;
      }
      if (!actions && currentActions) {
        actions = currentActions;
      }
      if (actions && actionsBeforeSkill) {
        break;
      }
    }
  }

  return {
    actionsBeforeSkill: actionsBeforeSkill ?? [],
    actions: actions ?? [],
  };
};
