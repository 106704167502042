import {
  CharacterRenderType,
  Direction,
  SpriteConfigType,
} from "game-engine/types";

//
// IS MIRRORED
//
export const isCharacterMirrored = (props: {
  facing: Direction;
  defaultFacing: Direction;
}) => {
  const isScalable =
    (props.facing && props.facing === Direction.left) ||
    (props.facing && props.facing === Direction.right);
  const isMirrored = isScalable && props.facing !== props.defaultFacing;

  return isMirrored;
};

//
// GET CHARACTER DIMENSIONS
//
export const getCharacterDimensions = (
  characterRender: CharacterRenderType
) => {
  const spriteConfig: SpriteConfigType = characterRender.default?.spriteConfig;

  const width = spriteConfig?.frameWidth ?? characterRender.default.width;
  const height = spriteConfig?.frameHeight ?? characterRender.default.height;

  return { width, height };
};
