import * as actionsArray from "./actions_array";
import * as actionsAudio from "./actions_audio";
import * as actionsCharacterAnimation from "./actions_character_animation";
import * as actionsCharacterDeath from "./actions_character_death";
import * as actionsCharacterFace from "./actions_character_face";
import * as actionsCharacterPoison from "./actions_character_poison";
import * as actionsCharacterTalk from "./actions_character_talk";
import * as actionsCharacterWalk from "./actions_character_walk";
import * as actionsCinematics from "./actions_cinematics";
import * as actionsConditional from "./actions_conditional";
import * as actionsEffectFadeScene from "././actions_effect_fadeScene";
import * as actionsEffectFadeViewport from "././actions_effect_fadeViewport";
import * as actionsEffectShakeViewport from "./actions_effect_shakeViewport";
import * as actionsEvent from "./actions_event";
import * as actionsExecute from "./actions_execute";
import * as actionsGuiCustomPopUp from "./actions_gui_customPopUp";
import * as actionsItemDeleteItem from "./actions_item_deleteItem";
import * as actionsItemNewItem from "./actions_item_newItem";
import * as actionsItemPutToSceneOverlay from "./actions_item_putToSceneOverlay";
import * as actionsItemSwapConfig from "./actions_item_swapConfig";
import * as actionsObjective from "./actions_objective";
import * as actionsOverrideMusic from "./actions_audio_overrideMusic";
import * as actionsPause from "./actions_pause";
import * as actionsSaveGame from "./actions_saveGame";
import * as actionsScene from "./actions_scene";
import * as actionsSceneOverlayAnimation from "./actions_sceneOverlayAnimation";
import * as actionsSkill from "./actions_skill";
import * as actionsSound from "./actions_sound";

//
// ACTIONS
//
export const ACTION = {
  ...actionsArray,
  ...actionsConditional,
  ...actionsCharacterWalk,
  ...actionsCharacterTalk,
  ...actionsCharacterFace,
  ...actionsCharacterDeath,
  ...actionsCharacterAnimation,
  ...actionsCharacterPoison,
  ...actionsExecute,
  ...actionsItemSwapConfig,
  ...actionsItemDeleteItem,
  ...actionsItemNewItem,
  ...actionsItemPutToSceneOverlay,
  ...actionsSaveGame,
  ...actionsPause,
  ...actionsAudio,
  ...actionsOverrideMusic,
  ...actionsSound,
  ...actionsScene,
  ...actionsSceneOverlayAnimation,
  ...actionsObjective,
  ...actionsEvent,
  ...actionsEffectShakeViewport,
  ...actionsEffectFadeViewport,
  ...actionsEffectFadeScene,
  ...actionsCinematics,
  ...actionsSkill,
  ...actionsGuiCustomPopUp,
};

//
// CREATE HTML ACTION NAME
//
export const createActionName = (props: {
  name: string;
  value?: string;
  prefix?: string;
}) => {
  const { name, value, prefix } = props;

  return `${
    prefix ? `<span class="action-name-prefix"'>${prefix}</span> ` : ""
  }${name}${value ? ` <span class="action-name-value"'>${value}</span>` : ""}`;
};
