import MenuInputText, {
  MENU_INPUT_TEXT_HEIGHT,
  MenuInputTextProps,
} from "game-engine/components/gui-elements/Menu/menu-elements/MenuInputText";

import AssetBox from "game-engine/_dev/basic-components/Box/AssetBox";
import AssetPreview from "game-engine/_dev/basic-components/AssetPreview";
import Box from "game-engine/_dev/basic-components/Box";
import Checkbox from "game-engine/_dev/basic-components/inputs/Checkbox";
import JsonView from "game-engine/_dev/basic-components/JsonView";
import styled from "styled-components";
import { useState } from "react";

type MenuInputTextPreviewProps = Omit<MenuInputTextProps, "width"> & {
  width?: number;
  exampleName: string;
};

const PreviewWrapper = styled.div<{ minWidth?: string }>`
  min-width: ${(props) => props.minWidth};
`;

const JsonWrapper = styled.div<{ minWidth?: string }>`
  min-width: ${(props) => props.minWidth};
  display: flex;
  & > * {
    flex: 1;
  }
`;

const AssetMenuInputText = (props: { isOpen?: boolean }) => {
  const elemWidth = 120;
  const elemHeight = MENU_INPUT_TEXT_HEIGHT;
  const elemText = "Button";
  const scale = 2;

  const [previewProps, setPreviewProps] = useState<MenuInputTextPreviewProps[]>(
    [
      {
        exampleName: "With Value",
        value: "text input",
        listenToKeyboard: false,
        maxLength: 14,
      },
      {
        exampleName: "Without Value",
        value: "",
        listenToKeyboard: false,
        maxLength: 14,
      },
      {
        exampleName: "Text Overflow",
        value: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
        listenToKeyboard: false,
        maxLength: 30,
      },
      {
        exampleName: "Custom Height",
        value: "Lorem ipsum dolor sit amet.",
        listenToKeyboard: false,
        maxLength: 30,
        height: MENU_INPUT_TEXT_HEIGHT + 10,
      },
    ]
  );

  //
  // RENDER
  //
  return (
    <AssetBox
      label="Menu Input Text"
      subLabel="Menu element that takes user text input"
      level={2}
      isOpen={props.isOpen}
      childMinWidth={`max(22%, ${elemWidth * scale + 20}px)`}
      canvasThumbnail={{
        scale: 1,
        contentWidth: 50,
        contentHeight: 17,
        children: (
          <MenuInputText
            value="input"
            width={50}
            centeredText
            listenToKeyboard={false}
          />
        ),
      }}
      contentPaddingLeft="20px"
      contentPaddingRight="20px"
      contentPaddingBottom="30px"
    >
      {previewProps.map((elemProps, i) => {
        const elemPropsFinal: MenuInputTextProps = {
          width: elemWidth,
          value: elemText,
          onChange: (v) => {
            const updated = [...previewProps];
            updated[i].value = v;
            setPreviewProps(updated);
          },
          ...elemProps,
        };

        const elemPropsPreview = { ...elemPropsFinal };
        delete (elemPropsPreview as any).exampleName;
        delete (elemPropsPreview as any).onChange;

        return (
          <Box
            key={i}
            label={elemProps.exampleName}
            level={3}
            isOpen
            useFlex
            contentPaddingLeft="0px"
            contentPaddingTop="10px"
            gap="20px"
            childMinWidth="90%"
          >
            <PreviewWrapper minWidth={`${elemWidth * scale}px`}>
              <AssetPreview
                width={elemWidth}
                height={elemPropsFinal.height || elemHeight}
                scale={scale}
                background="#0000"
              >
                <MenuInputText {...elemPropsFinal} />
              </AssetPreview>
            </PreviewWrapper>

            <Checkbox
              label={"listenToKeyboard"}
              value={elemProps.listenToKeyboard}
              onChange={(v) => {
                const updated = [...previewProps].map((p) => {
                  return { ...p, listenToKeyboard: false };
                });
                updated[i].listenToKeyboard = v;
                setPreviewProps(updated);
              }}
            />

            <JsonWrapper minWidth="200px">
              <JsonView
                json={elemPropsPreview}
                autoExpandToLevel={1}
                hideRoot
              />
            </JsonWrapper>
          </Box>
        );
      })}
    </AssetBox>
  );
};

export default AssetMenuInputText;
