import {
  CharacterConfigType,
  CharacterDevToolsPreviewType,
} from "game-engine/types";
import MainCharacter, { MainCharacterDevtoolsPreview } from "./MainCharacter";
import TestCharacter, { TestCharacterDevtoolsPreview } from "./TestCharacter";

import { CharacterId } from "game-files/common/ids";

const CHARACTER_CONFIGS: { [key in CharacterId]: CharacterConfigType } = {
  MainCharacter,
  TestCharacter,
};

export const CHARACTER_DEVTOOLS_PREVIEWS: CharacterDevToolsPreviewType[] = [
  MainCharacterDevtoolsPreview,
  TestCharacterDevtoolsPreview,
];

export const getCharacterConfigById = (characterConfigId: CharacterId) => {
  return CHARACTER_CONFIGS[characterConfigId];
};

export default CHARACTER_CONFIGS;
