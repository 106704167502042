/* eslint-disable react-hooks/exhaustive-deps */

import { ImageChannel, Position } from "game-engine/types";

import Konva from "konva";
import { getImageChannelPixelMatrix } from "game-engine/utils";
import useGame from "./useGame";
import { useMemo } from "react";

const useKonvaImageData = (props: {
  imageLoaded: boolean;
  ref: {
    current: Konva.Image | null;
  };
}) => {
  const { logger } = useGame();
  const { ref, imageLoaded } = props;

  //
  // CACHED PIXEL MATRIX
  //
  const pixelMatrix = useMemo<number[][] | undefined>(() => {
    if (!imageLoaded || !ref?.current) return undefined;

    const imageElem = ref.current;
    const image = imageElem.attrs.image;

    // Safety check on image attributes
    if (!image || !image.width || !image.height) return undefined;

    logger.graphics("useKonvaImageData caching image data", props);

    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d", { willReadFrequently: true });

    if (!context) return undefined; // handle null context edge case

    const width = image.width;
    const height = image.height;
    canvas.width = width;
    canvas.height = height;

    // Draw the image to extract pixel data
    context.drawImage(image, 0, 0, width, height);

    // Extract the red channel data into a pixel matrix
    return getImageChannelPixelMatrix({
      context,
      imgWidth: width,
      imgHeight: height,
      channel: ImageChannel.red,
    });
  }, [ref?.current, imageLoaded]);

  //
  // UTILS
  //
  const getPixelValue = ({ x, y }: Position) => {
    if (!pixelMatrix) return null;

    // Limit coordinates to be inside the matrix
    const _x = Math.max(0, Math.min(pixelMatrix[0].length - 1, x));
    const _y = Math.max(0, Math.min(pixelMatrix.length - 1, y));

    return pixelMatrix[_y][_x];
  };

  const getPixelMatrix = () => pixelMatrix;

  //
  // RETURN DATA
  //
  return {
    getPixelValue,
    getPixelMatrix,
  };
};

export default useKonvaImageData;
