import Button from "game-engine/_dev/basic-components/Button";
import Checkbox from "game-engine/_dev/basic-components/inputs/Checkbox";
import { EditorType } from "../..";
import { getSceneCode } from "../../utils/code";
import styled from "styled-components";

const Footer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap-reverse;
  gap: 10px;

  button {
    flex: 1;
    align-items: center;
    justify-content: center;
    text-align: center;
    max-width: 300px;
    font-size: 14px;
    font-weight: 600;
  }

  ${(props) => props.theme.breakpoints.S} {
    flex-direction: column-reverse;
    align-items: stretch;
    justify-content: stretch;
    button {
      max-width: 100%;
    }
  }
`;

const Code = styled.div`
  min-width: 30%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap-reverse;
  gap: 10px;
`;

//
// COMPONENT
//
const EditorFooter = (props: {
  editor: EditorType;
  setEditor: (e: EditorType) => void;
}) => {
  const { editor, setEditor } = props;

  //
  // RENDER
  //
  return (
    <Footer>
      <Button
        onClick={() => {
          setEditor(undefined);
        }}
      >
        {"Reset"}
      </Button>

      <Code>
        <Checkbox
          label={`No examples in code`}
          value={editor.noCodeExamples}
          onChange={(v) => setEditor({ ...editor, noCodeExamples: v })}
        />

        <Button
          onClick={() => {
            navigator.clipboard.writeText(
              getSceneCode({
                editor,
              })
            );
          }}
          isActive
          clickFeedbackMessage="copied"
        >
          {"Copy Scene Code"}
        </Button>
      </Code>
    </Footer>
  );
};

export default EditorFooter;
