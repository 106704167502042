import {
  ActionId,
  Action_Sound,
  PlaySoundFunctionProps,
} from "game-engine/types";

import { createActionName } from ".";

//
// CREATE PLAY SOUND ACTION
//
type PlaySoundProps = PlaySoundFunctionProps & {
  blockGui?: true;
};

type PlaySoundReturn = () => Action_Sound;

export const playSound: (props: PlaySoundProps) => PlaySoundReturn =
  (props) => () => {
    return {
      ...props,
      _name: createActionName({
        name: `play sound`,
        value: `'${props.soundConfigId}'`,
      }),
      id: ActionId.sound,
      blockGui: props.blockGui ?? true,
      unskippable: props.blockGui ?? true,
    };
  };
