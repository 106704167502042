import MenuDialogConfirm from "../menu-templates/MenuDialogConfirm";
import { MenuProps } from "..";

const LoadGameError = (props: MenuProps) => {
  //
  // RENDER
  //
  return (
    <MenuDialogConfirm
      {...props}
      height={62}
      title={{
        en: "Loading of selected position FAILED.",
        cz: "Při nahrávání zvolené pozice došlo k CHYBĚ.",
      }}
      titleIsError
      submitHidden
      cancelLabel={{ en: "Back", cz: "Zpět" }}
    />
  );
};

export default LoadGameError;
