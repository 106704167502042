/* eslint-disable react-hooks/exhaustive-deps */

import {
  AnimationConfigType,
  SceneOverlayAnimationOptions,
} from "game-engine/types";
import { useEffect, useRef, useState } from "react";

import SceneOverlayAnimation from "./SceneOverlayAnimation";
import { getAnimationBySceneOverlayAnimationId } from "game-files/animations/ANIMATION_CONFIGS";
import useGame from "game-engine/hooks/useGame";

export type SceneOverlayAnimationType = {
  animationConfig: AnimationConfigType;
  sceneOverlayOptions: SceneOverlayAnimationOptions;
};

const SceneOverlayAnimations = () => {
  const { gamePlay } = useGame();

  const [overlayAnimation, setOverlayAnimation] =
    useState<SceneOverlayAnimationType>();

  const getCurrentSceneId = () => {
    return (
      gamePlay.state.currentScene?.uniqueSceneId ??
      gamePlay.state.currentScene?.cinematic?.id
    );
  };

  const currentSceneIdRef = useRef(getCurrentSceneId());

  useEffect(() => {
    const currentSceneId = getCurrentSceneId();
    if (currentSceneIdRef.current !== currentSceneId) {
      //
      // on scene-change, the scene overlay animation is reset
      // BUT, the scene is still visible until the new one loads all of its images !
      //
      // -> this results in the overlay animation being hidden before the scene
      //
      // -> keep the animation if scene-change detected to avoid this
      //
      return;
    }

    const animationConfig = getAnimationBySceneOverlayAnimationId(
      gamePlay.state.sceneOverlayAnimation?.sceneOverlayAnimationId
    );
    if (animationConfig) {
      setOverlayAnimation({
        animationConfig,
        sceneOverlayOptions: animationConfig.asSceneOverlayAnimation,
      });
    } else {
      setOverlayAnimation(undefined);
    }
  }, [
    gamePlay.state.currentScene?.uniqueSceneId,
    gamePlay.state.currentScene?.cinematic?.id,
    gamePlay.state.sceneOverlayAnimation?.sceneOverlayAnimationId,
  ]);

  //
  // RENDER
  //
  return overlayAnimation ? (
    <SceneOverlayAnimation overlayAnimation={overlayAnimation} />
  ) : null;
};

export default SceneOverlayAnimations;
