import { GAME_EVENTS, GameEventsType } from "game-files/gameEvents";
import { GameObjective, getMaxObjective } from "game-files/gameObjectives";
import {
  SceneWalkPathsByCurrentObjective,
  SceneWalkPathsType,
} from "game-engine/types";

import { getDataByCurrentObjective } from "./getters";
import { isWalkableWalkPath } from "../geometry";

//
// GET SCENE WALK PATHS BY CURRENT OBJECTIVE
//
export const getSceneWalkPathsByCurrentObjective = (props: {
  dataByCurrentObjective: SceneWalkPathsByCurrentObjective;
  currentObjective: GameObjective;
  events: GameEventsType;
}) => {
  return getDataByCurrentObjective<SceneWalkPathsType>(
    {
      ...props,
      aggregateConditionals: true, // This ensures that conditional values are added to unconditional
    },
    {}
  );
};

//
// GET ALL WALKABLE PATHS (including all possible conditionally set walkPaths!)
//
export const getAllWalkableSceneWalkPaths = (props: {
  dataByCurrentObjective: SceneWalkPathsByCurrentObjective;
}) => {
  return getDataByCurrentObjective<SceneWalkPathsType>(
    {
      dataByCurrentObjective: props.dataByCurrentObjective,
      currentObjective: getMaxObjective(),
      events: GAME_EVENTS,
      includeAllConditionals: true, // This ensures that all conditionals are taken into account
      aggregateConditionals: true, // This ensures that conditional values are added to unconditional
      isValid: (v) => isWalkableWalkPath(v), // Pass the custom comparator
    },
    {}
  );
};
