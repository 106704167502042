/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from "react";

import GAME_CONFIG from "game-files/gameConfig";
import { Group } from "react-konva";

export type TransitionScaleAnimation = {
  from: number;
  to?: number;
  durationSec?: number;
  scaleOrigin?: { x: number; y: number };
  onEnd?: () => void;
};

const TransitionScale = (props: {
  children?: any;
  animatedScale: TransitionScaleAnimation;
}) => {
  const { animatedScale } = props;
  const [scale, setScale] = useState(animatedScale.from);

  useEffect(() => {
    if (
      animatedScale.from === animatedScale.to ||
      animatedScale.to === undefined
    ) {
      return setScale(animatedScale.from);
    }

    if (!animatedScale.durationSec) {
      return setScale(animatedScale.to ?? animatedScale.from);
    }

    animateScale(
      animatedScale.from,
      animatedScale.to,
      animatedScale.durationSec
    );
  }, [animatedScale]);

  //
  // ANIMATE SCALE
  //
  const animateScale = (start: number, end: number, durationSec: number) => {
    const startTime = performance.now();
    const frameDuration = durationSec * 1000;

    const step = (currentTime: number) => {
      const elapsed = currentTime - startTime;
      const progress = Math.min(elapsed / frameDuration, 1);

      // Calculate the new scale based on the progress
      const newScale = Math.max(
        0,
        Math.min(1, start + progress * (end - start))
      );

      setScale(newScale);

      if (progress < 1) {
        requestAnimationFrame(step);
      }
    };

    // Only start the animation if duration is greater than 0
    if (durationSec > 0) {
      requestAnimationFrame(step);
    } else {
      setScale(end);
    }
  };

  const origin = animatedScale.scaleOrigin || {
    x: Math.floor(GAME_CONFIG.scene.dimensions.x / 2),
    y: Math.floor(GAME_CONFIG.scene.dimensions.y / 2),
  };

  useEffect(() => {
    if (scale === animatedScale.to && animatedScale.onEnd) {
      animatedScale.onEnd();
    }
  }, [scale]);

  //
  // RENDER
  //
  return (
    <Group
      listening={scale !== 0}
      scaleX={scale}
      scaleY={scale}
      position={origin}
      offsetX={origin.x}
      offsetY={origin.y}
    >
      {props.children}
    </Group>
  );
};

export default TransitionScale;
