import { Direction, Position } from 'game-engine/types'

//
// GET PATH DIRECTION
//
export const getDirection = (from: Position, to: Position) => {
  if (from.x === to.x && from.y === to.y) {
    return null
  }
  if (to) {
    const { x, y } = from
    const { x: toX, y: toY } = to

    if (toX < x) {
      return Direction.left
    }
    if (toX > x) {
      return Direction.right
    }
    if (toY < y) {
      return Direction.up
    }
    if (toY > y) {
      return Direction.down
    }
  }

  // undefined will also be returned for 'from === to'
  return undefined
}

export const getOppositeDirection = (direction: Direction) => {
  switch (direction) {
    case Direction.left:
      return Direction.right
    case Direction.right:
      return Direction.left
    case Direction.up:
      return Direction.down
    case Direction.down:
      return Direction.up
  }
}

//
// GET DISTANCE BETWEEN TWO POSITIONS
//
export const getDistance = (a: Position, b: Position) => {
  const sideA = a.x - b.x
  const sideB = a.y - b.y
  return Math.sqrt(Math.pow(sideA, 2) + Math.pow(sideB, 2))
}
