import {
  DynamicLightingByCurrentObjective,
  DynamicLightingType,
} from "game-engine/types";

import { GameEventsType } from "game-files/gameEvents";
import { GameObjective } from "game-files/gameObjectives";
import { getDataByCurrentObjective } from "./getters";

export const getDynamicLightingByCurrentObjective = (props: {
  dataByCurrentObjective: DynamicLightingByCurrentObjective;
  currentObjective: GameObjective;
  events: GameEventsType;
}) => {
  const result: DynamicLightingType =
    getDataByCurrentObjective<DynamicLightingType>(props, {});

  if (!Object.values(result)?.length) {
    return undefined;
  }

  return result;
};
