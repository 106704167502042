import {
  Direction,
  Position,
  SceneWalkPathBlockedType,
  SceneWalkPathHorizontalType,
  SceneWalkPathVerticalType,
  SceneWalkPathWalkableType,
  SceneWalkPathsType,
} from "game-engine/types";
import { EditorType, OnSceneMouseClick } from "../../..";

import ActionsTalk from "../../ActionsTalk";
import Box from "game-engine/_dev/basic-components/Box";
import Button from "game-engine/_dev/basic-components/Button";
import Checkbox from "game-engine/_dev/basic-components/inputs/Checkbox";
import Divider from "game-engine/_dev/basic-components/Divider";
import Dropdown from "game-engine/_dev/basic-components/inputs/Dropdown";
import GAME_CONFIG from "game-files/gameConfig";
import NumberInput from "game-engine/_dev/basic-components/inputs/NumberInput";
import PositionInput from "game-engine/_dev/basic-components/inputs/PositionInput";
import { capitalizeFirstLetter } from "game-engine/utils";
import styled from "styled-components";
import { useState } from "react";

const BoxHeader = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  gap: 10px;
`;

const BoxName = styled.div`
  min-width: 50%;
  font-size: 13px;
  font-weight: 600;
  color: #fffa;
`;

enum WalkPathType {
  None = "none",
  Blocked = "blocked",
  Walkable = "walkable",
}

//
// COMPONENT
//
const WalkPathSettings = (props: {
  editor: EditorType;
  setEditor: (e: EditorType) => void;
  direction: "left" | "right" | "up" | "down";
  onSceneMouseClick: OnSceneMouseClick;
  setOnSceneMouseClick: (v: OnSceneMouseClick) => void;
  walkPaths: SceneWalkPathsType;
  setWalkPaths: (wp: SceneWalkPathsType) => void;

  setForceWalkIn: (v: { fromDirection: Direction }) => void;
  setForceWalkOut: (v: { walkTo: Position; direction: Direction }) => void;
}) => {
  const {
    editor,
    setEditor,
    direction,
    walkPaths,
    setWalkPaths,
    onSceneMouseClick,
    setOnSceneMouseClick,
    setForceWalkIn,
    setForceWalkOut,
  } = props;

  const walkPath = (walkPaths || {})[direction];

  const type: WalkPathType = !walkPath
    ? WalkPathType.None
    : (walkPath as SceneWalkPathBlockedType)?.isBlocked
    ? WalkPathType.Blocked
    : WalkPathType.Walkable;

  const [flash, setFlash] = useState(false);

  const [isOpen, setIsOpen] = useState(false);

  let edgeParam = "";
  let edgeParamName = "";
  switch (props.direction) {
    case "left":
      edgeParam = "edgeWalkY";
      edgeParamName = "Scene Edge Walk Y";
      break;
    case "right":
      edgeParam = "edgeWalkY";
      edgeParamName = "Scene Edge Walk Y";
      break;
    case "up":
      edgeParam = "edgeWalkX";
      edgeParamName = "Scene Edge Walk X";
      break;
    case "down":
      edgeParam = "edgeWalkX";
      edgeParamName = "Scene Edge Walk X";
      break;
  }

  const sceneMouseClickId_EdgeParam = `walkPath_${direction}_${edgeParam}`;
  const sceneMouseClickId_WalkTo = `walkPath_${direction}_walkTo`;

  const sceneMouseClickId_EdgeParam_isActive =
    onSceneMouseClick?.paramId === sceneMouseClickId_EdgeParam;
  const sceneMouseClickId_WalkTo_isActive =
    onSceneMouseClick?.paramId === sceneMouseClickId_WalkTo;

  const setWalkPath = (obj: any) => {
    const updatedWalkPaths = { ...walkPaths };
    updatedWalkPaths[direction] = obj;
    setWalkPaths(updatedWalkPaths);
  };

  const setEdgeParam = (value: number) => {
    const updatedWalkPath = { ...walkPath };
    updatedWalkPath[edgeParam] = value;
    setWalkPath(updatedWalkPath);
  };

  const setWalkTo = (position: Position) => {
    const updatedWalkPath = { ...walkPath, walkTo: position };
    setWalkPath(updatedWalkPath);
  };

  const setDefaultWalkPath = () => {
    const p = GAME_CONFIG.scene.dimensions;

    let walkTo: Position;

    switch (direction) {
      case "left":
        walkTo = {
          x: Math.floor(p.x / 4),
          y: Math.floor(p.y / 2),
        };
        break;
      case "right":
        walkTo = {
          x: Math.floor((p.x / 4) * 3),
          y: Math.floor(p.y / 2),
        };
        break;
      case "up":
        walkTo = {
          x: Math.floor(p.x / 2),
          y: Math.floor(p.y / 4),
        };
        break;
      case "down":
        walkTo = {
          x: Math.floor(p.x / 2),
          y: Math.floor((p.y / 4) * 3),
        };
        break;
    }

    const updatedWalkPath = {
      walkTo,
    };

    updatedWalkPath[edgeParam] = Math.floor(
      (direction === "left" || direction === "right" ? p.y : p.x) / 2
    );
    setWalkPath(updatedWalkPath);
  };

  const setBlockedWalkPath = () => {
    const blockedPath: SceneWalkPathBlockedType = {
      isBlocked: true,
      actions: [],
    };
    setWalkPath(blockedPath);
  };

  const getWalkOutPosition = () => {
    const p = GAME_CONFIG.scene.dimensions;
    const walkPathData = walkPaths[direction];

    if (walkPathData) {
      let walkTo: Position;

      switch (direction) {
        case "left":
          walkTo = {
            x: 0,
            y: (walkPathData as SceneWalkPathHorizontalType).edgeWalkY,
          };
          break;
        case "right":
          walkTo = {
            x: p.x,
            y: (walkPathData as SceneWalkPathHorizontalType).edgeWalkY,
          };
          break;
        case "up":
          walkTo = {
            x: (walkPathData as SceneWalkPathVerticalType).edgeWalkX,
            y: 0,
          };
          break;
        case "down":
          walkTo = {
            x: (walkPathData as SceneWalkPathVerticalType).edgeWalkX,
            y: p.y,
          };
          break;
      }

      return walkTo;
    }
    return undefined;
  };

  const renderBoxName = () => {
    return (
      <BoxHeader
        onMouseDown={
          type === WalkPathType.None ? () => setFlash(true) : undefined
        }
        onMouseUp={
          type === WalkPathType.None ? () => setFlash(false) : undefined
        }
      >
        <BoxName>{capitalizeFirstLetter(direction)}</BoxName>

        <Dropdown
          isActive={flash || type !== WalkPathType.None}
          selectedId={type}
          items={Object.values(WalkPathType).map((type) => {
            return {
              id: type,
              data: type,
              label: capitalizeFirstLetter(type),
            };
          })}
          onChange={(selectedId) => {
            switch (selectedId) {
              case WalkPathType.None:
                setWalkPath(undefined);
                setOnSceneMouseClick(undefined);
                setIsOpen(false);
                break;

              case WalkPathType.Blocked:
                setBlockedWalkPath();
                setIsOpen(true);
                break;

              default:
                setDefaultWalkPath();
                setIsOpen(true);
                break;
            }
          }}
        />
      </BoxHeader>
    );
  };

  const renderBoxContentWalkable = () => {
    return (
      <>
        <Box renderContentOnly useFlex childMinWidth="50%">
          <Divider name="Walk to Position:" marginTop="0px" />
          <Checkbox
            label="Set with mouse click"
            value={sceneMouseClickId_WalkTo_isActive}
            onChange={(v) =>
              setOnSceneMouseClick(
                sceneMouseClickId_WalkTo_isActive
                  ? undefined
                  : {
                      paramId: sceneMouseClickId_WalkTo,
                      func: setWalkTo,
                    }
              )
            }
          />

          <PositionInput
            value={(walkPaths[direction] as SceneWalkPathWalkableType).walkTo}
            onChange={setWalkTo}
            onClick={() => setOnSceneMouseClick(undefined)}
            rangeControllerX={{
              min: 0,
              max: GAME_CONFIG.scene.dimensions.x,
            }}
            rangeControllerY={{
              min: 0,
              max: GAME_CONFIG.scene.dimensions.y,
            }}
            noBorder
          />
        </Box>

        <Box renderContentOnly useFlex childMinWidth="50%">
          <Divider name={`${edgeParamName}:`} />

          <Checkbox
            label="Set with mouse click"
            value={sceneMouseClickId_EdgeParam_isActive}
            onChange={(v) =>
              setOnSceneMouseClick(
                sceneMouseClickId_EdgeParam_isActive
                  ? undefined
                  : {
                      paramId: sceneMouseClickId_EdgeParam,
                      func: (p: Position) =>
                        setEdgeParam(
                          direction === "left" || direction === "right"
                            ? p.y
                            : p.x
                        ),
                    }
              )
            }
          />

          <NumberInput
            value={walkPaths[direction][edgeParam] || 0}
            onChange={(v) => setEdgeParam(v)}
            onClick={() => setOnSceneMouseClick(undefined)}
            rangeController={{
              min: 0,
              max: GAME_CONFIG.scene.dimensions.y,
            }}
            noBorder
          />
        </Box>

        <Box renderContentOnly useFlex childMinWidth="50%">
          <Divider name={`Preview Actions:`} />
          <Box renderContentOnly useFlex childMinWidth="40%">
            <Button
              onClick={() =>
                setForceWalkIn({ fromDirection: direction as Direction })
              }
            >
              {"Walk-In"}
            </Button>

            <Button
              onClick={() =>
                setForceWalkOut({
                  direction: direction as Direction,
                  walkTo: getWalkOutPosition(),
                })
              }
            >
              {"Walk-Out"}
            </Button>
          </Box>
        </Box>
      </>
    );
  };

  const renderBoxContentBlocked = () => {
    return (
      <>
        <Box renderContentOnly useFlex childMinWidth="50%">
          <ActionsTalk
            dialogActions={editor.meta.walkPathsBlockedActions[direction]}
            setDialogActions={(dialogActions) =>
              setEditor({
                ...editor,
                meta: {
                  ...editor.meta,
                  walkPathsBlockedActions: {
                    ...editor.meta.walkPathsBlockedActions,
                    [direction]: dialogActions,
                  },
                },
              })
            }
          />
        </Box>
      </>
    );
  };

  const renderBoxContent = () => {
    switch (type) {
      case WalkPathType.Walkable:
        return renderBoxContentWalkable();

      case WalkPathType.Blocked:
        return renderBoxContentBlocked();
    }
  };

  //
  // RENDER
  //
  return (
    <Box
      label={renderBoxName()}
      level={2}
      isOpen={isOpen}
      onOpenChange={setIsOpen}
      keepClosed={type === WalkPathType.None}
      useFlex
      childMinWidth="90%"
      contentPaddingLeft="10px"
      contentPaddingRight="10px"
      contentPaddingBottom="20px"
    >
      {renderBoxContent()}
    </Box>
  );
};

export default WalkPathSettings;
