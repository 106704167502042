import styled, { css, keyframes } from "styled-components";

import { ActionType } from "game-engine/types";
import { DevEventType } from "game-engine/types/devTools";
import JsonView from "../JsonView";
import React from "react";

const ActionList = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const ItemTimeline = styled.div`
  position: relative;
  height: 100%;
  border-left: 2px solid var(--line-color);

  transition: border-color 0.2s ease;
`;

const Line = styled.div`
  height: 7px;
  width: 12px;
  border-bottom: 2px solid var(--line-color);

  transition: border-color 0.2s ease;
  transition-delay: 0.1s;
`;

const ItemName = styled.div`
  flex: 1;

  font-size: 12px;
  font-weight: 600;
  color: var(--color);

  transition: color 0.2s ease;
  transition-delay: 0.2s;

  .action-name-prefix {
    opacity: 0.8;
    font-weight: 400;
  }
  .action-name-value {
    opacity: 0.8;
    font-weight: 400;
  }
`;

const ItemInfo = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 10px;

  padding-top: 5px;
  padding-bottom: 20px;

  opacity: 0.4;

  font-size: 11px;

  & > * {
    width: 100%;
  }
`;

const ItemContent = styled.div`
  flex: 1;
  min-height: 30px;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 1px;
`;

const Point = styled.div`
  --point-size: 10px;
  z-index: 2;
  margin-top: calc((var(--point-size) - 2px) / -2);
  margin-left: calc((var(--point-size) - 2px) / -2);
  width: var(--point-size);
  min-width: var(--point-size);
  max-width: var(--point-size);
  height: var(--point-size);
  min-height: var(--point-size);
  max-height: var(--point-size);
  border-radius: 50%;
  background: #555;
`;

const expand = keyframes`
  from {
    max-height: 0;
  }
  to {
    max-height: 1000px; /* A large enough value */
  }
`;

const Item = styled.div<{
  isActive: boolean;
  isFuture: boolean;
  isEvent: boolean;
}>`
  --color: #999;
  --line-color: #444;

  overflow: hidden;
  animation: ${expand} 2s ease-out forwards;

  display: flex;
  align-items: stretch;
  gap: 10px;

  &:first-child {
    ${ItemTimeline} {
      padding-top: 0px;
      margin-top: 7px;
    }
    ${Line} {
      height: 0px;
    }
  }

  ${(props) =>
    props.isEvent &&
    css`
      --color: #77c2ff88;

      ${Line} {
        width: 50px;
      }
    `}

  ${(props) =>
    props.isActive
      ? css`
          --color: #ddff99;
          --line-color: #ddff99;
        `
      : props.isFuture
      ? css`
          --color: #ddff9966;
          --line-color: #ddff9966;
        `
      : null}
`;

type ActionOrEvent = {
  action?: ActionType;
  event?: DevEventType;
  isFuture?: boolean;
  isActive?: boolean;
};

const ActionQueueList = (props: {
  actionQueue: ActionType[];
  actionOrEventHistory?: (ActionType | DevEventType)[];
}) => {
  const { actionQueue, actionOrEventHistory } = props;

  const actionOrEventList: ActionOrEvent[] = [
    ...actionOrEventHistory.map((actionOrEvent) =>
      (actionOrEvent as DevEventType).isEvent
        ? { event: actionOrEvent as DevEventType }
        : { action: actionOrEvent as ActionType }
    ),
    ...actionQueue.map((a, i) => {
      const isActive = i === 0;
      return {
        action: a,
        isActive: isActive,
        isFuture: !isActive,
        timestamp: 0,
      };
    }),
  ].reverse();

  //
  // ITEM
  //
  const renderItem = (params: {
    name: string;
    json?: Object;
    isActive?: boolean;
    isFuture?: boolean;
    isEvent?: boolean;
  }) => {
    const { name, json, isActive, isFuture, isEvent } = params;
    return (
      <Item isEvent={isEvent} isActive={isActive} isFuture={isFuture}>
        <ItemTimeline>
          <Line />
        </ItemTimeline>

        <ItemContent>
          <ItemName dangerouslySetInnerHTML={{ __html: name }} />

          <ItemInfo>
            {json ? (
              <JsonView json={json} autoExpandToLevel={0} hideRoot />
            ) : null}
          </ItemInfo>
        </ItemContent>
      </Item>
    );
  };

  //
  // ACTION
  //
  const renderAction = (params: {
    action: ActionType;
    isActive?: boolean;
    isFuture?: boolean;
  }) => {
    const { action, isActive, isFuture } = params;

    const trimmedAction = {
      id: action.id, // puts id at the start
    };

    for (const key in action) {
      if (key !== "id" && key !== "_name" && action[key] !== undefined) {
        trimmedAction[key] = action[key];
      }
    }

    return renderItem({
      name: action._name || action.id,
      json: trimmedAction,
      isActive,
      isFuture,
    });
  };

  //
  // EVENT
  //
  const renderEvent = (params: { event: DevEventType }) => {
    const { event } = params;
    return renderItem({
      name: event.name,
      json: event.data,
      isEvent: true,
    });
  };

  //
  // RENDER
  //
  return (
    <ActionList>
      {actionOrEventList.map((data, i) => (
        <React.Fragment key={actionOrEventList.length - i}>
          {data.action
            ? renderAction({
                action: data.action,
                isActive: data.isActive,
                isFuture: data.isFuture,
              })
            : renderEvent({
                event: data.event,
              })}
        </React.Fragment>
      ))}

      <Point />
    </ActionList>
  );
};

export default ActionQueueList;
