import styled from 'styled-components'

const Wrapper = styled.div`
  padding-left: 6px;
  color: #fff4;
  font-size: 14px;
  font-style: italic;
`

const NoDataLabel = (props: { children?: any }) => {
  //
  // RENDER
  //
  return <Wrapper>{props.children}</Wrapper>
}

export default NoDataLabel
