import { SceneDefinitionsType } from "game-engine/types";
import Test_Forest_L_D from "./Test_Forest_L_D";
import Test_Forest_L_R_D from "./Test_Forest_L_R_D";
import Test_Forest_L_R_U_D from "./Test_Forest_L_R_U_D";
import Test_Forest_L_R_U_D_v2 from "./Test_Forest_L_R_U_D_v2";
import Test_Forest_L_U_D from "./Test_Forest_L_U_D";
import Test_Forest_R_D from "./Test_Forest_R_D";
import Test_Forest_R_U_D from "./Test_Forest_R_U_D";
import Test_Night_Forest_L_R_U_D from "./Test_Night_Forest_L_R_U_D";

const SCENE_CONFIGS_TEST: SceneDefinitionsType = {
  Test_Forest_L_D,
  Test_Forest_L_R_D,
  Test_Forest_L_R_U_D,
  Test_Forest_L_R_U_D_v2,
  Test_Forest_L_U_D,
  Test_Forest_R_D,
  Test_Forest_R_U_D,
  Test_Night_Forest_L_R_U_D,
};

export default SCENE_CONFIGS_TEST;
