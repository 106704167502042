import { MENU_IDS, MenuBreadcrumbType_LoadGameDialog, MenuProps } from "..";

import MenuDialogConfirm from "../menu-templates/MenuDialogConfirm";
import useGame from "game-engine/hooks/useGame";

const LoadGameDialog = (
  props: MenuProps & { breadcrumb: MenuBreadcrumbType_LoadGameDialog }
) => {
  const { closeMenu, replaceMenu, breadcrumb } = props;
  const { gameFns } = useGame();

  const onSubmit = async () => {
    const savedGame = breadcrumb.data?.savedGame;
    const success = gameFns.loadGame(savedGame);
    if (success) {
      closeMenu();
    } else {
      replaceMenu({ menuId: MENU_IDS.loadGameError });
    }
  };

  //
  // RENDER
  //
  return (
    <MenuDialogConfirm
      {...props}
      title={{
        en: `Do you want to load "${breadcrumb.data?.savedGame?.name}"?`,
        cz: `Chcete nahrát pozici "${breadcrumb.data?.savedGame?.name}"?`,
      }}
      titlePaddingX={10}
      titleMinHeight={24}
      onSubmit={onSubmit}
      submitLabel={{ en: "Load Game", cz: "Nahrát hru" }}
    />
  );
};

export default LoadGameDialog;
