import { ACTION } from ".";
import { ActionType } from "game-engine/types";
import { GameEvent } from "game-files/gameEvents";

//
// POISON MAIN CHARACTER
//
export const poisonMainCharacter: (props?: {
  isPoisoned?: boolean;
}) => () => ActionType[] = (props) => () => {
  const isPoisoned = props?.isPoisoned ?? true;

  const actions: ActionType[] = [];

  if (isPoisoned) {
    actions.push(
      ACTION.saveGameBeforeDeath({ blockGui: true })(),
      ACTION.stopAllSkills({ prependStopActions: true })()
    );
  }

  return [
    ...actions,

    ACTION.setEvent({
      key: GameEvent.mainCharacterIsPoisoned,
      value: isPoisoned,
    })(),
  ];
};
