import { ImageFilters } from "game-engine/types";
import Konva from "konva";

export const getKonvaImageFilters = (filters: ImageFilters) => {
  const normalizedFilters: Record<string, any> = {}; // Create an object to hold the normalized Konva filter props
  const activeFilters: Set<any> = new Set(); // Use a Set to avoid duplicates

  if (filters) {
    /* if (filters.blur !== undefined) {
      normalizedFilters.blur = filters.blur;
      if (filters.blur > 0) activeFilters.add(Konva.Filters.Blur);
    } */

    if (filters.grayscale) {
      normalizedFilters.grayscale = 1; // Set grayscale to 1 if true
      activeFilters.add(Konva.Filters.Grayscale);
    }

    if (filters.invert) {
      normalizedFilters.invert = 1; // Set invert to 1 if true
      activeFilters.add(Konva.Filters.Invert);
    }

    if (filters.pixelate !== undefined) {
      normalizedFilters.pixelate = filters.pixelate;
      if (normalizedFilters.pixelate > 0)
        activeFilters.add(Konva.Filters.Pixelate);
    }

    if (filters.sepia) {
      normalizedFilters.sepia = 1; // Set sepia to 1 if true
      activeFilters.add(Konva.Filters.Sepia);
    }

    if (filters.threshold !== undefined) {
      normalizedFilters.threshold = filters.threshold;
      if (normalizedFilters.threshold > 0)
        activeFilters.add(Konva.Filters.Threshold);
    }

    if (filters.emboss) {
      normalizedFilters.emboss = filters.emboss;
      activeFilters.add(Konva.Filters.Emboss);
    }

    if (filters.enhance !== undefined) {
      normalizedFilters.enhance = filters.enhance;
      if (normalizedFilters.enhance > 0)
        activeFilters.add(Konva.Filters.Enhance);
    }

    if (filters.noise !== undefined) {
      normalizedFilters.noise = filters.noise;
      if (normalizedFilters.noise > 0) activeFilters.add(Konva.Filters.Noise);
    }
    if (filters.rgb !== undefined) {
      normalizedFilters.red = filters.rgb.red;
      normalizedFilters.green = filters.rgb.green;
      normalizedFilters.blue = filters.rgb.blue;
      activeFilters.add(Konva.Filters.RGB);
    }

    if (
      filters.saturation !== undefined ||
      filters.luminance !== undefined ||
      filters.hue !== undefined
    ) {
      normalizedFilters.saturation = (filters.saturation ?? 1) * 6 - 6; // experimenting revealed that -6 is complete de-saturation
      normalizedFilters.luminance = filters.luminance ?? 0;
      normalizedFilters.hue = filters.hue ?? 0;
      activeFilters.add(Konva.Filters.HSL);
    }

    if (filters.contrast !== undefined) {
      normalizedFilters.contrast = (filters.contrast - 1) * 100; // Konva uses -100 to 100
      if (normalizedFilters.contrast !== 0)
        activeFilters.add(Konva.Filters.Contrast);
    }

    if (filters.brightness !== undefined) {
      normalizedFilters.brightness = filters.brightness - 1; // Shift brightness by 1
      if (normalizedFilters.brightness !== 0)
        activeFilters.add(Konva.Filters.Brighten);
    }
  }

  return {
    normalizedFilters,
    activeFilters: Array.from(activeFilters), // Convert the Set back to an array
  };
};
