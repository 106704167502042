import {
  ActionConditionalData,
  ActionConditionalItem,
  ActionId,
  Action_Conditional,
} from "game-engine/types";

import { createActionName } from ".";

//
// CREATE ACTION
//
type ConditionalProps = (
  data: ActionConditionalData
) => ActionConditionalItem[];
//
type ConditionalReturn = () => Action_Conditional;

export const conditional: (props: ConditionalProps) => ConditionalReturn =
  (getConditionalActions) => () => {
    return {
      _name: createActionName({
        name: "conditional actions",
      }),
      id: ActionId.conditional,
      getConditionalActions,
      blockGui: true,
      unskippable: true,
    };
  };
