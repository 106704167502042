/* eslint-disable react-hooks/exhaustive-deps */

import styled, { keyframes } from "styled-components";
import { useEffect, useState } from "react";

import GAME_CONFIG from "game-files/gameConfig";
import GameAudio from "./game-elements/Audio";
import GameIntro from "./game-sections/GameIntro";
import GamePlay from "./game-sections/GamePlay";
import { SavedGameType } from "game-engine/utils";
import StartScreen from "./game-sections/StartScreen";
import useDebounce from "hooks/useDebounce";
import useGame from "../hooks/useGame";

const Viewport = styled.div`
  --width: ${`${GAME_CONFIG.window.displayDimensions.x}px`};
  --height: ${`${GAME_CONFIG.window.displayDimensions.y}px`};

  width: var(--width);
  min-width: var(--width);
  max-width: var(--width);
  height: var(--height);
  min-height: var(--height);
  max-height: var(--height);
`;

const GameSectionAppear = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;
const GameSection = styled.div`
  animation: ${GameSectionAppear} 2s ease-out forwards;
`;

export enum GameSectionEnum {
  "startScreen" = "startScreen",
  "gameIntro" = "gameIntro",
  "gamePlay" = "gamePlay",
}

const Game = () => {
  const { gamePlay, gameFns } = useGame();

  const [isInitialized, setIsInitialized] = useState(false);
  const [gameSection, setGameSection] = useState<GameSectionEnum>(
    GameSectionEnum.startScreen
  );

  //
  // MAIN FUNCTIONS
  //
  const onNewGame = () => {
    if (gameFns.newGame()) {
      setGameSection(GameSectionEnum.gameIntro);
    }
  };

  const onIntroEnd = () => {
    setGameSection(GameSectionEnum.gamePlay);
  };

  const onQuickLoadGame = () => {
    if (gameFns.loadAutosavedGame()) {
      setGameSection(GameSectionEnum.gamePlay);
    }
  };

  const onLoadGame = (savedGame: SavedGameType) => {
    if (gameFns.loadGame(savedGame)) {
      setGameSection(GameSectionEnum.gamePlay);
    }
  };

  const onExitGame = () => {
    setGameSection(GameSectionEnum.startScreen);
  };

  //
  // ON GAME INIT
  //
  useEffect(() => {
    gameFns.loadSettings();
    setIsInitialized(true);
  }, []);

  //
  // AFTER GAME LOAD
  //
  useEffect(() => {
    if (gamePlay.state.isLoadGame) {
      gamePlay.setState((prevState) => ({
        ...prevState,
        isLoadGame: false, // Turn off isLoadGame flag
      }));
    }
  }, [gamePlay.state.isLoadGame]);

  //
  // SAVE SETTINGS
  //
  const debouncedSaveSettings = useDebounce(gameFns.saveSettings, 500); // delay in milliseconds

  useEffect(() => {
    if (isInitialized) {
      debouncedSaveSettings();
    }
  }, [isInitialized, gamePlay.state.settings]);

  //
  // ACTIVE GAME SECTION
  //
  const renderActiveSection = () => {
    switch (gameSection) {
      case GameSectionEnum.startScreen:
        return (
          <GameSection>
            <StartScreen
              onNewGame={onNewGame}
              onQuickLoadGame={onQuickLoadGame}
              onLoadGame={onLoadGame}
            />
          </GameSection>
        );

      case GameSectionEnum.gameIntro:
        return (
          <GameSection>
            <GameIntro onEnd={onIntroEnd} />
          </GameSection>
        );

      case GameSectionEnum.gamePlay:
        return !gamePlay.state?.isLoadGame ? (
          <GameSection>
            <GamePlay onExitGame={onExitGame} onNewGame={onNewGame} />
          </GameSection>
        ) : null;

      default:
        return <GameSection>UNKNOWN GAME SECTION</GameSection>;
    }
  };

  //
  // RENDER
  //
  return (
    <Viewport>
      {isInitialized ? (
        <>
          {/* GAME VIEWPORT */}
          {renderActiveSection()}

          {/* GAME AUDIO - needs to be in root, otherwise repeated user interaction to start playing is needed */}
          <GameAudio activeGameSection={gameSection} />
        </>
      ) : (
        <div>TODO - LOADING THE BEST GAME I EVER MADE (I only made one...)</div>
      )}
    </Viewport>
  );
};

export default Game;
