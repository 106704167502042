import RectBeveled, {
  RectBeveledProps,
} from "game-engine/components/basic-elements/RectBeveled";

import AssetBox from "game-engine/_dev/basic-components/Box/AssetBox";
import AssetPreview from "game-engine/_dev/basic-components/AssetPreview";
import Box from "game-engine/_dev/basic-components/Box";
import JsonView from "game-engine/_dev/basic-components/JsonView";
import styled from "styled-components";

type RectBeveledPreviewProps = Omit<RectBeveledProps, "width" | "height"> & {
  width?: number;
  height?: number;
  exampleName: string;
};

const PreviewWrapper = styled.div`
  flex: 1;
`;

const AssetBeveledRectangle = (props: { isOpen?: boolean }) => {
  const rectWidth = 40;
  const rectHeight = 18;
  const rectScale = 2;
  const rectBevel = 3;
  const rectColor = `#770`;

  const previewProps: RectBeveledPreviewProps[] = [
    {
      exampleName: "Default",
    },
    {
      exampleName: "Full contrast",
      contrast: 1,
    },
    {
      exampleName: "No contrast",
      contrast: 0,
    },
    {
      exampleName: "Custom highlight color",
      colorHighlight: "#ff0",
    },
    {
      exampleName: "Custom shadow color",
      colorShadow: "#422",
    },
    {
      exampleName: "Custom colors",
      color: `#AA0`,
      colorHighlight: "red",
      colorShadow: "blue",
    },
    {
      exampleName: "Custom bevel",
      bevel: 1,
    },
    {
      exampleName: "Custom bevel",
      bevel: 4,
    },
    {
      exampleName: "Custom bevel",
      bevel: 8,
    },
  ];

  //
  // RENDER
  //
  return (
    <AssetBox
      label="Beveled Rectangle"
      subLabel="Basic shape for menu buttons and boxes"
      level={2}
      isOpen={props.isOpen}
      childMinWidth={`max(14%, ${rectWidth * rectScale + 40}px)`}
      canvasThumbnail={{
        scale: 1,
        contentWidth: 50,
        contentHeight: 17,
        children: <RectBeveled width={50} height={17} />,
      }}
      contentPaddingLeft="20px"
      contentPaddingRight="20px"
      contentPaddingBottom="30px"
    >
      {previewProps.map((rectProps, i) => {
        const rectPropsFinal: RectBeveledProps = {
          width: rectWidth,
          height: rectHeight,
          bevel: rectBevel,
          color: rectColor,
          ...rectProps,
        };

        delete (rectPropsFinal as any).exampleName;

        return (
          <Box
            key={i}
            label={rectProps.exampleName}
            level={2}
            isOpen
            useFlex
            childMinWidth="90%"
            contentPaddingLeft="0px"
            contentPaddingTop="10px"
            gap="20px"
          >
            <PreviewWrapper>
              <AssetPreview
                width={rectWidth}
                height={rectHeight}
                scale={rectScale}
                background="#0000"
              >
                <RectBeveled {...rectPropsFinal} />
              </AssetPreview>
            </PreviewWrapper>

            <JsonView json={rectPropsFinal} autoExpandToLevel={1} hideRoot />
          </Box>
        );
      })}
    </AssetBox>
  );
};

export default AssetBeveledRectangle;
