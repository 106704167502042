import ScenesLayoutGrid, { ScenesLayoutGridProps } from '../ScenesLayoutGrid'
import styled, { css } from 'styled-components'

import { Direction } from 'game-engine/types'
import ShapePlus from 'game-engine/_dev/basic-components/ShapePlus'

const OverflowWrapper = styled.div<{
  padding: string
  noScrollOverflow: boolean
}>`
  position: relative;

  ${(props) =>
    !props.noScrollOverflow &&
    css`
      width: calc(100% - 2 * ${props.padding || '0px'});
      height: calc(100% - 2 * ${props.padding || '0px'});
      overflow: auto;
    `}

  align-items: center;
  display: flex;

  padding: ${(props) => props.padding || '0px'};
`

const Container = styled.div`
  margin: auto;
`

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
`

const ExtendButton = styled.div`
  cursor: pointer;
  user-select: none;
  padding: 20px;
`

//
// COMPONENT
//
const ScenesLayout = (
  props: ScenesLayoutGridProps & {
    isEditable?: boolean
    padding?: string
    noScrollOverflow?: boolean
    onExtendScenesLayoutClick?: (direction: Direction) => void
  }
) => {
  const renderExtendButton = (direction: Direction) => {
    return (
      <ExtendButton
        onClick={
          props.onExtendScenesLayoutClick
            ? () => props.onExtendScenesLayoutClick(direction)
            : undefined
        }
      >
        <ShapePlus opacity={0.3} opacityOnHover={0.8} />
      </ExtendButton>
    )
  }

  //
  // RENDER
  //
  return !props.isEditable ? (
    <>
      <OverflowWrapper
        padding={props.padding}
        noScrollOverflow={props.noScrollOverflow}
      >
        <Container>
          <ScenesLayoutGrid {...props} />
        </Container>
      </OverflowWrapper>
    </>
  ) : (
    <>
      <OverflowWrapper
        padding={props.padding}
        noScrollOverflow={props.noScrollOverflow}
      >
        <Container>
          <Column>
            {renderExtendButton(Direction.up)}
            <Row>
              {renderExtendButton(Direction.left)}
              <ScenesLayoutGrid {...props} />
              {renderExtendButton(Direction.right)}
            </Row>
            {renderExtendButton(Direction.down)}
          </Column>
        </Container>
      </OverflowWrapper>
    </>
  )
}

export default ScenesLayout
