import { isNumber } from "game-engine/utils";

export const getRandomIndex: (
  length: number,
  options?: { excludeIndex?: number }
) => number | undefined = (length, options) => {
  const excludeIndex = options?.excludeIndex;
  const randomIndex =
    length > 0 ? Math.floor(Math.random() * length) : undefined;

  if (
    length > 1 &&
    isNumber(excludeIndex) &&
    isNumber(randomIndex) &&
    excludeIndex === randomIndex
  ) {
    // avoid returning the same index as excludeIndex -> return the next one
    return (randomIndex + 1) % (length - 1);
  }

  return randomIndex;
};

export const getRandomItemFromArray = <T>(
  array: T[],
  options?: { excludeIndex?: number }
): T | undefined => {
  if (array.length === 1) {
    return array[0];
  }
  const index = getRandomIndex(array.length, options);
  return isNumber(index) ? array[index] : undefined;
};

export const getRandomTrue: (props: {
  probabilityPercent: number;
}) => boolean = ({ probabilityPercent }) => {
  return Math.random() > probabilityPercent / 100;
};

export const randomInRange: (props: { min: number; max: number }) => number = ({
  min,
  max,
}) => {
  return Math.random() * (max - min) + min;
};

export const getRandomId = (): string => {
  const array = new Uint32Array(4);
  crypto.getRandomValues(array);
  return Array.from(array, (num) => num.toString(16)).join("-");
};
