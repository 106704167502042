import { GameEffectFadeEnum, GameEffectFadeViewport } from "game-engine/types";
import { useEffect, useState } from "react";

import styled from "styled-components";
import useGame from "game-engine/hooks/useGame";

const FadingWrapper = styled.div<{ durationSec: number; opacity: number }>`
  transition: opacity ${(props) => props.durationSec}s ease-in-out;
  opacity: ${(props) => props.opacity};
`;

const EffectFadeViewport = (props: { children: any }) => {
  const { gamePlay, logger } = useGame();

  const [effect, setEffect] = useState<GameEffectFadeViewport>();
  const [opacity, setOpacity] = useState<number>(1); // Start fully visible

  useEffect(() => {
    const fadeEffect = gamePlay.state.effects?.fadeViewport;
    const newEffect = {
      ...fadeEffect,
      durationSec: fadeEffect?.durationSec ?? 0,
    };
    setEffect(newEffect);

    if (newEffect.type === GameEffectFadeEnum.fadeOut) {
      // Animate from 1 to 0 for fadeOut
      setOpacity(1); // Start visible
      setTimeout(() => setOpacity(0), 10); // Trigger fade out
    } else if (newEffect.type === GameEffectFadeEnum.fadeIn) {
      // Animate from 0 to 1 for fadeIn
      setOpacity(0); // Start invisible
      setTimeout(() => setOpacity(1), 10); // Trigger fade in
    }
  }, [gamePlay.state.effects?.fadeViewport]);

  const onFadeClick = () => {
    logger.info("EffectFadeViewport clicked and blocked");
  };

  return (
    <FadingWrapper
      durationSec={effect?.durationSec}
      opacity={opacity}
      onClick={opacity === 1 ? undefined : onFadeClick}
    >
      {props.children}
    </FadingWrapper>
  );
};

export default EffectFadeViewport;
