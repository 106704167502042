import AssetBeveledRectangle from "./sections/AssetBeveledRectangle";
import AssetMenuButton from "./sections/AssetMenuButton";
import AssetMenuInputText from "./sections/AssetMenuInputText";
import AssetMenuScrollable from "./sections/AssetMenuScrollable";
import Box from "game-engine/_dev/basic-components/Box";

const AssetsGuiElements = (props: { isOpen?: boolean }) => {
  //
  // RENDER
  //
  return (
    <Box
      label="GUI ELements"
      useFlex
      gap="10px"
      childMinWidth="60%"
      isOpen={props.isOpen}
      level={1}
    >
      <AssetBeveledRectangle />
      <AssetMenuButton />
      <AssetMenuInputText />
      <AssetMenuScrollable />
    </Box>
  );
};

export default AssetsGuiElements;
