/* eslint-disable react-hooks/exhaustive-deps */

import { Group, Rect } from "react-konva";

import GAME_CONFIG from "game-files/gameConfig";
import Item from "game-engine/components/game-elements/Item";
import { StopActionsType } from "game-engine/components/game-elements/Scene/logic/useSceneActions";
import { arrayOf } from "game-engine/utils";
import { useCallback } from "react";
import useGame from "game-engine/hooks/useGame";

const Inventory = (props: { stopActions: StopActionsType }) => {
  const { stopActions } = props;

  const { gameItems, gameFns } = useGame();

  const { slotWidth, slotHeight } = GAME_CONFIG.gui.inventory;

  const onInventorySlotClick = (index: number) => {
    const itemInCursor = gameItems.state.itemInCursor;
    const itemInSlot = gameFns.getItemInInventorySlot(index);

    if (stopActions && (itemInCursor || itemInSlot)) {
      stopActions();
    }

    // PUT ITEM FROM CURSOR INTO INVENTORY SLOT + SWAP ITEMS IF THE SLOT IS ALREADY OCCUPIED
    if (itemInCursor) {
      gameFns.dropItemInInventorySlot({
        item: itemInCursor,
        slotIndex: index,
      });
    }

    // PUT ITEM FROM INVENTORY SLOT INTO CURSOR
    else if (itemInSlot) {
      gameFns.grabItemFromInventorySlot(index);
    }
  };

  const renderItemsInInventory = useCallback(() => {
    return (
      <>
        {arrayOf(
          GAME_CONFIG.gui.inventory.rows,
          arrayOf(GAME_CONFIG.gui.inventory.cols, null)
        ).map((row, y) =>
          row.map((col, x) => {
            const index = y * GAME_CONFIG.gui.inventory.cols + x;

            const itemInInventorySlot = gameFns.getItemInInventorySlot(index);

            return (
              <Group
                key={index}
                onClick={() => onInventorySlotClick(index)}
                x={Math.round(
                  x *
                    (GAME_CONFIG.gui.inventory.slotWidth +
                      GAME_CONFIG.gui.inventory.gapX)
                )}
                y={Math.round(
                  y *
                    (GAME_CONFIG.gui.inventory.slotHeight +
                      GAME_CONFIG.gui.inventory.gapY)
                )}
              >
                <Rect
                  width={slotWidth}
                  height={slotHeight}
                  fill={GAME_CONFIG.gui.inventory.fillColor}
                />

                {itemInInventorySlot ? (
                  <Group
                    position={{
                      x: Math.round(slotWidth * 0.5),
                      y: Math.round(slotHeight * 0.75),
                    }}
                  >
                    <Item item={itemInInventorySlot} />
                  </Group>
                ) : null}
              </Group>
            );
          })
        )}
      </>
    );
  }, [gameItems.state.itemsInInventory, onInventorySlotClick]);

  //
  // RENDER
  //
  return (
    <Group
      position={{
        x: Math.round(GAME_CONFIG.gui.inventory.origin.x),
        y: Math.round(GAME_CONFIG.gui.inventory.origin.y),
      }}
    >
      {renderItemsInInventory()}
    </Group>
  );
};

export default Inventory;
