import { GameDisplayModeEnum, Language } from "game-engine/types";

import GAME_CONFIG from "game-files/gameConfig";
import MenuButtonsLeftRight from "../menu-elements/MenuButtonsLeftRight";
import MenuInputRadio from "../menu-elements/MenuInputRadio";
import { MenuProps } from "..";
import MenuSpacer from "../menu-elements/MenuSpacer";
import MenuWrapper from "../menu-elements/MenuWrapper";
import useGame from "game-engine/hooks/useGame";

const SettingsMenu = (props: MenuProps) => {
  const { isActive, closeSubMenu } = props;
  const { gameFns, gamePlay } = useGame();

  const canvasX = GAME_CONFIG.window.canvasDimensions.x;
  const canvasY = GAME_CONFIG.window.canvasDimensions.y;

  const menuWidth = canvasX - 30;
  const menuHeight = canvasY - 45;

  const paddingX = 20;
  const contentWidth = menuWidth - paddingX * 2;
  const buttonWidth = 120;

  //
  // RENDER
  //
  return (
    <MenuWrapper
      isActive={isActive}
      width={menuWidth}
      height={menuHeight}
      title={{
        en: "Game Settings",
        cz: "Nastavení hry",
      }}
      titleGap={10}
    >
      <MenuInputRadio
        x={paddingX}
        width={contentWidth}
        buttonWidth={buttonWidth}
        label={{
          en: "Language",
          cz: "Jazyk",
        }}
        onChange={(lang) => gameFns.setSettings({ language: lang })}
        value={gamePlay.state.settings.language}
        options={[
          {
            label: { en: "English", cz: "English" },
            value: Language.en,
          },
          {
            label: { en: "Čeština", cz: "Čeština" },
            value: Language.cz,
          },
        ]}
      />

      <MenuInputRadio
        x={paddingX}
        width={contentWidth}
        buttonWidth={buttonWidth}
        label={{
          en: "Walk Speed",
          cz: "Rychlost chůze",
        }}
        onChange={(walkSpeed) => gameFns.setSettings({ walkSpeed: walkSpeed })}
        value={gamePlay.state.settings.walkSpeed}
        options={[
          {
            label: {
              en: "Regular",
              cz: "Normální",
            },
            value: GAME_CONFIG.actions.walkSpeed.slow,
          },
          {
            label: {
              en: "Fast",
              cz: "Rychlá",
            },
            value: GAME_CONFIG.actions.walkSpeed.fast,
          },
        ]}
      />

      <MenuInputRadio
        x={paddingX}
        width={contentWidth}
        buttonWidth={buttonWidth}
        label={{
          en: "Talk Speed",
          cz: "Rychlost mluvení",
        }}
        onChange={(talkSpeed) => gameFns.setSettings({ talkSpeed: talkSpeed })}
        value={gamePlay.state.settings.talkSpeed}
        options={[
          {
            label: {
              en: "Slow",
              cz: "Pomalá",
            },
            value: GAME_CONFIG.actions.talkSpeed.slow,
          },
          {
            label: {
              en: "Medium",
              cz: "Střední",
            },
            value: GAME_CONFIG.actions.talkSpeed.medium,
          },
          {
            label: {
              en: "Fast",
              cz: "Rychlá",
            },
            value: GAME_CONFIG.actions.talkSpeed.fast,
          },
        ]}
      />

      <MenuInputRadio
        x={paddingX}
        width={contentWidth}
        buttonWidth={buttonWidth}
        label={{
          en: "Music is",
          cz: "Hudba je",
        }}
        onChange={(playMusic) => gameFns.setSettings({ playMusic: playMusic })}
        value={gamePlay.state.settings.playMusic}
        options={[
          {
            label: { en: "On", cz: "Zapnuta" },
            value: true,
          },
          {
            label: { en: "Off", cz: "Vypnuta" },
            value: false,
          },
        ]}
      />

      <MenuInputRadio
        x={paddingX}
        width={contentWidth}
        buttonWidth={buttonWidth}
        label={{
          en: "Sounds are",
          cz: "Zvuky jsou",
        }}
        onChange={(playSounds) =>
          gameFns.setSettings({ playSounds: playSounds })
        }
        value={gamePlay.state.settings.playSounds}
        options={[
          {
            label: { en: "On", cz: "Zapnuty" },
            value: true,
          },
          {
            label: { en: "Off", cz: "Vypnuty" },
            value: false,
          },
        ]}
      />

      <MenuInputRadio
        x={paddingX}
        width={contentWidth}
        buttonWidth={buttonWidth}
        label={{
          en: "Display Mode",
          cz: "Velikost zobrazení",
        }}
        onChange={(displayMode) =>
          gameFns.setSettings({ displayMode: displayMode })
        }
        value={gamePlay.state.settings.displayMode}
        options={[
          {
            label: { en: "Windowed", cz: "V okně" },
            value: GameDisplayModeEnum.default,
          },
          {
            label: { en: "Fullscreen", cz: "Celá obrazovka" },
            value: GameDisplayModeEnum.fullscreen,
          },
        ]}
      />

      <MenuSpacer height={14} />

      <MenuButtonsLeftRight
        x={paddingX}
        width={contentWidth}
        button1={{
          label: { en: "Developer Tools", cz: "Pro vývojáře" },
          width: buttonWidth,
          onClick: () => {
            window.open(`${window.location.href}?tab=developer`, "_blank");
          },
        }}
        button2={{
          label: { en: "Main Menu", cz: "Hlavní nabídka" },
          width: buttonWidth,
          onClick: closeSubMenu,
        }}
      />
    </MenuWrapper>
  );
};

export default SettingsMenu;
