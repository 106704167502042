import GAME_CONFIG from "game-files/gameConfig";
import { Group } from "react-konva";
import MenuButton from "./MenuButton";
import Text from "game-engine/components/basic-elements/Text";
import { TranslatedString } from "game-engine/types";

type MenuInputRadioOptionType = {
  label: string | TranslatedString;
  value: any;
};

const MenuInputRadio = (props: {
  x?: number;
  y?: number;
  width: number;
  label: string | TranslatedString;
  value: any;
  options: MenuInputRadioOptionType[];
  onChange: (v: any) => void;
  buttonWidth?: number;
}) => {
  const {
    x = 0,
    y = 0,
    width,
    buttonWidth = 120,
    label,
    value,
    options,
    onChange,
  } = props;

  const onClick = () => {
    const currentIndex = options.findIndex((option) => option.value === value);
    const nextIndex = (currentIndex + 1) % options.length;
    onChange(options[nextIndex].value);
  };

  //
  // RENDER
  //
  return (
    <Group x={x} y={y}>
      <Text
        text={label}
        maxWidth={width - buttonWidth}
        color={GAME_CONFIG.menu.colorText}
        outlined={false}
        shadow
        opacity={GAME_CONFIG.menu.textOpacityDefault}
      />

      <MenuButton
        x={width - buttonWidth}
        width={buttonWidth}
        label={options.find((o) => o.value === value)?.label}
        onClick={onClick}
      />
    </Group>
  );
};

export default MenuInputRadio;
