import GAME_CONFIG from "game-files/gameConfig";
import SCENE_CONFIGS from "game-files/scenes/SCENE_CONFIGS";
import devToolsConfig from "game-engine/configs/devtools-config";
import styled from "styled-components";

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
`;

const SceneList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, 250px);
  gap: 10px;
  align-items: center;
  justify-content: center;
  padding: 10%;
`;

const SceneThumbnail = styled.img`
  width: 100%;
  object-fit: contain;
`;

const SceneOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${devToolsConfig.background}AA;

  transition: all 0.2s ease;
`;
const SceneName = styled.div`
  font-size: 12px;
  font-weight: 600;
  color: #fffd;

  transition: all 0.2s ease;
`;

const SceneItem = styled.div`
  position: relative;
  cursor: pointer;
  aspect-ratio: ${GAME_CONFIG.scene.dimensions.x} /
    ${GAME_CONFIG.scene.dimensions.y};

  &:hover {
    ${SceneOverlay} {
      background: ${devToolsConfig.background}00;
    }
    ${SceneName} {
      margin-top: 35%;
    }
  }
`;

const NewSceneItem = styled.div`
  cursor: pointer;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff2;

  font-size: 12px;
  font-weight: 600;

  transition: all 0.2s ease;

  &:hover {
    background: #0004;
  }
`;

//
// COMPONENT
//
const SceneSelection = (props: {
  onNewScene: () => void;
  onEditScene: (configId: string) => void;
}) => {
  const { onNewScene, onEditScene } = props;

  //
  // RENDER
  //
  return (
    <Wrapper>
      <SceneList>
        <SceneItem onClick={onNewScene}>
          <NewSceneItem>NEW SCENE</NewSceneItem>
        </SceneItem>

        {Object.values(SCENE_CONFIGS).map((scene) => {
          return (
            <SceneItem
              key={scene.configId}
              onClick={() => onEditScene(scene.configId)}
            >
              <SceneThumbnail src={scene.preview?.src} />
              <SceneOverlay>
                <SceneName>{scene.configId}</SceneName>
              </SceneOverlay>
            </SceneItem>
          );
        })}
      </SceneList>
    </Wrapper>
  );
};

export default SceneSelection;
