import Assets from "game-engine/_dev/dev-components/Assets";
import AssetsCharacterPage from "game-engine/_dev/dev-components/AssetsCharacterPage";
import AssetsItemPage from "game-engine/_dev/dev-components/AssetsItemPage";
import { ItemConfigId } from "game-files/common/ids";
import devToolsConfig from "game-engine/configs/devtools-config";
import styled from "styled-components";

const Wrapper = styled.div`
  position: relative;

  flex: 1;
  width: 100%;
  height: 100%;
  overflow: auto;

  background: ${devToolsConfig.background};

  padding-top: 70px;
  padding-bottom: 150px;
`;

const Content = styled.div`
  margin: auto;
  width: 95%;
  max-width: 1600px;
`;

export const URL_PARAM_CHARACTER_CONFIG_ID = "characterConfigId";
export const URL_PARAM_ITEM_CONFIG_ID = "itemConfigId";

const TabAssets = () => {
  const urlParams = new URLSearchParams(window.location.search);

  const activeCharacterConfigId = urlParams.get(URL_PARAM_CHARACTER_CONFIG_ID);
  const activeItemConfigId = urlParams.get(URL_PARAM_ITEM_CONFIG_ID);

  //
  // RENDER
  //
  return (
    <Wrapper>
      <Content>
        {activeCharacterConfigId ? (
          <AssetsCharacterPage characterConfigId={activeCharacterConfigId} />
        ) : activeItemConfigId ? (
          <AssetsItemPage itemConfigId={activeItemConfigId as ItemConfigId} />
        ) : (
          <Assets />
        )}
      </Content>
    </Wrapper>
  );
};

export default TabAssets;
