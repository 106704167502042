/* eslint-disable react-hooks/exhaustive-deps */

import {
  CinematicOnEnd,
  CinematicScreenItem_AddLayer,
  CinematicScreenItem_Pause,
  CinematicScreenSize,
  CinematicScreenType,
  GamePlayCurrentSceneType,
} from "game-engine/types";
import { Group, Rect } from "react-konva";
import { useEffect, useRef, useState } from "react";

import CinematicScreenRender from "./CinematicScreenRender";
import GAME_CONFIG from "game-files/gameConfig";
import { GUIBlockerProps } from "game-engine/components/game-viewport/GameViewport";
import Transition from "game-engine/components/basic-elements/Transition";
import { TransitionFadeAnimation } from "game-engine/components/basic-elements/Transition/types/fade";
import useGame from "game-engine/hooks/useGame";

const CinematicScreen = (props: {
  cinematicScreen: CinematicScreenType;
  onEndSettings?: CinematicOnEnd;
  isVisible?: boolean;
  setGuiBlocker: (data: GUIBlockerProps) => void;
  onCinematicLoaded?: () => void;
}) => {
  const {
    cinematicScreen,
    onEndSettings,
    isVisible,
    setGuiBlocker,
    onCinematicLoaded,
  } = props;

  const { gameFns, sceneOffset, logger } = useGame();

  let offset = sceneOffset;
  let width = GAME_CONFIG.scene.dimensions.x;
  let height = GAME_CONFIG.scene.dimensions.y;

  if (cinematicScreen.size === CinematicScreenSize.viewport) {
    offset = { x: 0, y: 0 };
    width = GAME_CONFIG.window.canvasDimensions.x;
    height = GAME_CONFIG.window.canvasDimensions.y;
  }

  //
  // CINEMATIC LOADED
  //
  const [cinematicLoaded, setCinematicLoaded] = useState(false);

  useEffect(() => {
    if (cinematicLoaded) {
      logger.info(
        `cinematic text loaded (${cinematicScreen.id})`,
        cinematicScreen
      );
      if (onCinematicLoaded) {
        onCinematicLoaded();
      }
    }
  }, [cinematicLoaded]);

  //
  // ON INIT
  //
  useEffect(() => {
    setGuiBlocker({ blockGui: true, unskippable: true });
  }, []);

  //
  // FADE IN/OUT
  //
  const [fade, setFade] = useState<TransitionFadeAnimation>({
    from: cinematicScreen.fadeIn ? 1 : 0,
  });

  const defaultFadeDurationSec = GAME_CONFIG.cinematics.fadeSceneDurationSec;

  const getFadeInDurationSec = () => {
    const isAllowed = !!cinematicScreen.fadeIn;

    if (isAllowed) {
      if (typeof cinematicScreen.fadeIn === "object") {
        return (cinematicScreen.fadeIn as any)?.durationSec ?? 0;
      }
      if (cinematicScreen.fadeIn) {
        return defaultFadeDurationSec;
      }
    }
    return 0;
  };

  const getFadeOutDurationSec = () => {
    const isAllowed = !!cinematicScreen.fadeOut;

    if (isAllowed) {
      if (typeof cinematicScreen.fadeOut === "object") {
        return (cinematicScreen.fadeOut as any)?.durationSec ?? 0;
      }
      if (cinematicScreen.fadeOut) {
        return defaultFadeDurationSec;
      }
    }
    return 0;
  };

  //
  // SEQUENCE
  //
  const [animationStep, setAnimationStep] = useState(-1);

  const animationStepRef = useRef(animationStep);

  useEffect(() => {
    animationStepRef.current = animationStep;
  }, [animationStep]);

  const timerBeforeRef = useRef<any>();
  const timerAfterRef = useRef<any>();
  const timerOnEndRef = useRef<any>();
  const timerPauseRef = useRef<any>();

  useEffect(() => {
    return () => {
      clearTimeout(timerBeforeRef.current);
      clearTimeout(timerAfterRef.current);
      clearTimeout(timerOnEndRef.current);
      clearTimeout(timerPauseRef.current);
    };
  }, []);

  useEffect(() => {
    if (cinematicLoaded) {
      const fadeInDurationSec = getFadeInDurationSec();

      if (cinematicScreen.fadeIn) {
        setFade({
          from: 1,
          to: 0,
          durationSec: fadeInDurationSec,
        });
      }

      const pauseBeforeSec =
        fadeInDurationSec +
        (cinematicScreen.pauseBefore?.durationSec ??
          GAME_CONFIG.cinematics.textPauseBefore.durationSec);

      if (pauseBeforeSec > 0) {
        timerBeforeRef.current = setTimeout(
          () => setAnimationStep(0),
          pauseBeforeSec * 1000
        );
      } else {
        setAnimationStep(0);
      }
    }
  }, [cinematicLoaded]);

  //
  // ON ANIMATION STEP CHANGE
  //
  useEffect(() => {
    if (animationStep < 0) {
      return;
    }

    const animationItem = cinematicScreen.sequence[animationStep];

    // AFTER LAST ANIMATION STEP
    if (!animationItem || animationStep > cinematicScreen.sequence.length - 1) {
      const pauseAfterSec =
        cinematicScreen.pauseAfter?.durationSec ??
        GAME_CONFIG.cinematics.textPauseAfter.durationSec;

      if (pauseAfterSec > 0) {
        timerAfterRef.current = setTimeout(() => onEnd(), pauseAfterSec * 1000);
      } else {
        onEnd();
      }

      return;
    }

    // ANIMATION ITEM - PAUSE
    const pause = (animationItem as CinematicScreenItem_Pause)?.pause
      ?.durationSec;

    if (pause) {
      timerAfterRef.current = setTimeout(
        () => setAnimationStep(animationStepRef.current + 1),
        pause * 1000
      );

      return;
    }

    // ANIMATION ITEM - ADD IMAGE LAYER
    const addLayer = (animationItem as CinematicScreenItem_AddLayer)?.addLayer;
    if (addLayer) {
      onAddLayer();
    }

    // HANDLE PAUSE-UNTIL-FINISHED
    if (!animationItem?.pauseUntilFinished) {
      setAnimationStep(animationStepRef.current + 1);
      return;
    }
  }, [animationStep]);

  //
  // ON SEQUENCE END
  //
  const onEnd = () => {
    // 0 : fade-out
    const fadeOutDurationSec = getFadeOutDurationSec();
    if (cinematicScreen.fadeOut) {
      setFade({
        from: 0,
        to: 1,
        durationSec: fadeOutDurationSec,
      });
    }

    // 1 : pause before ending the cinematic
    const pauseOnEndDurationSec =
      fadeOutDurationSec + onEndSettings?.pause?.durationSec || 0;

    const endCinematic = () => {
      // 1: check if cinematic sequence has next cinematic
      if (onEndSettings?.setCinematicIds?.length) {
        const currentScene: GamePlayCurrentSceneType = {
          uniqueSceneId: undefined,
          cinematic: {
            id: onEndSettings?.setCinematicIds[0],
            onEnd: {
              ...onEndSettings,
              setCinematicIds: onEndSettings?.setCinematicIds.slice(1),
            },
          },
          walkIn: undefined,
          isSetByDeveloper: undefined,
        };

        return gameFns.setCurrentScene(currentScene);
      }

      // 2: no cinematics remaining -> end

      // 2a) check if scene is specified
      if (onEndSettings?.setSceneId) {
        return gameFns.setCurrentScene({
          uniqueSceneId: onEndSettings.setSceneId,
          fadeIn: onEndSettings.sceneFadeIn,
          walkIn: onEndSettings.sceneWalkIn,
        });
      }

      // 2b) check if death is required
      if (onEndSettings?.death) {
        setGuiBlocker(undefined);
        return gameFns.killMainCharacter();
      }

      // 3: there's no way to continue
      console.warn(
        "Cinematic sequence ended without onEndSettings resolution (from cinematic screen)."
      );
      return undefined;
    };

    if (pauseOnEndDurationSec > 0) {
      timerOnEndRef.current = setTimeout(
        () => endCinematic(),
        pauseOnEndDurationSec * 1000
      );
    } else {
      endCinematic();
    }
  };

  //
  // ADD IMAGE LAYER
  //
  const [addLayer, setAddLayer] = useState<{
    index: number;
    onImageLayerAdded: () => void;
  }>();

  const addImageLayerIndexRef = useRef(0);

  const onAddLayer = () => {
    setAddLayer({
      index: addImageLayerIndexRef.current,
      onImageLayerAdded: () => {
        setAddLayer(undefined);
      },
    });
    addImageLayerIndexRef.current = addImageLayerIndexRef.current + 1;
  };

  //
  // RENDER
  //
  return (
    <Group
      position={offset}
      opacity={isVisible ? 1 : 0}
      listening={isVisible && cinematicLoaded}
    >
      <Group clipX={0} clipY={0} clipWidth={width} clipHeight={height}>
        <CinematicScreenRender
          width={width}
          height={height}
          cinematicScreen={cinematicScreen}
          onImagesLoaded={() => setCinematicLoaded(true)}
          currentSequenceItem={cinematicScreen.sequence[animationStep]}
          onAnimationEnd={
            cinematicScreen.sequence[animationStep]?.pauseUntilFinished
              ? () => {
                  setAnimationStep(animationStepRef.current + 1);
                }
              : undefined
          }
          addLayer={addLayer}
        />

        <Transition fade={fade}>
          <Rect
            width={width}
            height={height}
            fill={GAME_CONFIG.cinematics.fadeFillColor}
          />
        </Transition>
      </Group>
    </Group>
  );
};

export default CinematicScreen;
