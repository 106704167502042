import { ITEM_GENDER, getItemSuffixes } from "game-files/items/_utils";

import IMG_default from "./assets/default.png";
import { ItemConfigId } from "game-files/common/ids";
import { ItemConfigType } from "game-engine/types/item";

const Test_Key: ItemConfigType = {
  configId: ItemConfigId.Test_Key,
  name: { en: "Rusty Key", cz: "Rezavý klíč" },

  suffix: getItemSuffixes({ gender: ITEM_GENDER.masculine }),

  render: {
    image: { src: IMG_default },
    width: 20,
    height: 10,
    offsetY: 3,
    noClickThroughAlpha: true,
  },
};

export default Test_Key;
