import { Rect, Stage } from "react-konva";

import { Dimensions } from "game-engine/types";
import PixelatedLayer from "game-engine/components/basic-elements/PixelatedLayer";
import styled from "styled-components";

const Wrapper = styled.div<{
  dimensions: Dimensions;
}>`
  margin: auto;
  width: ${(props) => props.dimensions.x}px;
  height: ${(props) => props.dimensions.y}px;
  overflow: hidden;
`;

const Transform = styled.div<{ scale: number }>`
  transform-origin: 50% 50%;
  transform: scale(${(props) => props.scale}, ${(props) => props.scale});

  &,
  & > * {
    image-rendering: optimizeSpeed;
    image-rendering: -moz-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: -o-crisp-edges;
    image-rendering: pixelated;
    -ms-interpolation-mode: nearest-neighbor;
  }

  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const AssetPreview = (props: {
  width: number;
  height: number;
  contentWidth?: number;
  contentHeight?: number;
  scale?: number;
  children?: any;
  background?: string;
}) => {
  const frameWidth = props.width;
  const frameHeight = props.height;
  const contentWidth = props.contentWidth || frameWidth;
  const contentHeight = props.contentHeight || frameHeight;
  const scale = props.scale || 1;
  const background = props.background || "#fff5";

  //
  // RENDER
  //
  return (
    <Wrapper dimensions={{ x: frameWidth * scale, y: frameHeight * scale }}>
      <Transform scale={scale}>
        <Stage width={contentWidth} height={contentHeight}>
          <PixelatedLayer>
            <Rect
              x={0}
              y={0}
              width={contentWidth}
              height={contentHeight}
              fill={background}
            />

            {props.children}
          </PixelatedLayer>
        </Stage>
      </Transform>
    </Wrapper>
  );
};

export default AssetPreview;
