import Button from "../../Button";
import InputLabel from "../_InputLabel";
import ValueBox from "../_ValueBox";
import devToolsConfig from "game-engine/configs/devtools-config";
import styled from "styled-components";

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const CheckMark = styled.div`
  position: absolute;
  text-align: center;
  left: 2px;
  bottom: -2px;
`;

const Checkbox = (props: {
  label: string;
  inputLabel?: string;
  subLabel?: string;
  value: boolean;
  onChange: (v: boolean) => void;
  style?: any;
  innerStyle?: any;
  isDisabled?: boolean;
  noBackground?: boolean;
}) => {
  //
  // RENDER
  //
  return (
    <Wrapper style={props.style}>
      {props.inputLabel && <InputLabel label={props.inputLabel} />}

      <Button
        subLabel={props.subLabel}
        subLabelIndent={props.subLabel ? "25px" : undefined}
        isActive={props.value}
        onClick={() => props.onChange(!props.value)}
        alignLeft
        noBorder
        isDisabled={props.isDisabled}
        style={props.innerStyle}
        backgroundColor={devToolsConfig.inputBackground}
        noBackground={props.noBackground}
      >
        <ValueBox>
          <CheckMark>{props.value ? "✔" : ""}</CheckMark>
        </ValueBox>
        {props.label}
      </Button>
    </Wrapper>
  );
};

export default Checkbox;
