import { Group, Rect } from "react-konva";
import {
  RunActionsType,
  StopActionsType,
} from "../game-elements/Scene/logic/useSceneActions";

import Canvas from "../basic-elements/Canvas";
import { DevCursorType } from "../game-elements/Cursor";
import DevPositionClicker from "../basic-elements/_DevPositionClicker";
import GAME_CONFIG from "game-files/gameConfig";
import GuiBlocker from "../game-elements/GuiBlocker";
import PixelatedLayer from "../basic-elements/PixelatedLayer";
import { Position } from "game-engine/types";
import UserInterface from "../gui-elements/UserInterface";
import ViewportEffects from "../game-effects/viewport-effects";
import useGame from "../../hooks/useGame";
import { useState } from "react";

export type GUIBlockerProps = {
  blockGui: boolean;
  unskippable: boolean;
};

export type CanvasClickSettings = {
  cursor: DevCursorType;
  onClick: (options: {
    canvasPosition: Position;
    scenePosition: Position;
  }) => void;
};

export const SCENE_OVERLAY_NAME = "Scene-overlay-layer";

const GameViewport = (props: {
  children?: any;
  renderGUI?: {
    onExitGame: () => void;
    onNewGame: () => void;
    onMenuOpen?: () => void;
    onMenuClose?: () => void;
  };
  noCursor?: boolean;
  guiBlocker?: GUIBlockerProps;
  guiBlockerForced?: boolean;
  guiBlockerClickRef?: { current: (clickedPosition: Position) => void };
  runActionsRef?: { current: RunActionsType };
  stopActionsRef?: { current: StopActionsType };
  canvasClickSettings?: CanvasClickSettings;
}) => {
  const {
    renderGUI,
    canvasClickSettings,
    noCursor,
    guiBlocker,
    guiBlockerForced,
    guiBlockerClickRef,
    runActionsRef,
    stopActionsRef,
  } = props;

  const { engineConfig } = useGame();

  const canvasX = GAME_CONFIG.window.canvasDimensions.x;
  const canvasY = GAME_CONFIG.window.canvasDimensions.y;

  const [guiLoaded, setGuiLoaded] = useState(false);

  //
  // RENDER
  //
  return (
    <ViewportEffects>
      <Canvas
        onClick={canvasClickSettings?.onClick}
        devCursor={canvasClickSettings?.cursor}
        noCursor={noCursor || guiBlocker?.blockGui}
      >
        <PixelatedLayer name={SCENE_OVERLAY_NAME}>
          <Group>
            <Rect
              x={0}
              y={0}
              width={canvasX}
              height={canvasY}
              fill={engineConfig.state.renderCanvasBackground ? "red" : "black"}
            />
          </Group>

          {/* GAME CONTENT (SCENES) GO HERE */}
          {guiLoaded ? props.children : null}

          {/* SCENE_OVERLAY_NAME content will be appended here */}
          {/* e.g. CHARACTER DIALOG goes here */}
          {/* <overlay> */}
        </PixelatedLayer>

        <PixelatedLayer>
          {renderGUI ? (
            <UserInterface
              runActionsRef={runActionsRef}
              stopActionsRef={stopActionsRef}
              onExitGame={renderGUI?.onExitGame}
              onNewGame={renderGUI?.onNewGame}
              onMenuOpen={renderGUI?.onMenuOpen}
              onMenuClose={renderGUI?.onMenuClose}
              onImagesLoaded={() => setGuiLoaded(true)}
            />
          ) : null}

          <GuiBlocker
            isActive={guiBlocker?.blockGui}
            forceIsActive={guiBlockerForced}
            unskippable={guiBlocker?.unskippable}
            onClick={guiBlockerClickRef?.current}
          />

          {engineConfig.state.copyScenePositionOnClick && (
            <DevPositionClicker />
          )}
        </PixelatedLayer>
      </Canvas>
    </ViewportEffects>
  );
};

export default GameViewport;
