/* eslint-disable react-hooks/exhaustive-deps */

import { SceneLayerDataType, SceneLayerType } from "game-engine/types";
import {
  createActionName,
  getSceneLayerByCurrentObjective,
  storeEventInDevTools,
} from "game-engine/utils";
import { useContext, useMemo } from "react";

import { CursorRenderType } from "game-engine/types/cursor";
import DepthLine from "game-engine/components/basic-elements/DepthLine";
import { DevToolsContext } from "game-engine";
import GAME_CONFIG from "game-files/gameConfig";
import { GetDynamicLightingFiltersFunc } from "../Scene/layers/useDynamicLighting";
import { Group } from "react-konva";
import SpriteAnimated from "game-engine/components/basic-elements/SpriteAnimated";
import useGame from "game-engine/hooks/useGame";

const SceneLayer = (props: {
  sceneLayer: SceneLayerType;
  onClick?: (e?: any) => void;
  onImageLoaded?: () => void;
  getDynamicLightingFilters: GetDynamicLightingFiltersFunc;
}) => {
  const { sceneLayer, getDynamicLightingFilters } = props;
  const { gamePlay, engineConfig, logger, gameFns, gameItems } = useGame();
  const devTools = useContext(DevToolsContext);

  //
  // SCENE
  //
  const sceneLayerData = useMemo<SceneLayerDataType>(() => {
    const dataByCurrentObjective = getSceneLayerByCurrentObjective({
      dataByCurrentObjective: sceneLayer.dataByCurrentObjective,
      currentObjective: gameFns.getCurrentObjective(),
      events: gameFns.getEvents(),
    });

    return dataByCurrentObjective;
  }, [sceneLayer, gamePlay.state.currentObjective, gamePlay.state.events]);

  //
  // DYNAMIC LIGHTING
  //
  const imageFilters = useMemo(() => {
    return getDynamicLightingFilters({ depthY: sceneLayer.depthY });
  }, [getDynamicLightingFilters]);

  //
  // SETTINGS
  //
  const renderOutline =
    // do not render for full-scene-dimension layers
    sceneLayerData?.offset && engineConfig.state.renderSceneLayerOutline;
  const renderFill =
    // do not render for full-scene-dimension layers
    (sceneLayerData?.offset?.x || sceneLayerData?.offset?.y) &&
    engineConfig.state.renderSceneLayerFill;

  const ignoreOnClick =
    sceneLayerData?.ignoreOnClick || engineConfig.state.hideSceneLayers;

  const isInvisible =
    sceneLayerData?.isInvisible || engineConfig.state.hideSceneLayers;

  const cursorOnHover = sceneLayerData?.cursorOnHover;

  //
  // ON CLICK
  //
  const onClick = (e) => {
    const clickedPosition = gameFns.getClickedScenePosition(e);

    logger.info(
      `scene layer clicked [${clickedPosition.x}, ${clickedPosition.y}] (${sceneLayer.id})`,
      sceneLayer,
      sceneLayerData
    );

    storeEventInDevTools({
      devTools,
      event: {
        name: createActionName({
          name: "clicked on",
          value: sceneLayer?.id,
        }),
        data: {
          sceneLayerId: sceneLayer.id,
        },
      },
    });

    if (props.onClick) {
      props.onClick(e);
    }
  };

  //
  // RENDER
  //
  return !sceneLayerData ? null : (
    <Group
      listening={!ignoreOnClick}
      position={sceneLayerData?.offset || { x: 0, y: 0 }}
    >
      <Group
        onClick={onClick}
        onTap={onClick}
        onMouseEnter={
          !gameItems.state.itemInCursor && cursorOnHover
            ? () => gameFns.setCursorRenderMode(cursorOnHover)
            : undefined
        }
        onMouseLeave={
          !gameItems.state.itemInCursor && cursorOnHover
            ? () => gameFns.setCursorRenderMode(CursorRenderType.default)
            : undefined
        }
        opacity={isInvisible ? 0 : 1}
      >
        {/* SPRITE IS USED EVEN FOR SINGLE IMAGES TO BETTER HANDLE CHANGING .SRC */}
        <SpriteAnimated
          src={sceneLayerData?.image?.src}
          srcTransitionOnChange={sceneLayerData?.image?.transition}
          spriteConfig={{
            ...(sceneLayerData?.spriteConfig || {}),
            frameWidth:
              sceneLayerData?.spriteConfig?.frameWidth ??
              sceneLayerData?.image?.width ??
              GAME_CONFIG.scene.dimensions.x,
            frameHeight:
              sceneLayerData?.spriteConfig?.frameHeight ??
              sceneLayerData?.image?.height ??
              GAME_CONFIG.scene.dimensions.y,
          }}
          controlledAnimation={{
            playAnimation: !!sceneLayerData?.spriteConfig,
          }}
          getSpriteFrameRenderIndex={undefined}
          onImageLoaded={props.onImageLoaded}
          renderOutline={renderOutline}
          renderFill={renderFill}
          filters={imageFilters}
        />
      </Group>

      {engineConfig.state.renderSceneLayerDepthLine && sceneLayer?.depthY ? (
        <DepthLine
          color={engineConfig.state.sceneLayerDepthLineColor}
          position={{
            x: 0,
            y: sceneLayer?.depthY - (sceneLayerData?.offset?.y || 0),
          }}
        />
      ) : null}
    </Group>
  );
};

export default SceneLayer;
