import GAME_CONFIG from "game-files/gameConfig";
import { Rect } from "react-konva";
import useGame from "game-engine/hooks/useGame";

const DevPositionClicker = () => {
  const { sceneOffset, gameFns, logger } = useGame();

  const onClick = (e) => {
    const position = gameFns.getClickedPosition(e);

    const scenePosition = {
      x: position.x - sceneOffset.x,
      y: position.y - sceneOffset.y,
    };

    const copyString = `{x:${scenePosition.x}, y:${scenePosition.y}}`;

    navigator.clipboard.writeText(copyString);
    logger.info(`"${copyString}" copied to clipboard`);
  };

  return (
    <Rect
      onClick={onClick}
      onTap={onClick}
      x={0}
      y={0}
      width={GAME_CONFIG.window.canvasDimensions.x}
      height={GAME_CONFIG.window.canvasDimensions.y}
      fill={"white"}
      opacity={0}
    />
  );
};

export default DevPositionClicker;
