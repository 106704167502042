import { ACTION } from "game-engine/utils";
import { GameObjective } from "game-files/gameObjectives";
import ImagePreview from "./assets/preview_00.png";
import Layer_01_Background from "./assets/layer - 01 - background_00.png";
import Layer_02_Background_Rock from "./assets/layer - 02 - background - rock_00.png";
import Layer_03_Background_Trees from "./assets/layer - 03 - background - trees_00.png";
import Layer_04_Bushes_And_Rocks_Right from "./assets/layer - 04 - bushes and rocks right_00.png";
import Layer_05_Tree_Right from "./assets/layer - 05 - tree right_00.png";
import Layer_06_Tree_Right from "./assets/layer - 06 - tree right_00.png";
import Layer_07_Tree_Right from "./assets/layer - 07 - tree right_00.png";
import Layer_08_Bush_Middle from "./assets/layer - 08 - bush middle_00.png";
import Layer_09_Bush_Left from "./assets/layer - 09 - bush left_00.png";
import Layer_10_Tree_Left from "./assets/layer - 10 - tree left_00.png";
import Layer_11_Front_Trees from "./assets/layer - 11 - front - trees_00.png";
import Layer_Map_Depth from "./assets/layer - 00 - DEPTH MAP_00.png";
import Layer_Map_Drop from "./assets/layer - 00 - DROP MAP_00.png";
import Layer_Map_Walk from "./assets/layer - 00 - WALK MAP_00.png";
import SHARED_DATA from "../../SHARED_DATA";
import { SceneType } from "game-engine/types";
import { TestScenesMusic } from "../_shared/music";

const Test_Forest_L_R_U_D_v2: SceneType = {
  configId: "Test_Forest_L_R_U_D_v2",
  name: {
    [GameObjective.GAME_START]: {
      en: "Ancient Forest 04",
      cz: "Prastarý les 04",
    },
  },

  preview: { src: ImagePreview },
  uniqueId: undefined, // IS GENERATED FROM SCENES LAYOUT
  sceneNeighbors: undefined, // IS GENERATED FROM SCENES LAYOUT

  sceneWalkPaths: {
    [GameObjective.GAME_START]: {
      // NOT ALL WALK-PATHS ARE NECESSARILY USED (mainly for reusable scenes)
      // -> ACCESSIBLE WALK-PATHS ARE SPECIFIED IN SCENES LAYOUT MATRIX
      left: {
        edgeWalkY: 120,
        walkTo: { x: 20, y: 123 },
      },
      right: {
        edgeWalkY: 130,
        walkTo: { x: 275, y: 130 },
      },
      up: {
        edgeWalkX: 150,
        walkTo: { x: 150, y: 60 },
      },
      down: {
        edgeWalkX: 170,
        walkTo: { x: 160, y: 135 },
      },
    },
  },

  audio: {
    [GameObjective.GAME_START]: {
      music: TestScenesMusic,
    },
  },

  images: {
    background: {
      dataByCurrentObjective: {
        [GameObjective.GAME_START]: {
          image: { src: Layer_01_Background },
          dropMap: { src: Layer_Map_Drop },
          walkMap: { src: Layer_Map_Walk },
          depthMap: { src: Layer_Map_Depth },
          depthSettings: {
            scaleMin: 0.4,
            //scaleMax: 1,
          },
        },
      },
      actionsByCurrentObjective: undefined,
    },

    layers: [
      {
        id: "Layer_02_Background_Rock",
        dataByCurrentObjective: {
          [GameObjective.GAME_START]: {
            image: { src: Layer_02_Background_Rock },
          },
        },
        actionsByCurrentObjective: {
          [GameObjective.GAME_START]: {
            actions: [
              ACTION.talkRandom({
                dialogOptions: [
                  ...SHARED_DATA.dialogOptions.rockLarge,
                  ...SHARED_DATA.dialogOptions.obstacleNoSceneWalkOut,
                ],
              }),
            ],
          },
        },
      },

      {
        id: "Layer_03_Background_Trees",
        dataByCurrentObjective: {
          [GameObjective.GAME_START]: {
            image: { src: Layer_03_Background_Trees },
          },
        },
        actionsByCurrentObjective: {
          [GameObjective.GAME_START]: {
            actions: [
              ACTION.talkRandom({
                dialogOptions: [
                  ...SHARED_DATA.dialogOptions.tree,
                  ...SHARED_DATA.dialogOptions.forest,
                  ...SHARED_DATA.dialogOptions.forestBackground,
                ],
              }),
            ],
          },
        },
      },

      {
        id: "Layer_04_Bushes_And_Rocks_Right",
        depthY: 50,
        dataByCurrentObjective: {
          [GameObjective.GAME_START]: {
            image: { src: Layer_04_Bushes_And_Rocks_Right },
          },
        },
        actionsByCurrentObjective: {
          [GameObjective.GAME_START]: {
            actions: [
              ACTION.talkRandom({
                dialogOptions: [...SHARED_DATA.dialogOptions.rocksAndBushes],
              }),
            ],
          },
        },
      },

      {
        id: "Layer_05_Tree_Right",
        depthY: 48,
        dataByCurrentObjective: {
          [GameObjective.GAME_START]: {
            image: { src: Layer_05_Tree_Right },
          },
        },
        actionsByCurrentObjective: {
          [GameObjective.GAME_START]: {
            actions: [
              ACTION.talkRandom({
                dialogOptions: [
                  ...SHARED_DATA.dialogOptions.tree,
                  ...SHARED_DATA.dialogOptions.forest,
                ],
              }),
            ],
          },
        },
      },

      {
        id: "Layer_06_Tree_Right",
        depthY: 62,
        dataByCurrentObjective: {
          [GameObjective.GAME_START]: {
            image: { src: Layer_06_Tree_Right },
          },
        },
        actionsByCurrentObjective: {
          [GameObjective.GAME_START]: {
            actions: [
              ACTION.talkRandom({
                dialogOptions: [
                  ...SHARED_DATA.dialogOptions.tree,
                  ...SHARED_DATA.dialogOptions.treeOld,
                  ...SHARED_DATA.dialogOptions.forest,
                ],
              }),
            ],
          },
        },
      },

      {
        id: "Layer_07_Tree_Right",
        depthY: 86,
        dataByCurrentObjective: {
          [GameObjective.GAME_START]: {
            image: { src: Layer_07_Tree_Right },
          },
        },
        actionsByCurrentObjective: {
          [GameObjective.GAME_START]: {
            actions: [
              ACTION.talkRandom({
                dialogOptions: [
                  ...SHARED_DATA.dialogOptions.tree,
                  ...SHARED_DATA.dialogOptions.treeOld,
                  ...SHARED_DATA.dialogOptions.forest,
                ],
              }),
            ],
          },
        },
      },

      {
        id: "Layer_08_Bush_Middle",
        depthY: 90,
        dataByCurrentObjective: {
          [GameObjective.GAME_START]: {
            image: { src: Layer_08_Bush_Middle },
          },
        },
        actionsByCurrentObjective: {
          [GameObjective.GAME_START]: {
            actions: [
              ACTION.talkRandom({
                dialogOptions: [...SHARED_DATA.dialogOptions.bush],
              }),
            ],
          },
        },
      },

      {
        id: "Layer_09_Bush_Left",
        depthY: 70,
        dataByCurrentObjective: {
          [GameObjective.GAME_START]: {
            image: { src: Layer_09_Bush_Left },
          },
        },
        actionsByCurrentObjective: {
          [GameObjective.GAME_START]: {
            actions: [
              ACTION.talkRandom({
                dialogOptions: [...SHARED_DATA.dialogOptions.bush],
              }),
            ],
          },
        },
      },

      {
        id: "Layer_10_Tree_Left",
        depthY: 90,
        dataByCurrentObjective: {
          [GameObjective.GAME_START]: {
            image: { src: Layer_10_Tree_Left },
          },
        },
        actionsByCurrentObjective: {
          [GameObjective.GAME_START]: {
            actions: [
              ACTION.talkRandom({
                dialogOptions: [
                  ...SHARED_DATA.dialogOptions.tree,
                  ...SHARED_DATA.dialogOptions.treeOld,
                  ...SHARED_DATA.dialogOptions.forest,
                ],
              }),
            ],
          },
        },
      },

      {
        id: "Layer_11_Front_Trees",
        depthY: 10000,
        dataByCurrentObjective: {
          [GameObjective.GAME_START]: {
            image: { src: Layer_11_Front_Trees },
          },
        },
        actionsByCurrentObjective: {
          [GameObjective.GAME_START]: {
            actions: [
              ACTION.talkRandom({
                dialogOptions: [
                  ...SHARED_DATA.dialogOptions.tree,
                  ...SHARED_DATA.dialogOptions.forest,
                ],
              }),
            ],
          },
        },
      },
    ],
  },
};

export default Test_Forest_L_R_U_D_v2;
