/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from "react";

import Button from "../../Button";
import { HexAlphaColorPicker } from "react-colorful";
import InputLabel from "../_InputLabel";
import ValueBox from "../_ValueBox";
import devToolsConfig from "game-engine/configs/devtools-config";
import styled from "styled-components";

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const Dialog = styled.div`
  position: absolute;
  top: calc(100% + 10px);
  left: 20px;
  right: 20px;
  z-index: 100;

  .react-colorful {
    width: 100%;
    height: 300px;
  }
  .react-colorful__saturation {
    border-radius: 0;
  }
  .react-colorful__hue {
    height: 30px;
    border-radius: 0 0 4px 4px;
  }

  .react-colorful__saturation-pointer,
  .react-colorful__hue-pointer,
  .react-colorful__alpha-pointer {
    width: 20px;
    height: 20px;
    border-radius: 50%;
  }
`;

const ColorInput = (props: {
  label: string;
  inputLabel?: string;
  subLabel?: string;
  value: string;
  onChange: (v: string) => void;
  style?: any;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [color, setColor] = useState(props.value || "fff");

  useEffect(() => {
    if (color) {
      props.onChange(color);
    }
  }, [color]);

  //
  // RENDER
  //
  return (
    <Wrapper style={props.style}>
      {props.inputLabel && <InputLabel label={props.inputLabel} />}

      <Button
        subLabel={props.subLabel}
        subLabelIndent={props.subLabel ? "25px" : undefined}
        onClick={() => setIsOpen(!isOpen)}
        alignLeft
        noBorder
        isActive={isOpen}
        backgroundColor={devToolsConfig.inputBackground}
      >
        <ValueBox backgroundColor="#000" fillColor={props.value} />
        {props.label}
      </Button>

      {isOpen ? (
        <Dialog>
          <HexAlphaColorPicker color={color} onChange={setColor} />
        </Dialog>
      ) : null}
    </Wrapper>
  );
};

export default ColorInput;
