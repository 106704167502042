import {
  Direction,
  GameScenesDataType,
  LayoutSceneType,
  SceneType,
  ScenesLayoutGridType,
  ScenesLayoutLinkType,
  ScenesLayoutType,
  ScenesLayoutsDefType,
} from "game-engine/types";
import { arrayDeleteAt, arrayInsertAt, arrayOf } from "game-engine/utils";

import SCENE_CONFIGS_DEV from "game-files/scenes/_dev-scenes";
import { getAllWalkableSceneWalkPaths } from "../get-by-current-objective/get-scene-walk-paths";

//
// CHECK IF SCENES'S LAYOUT IS EMPTY (NO ROWS OR COLUMNS, EVEN EMPTY ONES (null arrays))
//
export const isScenesLayoutEmpty = (layout: ScenesLayoutType) => {
  return isScenesLayoutGridEmpty(layout?.grid);
};

export const isScenesLayoutGridEmpty = (grid: ScenesLayoutGridType) => {
  return !grid || !grid?.length || !grid[0]?.length;
};

//
// ADD NULL ROWS AND COLUMNS AROUND LAYOUT SCENES (ONLY IF NULL ROW/COL ISN'T THERE ALREADY)
//
export const extendScenesLayoutDef: (
  layout: ScenesLayoutType,
  direction: Direction
) => ScenesLayoutType = (layout, direction) => {
  if (direction === Direction.up) {
    return addScenesLayoutRow(layout, 0);
  }

  if (direction === Direction.down) {
    return addScenesLayoutRow(layout, layout.grid.length);
  }

  if (direction === Direction.left) {
    return addScenesLayoutColumn(layout, 0);
  }

  if (direction === Direction.right) {
    return addScenesLayoutColumn(layout, layout.grid[0]?.length);
  }
};

//
// ADD ROW TO SCENES LAYOUT (BY INDEX)
//
export const addScenesLayoutRow = (
  layout: ScenesLayoutType,
  rowIndex: number
) => {
  if (isScenesLayoutEmpty(layout)) {
    return { ...layout, grid: [[null]] };
  }

  const updatedGrid: ScenesLayoutGridType = arrayInsertAt(
    layout?.grid,
    rowIndex,
    arrayOf(layout.grid[0].length, null)
  );

  return { ...layout, grid: updatedGrid };
};

//
// ADD COLUMN TO SCENES LAYOUT (BY INDEX)
//
export const addScenesLayoutColumn = (
  layout: ScenesLayoutType,
  columnIndex: number
) => {
  if (isScenesLayoutEmpty(layout)) {
    return { ...layout, grid: [[null]] };
  }

  const updatedGrid: ScenesLayoutGridType = [...layout?.grid].map((row) => {
    return arrayInsertAt(row, columnIndex, null);
  });

  return { ...layout, grid: updatedGrid };
};

//
// DELETE ROW FROM SCENES LAYOUT (BY INDEX)
//
export const deleteScenesLayoutRow = (
  layout: ScenesLayoutType,
  rowIndex: number
) => {
  if (isScenesLayoutEmpty(layout)) {
    return { ...layout, grid: [[null]] };
  }

  const updatedGrid: ScenesLayoutGridType = arrayDeleteAt(
    layout?.grid,
    rowIndex
  );

  return {
    ...layout,
    grid: isScenesLayoutGridEmpty(updatedGrid) ? [[null]] : updatedGrid,
  };
};

//
// DELETE COLUMN FROM SCENES LAYOUT (BY INDEX)
//
export const deleteScenesLayoutColumn = (
  layout: ScenesLayoutType,
  columnIndex: number
) => {
  if (isScenesLayoutEmpty(layout)) {
    return { ...layout, grid: [[null]] };
  }

  const updatedGrid: ScenesLayoutGridType = [...layout?.grid].map((row) => {
    return arrayDeleteAt(row, columnIndex);
  });

  return {
    ...layout,
    grid: isScenesLayoutGridEmpty(updatedGrid) ? [[null]] : updatedGrid,
  };
};

//
// CLEAN SCENES LAYOUT OF NULL ROWS AND COLS
//
export const cleanScenesLayout = (layoutDef: ScenesLayoutType) => {
  let grid: ScenesLayoutGridType = [];

  // filter empty rows
  layoutDef?.grid?.forEach((row) => {
    if (row.filter((s) => s).length) {
      // row is not empty
      grid = [...grid, row];
    }
  });

  // filter empty columns
  const cleanGrid = arrayOf(grid?.length, []);

  for (let i = 0; i < grid[0]?.length; i++) {
    const col = grid.map((row) => row[i]);

    if (col.filter((s) => s).length) {
      // column is not empty
      for (let row = 0; row < grid?.length; row++) {
        cleanGrid[row] = [...cleanGrid[row], grid[row][i]];
      }
    }
  }

  return {
    ...layoutDef,
    grid: isScenesLayoutGridEmpty(cleanGrid) ? [[null]] : cleanGrid,
  };
};

//
// GET SCENES WITH DUPLICATE IDS
//
export const getDuplicateSceneIDs = (scenes: SceneType[]) => {
  const uniqueIds: string[] = [];
  const duplicateIds: string[] = [];

  scenes.forEach((scene) => {
    if (!uniqueIds.includes(scene.uniqueId)) {
      uniqueIds.push(scene.uniqueId);
    } else {
      duplicateIds.push(scene.uniqueId);
    }
  });
  return duplicateIds;
};

//
// GET DATA ABOUT SCENES WITH DUPLICATE IDS (INCLUDES INFO ABOUT SCENES LAYOUT)
//
export type DuplicateSceneIDsData = {
  scene: SceneType;
  duplicateId: string;
  scenesLayoutId: string;
};

export const getDuplicateSceneIDsWithData = (
  scenes: SceneType[],
  scenesLayouts: ScenesLayoutType[]
) => {
  const duplicateIDs = getDuplicateSceneIDs(scenes);
  const duplicateData: DuplicateSceneIDsData[] = [];

  scenesLayouts.forEach((scenesLayout) =>
    scenesLayout?.neighborGrid?.forEach((row) =>
      row.forEach((scene) => {
        if (scene) {
          if (duplicateIDs.includes(scene?.uniqueId)) {
            duplicateData.push({
              scene: scene,
              duplicateId: scene?.uniqueId,
              scenesLayoutId: scenesLayout.id,
            });
          }
        }
      })
    )
  );

  return duplicateData;
};

//
// GO THROUGH SCENES LAYOUT AND GENERATE UNIQUE SCENE IDS (repeatable scenes have the same id otherwise)
//
export const assignUniqueIdsInScenesLayout = (
  layoutDef: ScenesLayoutType,
  options?: { logErrors?: boolean }
) => {
  const sceneIds = [];

  const gridWithUniqueIds: ScenesLayoutGridType = layoutDef?.grid?.map(
    (row, y) =>
      row.map((sceneData, x) => {
        if (sceneData && sceneData.scene) {
          let uniqueId = `${sceneData.scene.configId}_${layoutDef.id}_${x}_${y}`;

          if (sceneData.overrideSceneId) {
            uniqueId = sceneData.overrideSceneId;
          }

          if (options?.logErrors && sceneIds.includes(uniqueId)) {
            console.error("DUPLICATE SCENE ID FOUND!", uniqueId);
          }

          sceneIds.push(uniqueId);
          return {
            ...sceneData,
            scene: {
              ...sceneData.scene,
              uniqueId,
            },
          } as LayoutSceneType;
        }
        return null;
      })
  );

  return { ...layoutDef, grid: gridWithUniqueIds };
};

//
// GO THROUGH SCENES LAYOUT MATRIX AND SET NEIGHBORING SCENE IDS (unique ids must already be set)
//
export const getScenesNeighborGrid = (
  layoutWithUniqueIds: ScenesLayoutType
) => {
  const grid = layoutWithUniqueIds?.grid;
  const scenesNeighborGrid: SceneType[][] = grid.map((row, y) =>
    row.map((sceneData, x) => {
      if (sceneData) {
        const { scene, noWalkLeft, noWalkRight, noWalkUp, noWalkDown } =
          sceneData;

        //
        // NEIGHBORING SCENES
        //
        const sceneLeft: SceneType = x > 0 ? grid[y][x - 1]?.scene : undefined;

        const sceneRight: SceneType =
          x < row.length - 1 ? grid[y][x + 1]?.scene : undefined;

        const sceneUp: SceneType = y > 0 ? grid[y - 1][x]?.scene : undefined;

        const sceneDown: SceneType =
          y < grid.length - 1 ? grid[y + 1][x]?.scene : undefined;

        //
        // GET ALL WALK PATHS FOR EACH SCENE (walk paths can be turned on/off > need to get all of possible walk paths)
        //
        const currentSceneWalkPaths = getAllWalkableSceneWalkPaths({
          dataByCurrentObjective: scene?.sceneWalkPaths,
        });
        const leftSceneWalkPaths = getAllWalkableSceneWalkPaths({
          dataByCurrentObjective: sceneLeft?.sceneWalkPaths,
        });
        const rightSceneWalkPaths = getAllWalkableSceneWalkPaths({
          dataByCurrentObjective: sceneRight?.sceneWalkPaths,
        });
        const upSceneWalkPaths = getAllWalkableSceneWalkPaths({
          dataByCurrentObjective: sceneUp?.sceneWalkPaths,
        });
        const downSceneWalkPaths = getAllWalkableSceneWalkPaths({
          dataByCurrentObjective: sceneDown?.sceneWalkPaths,
        });

        const sceneNeighbors = {
          sceneId_left:
            !noWalkLeft && // ..................... check that walk-left is not forbidden
            currentSceneWalkPaths?.left && // ...... check if scene has defined walk/out path to the left
            leftSceneWalkPaths?.right // ... check if scene on the left has defined walk-in path to the right (to this scene)
              ? sceneLeft.uniqueId
              : undefined,

          sceneId_right:
            !noWalkRight &&
            currentSceneWalkPaths?.right &&
            rightSceneWalkPaths?.left
              ? sceneRight.uniqueId
              : undefined,

          sceneId_up:
            !noWalkUp && currentSceneWalkPaths?.up && upSceneWalkPaths?.down
              ? sceneUp.uniqueId
              : undefined,

          sceneId_down:
            !noWalkDown && currentSceneWalkPaths?.down && downSceneWalkPaths?.up
              ? sceneDown.uniqueId
              : undefined,
        };

        return {
          ...scene,
          sceneNeighbors,
        };
      } else {
        return null;
      }
    })
  );

  return scenesNeighborGrid;
};

//
// GET FLAT ARRAY OF SCENES FROM SCENES IN NEIGHBOR GRID
//
export const getScenesFromScenesGrid = (scenesNeighborGrid: SceneType[][]) => {
  const scenes = [];
  scenesNeighborGrid.forEach((row) =>
    row.forEach((scene) => {
      if (scene) {
        scenes.push(scene);
      }
    })
  );
  return scenes;
};

//
// FUNC THAT ENCAPSULATES THE ENTIRE PROCESS OF GETTING THE FINAL GAME SCENES FROM LAYOUT DEFINITIONS
//
export const processScenesLayout = (
  scenesLayout: ScenesLayoutType,
  options?: { logErrors?: boolean }
) => {
  if (!scenesLayout) {
    return undefined;
  }
  const layoutWithUniqueIds = assignUniqueIdsInScenesLayout(
    scenesLayout,
    options
  );
  const scenesNeighborGrid = getScenesNeighborGrid(layoutWithUniqueIds);
  const scenes = getScenesFromScenesGrid(scenesNeighborGrid);

  return {
    neighborGrid: scenesNeighborGrid,
    scenes,
  };
};

//
// GET SCENES LAYOUTS LINKS BY SCENE ID
//
export const getSceneIdsInScenesLayout = (scenesLayout: ScenesLayoutType) => {
  return scenesLayout?.neighborGrid
    ?.map((row) => row.map((scene) => scene?.uniqueId))
    .flat();
};

//
// GET SCENES LAYOUTS LINKS BY SCENE ID
//
export const getSceneLinksByScenesLayoutId: (options: {
  scenesLayoutId: string;
  scenesLayouts: ScenesLayoutType[];
  scenesLayoutsDef: ScenesLayoutsDefType;
}) => ScenesLayoutLinkType[] = ({
  scenesLayoutId,
  scenesLayouts,
  scenesLayoutsDef,
}) => {
  const sceneIds = getSceneIdsInScenesLayout(
    scenesLayouts.find((scenesLayout) => scenesLayout.id === scenesLayoutId)
  );
  const sceneLinks: ScenesLayoutLinkType[] = [];
  const sceneLinksStringified: string[] = [];

  sceneIds?.forEach((id) => {
    const linksWithScene = getSceneLinksIncludingSceneId({
      id,
      scenesLayoutsDef,
    });
    linksWithScene?.forEach((link) => {
      const linkStringified = JSON.stringify(link);
      if (!sceneLinksStringified.includes(linkStringified)) {
        sceneLinks.push(link);
        sceneLinksStringified.push(linkStringified);
      }
    });
  });

  return sceneLinks;
};

//
// GET SCENES LAYOUTS LINKS BY SCENE ID
//
export const getScenesLayoutBySceneId: (options: {
  sceneId: string;
  scenesLayouts: ScenesLayoutType[];
}) => ScenesLayoutType = ({ sceneId, scenesLayouts }) => {
  for (const scenesLayout of scenesLayouts) {
    const sceneIds = getSceneIdsInScenesLayout(scenesLayout);
    if (sceneIds.includes(sceneId)) {
      return scenesLayout;
    }
  }

  return undefined;
};

//
// GET SCENES LAYOUTS LINKS BY SCENE ID
//
export const getSceneLinksIncludingSceneId: (options: {
  id: string;
  scenesLayoutsDef: ScenesLayoutsDefType;
}) => ScenesLayoutLinkType[] = ({ id, scenesLayoutsDef }) => {
  return scenesLayoutsDef.sceneLinks.filter(
    (sceneLink) =>
      sceneLink?.scene1?.uniqueId === id || sceneLink?.scene2?.uniqueId === id
  );
};

//
// GET SCENES LAYOUTS LINKS BY SCENE ID
//
export const findLinkBetweenSceneIds: (options: {
  sceneId1: string;
  sceneId2: string;
  sceneLinks: ScenesLayoutLinkType[];
}) => ScenesLayoutLinkType = ({ sceneId1, sceneId2, sceneLinks }) => {
  const sceneLink = sceneLinks.find((sceneLink) => {
    if (sceneLink) {
      const sceneIds = [sceneLink.scene1.uniqueId, sceneLink.scene2.uniqueId];
      return sceneIds.includes(sceneId1) && sceneIds.includes(sceneId2);
    }

    return false;
  });

  return sceneLink;
};

//
// GET SCENES LAYOUTS LINKS BY SCENE ID
//
export const getSceneLinksWithoutSceneId: (options: {
  id: string;
  scenesLayoutsDef: ScenesLayoutsDefType;
}) => ScenesLayoutLinkType[] = ({ id, scenesLayoutsDef }) => {
  return scenesLayoutsDef.sceneLinks.filter(
    (sceneLink) =>
      sceneLink?.scene1?.uniqueId !== id && sceneLink?.scene2?.uniqueId !== id
  );
};

//
// GET SCENES LAYOUTS LINK FOR SCENE ID
//
export const getSceneNeighborsFromSceneLinks: (props: {
  sceneId: string;
  sceneLinks: ScenesLayoutLinkType[];
}) => { neighborId: string; direction: Direction }[] = ({
  sceneId,
  sceneLinks,
}) => {
  const neighbors: { neighborId: string; direction: Direction }[] = [];

  sceneLinks.forEach((sceneLink) => {
    if (sceneLink.scene1.uniqueId === sceneId) {
      neighbors.push({
        neighborId: sceneLink.scene2.uniqueId,
        direction: sceneLink.scene1.direction,
      });
    }
    if (sceneLink.scene2.uniqueId === sceneId) {
      neighbors.push({
        neighborId: sceneLink.scene1.uniqueId,
        direction: sceneLink.scene2.direction,
      });
    }
  });

  return neighbors;
};

//
// FUNC THAT ENCAPSULATES THE ENTIRE PROCESS OF GETTING THE FINAL GAME SCENES FROM LAYOUT DEFINITIONS
//
export const getGameScenesData: (
  scenesLayoutsDef: ScenesLayoutsDefType,
  options?: { logErrors?: boolean }
) => GameScenesDataType = (scenesLayoutsDef, options) => {
  let allScenes: SceneType[] = [];
  const scenesLayouts: ScenesLayoutType[] = [];

  Object.values(scenesLayoutsDef.scenesLayouts).forEach((scenesLayout) => {
    const { neighborGrid, scenes } = processScenesLayout(scenesLayout, options);
    allScenes.push(...scenes);
    scenesLayouts.push({ ...scenesLayout, neighborGrid });
  });

  const sceneLinks = scenesLayoutsDef.sceneLinks;

  if (sceneLinks?.length) {
    // Link scenes between separate scenes layouts
    allScenes = allScenes.map((scene) => {
      const linkNeighbors = getSceneNeighborsFromSceneLinks({
        sceneId: scene.uniqueId,
        sceneLinks,
      });
      if (linkNeighbors.length) {
        const updatedScene = {
          ...scene,
          sceneNeighbors: scene.sceneNeighbors || {},
        };
        linkNeighbors.forEach((link) => {
          updatedScene.sceneNeighbors[`sceneId_${link.direction}`] =
            link.neighborId;
        });
        return updatedScene;
      }
      return scene;
    });
  }

  Object.values(SCENE_CONFIGS_DEV).forEach((devScene) =>
    allScenes.push(devScene)
  );

  return {
    scenesLayoutsDef,
    scenesLayouts,
    scenes: allScenes,
    sceneIds: allScenes?.map((s) => s.uniqueId),
  };
};
