import React, { useMemo } from "react";
import TransitionFade, { TransitionFadeAnimation } from "./types/fade";
import TransitionPosition, {
  TransitionPositionAnimation,
} from "./types/position";
import TransitionScale, { TransitionScaleAnimation } from "./types/scale";

export enum TransitionTypeEnum {
  fade = "fade",
  fadeOut = "fadeOut",
  scaleDown = "scaleDown",
}

// Memoize individual transition components
const MemoizedTransitionFade = React.memo(TransitionFade);
const MemoizedTransitionPosition = React.memo(TransitionPosition);
const MemoizedTransitionScale = React.memo(TransitionScale);

const Transition = (props: {
  children?: React.ReactNode;
  fade?: TransitionFadeAnimation;
  scale?: TransitionScaleAnimation;
  position?: TransitionPositionAnimation;
}) => {
  // Memoize the position content
  const contentWithPosition = useMemo(() => {
    return props.position ? (
      <MemoizedTransitionPosition animatedPosition={props.position}>
        {props.children}
      </MemoizedTransitionPosition>
    ) : (
      props.children
    );
  }, [props.position, props.children]);

  // Memoize the scale content
  const contentWithScale = useMemo(() => {
    return props.scale ? (
      <MemoizedTransitionScale animatedScale={props.scale}>
        {contentWithPosition}
      </MemoizedTransitionScale>
    ) : (
      contentWithPosition
    );
  }, [props.scale, contentWithPosition]);

  // Memoize the fade content
  const contentWithFade = useMemo(() => {
    return props.fade ? (
      <MemoizedTransitionFade animatedOpacity={props.fade}>
        {contentWithScale}
      </MemoizedTransitionFade>
    ) : (
      contentWithScale
    );
  }, [props.fade, contentWithScale]);

  return <>{contentWithFade}</>;
};

export default React.memo(Transition);
