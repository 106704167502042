import { Position, SceneBackgroundType, SceneType } from "game-engine/types";
import { useRef, useState } from "react";

import GAME_CONFIG from "game-files/gameConfig";
import { Group } from "react-konva";
import Image from "game-engine/components/basic-elements/Image";
import Konva from "konva";
import SceneFill from "game-engine/components/game-elements/SceneFill";
import useGame from "game-engine/hooks/useGame";
import useKonvaImageData from "game-engine/hooks/useKonvaImageData";

const useDepthMap = (props: {
  scene: SceneType;
  sceneBackground: SceneBackgroundType;
  onDepthMapImageLoaded: () => void;
}) => {
  const { engineConfig } = useGame();

  const [imageLoaded, setImageLoaded] = useState(false);
  const onImageLoaded = () => {
    setImageLoaded(true);
    props.onDepthMapImageLoaded();
  };

  const depthMapRef = useRef<Konva.Image>();
  const depthMap = props.sceneBackground?.depthMap;
  const isIgnored = engineConfig.state.ignoreDepthMap || !depthMap?.src;

  const scaleMin =
    props.sceneBackground?.depthSettings?.scaleMin ||
    GAME_CONFIG.scene.defaultDepthSettings.scaleMin;
  const scaleMax =
    props.sceneBackground?.depthSettings?.scaleMax ||
    GAME_CONFIG.scene.defaultDepthSettings.scaleMax;

  const scaleRange = scaleMax - scaleMin;

  const { getPixelValue } = useKonvaImageData({
    ref: depthMapRef,
    imageLoaded,
  });

  //
  // UTILS
  //
  const getPositionLimitedToScene = (position: Position) => {
    const x =
      position.x < 0
        ? 0
        : position.x > GAME_CONFIG.scene.dimensions.x
        ? GAME_CONFIG.scene.dimensions.x
        : position.x;

    const y =
      position.y < 0
        ? 0
        : position.y > GAME_CONFIG.scene.dimensions.y
        ? GAME_CONFIG.scene.dimensions.y
        : position.y;

    return { x, y };
  };

  const getPixelDepth = (position: Position) => {
    let scale = 1;

    // depth map is ignored or scene does not have a depth map
    if (engineConfig.state.ignoreDepthMap || !depthMap?.src) {
      return scale;
    }

    // limit position to scene dimensions for walk paths outside the depth map
    const positionInMap = getPositionLimitedToScene(position);
    const pixelValue = getPixelValue(positionInMap);

    if (typeof pixelValue === "number") {
      scale = pixelValue / 255;
      scale = scaleMin + scale * scaleRange; // stretch the value between min and max
    }

    return scale;
  };

  //
  // RENDER
  //
  const renderDepthMap = () => (
    <Group
      listening={false}
      opacity={engineConfig.state.renderDepthMap ? 1 : 0}
    >
      <SceneFill
        color="white"
        opacity={isIgnored ? 1 : 0}
        listening={isIgnored}
      />
      <Image
        imageRef={depthMapRef}
        src={depthMap?.src}
        opacity={!isIgnored ? 1 : 0}
        listening={!isIgnored}
        onImageLoaded={onImageLoaded}
      />
    </Group>
  );

  return { renderDepthMap, getPixelDepth };
};

export default useDepthMap;
