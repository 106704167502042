export enum AudioSettingsTypeEnum {
  "music" = "music",
  "sound" = "sound",
}

export type AudioConfigType = {
  id: string;
  src: string;
  volume?: number;
};

export type MusicConfigType = AudioConfigType & {
  name?: string;
};

export type SoundConfigType = AudioConfigType & {};
