import Box from "../../basic-components/Box";
import JsonView from "../../basic-components/JsonView";
import useGame from "game-engine/hooks/useGame";

const GameState = (props: { isOpen?: boolean }) => {
  const { gameItems, gamePlay } = useGame();

  //
  // RENDER
  //
  return (
    <Box label="State" childMinWidth="80%" isOpen={props.isOpen} level={1}>
      <Box label="Game Play" level={2}>
        <JsonView json={gamePlay.state} autoExpandToLevel={1} hideRoot />
      </Box>

      <Box label="Game Items" level={2}>
        <JsonView json={gameItems.state} autoExpandToLevel={3} hideRoot />
      </Box>

      <Box label="Game Settings" level={2}>
        <JsonView
          json={gamePlay.state.settings}
          autoExpandToLevel={3}
          hideRoot
        />
      </Box>
    </Box>
  );
};

export default GameState;
