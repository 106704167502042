import "prismjs";

import PrismCode from "react-prism";
import styled from "styled-components";

const Wrapper = styled.div<{ background: string }>`
  white-space: pre-wrap;
  background: ${(props) => props.background || "#000a"};
  padding: 5px 20px;

  code[class*="language-"],
  pre[class*="language-"] {
    color: #fffd;
    font-family: "Consolas", "Bitstream Vera Sans Mono", "Courier New", Courier,
      monospace;
    direction: ltr;
    text-align: left;
    white-space: pre;
    word-spacing: normal;
    word-break: normal;
    font-size: 0.9em;
    line-height: 1.2em;

    -moz-tab-size: 4;
    -o-tab-size: 4;
    tab-size: 4;

    -webkit-hyphens: none;
    -moz-hyphens: none;
    -ms-hyphens: none;
    hyphens: none;
  }

  pre > code[class*="language-"] {
    font-size: 1em;
  }

  pre[class*="language-"]::-moz-selection,
  pre[class*="language-"] ::-moz-selection,
  code[class*="language-"]::-moz-selection,
  code[class*="language-"] ::-moz-selection {
    background: #fff1;
  }

  pre[class*="language-"]::selection,
  pre[class*="language-"] ::selection,
  code[class*="language-"]::selection,
  code[class*="language-"] ::selection {
    background: #fff1;
  }

  /* Code blocks */
  pre[class*="language-"] {
    padding: 1em;
    margin: 0.5em 0;
    overflow: auto;
    border: 1px solid #dddddd;
    background-color: white;
  }

  /* Inline code */
  :not(pre) > code[class*="language-"] {
    padding: 0.2em;
    padding-top: 1px;
    padding-bottom: 1px;
  }

  .token.comment,
  .token.prolog,
  .token.doctype,
  .token.cdata {
    color: #95ff95aa;
    font-style: italic;
  }

  .token.namespace {
    opacity: 0.7;
  }

  .token.string {
    color: #67c2c7;
  }

  .token.punctuation,
  .token.operator {
    color: #fff6 !important; /* no highlight */
  }

  .token.url,
  .token.symbol,
  .token.number,
  .token.boolean,
  .token.variable,
  .token.constant,
  .token.inserted {
    color: #36acaa;
  }

  .token.atrule,
  .token.keyword,
  .token.attr-value,
  .language-autohotkey .token.selector,
  .language-json .token.boolean,
  .language-json .token.number,
  code[class*="language-css"] {
    color: #fff6;
  }

  .token.function {
    color: #ff7bab;
  }

  .token.deleted,
  .language-autohotkey .token.tag {
    color: #9a050f;
  }

  .token.selector,
  .language-autohotkey .token.keyword {
    color: #00009f;
  }

  .token.important,
  .token.bold {
    font-weight: bold;
  }

  .token.italic {
    font-style: italic;
  }

  .token.class-name,
  .language-json .token.property {
    color: #67c2c7;
  }

  .token.tag,
  .token.selector {
    color: #67c2c7;
  }

  .token.attr-name,
  .token.property,
  .token.regex,
  .token.entity {
    color: #91adbd;
  }

  .token.directive.tag .tag {
    background: #ffff00;
    color: #393a34;
  }

  /* overrides color-values for the Line Numbers plugin
   * http://prismjs.com/plugins/line-numbers/
   */
  .line-numbers .line-numbers-rows {
    border-right-color: #a5a5a5;
  }

  .line-numbers-rows > span:before {
    color: #2b91af;
  }

  /* overrides color-values for the Line Highlight plugin
  * http://prismjs.com/plugins/line-highlight/
  */
  .line-highlight {
    background: rgba(193, 222, 241, 0.2);
    background: -webkit-linear-gradient(
      left,
      rgba(193, 222, 241, 0.2) 70%,
      rgba(221, 222, 241, 0)
    );
    background: linear-gradient(
      to right,
      rgba(193, 222, 241, 0.2) 70%,
      rgba(221, 222, 241, 0)
    );
  }
`;

const Code = (props: { code: string; background?: string }) => {
  //
  // RENDER
  //
  return (
    <Wrapper background={props.background}>
      {/* {props.code} */}

      <PrismCode className="language-javascript">{props.code}</PrismCode>
    </Wrapper>
  );
};

export default Code;
