import { MENU_IDS, MenuProps } from "..";

import MenuDialogConfirm from "../menu-templates/MenuDialogConfirm";
import useGame from "game-engine/hooks/useGame";

const SavedGamesImportDialog = (props: MenuProps) => {
  const { closeSubMenu, replaceMenu } = props;
  const { gameFns } = useGame();

  const onSubmit = async () => {
    const success = await gameFns.uploadSavedData();
    if (success) {
      closeSubMenu();
    } else {
      replaceMenu({ menuId: MENU_IDS.savedGamesImportError });
    }
  };

  //
  // RENDER
  //
  return (
    <MenuDialogConfirm
      {...props}
      title={{
        en: "Importing saved games from a file will DELETE all your current saved games!",
        cz: "Nahrání uložených pozic ze souboru VYMAŽE vaše aktuálně uložené hry!",
      }}
      titlePaddingX={10}
      titleIsWarning
      onSubmit={onSubmit}
      submitLabel={{ en: "Import from file", cz: "Nahrát ze souboru" }}
      submitWidth={140}
    />
  );
};

export default SavedGamesImportDialog;
