/* eslint-disable react-hooks/exhaustive-deps */

import {
  CharacterDevToolsPreviewType,
  SceneCharacterDataType,
  SceneCharacterType,
} from "game-engine/types";

import { CHARACTER_DEVTOOLS_PREVIEWS } from "game-files/characters/CHARACTER_CONFIGS";
import CharacterBox from "./CharacterBox";
import { GameObjective } from "game-files/gameObjectives";
import NoDataLabel from "../NoDataLabel";
import { getCharacterRenderByCurrentObjective } from "game-engine/utils/get-by-current-objective";
import { getSceneCharacterDataByCurrentObjective } from "game-engine/utils";
import useGame from "game-engine/hooks/useGame";
import { useMemo } from "react";

const CharacterList = (props: {
  isInSceneInfo?: boolean;
  sceneCharactersInfo?: {
    sceneCharacter: SceneCharacterType;
    sceneCharacterData: SceneCharacterDataType;
  }[];
  thumbnailSettings?: {
    width: number;
    height: number;
  };
  previewSettings?: {
    width: number;
    height: number;
    scale: number;
  };
}) => {
  const {
    sceneCharactersInfo,
    thumbnailSettings,
    previewSettings,
    isInSceneInfo,
  } = props;

  const { gameFns } = useGame();

  const characterPreviews = useMemo<CharacterDevToolsPreviewType[]>(() => {
    if (!isInSceneInfo) {
      return CHARACTER_DEVTOOLS_PREVIEWS;
    }
    const previews: CharacterDevToolsPreviewType[] = sceneCharactersInfo.map(
      (sceneCharacterInfo) => {
        const sceneCharByCurrentObjective =
          getSceneCharacterDataByCurrentObjective({
            config: sceneCharacterInfo.sceneCharacter.config,
            dataByCurrentObjective:
              sceneCharacterInfo.sceneCharacter.dataByCurrentObjective,
            currentObjective: gameFns.getCurrentObjective(),
            events: gameFns.getEvents(),
          });

        const renderByCurrObjective = getCharacterRenderByCurrentObjective({
          dataByCurrentObjective: sceneCharByCurrentObjective.config.render,
          currentObjective: gameFns.getCurrentObjective(),
          events: gameFns.getEvents(),
        });

        const animationIds = {};
        renderByCurrObjective.animations?.forEach(
          (a) => (animationIds[a.animationId] = a.animationId)
        );

        return {
          config: sceneCharacterInfo.sceneCharacter.config,
          sceneCharacter: sceneCharByCurrentObjective,
          renderAssets: [
            {
              name: `${GameObjective[gameFns.getCurrentObjective()]}`,
              render: renderByCurrObjective,
              animationIds,
            },
          ],
        };
      }
    );

    return previews;
  }, [sceneCharactersInfo]);

  //
  // RENDER
  //
  return (
    <>
      {!characterPreviews.length ? (
        <NoDataLabel>no characters</NoDataLabel>
      ) : (
        characterPreviews.map((characterDevPreview) => (
          <CharacterBox
            key={characterDevPreview.config.id}
            characterDevPreview={characterDevPreview}
            thumbnailSettings={thumbnailSettings}
            previewSettings={previewSettings}
          />
        ))
      )}
    </>
  );
};

export default CharacterList;
