import { GameDisplayModeEnum, Language } from "game-engine/types";

import Box from "game-engine/_dev/basic-components/Box";
import Checkbox from "game-engine/_dev/basic-components/inputs/Checkbox";
import Divider from "game-engine/_dev/basic-components/Divider";
import GAME_CONFIG from "game-files/gameConfig";
import useGame from "game-engine/hooks/useGame";

const SectionSettings = (props: { isOpen?: boolean }) => {
  const { gameFns, gamePlay } = useGame();

  //
  // RENDER
  //
  return (
    <Box
      label="Game Settings"
      isOpen={props.isOpen}
      level={2}
      useFlex
      gap="0px"
      childMinWidth="90%"
      contentPaddingBottom="20px"
    >
      <Divider name="Language:" marginTop="0px" />

      <Box renderContentOnly label="Language" level={2} isOpen>
        {Object.keys(Language).map((key) => {
          const isActive = key === gamePlay.state.settings.language;
          return (
            <Checkbox
              key={key}
              label={key}
              value={isActive}
              onChange={(v) =>
                gameFns.setSettings({
                  language: key as Language,
                })
              }
            />
          );
        })}
      </Box>

      <Divider name="Walk Speed:" />

      <Box renderContentOnly label="Walk Speed" level={2} isOpen>
        {["slow", "fast"].map((key) => {
          const value = GAME_CONFIG.actions.walkSpeed[key];
          const isActive = value === gamePlay.state.settings.walkSpeed;
          return (
            <Checkbox
              key={key}
              label={key}
              value={isActive}
              onChange={(v) =>
                gameFns.setSettings({
                  walkSpeed: value,
                })
              }
            />
          );
        })}
      </Box>

      <Divider name="Talk Speed:" />

      <Box renderContentOnly label="Talk Speed" level={2} isOpen>
        {["slow", "medium", "fast"].map((key) => {
          const value = GAME_CONFIG.actions.talkSpeed[key];
          const isActive = value === gamePlay.state.settings.talkSpeed;
          return (
            <Checkbox
              key={key}
              label={key}
              value={isActive}
              onChange={(v) =>
                gameFns.setSettings({
                  talkSpeed: value,
                })
              }
            />
          );
        })}
      </Box>

      <Divider name="Dimensions:" />

      <Box renderContentOnly label="Dimensions" level={2} isOpen>
        {Object.values(GameDisplayModeEnum).map((key) => {
          const value = key;
          const isActive = value === gamePlay.state.settings.displayMode;
          return (
            <Checkbox
              key={key}
              label={key}
              value={isActive}
              onChange={(v) =>
                gameFns.setSettings({
                  displayMode: value,
                })
              }
            />
          );
        })}
      </Box>

      <Divider name="Audio:" />

      <Box
        renderContentOnly
        label="Dimensions"
        level={2}
        isOpen
        childMinWidth="90%"
      >
        <Checkbox
          label="Play Music"
          value={gamePlay.state.settings.playMusic}
          onChange={(v) =>
            gameFns.setSettings({
              playMusic: v,
            })
          }
        />
        <Checkbox
          label="Play Sounds"
          value={gamePlay.state.settings.playSounds}
          onChange={(v) =>
            gameFns.setSettings({
              playSounds: v,
            })
          }
        />
      </Box>
    </Box>
  );
};

export default SectionSettings;
