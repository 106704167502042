import MenuDialogConfirm from "../menu-templates/MenuDialogConfirm";
import { MenuProps } from "..";
import useGame from "game-engine/hooks/useGame";

const CustomPopUp = (props: MenuProps) => {
  const { closeMenu, closeSubMenu } = props;

  const { gamePlay, gameFns } = useGame();

  const onClose = () => {
    gameFns.setCustomPopUp(undefined);
  };

  //
  // RENDER
  //
  return (
    <MenuDialogConfirm
      {...props}
      width={270}
      height={gamePlay.state.customPopUp?.height ?? 62}
      title={gamePlay.state.customPopUp?.title}
      titlePaddingX={10}
      closeMenu={() => {
        closeMenu?.();
        onClose();
      }}
      closeSubMenu={() => {
        closeSubMenu?.();
        onClose();
      }}
      submitHidden
      cancelLabel={
        gamePlay.state?.customPopUp?.buttonClose ?? {
          en: "Close",
          cz: "Zavřít",
        }
      }
    />
  );
};

export default CustomPopUp;
