import { ActionsByCurrentObjectiveType, ItemType } from "game-engine/types";

import { GameObjective } from "game-files/gameObjectives";

//
// GET ACTIONS BY CURRENT OBJECTIVE
//
export const getActionsByCurrentObjective = (props: {
  actionsByCurrentObjective: ActionsByCurrentObjectiveType;
  currentObjective: GameObjective;
  itemInCursor?: ItemType;
}) => {
  const { actionsByCurrentObjective, currentObjective, itemInCursor } = props;

  if (actionsByCurrentObjective) {
    const itemConfigId = itemInCursor?.configId;

    // FIND ACTIONS WITH ITEM
    if (itemConfigId) {
      for (let obj = currentObjective; obj >= 0; obj--) {
        // 01 - check if actions for specific item are defined
        const actionsByItemConfigId =
          actionsByCurrentObjective[obj]?.actionsWithItem?.[itemConfigId];

        if (actionsByItemConfigId) {
          return actionsByItemConfigId;
        }

        // 02 - check if there are default item actions
        const defaultItemActions =
          actionsByCurrentObjective[obj]?.actionsWithItem?.default;

        if (defaultItemActions) {
          return defaultItemActions;
        }
      }
    }

    // FIND ACTIONS WITHOUT ITEM
    else {
      for (let obj = currentObjective; obj >= 0; obj--) {
        const currentActions = actionsByCurrentObjective[obj]?.actions;
        if (currentActions) {
          return currentActions;
        }
      }
    }
  }

  return [];
};
