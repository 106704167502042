/* eslint-disable react-hooks/exhaustive-deps */

import { SceneCharacterDataType, SceneType } from "game-engine/types";
import { useEffect, useMemo, useState } from "react";

import { CharacterId } from "game-files/common/ids";
import { getSceneCharacterDataByCurrentObjective } from "game-engine/utils";
import useGame from "game-engine/hooks/useGame";

const useSceneCharacters = (props: { scene: SceneType }) => {
  const { scene } = props;
  const { gameFns, gamePlay } = useGame();

  const [sceneCharacters, setSceneCharacters] = useState<
    SceneCharacterDataType[]
  >([]);

  const sceneCharactersData = useMemo<SceneCharacterDataType[]>(() => {
    let data = scene?.characters?.map((character) =>
      getSceneCharacterDataByCurrentObjective({
        config: character.config,
        dataByCurrentObjective: character.dataByCurrentObjective,
        currentObjective: gameFns.getCurrentObjective(),
        events: gameFns.getEvents(),
      })
    );

    // After getting new scene characters data, some params need to be
    // propagated from previous data -> e.g. if animation was supposed
    // to run, it needs to be copied into the new data (with renderMode)
    const paramsToPropagate: {
      [configId: string]: Partial<SceneCharacterDataType>;
    } = {};

    sceneCharacters?.forEach((sceneCharacter) => {
      if (sceneCharacter.playAnimation) {
        const {
          dialog,
          facing,
          onClickOverride,
          playAnimation,
          position,
          renderMode,
          talkRenderOptions,
        } = sceneCharacter;
        const params: Partial<SceneCharacterDataType> = {
          dialog,
          facing,
          onClickOverride,
          playAnimation,
          position,
          renderMode,
          talkRenderOptions,
        };
        paramsToPropagate[sceneCharacter.config.id] = params;
      }
    });

    if (Object.keys(sceneCharacters)?.length) {
      data = data.map((sceneCharacter) => {
        const params = paramsToPropagate[sceneCharacter.config.id];
        if (params) {
          return { ...sceneCharacter, ...params };
        }
        return sceneCharacter;
      });
    }

    return data || [];
  }, [scene, gamePlay.state.currentObjective, gamePlay.state.events]);

  //
  // STATE - CHARACTERS
  //
  useEffect(() => {
    // if characters data changes, override scene characters
    // this is intentional, if the character should change position, for example:
    // 1) trigger ACTION.walk to animate character position to target position
    // 2) trigger ACTION.setNextObjective / ACTION.setEvents to switch to new state
    // 3) state the target position in scene character config based on newly updated state
    // 4) -> this way, the position is remembered, but there's also the walk animation in between the changes
    setSceneCharacters(sceneCharactersData);
  }, [sceneCharactersData]);

  const setSceneCharacter = (updatedCharacter: SceneCharacterDataType) => {
    const updatedCharacters = sceneCharacters.map((c) =>
      c.config.id === updatedCharacter.config.id ? updatedCharacter : c
    );
    setSceneCharacters(updatedCharacters);
  };

  const getSceneCharacter: (
    characterId: CharacterId
  ) => SceneCharacterDataType = (characterId) => {
    return sceneCharacters.find((c) => c.config.id === characterId);
  };

  //
  // RETURN
  //
  return {
    sceneCharacters,
    setSceneCharacters,
    getSceneCharacter,
    setSceneCharacter,
  };
};

export default useSceneCharacters;
