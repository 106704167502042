export const playTone = (props: {
  frequency: number;
  duration?: number;
  volume?: number;
}) => {
  const { frequency, duration = 200, volume = 0.5 } = props;

  // Create a new AudioContext
  const audioCtx = new (window.AudioContext ||
    (window as any).webkitAudioContext)();

  // Create an oscillator (which generates the tone)
  const oscillator = audioCtx.createOscillator();

  // Create a gain node to control the volume
  const gainNode = audioCtx.createGain();

  // Set the frequency (in Hertz)
  oscillator.frequency.value = frequency;

  // Set the waveform type (sine, square, triangle, sawtooth)
  oscillator.type = "sine";

  // Connect the oscillator to the gain node
  oscillator.connect(gainNode);

  // Connect the gain node to the audio context's destination (speakers)
  gainNode.connect(audioCtx.destination);

  // Set the gain/volume (0 to 1)
  gainNode.gain.value = volume;

  // Start playing the tone immediately
  oscillator.start();

  // Stop playing the tone after the specified duration (in milliseconds)
  setTimeout(() => {
    oscillator.stop();
    audioCtx.close(); // Close the audio context to free up resources
  }, duration);
};
