/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from "react";

import { Group } from "react-konva";

export type TransitionFadeAnimation = {
  from: number;
  to?: number;
  durationSec?: number;
  onEnd?: () => void;
};

const TransitionFade = (props: {
  children?: any;
  animatedOpacity: TransitionFadeAnimation;
}) => {
  const { animatedOpacity } = props;

  const [opacity, setOpacity] = useState(animatedOpacity.from);

  useEffect(() => {
    if (
      animatedOpacity.from === animatedOpacity.to ||
      animatedOpacity.to === undefined
    ) {
      return setOpacity(animatedOpacity.from);
    }

    if (!animatedOpacity.durationSec) {
      return setOpacity(animatedOpacity.to ?? animatedOpacity.from);
    }

    animateOpacity(
      animatedOpacity.from,
      animatedOpacity.to,
      animatedOpacity.durationSec
    );
  }, [animatedOpacity]);

  //
  // ANIMATED OPACITY
  //
  const animateOpacity = (start: number, end: number, durationSec: number) => {
    const startTime = performance.now();
    const frameDuration = durationSec * 1000;

    const step = (currentTime: number) => {
      const elapsed = currentTime - startTime;
      const progress = Math.min(elapsed / frameDuration, 1);

      // Ensure opacity is always a valid number and between 0 and 1
      const newOpacity = Math.max(
        0,
        Math.min(1, start + progress * (end - start))
      );

      setOpacity(newOpacity);

      if (progress < 1) {
        requestAnimationFrame(step);
      }
    };

    // Only start the animation if duration is greater than 0
    if (durationSec > 0) {
      requestAnimationFrame(step);
    } else {
      setOpacity(end);
    }
  };

  useEffect(() => {
    if (opacity === animatedOpacity.to && animatedOpacity.onEnd) {
      animatedOpacity.onEnd();
    }
  }, [opacity]);

  //
  // RENDER
  //
  return (
    <Group listening={opacity !== 0} opacity={opacity}>
      {props.children}
    </Group>
  );
};

export default TransitionFade;
