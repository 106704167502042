import { ACTION } from "game-engine/utils";
import GAME_CONFIG from "game-files/gameConfig";
import { GameObjective } from "game-files/gameObjectives";
import { SkillId } from "game-files/common/ids";
import { SkillType } from "game-engine/types";

const Invisibility: SkillType = {
  id: SkillId.Invisibility,

  name: { en: "Scroll of Invisibility", cz: "Svitek neviditelnosti" },

  guiSkillSlot: undefined, // TODO

  states: {
    [GameObjective.GAME_START]: {
      state_start: {
        getActions: ({ gamePlay, gameItems }) => [
          // TODO - ADD ACTION.characterAnimation FOR READING A SKILL SCROLL (do this individually for each skill to have the option to have something different than a scroll in the gui - e.g. a wand (or a differently looking scroll))

          ACTION.talk({
            dialog: { en: "invisibility started", cz: "Neviditelnost začíná" },
          }),
        ],
      },

      state_active: {
        duration: {
          sceneChangeCount: 1, // stop after each scene change
          durationSec: undefined, // keep until scene changes
        },
      },

      state_stop: {
        getActions: ({ gamePlay, gameItems }) => [
          ACTION.talk({
            dialog: { en: "invisibility stopped", cz: "Neviditelnost končí" },
          }),
        ],
      },

      state_waitForReset: {
        duration: GAME_CONFIG.skills.resetAfter,
      },
    },
  },
};

export default Invisibility;
