/* eslint-disable react-hooks/exhaustive-deps */

import {
  DynamicLightingLightSource,
  DynamicLightingType,
  ImageFilters,
  SceneCharacterDataType,
  SceneType,
} from "game-engine/types";
import { useCallback, useEffect, useMemo, useState } from "react";

import GAME_CONFIG from "game-files/gameConfig";
import SKILL_CONFIGS from "game-files/skills/SKILL_CONFIGS";
import SceneDynamicLightOverlay from "../../SceneDynamicLightOverlay";
import { getDynamicLightingByCurrentObjective } from "game-engine/utils/get-by-current-objective/get-scene-dynamic-lighting";
import useGame from "game-engine/hooks/useGame";

export type GetDynamicLightingFiltersFunc = (params?: {
  depthY?: number;
}) => ImageFilters;

const useDynamicLighting = (props: {
  scene: SceneType;
  mainCharacter: SceneCharacterDataType;
}) => {
  const { scene, mainCharacter } = props;

  const { gameFns, gamePlay, gameItems } = useGame();

  const { dynamicLighting, isRegularScene } = useMemo<{
    dynamicLighting: DynamicLightingType;
    isRegularScene: boolean;
  }>(() => {
    let isRegularScene = false;

    // Check dynamicLighting definition
    let dynamicLighting = getDynamicLightingByCurrentObjective({
      dataByCurrentObjective: scene?.dynamicLighting,
      currentObjective: gameFns.getCurrentObjective(),
      events: gameFns.getEvents(),
    });

    // Get dynamicLighting for active celestial flame (lights-up even regular scenes)
    if (
      !dynamicLighting &&
      gamePlay.state.currentSkills?.CelestialFlame?.isActive
    ) {
      isRegularScene = true;
      dynamicLighting = {
        filters:
          SKILL_CONFIGS.CelestialFlame.dynamicLighting.regularScenes
            .sceneFilters,
      };
    }

    // Return dynamicLighting
    return { dynamicLighting, isRegularScene };
  }, [
    scene,
    gamePlay.state.currentObjective,
    gamePlay.state.events,
    gamePlay.state.currentSkills?.CelestialFlame?.isActive,
  ]);

  //
  // LIGHT SOURCE REFRESH LISTENER
  //
  const [runLightSourceUpdate, setRunLightSourceUpdate] = useState(0);

  useEffect(() => {
    if (
      dynamicLighting ||
      gamePlay.state.currentSkills?.CelestialFlame?.isActive
    ) {
      setRunLightSourceUpdate((prevState) => prevState + 1);
    }
  }, [
    dynamicLighting,
    scene?.uniqueId,
    gameItems.state,
    gamePlay.state.currentSkills?.CelestialFlame?.isActive,
  ]);

  useEffect(() => {
    // Separate useEffect to watch mainCharacter position in
    // order to only update lightSource re-render when needed
    // (character position is relevant only for skills)
    if (
      dynamicLighting &&
      gamePlay.state.currentSkills?.CelestialFlame?.isActive
    ) {
      setRunLightSourceUpdate((prevState) => prevState + 1);
    }
  }, [
    dynamicLighting,
    mainCharacter?.position,
    gamePlay.state.currentSkills?.CelestialFlame?.isActive,
  ]);

  //
  // LIGHT SOURCE
  //
  const lightSource = useMemo<DynamicLightingLightSource>(() => {
    const sceneLightSource = gameFns.getSceneLightSource({
      uniqueSceneId: scene.uniqueId,
      dynamicLighting,
      isRegularScene,
    });

    if (!sceneLightSource) {
      return undefined;
    }

    let lightSourcePosition = sceneLightSource?.position;

    if (
      gamePlay.state.currentSkills?.CelestialFlame?.isActive &&
      mainCharacter?.position
    ) {
      lightSourcePosition = mainCharacter?.position;
    }

    if (!lightSourcePosition) {
      lightSourcePosition = {
        x: 0,
        y: GAME_CONFIG.scene.dimensions.y - 1, // -1 ensures that foreground is always in shadows
      };
    }

    return { ...sceneLightSource, position: lightSourcePosition };
  }, [runLightSourceUpdate]);

  //
  // GET DYNAMIC LIGHTING FILTERS
  //
  const getDynamicLightingFilters: GetDynamicLightingFiltersFunc = useCallback(
    (params: { depthY?: number }) => {
      if (!dynamicLighting) {
        return undefined;
      }

      // filters or lightSource are missing (fallback, this should not happen - death by darkness)
      if (!dynamicLighting?.filters || !lightSource) {
        const darknessFilters: ImageFilters = {
          tint: {
            color: "#3a3d49",
            amount: 0.8,
          },
          saturation: 0.6,
        };
        return darknessFilters;
      }

      let filters: ImageFilters = {
        tint: lightSource.tint,
      };

      // layer is scene foreground -> this should always be in shadows (usually darker)
      if (params.depthY && params.depthY >= GAME_CONFIG.scene.dimensions.y) {
        return {
          ...dynamicLighting.filters.shadowForeground,
          ...filters,
        };
      }

      // layer is inside the scene and is in shadows
      if (params.depthY && params.depthY > lightSource?.position.y) {
        return { ...dynamicLighting.filters.shadowLayers, ...filters };
      }

      // otherwise layer is lit
      return { ...dynamicLighting.filters.light, ...filters };
    },
    [lightSource]
  );

  const renderDynamicLightingOverlay = useCallback(() => {
    return dynamicLighting && lightSource ? (
      <SceneDynamicLightOverlay
        dynamicLighting={dynamicLighting}
        lightSource={lightSource}
      />
    ) : null;
  }, [lightSource, dynamicLighting]);

  //
  // RETURN HOOK DATA / RENDER
  //
  return {
    getDynamicLightingFilters,
    renderDynamicLightingOverlay,
  };
};

export default useDynamicLighting;
