import { FrameSequenceValue, SpriteConfigType } from "game-engine/types";

import { capitalizeFirstLetter } from "../by-types";
import { prettifyCode } from "./code-formatting";

//
// SPRITE SETTINGS
//
export const getCode_SpriteConfig = (
  spriteConfig: SpriteConfigType,
  options?: { onlyValidValues?: boolean }
) => {
  if (!spriteConfig) {
    return "undefined";
  }

  const codeLines = ["{"];

  if (spriteConfig.direction || !options?.onlyValidValues) {
    codeLines.push(
      `direction: SpriteDirection.${capitalizeFirstLetter(
        spriteConfig.direction
      )},`
    );
  }

  if (spriteConfig.frameWidth || !options?.onlyValidValues) {
    codeLines.push(`frameWidth: ${spriteConfig.frameWidth},`);
  }

  if (spriteConfig.frameHeight || !options?.onlyValidValues) {
    codeLines.push(`frameHeight: ${spriteConfig.frameHeight},`);
  }

  if (spriteConfig.frameCount || !options?.onlyValidValues) {
    codeLines.push(`frameCount: ${spriteConfig.frameCount},`);
  }

  if (spriteConfig.frameDurationMilliseconds || !options?.onlyValidValues) {
    codeLines.push(
      `frameDurationMilliseconds: ${spriteConfig.frameDurationMilliseconds},`
    );
  }

  let frameSequenceAdded = false;

  if (
    spriteConfig.frameSequence?.length > 1 ||
    (spriteConfig.frameSequence?.length === 1 &&
      spriteConfig.frameSequence[0] !== FrameSequenceValue.original)
  ) {
    codeLines.push(
      `frameSequence: [
      ${spriteConfig?.frameSequence
        ?.map((frameSequenceItem) => {
          if (typeof frameSequenceItem === "number") {
            return `${frameSequenceItem}`;
          }
          if (typeof (frameSequenceItem as any).pause === "number") {
            return `{pause: ${(frameSequenceItem as any).pause}}`;
          }
          if (
            typeof (frameSequenceItem as any).from === "number" &&
            typeof (frameSequenceItem as any).to === "number"
          ) {
            return `{from: ${(frameSequenceItem as any).from}, to: ${
              (frameSequenceItem as any).to
            }}`;
          }

          return `FrameSequenceValue.${frameSequenceItem}`;
        })
        .join(",\n")}
        ],`
    );
    frameSequenceAdded = true;
  }
  if (!frameSequenceAdded && !options?.onlyValidValues) {
    codeLines.push(`frameSequence: undefined`);
  }

  if (spriteConfig?.originOffset || !options?.onlyValidValues) {
    codeLines.push(
      `originOffset: {x: ${spriteConfig.originOffset?.x ?? 0}, y: ${
        spriteConfig.originOffset?.y ?? 0
      }},`
    );
  }

  codeLines.push(`playCount: ${spriteConfig?.playCount},`);

  codeLines.push("},");
  return prettifyCode(codeLines.join("\n"));
};
