//
// DEFS
//
const breakpointsDef = {
  XL: "1600px",
  L: "1200px",
  M: "800px",
  S: "600px",
  XS: "400px",
};

//
// BREAKPOINT OBJECT
//
const breakpointGen: any = {};
Object.entries(breakpointsDef).forEach((entry) => {
  const size = entry[0];
  const value = entry[1];
  breakpointGen[size] = `@media screen and (max-width: ${value})`;
});

const breakpoints: typeof breakpointGen = breakpointGen;

export default breakpoints;
