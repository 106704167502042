import { ACTION } from "game-engine/utils";
import { GameObjective } from "game-files/gameObjectives";
import ImagePreview from "./assets/preview_00.png";
import Layer_01_Background from "./assets/layer - 01 - background_00.png";
import Layer_01_Background_onClick_Forest from "./assets/layer - 01 - background - onClick - forest_00.png";
import Layer_02_Background_Trees from "./assets/layer - 02 - background - trees_00.png";
import Layer_03_NonWalkableLeft from "./assets/layer - 03 - non-walkable left_00.png";
import Layer_04_RockRight from "./assets/layer - 04 - rock right_00.png";
import Layer_05_TreeRight from "./assets/layer - 05 - tree right_00.png";
import Layer_06_TreeLeft from "./assets/layer - 06 - tree left_00.png";
import Layer_07_RocksLeft from "./assets/layer - 07 - rocks left_00.png";
import Layer_08_Front_RocksLeft from "./assets/layer - 08 - front - rocks left_00.png";
import Layer_09_Front_TreeLeft from "./assets/layer - 09 - front - tree left_00.png";
import Layer_10_Front_TreeMiddle from "./assets/layer - 10 - front - tree middle_00.png";
import Layer_11_Front_TreeRight from "./assets/layer - 11 - front - tree right_00.png";
import Layer_Map_Depth from "./assets/layer - 00 - DEPTH MAP_00.png";
import Layer_Map_Drop from "./assets/layer - 00 - DROP MAP_00.png";
import Layer_Map_Walk from "./assets/layer - 00 - WALK MAP_00.png";
import SHARED_DATA from "../../SHARED_DATA";
import { SceneType } from "game-engine/types";
import { TestScenesMusic } from "../_shared/music";

const Test_Forest_L_U_D: SceneType = {
  configId: "Test_Forest_L_U_D",
  name: {
    [GameObjective.GAME_START]: {
      en: "Ancient Forest 05",
      cz: "Prastarý les 05",
    },
  },

  preview: { src: ImagePreview },
  uniqueId: undefined, // IS GENERATED FROM SCENES LAYOUT
  sceneNeighbors: undefined, // IS GENERATED FROM SCENES LAYOUT

  sceneWalkPaths: {
    [GameObjective.GAME_START]: {
      // NOT ALL WALK-PATHS ARE NECESSARILY USED (mainly for reusable scenes)
      // -> ACCESSIBLE WALK-PATHS ARE SPECIFIED IN SCENES LAYOUT MATRIX
      left: {
        edgeWalkY: 110,
        walkTo: { x: 38, y: 117 },
      },
      up: {
        edgeWalkX: 120,
        walkTo: { x: 132, y: 45 },
      },
      down: {
        edgeWalkX: 89,
        walkTo: { x: 89, y: 138 },
      },
    },
  },

  audio: {
    [GameObjective.GAME_START]: {
      music: TestScenesMusic,
    },
  },

  images: {
    background: {
      dataByCurrentObjective: {
        [GameObjective.GAME_START]: {
          image: { src: Layer_01_Background },
          dropMap: { src: Layer_Map_Drop },
          walkMap: { src: Layer_Map_Walk },
          depthMap: { src: Layer_Map_Depth },
          depthSettings: {
            //scaleMin: 0.5,
            //scaleMax: 1,
          },
        },
      },
      actionsByCurrentObjective: undefined,
    },

    layers: [
      {
        id: "Layer_01_Background_onClick_Forest",
        dataByCurrentObjective: {
          [GameObjective.GAME_START]: {
            image: { src: Layer_01_Background_onClick_Forest },
            isInvisible: true,
          },
        },
        actionsByCurrentObjective: {
          [GameObjective.GAME_START]: {
            actions: [
              ACTION.talkRandom({
                dialogOptions: [
                  {
                    en: `I bet I can hide behind that tree.`,
                    cz: "Za ten strom se určitě můžu schovat.",
                  },
                ],
              }),
            ],
          },
        },
      },

      {
        id: "Layer_02_Background_Trees",
        dataByCurrentObjective: {
          [GameObjective.GAME_START]: {
            image: { src: Layer_02_Background_Trees },
          },
        },
        actionsByCurrentObjective: {
          [GameObjective.GAME_START]: {
            actions: [
              ACTION.talkRandom({
                dialogOptions: [
                  ...SHARED_DATA.dialogOptions.tree,
                  ...SHARED_DATA.dialogOptions.forest,
                  ...SHARED_DATA.dialogOptions.forestBackground,
                ],
              }),
            ],
          },
        },
      },

      {
        id: "Layer_03_NonWalkableLeft",
        depthY: 77,
        dataByCurrentObjective: {
          [GameObjective.GAME_START]: {
            image: { src: Layer_03_NonWalkableLeft },
          },
        },
        actionsByCurrentObjective: {
          [GameObjective.GAME_START]: {
            actions: [
              ACTION.talkRandom({
                dialogOptions: [
                  ...SHARED_DATA.dialogOptions.notGoingToWalk,
                  ...SHARED_DATA.dialogOptions.obstacleNoSceneWalkOut,
                ],
              }),
            ],
          },
        },
      },

      {
        id: "Layer_04_RockRight",
        depthY: 58,
        dataByCurrentObjective: {
          [GameObjective.GAME_START]: {
            image: { src: Layer_04_RockRight },
          },
        },
        actionsByCurrentObjective: {
          [GameObjective.GAME_START]: {
            actions: [
              ACTION.talkRandom({
                dialogOptions: [...SHARED_DATA.dialogOptions.rockLarge],
              }),
            ],
          },
        },
      },

      {
        id: "Layer_05_TreeRight",
        depthY: 74,
        dataByCurrentObjective: {
          [GameObjective.GAME_START]: {
            image: { src: Layer_05_TreeRight },
          },
        },
        actionsByCurrentObjective: {
          [GameObjective.GAME_START]: {
            actions: [
              ACTION.talkRandom({
                dialogOptions: [
                  ...SHARED_DATA.dialogOptions.treeOld,
                  {
                    en: `I wonder how many people tripped over that root.`,
                    cz: `Kolik lidí asi zakoplo o kořen toho stromu?`,
                  },
                ],
              }),
            ],
          },
        },
      },

      {
        id: "Layer_06_TreeLeft",
        depthY: 98,
        dataByCurrentObjective: {
          [GameObjective.GAME_START]: {
            image: { src: Layer_06_TreeLeft },
          },
        },
        actionsByCurrentObjective: {
          [GameObjective.GAME_START]: {
            actions: [
              ACTION.talkRandom({
                dialogOptions: [
                  ...SHARED_DATA.dialogOptions.tree,
                  ...SHARED_DATA.dialogOptions.treeOld,
                  ...SHARED_DATA.dialogOptions.forest,
                ],
              }),
            ],
          },
        },
      },

      {
        id: "Layer_07_RocksLeft",
        depthY: 126,
        dataByCurrentObjective: {
          [GameObjective.GAME_START]: {
            image: { src: Layer_07_RocksLeft },
          },
        },
        actionsByCurrentObjective: {
          [GameObjective.GAME_START]: {
            actions: [
              ACTION.talkRandom({
                dialogOptions: [
                  ...SHARED_DATA.dialogOptions.rockLarge,
                  ...SHARED_DATA.dialogOptions.obstacleNoSceneWalkOut,
                ],
              }),
            ],
          },
        },
      },

      {
        id: "Layer_08_Front_RocksLeft",
        depthY: 10000,
        dataByCurrentObjective: {
          [GameObjective.GAME_START]: {
            image: { src: Layer_08_Front_RocksLeft },
          },
        },
        actionsByCurrentObjective: {
          [GameObjective.GAME_START]: {
            actions: [
              ACTION.talkRandom({
                dialogOptions: [
                  ...SHARED_DATA.dialogOptions.notGoingToWalk,
                  ...SHARED_DATA.dialogOptions.obstacleNoSceneWalkOut,
                ],
              }),
            ],
          },
        },
      },

      {
        id: "Layer_09_Front_TreeLeft",
        depthY: 10000,
        dataByCurrentObjective: {
          [GameObjective.GAME_START]: {
            image: { src: Layer_09_Front_TreeLeft },
          },
        },
        actionsByCurrentObjective: {
          [GameObjective.GAME_START]: {
            actions: [
              ACTION.talkRandom({
                dialogOptions: [...SHARED_DATA.dialogOptions.treeYoung],
              }),
            ],
          },
        },
      },

      {
        id: "Layer_10_Front_TreeMiddle",
        depthY: 10000,
        dataByCurrentObjective: {
          [GameObjective.GAME_START]: {
            image: { src: Layer_10_Front_TreeMiddle },
          },
        },
        actionsByCurrentObjective: {
          [GameObjective.GAME_START]: {
            actions: [
              ACTION.talkRandom({
                dialogOptions: [
                  ...SHARED_DATA.dialogOptions.tree,
                  ...SHARED_DATA.dialogOptions.treeOld,
                  ...SHARED_DATA.dialogOptions.forest,
                ],
              }),
            ],
          },
        },
      },

      {
        id: "Layer_11_Front_TreeRight",
        depthY: 10000,
        dataByCurrentObjective: {
          [GameObjective.GAME_START]: {
            image: { src: Layer_11_Front_TreeRight },
          },
        },
        actionsByCurrentObjective: {
          [GameObjective.GAME_START]: {
            actions: [
              ACTION.talkRandom({
                dialogOptions: [
                  ...SHARED_DATA.dialogOptions.tree,
                  ...SHARED_DATA.dialogOptions.treeYoung,
                  ...SHARED_DATA.dialogOptions.forest,
                ],
              }),
            ],
          },
        },
      },
    ],
  },
};

export default Test_Forest_L_U_D;
