import { Group, Rect } from "react-konva";

import { Position } from "game-engine/types";

const Origin = (props: { position?: Position; color?: string }) => {
  const originSize = 1;
  const originColor = props.color || "red";

  //
  // RENDER
  //
  return (
    <Group position={props.position || { x: 0, y: 0 }}>
      {/* <Circle listening={false} radius={originSize} fill={originColor} /> */}

      <Rect
        x={Math.round(-originSize / 2)}
        y={Math.round(-originSize / 2)}
        width={originSize}
        height={originSize}
        fill={originColor}
      />
    </Group>
  );
};

export default Origin;
