import { GameAudioByCurrentObjective, GameAudioType } from "game-engine/types";

import { GameEventsType } from "game-files/gameEvents";
import { GameObjective } from "game-files/gameObjectives";
import { getDataByCurrentObjective } from "./getters";

export const getGameAudioByCurrentObjective = (props: {
  dataByCurrentObjective: GameAudioByCurrentObjective;
  currentObjective: GameObjective;
  events: GameEventsType;
}) => {
  return getDataByCurrentObjective<GameAudioType>(
    {
      ...props,
      aggregateConditionals: true, // This ensures that conditional values are added to unconditional
    },
    {}
  );
};
