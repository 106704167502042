import MenuDialogConfirm from "../menu-templates/MenuDialogConfirm";
import { MenuProps } from "..";

const NewGame = (props: MenuProps) => {
  const { onNewGame } = props;

  const onSubmit = async () => {
    onNewGame();
  };

  //
  // RENDER
  //
  return (
    <MenuDialogConfirm
      {...props}
      width={270}
      height={62}
      title={{
        en: `Do you want to start a new game?`,
        cz: `Chcete začít hrát novou hru?`,
      }}
      titlePaddingX={10}
      onSubmit={onSubmit}
      submitLabel={{ en: "New Game", cz: "Nová hra" }}
    />
  );
};

export default NewGame;
