import { TextAlign, TextVerticalAlign } from "game-engine/types";

import Canvas from "../basic-elements/Canvas";
import GAME_CONFIG from "game-files/gameConfig";
import PixelatedLayer from "../basic-elements/PixelatedLayer";
import { Rect } from "react-konva";
import Text from "../basic-elements/Text";
import { useEffect } from "react";

const GameIntro = (props: { onEnd: () => void }) => {
  const { onEnd } = props;

  const canvasX = GAME_CONFIG.window.canvasDimensions.x;
  const canvasY = GAME_CONFIG.window.canvasDimensions.y;

  //
  // TODO - TEMPORARY TIMER SWITCH, REMOVE THIS ONCE INTRO IS FINISHED AND RUN 'onEnd' AT THE END TO SWITCH TO GAMEPLAY
  //
  useEffect(() => {
    const timer = setTimeout(() => {
      onEnd();
    }, 5000);

    // Cleanup the timer in case the component unmounts before the timeout
    return () => clearTimeout(timer);
  }, [onEnd]);

  const onClick = () => {
    // TODO - SHOW A DIALOG 'SKIP INTRO?' (keep playing the intro to keep game and audio in sync, so show the dialog below scene viewport where GUI will be)
    onEnd();
  };

  //
  // RENDER
  //
  return (
    <Canvas noCursor onClick={onClick}>
      <PixelatedLayer>
        <Rect x={0} y={0} width={canvasX} height={canvasY} fill={"#0f04"} />

        <Text
          position={{ x: Math.round(canvasX / 2), y: Math.round(canvasY / 2) }}
          text={{ en: "This is a new game", cz: "Tohle je nová hra" }}
          align={TextAlign.center}
          verticalAlign={TextVerticalAlign.center}
          maxWidth={canvasX}
        />
      </PixelatedLayer>
    </Canvas>
  );
};

export default GameIntro;
