import { BoxMessage } from "game-engine/_dev/basic-components/Box";
import Divider from "../Divider";
import ItemBox from "./ItemBox";
import { ItemType } from "game-engine/types";
import NoDataLabel from "../NoDataLabel";

export type ListDividerType = { divider: string };
export type ListItemType = ItemType & { messages?: BoxMessage[] };

const ItemList = (props: {
  items: (ListItemType | ListDividerType)[];
  thumbnail?: { width?: number; height?: number; imgFillPercentage?: string };
  preview?: { width?: number; height?: number; scale?: number };
  infoInScene?: {
    noSceneId?: boolean;
    noPosition?: boolean;
  };
  isAssetPreview?: boolean;
}) => {
  const { items, preview, infoInScene, isAssetPreview } = props;

  //
  // RENDER
  //
  return (
    <>
      {!items.length ? (
        <NoDataLabel>no items</NoDataLabel>
      ) : (
        items.map((itemOrDivider, i) => {
          if (!itemOrDivider) {
            return null;
          }

          if ((itemOrDivider as ListDividerType).divider) {
            const divider = itemOrDivider as ListDividerType;
            return (
              <Divider
                key={i}
                name={divider.divider}
                marginTop="6px"
                marginBottom="6px"
              />
            );
          }

          const item = itemOrDivider as ListItemType;

          return (
            <ItemBox
              key={i}
              item={item}
              thumbnail={props.thumbnail}
              preview={preview}
              infoInScene={infoInScene}
              isAssetPreview={isAssetPreview}
            />
          );
        })
      )}
    </>
  );
};

export default ItemList;
