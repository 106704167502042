import { MenuBreadcrumbType_SaveGameDialog, MenuProps } from "..";

import MenuDialogInputText from "../menu-templates/MenuDialogInputText";
import useGame from "game-engine/hooks/useGame";

const SaveGameDialog = (
  props: MenuProps & { breadcrumb: MenuBreadcrumbType_SaveGameDialog }
) => {
  const { closeMenu, breadcrumb } = props;
  const { gameFns } = useGame();

  const onSubmit = (name: string) => {
    gameFns.saveGame({
      name: name,
      overwriteSavedGame: breadcrumb.data?.overwriteSavedGame,
    });
    closeMenu();
  };

  //
  // RENDER
  //
  return (
    <MenuDialogInputText
      {...props}
      title={{
        en: "Enter a description of your saved game:",
        cz: "Napište krátký popis uložené pozice:",
      }}
      defaultValue={breadcrumb?.data?.overwriteSavedGame?.name}
      onSubmit={onSubmit}
    />
  );
};

export default SaveGameDialog;
