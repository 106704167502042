import { SoundConfigType } from "game-engine/types";
import { createSoundConfig } from "./sounds/_files";

//
// SOUND CONFIGS OBJECT
//
const SOUND_CONFIGS = {
  Test_Bird: createSoundConfig({
    filename: "test-bird",
    volume: 1,
  }),

  Test_River: createSoundConfig({
    filename: "test-river",
    volume: 2,
  }),

  Test_Grab: createSoundConfig({
    filename: "test-grab",
    volume: 2,
  }),

  Test_Drop: createSoundConfig({
    filename: "test-drop",
    volume: 2,
  }),

  Test_Vial_Grab: createSoundConfig({
    filename: "test-vial-grab",
    volume: 2,
  }),

  Test_Vial_Drop: createSoundConfig({
    filename: "test-vial-drop",
    volume: 2,
  }),

  Test_Vial_Full_Grab: createSoundConfig({
    filename: "test-vial-full-grab",
    volume: 2,
  }),

  Test_Vial_Full_Drop: createSoundConfig({
    filename: "test-vial-full-drop",
    volume: 2,
  }),

  Flame01: createSoundConfig({
    filename: "flame-01",
    volume: 1,
  }),

  Fire: createSoundConfig({
    filename: "fire",
    volume: 1,
  }),

  MagicDust: createSoundConfig({
    filename: "magic-dust",
    volume: 1,
  }),

  TreeHealing: createSoundConfig({
    filename: "tree-healing",
    volume: 1,
  }),
};

export default SOUND_CONFIGS;

export const getSoundByConfigId = (id: string): SoundConfigType | null => {
  if (!id) return null;
  return Object.values(SOUND_CONFIGS).find((sound) => sound.id === id) || null;
};
