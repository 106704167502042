import InputLabel from '../_InputLabel'
import TextInput from '../TextInput'
import { TranslatedString } from 'game-engine/types'
import styled from 'styled-components'

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

const TranslatedStringInput = (props: {
  label?: string
  value: TranslatedString
  onChange: (s: TranslatedString) => void
  style?: any
}) => {
  //
  // RENDER
  //
  return (
    <Wrapper style={props.style}>
      {props.label && <InputLabel label={props.label} />}

      {Object.entries(props.value).map(([lang, langValue]) => (
        <TextInput
          key={lang}
          prefix={lang}
          prefixWidth={'20px'}
          value={langValue}
          onChange={(v) => {
            const updatedValue = { ...props.value }
            updatedValue[lang] = v
            props.onChange(updatedValue)
          }}
        />
      ))}
    </Wrapper>
  )
}

export default TranslatedStringInput
