import styled, { css } from "styled-components";

import Footer from "./components/Footer";
import Game from "game-engine/components/Game";
import { GameDisplayModeEnum } from "game-engine/types";
import SidebarLeft from "./components/SidebarLeft";
import SidebarRight from "./components/SidebarRight";
import useGame from "game-engine/hooks/useGame";

const Wrapper = styled.div`
  flex: 1;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  width: 100%;
`;

const Main = styled.div<{ isFullscreen?: boolean }>`
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;

  gap: 10px;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;

  width: auto;
  align-items: center;

  ${(props) =>
    props.isFullscreen &&
    css`
      padding-top: 0;
      max-width: 100%;
    `}
`;

const WrapperGame = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 20px;
  padding-bottom: 40px;

  & > * {
    flex: 1;
  }
`;

const GameWindowWrapper = styled.div`
  flex: 0;
  outline: 1px solid #fff1;
`;

const TabDeveloper = () => {
  const { gamePlay } = useGame();

  const isFullscreen =
    gamePlay.state.settings.displayMode === GameDisplayModeEnum.fullscreen;

  return (
    <Wrapper>
      <SidebarLeft />
      <Main isFullscreen={isFullscreen}>
        <WrapperGame>
          <GameWindowWrapper>
            <Game />
          </GameWindowWrapper>
        </WrapperGame>

        <Footer />
      </Main>
      <SidebarRight />
    </Wrapper>
  );
};

export default TabDeveloper;
