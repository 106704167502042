import { ActionId, Action_CharacterDeath } from "game-engine/types";

import { createActionName } from ".";

//
// CREATE BASIC SAVE GAME ACTION
//
type KillMainCharacterProps = {};

type KillMainCharacterReturn = () => Action_CharacterDeath;

export const killMainCharacter: (
  props?: KillMainCharacterProps
) => KillMainCharacterReturn = (/* props */) => () => {
  return {
    _name: createActionName({ name: "main character death" }),
    id: ActionId.characterDeath,
    blockGui: true,
    unskippable: true,
  };
};
