/* eslint-disable react-hooks/exhaustive-deps */

import {
  TextAlign,
  TextVerticalAlign,
  imageCompositionLayerTextType,
} from "game-engine/types";
import { useEffect, useRef, useState } from "react";

import GAME_CONFIG from "game-files/gameConfig";
import Text from "game-engine/components/basic-elements/Text";
import Transition from "game-engine/components/basic-elements/Transition";
import { TransitionFadeAnimation } from "game-engine/components/basic-elements/Transition/types/fade";

const ImageCompositionLayerText = (props: {
  isHidden?: boolean;
  text: imageCompositionLayerTextType;
  pauseOnInit?: { durationSec: number };
  defaultTextSettings: Partial<imageCompositionLayerTextType>;
  onEnd?: () => void;
  wrapperDimensions: {
    width: number;
    height: number;
  };
}) => {
  const {
    text,
    defaultTextSettings = {},
    isHidden,
    pauseOnInit,
    onEnd,
    wrapperDimensions: { width, height },
  } = props;

  const [isInitialized, setIsInitialized] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [wasVisible, setWasVisible] = useState(false);

  const defaultFadeDurationSec = GAME_CONFIG.cinematics.fadeTextDurationSec;

  //
  // CALCULATED TEXT DURATION
  //
  const { speed, millisecondsBase, millisecondsPerCharInText } =
    GAME_CONFIG.cinematics.textSpeed;

  // calculate character count based on BOTH languages to ensure same duration for each language
  // this will make it easy to time sounds or other audio cues that will be part of one audio file
  const textCharacterCount = Math.max(text.en.length, text.cz.length);

  const textDurationMilliseconds =
    millisecondsBase + textCharacterCount * millisecondsPerCharInText * speed;

  //
  // FINAL SETTINGS
  //
  const textProps: imageCompositionLayerTextType = {
    en: "",
    cz: "",

    fadeIn: { durationSec: defaultFadeDurationSec },
    fadeOut: { durationSec: defaultFadeDurationSec },
    visibleFor: { durationSec: textDurationMilliseconds / 1000 }, // how long is the text visible
    pauseAfter: {
      durationSec: GAME_CONFIG.cinematics.textPauseBetweenItems.durationSec,
    }, // pause after text disappears

    color: `#ffffff`,
    shadow: false,
    outlined: true,
    opacity: 1,

    position: { x: width / 2, y: height / 2 },
    offsetX: 0,
    offsetY: 0,
    minWidth: width * 0.75,
    maxWidth: width * 0.75,
    align: TextAlign.center,
    verticalAlign: TextVerticalAlign.center,

    ...defaultTextSettings,
    ...text,
  };

  const initOpacity = textProps.fadeIn ? 0 : 1;
  const [fadeSettings, setFadeSettings] = useState<TransitionFadeAnimation>({
    from: initOpacity,
    to: initOpacity,
    durationSec: 0,
  });

  //
  // FADE IN/OUT
  //
  const getFadeInDurationSec = () => {
    const isAllowed = !!textProps.fadeIn;

    if (isAllowed) {
      if (typeof textProps.fadeIn === "object") {
        return (textProps.fadeIn as any)?.durationSec ?? 0;
      }
      if (textProps.fadeIn) {
        return defaultFadeDurationSec;
      }
    }
    return 0;
  };

  const getFadeOutDurationSec = () => {
    const isAllowed = !!textProps.fadeOut;

    if (isAllowed) {
      if (typeof textProps.fadeOut === "object") {
        return (textProps.fadeOut as any)?.durationSec ?? 0;
      }
      if (textProps.fadeOut) {
        return defaultFadeDurationSec;
      }
    }
    return 0;
  };

  //
  // SEQUENCE
  //
  const timerIsInitializedRef = useRef<any>();
  const timerIsVisibleRef = useRef<any>();
  const timerIsPauseRef = useRef<any>();

  // step 1: pause before setting initialization if requested
  useEffect(() => {
    if (!isHidden) {
      if (pauseOnInit?.durationSec) {
        timerIsVisibleRef.current = setTimeout(
          () => setIsInitialized(true),
          pauseOnInit.durationSec * 1000
        );
      } else {
        setIsInitialized(true);
      }
    }

    return () => {
      clearTimeout(timerIsInitializedRef.current);
    };
  }, [isHidden]);

  // step 2: once initialized, set text to visible and turn it off after pause
  useEffect(() => {
    if (isInitialized) {
      setIsVisible(true);
      const pause = textProps.visibleFor.durationSec + getFadeInDurationSec();

      timerIsVisibleRef.current = setTimeout(
        () => setIsVisible(false),
        pause * 1000
      );
    }
    return () => {
      clearTimeout(timerIsVisibleRef.current);
    };
  }, [isInitialized]);

  // step 3: (first handle opacity for visibility, then) handle pause after text disappears and run onEnd function
  useEffect(() => {
    if (isVisible) {
      setWasVisible(true);
    }

    if (isVisible) {
      setFadeSettings({ from: 0, to: 1, durationSec: getFadeInDurationSec() });
    } else if (wasVisible) {
      setFadeSettings({ from: 1, to: 0, durationSec: getFadeOutDurationSec() });

      const pause = textProps.pauseAfter.durationSec + getFadeOutDurationSec();
      timerIsPauseRef.current = setTimeout(() => onEnd(), pause * 1000);
    }

    return () => {
      clearTimeout(timerIsPauseRef.current);
    };
  }, [isVisible]);

  //
  // RENDER
  //
  return (
    <Transition fade={fadeSettings}>
      <Text
        {...textProps}
        text={textProps /* en/cz is directly inside props */}
      />
    </Transition>
  );
};

export default ImageCompositionLayerText;
