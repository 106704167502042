import {
  SceneLayerDataType,
  SceneLayerType,
  SpriteDirection,
} from "game-engine/types";

import Box from "game-engine/_dev/basic-components/Box";
import Checkbox from "game-engine/_dev/basic-components/inputs/Checkbox";
import Divider from "game-engine/_dev/basic-components/Divider";
import Dropdown from "game-engine/_dev/basic-components/inputs/Dropdown";
import GAME_CONFIG from "game-files/gameConfig";
import { GameObjective } from "game-files/gameObjectives";
import NumberInput from "game-engine/_dev/basic-components/inputs/NumberInput";
import { capitalizeFirstLetter } from "game-engine/utils";
import devToolsConfig from "game-engine/configs/devtools-config";

const SpriteConfig = (props: {
  layer: SceneLayerType;
  updateLayer: (layer: SceneLayerType) => void;
  updateLayerData: (
    sceneLayerId: string,
    layerData: SceneLayerDataType
  ) => void;
}) => {
  const { layer, updateLayerData } = props;

  const layerData = layer.dataByCurrentObjective[GameObjective.GAME_START];

  //
  // RENDER
  //
  return (
    <Box renderContentOnly childMinWidth="90%" useFlex>
      <Divider name="Sprite Settings" />

      <Checkbox
        label="Is Animated Sprite"
        value={!!layerData.spriteConfig}
        onChange={(v) =>
          updateLayerData(layer.id, {
            ...layerData,
            spriteConfig: !!layerData.spriteConfig
              ? undefined
              : {
                  direction: SpriteDirection.Vertical,
                  frameWidth: GAME_CONFIG.scene.dimensions.x,
                  frameHeight: GAME_CONFIG.scene.dimensions.y,
                  frameCount: 10,
                  frameDurationMilliseconds:
                    GAME_CONFIG.sprites.defaultSpriteConfig
                      .frameDurationMilliseconds,
                  frameSequence: undefined,
                  clipOffset: undefined,
                },
          })
        }
      />

      {!!layerData.spriteConfig && (
        <>
          <Box renderContentOnly childMinWidth="90%">
            <Dropdown
              prefix="Sprite Direction:"
              prefixWidth="140px"
              selectedId={layerData.spriteConfig?.direction}
              items={Object.values(SpriteDirection).map((spriteDirection) => {
                return {
                  id: spriteDirection,
                  data: spriteDirection,
                  label: capitalizeFirstLetter(spriteDirection),
                };
              })}
              onChange={(v) =>
                updateLayerData(layer.id, {
                  ...layerData,
                  spriteConfig: {
                    ...layerData.spriteConfig,
                    direction: v as SpriteDirection,
                  },
                })
              }
              backgroundColor={devToolsConfig.inputBackground}
              padding="8px 6px"
            />

            <NumberInput
              prefix="Offset X (px)"
              prefixWidth="140px"
              min={0}
              noBorder
              value={layerData.spriteConfig.originOffset?.x ?? 0}
              onChange={(v) =>
                updateLayerData(layer.id, {
                  ...layerData,
                  spriteConfig: {
                    ...layerData.spriteConfig,
                    originOffset: {
                      ...layerData.spriteConfig.originOffset,
                      x: v,
                    },
                  },
                })
              }
            />
            <NumberInput
              prefix="Offset Y (px)"
              prefixWidth="140px"
              min={0}
              noBorder
              value={layerData.spriteConfig.originOffset?.y ?? 0}
              onChange={(v) =>
                updateLayerData(layer.id, {
                  ...layerData,
                  spriteConfig: {
                    ...layerData.spriteConfig,
                    originOffset: {
                      ...layerData.spriteConfig.originOffset,
                      y: v,
                    },
                  },
                })
              }
            />

            <NumberInput
              prefix="Frame Width (px)"
              prefixWidth="140px"
              min={0}
              noBorder
              value={layerData.spriteConfig.frameWidth}
              onChange={(v) =>
                updateLayerData(layer.id, {
                  ...layerData,
                  spriteConfig: {
                    ...layerData.spriteConfig,
                    frameWidth: v,
                  },
                })
              }
            />
            <NumberInput
              prefix="Frame Height (px)"
              prefixWidth="140px"
              min={0}
              noBorder
              value={layerData.spriteConfig.frameHeight}
              onChange={(v) =>
                updateLayerData(layer.id, {
                  ...layerData,
                  spriteConfig: {
                    ...layerData.spriteConfig,
                    frameHeight: v,
                  },
                })
              }
            />

            <NumberInput
              prefix="Frame Count"
              prefixWidth="140px"
              min={1}
              noBorder
              value={layerData.spriteConfig.frameCount}
              onChange={(v) =>
                updateLayerData(layer.id, {
                  ...layerData,
                  spriteConfig: {
                    ...layerData.spriteConfig,
                    frameCount: v,
                  },
                })
              }
            />

            <NumberInput
              prefix="Milliseconds per frame"
              prefixWidth="140px"
              min={1}
              noBorder
              value={layerData.spriteConfig.frameDurationMilliseconds}
              onChange={(v) =>
                updateLayerData(layer.id, {
                  ...layerData,
                  spriteConfig: {
                    ...layerData.spriteConfig,
                    frameDurationMilliseconds: v,
                  },
                })
              }
            />

            {/* <NumberInput
            prefix="Clip Offset X"
            prefixWidth="140px"
            value={layerData.spriteConfig.clipOffset.x}
            onChange={(v) =>
              updateLayerData(
                layer.id,
                {
                  ...layerData,
                  spriteConfig: {
                    ...layerData.spriteConfig,
                    clipOffset: {
                      ...layerData.spriteConfig.clipOffset,
                      x: v,
                    },
                  },
                },
              )
            }
          />
          <NumberInput
            prefix="Clip Offset Y"
            prefixWidth="140px"
            value={layerData.spriteConfig.clipOffset.y}
            onChange={(v) =>
              updateLayerData(
                layer.id,
                {
                  ...layerData,
                  spriteConfig: {
                    ...layerData.spriteConfig,
                    clipOffset: {
                      ...layerData.spriteConfig.clipOffset,
                      y: v,
                    },
                  },
                },
              )
            }
          /> */}
          </Box>
        </>
      )}
    </Box>
  );
};

export default SpriteConfig;
