/* eslint-disable react-hooks/exhaustive-deps */

import { AudioSettingsTypeEnum, GameAudioType } from "game-engine/types";
import { useEffect, useState } from "react";

import { getMusicByConfigId } from "game-files/audio/MUSIC_CONFIGS";
import useAudio from "game-engine/hooks/useAudio";
import useGame from "game-engine/hooks/useGame";

const Music = (props: { gameAudio: GameAudioType }) => {
  const { gameAudio } = props;
  const { gamePlay, gameFns } = useGame();

  const { audioRef, activeAudio, setActiveAudio, stopAudio } = useAudio({
    type: AudioSettingsTypeEnum.music,
  });

  const [forcedStop, setForcedStop] = useState(false);

  useEffect(() => {
    if (props.gameAudio?.music?.musicConfigId) {
      setForcedStop(false);
    }
  }, [props.gameAudio]);

  //
  // LOGIC
  //
  const setMusic = () => {
    if (!forcedStop && gameAudio?.music?.musicConfigId) {
      const backgroundMusic = gameAudio.music;
      const audioConfig = getMusicByConfigId(backgroundMusic.musicConfigId);

      if (audioConfig) {
        if (audioConfig.id !== activeAudio?.audioConfig?.id) {
          setActiveAudio({
            audioConfig,
            volume: backgroundMusic.volume ?? 1,
            loop: true,
            onEnd: () => {
              // callback after audio finishes playing (if not looping)
            },
          });
        }
        return true;
      }
    }
    return false;
  };

  const setOverrideMusic = () => {
    if (gamePlay.state.audio?.overrideMusic?.musicConfigId) {
      const overrideMusic = gamePlay.state.audio?.overrideMusic;
      const audioConfig = getMusicByConfigId(
        gamePlay.state.audio?.overrideMusic?.musicConfigId
      );

      if (audioConfig) {
        if (audioConfig.id !== activeAudio?.audioConfig?.id) {
          // override music
          setActiveAudio({
            audioConfig,
            volume: overrideMusic.volume ?? 1,
            loop: false,
            onEnd: () => {
              gameFns.setOverrideMusic(undefined);
            },
          });
        }
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    if (gamePlay.state.audio?.overrideMusic?.stopBackgroundMusic) {
      setForcedStop(true);
    }
    setOverrideMusic() || setMusic() || stopAudio();
  }, [gameAudio?.music, gamePlay.state.audio?.overrideMusic]);

  //
  // RENDER
  //
  return <audio ref={audioRef} src={activeAudio?.audioConfig?.src} />;
};

export default Music;
