import {
  CharacterDialogConfigByCurrentObjective,
  CharacterDialogConfigType,
} from "game-engine/types";

import { GameEventsType } from "game-files/gameEvents";
import { GameObjective } from "game-files/gameObjectives";
import { getDataByCurrentObjective } from "./getters";

export const getCharacterDialogConfigByCurrentObjective = (props: {
  dataByCurrentObjective: CharacterDialogConfigByCurrentObjective;
  currentObjective: GameObjective;
  events: GameEventsType;
}) => {
  return getDataByCurrentObjective<CharacterDialogConfigType>(
    { ...props, aggregateConditionals: true },
    {}
  );
};
