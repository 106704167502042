import styled, { css } from "styled-components";

import React from "react";

const CSS_DIVIDER = css`
  border-top: 2px solid #0004;
  padding-top: 8px;
  margin-top: 8px;

  &:nth-child(1),
  &:nth-child(2) {
    margin-top: 0;
    padding-top: 0;
    border: none;
  }
`;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: max-content auto;
  padding-left: 6px;
`;

const Label = styled.div`
  font-size: 13px;
  color: #fff4;
  padding-right: 8px;
  word-break: break-all;
  ${CSS_DIVIDER}
`;

const Value = styled.div`
  font-size: 13px;
  font-weight: 600;
  color: #fff8;
  word-break: break-all;
  ${CSS_DIVIDER}
`;

const InfoTable = (props: { data: { label: string; value: any }[] }) => {
  //
  // RENDER
  //

  return (
    <Wrapper>
      {props.data.map(
        (d, i) =>
          d && (
            <React.Fragment key={i}>
              <Label>{d.label}:</Label>
              <Value>{d.value}</Value>
            </React.Fragment>
          )
      )}
    </Wrapper>
  );
};

export default InfoTable;
