import { indentCode, prettifyCode } from "./code-formatting";

import { EditorType } from "game-engine/_dev/scene-editor-create-new";
import GAME_CONFIG from "game-files/gameConfig";
import { GameObjective } from "game-files/gameObjectives";

//
// BACKGROUND
//
export const getCode_SceneBackground = (
  editor: EditorType,
  options?: { includeConditionalExample: boolean }
) => {
  const background =
    editor.scene.images.background.dataByCurrentObjective[
      GameObjective.GAME_START
    ];

  const codeLines = ["{"];

  codeLines.push(`fillColor: undefined,`);
  codeLines.push(
    `image: { 
      src: ${editor.meta.background.names.image}, 
      transition: undefined
    },`
  );
  codeLines.push(`dropMap: { src: ${editor.meta.background.names.dropMap} },`);
  codeLines.push(`walkMap: { src: ${editor.meta.background.names.walkMap} },`);
  codeLines.push(
    `depthMap: { src: ${editor.meta.background.names.depthMap} },`
  );

  const depthScaleMin = background?.depthSettings?.scaleMin;
  const depthScaleMax = background?.depthSettings?.scaleMax;
  const depthScaleMinDefault = GAME_CONFIG.scene.defaultDepthSettings.scaleMin;
  const depthScaleMaxDefault = GAME_CONFIG.scene.defaultDepthSettings.scaleMax;

  codeLines.push(`depthSettings: {
    scaleMin: ${
      depthScaleMin === depthScaleMinDefault ? "undefined" : depthScaleMin
    },
    scaleMax: ${
      depthScaleMax === depthScaleMaxDefault ? "undefined" : depthScaleMax
    },  
  }`);

  if (options?.includeConditionalExample) {
    codeLines.push(`
      /*      
      ${indentCode(getCode_SceneBackground_Conditional(), 3, {
        ignoreFirstLine: true,
      })}
      */`);
  }

  codeLines.push("}");
  return codeLines.join("\n");
};

//
// CONDITIONAL SCENE BACKGROUND
//
export const getCode_SceneBackground_Conditional = () => {
  const codeLines = [];

  codeLines.push(`
    // EXAMPLE OF CONDITIONAL SCENE BACKGROUND
    conditional: ({ events }) => [
    
      {
        condition: events.test_sickTreeState === 1,
        state: {
          image: { src: IMAGE_Layer_01_Background_A_B_Sprite },
          imageSpriteConfig: {
            direction: SpriteDirection.Vertical,
            playCount: 1, // plays once
            frameCount: 10,
            frameWidth: GAME_CONFIG.scene.dimensions.x,
            frameHeight: GAME_CONFIG.scene.dimensions.y,
            //frameDurationMilliseconds: 80,
          },

          dropMap: { src: IMAGE_Layer_00_DROP_MAP }, //   STATE NEEDS TO BE COMPLETE
          walkMap: { src: IMAGE_Layer_00_WALK_MAP }, //   STATE NEEDS TO BE COMPLETE
          depthMap: { src: IMAGE_Layer_00_DEPTH_MAP }, // STATE NEEDS TO BE COMPLETE
        },
      },

      {
        condition: events.test_sickTreeState > 1,
        state: {
          image: { src: IMAGE_Layer_01_Background_B },

          dropMap: { src: IMAGE_Layer_00_DROP_MAP }, //   STATE NEEDS TO BE COMPLETE
          walkMap: { src: IMAGE_Layer_00_WALK_MAP }, //   STATE NEEDS TO BE COMPLETE
          depthMap: { src: IMAGE_Layer_00_DEPTH_MAP }, // STATE NEEDS TO BE COMPLETE
        },
      },
      
    ],`);

  return prettifyCode(codeLines.join("\n"));
};
