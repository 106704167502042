import { prettifyCode } from "./code-formatting";

//
// SCENE AUDIO
//
export const getCode_SceneName_Conditional = () => {
  const codeLines = [];

  codeLines.push(`
    // EXAMPLE OF CONDITIONAL SCENE NAME
    conditional: ({ events }) => [
    
      {
        condition: events.test_treeClickCount > 3,
        state: {
          en: "SCENE NAME FOR treeClickCount > 3",
          cz: "SCENE NAME FOR treeClickCount > 3",
        },
      },
      
    ],`);

  return prettifyCode(codeLines.join("\n"));
};
