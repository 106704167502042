import styled, { css } from 'styled-components'

const Wrapper = styled.div`
  width: 15px;
  height: 15px;
  min-width: 15px;
  min-height: 15px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Box = styled.div<{ background?: string }>`
  position: relative;
  width: 80%;
  height: 80%;
  outline: 1px solid #fff2;

  ${(props) =>
    props.background &&
    css`
      background: ${props.background};
    `}
`

const Color = styled.div<{
  color: string
}>`
  width: 100%;
  height: 100%;
  background: ${(props) => props.color};
`

const ValueBox = (props: {
  children?: any
  backgroundColor?: string
  fillColor?: string
}) => {
  //
  // RENDER
  //
  return (
    <Wrapper>
      <Box background={props.backgroundColor}>
        {props.fillColor && <Color color={props.fillColor} />}
        {props.children}
      </Box>
    </Wrapper>
  )
}

export default ValueBox
