import InputBase, {
  InputBaseError,
  InputBaseSubmitButton,
} from "../_InputBase";

import InputLabel from "../_InputLabel";
import styled from "styled-components";

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export type NumberInputRangeControllerProps = {
  min: number;
  max: number;
  step?: number;
};

const NumberInput = (props: {
  label?: string;
  prefix?: string;
  prefixWidth?: string;
  value: number;
  min?: number;
  max?: number;
  onChange: (v: number) => void;
  onClick?: () => void;
  disabled?: boolean;
  rangeController?: NumberInputRangeControllerProps;
  submitButton?: InputBaseSubmitButton;
  errors?: InputBaseError[];
  setErrors?: (e: InputBaseError[]) => void;
  noBorder?: boolean;
}) => {
  //
  // RENDER
  //
  return (
    <Wrapper>
      {props.label && <InputLabel label={props.label} />}

      <InputBase
        type="number"
        value={props.value}
        min={props.min}
        max={props.max}
        prefix={props.prefix}
        prefixWidth={props.prefixWidth}
        onChange={(v) => props.onChange(parseInt(v as any) || 0)}
        onClick={props.onClick}
        disabled={props.disabled}
        rangeController={
          props.rangeController
            ? {
                value: props.value,
                onChange: (v) => props.onChange(v),
                min: props.rangeController.min,
                max: props.rangeController.max,
                step: props.rangeController.step,
              }
            : undefined
        }
        submitButton={
          props.submitButton ? { label: props.submitButton.label } : undefined
        }
        errors={props.errors}
        setErrors={props.setErrors}
        noBorder={props.noBorder}
      />
    </Wrapper>
  );
};

export default NumberInput;
