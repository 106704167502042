import styled from "styled-components";

const Label = styled.div<{ indent: string }>`
  color: #fff4;
  font-size: 12px;
  margin-top: 4px;
  margin-left: ${(props) => props.indent || "0px"};
`;

const InputSubLabel = (props: { label?: string; indent?: string }) => {
  //
  // RENDER
  //
  return props.label ? (
    <Label indent={props.indent}>{props.label}</Label>
  ) : null;
};

export default InputSubLabel;
