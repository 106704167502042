import {
  ActionId,
  Action_ItemInCursorToSceneOverlay,
  ItemPropInSceneOverlay,
} from "game-engine/types";

import { createActionName } from ".";

//
// CREATE BASIC ACTION THAT PUTS ITEM IN CURSOR INTO SCENE OVERLAY
//
type PutItemToSceneOverlayProps = Partial<ItemPropInSceneOverlay>;

type PutItemToSceneOverlayReturn = () => Action_ItemInCursorToSceneOverlay;

export const putItemInCursorToSceneOverlay: (
  props?: PutItemToSceneOverlayProps
) => PutItemToSceneOverlayReturn = (props) => () => {
  return {
    _name: createActionName({
      name: `put item in cursor to scene overlay`,
    }),
    id: ActionId.itemInCursorToSceneOverlay,
    sceneId: undefined, // THIS MUST BE FILLED WHEN RUNNING THE ACTION
    ...props,
  };
};
