import { CursorRenderType, SceneLayerType } from "game-engine/types";

import { GameObjective } from "game-files/gameObjectives";
import { SceneLayerMeta } from "game-engine/_dev/scene-editor-create-new";
import { getCode_SceneTalkActions } from "./code-actions";
import { getCode_SpriteConfig } from "./code-sprite-config";
import { getImportNameFromFilename } from "game-engine/_dev/scene-editor-create-new/utils/files";

//
// SCENE LAYER
//
export const getCode_SceneLayer = (
  layer: SceneLayerType,
  layerMeta: SceneLayerMeta,
  index: number,
  options?: { noExamples?: boolean }
) => {
  const noExamples = options?.noExamples;

  const layerData = layer.dataByCurrentObjective[GameObjective.GAME_START];

  const codeLines = ["\n{"];

  const conditionalExample = `
    /* 
    // EXAMPLE OF CONDITIONAL SCENE LAYER IMAGE
    conditional: ({ events }) => [
    
      {
        condition: events.test_treeClickCount > 0,
        state: {
          image: {
            src: ImageLayerTree1_State2,
            transition: { durationSec: 1 },
          },
        },
      },

    ],
    */`;

  codeLines.push(`id: "${layerMeta.sceneLayerId}",`);
  codeLines.push(
    `depthY: ${
      layerMeta.isInFront
        ? "1000"
        : layerMeta.isInBack
        ? "undefined"
        : layer.depthY || "undefined"
    },`
  );
  codeLines.push(`dataByCurrentObjective: {
                    [GameObjective.GAME_START]: {
                      image: { src: ${getImportNameFromFilename(
                        layerMeta.filename
                      )} },
                      spriteConfig: ${getCode_SpriteConfig(
                        layerData.spriteConfig
                      )},
                      ignoreOnClick: ${
                        layerData.ignoreOnClick ? "true" : "false"
                      },
                      isInvisible: ${layerData.isInvisible ? "true" : "false"},
                      cursorOnHover: ${
                        layerData.cursorOnHover &&
                        layerData.cursorOnHover !== CursorRenderType.default
                          ? `CursorRenderType.${layerData.cursorOnHover}`
                          : "undefined"
                      },
                      offset: undefined,
                      
                    ${
                      index === 0 && !noExamples ? conditionalExample : ""
                    }                
                    },
                  },`);

  codeLines.push(`actionsByCurrentObjective: {
                    [GameObjective.GAME_START]:{
                      actionsWithItem: undefined,
                      actions: ${getCode_SceneTalkActions(
                        layerMeta.dialogActions
                      )} }
                  }`);

  codeLines.push("},");
  return codeLines.join("\n");
};
