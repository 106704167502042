import Cursor, {
  DevCursorType,
} from "game-engine/components/game-elements/Cursor";
import {
  Dimensions,
  GameConfigType,
  GameDisplayModeEnum,
  Position,
} from "game-engine/types";
import { Rect, Stage } from "react-konva";

import GAME_CONFIG from "game-files/gameConfig";
import PixelatedLayer from "../PixelatedLayer";
import styled from "styled-components";
import useGame from "game-engine/hooks/useGame";
import { useState } from "react";
import useWindowSize from "hooks/useWindowSize";

const Wrapper = styled.div<{
  dimensions: Dimensions;
  showCursor: boolean;
  gameConfig: GameConfigType;
}>`
  width: ${(props) => props.dimensions.x}px;
  height: ${(props) => props.dimensions.y}px;
  overflow: hidden;
  cursor: ${(props) => (props.showCursor ? "auto" : "none")};
  filter: ${(props) => `blur(${props.gameConfig.render.blur}px)`};
`;

const Transform = styled.div<{ scale: Dimensions }>`
  transform-origin: 0 0;
  transform: scale(${(props) => props.scale.x}, ${(props) => props.scale.y});

  &,
  & > * {
    image-rendering: optimizeSpeed;
    image-rendering: -moz-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: -o-crisp-edges;
    image-rendering: pixelated;
    -ms-interpolation-mode: nearest-neighbor;
  }
`;

const Canvas = (props: {
  onClick?: (options: {
    canvasPosition: Position;
    scenePosition: Position;
  }) => void;
  devCursor?: DevCursorType;
  noCursor?: boolean;
  children?: any;
}) => {
  const { onClick, devCursor, noCursor } = props;

  const { gamePlay, engineConfig, gameFns } = useGame();
  const [mouseOnCanvas, setMouseOnCanvas] = useState(false);

  //
  // DIMENSIONS
  //
  const isFullscreen =
    gamePlay.state.settings.displayMode === GameDisplayModeEnum.fullscreen;
  const fullscreenDimension = useWindowSize();

  const forceFullscreen =
    fullscreenDimension.x < GAME_CONFIG.window.displayDimensions.x;

  const dimensionsSrc: Dimensions = {
    x: GAME_CONFIG.window.canvasDimensions.x,
    y: GAME_CONFIG.window.canvasDimensions.y,
  };
  const dimensionsFinal: Dimensions = {
    x:
      isFullscreen || forceFullscreen
        ? fullscreenDimension.x
        : GAME_CONFIG.window.displayDimensions.x,
    y:
      isFullscreen || forceFullscreen
        ? fullscreenDimension.y
        : GAME_CONFIG.window.displayDimensions.y,
  };

  //
  // TRANSFORMS
  //
  const scale: Dimensions = {
    x: dimensionsFinal.x / dimensionsSrc.x,
    y: dimensionsFinal.y / dimensionsSrc.y,
  };
  const scaleVal = Math.min(scale.x, scale.y);
  const scaleWithAspectRatio: Dimensions = {
    x: scaleVal,
    y: scaleVal,
  };

  const dimensionsWithAspectRatio: Dimensions = {
    x: scaleVal * dimensionsSrc.x,
    y: scaleVal * dimensionsSrc.y,
  };

  //
  // CURSOR
  //
  const [cursor, setCursor] = useState<Position>({ x: 0, y: 0 });

  const handleMouseMove = (e) => {
    setMouseOnCanvas(true);
    const position = e.target.getStage().getPointerPosition();
    setCursor({ x: Math.round(position.x), y: Math.round(position.y) });
  };

  //
  // RENDER
  //
  return (
    <Wrapper
      dimensions={dimensionsWithAspectRatio}
      showCursor={engineConfig.state.renderBrowserCursor}
      gameConfig={GAME_CONFIG}
    >
      <Transform scale={scaleWithAspectRatio}>
        <Stage
          width={dimensionsSrc.x}
          height={dimensionsSrc.y}
          onMouseMove={handleMouseMove}
          onMouseEnter={() => setMouseOnCanvas(true)}
          onMouseLeave={() => setMouseOnCanvas(false)}
        >
          {props.children}

          <PixelatedLayer>
            {!noCursor && mouseOnCanvas && (
              <Cursor position={cursor} devCursor={devCursor} />
            )}

            {onClick && (
              <Rect
                x={0}
                y={0}
                width={dimensionsSrc.x}
                height={dimensionsSrc.y}
                opacity={0}
                onClick={(e) =>
                  onClick({
                    canvasPosition: gameFns.getClickedPosition(e as any),
                    scenePosition: gameFns.getClickedScenePosition(e as any),
                  })
                }
              />
            )}
          </PixelatedLayer>
        </Stage>
      </Transform>
    </Wrapper>
  );
};

export default Canvas;
