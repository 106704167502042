import ActionsTalk from "../ActionsTalk";
import Box from "game-engine/_dev/basic-components/Box";
import BoxWithThumbnail from "game-engine/_dev/basic-components/Box/BoxWithThumbnail";
import { EditorType } from "../..";
import { GameObjective } from "game-files/gameObjectives";
import { ImageType } from "game-engine/types";
import { getImportNameFromFilename } from "../../utils/files";

const SectionImages = (props: {
  isOpen?: boolean;
  onOpenChange?: (v) => void;
  editor: EditorType;
  setEditor: (editor: EditorType) => void;
}) => {
  const { editor, setEditor } = props;

  const {
    image: background,
    dropMap,
    walkMap,
    depthMap,
  } = editor.scene?.images?.background.dataByCurrentObjective[
    GameObjective.GAME_START
  ] || {};

  const images: { id: string; name: string; image: ImageType }[] = [
    { id: "preview", name: "Preview", image: editor.scene.preview },
    { id: "background", name: "Background", image: background },
    { id: "dropMap", name: "Drop Map", image: dropMap },
    { id: "walkMap", name: "Walk Map", image: walkMap },
    { id: "depthMap", name: "Depth Map", image: depthMap },
  ];

  const changeSceneImage = (
    imageId: string,
    newImage: { src: string; filename: string }
  ) => {
    if (imageId === "preview") {
      setEditor({
        ...editor,
        scene: {
          ...editor.scene,
          preview: { src: newImage.src },
        },
        meta: {
          ...editor.meta,
          previewFileName: newImage.filename,
        },
      });

      return;
    }

    const key = imageId === "background" ? "image" : imageId;

    setEditor({
      ...editor,
      scene: {
        ...editor.scene,
        images: {
          ...editor.scene.images,
          background: {
            ...editor.scene.images?.background,
            dataByCurrentObjective: {
              [GameObjective.GAME_START]: {
                ...editor.scene.images.background.dataByCurrentObjective[
                  GameObjective.GAME_START
                ],
                [key]: { src: newImage.src },
              },
            },
          },
        },
      },
      meta: {
        ...editor.meta,
        background: {
          ...editor.meta.background,
          filenames: {
            ...editor.meta.background.filenames,
            [key]: newImage.filename,
          },
          names: {
            ...editor.meta.background.names,
            [key]: getImportNameFromFilename(newImage.filename),
          },
        },
      },
    });
  };

  //
  // RENDER
  //
  return (
    <Box
      label="Images"
      level={1}
      childMinWidth="60%"
      gap="10px"
      isOpen={props.isOpen}
      onOpenChange={props.onOpenChange}
    >
      {images.map((data) => (
        <BoxWithThumbnail
          key={data.id}
          label={data.name}
          level={2}
          useFlex
          childMinWidth="40%"
          imageSrc={data.image?.src}
          largeImageWhenOpen={{
            onImageChange: (image, filename) =>
              changeSceneImage(data.id, { src: image, filename }),
          }}
          contentPaddingTop="10px"
          contentPaddingLeft="15px"
          contentPaddingRight="15px"
        >
          {data.id === "background" ? (
            <>
              <ActionsTalk
                dialogActions={editor.meta.background.dialogActions}
                setDialogActions={(dialogActions) =>
                  setEditor({
                    ...editor,
                    meta: {
                      ...editor.meta,
                      background: {
                        ...editor.meta.background,
                        dialogActions: dialogActions,
                      },
                    },
                  })
                }
              />
            </>
          ) : null}
        </BoxWithThumbnail>
      ))}
    </Box>
  );
};

export default SectionImages;
