import Box from "../../basic-components/Box";
import GAME_CONFIG from "game-files/gameConfig";
import { GAME_OBJECTIVES_DEF } from "game-files/gameObjectives";
import JsonView from "../../basic-components/JsonView";
import devToolsConfig from "game-engine/configs/devtools-config";
import useGame from "game-engine/hooks/useGame";

const GameConfigs = (props: { isOpen?: boolean }) => {
  const { engineConfig } = useGame();

  //
  // RENDER
  //
  return (
    <Box label="Configs" childMinWidth="80%" isOpen={props.isOpen} level={1}>
      <Box label="Game Objectives" level={2}>
        <JsonView json={GAME_OBJECTIVES_DEF} autoExpandToLevel={3} hideRoot />
      </Box>

      <Box label="Game Config" level={2}>
        <JsonView json={GAME_CONFIG} autoExpandToLevel={3} hideRoot />
      </Box>

      <Box label="Engine Config" level={2}>
        <JsonView json={engineConfig.state} autoExpandToLevel={3} hideRoot />
      </Box>

      <Box label="Devtools Config" level={2}>
        <JsonView json={devToolsConfig} autoExpandToLevel={3} hideRoot />
      </Box>
    </Box>
  );
};

export default GameConfigs;
