import {
  BlendingMode,
  DynamicLightingLightSource,
  DynamicLightingType,
} from "game-engine/types";
import TransitionFade, {
  TransitionFadeAnimation,
} from "game-engine/components/basic-elements/Transition/types/fade";
import { useEffect, useRef, useState } from "react";

import { Group } from "react-konva";
import SceneFill from "game-engine/components/game-elements/SceneFill";
import SceneVignette from "../SceneVignette";

const SceneDynamicLightOverlay = (props: {
  lightSource: DynamicLightingLightSource;
  dynamicLighting: DynamicLightingType;
}) => {
  const { lightSource, dynamicLighting } = props;

  const isActive = !!lightSource;

  const flickerColor = lightSource?.flicker?.color ?? `#fff`;
  const flickerOpacity = lightSource?.flicker?.amount ?? 0.3; // Applied on top of flickering opacity
  const flickerOpacityMin = lightSource?.flicker?.opacityMin ?? 0; // Minimum opacity for flickering
  const flickerOpacityMax = lightSource?.flicker?.opacityMax ?? 1; // Maximum opacity for flickering
  const flickerStepDurationSec = lightSource?.flicker?.stepDurationSec ?? 0.5; // Duration for the opacity change step (in seconds)
  const flickerUpdateIntervalMs = 100; // Duration for the opacity to redraw (between each step, milliseconds)

  const flickerBlendingMode =
    lightSource.flicker.blendingMode ?? BlendingMode.Lighter;

  // TODO - VIGNETTE

  // HANDLE FADE IN-OUT
  const [fade, setFade] = useState<TransitionFadeAnimation>({
    from: isActive ? 1 : 0,
  });

  const isFirstRenderRef = useRef(true);
  const isActiveOnInitRef = useRef(isActive);

  useEffect(() => {
    if (isActive) {
      setFade({
        from: 0,
        to: 1,
        durationSec:
          isFirstRenderRef.current && isActiveOnInitRef.current ? 0 : 1,
      });
    } else {
      setFade({
        from: 1,
        to: 0,
        durationSec: 1,
      });
    }

    isFirstRenderRef.current = false;
  }, [isActive]);

  //
  // FLICKERING EFFECT
  //
  const [flickerOpacityAnimated, setFlickerOpacityAnimated] = useState(1);

  useEffect(() => {
    // Only run the flickering logic if the effect is active
    if (!isActive || !lightSource?.flicker) {
      return;
    }

    const interval = setInterval(() => {
      // Get a random opacity value between flickerOpacityMin and flickerOpacityMax
      const randomOpacity =
        Math.random() * (flickerOpacityMax - flickerOpacityMin) +
        flickerOpacityMin;

      const currentOpacity = flickerOpacityAnimated;

      // Smoothly interpolate between the current opacity and the new random opacity
      const interpolatedOpacity =
        currentOpacity +
        (randomOpacity - currentOpacity) * (flickerStepDurationSec / 2); // Adjust factor for smoothness
      setFlickerOpacityAnimated(interpolatedOpacity);
    }, flickerUpdateIntervalMs);

    return () => clearInterval(interval); // Clean up on unmount
  }, [
    isActive,
    lightSource?.flicker,
    flickerOpacityAnimated,
    flickerOpacityMin,
    flickerOpacityMax,
    flickerStepDurationSec,
    flickerUpdateIntervalMs,
  ]);

  // RENDER
  return (
    <Group listening={false}>
      {/* FLICKERING EFFECT */}
      {lightSource.flicker ? (
        <TransitionFade animatedOpacity={fade}>
          <Group opacity={flickerOpacityAnimated}>
            <SceneFill
              color={flickerColor}
              blendingMode={flickerBlendingMode}
              opacity={flickerOpacity}
            />
          </Group>
        </TransitionFade>
      ) : null}

      {/* VIGNETTE */}
      {dynamicLighting?.vignette ? (
        <SceneVignette {...dynamicLighting?.vignette} />
      ) : undefined}
    </Group>
  );
};

export default SceneDynamicLightOverlay;
