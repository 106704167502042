import { useRef, useState } from "react";

import { ReactComponent as SpeakerOff } from "assets/icons/speaker-off.svg";
import { ReactComponent as SpeakerOn } from "assets/icons/speaker-on.svg";
import devToolsConfig from "game-engine/configs/devtools-config";
import styled from "styled-components";

const Button = styled.button<{ isMuted: boolean }>`
  --size: 25px;

  border: none;
  outline: none;
  cursor: pointer;

  width: var(--size);
  min-width: var(--size);
  max-width: var(--size);
  height: var(--size);
  min-height: var(--size);
  max-height: var(--size);
  border-radius: 50%;
  padding: 0;

  color: ${devToolsConfig.background};
  background: ${(props) => (props.isMuted ? "#f00" : "#fff")};
  opacity: ${(props) => (props.isMuted ? "0.8" : "0.4")};

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 60%;
  }

  transition: all 0.2s ease;

  &:hover {
    opacity: 1;
  }
`;

const ButtonMuteAll: React.FC = () => {
  const [isMuted, setIsMuted] = useState<boolean>(false);

  const audioContextRef = useRef<AudioContext | null>(null);
  if (!audioContextRef.current) {
    audioContextRef.current = new window.AudioContext();
  }

  // FUNCTIONS
  const muteAllAudioElements = () => {
    const audioElements = document.querySelectorAll<HTMLAudioElement>("audio");
    audioElements.forEach((audio) => {
      audio.muted = true;
    });
  };

  const unmuteAllAudioElements = () => {
    const audioElements = document.querySelectorAll<HTMLAudioElement>("audio");
    audioElements.forEach((audio) => {
      audio.muted = false;
    });
  };

  const toggleWebAudioContext = (mute: boolean) => {
    if (audioContextRef.current) {
      if (mute) {
        audioContextRef.current.suspend();
      } else {
        audioContextRef.current.resume();
      }
    }
  };

  // Toggle mute/unmute function
  const toggleMute = () => {
    if (isMuted) {
      unmuteAllAudioElements();
      toggleWebAudioContext(false);
    } else {
      muteAllAudioElements();
      toggleWebAudioContext(true);
    }
    setIsMuted(!isMuted);
  };

  // RENDER
  return (
    <Button onClick={toggleMute} isMuted={isMuted}>
      {isMuted ? <SpeakerOff /> : <SpeakerOn />}
    </Button>
  );
};

export default ButtonMuteAll;
