/* eslint-disable react-hooks/exhaustive-deps */

import {
  CinematicScreenItem_AddLayer,
  CinematicScreenItem_BackgroundAnimation,
  CinematicScreenSequenceItem,
  CinematicScreenType,
  ImageCompositionLayerType,
  ImageCompositionType,
} from "game-engine/types";
import { Group, Rect } from "react-konva";
import { useEffect, useMemo, useState } from "react";

import ImageComposition from "game-engine/components/game-elements/ImageComposition";
import { TransitionPositionAnimation } from "game-engine/components/basic-elements/Transition/types/position";

const CinematicScreenRender = (props: {
  width: number;
  height: number;
  cinematicScreen: CinematicScreenType;
  currentSequenceItem?: CinematicScreenSequenceItem;
  onAnimationEnd?: () => void;
  onImagesLoaded: () => void;
  addLayer?: { index: number; onImageLayerAdded: () => void };
}) => {
  const {
    width,
    height,
    cinematicScreen,
    currentSequenceItem,
    onAnimationEnd,
    onImagesLoaded,
    addLayer,
  } = props;

  const background = cinematicScreen?.background;

  const getHiddenLayerIdByIndex = (index: number) => {
    return `hidden-layer-${index}`;
  };

  const [doOnAnimationEnd, setDoOnAnimationEnd] = useState(false);

  useEffect(() => {
    if (doOnAnimationEnd) {
      if (onAnimationEnd) {
        onAnimationEnd();
      }
      setDoOnAnimationEnd(false);
    }
  }, [doOnAnimationEnd]);

  //
  // GENERATE IMAGE COMPOSITION SETUP
  //
  const imageComposition = useMemo<ImageCompositionType>(() => {
    const layers: ImageCompositionLayerType[] = [];
    const hiddenLayerIds = [];
    let hiddenLayerId = -1;

    const getHiddenLayerId = () => {
      hiddenLayerId = hiddenLayerId + 1;
      return getHiddenLayerIdByIndex(hiddenLayerId);
    };

    if (background?.image) {
      // handle even background.image as an image composition for unified image handling
      layers.push({
        image: { width, height, ...background.image },
        spriteConfig: background.spriteConfig,
      });
    }

    if (background?.imageComposition?.layers?.length) {
      layers.push(
        ...background.imageComposition?.layers?.map((layer) => {
          return { ...layer, image: { width, height, ...layer.image } };
        })
      );
    }

    cinematicScreen?.sequence?.forEach((sequenceItem) => {
      const addLayer = (sequenceItem as CinematicScreenItem_AddLayer)?.addLayer;

      if (addLayer) {
        const hiddenLayerId = getHiddenLayerId();
        layers.push({
          id: hiddenLayerId,
          ...addLayer,

          image: addLayer.image
            ? { width, height, ...addLayer.image }
            : undefined,

          video: addLayer.video
            ? {
                width,
                height,
                ...addLayer.video,
              }
            : undefined,

          onEnd: () => {
            if (sequenceItem.hideAfterFinished) {
              setHiddenLayerIds((prevState) => [...prevState, hiddenLayerId]);
            }
            if (sequenceItem?.pauseUntilFinished) {
              setDoOnAnimationEnd(true);
            }
          },
        });
        hiddenLayerIds.push(hiddenLayerId);
      }
    });

    if (layers.length) {
      return {
        ...(background?.imageComposition || {}),
        layers,
        hiddenLayerIds: hiddenLayerIds?.length ? hiddenLayerIds : undefined,
      };
    }

    return undefined;
  }, [
    background?.image,
    background?.spriteConfig,
    background?.imageComposition?.layers,
  ]);

  useEffect(() => {
    if (!imageComposition?.layers?.length) {
      onImagesLoaded();
    }
  }, [imageComposition]);

  //
  // HANDLE ADDING NEW LAYERS (REMOVING THEIR ID FROM HIDDEN LAYER IDS)
  //
  const [hiddenLayerIds, setHiddenLayerIds] = useState(
    imageComposition?.hiddenLayerIds
  );

  useEffect(() => {
    if (addLayer) {
      const layerId = getHiddenLayerIdByIndex(addLayer.index);

      setHiddenLayerIds((prevState) =>
        prevState.filter((hiddenLayerId) => hiddenLayerId !== layerId)
      );
      addLayer.onImageLayerAdded();
    }
  }, [addLayer]);

  //
  // BACKGROUND ANIMATION
  //
  const [animatedPosition, setAnimatedPosition] =
    useState<TransitionPositionAnimation>({
      from: { x: 0, y: 0 },
    });

  useEffect(() => {
    const animateBackground = (
      currentSequenceItem as CinematicScreenItem_BackgroundAnimation
    )?.animateBackground;

    if (animateBackground) {
      setAnimatedPosition((prevState) => {
        return {
          from: prevState.from,
          to: {
            x: animateBackground.offsetX ?? prevState.to?.x,
            y: animateBackground.offsetY ?? prevState.to?.y,
          },
          durationSec: animateBackground.durationSec,
          onEnd: currentSequenceItem?.pauseUntilFinished
            ? () => setDoOnAnimationEnd(true)
            : undefined,
        };
      });
    }
  }, [currentSequenceItem]);

  //
  // RENDER
  //
  return (
    <Group>
      <Rect width={width} height={height} fill={background?.color || "black"} />

      {imageComposition ? (
        <ImageComposition
          width={width}
          height={height}
          imageComposition={{
            ...imageComposition,
            hiddenLayerIds,
          }}
          onImagesLoaded={onImagesLoaded}
          defaultTextSettings={cinematicScreen.textDefaults}
          position={background?.position}
          transitions={{ position: animatedPosition }}
        />
      ) : null}
    </Group>
  );
};

export default CinematicScreenRender;
