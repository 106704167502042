import { Ellipse, Group } from "react-konva";

import { BlendingMode } from "game-engine/types";
import GAME_CONFIG from "game-files/gameConfig";
import { normalizeHex } from "game-engine/utils";

const SceneVignette = (props: {
  opacity?: number;
  color?: string;
  size?: number; // 0 - 1
  blendingMode?: BlendingMode;
}) => {
  const {
    opacity = GAME_CONFIG.scene.dynamicLighting.vignette.opacity,
    size = GAME_CONFIG.scene.dynamicLighting.vignette.size,
    color = GAME_CONFIG.scene.dynamicLighting.vignette.color,
    blendingMode = GAME_CONFIG.scene.dynamicLighting.vignette.blendingMode,
  } = props;

  const sceneWidth = GAME_CONFIG.scene.dimensions.x;
  const sceneHeight = GAME_CONFIG.scene.dimensions.y;
  const sizeX = GAME_CONFIG.scene.dimensions.x * 1.5;
  const sizeY = GAME_CONFIG.scene.dimensions.y * 1.5;

  // Normalize the color for use
  const normalizedColor = normalizeHex(color);
  const transparentStop = `${normalizedColor.slice(0, 7)}00`; // Transparent color

  const transition = 0.8;

  // Adjusted gradient stops
  const gradientColorStops = [
    0,
    transparentStop,
    (1 - Math.min(1, Math.max(0, size))) * transition,
    transparentStop,
    transition,
    normalizedColor, // Start transitioning to color
    1,
    normalizedColor,
  ];

  return (
    <Group listening={false}>
      <Ellipse
        x={sceneWidth / 2}
        y={sceneHeight / 2}
        radiusX={sizeX / 2}
        radiusY={sizeY / 2}
        fillRadialGradientStartRadius={0}
        fillRadialGradientEndRadius={Math.max(sizeX, sizeY) / 2}
        fillRadialGradientColorStops={gradientColorStops}
        opacity={opacity}
        globalCompositeOperation={blendingMode}
      />
    </Group>
  );
};

export default SceneVignette;
