import { useContext, useEffect, useState } from "react";

import { ACTION } from "game-engine/utils";
import Box from "../../../../basic-components/Box";
import Checkbox from "game-engine/_dev/basic-components/inputs/Checkbox";
import { DevToolsContext } from "game-engine";
import MUSIC_CONFIGS from "game-files/audio/MUSIC_CONFIGS";
import useGame from "game-engine/hooks/useGame";

const SectionMusic = (props: { isOpen?: boolean }) => {
  const { gamePlay, gameFns } = useGame();
  const devTools = useContext(DevToolsContext);

  const runActions = devTools.state.actions.runActionsRef?.current;

  const [isDisabled, setIsDisabled] = useState(!runActions);
  useEffect(() => {
    setIsDisabled(!runActions);
  }, [runActions]);

  //
  // ACTIONS - OVERRIDE MUSIC
  //
  const actionSetOverrideMusic = (musicConfigId: string) => {
    // turn on music (otherwise it will be confusing why nothing is playing)
    gameFns.setSettings({ playMusic: true });

    runActions([
      ACTION.playMusic({
        musicConfigId,
        volume: 1,
      }),
    ]);
  };

  const actionStopOverrideMusic = () => {
    // turn on music (to test that music is actually stopped, not just turned off)
    gameFns.setSettings({ playMusic: true });

    runActions([ACTION.stopCustomMusic()]);
  };

  //
  // RENDER
  //
  return (
    <Box
      label="Music"
      childMinWidth="80%"
      contentPaddingBottom="20px"
      isOpen={props.isOpen}
      level={2}
      gap="0px"
    >
      <Box renderContentOnly childMinWidth="80%" isOpen>
        {Object.values(MUSIC_CONFIGS).map((musicConfig) => {
          const isActive =
            gamePlay.state.audio?.overrideMusic?.musicConfigId ===
            musicConfig?.id;

          return (
            <Checkbox
              key={musicConfig.id}
              isDisabled={isDisabled}
              label={`" ${musicConfig.name} "`}
              value={isActive}
              onChange={(v) => {
                if (v) {
                  actionSetOverrideMusic(musicConfig.id);
                } else {
                  actionStopOverrideMusic();
                }
              }}
            />
          );
        })}
      </Box>
    </Box>
  );
};

export default SectionMusic;
