/* eslint-disable react-hooks/exhaustive-deps */

import {
  ImageCompositionLayerType,
  ImageCompositionType,
  Position,
  imageCompositionLayerTextType,
} from "game-engine/types";
import { useEffect, useMemo, useState } from "react";

import GAME_CONFIG from "game-files/gameConfig";
import { Group } from "react-konva";
import ImageCompositionLayerText from "./ImageCompositionLayerText";
import SpriteAnimated from "game-engine/components/basic-elements/SpriteAnimated";
import Transition from "game-engine/components/basic-elements/Transition";
import { TransitionPositionAnimation } from "game-engine/components/basic-elements/Transition/types/position";
import Video from "game-engine/components/basic-elements/Video";

const ImageComposition = (props: {
  imageComposition: ImageCompositionType;
  defaultTextSettings?: Partial<imageCompositionLayerTextType>;
  width?: number;
  height?: number;
  position?: Position;
  x?: number;
  y?: number;
  onImagesLoaded: () => void;
  transitions?: {
    position: TransitionPositionAnimation;
  };
}) => {
  const {
    imageComposition,
    onImagesLoaded,
    width = GAME_CONFIG.scene.dimensions.x,
    height = GAME_CONFIG.scene.dimensions.y,
    position = { x: 0, y: 0 },
    x = 0,
    y = 0,
    defaultTextSettings,
    transitions,
  } = props;

  const [fileLoadCount, setFileLoadCount] = useState(0);

  useEffect(() => {
    if (
      fileLoadCount ===
      imageComposition?.layers?.filter((layer) => layer.image || layer.video)
        ?.length
    ) {
      onImagesLoaded();
    }
  }, [fileLoadCount]);

  const layers = useMemo<ImageCompositionLayerType[]>(() => {
    // sort layers by zIndex
    return (imageComposition?.layers || [])?.sort((a, b) =>
      a.text && a.zIndex === undefined
        ? 1
        : b.text && b.zIndex === undefined
        ? -1
        : (a.zIndex || 0) < (b.zIndex || 0)
        ? -1
        : 1
    );
  }, [imageComposition.layers]);

  const onAnimationEnd = (layer: ImageCompositionLayerType) => {
    if (layer.onEnd) {
      setTimeout(() => layer.onEnd(), 0);
    }
  };

  //
  // RENDER
  //
  return (
    <Group x={position.x + x} y={position.y + y}>
      {layers.map((layer, i) => {
        const isHidden =
          layer.id === undefined
            ? false
            : imageComposition.hiddenLayerIds?.includes(layer.id);

        const playAnimation = !isHidden && !!layer?.spriteConfig;

        // All images are handled as sprite sheets for unified approach

        return (
          <Group
            key={layer.image?.src ?? layer.video?.src ?? layer.text?.en}
            offset={layer.offset}
            opacity={isHidden ? 0 : 1}
          >
            {layer.text ? (
              <Transition
                position={
                  layer.text.moveWithBackground
                    ? transitions?.position
                    : undefined
                }
              >
                <ImageCompositionLayerText
                  isHidden={isHidden}
                  text={layer.text}
                  pauseOnInit={undefined}
                  defaultTextSettings={defaultTextSettings}
                  onEnd={() => onAnimationEnd(layer)}
                  wrapperDimensions={{
                    width,
                    height,
                  }}
                />
              </Transition>
            ) : layer.video ? (
              <Transition position={transitions?.position}>
                <Video
                  src={layer.video.src}
                  onVideoLoaded={() =>
                    setFileLoadCount((prevState) => prevState + 1)
                  }
                  width={layer.video.width}
                  height={layer.video.height}
                  play={!isHidden}
                  playCount={layer.video.playCount}
                  frameDurationMilliseconds={
                    layer.video.frameDurationMilliseconds
                  }
                  onEnd={() => onAnimationEnd(layer)}
                />
              </Transition>
            ) : (
              <Transition position={transitions?.position}>
                <SpriteAnimated
                  src={layer?.image?.src}
                  srcTransitionOnChange={layer?.image?.transition}
                  spriteConfig={{
                    ...(layer?.spriteConfig || {}),
                    frameWidth:
                      layer?.spriteConfig?.frameWidth ??
                      layer?.image?.width ??
                      GAME_CONFIG.scene.dimensions.x,
                    frameHeight:
                      layer?.spriteConfig?.frameHeight ??
                      layer?.image?.height ??
                      GAME_CONFIG.scene.dimensions.y,
                  }}
                  controlledAnimation={{ playAnimation }}
                  getSpriteFrameRenderIndex={undefined}
                  onImageLoaded={() =>
                    setFileLoadCount((prevState) => prevState + 1)
                  }
                  renderOutline={undefined}
                  renderFill={undefined}
                  onAnimationEnd={() => onAnimationEnd(layer)}
                />
              </Transition>
            )}
          </Group>
        );
      })}
    </Group>
  );
};

export default ImageComposition;
