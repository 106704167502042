import {
  SceneBackgroundByCurrentObjective,
  SceneBackgroundType,
} from "game-engine/types";

import { GameEventsType } from "game-files/gameEvents";
import { GameObjective } from "game-files/gameObjectives";
import { getDataByCurrentObjective } from "./getters";

export const getSceneBackgroundByCurrentObjective = (props: {
  dataByCurrentObjective: SceneBackgroundByCurrentObjective;
  currentObjective: GameObjective;
  events: GameEventsType;
}) => {
  return getDataByCurrentObjective<SceneBackgroundType>(props, {});
};
