import styled from 'styled-components'

const List = styled.ol`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

const Item = styled.li`
  color: #fff6;
  font-size: 12px;
`

const Tutorial = (props: { items: string[] }) => {
  return (
    <div>
      <List>
        {props.items.map((item, i) => (
          <Item key={i}>{item}</Item>
        ))}
      </List>
    </div>
  )
}

export default Tutorial
