import MenuButton, {
  MENU_BUTTON_HEIGHT,
  MenuButtonProps,
} from "game-engine/components/gui-elements/Menu/menu-elements/MenuButton";

import AssetBox from "game-engine/_dev/basic-components/Box/AssetBox";
import AssetPreview from "game-engine/_dev/basic-components/AssetPreview";
import Box from "game-engine/_dev/basic-components/Box";
import JsonView from "game-engine/_dev/basic-components/JsonView";
import styled from "styled-components";

type MenuButtonPreviewProps = Omit<MenuButtonProps, "width" | "label"> & {
  label?: string;
  width?: number;
  exampleName: string;
};

const PreviewWrapper = styled.div<{ minWidth?: string }>`
  min-width: ${(props) => props.minWidth};
`;

const JsonWrapper = styled.div<{ minWidth?: string }>`
  min-width: ${(props) => props.minWidth};
  display: flex;
  & > * {
    flex: 1;
  }
`;

const AssetMenuButton = (props: { isOpen?: boolean }) => {
  const elemWidth = 120;
  const elemHeight = MENU_BUTTON_HEIGHT;
  const elemText = "Button";
  const scale = 2;

  const previewProps: MenuButtonPreviewProps[] = [
    {
      exampleName: "Default",
    },
    {
      exampleName: "Centered Text",
      centeredText: true,
    },
    {
      exampleName: "Custom Padding X",
      paddingX: 24,
    },
    {
      exampleName: "Custom Padding Y",
      paddingY: 3,
    },
    {
      exampleName: "Custom Height",
      label: "Tall button with long text",
      height: 28,
    },
    {
      exampleName: "Opacity",
      opacity: 0.2,
    },
  ];

  //
  // RENDER
  //
  return (
    <AssetBox
      label="Menu Button"
      subLabel="Menu element to trigger functions"
      level={2}
      isOpen={props.isOpen}
      childMinWidth={`max(22%, ${elemWidth * scale + 20}px)`}
      canvasThumbnail={{
        scale: 1,
        contentWidth: 50,
        contentHeight: 17,
        children: <MenuButton label="button" width={50} centeredText />,
      }}
      contentPaddingLeft="20px"
      contentPaddingRight="20px"
      contentPaddingBottom="30px"
    >
      {previewProps.map((elemProps, i) => {
        const elemPropsFinal: MenuButtonProps = {
          width: elemWidth,
          label: elemText,
          onClick: () => {},
          ...elemProps,
        };

        const elemPropsPreview = { ...elemPropsFinal };
        delete (elemPropsPreview as any).exampleName;
        delete (elemPropsPreview as any).onClick;
        delete (elemPropsPreview as any).label;

        return (
          <Box
            key={i}
            label={elemProps.exampleName}
            level={3}
            isOpen
            useFlex
            contentPaddingLeft="0px"
            contentPaddingTop="10px"
            gap="20px"
          >
            <PreviewWrapper minWidth={`${elemWidth * scale}px`}>
              <AssetPreview
                width={elemWidth}
                height={elemPropsFinal.height || elemHeight}
                scale={scale}
                background="#0000"
              >
                <MenuButton {...elemPropsFinal} />
              </AssetPreview>
            </PreviewWrapper>

            <JsonWrapper minWidth="200px">
              <JsonView
                json={elemPropsPreview}
                autoExpandToLevel={1}
                hideRoot
              />
            </JsonWrapper>
          </Box>
        );
      })}
    </AssetBox>
  );
};

export default AssetMenuButton;
