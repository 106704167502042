import {
  ActionId,
  Action_Scene,
  GamePlayCurrentSceneType,
} from "game-engine/types";

import { createActionName } from ".";

//
// CREATE BASIC SCENE ACTION
//
type SetCurrentSceneProps = GamePlayCurrentSceneType & {};

type SetCurrentSceneReturn = () => Action_Scene;

export const setCurrentScene: (
  props?: SetCurrentSceneProps
) => SetCurrentSceneReturn = (props) => () => {
  return {
    ...props,
    _name: createActionName({
      name: `change current scene`,
    }),
    id: ActionId.scene,
  };
};
