import Button from "game-engine/_dev/basic-components/Button";
import Tutorial from "game-engine/_dev/basic-components/Tutorial";
import styled from "styled-components";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
`;

//
// COMPONENT
//
const SectionIntro = (props: { allowEdit: () => void }) => {
  //
  // RENDER
  //
  return (
    <Wrapper>
      <Button
        isActive
        onClick={props.allowEdit}
        style={{ padding: "20px 30px" }}
      >
        Load Scenes Layout
      </Button>

      <Tutorial
        items={[
          "Load scenes layout (using the button above) definition specified in `src/game-files/scenesLayout`",
          "The default view displays all defined scenes layouts.",
          "The right sidebar has the option to add new or delete existing scenes layouts",
          "Link two scenes layouts by clicking on the neighbor areas (after clicking on the second one, a link is created)",
          "Select a scene layout from the list (right sidebar) to change scenes, override ids and the overall layout",
          "Left sidebar offers several functions for editing the scenes layout",
          "Use button in the footer to copy the final code",
          "Paste the code into 'src/game-files/scenes/SCENE_LAYOUTS.ts'.",
        ]}
      />
    </Wrapper>
  );
};

export default SectionIntro;
