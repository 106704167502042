import DevScene01 from "./DevScene01";
import DevScene02 from "./DevScene02";
import DevScene03 from "./DevScene03";
import DevScene04 from "./DevScene04";
import DevScene05 from "./DevScene05";
import DevScene06 from "./DevScene06";
import DevScene07 from "./DevScene07";
import { SceneDefinitionsType } from "game-engine/types";

const SCENE_CONFIGS_DEV: SceneDefinitionsType = {
  DevScene01,
  DevScene02,
  DevScene03,
  DevScene04,
  DevScene05,
  DevScene06,
  DevScene07,
};

export default SCENE_CONFIGS_DEV;
