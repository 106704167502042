import { Group, Rect } from "react-konva";

import GAME_CONFIG from "game-files/gameConfig";
import LayoutColumn from "game-engine/components/basic-elements/LayoutColumn";
import MenuBevelBox from "./MenuBevelBox";
import MenuSpacer from "./MenuSpacer";
import MenuTitle from "./MenuTitle";
import { TranslatedString } from "game-engine/types";

const MenuWrapper = (props: {
  children?: any;
  title?: string | TranslatedString;
  titleGap?: number;
  titlePaddingX?: number;
  titleIsWarning?: boolean;
  titleIsError?: boolean;
  titleMinHeight?: number;
  isActive: boolean;
  width: number;
  height: number;
  paddingTop?: number;
  paddingLeft?: number;
  gap?: number;
}) => {
  const {
    children,
    title,
    titleGap = 6,
    titlePaddingX = 0,
    titleIsWarning = false,
    titleIsError = false,
    titleMinHeight = 0,
    isActive,
    width,
    height,
    paddingTop = 8,
    paddingLeft = 0,
    gap = 3,
  } = props;

  const canvasX = GAME_CONFIG.window.canvasDimensions.x;
  const canvasY = GAME_CONFIG.window.canvasDimensions.y;

  const contentWidth = width - paddingLeft * 2;

  return (
    <Group opacity={isActive ? 1 : 0} listening={isActive}>
      <Rect
        x={0}
        y={0}
        width={GAME_CONFIG.window.canvasDimensions.x}
        height={GAME_CONFIG.window.canvasDimensions.y}
        fill="black"
        opacity={0.6}
      />

      <MenuBevelBox
        x={Math.round(canvasX / 2)}
        y={Math.round((canvasY - height) / 2)}
        width={width}
        height={height}
        centered
      >
        <LayoutColumn x={paddingLeft} y={paddingTop} gap={gap}>
          {!!title ? (
            <LayoutColumn gap={0}>
              <MenuTitle
                x={Math.round(contentWidth / 2)}
                text={title}
                width={contentWidth - titlePaddingX * 2}
                isWarning={titleIsWarning}
                isError={titleIsError}
                minHeight={titleMinHeight}
              />
              <MenuSpacer height={titleGap} />
            </LayoutColumn>
          ) : (
            <></>
          )}

          {children}
        </LayoutColumn>
      </MenuBevelBox>
    </Group>
  );
};

export default MenuWrapper;
