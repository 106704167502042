import { CursorGlyphType } from "game-engine/types";
import Pixels from "game-engine/components/basic-elements/Pixels";

const CursorGlyph = (props: {
  cursorGlyph: CursorGlyphType;
  x?: number;
  y?: number;
  opacity?: number;
}) => {
  const { cursorGlyph, x = 0, y = 0, opacity = 1 } = props;

  const outlineColor = cursorGlyph.outlineColor || `#000000ff`;
  const transparentColor = `#00000000`;
  const glyphColor = cursorGlyph.color || `#ffffff`;

  //
  // RENDER
  //
  return (
    <Pixels
      opacity={opacity}
      position={{
        x: x + (cursorGlyph.offsetX || 0),
        y: y + (cursorGlyph.offsetY || 0),
      }}
      matrix={cursorGlyph.pixels}
      outlined={cursorGlyph.outlined ?? true}
      outlineNoAllowDiagonal={cursorGlyph.outlineNoDiagonal}
      colorMap={{
        "-1": outlineColor,
        "0": transparentColor,
        "1": glyphColor,
      }}
    />
  );
};

export default CursorGlyph;
