import { Group, Rect } from "react-konva";

import GAME_CONFIG from "game-files/gameConfig";
import { Position } from "game-engine/types";

const DepthLine = (props: { position?: Position; color?: string }) => {
  const { dimensions } = GAME_CONFIG.scene;

  //
  // RENDER
  //
  return (
    <Group position={props.position || { x: 0, y: 0 }}>
      <Rect
        listening={false}
        x={-dimensions.x}
        y={0}
        width={dimensions.x * 3}
        height={1}
        fill={props.color || "red"}
      />
    </Group>
  );
};

export default DepthLine;
