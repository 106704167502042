/* eslint-disable react-hooks/exhaustive-deps */

import {
  RunActionsType,
  StopActionsType,
} from "game-engine/components/game-elements/Scene/logic/useSceneActions";
import { useEffect, useMemo, useState } from "react";

import GAME_CONFIG from "game-files/gameConfig";
import { Group } from "react-konva";
import MenuButton from "../../Menu/menu-elements/MenuButton";
import { SkillId } from "game-files/common/ids";
import { getSkillConfigById } from "game-files/skills/SKILL_CONFIGS";
import useGame from "game-engine/hooks/useGame";

const SkillSlot = (props: {
  skillId: SkillId;
  runActions: RunActionsType;
  stopActions: StopActionsType;
}) => {
  const { skillId, runActions } = props;

  const { gamePlay, gameFns, logger } = useGame();

  const skill = useMemo(() => {
    return getSkillConfigById(skillId);
  }, [skillId]);

  //
  // AVAILABILITY
  //
  const getIsAvailable = () => {
    if (
      gameFns.getActiveSkillIds({ excludeSkillsWithNoDuration: true }).length
    ) {
      // - skills can only be used if no other skill is currently active
      // - skip skills with no duration, these only cause flicker of availability of the remaining skills
      return false;
    }

    if (
      !gamePlay.state.currentSkills ||
      gamePlay.state.currentSkills[skillId] === undefined
    ) {
      // skill can run if currentSkills do not contain it already (either as isActive or isPaused)
      return true;
    }

    if (!skill) {
      console.warn(`skill in skill slot not found (${skillId})`);
    }
    if (!runActions) {
      console.warn(`runActions not available in skill slot (${skillId})`);
    }
    return false;
  };

  const [isAvailable, setIsAvailable] = useState(getIsAvailable());

  useEffect(() => {
    setIsAvailable(getIsAvailable());
  }, [runActions, skill, gamePlay.state.currentSkills]);

  //
  // ON CLICK
  //
  const onClick = () => {
    logger.info(`skill started from gui ${skillId}`, skill);
    runActions(gameFns.getSkillActionsOnStart(skillId));
  };

  //
  // RENDER
  //
  return (
    <Group>
      <MenuButton
        label={`${skill.id[0]}`}
        width={GAME_CONFIG.gui.skillSlots.slotWidth}
        height={GAME_CONFIG.gui.skillSlots.slotHeight}
        paddingX={0}
        paddingY={-10}
        centeredText
        color="#444"
        onClick={onClick}
        disabled={!isAvailable}
      />
    </Group>
  );
};

export default SkillSlot;
