export type ImageType = {
  src: string;
  width?: number;
  height?: number;
  opacity?: number;
  brightness?: number;
  transition?: ImageTransitionType; // this is used if render img.src changes
};

export type ImageTransitionType = {
  durationSec: number;
};

export enum ImageChannel {
  red = "red",
  green = "green",
  blue = "blue",
  alpha = "alpha",
}

export enum BlendingMode {
  SourceOver = "source-over",
  SourceIn = "source-in",
  SourceOut = "source-out",
  SourceAtop = "source-atop",
  DestinationOver = "destination-over",
  DestinationIn = "destination-in",
  DestinationOut = "destination-out",
  DestinationAtop = "destination-atop",
  Lighter = "lighter",
  Copy = "copy",
  Xor = "xor",
  Multiply = "multiply",
  Screen = "screen",
  Overlay = "overlay",
  Darken = "darken",
  Lighten = "lighten",
  ColorDodge = "color-dodge",
  ColorBurn = "color-burn",
  HardLight = "hard-light",
  SoftLight = "soft-light",
  Difference = "difference",
  Exclusion = "exclusion",
  Hue = "hue",
  Saturation = "saturation",
  Color = "color",
  Luminosity = "luminosity",
}

export type ImageFilters = {
  //blur?: number; // too much of a hassle, doesn't work at the moment
  brightness?: number;
  contrast?: number;
  grayscale?: boolean;
  hue?: number; // 0-360
  saturation?: number;
  luminance?: number;
  invert?: boolean;
  pixelate?: number;
  sepia?: boolean;
  threshold?: number; // 0 - 1
  emboss?: EmbossFilterOptions;
  enhance?: number;
  noise?: number;
  rgb?: RGBFilterOptions;

  // CUSTOM FILTERS
  colorizeBW?: ColorizeBWFilterOptions;
  tint?: TintFilterOptions;
};

export type EmbossFilterOptions = {
  strength?: number; // 0 - 1
  whiteLevel?: number; // 0 - 1
  direction?:
    | "top"
    | "left"
    | "right"
    | "bottom"
    | "top-left"
    | "top-right"
    | "bottom-left"
    | "bottom-right";
  blend?: boolean;
};

export type RGBFilterOptions = {
  red: number; // 0 - 256
  green: number; // 0 - 256
  blue: number; // 0 - 256
};

export type ColorizeBWFilterOptions = {
  colorBlack: string; // hex
  colorWhite: string; // hex
};

export type TintFilterOptions = {
  color: string; // hex
  amount: number; // 0 - 1
  tintTargetColor?: {
    color: string;
    threshold?: number; // 0 - 256
    brightness?: number; // 1 = default
  };
};
