import Box from "game-engine/_dev/basic-components/Box";
import Button from "game-engine/_dev/basic-components/Button";
import Checkbox from "game-engine/_dev/basic-components/inputs/Checkbox";
import Divider from "game-engine/_dev/basic-components/Divider";
import { EditorDialogActions } from "../..";
import SHARED_DATA from "game-files/scenes/SHARED_DATA";
import { TranslatedString } from "game-engine/types";
import TranslatedStringInput from "game-engine/_dev/basic-components/inputs/TranslatedStringInput";
import { capitalizeFirstLetter } from "game-engine/utils";
import styled from "styled-components";

const DialogButtons = styled.div`
  width: 100%;
  max-width: 200px;
  margin-left: auto;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;

  button {
    flex: 1;
    max-width: 200px;
  }
`;

const ActionsTalk = (props: {
  dialogActions: EditorDialogActions;
  setDialogActions: (a: EditorDialogActions) => void;
}) => {
  const { dialogActions, setDialogActions } = props;

  const updateCustomDialogOptions = (dialogs: TranslatedString[][]) => {
    setDialogActions({
      ...dialogActions,
      customDialogOptions: dialogs,
    });
  };

  const addCustomDialogOption = () => {
    const newDialogOption: TranslatedString = { en: "", cz: "" };
    updateCustomDialogOptions([
      ...dialogActions.customDialogOptions,
      [newDialogOption],
    ]);
  };

  const deleteCustomDialogOption = (dialogOptionIndex: number) => {
    const updatedDialogOptions = [...dialogActions.customDialogOptions];
    updatedDialogOptions.splice(dialogOptionIndex, 1);
    updateCustomDialogOptions(updatedDialogOptions);
  };

  //
  // RENDER
  //
  return (
    <Box renderContentOnly childMinWidth="90%" contentPaddingBottom="30px">
      <Divider name="Generic Talk Actions" />

      <Box renderContentOnly childMinWidth="40%">
        {Object.keys(SHARED_DATA?.dialogOptions).map((sharedId) => {
          const isActive =
            dialogActions?.sharedDialogOptionIds?.includes(sharedId);

          const getUpdatedSharedDialogOptionIds = () => {
            if (isActive) {
              return (dialogActions?.sharedDialogOptionIds || []).filter(
                (id) => id !== sharedId
              );
            }
            return [...(dialogActions?.sharedDialogOptionIds || []), sharedId];
          };

          return (
            <Checkbox
              key={sharedId}
              label={capitalizeFirstLetter(
                sharedId.replace(/([a-z])([A-Z])/g, "$1 $2")
              )}
              value={isActive}
              onChange={(v) =>
                setDialogActions({
                  ...dialogActions,
                  sharedDialogOptionIds: getUpdatedSharedDialogOptionIds(),
                })
              }
            />
          );
        })}
      </Box>

      <Divider name="Custom Talk Actions" />
      <Box renderContentOnly childMinWidth="90%">
        {dialogActions?.customDialogOptions?.map(
          (dialogOptionArray, dialogOptionIndex) => {
            const updateDialogOption = (updatedDialogOption) => {
              setDialogActions({
                ...dialogActions,
                customDialogOptions: dialogActions.customDialogOptions.map(
                  (d, i) => (i === dialogOptionIndex ? updatedDialogOption : d)
                ),
              });
            };

            const onChange = (updatedString, arrayIndex) => {
              const updatedDialogOption = [...dialogOptionArray].map((d, i) =>
                i === arrayIndex ? updatedString : d
              );
              updateDialogOption(updatedDialogOption);
            };

            const onAdd = () => {
              const newLine: TranslatedString = { en: "", cz: "" };
              const updatedDialogOption = [...dialogOptionArray, newLine];
              updateDialogOption(updatedDialogOption);
            };

            const onDelete = () => {
              const updatedDialogOption = [...dialogOptionArray];
              updatedDialogOption.pop();
              if (!updatedDialogOption?.length) {
                deleteCustomDialogOption(dialogOptionIndex);
              } else {
                updateDialogOption(updatedDialogOption);
              }
            };

            return (
              <Box
                key={dialogOptionIndex}
                label={`Dialog ${dialogOptionIndex + 1}`}
                level={2}
                isOpen
                childMinWidth="90%"
                minWidth="90%"
                gap="20px"
              >
                {dialogOptionArray.map((dialog, arrayIndex) => (
                  <TranslatedStringInput
                    key={arrayIndex}
                    value={dialog}
                    onChange={(updatedString) =>
                      onChange(updatedString, arrayIndex)
                    }
                  />
                ))}

                <DialogButtons>
                  <Button onClick={onDelete}>Delete</Button>
                  <Button onClick={onAdd}>Add</Button>
                </DialogButtons>
              </Box>
            );
          }
        )}

        <Button onClick={addCustomDialogOption}>Add Dialog Option</Button>
      </Box>
    </Box>
  );
};

export default ActionsTalk;
