import SceneEditor from "game-engine/_dev/scene-editor";
import SceneEditorCreateNew from "game-engine/_dev/scene-editor-create-new";
import SceneEditorSelection from "game-engine/_dev/scene-editor-selection";
import { SceneType } from "game-engine/types";
import devToolsConfig from "game-engine/configs/devtools-config";
import { getRandomId } from "game-engine/utils";
import { getSceneConfigById } from "game-files/scenes/SCENE_CONFIGS";
import styled from "styled-components";
import { useMemo } from "react";

const Wrapper = styled.div`
  flex: 1;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  width: 100%;
  background: ${devToolsConfig.background};
`;

const NEW_SCENE_PARAM = "new";

//
// COMPONENT
//
const TabSceneEditor = () => {
  const params = new URLSearchParams(window.location.search);
  const sceneConfigId = params.get("sceneConfigId");

  const sceneConfig: SceneType = useMemo(() => {
    const scene = getSceneConfigById(sceneConfigId);
    const uniqueSceneId = getRandomId();

    if (!scene && sceneConfigId !== NEW_SCENE_PARAM) {
      console.error(`SCENE NOT FOUND (${sceneConfigId})`);
      return undefined;
    }

    return { ...scene, uniqueId: uniqueSceneId };
  }, [sceneConfigId]);

  const onNewScene = () => {
    onEditScene(NEW_SCENE_PARAM);
  };

  const onEditScene = (sceneConfigId: string) => {
    const url = new URL(window.location.href);
    url.searchParams.set("sceneConfigId", sceneConfigId);
    window.location.href = url.toString();
  };

  //
  // RENDER
  //
  return (
    <Wrapper>
      {sceneConfigId ? (
        sceneConfigId === NEW_SCENE_PARAM ? (
          <SceneEditorCreateNew />
        ) : (
          <SceneEditor sceneConfig={sceneConfig} />
        )
      ) : (
        <SceneEditorSelection
          onNewScene={onNewScene}
          onEditScene={onEditScene}
        />
      )}
    </Wrapper>
  );
};

export default TabSceneEditor;
