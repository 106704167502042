import { ActionId, Action_Effect, GameEffectFadeEnum } from "game-engine/types";

import GAME_CONFIG from "game-files/gameConfig";
import { createActionName } from ".";

type SetEffectSceneFadeProps = {
  durationSec: number;
};

type SetEffectSceneFadeReturn = () => Action_Effect;

export const sceneFadeOut: (
  props?: SetEffectSceneFadeProps
) => SetEffectSceneFadeReturn = (props) => () => {
  const durationSec =
    props?.durationSec ?? GAME_CONFIG.cinematics.fadeSceneDurationSec;

  return {
    _name: createActionName({ name: "fade-out scene" }),
    id: ActionId.effect,
    blockGui: true,
    unskippable: true,
    durationSec,
    effectFadeScene: {
      durationSec,
      type: GameEffectFadeEnum.fadeOut,
    },
  };
};

export const sceneFadeIn: (
  props?: SetEffectSceneFadeProps
) => SetEffectSceneFadeReturn = (props) => () => {
  const durationSec =
    props?.durationSec ?? GAME_CONFIG.cinematics.fadeSceneDurationSec;

  return {
    _name: createActionName({ name: "fade-in scene" }),
    id: ActionId.effect,
    blockGui: true,
    unskippable: true,
    durationSec,
    effectFadeScene: {
      durationSec,
      type: GameEffectFadeEnum.fadeIn,
    },
  };
};
