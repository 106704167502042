import {
  Direction,
  ExtendWalkPathType,
  Position,
  WalkConfigType,
} from "game-engine/types";
import { Graph, astar } from "javascript-astar";

import { getOppositeDirection } from "./distance";
import { getWalkCompressionParams } from "./walkPath";
import { printGraphWithPath } from "./matrix";

//
// GET DIRECT PATH OF GIVEN DISTANCE AND DIRECTION
//
export const getLinePath: (props: {
  walkConfig: WalkConfigType;
  startPosition?: Position;
  endPosition?: Position;
  direction: Direction;
  distance: number;
}) => Position[] = ({
  walkConfig,
  startPosition,
  endPosition,
  direction: directionRaw,
  distance,
}) => {
  const position = startPosition || endPosition;
  const direction = endPosition
    ? getOppositeDirection(directionRaw)
    : directionRaw;

  let path: Position[] = [position];

  if (distance <= 1) {
    return path;
  }

  const { everyNthHorizontal, everyNthVertical } = getWalkCompressionParams({
    walkConfig,
  });

  for (let i = 0; i < distance - 2; i++) {
    let x = position.x;
    let y = position.y;
    if (direction === Direction.left) {
      x = x - i * everyNthHorizontal;
    }
    if (direction === Direction.right) {
      x = x + i * everyNthHorizontal;
    }
    if (direction === Direction.up) {
      y = y - i * everyNthVertical;
    }
    if (direction === Direction.down) {
      y = y + i * everyNthVertical;
    }

    if (endPosition) {
      path = [{ x, y }, ...path];
    } else {
      path.push({ x, y });
    }
  }
  return path;
};

//
// GET SHORTEST PATH IN MATRIX WITH OBSTACLES
//
export const getPathInMatrix: (props: {
  matrix: number[][];
  from: Position;
  to: Position;
  walkConfig?: WalkConfigType;
  noDiagonalPaths?: boolean;
  printResult?: boolean;
  extendPath?: ExtendWalkPathType;
}) => Position[] = ({
  matrix: matrixRaw,
  from: fromRaw,
  to: toRaw,
  printResult,
  noDiagonalPaths,
  walkConfig,
  extendPath,
}) => {
  let matrix = matrixRaw;
  let from = fromRaw;
  let to = toRaw;

  // starting from unreachable pixel
  const fromPixel = matrix[from.y][from.x];
  if (fromPixel === 0) {
    // starting path from unreachable pixel (e.g. when switching to dev-walk-map and the character is in a non-walkable area after that)
    // this will create a path that will put the character back on the walk map
    return [to, to];
  }

  let path = [];

  try {
    // compress walk matrix by character walk config
    let everyNthHorizontal;
    let everyNthVertical;

    if (walkConfig) {
      const compressionParams = getWalkCompressionParams({ walkConfig });
      everyNthHorizontal = compressionParams.everyNthHorizontal;
      everyNthVertical = compressionParams.everyNthVertical;

      if (everyNthHorizontal || everyNthVertical) {
        from = {
          x: Math.round(from.x / everyNthHorizontal),
          y: Math.round(from.y / everyNthVertical),
        };
        to = {
          x: Math.round(to.x / everyNthHorizontal),
          y: Math.round(to.y / everyNthVertical),
        };

        const compressedMatrix = [];

        matrix.forEach((row, y) => {
          // compress matrix by including on nth rows OR the last one (otherwise paths to edges of the matrix crash)
          if (
            y % everyNthVertical === 0 ||
            y === from.y ||
            y === to.y ||
            y === matrix.length - 1
          ) {
            const compressedRow = row.filter((value, x) => {
              // compress row by including on nth cols OR the last one (otherwise paths to edges of the matrix crash)
              return (
                x % everyNthHorizontal === 0 ||
                x === from.x ||
                x === to.x ||
                x === row.length - 1
              );
            });
            compressedMatrix.push(compressedRow);
          }
        });
        matrix = compressedMatrix;
      }
    }

    const graph = new Graph(matrix, { diagonal: !noDiagonalPaths });
    let start = graph.grid[from.y][from.x];
    let end = graph.grid[to.y][to.x];

    // If the end position is unreachable, search for nearby walkable pixels
    // (note that the original 'to' position is supposed to be always reachable, because it is determined by user click)
    // -> compression likely messed the end position, so find the nearest one
    if (end.weight === 0) {
      end = findNearbyWalkablePixel(graph, to);
    }

    const result = astar.search(graph, start, end);

    path = result.map((r, i) => {
      const x = r.y; // NOTE: swapping of x and y is intentional
      const y = r.x; // NOTE: swapping of x and y is intentional

      if (walkConfig) {
        return {
          x: x * everyNthHorizontal,
          y: y * everyNthVertical,
        };
      }
      return { x, y };
    });

    if (path?.length > 0) {
      if (extendPath) {
        const extendedPath = getLinePath({
          walkConfig,
          startPosition: path[path.length - 1],
          direction: extendPath.direction,
          distance: extendPath.distance,
        });
        path = [...path, ...extendedPath];
      }

      if (printResult) {
        printGraphWithPath(graph, from, to, path);
      }
      return path;
    }
  } catch (e) {
    console.error(e);
    return null;
  }

  return [];
};

/**
 * Finds the nearest walkable pixel around the given position in the grid.
 */
const findNearbyWalkablePixel = (graph, to: Position) => {
  const searchRadius = 2; // You can adjust this radius
  for (let y = to.y - searchRadius; y <= to.y + searchRadius; y++) {
    for (let x = to.x - searchRadius; x <= to.x + searchRadius; x++) {
      if (
        y >= 0 &&
        y < graph.grid.length &&
        x >= 0 &&
        x < graph.grid[0].length
      ) {
        const neighbor = graph.grid[y][x];
        if (neighbor.weight !== 0) {
          return neighbor; // Found a walkable neighbor
        }
      }
    }
  }
  return graph.grid[to.y][to.x]; // Fallback to original 'to' position if no walkable neighbor found
};
