import {
  getCode_CommentSectionTitle,
  getCode_CommentSubSectionTitle,
  prettifyCode,
} from "game-engine/utils";

import { EditorType } from "..";

////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
//
// GET SCENE LAYOUTS CODE
//
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////

export const getSceneLayoutCode: (props: { editor: EditorType }) => string = ({
  editor,
}) => {
  if (!editor) {
    return "";
  }

  const codeLines = [];

  // Imports
  codeLines.push(
    `import { Direction, SceneType, ScenesLayoutsDefType } from "game-engine/types";`
  );
  codeLines.push(``);
  codeLines.push(
    `import SCENE_CONFIGS from 'game-files/scenes/SCENE_CONFIGS';`
  );
  codeLines.push(`import { getGameScenesData } from 'game-engine/utils'`);

  codeLines.push(
    getCode_CommentSubSectionTitle(
      "NOTICE: THIS CODE SHOULDN'T BE EDITED MANUALLY, BUT VIA SCENES-LAYOUT-EDITOR AND EXPORTED"
    )
  );

  //
  // SCENES_LAYOUTS_DEF
  //
  codeLines.push(`export const SCENES_LAYOUTS_DEF: ScenesLayoutsDefType = {`);

  // scenesLayouts
  codeLines.push(getCode_CommentSectionTitle("Scene Layout Grids"));
  codeLines.push(`scenesLayouts: [`);

  editor.gameScenesData.scenesLayoutsDef.scenesLayouts.forEach(
    (scenesLayout) => {
      codeLines.push(getCode_CommentSubSectionTitle("Scene Layout"));
      codeLines.push(`{`);
      codeLines.push(`id: '${scenesLayout.id}',`);
      codeLines.push(`grid: [`);

      scenesLayout.grid.forEach((row) => {
        codeLines.push(`[`);
        row.forEach((v) => {
          if (!v) {
            codeLines.push("null,");
          } else {
            codeLines.push(`
              { 
                scene: SCENE_CONFIGS.${v.scene?.configId},
                overrideSceneId: ${
                  v.overrideSceneId ? `'${v.overrideSceneId}'` : undefined
                },
                noWalkLeft: ${v.noWalkLeft},
                noWalkRight: ${v.noWalkRight},
                noWalkUp: ${v.noWalkUp},
                noWalkDown: ${v.noWalkDown},
              },
            `);
          }
        });
        codeLines.push(`],`); // grid row closing bracket
      });

      codeLines.push(`]`); // grid closing bracket
      codeLines.push(`},`); // sceneLayout closing brace
    }
  );

  codeLines.push(`],`); // scenesLayouts - closing bracket

  // sceneLinks
  codeLines.push(
    getCode_CommentSectionTitle("Scene Links (links between grids above)")
  );
  codeLines.push(`sceneLinks: [`);
  editor.gameScenesData.scenesLayoutsDef.sceneLinks.forEach((sceneLink) => {
    codeLines.push(getCode_CommentSubSectionTitle("Scene Link"));
    codeLines.push(`
      {
        sceneLinkId: '${sceneLink.sceneLinkId}',
        scene1: {
          uniqueId: '${sceneLink.scene1?.uniqueId}',
          direction: Direction.${sceneLink.scene1.direction},
        },
        scene2: {
          uniqueId: '${sceneLink.scene2?.uniqueId}',
          direction: Direction.${sceneLink.scene2.direction},
        },
      },
    `);
  });
  codeLines.push(`],`); // sceneLinks - closing bracket
  codeLines.push(`};`); // SCENES_LAYOUTS_DEF - closing brace

  //
  // SCENES_LAYOUTS
  //
  codeLines.push(getCode_CommentSectionTitle("GENERATED SCENES LAYOUTS"));

  codeLines.push(`
    const SCENES_LAYOUTS = getGameScenesData(SCENES_LAYOUTS_DEF, {
      logErrors: true,
    });
  `);

  //
  // getSceneByUniqueId
  //
  codeLines.push(`
    export const getSceneByUniqueId: (id: string) => SceneType = (id) => {
      if (id === undefined || id === null) {
        return null;
      }
      return SCENES_LAYOUTS.scenes.find((s) => s.uniqueId === id);
    };
  `);

  //
  // CLOSE CODE
  //
  codeLines.push(`export default SCENES_LAYOUTS;`);

  //
  // RETURN PRETTIFIED CODE
  //
  return prettifyCode(codeLines.join("\n"));
};
