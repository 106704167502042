import { AnimationFrameSequence, FrameSequenceValue } from "game-engine/types";
import { arrayLinear, arrayOf, sequenceFromTo } from "../by-types";

const frameSequenceFadeOut = ({
  framesArray,
  fadeFrameCount,
  fadeMultiplier = 1,
  framesPerFrame = 1,
}: {
  framesArray: number[];
  fadeFrameCount: number;
  fadeMultiplier?: number;
  framesPerFrame?: number;
}): number[] => {
  const totalFrames = framesArray.length;
  const fadeOutFramesStartIndex = totalFrames - fadeFrameCount;

  // Get the sequence leading up to the fade-out
  const baseSequence = framesArray.slice(0, fadeOutFramesStartIndex);

  // Get the last X frames that should be faded out
  const fadeOutFrames = framesArray.slice(fadeOutFramesStartIndex);

  // Build the fade-out part by duplicating frames with multiplier effect
  const fadedOutSequence: number[] = [];
  fadeOutFrames.forEach((frame, i) => {
    const fadeOutCount = fadeMultiplier + i;
    for (let j = 0; j < fadeOutCount; j++) {
      fadedOutSequence.push(frame);
    }
  });

  const sequence = [...baseSequence, ...fadedOutSequence];

  // OPTIONAL RETURN OF FADE-OUT SEQUENCE
  if (framesPerFrame === 1) {
    return sequence;
  }

  const smoothedSequence: number[] = [];

  sequence.forEach((frame) => {
    const lastFrame = smoothedSequence[smoothedSequence.length - 1];
    if (frame !== lastFrame) {
      for (let i = 0; i < framesPerFrame - 1; i++) {
        // Smooth sequence by adding framesPerFrame copies of individual frames
        smoothedSequence.push(frame);
      }
    }
    smoothedSequence.push(frame);
  });

  // RETURN SMOOTHED SEQUENCE
  return smoothedSequence;
};

const frameSequenceFadeIn = ({
  framesArray,
  fadeFrameCount,
  fadeMultiplier = 1,
  framesPerFrame = 1,
}: {
  framesArray: number[];
  fadeFrameCount: number;
  fadeMultiplier?: number;
  framesPerFrame?: number;
}): number[] => {
  // Reverse the sequence, apply fadeOut logic, and reverse again
  const fadedReversedSequence = frameSequenceFadeOut({
    framesArray: [...framesArray].reverse(),
    fadeFrameCount,
    fadeMultiplier,
    framesPerFrame,
  });

  // Reverse the result to get the fade-in effect
  return fadedReversedSequence.reverse();
};

//
// CONVERT DEFINED ANIMATION SEQUENCE TO FRAME INDEXES
//
export const getAnimationFrames: (props: {
  frameCount: number;
  frameSequence: AnimationFrameSequence;
}) => number[] = ({ frameCount, frameSequence }) => {
  //
  // SETTINGS
  const framesArray = arrayLinear(frameCount);
  const fadeMultiplier = 1;
  const framesPerFrame = 1;
  const framesPerFrameSmooth = 2;
  const fadeFrameCount = Math.round(frameCount * 0.5);

  //
  // CREATE FRAME SEQUENCE FROM VALUES
  const frames = [];

  frameSequence.forEach((frameItem) => {
    const firstFrame = 0;
    const lastFrame = frames[frames.length - 1] || 0;

    if (typeof frameItem === "number") {
      return frames.push(frameItem);
    }
    if ((frameItem as any).pause) {
      const repeat = (frameItem as any).pause;
      return frames.push(...arrayOf(repeat, lastFrame));
    }
    if (
      (frameItem as any).from !== undefined &&
      (frameItem as any).to !== undefined
    ) {
      const from = (frameItem as any).from;
      const to = (frameItem as any).to;
      return frames.push(
        ...sequenceFromTo(
          from === "firstFrame"
            ? firstFrame
            : from === "lastFrame"
            ? lastFrame
            : from,

          to === "firstFrame" ? firstFrame : to === "lastFrame" ? lastFrame : to
        )
      );
    }
    switch (frameItem) {
      case FrameSequenceValue.original:
        return frames.push(...framesArray);

      case FrameSequenceValue.reversed:
        return frames.push(...framesArray.reverse());

      case FrameSequenceValue.firstFrame:
        return frames.push(0);

      case FrameSequenceValue.lastFrame:
        return frames.push(frameCount - 1);

      case FrameSequenceValue.fadeOut:
        return frames.push(
          ...frameSequenceFadeOut({
            framesArray,
            fadeFrameCount,
            fadeMultiplier,
            framesPerFrame,
          })
        );

      case FrameSequenceValue.fadeOutSmooth:
        return frames.push(
          ...frameSequenceFadeOut({
            framesArray,
            fadeFrameCount,
            fadeMultiplier,
            framesPerFrame: framesPerFrameSmooth,
          })
        );

      case FrameSequenceValue.fadeIn:
        return frames.push(
          ...frameSequenceFadeIn({
            framesArray,
            fadeFrameCount,
            fadeMultiplier,
            framesPerFrame,
          })
        );

      case FrameSequenceValue.fadeInSmooth:
        return frames.push(
          ...frameSequenceFadeIn({
            framesArray,
            fadeFrameCount,
            fadeMultiplier,
            framesPerFrame: framesPerFrameSmooth,
          })
        );

      case FrameSequenceValue.fadeInAndOut:
        return frames.push(
          ...frameSequenceFadeIn({
            framesArray: frameSequenceFadeOut({
              framesArray,
              fadeFrameCount: Math.round(frameCount * 0.35),
              fadeMultiplier,
              framesPerFrame,
            }),
            fadeFrameCount: Math.round(frameCount * 0.35),
            fadeMultiplier,
            framesPerFrame,
          })
        );

      case FrameSequenceValue.fadeInAndOutSmooth:
        return frames.push(
          ...frameSequenceFadeIn({
            framesArray: frameSequenceFadeOut({
              framesArray,
              fadeFrameCount: Math.round(frameCount * 0.35),
              fadeMultiplier,
              framesPerFrame: framesPerFrameSmooth,
            }),
            fadeFrameCount: Math.round(frameCount * 0.35),
            fadeMultiplier,
            framesPerFrame: framesPerFrameSmooth,
          })
        );
    }
  });

  return frames;
};
