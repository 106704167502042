import {
  SceneLayerByCurrentObjective,
  SceneLayerDataType,
} from "game-engine/types";

import { GameEventsType } from "game-files/gameEvents";
import { GameObjective } from "game-files/gameObjectives";
import { getDataByCurrentObjective } from "./getters";

export const getSceneLayerByCurrentObjective = (props: {
  dataByCurrentObjective: SceneLayerByCurrentObjective;
  currentObjective: GameObjective;
  events: GameEventsType;
}) => {
  return getDataByCurrentObjective<SceneLayerDataType>(props, {});
};
