import { EditorType } from "game-engine/_dev/scene-editor-create-new";
import { prettifyCode } from "./code-formatting";

//
// SCENE AUDIO
//
export const getCode_SceneDynamicLighting = (editor: EditorType) => {
  const codeLines = [];

  codeLines.push(`
    dynamicLighting: undefined,
    `);

  if (!editor.noCodeExamples) {
    codeLines.push(`
    /*
    // EXAMPLE OF DYNAMIC LIGHTING
    dynamicLighting: {
      [GameObjective.GAME_START]: {
        deathByDarkness: true,
        vignette: GAME_CONFIG.scene.dynamicLighting.vignette,
        filters: GAME_CONFIG.scene.dynamicLighting.filters,
      },
    },
    */
  `);
  }

  return prettifyCode(codeLines.join("\n"));
};
