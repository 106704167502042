import ActionQueueList from "../../../_dev/basic-components/ActionQueueList";
import { ActionType } from "game-engine/types";
import Box from "../../../_dev/basic-components/Box";
import { DevEventType } from "game-engine/types/devTools";
import { DevToolsContext } from "game-engine";
import GameItems from "../../../_dev/dev-components/GameItems";
import SceneInfo from "../../../_dev/dev-components/SceneInfo";
import Sidebar from "../../../_dev/basic-components/Sidebar";
import { getSceneByUniqueId } from "game-files/scenes/SCENE_LAYOUTS";
import { useContext } from "react";
import useGame from "game-engine/hooks/useGame";

const SidebarRight = () => {
  const { gamePlay } = useGame();
  const devTools = useContext(DevToolsContext);

  const actionQueue: ActionType[] = devTools.state?.actions?.actionQueue || [];

  const actionOrEventHistory: (ActionType | DevEventType)[] =
    devTools.state?.actions.actionOrEventHistory || [];

  //
  // RENDER
  //
  return (
    <Sidebar
      name="Info"
      position="right"
      width="420px"
      forcedHeight="100vh"
      muteButton
      isOpen
    >
      <Box label="Current Scene" level={1} childMinWidth="80%">
        <SceneInfo
          scene={getSceneByUniqueId(gamePlay.state.currentScene?.uniqueSceneId)}
          isOpen
        />
      </Box>

      <GameItems />

      <Box
        label="Action Queue"
        level={1}
        isOpen
        childMinWidth="80%"
        contentPaddingTop="15px"
      >
        <ActionQueueList
          actionQueue={actionQueue}
          actionOrEventHistory={actionOrEventHistory}
        />
      </Box>
    </Sidebar>
  );
};

export default SidebarRight;
