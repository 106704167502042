/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useRef } from "react";

import { GamePlayCurrentSkillsType } from "game-engine/types";
import { SkillId } from "game-files/common/ids";
import useGame from "./useGame";

export type SkillWatcherFunc = (skillId: SkillId) => void;

export const useSkills = (props: {
  onSkillStart?: SkillWatcherFunc;
  onSkillStop?: SkillWatcherFunc;
  onSkillReset?: SkillWatcherFunc;
}) => {
  const { onSkillStart, onSkillStop, onSkillReset } = props;

  const { gamePlay } = useGame();

  // Initialize prevSkillsRef with the first value of gamePlay.state.currentSkills
  const prevSkillsRef = useRef<GamePlayCurrentSkillsType>({
    ...(gamePlay.state.currentSkills || {}),
  });

  //
  // WATCH FOR SKILLS CHANGES
  //
  useEffect(() => {
    const currentSkills = gamePlay.state.currentSkills || {};

    // Get the previous skills state
    const prevSkills = prevSkillsRef.current;

    // Loop through the current skills to detect start and stop
    if (onSkillStart || onSkillStop) {
      for (const _skillId in currentSkills) {
        const skillId = _skillId as SkillId;

        const currentSkill = currentSkills[skillId];
        const prevSkill = prevSkills[skillId];

        // Skill started (isActive went from false -> true)
        if (onSkillStart && currentSkill?.isActive && !prevSkill?.isActive) {
          onSkillStart(skillId);
        }

        // Skill stopped (isActive went from true -> false)
        if (onSkillStop && !currentSkill?.isActive && prevSkill?.isActive) {
          onSkillStop(skillId);
        }
      }
    }

    // Loop through the previous skills to detect removed skills
    if (onSkillReset) {
      for (const _skillId in prevSkills) {
        const skillId = _skillId as SkillId;

        // Ensure that the skill is removed (no longer present in currentSkills)
        if (!(skillId in currentSkills)) {
          onSkillReset(skillId);
        }
      }
    }

    // Update the previous skills ref with a shallow copy of currentSkills
    prevSkillsRef.current = { ...currentSkills };
  }, [gamePlay.state.currentSkills]);
};
