import { ActionId, Action_Event } from "game-engine/types";
import { GameEvent, GameEventsType } from "game-files/gameEvents";

import { createActionName } from ".";

//
// CREATE ACTION
//
type SetEventsProps = (events: GameEventsType) => GameEventsType; // the action will take func that will provide
//                                                                   current events and return updated events
type SetEventsReturn = () => Action_Event;

export const setEvents: (props: SetEventsProps) => SetEventsReturn =
  (getUpdatedEvents) => () => {
    return {
      _name: createActionName({
        name: "update game events",
      }),
      id: ActionId.event,
      getUpdatedEvents,
      blockGui: true,
      unskippable: true,
    };
  };

type SetEventProps = { key: GameEvent; value: any };

export const setEvent: (props: SetEventProps) => () => Action_Event =
  (props) => () => {
    return {
      _name: createActionName({
        name: `set game event ${props.key} = ${props.value}`,
      }),
      id: ActionId.event,
      setEvent: props,
      blockGui: true,
      unskippable: true,
    };
  };
