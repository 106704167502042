import { ActionId, Action_Execute } from "game-engine/types";

import { createActionName } from ".";

//
// CREATE BASIC FACING ACTION
//
type ExecuteProps = { funcName: string; func: ExecuteFunc; blockGui?: boolean };

type ExecuteFunc = () => void;

type ExecuteReturn = () => Action_Execute;

export const execute: (props: ExecuteProps) => ExecuteReturn =
  ({ funcName, func, blockGui }) =>
  () => {
    return {
      id: ActionId.execute,
      _name: createActionName({
        name: `execute`,
        value: `'${funcName}'`,
      }),
      execute: func,
      blockGui: blockGui,
      unskippable: blockGui,
    };
  };
