/* eslint-disable react-hooks/exhaustive-deps */

import {
  BlendingMode,
  FrameSequenceValue,
  ImageFilters,
  SpriteConfigType,
} from "game-engine/types";
import { getAnimationFrames, getRandomIndex } from "game-engine/utils";
import { useCallback, useEffect, useRef, useState } from "react";

import GAME_CONFIG from "game-files/gameConfig";
import SpriteFrame from "../SpriteFrame";
import useGame from "game-engine/hooks/useGame";

export type SpriteAnimatedSingleProps = {
  src: any;
  spriteConfig: SpriteConfigType;
  onImageLoaded?: () => void;
  onClick?: (e?: any) => void;
  onAnimationEnd?: () => void;
  controlledAnimation?: { playAnimation: boolean };
  frameIndex?: number;

  randomFrames?: boolean;
  getSpriteFrameRenderIndex?: (frameIndex: number) => number;

  loggerString?: string;
  renderFill?: boolean;
  fillColor?: string;
  renderOutline?: boolean;
  outlineColor?: string;
  imageRef?: any;
  blendingMode?: BlendingMode;
  filters?: ImageFilters;
};

const SpriteAnimatedSingle = (props: SpriteAnimatedSingleProps) => {
  const { loggerString, imageRef } = props;

  const { logger } = useGame();

  // Ensure the spriteConfig always has valid values from either props or defaults
  const spriteConfig: SpriteConfigType = {
    ...GAME_CONFIG.sprites.defaultSpriteConfig,

    ...Object.fromEntries(
      Object.entries(props.spriteConfig || {}).map(([key, value]) => [
        key,
        value ?? GAME_CONFIG.sprites.defaultSpriteConfig[key],
      ])
    ),

    frameSequence:
      props.spriteConfig?.frameSequence?.length > 0
        ? props.spriteConfig?.frameSequence
        : [FrameSequenceValue.original],
  };

  const isInfiniteLoop = spriteConfig.playCount < 0;

  const [imageLoaded, setImageLoaded] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isStopped, setIsStopped] = useState(false);

  // Generate animation frames
  const animationFrames = getAnimationFrames({
    frameCount: spriteConfig.frameCount,
    frameSequence: spriteConfig.frameSequence,
  });
  const totalFrameCount = animationFrames.length;
  const [animationFrame, setAnimationFrame] = useState(0); // Current frame for rendering

  const [currentFrameIndex, setCurrentFrameIndex] = useState(
    props.frameIndex ?? 0
  );
  const currentFrameIndexRef = useRef(currentFrameIndex); // Ref to persist frame index between renders

  // Track the current frame index
  useEffect(() => {
    currentFrameIndexRef.current = currentFrameIndex;

    let renderedFrame = 0;
    if (props.getSpriteFrameRenderIndex) {
      renderedFrame = props.getSpriteFrameRenderIndex(currentFrameIndex);
    } else if (props.randomFrames) {
      renderedFrame = getRandomIndex(spriteConfig.frameCount);
    } else {
      renderedFrame = animationFrames[currentFrameIndex];
    }
    setAnimationFrame(renderedFrame);
  }, [currentFrameIndex]);

  const [currentPlayCount, setCurrentPlayCount] = useState(0);
  const currentPlayCountRef = useRef(0); // Ref to persist play count between renders

  // Track the current play count
  useEffect(() => {
    currentPlayCountRef.current = currentPlayCount;
  }, [currentPlayCount]);

  const timerRef = useRef<NodeJS.Timeout>();

  // UPDATE FRAME CALLBACK (memoized)
  const updateFrame = useCallback(() => {
    const nextFrameIndex = (currentFrameIndexRef.current + 1) % totalFrameCount;

    // Handle play count and animation completion
    if (nextFrameIndex === 0) {
      const nextPlayCount = currentPlayCountRef.current + 1;
      setCurrentPlayCount(nextPlayCount);

      // STOP ANIMATION after max play count
      if (
        spriteConfig.playCount > 0 &&
        nextPlayCount >= spriteConfig.playCount
      ) {
        setIsStopped(true);
        return;
      }
    }

    // Only update if there's an actual change in frame
    if (nextFrameIndex !== currentFrameIndexRef.current) {
      setCurrentFrameIndex(nextFrameIndex);
    }
  }, [totalFrameCount, spriteConfig.playCount]);

  // PLAY ANIMATION (interval management)
  useEffect(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    // Start frame updates if playing animation
    if (isPlaying && spriteConfig.frameCount > 1) {
      timerRef.current = setInterval(
        updateFrame,
        spriteConfig.frameDurationMilliseconds
      );
    }

    return () => {
      clearTimeout(timerRef.current); // Clear the timer when effect re-runs
    };
  }, [isPlaying]);

  // STOP ANIMATION logic
  useEffect(() => {
    if (isStopped) {
      setIsStopped(false); // Reset stopper
      setIsPlaying(false); // Stop the animation

      props.onAnimationEnd?.(); // Call onAnimationEnd callback
    }
  }, [isStopped]);

  // START ANIMATION based on controlled props and state
  useEffect(() => {
    const isPlayable =
      (!props.controlledAnimation ||
        props.controlledAnimation?.playAnimation) &&
      imageLoaded &&
      spriteConfig.frameCount > 1 &&
      (isInfiniteLoop || spriteConfig.playCount > 0);

    if (isPlayable) {
      // Start animation only if it's not already playing
      if (!isPlaying) {
        setCurrentFrameIndex(0);
        setCurrentPlayCount(0);
        setIsPlaying(true);
        logger.graphics(`Animation started (${loggerString})`, props);
      }
    } else {
      setIsPlaying(false); // Stop animation
    }
  }, [
    imageLoaded,
    props.controlledAnimation?.playAnimation,
    isInfiniteLoop,
    spriteConfig.playCount,
    spriteConfig.frameCount,
  ]);

  // Handle image loaded event
  const handleImageLoaded = () => {
    setImageLoaded(true); // Mark the image as loaded
    props.onImageLoaded?.(); // Trigger onImageLoaded callback
  };

  // Render the SpriteFrame component
  return (
    <SpriteFrame
      src={props.src}
      spriteConfig={spriteConfig}
      frameIndex={animationFrame}
      renderFill={props.renderFill}
      renderOutline={props.renderOutline}
      outlineColor={props.outlineColor}
      fillColor={props.fillColor}
      onClick={props.onClick}
      onImageLoaded={handleImageLoaded}
      imageRef={imageRef}
      blendingMode={props.blendingMode}
      filters={props.filters}
    />
  );
};

export default SpriteAnimatedSingle;
