//
// GAME EVENTS
//
export enum GameEvent {
  "test_treeClickCount" = "test_treeClickCount",
  "test_testCharacterHealthy" = "test_testCharacterHealthy",
  "test_sickTreeState" = "test_sickTreeState",
  "test_sickTreeState_HealingSkill" = "test_sickTreeState_HealingSkill",
  "test_burningBush" = "test_burningBush",

  "mainCharacterIsPoisoned" = "mainCharacterIsPoisoned",
}

export type GameEventsType = { [key in GameEvent]: any };

export const GAME_EVENTS = {
  [GameEvent.test_treeClickCount]: 0,
  [GameEvent.test_testCharacterHealthy]: false,
  [GameEvent.test_sickTreeState]: 0, // 0 (sick), 1 (transition 1), 2 (transition 2), 3 (healed)
  [GameEvent.test_sickTreeState_HealingSkill]: 0, // 0 (sick), 1 (transition 1), 2 (transition 2), 3 (healed)
  [GameEvent.test_burningBush]: 0, // 0 (fire), 1 (transition), 2 (frozen)

  [GameEvent.mainCharacterIsPoisoned]: false,
};
