import { SoundConfigType } from "game-engine/types";

// Declare require.context for TypeScript
declare const require: {
  context: (
    directory: string,
    useSubdirectories: boolean,
    regExp: RegExp
  ) => {
    keys: () => string[];
    (id: string): string;
  };
};

// Dynamic import of all .mp3 files from current directory
const audioFilesContext = require.context("./", false, /\.mp3$/);

// Create a mapping of file names to imported mp3 files
const audioFiles = audioFilesContext.keys().reduce((acc, fileName) => {
  const key = fileName.replace("./", "").replace(".mp3", "");
  acc[key] = audioFilesContext(fileName);
  return acc;
}, {} as Record<string, string>);

const getSoundFile = (filename: string) => {
  const file = audioFiles[filename];
  if (!file) {
    console.error(`SOUND FILE '${filename}' IS MISSING!`);
  }
  return file;
};

export const createSoundConfig: (props: {
  filename: string;
  volume?: number;
}) => SoundConfigType = ({ filename, volume }) => {
  return {
    id: filename,
    src: getSoundFile(filename),
    volume: volume ?? 1,
  };
};
