/* eslint-disable react-hooks/exhaustive-deps */

import {
  CharacterRenderType,
  Position,
  SceneBackgroundType,
  SceneCharacterDataType,
  SceneType,
} from "game-engine/types";
import { Group, Rect } from "react-konva";
import {
  PixelMatrixType,
  getCharacterDimensions,
  getDistance,
  getItemDimensions,
} from "game-engine/utils";
import { useCallback, useEffect, useRef, useState } from "react";

import GAME_CONFIG from "game-files/gameConfig";
import Image from "game-engine/components/basic-elements/Image";
import Konva from "konva";
import Pixels from "game-engine/components/basic-elements/Pixels";
import { getItemConfigById } from "game-files/items/ITEM_CONFIGS";
import useGame from "game-engine/hooks/useGame";
import useKonvaImageData from "game-engine/hooks/useKonvaImageData";

const useDropMap = (props: {
  scene: SceneType;
  sceneBackground: SceneBackgroundType;
  mainCharacter: SceneCharacterDataType;
  mainCharacterRender: CharacterRenderType;
  sceneCharacters: SceneCharacterDataType[];
  onDropMapImageLoaded: () => void;
}) => {
  const {
    scene,
    sceneCharacters,
    sceneBackground,
    mainCharacter,
    mainCharacterRender,
  } = props;
  const { gameItems, engineConfig, gameFns } = useGame();

  const [imageLoaded, setImageLoaded] = useState(false);
  const dropMapRef = useRef<Konva.Image>();
  const dropMap = sceneBackground?.dropMap;
  const isIgnored = engineConfig.state.ignoreDropMap || !dropMap?.src;
  const [dropMatrix, setDropMatrix] = useState<number[][]>();

  const { getPixelMatrix } = useKonvaImageData({
    ref: dropMapRef,
    imageLoaded,
  });

  //
  // ON-CLICK EVENTS
  //
  const onDropItem = (e) => {
    const clickedPosition = gameFns.getClickedScenePosition(e);
    dropItemInScene(clickedPosition);
  };

  const onDropNotAvailable = () => {
    console.warn("NO DROP POSITION AVAILABLE");

    // TODO - play a sound that indicates that item cannot be dropped (not enough space, no drop map etc.)
  };

  //
  // ON IMAGE LOADED
  //
  const onImageLoaded = useCallback(() => {
    setImageLoaded(true);
    props.onDropMapImageLoaded();
  }, [props]);

  //
  // GENERATE DROP MATRIX
  //
  useEffect(() => {
    generateDropMatrix();
  }, [
    gameItems.state.itemsInScene[scene.uniqueId],
    imageLoaded,
    isIgnored,
    mainCharacter,
  ]);

  const generateDropMatrix = () => {
    if (!imageLoaded) return;

    let matrix: number[][] = getPixelMatrix().map((row) =>
      row.map((val) => (isIgnored ? 1 : val === 0 ? 0 : 1))
    );

    if (!matrix) return;

    const paddingY = 10;
    const paddingX = 10;
    const width = matrix[0].length;
    const height = matrix.length;

    // Remove padding
    for (let i = 0; i < paddingY; i++) {
      matrix[i].fill(0); // Zero out the row at the top
      matrix[height - 1 - i].fill(0); // Zero out the row at the bottom
    }
    for (let i = paddingY; i < height - paddingY; i++) {
      matrix[i].fill(0, 0, paddingX); // Zero out columns at the left
      matrix[i].fill(0, width - paddingX); // Zero out columns at the right
    }

    // Collect all removal areas
    const removalAreas = [];

    // Add items to removal areas
    gameFns.getItemsInScene(scene.uniqueId).forEach((item) => {
      const itemConfig = getItemConfigById(item.configId);
      const { width, height } = getItemDimensions({
        itemConfig,
        decayIndex: item.sceneChangeCounter,
      });

      removalAreas.push({
        x: item.inScene.position.x,
        y: item.inScene.position.y + (itemConfig.render.offsetY || 0),
        width: width + 15,
        height: height + 20,
      });
    });

    // Add characters to removal areas
    const characters = [mainCharacter, ...(sceneCharacters || [])];
    characters.forEach((character) => {
      const { width, height } = getCharacterDimensions(mainCharacterRender);

      removalAreas.push({
        x: character.position.x,
        y:
          character.position.y -
          height / 2 +
          (mainCharacterRender.offsetY || 0) +
          (mainCharacterRender.default.offsetY || 0),
        width: width + 10,
        height: height + 10,
      });
    });

    // Remove all areas from the matrix in a single pass
    matrix = removeDropAreasInSinglePass(matrix, removalAreas);

    setDropMatrix(matrix);
  };

  // Optimize removal by iterating over the matrix once and applying all removal areas
  const removeDropAreasInSinglePass = (
    matrix: number[][],
    areas: { x: number; y: number; width: number; height: number }[]
  ) => {
    const height = matrix.length;
    const width = matrix[0].length;

    for (let y = 0; y < height; y++) {
      for (let x = 0; x < width; x++) {
        for (let i = 0; i < areas.length; i++) {
          const area = areas[i];
          const startX = Math.floor(area.x - area.width / 2);
          const endX = Math.floor(area.x + area.width / 2);
          const startY = Math.floor(area.y - area.height / 2);
          const endY = Math.floor(area.y + area.height / 2);

          if (x >= startX && x <= endX && y >= startY && y <= endY) {
            matrix[y][x] = 0;
            break; // If it's inside an area, move on to the next pixel
          }
        }
      }
    }
    return matrix;
  };

  //
  // POSITION UTILS
  //
  const isPositionInDropMap = useCallback(
    (position: Position) => {
      try {
        return dropMatrix[position.y][position.x] > 0;
      } catch {
        return false;
      }
    },
    [dropMatrix]
  );

  const getAvailableDropPositions = useCallback(() => {
    const availablePositions = [];
    dropMatrix.forEach((row, y) =>
      row.forEach((val, x) => {
        if (val > 0) {
          availablePositions.push({ x, y });
        }
      })
    );
    return availablePositions;
  }, [dropMatrix]);

  const getNearestDropPosition = (clickedPosition: Position) => {
    if (isPositionInDropMap(clickedPosition)) {
      return clickedPosition;
    }

    const availablePositions = getAvailableDropPositions();
    if (!availablePositions.length) return null;

    let closestPosition = null;
    let closestDistance = Infinity;

    availablePositions.forEach((p) => {
      const distance = getDistance(clickedPosition, p);
      if (distance < closestDistance) {
        closestPosition = p;
        closestDistance = distance;
      }
    });

    return closestPosition;
  };

  //
  // DROP ITEM IN SCENE
  //
  const dropItemInScene = (position: Position) => {
    if (isPositionInDropMap(position)) {
      return gameFns.dropItemInScene({
        item: gameItems.state.itemInCursor,
        position,
      });
    }

    const positionInDrop = getNearestDropPosition(position);
    if (positionInDrop) {
      return gameFns.dropItemInScene({
        item: gameItems.state.itemInCursor,
        position: positionInDrop,
        animation: { from: position },
      });
    }

    onDropNotAvailable();
  };

  //
  // RENDER FUNC
  //
  const renderDropMap = () => (
    <Group
      listening={!!gameItems.state.itemInCursor}
      opacity={engineConfig.state.renderDropMap ? 0.4 : 0}
    >
      <Rect
        x={0}
        y={0}
        width={GAME_CONFIG.window.canvasDimensions.x}
        height={GAME_CONFIG.window.canvasDimensions.y}
        fill="#fff"
        opacity={0}
      />

      {/* image is needed to get initial matrix from it, BUT IT IS NOT USED FOR CLICKING */}
      <Image
        imageRef={dropMapRef}
        src={dropMap?.src}
        opacity={0}
        listening={false}
        onImageLoaded={onImageLoaded}
      />

      <Pixels matrix={dropMatrix as PixelMatrixType} onClick={onDropItem} />
    </Group>
  );

  return { renderDropMap, dropItemInScene };
};

export default useDropMap;
