import { prettifyCode } from "./code-formatting";

//
// SCENE CHARACTER
//
export const getCode_SceneCharacter = () => {
  const codeLines = [];

  codeLines.push(`
  {
    config: TestCharacterConfig,
    dataByCurrentObjective: {
      [GameObjective.GAME_START]: {
        position: { x: 100, y: 100 },
        facing: Direction.right,

       ${getCode_SceneCharacter_OnClickOverride()}
       
       ${getCode_SceneCharacter_Conditional()}
      },
    },
  },`);

  return prettifyCode(codeLines.join("\n"));
};

//
// SCENE CHARACTER - CONDITIONAL
//
export const getCode_SceneCharacter_Conditional = () => {
  const codeLines = [];

  codeLines.push(`
    // EXAMPLE OF CONDITIONAL CHARACTER OPTIONS
    conditional: ({ events }) => [

      {
        condition: events.test_treeClickCount > 3,
        state: {
          facing: Direction.left,
          position: { x: 200, y: 100 },
        },
      },
      
    ],`);

  return prettifyCode(codeLines.join("\n"));
};

//
// SCENE CHARACTER - ON CLICK OVERRIDE
//
export const getCode_SceneCharacter_OnClickOverride = () => {
  const codeLines = [];

  codeLines.push(`onClickOverride: {
                    actionsByCurrentObjective: {
                      [GameObjective.GAME_START]: {
                        actions: [],
                        actionsWithItem: {
                          default: [],
                          // EXAMPLE OF ASSIGNING DIFFERENT ACTIONS TO SPECIFIC ITEMS
                          // [ItemConfigId.Test_Key]: [],
                          // [ItemConfigId.Test_Flask]: [],
                          // [ItemConfigId.Test_FlaskWater]: [],
                        },
                      },
                    },
                  },`);

  return prettifyCode(codeLines.join("\n"));
};
