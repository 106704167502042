import { useEffect, useState } from "react";

import { ACTION } from "game-engine/utils";
import { ActionId } from "game-engine/types";
import Box from "../../basic-components/Box";
import TextInput from "game-engine/_dev/basic-components/inputs/TextInput";
import styled from "styled-components";

const FuncList = styled.ul`
  list-style-type: square; /* Choose your bullet type (disc, circle, square, etc.) */
  padding-left: 24px;
`;

const FuncItem = styled.li`
  font-size: 15px;
  margin-bottom: 5px;
  opacity: 0.6;

  /* Style the bullet (dot) */
  &::marker {
    font-size: 12px;
    color: #fff6;
  }
`;

type ActionMeta = {
  actionId: string;
  name: string;
  funcString: string;
};

const UNSORTED_ACTION_ID = "UNSORTED";

type ActionsById = { [actionId in ActionId | "UNSORTED"]?: ActionMeta[] };

const GameActions = (props: { isOpen?: boolean }) => {
  const [search, setSearch] = useState("");
  const [actionsById, setActionsById] = useState<ActionsById>();

  const extractActionId = (funcString: string) => {
    const match = funcString.match(/ActionId\.(\w+)/);
    return match ? match[1] : null;
  };

  useEffect(() => {
    const filteredActionsById: ActionsById = {};

    Object.entries(ACTION)
      .map(([name, actionFunc]) => {
        const funcString = actionFunc.toString();
        let actionId = extractActionId(funcString);

        if (!actionId) {
          if (name === "conversation" || name === "talkRandom") {
            actionId = ActionId.characterTalk;
          } else if (name === "playCinematicSequence") {
            actionId = ActionId.cinematic;
          } else if (name === "poisonMainCharacter") {
            actionId = "poison";
          } else {
            actionId = UNSORTED_ACTION_ID;
          }
        }

        const actionMeta: ActionMeta = {
          actionId,
          name,
          funcString,
        };

        return actionMeta;
      })
      .filter((actionMeta) => {
        const s = search.toLowerCase();
        return (
          actionMeta.name.toLowerCase().includes(s) ||
          actionMeta.actionId.toLowerCase().includes(s)
        );
      })
      .forEach((actionMeta) => {
        if (filteredActionsById[actionMeta.actionId]) {
          filteredActionsById[actionMeta.actionId].push(actionMeta);
        } else {
          filteredActionsById[actionMeta.actionId] = [actionMeta];
        }

        return actionMeta;
      });

    setActionsById(filteredActionsById);
  }, [search]);

  //
  // RENDER
  //
  return (
    <Box
      label="Action List"
      childMinWidth="80%"
      isOpen={props.isOpen}
      level={1}
      contentPaddingBottom="50px"
    >
      <TextInput prefix="search" value={search} onChange={setSearch} />

      {Object.entries(actionsById || {})
        ?.sort((a, b) => {
          const aName = a[0];
          const bName = b[0];
          if (aName === UNSORTED_ACTION_ID) return -1;
          if (bName === UNSORTED_ACTION_ID) return 1;
          return aName < bName ? -1 : 1;
        })
        ?.map(([actionId, actions]) => {
          return (
            <Box
              key={actionId}
              label={actionId}
              childMinWidth="80%"
              isOpen={search?.length > 0 || actionId === UNSORTED_ACTION_ID}
              level={2}
            >
              <FuncList>
                {actions?.map((action) => {
                  return <FuncItem key={action.name}>{action.name}</FuncItem>;
                })}
              </FuncList>
            </Box>
          );
        })}
    </Box>
  );
};

export default GameActions;
