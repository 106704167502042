/* eslint-disable react-hooks/exhaustive-deps */

import {
  RunActionsType,
  StopActionsType,
} from "game-engine/components/game-elements/Scene/logic/useSceneActions";
import { useEffect, useState } from "react";

import GAME_CONFIG from "game-files/gameConfig";
import { Group } from "react-konva";
import GuiBackground from "game-files/gui/background.png";
import Image from "game-engine/components/basic-elements/Image";
import InfoText from "../InfoText";
import Inventory from "../Inventory";
import Menu from "../Menu";
import Skills from "../Skills";
import Transition from "game-engine/components/basic-elements/Transition";
import { TransitionFadeAnimation } from "game-engine/components/basic-elements/Transition/types/fade";
import useGame from "game-engine/hooks/useGame";

const UserInterface = (props: {
  runActionsRef: { current: RunActionsType };
  stopActionsRef: { current: StopActionsType };
  onExitGame: () => void;
  onNewGame: () => void;
  onMenuOpen?: () => void;
  onMenuClose?: () => void;
  onImagesLoaded: () => void;
}) => {
  const {
    runActionsRef,
    stopActionsRef,
    onExitGame,
    onNewGame,
    onMenuOpen,
    onMenuClose,
    onImagesLoaded,
  } = props;
  const { gamePlay } = useGame();

  const runActions = runActionsRef?.current;
  const stopActions = stopActionsRef?.current;

  //
  // IMAGE LOADER
  //
  const [backgroundImageLoaded, setBackgroundImageLoaded] = useState(false);

  useEffect(() => {
    // TODO - AFTER ADDING MORE IMAGES, WAIT FOR THEM HERE (e.g. menu button as image)
    if (backgroundImageLoaded && onImagesLoaded) {
      onImagesLoaded();
    }
  }, [backgroundImageLoaded]);

  //
  // HIDE GUI
  //
  const [animatedOpacity, setAnimatedOpacity] =
    useState<TransitionFadeAnimation>(
      // specify both 'from' and 'to' to avoid flicker on first load
      gamePlay.state.hideGui ? { from: 0, to: 0 } : { from: 1, to: 1 }
    );

  useEffect(() => {
    if (
      (gamePlay.state.hideGui?.isHidden && animatedOpacity.to !== 0) ||
      (!gamePlay.state.hideGui?.isHidden && animatedOpacity.to !== 1)
    ) {
      const durationSec =
        gamePlay.state.hideGui?.durationSec ?? GAME_CONFIG.gui.fadeDurationSec;

      setAnimatedOpacity(
        gamePlay.state.hideGui.isHidden
          ? { from: 1, to: 0, durationSec }
          : { from: 0, to: 1, durationSec }
      );
    }
  }, [gamePlay.state.hideGui?.isHidden]);

  //
  // RENDER
  //
  return (
    <Transition fade={animatedOpacity}>
      <Group>
        {/* GUI BACKGROUND */}
        <Image
          src={GuiBackground}
          listening={false}
          onImageLoaded={() => setBackgroundImageLoaded(true)}
        />

        {/* TODO - put image overlay here - WARNING - CHECK IF OVERFLOWING WALKOUT AREAS ARE STILL CLICKABLE! IMAGE SHOULDN'T BLOCK EVENTS */}

        {/* INFORMATIONAL TEXT */}
        <InfoText />

        {/* INVENTORY */}
        <Inventory stopActions={stopActions} />

        {/* SKILLS */}
        <Skills runActions={runActions} stopActions={stopActions} />

        {/* MENU */}
        <Menu
          stopActions={stopActions}
          onExitGame={onExitGame}
          onNewGame={onNewGame}
          onMenuOpen={onMenuOpen}
          onMenuClose={onMenuClose}
        />
      </Group>
    </Transition>
  );
};

export default UserInterface;
