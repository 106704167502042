import {
  GameItemsType,
  ItemConfigType,
  ItemLightSourceDecayItem,
  ItemPropInInventory,
  ItemPropInScene,
  ItemPropInSceneOverlay,
  ItemSuffixType,
  ItemType,
  SpriteConfigType,
  TranslatedString,
} from "game-engine/types";

import { getItemConfigById } from "game-files/items/ITEM_CONFIGS";
import { getRandomId } from "../by-types";

//
// ITEM ACTION LABELS
//
export const getItemLabel: (props: {
  item: ItemType;
  type: ItemSuffixType;
}) => TranslatedString = (props) => {
  const { item, type } = props;
  const itemConfig = getItemConfigById(item.configId);
  const suffix = itemConfig?.suffix[type];

  const translatedLabel = {};
  Object.entries(itemConfig?.name).forEach(([lang, itemName]) => {
    translatedLabel[lang] = `${itemName} ${suffix[lang]}`;
  });
  return translatedLabel as TranslatedString;
};

export const getItemGrabbedLabel = (item: ItemType) =>
  getItemLabel({
    item,
    type: ItemSuffixType.grab,
  });

export const getItemDroppedLabel = (item: ItemType) =>
  getItemLabel({
    item,
    type: ItemSuffixType.drop,
  });

export const getItemStoredLabel = (item: ItemType) =>
  getItemLabel({
    item,
    type: ItemSuffixType.store,
  });

//
// GENERATE A NEW ITEM WITH GLOBAL HANDLED INDEX, PUT IT INTO ITEMS AND RETURN IT (so it can be put into cursor etc.)
//
export const generateItem: (props: {
  gameItems: {
    state: GameItemsType;
    setState: (
      s: GameItemsType | ((prevState: GameItemsType) => GameItemsType)
    ) => void;
  };
  config: ItemConfigType;
  inScene?: ItemPropInScene;
  inSceneOverlay?: ItemPropInSceneOverlay;
  inInventory?: ItemPropInInventory;
  inCursor?: boolean;
}) => ItemType = ({ config, gameItems, inScene, inCursor }) => {
  const newItem: ItemType = {
    id: `${config.configId}_${getRandomId()}`,
    configId: config.configId,
    inScene,
    inCursor,
  };

  if (config.lightSourceDecay) {
    newItem.sceneChangeCounter = 0;
    newItem.sceneChangeCounterMax =
      config.lightSourceDecay.bySceneChange.length - 1;
  }

  gameItems.setState((prevState) => {
    return addItemToGameItems({ gameItemsState: prevState, item: newItem });
  });

  return newItem;
};

//
// GET ACTIVE ITEMS AS FLAT ARRAY
//
export const getActiveItemsArray = (gameItemsState: GameItemsType) => {
  const { itemsInInventory, itemsInScene, itemsInSceneOverlay, itemInCursor } =
    gameItemsState;

  const combinedItems: ItemType[] = [
    ...(itemsInScene
      ? Object.values(itemsInScene).flatMap((sceneItems) =>
          Object.values(sceneItems)
        )
      : []),
    ...(itemsInSceneOverlay
      ? Object.values(itemsInSceneOverlay).flatMap((sceneItems) =>
          Object.values(sceneItems)
        )
      : []),
    ...(itemsInInventory ? Object.values(itemsInInventory) : []),
    ...(itemInCursor ? [itemInCursor] : []),
  ].filter(Boolean);

  return combinedItems;
};

//
// GENERATE A NEW ITEM WITH GLOBAL HANDLED INDEX, PUT IT INTO ITEMS AND RETURN IT (so it can be put into cursor etc.)
//
export const addItemToGameItems: (props: {
  gameItemsState: GameItemsType;
  item: ItemType;
}) => GameItemsType = ({ gameItemsState, item }) => {
  const newState = { ...gameItemsState };

  // Ensure the scene object exists
  if (item.inScene) {
    if (!newState.itemsInScene[item.inScene.sceneId]) {
      newState.itemsInScene[item.inScene.sceneId] = {}; // Initialize if undefined
    }
    newState.itemsInScene[item.inScene.sceneId][item.id] = item;
    return newState;
  }

  // Ensure the scene overlay object exists
  if (item.inSceneOverlay) {
    if (!newState.itemsInSceneOverlay[item.inSceneOverlay.sceneId]) {
      newState.itemsInSceneOverlay[item.inSceneOverlay.sceneId] = {}; // Initialize if undefined
    }
    newState.itemsInSceneOverlay[item.inSceneOverlay.sceneId][item.id] = item;
    return newState;
  }

  // Handle inventory items
  if (item.inInventory) {
    // Initialize the inventory if it doesn't exist
    if (!newState.itemsInInventory) {
      newState.itemsInInventory = {}; // Initialize as an empty object if undefined
    }

    // Set or replace the item in the inventory
    newState.itemsInInventory[item.inInventory.index] = item; // Set item at index
    return newState;
  }

  // Handle cursor item
  if (item.inCursor) {
    newState.itemInCursor = item; // TODO: Handle existing item in cursor if necessary
    return newState;
  }

  // Handle items in trash
  if (item.inTrash) {
    newState.itemsInTrash = newState.itemsInTrash?.length
      ? [...newState.itemsInTrash, item]
      : [item];

    return newState;
  }

  // console.warn("generateItem did not produce a new item!", item);
  return newState;
};

//
// RETURN UPDATED ITEMS STATE
//
export const updateItemsState = (
  prevState: GameItemsType,
  item: ItemType,
  updatedData: Partial<ItemType>
): GameItemsType => {
  let updatedState = { ...prevState };

  // 1. Remove item from its current category
  updatedState = removeItemFromCurrentCategory(updatedState, item);

  // 2. Prepare the item with default undefined values
  const newItemData: ItemType = {
    ...item,
    inInventory: undefined,
    inScene: undefined,
    inSceneOverlay: undefined,
    inCursor: undefined,
    inTrash: undefined,
    ...updatedData,
  };

  // 3. Add item to its new category (based on the updatedData)
  updatedState = addItemToNewCategory(updatedState, newItemData);
  return updatedState;
};

//
// REMOVE ITEM FROM CATEGORY
//
const removeItemFromCurrentCategory = (
  state: GameItemsType,
  item: ItemType
): GameItemsType => {
  const { itemsInInventory, itemsInScene, itemsInSceneOverlay, itemInCursor } =
    state;

  // Remove from inventory if the item is in inventory (need to check id, because deletion is by inventory slot index)
  if (
    item.inInventory &&
    itemsInInventory[item.inInventory.index]?.id === item.id
  ) {
    const newInventory = { ...itemsInInventory };
    delete newInventory[item.inInventory.index]; // Use the index directly from the item
    state = { ...state, itemsInInventory: newInventory };
  }

  // Remove from scenes if the item is in a scene
  if (item.inScene) {
    const { sceneId } = item.inScene; // Assuming inScene contains the sceneId
    if (itemsInScene[sceneId]?.[item.id]) {
      const newSceneItems = { ...itemsInScene[sceneId] };
      delete newSceneItems[item.id];
      state = {
        ...state,
        itemsInScene: { ...itemsInScene, [sceneId]: newSceneItems },
      };
    }
  }

  // Remove from scene overlays if the item is in an overlay
  if (item.inSceneOverlay) {
    const { sceneId } = item.inSceneOverlay; // Assuming inSceneOverlay contains the sceneId
    if (itemsInSceneOverlay[sceneId]?.[item.id]) {
      const newOverlayItems = { ...itemsInSceneOverlay[sceneId] };
      delete newOverlayItems[item.id];
      state = {
        ...state,
        itemsInSceneOverlay: {
          ...itemsInSceneOverlay,
          [sceneId]: newOverlayItems,
        },
      };
    }
  }

  // Remove from cursor if the item is in the cursor
  if (itemInCursor?.id === item.id) {
    state = { ...state, itemInCursor: null };
  }

  return state;
};

//
// ADD ITEM TO CATEGORY
//
const addItemToNewCategory = (
  state: GameItemsType,
  item: ItemType
): GameItemsType => {
  const { inInventory, inScene, inSceneOverlay, inCursor, inTrash } = item;

  if (inInventory) {
    return {
      ...state,
      itemsInInventory: {
        ...state.itemsInInventory,
        [inInventory.index]: item,
      },
    };
  }

  if (inScene) {
    const { sceneId } = inScene;
    return {
      ...state,
      itemsInScene: {
        ...state.itemsInScene,
        [sceneId]: { ...(state.itemsInScene[sceneId] || {}), [item.id]: item },
      },
    };
  }

  if (inSceneOverlay) {
    const { sceneId } = inSceneOverlay;
    return {
      ...state,
      itemsInSceneOverlay: {
        ...state.itemsInSceneOverlay,
        [sceneId]: {
          ...(state.itemsInSceneOverlay[sceneId] || {}),
          [item.id]: item,
        },
      },
    };
  }

  if (inCursor) {
    return {
      ...state,
      itemInCursor: item,
    };
  }

  if (inTrash) {
    return {
      ...state,
      itemsInTrash: [...(state.itemsInTrash || []), item],
    };
  }

  return state;
};

//
// GET ITEM DIMENSIONS
//
export const getItemDimensions = (props: {
  itemConfig: ItemConfigType;
  decayIndex?: number;
}) => {
  const { itemConfig, decayIndex } = props;

  const render = getItemRender({ itemConfig, decayIndex });

  const spriteConfig: SpriteConfigType = render.spriteConfig;

  const width = spriteConfig?.frameWidth ?? render.width;
  const height = spriteConfig?.frameHeight ?? itemConfig.render.height;

  return { width, height };
};

//
// GET ITEM RENDER
//
export const getItemRender = (props: {
  itemConfig: ItemConfigType;
  decayIndex?: number;
}) => {
  const { itemConfig, decayIndex } = props;

  if (!itemConfig) {
    return undefined;
  }
  if (
    itemConfig.lightSourceDecay?.bySceneChange?.length &&
    decayIndex !== undefined &&
    decayIndex >= 0
  ) {
    const render = getItemLightSourceDecayItem({
      itemConfig,
      decayIndex,
    })?.render;
    if (render) {
      return render;
    }
  }

  return itemConfig.render;
};

//
// GET ITEM LIGHT SOURCE DECAY BY INDEX
//
export const getItemLightSourceDecayItem = (props: {
  itemConfig: ItemConfigType;
  decayIndex?: number;
}) => {
  const { itemConfig, decayIndex } = props;

  if (
    itemConfig &&
    itemConfig.lightSourceDecay?.bySceneChange?.length &&
    decayIndex !== undefined &&
    decayIndex >= 0
  ) {
    return itemConfig.lightSourceDecay.bySceneChange[
      Math.min(
        itemConfig.lightSourceDecay.bySceneChange.length - 1,
        decayIndex - 1
      )
    ] as ItemLightSourceDecayItem;
  }

  return undefined;
};
