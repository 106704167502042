import Credits from "game-engine/_dev/dev-components/Credits";
import Game from "game-engine/components/Game";
import styled from "styled-components";

const PlayerContent = styled.div`
  margin: auto;
  background: #000;
`;

const TabPlayer = () => {
  //
  // RENDER
  //
  return (
    <PlayerContent>
      <Game />
      <Credits />
    </PlayerContent>
  );
};

export default TabPlayer;
