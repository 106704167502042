import { GameItemsType, ItemInitType } from "game-engine/types";

import { ItemConfigId } from "./common/ids";

const GAME_ITEMS: GameItemsType = {
  itemInCursor: undefined,
  itemsInTrash: [],
  itemsInInventory: {},
  itemsInSceneOverlay: {},
  itemsInScene: {},
};

export const GAME_ITEMS_INIT: ItemInitType[] = [
  {
    id: "flask-01",
    configId: ItemConfigId.Test_Flask,
  },
  {
    id: "flask-02",
    configId: ItemConfigId.Test_Flask,
    inTrash: true,
  },

  {
    id: "key-01",
    configId: ItemConfigId.Test_Key,
    inScene: {
      sceneId: "DevScene02",
      position: { x: 217, y: 135 },
    },
  },

  {
    id: "flask-03",
    configId: ItemConfigId.Test_Flask,
    inScene: {
      sceneId: "DevScene02",
      position: { x: 242, y: 148 },
    },
  },
  {
    id: "flask-water-01",
    configId: ItemConfigId.Test_FlaskWater,
    inScene: {
      sceneId: "DevScene02",
      position: { x: 287, y: 140 },
    },
  },
  {
    id: "key-02",
    configId: ItemConfigId.Test_Key,
    inScene: {
      sceneId: "test-scene-01",
      position: { x: 217, y: 135 },
    },
  },
  {
    id: "key-03",
    configId: ItemConfigId.Test_Key,
    inScene: {
      sceneId: "test-scene-01",
      position: { x: 180, y: 115 },
    },
  },
  {
    id: "flask-water-02",
    configId: ItemConfigId.Test_FlaskWater,
    inScene: {
      sceneId: "test-scene-01",
      position: { x: 260, y: 140 },
    },
  },
  {
    id: "flask-water-03",
    configId: ItemConfigId.Test_FlaskWater,
    inScene: {
      sceneId: "Test_Forest_L_R_D_1_0",
      position: { x: 220, y: 140 },
    },
  },
  {
    id: "flask-toxic-01",
    configId: ItemConfigId.Test_FlaskToxic,
    inScene: {
      sceneId: "test-scene-01",
      position: { x: 140, y: 130 },
    },
  },
  {
    id: "lantern",
    configId: ItemConfigId.LanternEmpty,
    inScene: {
      sceneId: "test-scene-01",
      position: { x: 239, y: 97 },
    },
  },
];

export default GAME_ITEMS;
