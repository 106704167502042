//
// COMMENT SECTIONS
//
export const getCode_CommentSectionTitle = (title: string) => {
  const line = `/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////`;
  return [
    "",
    line,
    line,
    line,
    "//",
    `// ${title.toUpperCase()}`,
    "//",
    line,
    line,
    line,
    "",
  ].join("\n");
};

export const getCode_CommentSubSectionTitle = (title: string) => {
  const line = `/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////`;
  return ["", line, "//", `// ${title}`, "//", line, ""].join("\n");
};

//
// REMOVE COMMENTS
//
export const removeCommentsFromCode = (tsCode: string): string => {
  return (
    tsCode
      // Remove single-line comments
      .replace(/\/\/.*$/gm, "")
      // Remove multi-line comments
      .replace(/\/\*[\s\S]*?\*\//g, "")
      // Remove extra empty lines created by comment removal
      .replace(/^\s*[\r\n]/gm, "")
  );
};
