import {
  SkillStatesByCurrentObjectiveType,
  SkillStatesType,
} from "game-engine/types";

import { GameEventsType } from "game-files/gameEvents";
import { GameObjective } from "game-files/gameObjectives";
import { getDataByCurrentObjective } from "./getters";

export const getSkillStatesByCurrentObjective = (props: {
  dataByCurrentObjective: SkillStatesByCurrentObjectiveType;
  currentObjective: GameObjective;
  events: GameEventsType;
}) => {
  return getDataByCurrentObjective<SkillStatesType>(props, {} as any);
};
