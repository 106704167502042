import {
  BlendingMode,
  ImageFilters,
  Position,
  SpriteConfigType,
  SpriteDirection,
} from "game-engine/types";
import { Group, Rect } from "react-konva";

import GAME_CONFIG from "game-files/gameConfig";
import Image from "../Image";
import React from "react";

const SpriteFrame = (props: {
  src: any;
  spriteConfig: SpriteConfigType;
  frameIndex: number;

  filters?: ImageFilters;
  position?: Position;
  scale?: { x: number; y: number };
  scaleX?: number;
  scaleY?: number;
  opacity?: number;
  blendingMode?: BlendingMode;
  onClick?: (e?: any) => void;
  onImageLoaded?: () => void;

  renderOutline?: boolean;
  renderFill?: boolean;
  outlineColor?: string;
  fillColor?: string;

  imageRef?: any;
}) => {
  const { frameIndex = 0, position = { x: 0, y: 0 } } = props;

  const spriteConfig: SpriteConfigType = {
    ...GAME_CONFIG.sprites.defaultSpriteConfig,
    ...Object.fromEntries(
      Object.entries(props.spriteConfig || {}).map(([key, value]) => [
        key,
        value ?? GAME_CONFIG.sprites.defaultSpriteConfig[key],
      ])
    ),
  };

  //
  // CLIPPING
  //
  const clipX = spriteConfig.clipOffset?.x || 0;
  const clipY = spriteConfig.clipOffset?.y || 0;

  //
  // RENDER
  //
  return (
    <Group
      position={{
        x: (position?.x || 0) + (spriteConfig.originOffset?.x || 0),
        y: (position?.y || 0) + (spriteConfig.originOffset?.y || 0),
      }}
      scale={props.scale}
      scaleX={props.scaleX}
      scaleY={props.scaleY}
    >
      <Group
        clipX={clipX}
        clipY={clipY}
        clipWidth={spriteConfig.frameWidth}
        clipHeight={spriteConfig.frameHeight}
        position={{
          x: -clipX,
          y: -clipY,
        }}
      >
        <Group
          position={
            spriteConfig.direction === SpriteDirection.Horizontal
              ? {
                  x:
                    spriteConfig.frameWidth *
                    (frameIndex % spriteConfig.frameCount) *
                    -1,
                  y: 0,
                }
              : {
                  x: 0,
                  y:
                    spriteConfig.frameHeight *
                    (frameIndex % spriteConfig.frameCount) *
                    -1,
                }
          }
        >
          <Image
            src={props.src}
            onClick={props.onClick}
            onImageLoaded={props.onImageLoaded}
            opacity={props.opacity}
            blendingMode={props.blendingMode}
            //renderOutline={props.renderOutline}
            renderFill={props.renderFill}
            outlineColor={props.outlineColor}
            fillColor={props.fillColor}
            imageRef={props.imageRef}
            filters={props.filters}
          />
        </Group>
      </Group>

      {props.renderOutline && (
        <Rect
          listening={false}
          width={spriteConfig.frameWidth + 1}
          height={spriteConfig.frameHeight + 1}
          stroke={props.outlineColor || "#00f"}
          strokeWidth={1}
          position={{ x: -0.5, y: -0.5 }} // fixes stroke blur
        />
      )}
    </Group>
  );
};

export default React.memo(SpriteFrame);
