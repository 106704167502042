import Button from "game-engine/_dev/basic-components/Button";
import Sidebar from "game-engine/_dev/basic-components/Sidebar";
import { SpriteEditorType } from "../..";
import styled from "styled-components";

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 5px;
`;

const Frame = styled.div`
  position: relative;
  width: 100%;
`;

const Index = styled.div`
  position: absolute;
  top: 4px;
  right: 8px;
  font-size: 13px;
  font-weight: 500;
  color: #fff9;
`;

const Image = styled.img`
  width: 100%;
  object-fit: contain;
  background: #fff2;

  image-rendering: optimizeSpeed;
  image-rendering: -moz-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: -o-crisp-edges;
  image-rendering: pixelated;
  -ms-interpolation-mode: nearest-neighbor;
`;

const SidebarLeft = (props: {
  spriteEditor: SpriteEditorType;
  setSpriteEditor: (spriteEditor: SpriteEditorType) => void;
}) => {
  const { spriteEditor, setSpriteEditor } = props;

  return !spriteEditor ? null : (
    <Sidebar name="Sprite Frames" position="left" width="180px" isOpen>
      <Button onClick={() => setSpriteEditor(undefined)}>Clear</Button>

      <Wrapper>
        {spriteEditor.frames.map((frame, i) => {
          return (
            <Frame key={i}>
              <Image src={frame.src} />
              <Index>{i + 1}</Index>
            </Frame>
          );
        })}
      </Wrapper>
    </Sidebar>
  );
};

export default SidebarLeft;
