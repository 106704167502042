import Box, { BoxProps } from ".";
import { SpriteConfigType, SpriteDirection } from "game-engine/types";
import Thumbnail, { ThumbnailProps } from "../Thumbnail";
import styled, { css } from "styled-components";

import AssetPreview from "../AssetPreview";

const Row = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  gap: 20px;
`;

const Column = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 10px;
`;

const SubLabel = styled.span`
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.03em;
  opacity: 0.3;
`;

const CanvasThumbnail = styled.div<{ width: any; height: any }>`
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  direction: flex;
  align-items: center;
  justify-content: center;
`;

const SpriteThumbnail = styled.div<{
  width: any;
  height: any;
  spriteConfig: SpriteConfigType;
  imgFillPercentage?: string;
  frameIndex: number;
}>`
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  background: #fff5;

  image-rendering: optimizeSpeed;
  image-rendering: -moz-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: -o-crisp-edges;
  image-rendering: pixelated;
  -ms-interpolation-mode: nearest-neighbor;

  & > div {
    transform: scale(${(props) => props.imgFillPercentage});

    margin: auto;
    overflow: hidden;
    aspect-ratio: ${(props) => props.spriteConfig.frameWidth} /
      ${(props) => props.spriteConfig.frameHeight};

    ${(props) =>
      props.spriteConfig.direction === SpriteDirection.Horizontal
        ? css`
            height: 100%;
          `
        : css`
            width: 100%;
          `}

    img {
      ${(props) =>
        props.spriteConfig.direction === SpriteDirection.Horizontal
          ? css`
              height: 100%;
              --frameWidth: 100%;
              transform: translateX(
                calc(
                  (var(--frameWidth) / ${props.spriteConfig.frameCount}) *
                    ${props.frameIndex} * -1
                )
              );
            `
          : css`
              width: 100%;
              --frameHeight: ${
                (props.width / props.spriteConfig.frameWidth) *
                props.spriteConfig.frameHeight
              }
              transform: translateY(
                calc(
                  (var(--frameHeight) / ${props.spriteConfig.frameCount}) *
                    ${props.frameIndex} * -1
                )
              );
            `}
    }
  }
`;

const AssetBox = (
  props: {
    thumbnail?: ThumbnailProps;
    spriteThumbnail?: {
      src: string;
      spriteConfig: SpriteConfigType;
      frameIndex?: number;
      width?: number;
      height?: number;
      imgFillPercentage?: string;
    };
    canvasThumbnail?: {
      width?: number;
      height?: number;
      contentWidth?: number;
      contentHeight?: number;
      paddingX?: number;
      paddingY?: number;
      scale?: number;
      children: any;
    };
    subLabel?: string;
  } & BoxProps
) => {
  const canvasWidth = props.canvasThumbnail?.width || 70;
  const canvasHeight = props.canvasThumbnail?.height || 70;
  const contentWidth = props.canvasThumbnail?.contentWidth || canvasWidth;
  const contentHeight = props.canvasThumbnail?.contentHeight || canvasHeight;
  const canvasScale = props.canvasThumbnail?.scale || 2;

  //
  // RENDER
  //
  return (
    <Box
      {...props}
      label={
        <Row>
          {props.thumbnail ? <Thumbnail {...props.thumbnail} /> : null}

          {props.spriteThumbnail ? (
            <SpriteThumbnail
              width={props.spriteThumbnail.width || 70}
              height={props.spriteThumbnail.height || 70}
              spriteConfig={props.spriteThumbnail.spriteConfig}
              frameIndex={
                props.spriteThumbnail.frameIndex ||
                Math.floor(props.spriteThumbnail.spriteConfig.frameCount / 2)
              }
              imgFillPercentage={props.spriteThumbnail.imgFillPercentage}
            >
              <div>
                <img src={props.spriteThumbnail.src} alt={`sprite thumbnail`} />
              </div>
            </SpriteThumbnail>
          ) : null}

          {props.canvasThumbnail ? (
            <CanvasThumbnail width={canvasWidth} height={canvasHeight}>
              <AssetPreview
                width={canvasWidth / canvasScale}
                height={canvasHeight / canvasScale}
                contentWidth={contentWidth / canvasScale}
                contentHeight={contentHeight / canvasScale}
                scale={canvasScale}
                background="#0000"
              >
                {props.canvasThumbnail.children}
              </AssetPreview>
            </CanvasThumbnail>
          ) : null}

          <Column>
            <div>{props.label}</div>
            {props.subLabel?.length ? (
              <SubLabel>{props.subLabel}</SubLabel>
            ) : null}
          </Column>
        </Row>
      }
    >
      {props.children}
    </Box>
  );
};

export default AssetBox;
