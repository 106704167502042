export const arrayOf = <T>(length: number, value: T) => {
  const arr = []
  arr.length = length
  arr.fill(value)

  return arr
}

export const arrayInsertAt = <T>(array: T[], index: number, ...items: T[]) => {
  const arr = [...array]
  arr.splice(index, 0, ...items)
  return arr
}

export const arrayDeleteAt = <T>(array: T[], index: number) => {
  const arr = [...array]
  arr.splice(index, 1)
  return arr
}

export const arrayOfNonUniqueItems = <T>(array: T[]) => {
  const uniqueItems = []
  const nonUniqueItems = []
  array.forEach((item) => {
    if (!uniqueItems.includes(item)) {
      uniqueItems.push(item)
    } else {
      nonUniqueItems.push(item)
    }
  })
  return nonUniqueItems
}

export const arrayLinear = (length: number, startValue?: number) => {
  const arr = []
  const start = startValue || 0
  for (let i = start; i < length + start; i++) {
    arr.push(i)
  }
  return arr
}

export const sequenceFromTo = (from: number, to: number) => {
  if (from === to) {
    return [from]
  }

  const arr = []
  const isReversed = from > to
  const start = isReversed ? to : from
  const end = isReversed ? from : to
  const length = end - start

  for (let i = start; i < start + length; i++) {
    arr.push(i)
  }
  return isReversed ? arr.reverse() : arr
}
