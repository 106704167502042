import {
  DuplicateSceneIDsData,
  getDuplicateSceneIDsWithData,
  getGameScenesData,
  getSceneLinksByScenesLayoutId,
  getSceneLinksWithoutSceneId,
} from "../../utils/objects/scenesLayout";
import {
  GameScenesDataType,
  LayoutSceneType,
  ScenesLayoutLinkType,
  ScenesLayoutType,
  ScenesLayoutsDefType,
} from "game-engine/types";
import styled, { css } from "styled-components";

import Box from "game-engine/_dev/basic-components/Box";
import Code from "game-engine/_dev/basic-components/Code";
import EditorFooter from "./components/EditorFooter";
import EditorViewSceneLinks from "./components/EditorViewSceneLinks";
import EditorViewScenesLayout from "./components/EditorViewScenesLayout";
import ModalConfirm from "game-engine/_dev/basic-components/ModalWindow/ModalConfirm";
import { SCENES_LAYOUTS_DEF } from "game-files/scenes/SCENE_LAYOUTS";
import { ScenesLayoutGridHoverMode } from "../dev-components/ScenesLayoutGrid";
import SectionIntro from "./components/SectionIntro";
import SidebarLeft from "./components/SidebarLeft";
import SidebarRight from "./components/SidebarRight";
import devToolsConfig from "game-engine/configs/devtools-config";
import { getSceneLayoutCode } from "./utils/code";
import { useState } from "react";

export const SCENES_LAYOUT_DEFAULT = "__default__";

const FOOTER_HEIGHT = "100px";

const Wrapper = styled.div`
  position: relative;
  flex: 1;
  overflow: hidden;

  display: flex;
  justify-content: stretch;
  flex-wrap: wrap;

  padding-bottom: ${FOOTER_HEIGHT};
  background: ${devToolsConfig.background};
`;

const FooterWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  min-height: ${FOOTER_HEIGHT};
  background: ${devToolsConfig.background};
  border-top: 1px solid ${devToolsConfig.borderColor};

  padding: 0 2%;

  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const Main = styled.div<{ hasErrors: boolean }>`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: ${devToolsConfig.background};
  overflow-x: hidden;
  overflow-y: auto;

  ${(props) =>
    props.hasErrors &&
    css`
      background: #f002;
    `}
`;

const Content = styled.div`
  min-height: 70%;
  overflow: auto;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const CodeWrapper = styled.div`
  padding: 10px;
  padding-top: 10px;
  padding-bottom: 20px;
  background: ${devToolsConfig.background};
  border-top: 1px solid ${devToolsConfig.borderColor};
`;

const Error = styled.div`
  padding: 20px 10px;
  padding-top: 10px;
  color: #ff6363;
  font-size: 14px;
`;

const ErrorMessage = styled.div`
  padding-bottom: 8px;
  font-weight: 600;
`;
const ErrorList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;
const ErrorItem = styled.div`
  padding-top: 2px;
  padding-left: 20px;

  span {
    margin: 0 8px;
    font-size: 12px;
    font-style: italic;
  }

  &:before {
    content: "• ";
  }
`;

export type EditorActiveSceneItem = {
  layoutScene: LayoutSceneType;
  row: number;
  column: number;
};

export enum OnSceneClickMode {
  "select" = "select",
  "reset" = "reset",
  "rowDelete" = "rowDelete",
  "rowInsertAbove" = "rowInsertAbove",
  "rowInsertBelow" = "rowInsertBelow",
  "columnDelete" = "columnDelete",
  "columnInsertLeft" = "columnInsertLeft",
  "columnInsertRight" = "columnInsertRight",
}

export type OnSceneClickType = {
  mode: OnSceneClickMode;
  hoverMode: ScenesLayoutGridHoverMode;
  hoverColor?: string;
};

export type EditorType = {
  sceneItemWidth: number;
  gameScenesData: GameScenesDataType;
  activeSceneLayoutId: string;
  activeSceneItem: EditorActiveSceneItem;
  onSceneClick: OnSceneClickType;
  duplicateSceneIDsData: DuplicateSceneIDsData[];
  highlightedSceneLinkIds: string[];
};

//
// COMPONENT
//
const ScenesLayoutEditor = () => {
  //
  // DATA
  //
  const [editor, setEditor] = useState<EditorType>();

  const defaultOnSceneClick = {
    mode: OnSceneClickMode.select,
    hoverMode: ScenesLayoutGridHoverMode.item,
    hoverColor: undefined,
  };

  const initEditor = () => {
    const gameScenesData = getGameScenesData(SCENES_LAYOUTS_DEF);
    const initEditor: EditorType = {
      sceneItemWidth: 150,
      gameScenesData: gameScenesData,
      activeSceneLayoutId: SCENES_LAYOUT_DEFAULT,
      activeSceneItem: undefined,
      onSceneClick: defaultOnSceneClick,
      duplicateSceneIDsData: getDuplicateSceneIDsWithData(
        gameScenesData?.scenes,
        gameScenesData?.scenesLayouts
      ),
      highlightedSceneLinkIds: [],
    };
    setEditor(initEditor);
  };

  //
  // UPDATE DATA
  //
  const updateScenesLayoutsDef = (
    updatedLayoutsDef: ScenesLayoutsDefType,
    options?: { highlightedSceneLinkIds: string[] }
  ) => {
    const gameScenesData = getGameScenesData(updatedLayoutsDef);

    setEditor({
      ...editor,
      gameScenesData: gameScenesData,
      duplicateSceneIDsData: getDuplicateSceneIDsWithData(
        gameScenesData?.scenes,
        gameScenesData?.scenesLayouts
      ),
      highlightedSceneLinkIds:
        options !== undefined
          ? options?.highlightedSceneLinkIds
          : editor.highlightedSceneLinkIds,
    });
  };

  const addNewScenesLayout = (options: { newId: string }) => {
    const { newId } = options;
    const newScenesLayout = {
      id: newId,
      grid: [[null]],
    };
    const updatedLayoutsDef = {
      ...editor.gameScenesData.scenesLayoutsDef,
      scenesLayouts: [...editor.gameScenesData.scenesLayouts, newScenesLayout],
    };
    const gameScenesData = getGameScenesData(updatedLayoutsDef);

    setEditor({
      ...editor,
      activeSceneItem: undefined,
      activeSceneLayoutId: newId,
      gameScenesData: gameScenesData,
      duplicateSceneIDsData: getDuplicateSceneIDsWithData(
        gameScenesData?.scenes,
        gameScenesData?.scenesLayouts
      ),
    });
  };

  const updateScenesLayout = (options: {
    updatedScenesLayout: ScenesLayoutType;
    activeSceneItem?: EditorActiveSceneItem;
    resetOnSceneClick?: boolean;
  }) => {
    const { updatedScenesLayout, activeSceneItem, resetOnSceneClick } = options;

    const updatedLayoutsDef = {
      ...editor.gameScenesData.scenesLayoutsDef,
      scenesLayouts: [...editor.gameScenesData.scenesLayouts].map((l) =>
        l.id === updatedScenesLayout.id ? updatedScenesLayout : l
      ),
    };

    const gameScenesData = getGameScenesData(updatedLayoutsDef);

    setEditor({
      ...editor,
      onSceneClick: resetOnSceneClick
        ? defaultOnSceneClick
        : editor.onSceneClick,
      activeSceneItem: Object.keys(options).includes("activeSceneItem")
        ? activeSceneItem
        : editor.activeSceneItem,
      gameScenesData: gameScenesData,
      duplicateSceneIDsData: getDuplicateSceneIDsWithData(
        gameScenesData?.scenes,
        gameScenesData?.scenesLayouts
      ),
    });
  };

  const updateScenesLayoutId = (options: {
    originalId: string;
    newId: string;
  }) => {
    const { originalId, newId } = options;

    const scenesLayout = editor.gameScenesData.scenesLayouts.find(
      (l) => l.id === originalId
    );
    if (scenesLayout) {
      const updatedScenesLayout = { ...scenesLayout, id: newId };
      const updatedLayoutsDef = {
        ...editor.gameScenesData.scenesLayoutsDef,
        scenesLayouts: [...editor.gameScenesData.scenesLayouts].map((l) =>
          l.id === scenesLayout.id ? updatedScenesLayout : l
        ),
      };

      const gameScenesData = getGameScenesData(updatedLayoutsDef);

      setEditor({
        ...editor,
        activeSceneLayoutId: updatedScenesLayout?.id,
        gameScenesData: gameScenesData,
        duplicateSceneIDsData: getDuplicateSceneIDsWithData(
          gameScenesData?.scenes,
          gameScenesData?.scenesLayouts
        ),
      });
    }
  };

  const updateLayoutScene = (options: {
    updatedLayoutScene: LayoutSceneType;
    row: number;
    column: number;
  }) => {
    const { updatedLayoutScene, row, column } = options;
    const activeScenesLayout = getActiveScenesLayout();
    const isSelected =
      editor.activeSceneItem &&
      row === editor.activeSceneItem?.row &&
      column === editor.activeSceneItem?.column;

    const updatedGrid = [...activeScenesLayout?.grid].map((rowItems, r) =>
      rowItems.map((layoutScene, c) => {
        if (r === row && c === column) {
          return updatedLayoutScene;
        }
        return layoutScene;
      })
    );

    const updatedScenesLayout = { ...activeScenesLayout, grid: updatedGrid };

    const updatedLayoutsDef = {
      ...editor.gameScenesData.scenesLayoutsDef,
      scenesLayouts: [...editor.gameScenesData.scenesLayouts].map((l) =>
        l.id === updatedScenesLayout.id ? updatedScenesLayout : l
      ),
    };

    const gameScenesData = getGameScenesData(updatedLayoutsDef);

    setEditor({
      ...editor,
      activeSceneItem:
        updatedLayoutScene === undefined
          ? {
              layoutScene: undefined,
              row, // set as active when unassigning scene
              column,
            }
          : editor.activeSceneItem && isSelected
          ? {
              ...editor.activeSceneItem,
              layoutScene: updatedLayoutScene,
            }
          : editor.activeSceneItem,
      gameScenesData: gameScenesData,
      duplicateSceneIDsData: getDuplicateSceneIDsWithData(
        gameScenesData?.scenes,
        gameScenesData?.scenesLayouts
      ),
    });
  };

  //
  // DELETE SCENES LAYOUT
  //
  const [deleteConfirm, setDeleteConfirm] = useState<{
    title?: string;
    description?: string;
    onConfirm: () => void;
  }>();

  const deleteScenesLayout = (options: { id: string }) => {
    const { id } = options;

    const sceneLinksToBeDeleted: ScenesLayoutLinkType[] =
      getSceneLinksByScenesLayoutId({
        scenesLayoutId: id,
        scenesLayouts: editor.gameScenesData.scenesLayouts,
        scenesLayoutsDef: editor.gameScenesData.scenesLayoutsDef,
      });

    let description = `The selected scenes layout will be deleted:`;
    description += `\n\n`;
    description += `${editor.activeSceneLayoutId}`;
    description += `\n\n`;

    if (sceneLinksToBeDeleted?.length) {
      description += `------------------------------------------------------`;
      description += `\n\n`;
      description += `Following scene links will also be deleted:`;
      description += `\n\n`;
      description += sceneLinksToBeDeleted
        .map((link) => `${link.sceneLinkId}`)
        .join("\n");
    }

    setDeleteConfirm({
      title: "Delete Scenes Layout?",
      description,
      onConfirm: () => {
        deleteScenesLayoutRaw(id);
        setDeleteConfirm(undefined);
      },
    });
  };

  const deleteScenesLayoutRaw = (id: string) => {
    const updatedLayoutsDef = {
      ...editor.gameScenesData.scenesLayoutsDef,
      sceneLinks: getSceneLinksWithoutSceneId({
        id,
        scenesLayoutsDef: editor.gameScenesData.scenesLayoutsDef,
      }),
      scenesLayouts: [
        ...editor.gameScenesData.scenesLayoutsDef.scenesLayouts,
      ].filter((l) => l.id !== id),
    };
    const gameScenesData = getGameScenesData(updatedLayoutsDef);

    setEditor({
      ...editor,
      activeSceneItem: undefined,
      activeSceneLayoutId: undefined,
      gameScenesData: gameScenesData,
      duplicateSceneIDsData: getDuplicateSceneIDsWithData(
        gameScenesData?.scenes,
        gameScenesData?.scenesLayouts
      ),
    });
  };

  //
  // HANDLERS
  //
  const getActiveScenesLayout: () => ScenesLayoutType = () => {
    return editor.gameScenesData?.scenesLayoutsDef?.scenesLayouts.find(
      (s) => s.id === editor.activeSceneLayoutId
    );
  };

  //
  // RENDER
  //
  return (
    <Wrapper>
      {!editor ? (
        <SectionIntro allowEdit={initEditor} />
      ) : (
        <>
          <SidebarLeft
            editor={editor}
            setEditor={setEditor}
            updateScenesLayout={updateScenesLayout}
            getActiveScenesLayout={getActiveScenesLayout}
          />

          <Main hasErrors={!!editor.duplicateSceneIDsData?.length}>
            {editor && (
              <>
                <Content>
                  {editor.activeSceneLayoutId &&
                  editor.activeSceneLayoutId !== SCENES_LAYOUT_DEFAULT ? (
                    <EditorViewScenesLayout
                      editor={editor}
                      setEditor={setEditor}
                      updateScenesLayout={updateScenesLayout}
                      getActiveScenesLayout={getActiveScenesLayout}
                      updateLayoutScene={updateLayoutScene}
                    />
                  ) : (
                    <EditorViewSceneLinks
                      editor={editor}
                      setEditor={setEditor}
                      updateScenesLayoutsDef={updateScenesLayoutsDef}
                    />
                  )}
                </Content>

                <CodeWrapper>
                  {editor.duplicateSceneIDsData?.length ? (
                    <Error>
                      <ErrorMessage>{"Duplicate Scene IDs:"}</ErrorMessage>
                      <ErrorList>
                        {editor.duplicateSceneIDsData.map((duplicateData) => (
                          <ErrorItem key={duplicateData?.duplicateId}>
                            {`${duplicateData?.duplicateId}`}
                            <span>in</span>
                            {`${duplicateData?.scenesLayoutId}`}
                          </ErrorItem>
                        ))}
                      </ErrorList>
                    </Error>
                  ) : null}

                  <Box label="Code Preview" level={2}>
                    <Code code={getSceneLayoutCode({ editor })} />
                  </Box>
                </CodeWrapper>
              </>
            )}
          </Main>

          <SidebarRight
            editor={editor}
            setEditor={setEditor}
            updateScenesLayoutId={updateScenesLayoutId}
            updateLayoutScene={updateLayoutScene}
            addNewScenesLayout={addNewScenesLayout}
            deleteScenesLayout={deleteScenesLayout}
            updateScenesLayoutsDef={updateScenesLayoutsDef}
          />

          <FooterWrapper>
            <EditorFooter editor={editor} setEditor={setEditor} />
          </FooterWrapper>
        </>
      )}

      <ModalConfirm
        isOpen={!!deleteConfirm}
        windowTitle="Delete"
        {...deleteConfirm}
        close={() => setDeleteConfirm(undefined)}
      />
    </Wrapper>
  );
};

export default ScenesLayoutEditor;
