import ANIMATION_CONFIGS from "game-files/animations/ANIMATION_CONFIGS";
import Animation from "game-engine/components/basic-elements/Animation";
import { Group } from "react-konva";
import { getImageOffset } from "game-engine/utils";

const CharacterCelestialFlame = (props: {
  isActive?: boolean;
  onImagesLoaded?: () => void;
}) => {
  const { isActive, onImagesLoaded } = props;

  const animationConfig = ANIMATION_CONFIGS.CelestialFlame;

  const width = animationConfig.spriteConfig?.frameWidth;
  const height = animationConfig.spriteConfig?.frameHeight;

  //
  // RENDER
  //
  return (
    <Group
      opacity={isActive ? 1 : 0}
      listening={isActive}
      position={getImageOffset({ width, height })}
    >
      <Animation
        animationConfig={ANIMATION_CONFIGS.CelestialFlame}
        playAnimation={isActive}
        onImageLoaded={onImagesLoaded}
      />
    </Group>
  );
};

export default CharacterCelestialFlame;
