import {
  FrameSequenceValue,
  SceneType,
  SpriteDirection,
} from "game-engine/types";

import { ACTION } from "game-engine/utils";
import { GameObjective } from "game-files/gameObjectives";
import ImagePreview from "./assets/preview_00.png";
import Layer_01_Background from "./assets/layer - 01 - background_00.png";
import Layer_02_ColumnAnimationSprite from "./assets/layer - 02 - column - animation sprite_00.png";
import Layer_Map_Depth from "./assets/layer - 00 - DEPTH MAP_00.png";
import Layer_Map_Drop from "./assets/layer - 00 - DROP MAP_00.png";
import Layer_Map_Walk from "./assets/layer - 00 - WALK MAP_00.png";
import MUSIC_CONFIGS from "game-files/audio/MUSIC_CONFIGS";

const DevScene01: SceneType = {
  isDev: true,
  isUsingTestObjectives: true, // IMPORTANT TO KEEP TEST AND GAME OBJECTIVES SEPARATELY

  configId: "DevScene01",
  name: {
    [GameObjective.GAME_START]: { en: "Development Scene 1", cz: undefined },
  },

  preview: { src: ImagePreview },
  uniqueId: "DevScene01", // USUALLY GENERATED FROM SCENES LAYOUT, UNLESS DEV SCENE THAT'S NOT IN SCENE LAYOUTS
  sceneNeighbors: undefined, // IS GENERATED FROM SCENES LAYOUT
  sceneWalkPaths: undefined,

  audio: {
    [GameObjective.GAME_START]: {
      music: {
        musicConfigId: MUSIC_CONFIGS.Test_01.id,
      },
    },
  },

  images: {
    background: {
      dataByCurrentObjective: {
        [GameObjective.GAME_START]: {
          image: { src: Layer_01_Background },
          dropMap: { src: Layer_Map_Drop },
          walkMap: { src: Layer_Map_Walk },
          depthMap: { src: Layer_Map_Depth },
          depthSettings: {
            scaleMin: 0.4,
            //scaleMax: 1,
          },
        },
      },
      actionsByCurrentObjective: undefined,
    },

    layers: [
      {
        id: "stream-Layer_02_ColumnAnimationSprite",
        depthY: 80,
        dataByCurrentObjective: {
          [GameObjective.GAME_START]: {
            image: { src: Layer_02_ColumnAnimationSprite },
            spriteConfig: {
              direction: SpriteDirection.Horizontal,
              frameWidth: 24,
              frameHeight: 50,
              clipOffset: { x: 0, y: 0 },
              frameCount: 10,
              frameDurationMilliseconds: 60,
              frameSequence: [
                FrameSequenceValue.original,
                { pause: 10 },
                { from: FrameSequenceValue.lastFrame, to: 5 },
                { from: 5, to: FrameSequenceValue.lastFrame },
                FrameSequenceValue.reversed,
                { pause: 10 },
              ],
            },
            offset: {
              x: 190,
              y: 30,
            },
            ignoreOnClick: false,
            isInvisible: false,
            cursorOnHover: undefined,
          },
        },

        actionsByCurrentObjective: {
          [GameObjective.GAME_START]: {
            actions: [
              ACTION.talkRandom({
                dialogOptions: [
                  {
                    en: `I could say something about it.`,
                    cz: "Mohl bych o tom něco říct.",
                  },
                ],
              }),
            ],
          },
        },
      },
    ],
  },
};

export default DevScene01;
