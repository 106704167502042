import {
  ActionFacingProps,
  ActionId,
  Action_CharacterFace,
} from "game-engine/types";

import { CharacterId } from "game-files/common/ids";
import { createActionName } from ".";
import { positionToString } from "../by-types";

//
// CREATE BASIC FACING ACTION
//
type FaceProps = {
  characterId?: CharacterId;
  blockGui?: boolean; // face is only 1 frame in duration, which causes CURSOR FLICKER if it is placed between two pauses -> fix with blockGui
  unskippable?: boolean;
} & ActionFacingProps;

type FaceReturn = () => Action_CharacterFace;

export const face: (props: FaceProps) => FaceReturn = (props) => () => {
  let direction: any = props.faceCharacterId;
  if (!direction) {
    direction = props.faceDirection;
  }
  if (!direction) {
    direction = props.faceItemId;
  }
  if (!direction && props.facePosition) {
    direction = positionToString(props.facePosition);
  }
  if (!direction) {
    direction = "???";
  }

  return {
    ...props,
    _name: createActionName({
      name: `face`,
      value: `${direction}`,
      prefix: props.characterId || CharacterId.MainCharacter,
    }),
    id: ActionId.characterFace,
    blockGui: props.blockGui,
    unskippable: props.blockGui,
  };
};
