import {
  CursorRenderType,
  Position,
  SceneLayerDataType,
  SceneLayerType,
} from "game-engine/types";
import { OnSceneMouseClick, SceneLayerMeta } from "../../../..";

import Box from "game-engine/_dev/basic-components/Box";
import Checkbox from "game-engine/_dev/basic-components/inputs/Checkbox";
import Divider from "game-engine/_dev/basic-components/Divider";
import Dropdown from "game-engine/_dev/basic-components/inputs/Dropdown";
import GAME_CONFIG from "game-files/gameConfig";
import { GameObjective } from "game-files/gameObjectives";
import NumberInput from "game-engine/_dev/basic-components/inputs/NumberInput";
import SpriteConfig from "../SpriteConfig";
import { capitalizeFirstLetter } from "game-engine/utils";
import devToolsConfig from "game-engine/configs/devtools-config";

const General = (props: {
  layer: SceneLayerType;
  updateLayer: (layer: SceneLayerType) => void;
  updateLayerData: (
    sceneLayerId: string,
    layerData: SceneLayerDataType
  ) => void;
  layerMeta: SceneLayerMeta;
  updateLayerMeta: (layerMeta: SceneLayerMeta) => void;
  onSceneMouseClick: OnSceneMouseClick;
  setOnSceneMouseClick: (v: OnSceneMouseClick) => void;
}) => {
  const {
    layer,
    updateLayer,
    updateLayerData,
    layerMeta,
    updateLayerMeta,
    onSceneMouseClick,
    setOnSceneMouseClick,
  } = props;

  const sceneMouseClickId_depthY = `layer_${layer.id}_depthY`;
  const sceneMouseClickId_depthY_isActive =
    onSceneMouseClick?.paramId === sceneMouseClickId_depthY;

  const layerData = layer.dataByCurrentObjective[GameObjective.GAME_START];

  //
  // RENDER
  //
  return (
    <Box renderContentOnly childMinWidth="90%">
      <Checkbox
        label="Highlight Layer"
        value={layerMeta.isHighlighted}
        onChange={(v) =>
          updateLayerMeta({
            ...layerMeta,
            isHighlighted: !layerMeta.isHighlighted,
          })
        }
        style={{ minWidth: "90%" }}
      />

      <SpriteConfig
        layer={layer}
        updateLayer={updateLayer}
        updateLayerData={updateLayerData}
      />

      <Divider name="Depth Y" />

      <Checkbox
        label="Is Background"
        value={layerMeta.isInBack}
        onChange={(v) => {
          updateLayer({
            ...layer,
            depthY: 0,
          });
          updateLayerMeta({
            ...layerMeta,
            isInFront: false,
            isInBack: !layerMeta.isInBack,
          });
        }}
      />

      <Checkbox
        label="Is Foreground"
        value={layerMeta.isInFront}
        onChange={(v) => {
          updateLayer({
            ...layer,
            depthY: GAME_CONFIG.scene.dimensions.y + 1000,
          });
          updateLayerMeta({
            ...layerMeta,
            isInBack: false,
            isInFront: !layerMeta.isInFront,
          });
        }}
      />

      {!layerMeta.isInFront && !layerMeta.isInBack && (
        <>
          <Checkbox
            label="Set with mouse click"
            value={sceneMouseClickId_depthY_isActive}
            onChange={(v) =>
              setOnSceneMouseClick(
                sceneMouseClickId_depthY_isActive
                  ? undefined
                  : {
                      sceneLayerId: layer.id,
                      paramId: sceneMouseClickId_depthY,
                      func: (p: Position) =>
                        updateLayer({ ...layer, depthY: p.y }),
                    }
              )
            }
          />

          <NumberInput
            prefix="(px)"
            min={0}
            value={layer.depthY}
            onChange={(v) => updateLayer({ ...layer, depthY: v })}
            onClick={() => setOnSceneMouseClick(undefined)}
            rangeController={{
              min: 0,
              max: GAME_CONFIG.scene.dimensions.y,
            }}
            noBorder
          />
        </>
      )}

      <Divider name="General" />

      <Checkbox
        label="Invisible but listening to onClick"
        value={layerData.isInvisible}
        onChange={(v) =>
          updateLayerData(layer.id, {
            ...layerData,
            isInvisible: !layerData.isInvisible,
          })
        }
      />

      <Checkbox
        label="Ignore onClick"
        value={layerData.ignoreOnClick}
        onChange={(v) =>
          updateLayerData(layer.id, {
            ...layerData,
            ignoreOnClick: !layerData.ignoreOnClick,
          })
        }
      />

      <Dropdown
        prefix="Cursor:"
        isActive={
          layerData.cursorOnHover !== undefined &&
          layerData.cursorOnHover !== CursorRenderType.default
        }
        selectedId={layerData.cursorOnHover || CursorRenderType.default}
        items={Object.values(CursorRenderType).map((cursor) => {
          return {
            id: cursor,
            data: cursor,
            label: capitalizeFirstLetter(
              cursor.replace(/([a-z])([A-Z])/g, "$1 $2")
            ),
          };
        })}
        onChange={(selectedId) =>
          updateLayerData(layer.id, {
            ...layerData,
            cursorOnHover: CursorRenderType[selectedId],
          })
        }
        backgroundColor={devToolsConfig.inputBackground}
        padding="8px 6px"
      />

      {/* <Divider name="Layer Offset" />

      <PositionInput
        value={layerData.offset}
        onChange={(v) =>
          updateLayerData(layer.id, {
            ...layerData,
            offset: v,
          })
        }
        rangeControllerX={{
          min: -GAME_CONFIG.scene.dimensions.x,
          max: GAME_CONFIG.scene.dimensions.x,
        }}
        rangeControllerY={{
          min: -GAME_CONFIG.scene.dimensions.y,
          max: GAME_CONFIG.scene.dimensions.y,
        }}
      /> */}
    </Box>
  );
};

export default General;
