import { indentCode, prettifyCode } from "./code-formatting";

import { GameAudioType } from "game-engine/types";
import MUSIC_CONFIGS from "game-files/audio/MUSIC_CONFIGS";
import SOUND_CONFIGS from "game-files/audio/SOUND_CONFIGS";

//
// SCENE AUDIO
//
export const getCode_SceneAudio = (
  audio: GameAudioType,
  options?: { includeConditionalExample: boolean }
) => {
  const codeLines = ["{"];

  // MUSIC
  const music = Object.entries(MUSIC_CONFIGS).find(
    ([key, config]) => config.id === audio.music?.musicConfigId
  );
  const musicKey = music?.length ? music[0] : undefined;

  codeLines.push(`music: {
      musicConfigId: ${musicKey ? `MUSIC_CONFIGS.${musicKey}.id` : "undefined"},
      volume: ${
        musicKey && audio.music?.volume !== 1 // 1 should be undefined and thus use default value
          ? audio.music?.volume
          : "undefined"
      },
    },`);

  // SOUND
  const sound = Object.entries(SOUND_CONFIGS).find(
    ([key, config]) => config.id === audio.sound?.soundConfigId
  );
  const soundKey = sound?.length ? sound[0] : undefined;

  codeLines.push(`sound: {
        soundConfigId: ${soundKey ? `SOUND_CONFIGS.${soundKey}` : "undefined"},
        volume: ${
          soundKey && audio.sound?.volume !== 1 // 1 should be undefined and thus use default value
            ? audio.sound?.volume
            : "undefined"
        },
        loop: ${soundKey && audio.sound?.loop ? "true" : "undefined"},
      },`);

  if (options?.includeConditionalExample) {
    codeLines.push(`
      /*      
      ${indentCode(getCode_SceneAudio_Conditional(), 3, {
        ignoreFirstLine: true,
      })}
      */`);
  }

  codeLines.push("}");
  return codeLines.join("\n");
};

//
// CONDITIONAL SCENE AUDIO
//
export const getCode_SceneAudio_Conditional = () => {
  const codeLines = [];

  codeLines.push(`
    // EXAMPLE OF CONDITIONAL SCENE AUDIO
    conditional: ({ events }) => [
    
      {
        condition: events.test_treeClickCount > 3,
        state: {
          music: {
            musicConfigId: MUSIC_CONFIGS.Test_03.id,
          },
          sound: {
            soundConfigId: SOUND_CONFIGS.Test_Bird.id,
            volume: 0.5,
            loop: true,
          },
        },
      },
      
    ],`);

  return prettifyCode(codeLines.join("\n"));
};
