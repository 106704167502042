import { MusicConfigType } from "game-engine/types";
import { createMusicConfig } from "./music/_files";

//
// INDIVIDUAL MUSIC CONFIGS
//
const Test_01 = createMusicConfig({
  filename: "kyrandia-the-forest",
  name: "Kyrandia - The Forest",
  volume: 1,
});

const Test_02 = createMusicConfig({
  filename: "kyrandia-the-timbermist-woods",
  name: "Kyrandia - The Timbermist Woods",
  volume: 1,
});

const Test_03 = createMusicConfig({
  filename: "kyrandia-playing-with-merith",
  name: "Kyrandia - Playing with Merith",
  volume: 1,
});

const Test_Fanfare = createMusicConfig({
  filename: "fanfare",
  name: "Fanfare",
  volume: 1,
});

//
// MUSIC CONFIGS
//
const MUSIC_CONFIGS = {
  Test_01,
  Test_02,
  Test_03,
  Test_Fanfare,
};

export default MUSIC_CONFIGS;

export const getMusicByConfigId: (id: string) => MusicConfigType = (id) => {
  if (id === undefined || id === null) {
    return null;
  }
  return Object.values(MUSIC_CONFIGS).find((s) => s.id === id);
};
