import { Group, Line, Rect } from "react-konva";

export type RectBeveledProps = {
  x?: number;
  y?: number;
  width: number;
  height: number;
  centered?: boolean;
  color?: string;
  colorHighlight?: string;
  colorShadow?: string;
  contrast?: number;
  bevel?: number;
};

const RectBeveled = (props: RectBeveledProps) => {
  const {
    x,
    y,
    color = "#666",
    colorHighlight,
    colorShadow,
    contrast = 0.6,
    bevel = 2,
    width,
    height,
    centered,
  } = props;

  // Coordinates for the yellow polygon
  const polygonPoints = [
    // Top-left corner
    0,
    0,

    // Top-right corner
    width,
    0,

    // Bottom-right corner
    width,
    height,

    // Diagonal to top left
    width - bevel,
    height - bevel,

    // Top-right offset
    width - bevel,
    bevel,

    // Top-left offset
    bevel,
    bevel,

    // Back to start
    0,
    0,
  ];

  return (
    <Group x={centered ? x - Math.round(width / 2) : x} y={y}>
      {/* Shadow Base */}
      <Rect x={0} y={0} width={width} height={height} fill={color} />

      {/* Shadow Overlay */}
      <Rect
        x={0}
        y={0}
        width={width}
        height={height}
        fill={colorShadow || "black"}
        opacity={colorShadow ? 1 : contrast}
      />

      {/* Highlight Base */}
      <Line points={polygonPoints} closed fill={color} />

      {/* Highlight Overlay */}
      <Line
        points={polygonPoints}
        closed
        fill={colorHighlight || "white"}
        opacity={colorHighlight ? 1 : contrast}
      />

      {/* Fill Base */}
      <Rect
        x={bevel}
        y={bevel}
        width={width - 2 * bevel}
        height={height - 2 * bevel}
        fill={color}
      />
    </Group>
  );
};

export default RectBeveled;
