import {
  CharacterConfigType,
  SceneCharacterByCurrentObjective,
  SceneCharacterDataType,
} from "game-engine/types";

import { GameEventsType } from "game-files/gameEvents";
import { GameObjective } from "game-files/gameObjectives";
import { getDataByCurrentObjective } from "./getters";

export const getSceneCharacterDataByCurrentObjective = (props: {
  config: CharacterConfigType;
  dataByCurrentObjective: SceneCharacterByCurrentObjective;
  currentObjective: GameObjective;
  events: GameEventsType;
}) => {
  const data: SceneCharacterDataType = {
    config: props.config,
    ...getDataByCurrentObjective<SceneCharacterDataType>(
      {
        dataByCurrentObjective: props.dataByCurrentObjective as any,
        currentObjective: props.currentObjective,
        events: props.events,
        aggregateConditionals: true,
      },
      {} as any
    ),
  };
  return data;
};
