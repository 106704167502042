import { ActionId, Action_Effect, GameEffectFadeEnum } from "game-engine/types";

import GAME_CONFIG from "game-files/gameConfig";
import { createActionName } from ".";

type SetEffectViewportFadeProps = {
  durationSec?: number;
};

type SetEffectViewportFadeReturn = () => Action_Effect;

export const viewportFadeOut: (
  props?: SetEffectViewportFadeProps
) => SetEffectViewportFadeReturn = (props) => () => {
  const durationSec =
    props?.durationSec ?? GAME_CONFIG.cinematics.fadeViewportDurationSec;

  return {
    _name: createActionName({ name: "fade-out viewport" }),
    id: ActionId.effect,
    blockGui: true,
    unskippable: true,
    durationSec,
    effectFadeViewport: {
      durationSec,
      type: GameEffectFadeEnum.fadeOut,
    },
  };
};

export const viewportFadeIn: (
  props?: SetEffectViewportFadeProps
) => SetEffectViewportFadeReturn = (props) => () => {
  const durationSec =
    props?.durationSec ?? GAME_CONFIG.cinematics.fadeViewportDurationSec;

  return {
    _name: createActionName({ name: "fade-in viewport" }),
    id: ActionId.effect,
    blockGui: true,
    unskippable: true,
    durationSec,
    effectFadeViewport: {
      durationSec,
      type: GameEffectFadeEnum.fadeIn,
    },
  };
};
