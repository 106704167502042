import Checkbox from "../Checkbox";
import styled from "styled-components";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  color: #fffa;
  font-size: 13px;
`;

const DropItem = styled.div``;

const RadioList = (props: {
  selectedId: string;
  items: { id: string; data: any; label: any; isDisabled?: boolean }[];
  onChange: (id: string, data?: any) => void;
  onSetDefault?: () => void;
}) => {
  //
  // RENDER
  //
  return (
    <Wrapper>
      {props.items.map((item) => {
        const isActive = item.id === props.selectedId;

        return (
          <DropItem key={item.id}>
            <Checkbox
              label={item.label}
              value={isActive}
              isDisabled={item.isDisabled}
              onChange={(v) => {
                if (item.isDisabled) {
                  return;
                }
                if (isActive && props.onSetDefault) {
                  props.onSetDefault();
                } else {
                  props.onChange(item.id, item.data);
                }
              }}
              innerStyle={{ minWidth: "100%" }}
            />
          </DropItem>
        );
      })}
    </Wrapper>
  );
};

export default RadioList;
