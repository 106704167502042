/* eslint-disable react-hooks/exhaustive-deps */

import { Position, SceneCharacterDataType } from "game-engine/types";
import { useEffect, useState } from "react";

import DistortionEffect from "./DistortionEffect";
import { Group } from "react-konva";
import useGame from "game-engine/hooks/useGame";

const InvisibilityEffect = (props: {
  renderInvisibilityBackground: () => any;
  renderInvisibilityForeground: () => any;
  renderMainCharacterOnly: () => any;
  mainCharacter: SceneCharacterDataType;
  isSceneVisible: boolean;
  sceneReadyForPlaying: boolean;
  sceneOffset: Position;
  setForceGuiBlockerRef: { current: (v: boolean) => void };
}) => {
  const {
    renderInvisibilityBackground,
    renderInvisibilityForeground,
    renderMainCharacterOnly,
    mainCharacter,
    isSceneVisible,
    sceneReadyForPlaying,
    sceneOffset,
    setForceGuiBlockerRef,
  } = props;

  const { gamePlay } = useGame();

  const [renderState, setRenderState] = useState({
    isActive: gamePlay.state.currentSkills?.Invisibility?.isActive,
    isEnding: false,
  });

  //
  // HANDLE SKILL ACTIVATION AND PROGRESS WITH GUI BLOCKER
  //
  useEffect(() => {
    setRenderState((prevState) => {
      const isActive = gamePlay.state.currentSkills?.Invisibility?.isActive;

      const newRenderState = {
        isActive,
        isEnding: prevState.isEnding || (!isActive && prevState.isActive), // if isActive changed from true to false > ending transition
      }; //                                                                   keep prevState.isEnding if true (false can ONLY be set after end transition finished)

      if (newRenderState.isActive) {
        const progress = gamePlay.state.currentSkills?.Invisibility?.progress;

        if (progress !== 2) {
          // start transition in progress
          setForceGuiBlockerRef.current(true);
        } else {
          // start transition ended
          setForceGuiBlockerRef.current(false);
        }
      }

      if (newRenderState.isEnding) {
        // end transition started
        setForceGuiBlockerRef.current(true);
      }

      if (!newRenderState.isEnding && prevState.isEnding) {
        // end transition ended
        setForceGuiBlockerRef.current(false);
      }

      return newRenderState;
    });
  }, [gamePlay.state.currentSkills?.Invisibility]);

  const onEndTransitionFinished = () => {
    setForceGuiBlockerRef.current(false);
    setRenderState({
      isActive: false,
      isEnding: false, // this will remove the effect from DOM
    });
  };

  //
  // RENDER
  //
  return renderState.isActive || renderState.isEnding ? (
    <Group listening={false}>
      <DistortionEffect
        renderInvisibilityBackground={renderInvisibilityBackground}
        renderInvisibilityForeground={renderInvisibilityForeground}
        renderMainCharacterOnly={renderMainCharacterOnly}
        mainCharacter={mainCharacter}
        isSceneVisible={isSceneVisible}
        sceneReadyForPlaying={sceneReadyForPlaying}
        sceneOffset={sceneOffset}
        isEnding={renderState.isEnding}
        onEndTransitionFinished={onEndTransitionFinished}
      />
    </Group>
  ) : null;
};

export default InvisibilityEffect;
