import {
  CharacterWalkPath,
  Position,
  TranslatedString,
} from "game-engine/types";

const UNKNOWN = "???";

export const positionToString = (p: Position) => {
  if (!p) {
    return UNKNOWN;
  }
  return `[${p.x}, ${p.y}]`;
};

export const walkPathEndToString = (w: CharacterWalkPath) => {
  if (!w?.length) {
    return UNKNOWN;
  }
  return positionToString(w[w.length - 1]?.position);
};

export const getPreviewString = (
  s: string | TranslatedString,
  length: number = 30
) => {
  if (!s) {
    return UNKNOWN;
  }
  const text = typeof s === "string" ? s : s.en || s.cz;
  const ellipsis = "...";
  const trimToLength = length - ellipsis.length;
  if (text.length > trimToLength) {
    return text.slice(0, trimToLength) + ellipsis;
  }
  return text;
};

export const removeIndexFromFilename = (str: string): string => {
  // 'custom_animation_00' -> 'custom_animation'
  const lastIndex = str.lastIndexOf("_");
  return lastIndex === -1 ? str : str.slice(0, lastIndex);
};

// Optimized version of replaceAll using split and join
export const replaceAll = (str: string, find: string, replace: string) =>
  str.split(find).join(replace);

// Optimized capitalizeFirstLetter
export const capitalizeFirstLetter = (string: string) =>
  !string?.length ? string : string.charAt(0).toUpperCase() + string.slice(1);

// Optimized stringifyTranslations with memoized JSX
export const stringifyTranslations = (text: TranslatedString) => {
  // Create an array of the filtered translations (excluding 'en')
  const nonEnglishTranslations = Object.entries(text).filter(
    ([lang, value]) => lang !== "en" && value
  );

  if (nonEnglishTranslations.length > 0) {
    <div style={{ display: "flex", flexDirection: "column", gap: 4 }}>
      <span key="en">{text.en}</span>

      {nonEnglishTranslations.map(([lang, value]) => (
        <span key={lang} style={{ opacity: 0.3 }}>
          {value}
        </span>
      ))}
    </div>;
  }

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: 4 }}>
      <span key="en">{text.en}</span>
    </div>
  );
};

// Split string by capital letters (e.g. 'MyString' => 'My String')
export const splitByCapitalLetters = (
  str: string,
  divider: string = " "
): string => {
  return str
    .replace(/([a-z])([A-Z])/g, `$1${divider}$2`) // Insert divider before capital letters
    .replace(/([A-Z]+)([A-Z][a-z])/g, `$1${divider}$2`); // Handle cases like "HTMLParser" -> "HTML Parser"
};
