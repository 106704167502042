/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useMemo, useRef, useState } from "react";

import GAME_CONFIG from "game-files/gameConfig";
import Text from "game-engine/components/basic-elements/Text";
import { TranslatedString } from "game-engine/types";
import { getSceneByUniqueId } from "game-files/scenes/SCENE_LAYOUTS";
import { getSceneNameByCurrentObjective } from "game-engine/utils";
import useGame from "game-engine/hooks/useGame";

const InfoText = () => {
  const { gamePlay, gameFns, logger } = useGame();

  //
  // CURRENT SCENE DATA
  //
  const currentScene = useMemo(() => {
    return getSceneByUniqueId(gamePlay?.state?.currentScene?.uniqueSceneId);
  }, [gamePlay?.state?.currentScene]);

  const currentSceneName = useMemo(() => {
    if (!currentScene) {
      return undefined;
    }
    return getSceneNameByCurrentObjective({
      dataByCurrentObjective: currentScene?.name,
      currentObjective: gameFns.getCurrentObjective(),
      events: gameFns.getEvents(),
    });
  }, [currentScene, gamePlay.state.currentObjective, gamePlay.state.events]);

  //
  // DISPLAYED TEXT
  //
  const [text, setText] = useState<TranslatedString>();

  const [forceReload, setForceReload] = useState(0);
  const forceReloadRef = useRef(forceReload);

  useEffect(() => {
    forceReloadRef.current = forceReload;
  }, [forceReload]);

  useEffect(() => {
    setText(currentSceneName || { en: "", cz: "" });
  }, [currentSceneName, gamePlay?.state?.currentScene.cinematic, forceReload]);

  //
  // DISPLAYED TEXT - OVERRIDE
  //
  const actionLabelTimerRef = useRef<any>();

  const setOverrideText = () => {
    if (gamePlay?.state?.labelOverride?.label) {
      setText(gamePlay.state.labelOverride?.label);
      gameFns.setOverrideLabel(undefined); // immediately reset override text
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (setOverrideText()) {
      if (gamePlay?.state?.labelOverride?.durationSec === "permanent") {
        return;
      }
      let pause =
        gamePlay?.state?.labelOverride?.durationSec ??
        GAME_CONFIG.actions.labelOverrideTimeoutSec;

      clearTimeout(actionLabelTimerRef.current);
      actionLabelTimerRef.current = setTimeout(() => {
        setForceReload(forceReloadRef.current + 1);
        logger.info("action label set to NULL");
      }, pause * 1000);
    }
  }, [gamePlay?.state?.labelOverride]);

  useEffect(() => {
    return () => {
      clearTimeout(actionLabelTimerRef.current);
    };
  }, []);

  //
  // RENDER
  //
  return <Text position={GAME_CONFIG.gui.label.origin} text={text} />;
};

export default InfoText;
