import Button from "../../Button";
import styled from "styled-components";
import { useId } from "react";

const Wrapper = styled.label`
  cursor: pointer;

  label {
    width: 100px;
    height: 50px;
  }

  button {
    width: 100%;
    pointer-events: none;
  }
`;

//
// COMPONENT
//
const FileInput = (props: {
  onFilesLoaded?: (files: File[]) => void;
  onFileLoaded?: (file: File) => void;
  children?: any;
}) => {
  const uniqueId = useId(); // Generate a unique ID

  //
  // RENDER
  //
  return (
    <Wrapper htmlFor={`file-input-${uniqueId}`}>
      <input
        id={`file-input-${uniqueId}`} // Use unique ID here
        style={{ display: "none" }}
        type="file"
        multiple={props.onFilesLoaded !== undefined}
        onChange={(e) => {
          const { files } = e.target;
          if (props.onFilesLoaded) {
            props.onFilesLoaded(Array.from(files));
          } else if (props.onFileLoaded && files.length === 1) {
            props.onFileLoaded(files[0]);
          }
        }}
      />
      <Button isActive>{props.children}</Button>
    </Wrapper>
  );
};

export default FileInput;
