export const storeInLocalStorage = (key: string, data: string): void => {
  // Directly store the string data in localStorage
  localStorage.setItem(key, data);
};

export const loadFromLocalStorage = (key: string): string | null => {
  // Retrieve the string data from localStorage
  const data = localStorage.getItem(key);
  return data; // Return the data as a string or null if not found
};

export const deleteFromLocalStorage = (key: string): void => {
  // Remove the string data associated with the key from localStorage
  localStorage.removeItem(key);
};
