import { GamePlayCurrentSkillType, SkillType } from "game-engine/types";
import { useContext, useEffect, useState } from "react";

import { ACTION } from "game-engine/utils";
import Box from "../../../../basic-components/Box";
import Button from "game-engine/_dev/basic-components/Button";
import { DevToolsContext } from "game-engine";
import Divider from "game-engine/_dev/basic-components/Divider";
import GAME_CONFIG from "game-files/gameConfig";
import JsonView from "game-engine/_dev/basic-components/JsonView";
import SKILL_CONFIGS from "game-files/skills/SKILL_CONFIGS";
import { SkillId } from "game-files/common/ids";
import styled from "styled-components";
import useGame from "game-engine/hooks/useGame";

const InvisibilityImagePreview = styled.div`
  width: 100%;
  aspect-ratio: ${GAME_CONFIG.scene.dimensions.x} /
    ${GAME_CONFIG.scene.dimensions.y};

  background: #fff1;

  img {
    width: 100%;
    height: 100%;
    object-fit: "contain";
  }
`;

const SectionSkills = (props: { isOpen?: boolean }) => {
  const { gamePlay } = useGame();
  const devTools = useContext(DevToolsContext);

  const runActions = devTools.state.actions.runActionsRef?.current;

  const [isDisabled, setIsDisabled] = useState(!runActions);
  useEffect(() => {
    setIsDisabled(!runActions);
  }, [runActions]);

  //
  // ACTIONS
  //
  const actionUseSkill = (skillId: SkillId) => {
    runActions([ACTION.startSkill(skillId)]);
  };

  const actionStopSkill = (skillId: SkillId) => {
    runActions([ACTION.stopSkill(skillId)]);
  };

  const actionResetSkill = (skillId: SkillId) => {
    runActions([ACTION.resetSkill(skillId)]);
  };

  //
  // RENDER
  //
  return (
    <Box
      label="Skills"
      childMinWidth="80%"
      contentPaddingBottom="20px"
      isOpen={props.isOpen}
      level={2}
      gap="30px"
    >
      <Box renderContentOnly childMinWidth="80%" gap="20px" isOpen>
        {Object.entries(SKILL_CONFIGS).map((entry) => {
          const skillId = entry[0] as SkillId;
          const skill = entry[1] as SkillType;

          const gamePlaySkill: GamePlayCurrentSkillType = gamePlay.state
            .currentSkills
            ? gamePlay.state.currentSkills[skillId]
            : undefined;

          const canStart = !gamePlaySkill?.isActive && !gamePlaySkill?.isPaused;
          const canStop = gamePlaySkill?.isActive;
          const canReset = gamePlaySkill;

          return (
            <Box
              renderContentOnly
              useFlex
              childMinWidth="80%"
              isOpen
              key={skillId}
            >
              <Divider name={`${skill.name.en}:`} marginTop="0px" />

              <Box renderContentOnly isOpen key={skillId} minWidth="60%">
                <Button
                  isDisabled={isDisabled || !canStart}
                  onClick={() => actionUseSkill(skillId)}
                >
                  {`Start ${skillId}`}
                </Button>

                <Button
                  isDisabled={isDisabled || !canStop}
                  onClick={() => actionStopSkill(skillId)}
                >
                  {`Stop ${skillId}`}
                </Button>

                <Button
                  isDisabled={isDisabled || !canReset}
                  onClick={() => actionResetSkill(skillId)}
                >
                  {`Reset ${skillId}`}
                </Button>
              </Box>
            </Box>
          );
        })}
      </Box>

      <JsonView
        json={
          gamePlay.state.currentSkills ?? {
            message: "No skills are currently in use.",
          }
        }
        autoExpandToLevel={3}
        hideRoot
      />

      {gamePlay.state.currentSkills?.Invisibility?.isActive ? (
        <Box
          label="Invisibility effect layers"
          level={2}
          useFlex
          childMinWidth="80%"
          isOpen
        >
          <Box renderContentOnly childMinWidth="40%" isOpen>
            <InvisibilityImagePreview>
              <img id="invisibility-image-preview-background" alt="" />
            </InvisibilityImagePreview>

            <InvisibilityImagePreview>
              <img id="invisibility-image-preview-character" alt="" />
            </InvisibilityImagePreview>

            <InvisibilityImagePreview>
              <img id="invisibility-image-preview-foreground" alt="" />
            </InvisibilityImagePreview>

            <InvisibilityImagePreview>
              <img id="invisibility-image-preview-displacement" alt="" />
            </InvisibilityImagePreview>
          </Box>

          <Box renderContentOnly childMinWidth="320px" isOpen>
            <InvisibilityImagePreview>
              <img id="invisibility-image-preview-final" alt="" />
            </InvisibilityImagePreview>
          </Box>
        </Box>
      ) : null}
    </Box>
  );
};

export default SectionSkills;
