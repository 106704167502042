/* eslint-disable react-hooks/exhaustive-deps */

import { Group, Image } from "react-konva";

import React from "react";

const VideoSprite = (props: {
  src: string;
  onVideoLoaded?: () => void;
  onEnd?: () => void;
  play?: boolean;
  playCount?: number;
  fps?: number;
  frameDurationMilliseconds?: number;
  width?: number;
  height?: number;
  position?: { x: number; y: number };
}) => {
  const {
    src,
    onVideoLoaded,
    onEnd,
    play = true,
    playCount = -1, // Number of times to loop the video
    fps = 30, // Original FPS of the video
    frameDurationMilliseconds = 1000, // Duration of each frame in milliseconds
    width = 100,
    height = 100,
    position = { x: 0, y: 0 },
  } = props;

  const imageRef = React.useRef(null);
  const videoRef = React.useRef(null);
  const [size, setSize] = React.useState({ width: 50, height: 50 });
  const [frameCount, setFrameCount] = React.useState(0);
  const [currentFrameIndex, setCurrentFrameIndex] = React.useState(0);

  // Create video element
  const videoElement = React.useMemo(() => {
    if (!videoRef.current) {
      const element = document.createElement("video");
      element.src = src;
      element.muted = true; // Mute the video
      element.preload = "auto"; // Preload the video for better performance
      videoRef.current = element;
    }
    return videoRef.current;
  }, [src]);

  // Load video metadata and set size
  React.useEffect(() => {
    const onload = () => {
      setSize({
        width: videoElement.videoWidth || width,
        height: videoElement.videoHeight || height,
      });
      setFrameCount(Math.floor(videoElement.duration * fps)); // Total frame count
      if (onVideoLoaded) onVideoLoaded(); // Call the onVideoLoaded prop if provided
      videoElement.pause(); // Ensure video is paused initially
    };

    videoElement.addEventListener("loadedmetadata", onload);
    return () => {
      videoElement.removeEventListener("loadedmetadata", onload);
    };
  }, [videoElement, onVideoLoaded, width, height, fps]);

  // Timer for frame updates
  React.useEffect(() => {
    let playCounter = 0; // Counter for how many times the video has played

    const frameDuration = 1000 / fps; // Calculate the duration for each frame based on FPS

    const frameTimer = setInterval(() => {
      if (play) {
        setCurrentFrameIndex((prevIndex) => {
          const newIndex = prevIndex + 1; // Calculate the next frame index

          // Set video current time based on the new frame duration
          videoElement.currentTime = (newIndex * frameDuration) / 1000;

          // Check if the new index exceeds the frame count
          if (newIndex >= frameCount) {
            playCounter += 1; // Increment play counter
            if (playCount !== -1 && playCounter >= playCount) {
              clearInterval(frameTimer); // Stop the timer if we've reached the play count
              if (onEnd) {
                onEnd();
              }
              videoElement.pause(); // Pause the video
              return frameCount - 1; // Keep on the last frame
            } else {
              return 0; // Reset to the first frame
            }
          }
          return newIndex; // Update the index
        });
      }
    }, frameDurationMilliseconds); // Set interval based on frame duration

    return () => {
      clearInterval(frameTimer); // Cleanup interval
    };
  }, [videoElement, play, playCount, frameCount, fps]);

  // Update the image source based on the current frame index
  React.useEffect(() => {
    if (imageRef.current) {
      imageRef.current.src = videoElement.src; // Use the video source for the image
    }
  }, [currentFrameIndex, videoElement]);

  return (
    <Group position={position}>
      <Image
        ref={imageRef}
        image={videoElement}
        width={size.width}
        height={size.height}
      />
    </Group>
  );
};

export default React.memo(VideoSprite);
