import GAME_CONFIG from "game-files/gameConfig";
import { Group } from "react-konva";
import RectBeveled from "game-engine/components/basic-elements/RectBeveled";

const MenuBevelBox = (props: {
  children?: any;
  x?: number;
  y?: number;
  width: number;
  height: number;
  isPushed?: boolean;
  centered?: boolean;
  color?: string;
}) => {
  const { x, y, width, height, centered, isPushed = false, color } = props;

  const colorBackground = color ? color : GAME_CONFIG.menu.colorMain;
  const colorHighlight = color ? undefined : GAME_CONFIG.menu.colorHighlight; // undefined will ensure highlight based on the given color
  const colorShadow = color ? undefined : GAME_CONFIG.menu.colorShadow; //       undefined will ensure shadow    based on the given color
  const bevel = isPushed ? 1 : 2;

  return (
    <Group x={centered ? Math.round(x - width / 2) : x} y={y}>
      <RectBeveled
        width={width}
        height={height}
        color={colorBackground}
        colorHighlight={colorHighlight}
        colorShadow={colorShadow}
        bevel={bevel}
      />
      <Group>{props.children}</Group>
    </Group>
  );
};

export default MenuBevelBox;
