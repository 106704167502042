import styled, { ThemeProvider } from "styled-components";

import GameEngine from "game-engine";
import GlobalStyle from "styles/GlobalStyle";
import React from "react";
import ReactDOM from "react-dom/client";
import theme from "styles/theme";

const Wrapper = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 40px;
`;

const App = () => {
  return (
    <Wrapper>
      <GameEngine />
    </Wrapper>
  );
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <App />
    </ThemeProvider>
  </React.StrictMode>
);
