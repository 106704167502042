import {
  ActionFacingProps,
  ActionId,
  Action_ItemInCursorSwapConfig,
} from "game-engine/types";

import { ItemConfigId } from "game-files/common/ids";
import { createActionName } from ".";

//
// CREATE BASIC ACTION THAT SWAPS ITEM CONFIG FOR ITEM IN CURSOR
//
type SwapConfigProps = {
  newItemConfigId: ItemConfigId;
  blockGui?: boolean; // swapConfig is only 1 frame in duration, which causes CURSOR FLICKER if it is placed between two pauses -> fix with blockGui
  unskippable?: boolean;
} & ActionFacingProps;

type SwapConfigReturn = () => Action_ItemInCursorSwapConfig;

export const swapItemInCursorConfig: (
  props: SwapConfigProps
) => SwapConfigReturn = (props) => () => {
  return {
    ...props,
    _name: createActionName({
      name: `replace item in cursor`,
      value: `(${props.newItemConfigId})`,
    }),
    id: ActionId.itemInCursorSwapConfig,
    blockGui: props.blockGui,
    unskippable: props.blockGui,
  };
};
