/* eslint-disable react-hooks/exhaustive-deps */

import {
  CharacterConfigType,
  CharacterRenderAnimationType,
  CharacterRenderMode,
} from "game-engine/types";
import { useEffect, useRef } from "react";

import GAME_CONFIG from "game-files/gameConfig";
import { getRandomIndex } from "game-engine/utils";
import useGame from "game-engine/hooks/useGame";
import useIdle from "game-engine/hooks/useIdle";

const useCharacterIdle = (props: {
  characterConfig: CharacterConfigType;
  idleAnimations: CharacterRenderAnimationType[];
  renderMode: CharacterRenderMode;
  setRenderMode: (m: CharacterRenderMode) => void;
}) => {
  const { logger, gamePlay } = useGame();
  const { characterConfig, idleAnimations, renderMode, setRenderMode } = props;

  //
  // IDLE
  //
  const { idleAfterMinSec, idleAfterMaxSec } = {
    ...GAME_CONFIG.character.animations.idle,
    ...characterConfig.idleConfig,
  };

  const { isIdle, startIdleTimer, clearIdleTimer } = useIdle({
    timeoutMinSec: idleAfterMinSec,
    timeoutMaxSec: idleAfterMaxSec,
  });

  const idleIndexRef = useRef<number>(
    getRandomIndex(idleAnimations?.length || 0, { excludeIndex: undefined })
  );

  useEffect(() => {
    // Stop idle timer if set to ignore (typically when the character is a part of the action queue)
    if (
      gamePlay.state.ignoreIdle?.characterIds?.length &&
      gamePlay.state.ignoreIdle?.characterIds.includes(characterConfig.id)
    ) {
      clearIdleTimer();
    }
    // Start idle timer
    else if (
      idleAnimations?.length &&
      renderMode === CharacterRenderMode.default
    ) {
      startIdleTimer();
    }
  }, [renderMode, gamePlay.state.ignoreIdle?.characterIds]);

  useEffect(() => {
    if (isIdle && renderMode === CharacterRenderMode.default) {
      const excludeIndex = idleIndexRef.current;
      idleIndexRef.current = getRandomIndex(idleAnimations?.length || 0, {
        excludeIndex,
      });
      setRenderMode(CharacterRenderMode.idle);
      logger.action(
        `character idle started (${characterConfig.id})`,
        characterConfig
      );
    }
  }, [isIdle]);

  const onIdleEnd = () => {
    if (renderMode === CharacterRenderMode.idle) {
      setRenderMode(CharacterRenderMode.default);
      logger.action(
        `character idle ended (${characterConfig.id})`,
        characterConfig
      );
    }
  };

  //
  // RETURN
  //
  return { onIdleEnd, idleIndex: idleIndexRef.current };
};

export default useCharacterIdle;
