import GAME_CONFIG from "game-files/gameConfig";
import MenuButtonsLeftRight from "../menu-elements/MenuButtonsLeftRight";
import { MenuProps } from "..";
import MenuWrapper from "../menu-elements/MenuWrapper";
import { TranslatedString } from "game-engine/types";

const MenuDialogConfirm = (
  props: MenuProps & {
    width?: number;
    height?: number;
    onSubmit?: () => void;
    title: TranslatedString;
    titlePaddingX?: number;
    titleIsWarning?: boolean;
    titleIsError?: boolean;
    titleMinHeight?: number;
    submitLabel?: TranslatedString;
    submitWidth?: number;
    submitHidden?: boolean;
    cancelLabel?: TranslatedString;
    cancelWidth?: number;
    cancelHidden?: boolean;
  }
) => {
  const {
    isActive,
    closeSubMenu,
    onSubmit,
    width,
    height = 75,
    title,
    titlePaddingX = 0,
    titleIsError = false,
    titleIsWarning = false,
    titleMinHeight = 0,
    submitLabel = { en: "Confirm", cz: "Potvrdit" },
    submitWidth = 88,
    submitHidden = false,
    cancelLabel = { en: "Cancel", cz: "Zrušit" },
    cancelWidth = 88,
    cancelHidden = false,
  } = props;

  const canvasX = GAME_CONFIG.window.canvasDimensions.x;

  const menuWidth = width || canvasX - 30;
  const menuHeight = height;

  const contentPaddingX = 20;
  const contentWidth = menuWidth - contentPaddingX * 2;

  //
  // RENDER
  //
  return (
    <MenuWrapper
      isActive={isActive}
      width={menuWidth}
      height={menuHeight}
      title={title}
      titleGap={0}
      titlePaddingX={titlePaddingX}
      titleIsWarning={titleIsWarning}
      titleIsError={titleIsError}
      titleMinHeight={titleMinHeight}
      gap={10}
      paddingTop={12}
      paddingLeft={contentPaddingX}
    >
      <MenuButtonsLeftRight
        width={contentWidth}
        button1={{
          label: submitLabel,
          width: submitWidth,
          onClick: onSubmit,
          isHidden: submitHidden,
        }}
        button2={{
          label: cancelLabel,
          width: cancelWidth,
          onClick: closeSubMenu,
          isHidden: cancelHidden,
        }}
      />
    </MenuWrapper>
  );
};

export default MenuDialogConfirm;
