import { ACTION } from "game-engine/utils";
import { GameObjective } from "game-files/gameObjectives";
import { SkillId } from "game-files/common/ids";
import { SkillType } from "game-engine/types";

const CelestialFlame: SkillType = {
  id: SkillId.CelestialFlame,

  name: { en: "Celestial Flame", cz: "Nebeský plamen" },

  guiSkillSlot: undefined,

  states: {
    [GameObjective.GAME_START]: {
      state_start: {
        getActions: ({ gamePlay, gameItems }) => [
          ACTION.talk({
            dialog: { en: "Let's do this.", cz: "Jdeme na to." },
          }),

          // TODO - ADD ACTION.characterAnimation FOR THE TRANSFORMATION FROM LANTERN
        ],
      },

      state_active: {
        duration: {
          sceneChangeCount: 1, // stop after each scene change
          durationSec: undefined, // keep until scene changes
        },
      },

      state_stop: {
        ignoreStopIf: {
          deathByDarkness: true,
        },
        getActions: ({ gamePlay, gameItems }) => [
          // TODO - ADD ACTION.characterAnimation FOR THE TRANSFORMATION TO LANTERN

          ACTION.talk({
            dialog: { en: "What a feeling!", cz: "To bylo něco!" },
          }),
        ],
      },

      state_waitForReset: {
        duration: undefined,
      },
    },
  },

  dynamicLighting: {
    darkScenes: {
      lightSource: {
        tint: {
          color: `#00b3ff`,
          amount: 1,
        },
        flicker: {
          color: `#afe7ff`,
          amount: 0.05,
          stepDurationSec: 0.5,
        },
      },
    },

    regularScenes: {
      lightSource: {
        tint: {
          color: `#00b3ff`,
          amount: 0.2,
        },
        flicker: {
          color: `#afe7ff`,
          amount: 0.05,
          stepDurationSec: 0.5,
        },
      },

      sceneFilters: {
        light: {
          brightness: 1,
          contrast: 1,
          saturation: 1,
        },
        shadowLayers: {
          brightness: 0.95,
          contrast: 0.96,
          saturation: 1,
        },
        shadowForeground: {
          brightness: 0.9,
          contrast: 0.9,
          saturation: 1,
        },
      },
    },
  },
};

export default CelestialFlame;
