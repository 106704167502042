import Button from 'game-engine/_dev/basic-components/Button'
import { EditorType } from '../..'
import { getSceneLayoutCode } from '../../utils/code'
import styled from 'styled-components'

const Footer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap-reverse;
  gap: 10px;

  padding: 20px 0;

  button {
    flex: 1;
    align-items: center;
    justify-content: center;
    text-align: center;
    max-width: 300px;
    font-size: 14px;
    font-weight: 600;
  }

  ${(props) => props.theme.breakpoints.S} {
    flex-direction: column-reverse;
    align-items: stretch;
    justify-content: stretch;
    button {
      max-width: 100%;
    }
  }
`

//
// COMPONENT
//
const EditorFooter = (props: {
  editor: EditorType
  setEditor: (e: EditorType) => void
}) => {
  const { editor, setEditor } = props

  //
  // RENDER
  //
  return (
    <Footer>
      <Button
        onClick={() => {
          setEditor(undefined)
        }}
      >
        {'Reset'}
      </Button>

      <Button
        onClick={() => {
          navigator.clipboard.writeText(
            getSceneLayoutCode({
              editor,
            })
          )
        }}
        isActive
        clickFeedbackMessage="copied"
      >
        {'Copy Scenes Layout Code'}
      </Button>
    </Footer>
  )
}

export default EditorFooter
