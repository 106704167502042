/* eslint-disable react-hooks/exhaustive-deps */

import { Group, Rect } from "react-konva";
import { ItemType, Position, TextAlign } from "game-engine/types";
import React, { useCallback, useMemo } from "react";

import CursorGlyph from "./cursor-glyph";
import CursorGlyphs from "game-engine/glyphs/Cursor";
import GAME_CONFIG from "game-files/gameConfig";
import ITEM_CONFIGS from "game-files/items/ITEM_CONFIGS";
import Item from "../Item";
import { PixelMatrixType } from "game-engine/utils";
import Pixels from "game-engine/components/basic-elements/Pixels";
import Text from "game-engine/components/basic-elements/Text";
import useGame from "game-engine/hooks/useGame";

export enum DevCursorType {
  LineHorizontal = "line-horizontal",
  LineVertical = "line-vertical",
  Cross = "cross",
  Coordinates = "coordinates",
}
const Cursor = (props: { position: Position; devCursor?: DevCursorType }) => {
  const { gameItems, engineConfig, sceneOffset, cursor } = useGame();

  const isHidden = cursor.state.isHidden;
  const opacity = isHidden
    ? 0
    : engineConfig.state.renderBrowserCursor
    ? 0.5
    : 1;

  //
  // POSITION
  //
  const position = {
    x: Math.round(props.position?.x || 0),
    y: Math.round(props.position?.y || 0),
  };

  //
  // SHAPE
  //
  const glyphs = CursorGlyphs;

  const coordinatesCursor: PixelMatrixType = useMemo(
    () => [
      [1, 1, 1],
      [1, 0, 1],
      [1, 1, 1],
    ],
    []
  );

  const getLine = (direction: "horizontal" | "vertical") => {
    const { x: sceneWidth, y: sceneHeight } = GAME_CONFIG.scene.dimensions;
    const lineWidth = 1;
    const color = "#f008";

    return direction === "horizontal" ? (
      <Rect
        width={sceneWidth * 3}
        height={lineWidth}
        x={-sceneWidth}
        fill={color}
      />
    ) : (
      <Rect
        width={lineWidth}
        height={sceneHeight * 3}
        y={-sceneHeight}
        fill={color}
      />
    );
  };

  const getDevCursor = () => {
    if (props.devCursor === DevCursorType.LineHorizontal) {
      return getLine("horizontal");
    }

    if (props.devCursor === DevCursorType.LineVertical) {
      return getLine("vertical");
    }

    if (props.devCursor === DevCursorType.Cross) {
      return (
        <Group>
          {getLine("horizontal")}
          {getLine("vertical")}
        </Group>
      );
    }

    // coordinates
    return (
      <Group>
        <Pixels
          position={{
            x: -2,
            y: -2,
          }}
          matrix={coordinatesCursor}
          opacity={opacity}
          outlined
        />
        <Text
          position={{ x: 0, y: 3 }}
          text={`${position.x - sceneOffset.x}x${position.y - sceneOffset.y}`}
          color={`#ffffff`}
          align={TextAlign.center}
        />
      </Group>
    );
  };

  const renderGrabbedItem = useCallback(() => {
    return (
      <>
        {Object.values(ITEM_CONFIGS).map((itemConfig) => {
          const position = { x: 0, y: -1 };

          const itemInCursor: ItemType = {
            ...(gameItems.state.itemInCursor || {}),
            id: gameItems.state.itemInCursor?.id,
            configId: itemConfig.configId,
            inCursor: true,
          };

          const isActive =
            gameItems.state.itemInCursor?.configId === itemConfig.configId;

          if (itemConfig.lightSourceDecay?.bySceneChange?.length) {
            return (
              <React.Fragment key={itemConfig.configId}>
                {itemConfig.lightSourceDecay.bySceneChange.map(
                  (_, decayIndex) => {
                    return (
                      <Item
                        key={`${itemConfig.configId}_decay-${decayIndex}`}
                        item={itemInCursor}
                        position={position}
                        isHidden={
                          !(
                            isActive &&
                            itemInCursor.sceneChangeCounter === decayIndex
                          )
                        }
                        cursorRender={{ decayIndex }}
                      />
                    );
                  }
                )}
              </React.Fragment>
            );
          }

          return (
            <Item
              key={itemConfig.configId}
              item={itemInCursor}
              position={position}
              isHidden={!isActive}
            />
          );
        })}
      </>
    );
  }, [gameItems.state.itemInCursor]);

  //
  // RENDER
  //
  return (
    <Group position={position} listening={false}>
      {engineConfig.state.renderSceneCoordinatesCursor || props.devCursor ? (
        <>
          {/* DEV CURSOR */}
          {getDevCursor()}
        </>
      ) : (
        <>
          {/* GAME CURSORS */}
          <Group opacity={opacity}>
            <Group opacity={gameItems.state.itemInCursor ? 0 : 1}>
              {/* DEFAULT CURSOR SHAPES */}
              {Object.entries(glyphs).map(([glyphName, glyphData]) => (
                <CursorGlyph
                  key={glyphName}
                  opacity={glyphName === cursor.state.renderMode ? 1 : 0}
                  cursorGlyph={glyphData}
                />
              ))}
            </Group>

            {/* GRABBED ITEM */}
            {renderGrabbedItem()}
          </Group>
        </>
      )}
    </Group>
  );
};

export default Cursor;
