import Box from "game-engine/_dev/basic-components/Box";
import ButtonPlayAudio from "game-engine/_dev/basic-components/ButtonPlayAudio";
import SOUND_CONFIGS from "game-files/audio/SOUND_CONFIGS";

const AssetsAudio = (props: { isOpen?: boolean }) => {
  //
  // RENDER
  //
  return (
    <Box
      label="Sound Library"
      isOpen={props.isOpen}
      level={1}
      childMinWidth="90%"
      gap="10px"
    >
      {Object.values(SOUND_CONFIGS)
        .sort((a, b) => (a.id < b.id ? -1 : 1))
        .map((soundConfig) => (
          <ButtonPlayAudio
            key={soundConfig.id}
            name={soundConfig.id}
            src={soundConfig.src}
            volume={soundConfig.volume}
          />
        ))}
    </Box>
  );
};

export default AssetsAudio;
