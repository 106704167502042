/* eslint-disable react-hooks/exhaustive-deps */

import { AudioSettingsTypeEnum, GameAudioType } from "game-engine/types";

import { getSoundByConfigId } from "game-files/audio/SOUND_CONFIGS";
import useAudio from "game-engine/hooks/useAudio";
import { useEffect } from "react";

const Sounds = (props: { gameAudio: GameAudioType }) => {
  const { gameAudio } = props;

  const { audioRef, activeAudio, setActiveAudio, stopAudio } = useAudio({
    type: AudioSettingsTypeEnum.sound,
  });

  //
  // LOGIC
  //
  const setSounds = () => {
    if (gameAudio?.sound?.soundConfigId) {
      const backgroundSound = gameAudio?.sound;
      const audioConfig = getSoundByConfigId(backgroundSound?.soundConfigId);

      if (audioConfig) {
        if (audioConfig.id !== activeAudio?.audioConfig?.id) {
          setActiveAudio({
            audioConfig,
            volume: backgroundSound.volume ?? 1,
            loop: backgroundSound.loop ?? true,
            onEnd: () => {
              // callback after audio finishes playing
            },
          });
        }
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    setSounds() || stopAudio();
  }, [gameAudio?.sound?.soundConfigId]);

  //
  // RENDER
  //
  return <audio ref={audioRef} src={activeAudio?.audioConfig?.src} />;
};

export default Sounds;
