import {
  CinematicScreenSize,
  CinematicScreenType,
  CinematicTypeEnum,
  SpriteDirection,
} from "game-engine/types";

import { CinematicId } from "game-files/common/ids";
import GAME_CONFIG from "game-files/gameConfig";
import TestBackgroundSky03_Background from "./assets/test-background-sky-03.jpg";
import TestBackgroundSky03_StarsSprite from "./assets/test-background-sky-03-stars-sprite.png";

const TestCinematicScreen04: CinematicScreenType = {
  id: CinematicId.TestCinematicScreen04,
  type: CinematicTypeEnum.screen,
  size: CinematicScreenSize.viewport,

  fadeIn: true,
  fadeOut: true,

  background: {
    color: "red",

    position: { x: 0, y: 0 },

    imageComposition: {
      layers: [
        {
          image: {
            src: TestBackgroundSky03_Background,
            width: 340,
            height: 350, // necessary for background transforms, otherwise the image is clipped to scene size
          },
        },
        {
          image: { src: TestBackgroundSky03_StarsSprite },
          spriteConfig: {
            direction: SpriteDirection.Vertical,
            frameWidth: 340,
            frameHeight: 206,
            frameCount: 30,
            frameDurationMilliseconds: 50,
          },
        },
      ],
    },
  },

  sequence: [
    {
      pauseUntilFinished: true,
      addLayer: {
        text: {
          en: "Test",
          cz: "Test",
        },
      },
    },

    {
      pause: { durationSec: 1 },
    },

    {
      pauseUntilFinished: true,
      animateBackground: {
        durationSec: 2,
        offsetY: GAME_CONFIG.scene.dimensions.y - 250,
      },
    },

    {
      pause: { durationSec: 1 },
    },

    {
      pauseUntilFinished: true,
      addLayer: {
        text: {
          en: "That was a background animation.",
          cz: "To byla animace pozadí.",
        },
      },
    },
  ],
};

export default TestCinematicScreen04;
