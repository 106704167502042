import { OnSceneMouseClick, SceneLayerMeta } from "../../..";
import { SceneLayerDataType, SceneLayerType } from "game-engine/types";

import ActionsTalk from "../../ActionsTalk";
import BoxWithThumbnail from "game-engine/_dev/basic-components/Box/BoxWithThumbnail";
import { GameObjective } from "game-files/gameObjectives";
import General from "./General";

const SceneLayerSettings = (props: {
  layer: SceneLayerType;
  changeSceneLayerImage: (
    sceneLayer: SceneLayerType,
    newImage: { src: string; filename: string }
  ) => void;
  deleteSceneLayer: (sceneLayer: SceneLayerType) => void;
  updateLayer: (sceneLayer: SceneLayerType) => void;
  updateLayerData: (
    sceneLayerId: string,
    layerData: SceneLayerDataType
  ) => void;
  layerMeta: SceneLayerMeta;
  updateLayerMeta: (layerMeta: SceneLayerMeta) => void;
  onSceneMouseClick: OnSceneMouseClick;
  setOnSceneMouseClick: (v: OnSceneMouseClick) => void;
}) => {
  const {
    layer,
    changeSceneLayerImage,
    deleteSceneLayer,
    updateLayer,
    updateLayerData,
    layerMeta,
    updateLayerMeta,
    onSceneMouseClick,
    setOnSceneMouseClick,
  } = props;

  //
  // RENDER
  //
  return (
    <BoxWithThumbnail
      label={layer.id}
      subLabel={`depthY: ${
        layerMeta.isInFront
          ? "foreground"
          : layerMeta.isInBack
          ? "background"
          : layer.depthY || 0
      }`}
      level={2}
      imageSrc={
        layer.dataByCurrentObjective[GameObjective.GAME_START].image.src
      }
      largeImageWhenOpen={{
        onImageChange: (image, filename) =>
          changeSceneLayerImage(layer, { src: image, filename }),
      }}
      trashIcon={{ onClick: () => deleteSceneLayer(layer) }}
      contentPaddingTop="10px"
      contentPaddingLeft="15px"
      contentPaddingRight="15px"
      useFlex
      childMinWidth="90%"
    >
      <General
        layer={layer}
        updateLayer={updateLayer}
        updateLayerData={updateLayerData}
        layerMeta={layerMeta}
        updateLayerMeta={updateLayerMeta}
        onSceneMouseClick={onSceneMouseClick}
        setOnSceneMouseClick={setOnSceneMouseClick}
      />

      <ActionsTalk
        dialogActions={layerMeta.dialogActions}
        setDialogActions={(dialogActions) =>
          updateLayerMeta({
            ...layerMeta,
            dialogActions,
          })
        }
      />
    </BoxWithThumbnail>
  );
};

export default SceneLayerSettings;
