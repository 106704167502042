import { createGlobalStyle } from "styled-components";
import devToolsConfig from "game-engine/configs/devtools-config";
import theme from "styles/theme";

const GlobalStyle = createGlobalStyle<{ theme: typeof theme }>`
  html {
    color: ${(props) => props.theme.colors.white};
    background: ${(props) => props.theme.colors.black};
  }

  body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100vh;
    
    font-size: 14px;
    font-family: 'Montserrat', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    
    /* SCROLLBAR */
    &::-webkit-scrollbar, *::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }
    &::-webkit-scrollbar-track, *::-webkit-scrollbar-track {
      background: ${devToolsConfig.background};
    }
    &::-webkit-scrollbar-thumb,*::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background: #fff2;
      &:hover {
      background: #fff6;
      }
    }
  }

  #root{
    width: 100%;
    height: 100%;
  }

`;

export default GlobalStyle;
