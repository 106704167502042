import styled, { css } from "styled-components";

import ShapePlus from "../ShapePlus";

const Wrapper = styled.div<{ isOpen: boolean }>`
  cursor: pointer;
  display: flex;
  gap: 6px;
  user-select: none;
  opacity: 0.7;

  ${(props) =>
    !props.isOpen &&
    css`
      opacity: 0.4;
    `}
`;

const Name = styled.div<{ isOpen: boolean }>`
  margin-top: 3px;
  font-size: 12px;
  font-weight: 600;

  ${(props) => !props.isOpen && css``}
`;

const Button = styled.div`
  cursor: pointer;
  user-select: none;
`;

const ExpandButton = (props: {
  isOpen: boolean;
  name?: string;
  namePosition?: "left" | "right";
  onClick?: () => void;
}) => {
  const namePosition = props.namePosition || "left";

  //
  // RENDER
  //
  return (
    <Wrapper isOpen={props.isOpen} onClick={props.onClick}>
      {namePosition === "left" && props.name && (
        <Name isOpen={props.isOpen}>{props.name}</Name>
      )}

      <Button>
        <ShapePlus rotate={props.isOpen ? 135 : 0} transitionSec={0.2} />
      </Button>

      {namePosition === "right" && props.name && (
        <Name isOpen={props.isOpen}>{props.name}</Name>
      )}
    </Wrapper>
  );
};

export default ExpandButton;
