import { JSONTree } from "react-json-tree";
import devToolsConfig from "game-engine/configs/devtools-config";
import styled from "styled-components";

const Wrapper = styled.div`
  background: ${devToolsConfig.jsonBackground};
  padding: 0 6px;
  overflow: auto;

  & * {
    white-space: nowrap;
  }
`;

const JsonView = (props: {
  json: object;
  autoExpandToLevel?: number;
  hideRoot?: boolean;
}) => {
  const theme = {
    scheme: "monokai",
    author: "wimer hazenberg (http://www.monokai.nl)",
    base00: "#0000", // background
    base03: "#fff2", // type
    base0D: "#fff8", // key
    base08: "#ffa1a1BB", // value - function
    base09: "#ddff99BB", // value - number
    base0B: "#77c2ffBB", // value - string
    base01: "#383830",
    base02: "#49483e",
    base04: "#a59f85",
    base05: "#f8f8f2",
    base06: "#f5f4f1",
    base07: "#f9f8f5",
    base0A: "#f4bf75",
    base0C: "#a1efe4",
    base0E: "#ae81ff",
    base0F: "#cc6633",
  };

  //
  // RENDER
  //
  return (
    <Wrapper>
      <JSONTree
        data={props.json}
        hideRoot={props.hideRoot}
        theme={theme}
        shouldExpandNodeInitially={
          props.autoExpandToLevel
            ? (keyPath, data, level) => level <= props.autoExpandToLevel
            : undefined
        }
      />
    </Wrapper>
  );
};

export default JsonView;
