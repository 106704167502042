import { EditorDialogActions } from "game-engine/_dev/scene-editor-create-new";
import { prettifyCode } from "./code-formatting";

export const getCode_SceneTalkActions = (
  dialogActions: EditorDialogActions
) => {
  if (
    dialogActions.sharedDialogOptionIds?.length ||
    dialogActions.customDialogOptions?.length
  ) {
    const codeLines = ["["];

    codeLines.push(`ACTION.talkRandom({
        dialogOptions: [`);

    dialogActions.sharedDialogOptionIds?.forEach((sharedDialogId) => {
      codeLines.push(`...SHARED_DATA.dialogOptions.${sharedDialogId},`);
    });

    dialogActions.customDialogOptions?.forEach((dialogOptionArray) => {
      if (dialogOptionArray.length === 1) {
        // dialog with single text
        codeLines.push(`{
                en: \`${dialogOptionArray[0].en}\`,
                cz: \`${dialogOptionArray[0].cz}\`,
            },`);
      }

      if (dialogOptionArray.length > 1) {
        // dialog with multiple parts of text
        codeLines.push("[\n");

        dialogOptionArray.forEach((dialogOption) =>
          codeLines.push(`{
                en: \`${dialogOption.en}\`,
                cz: \`${dialogOption.cz}\`,
            },`)
        );

        codeLines.push("],");
      }
    });

    codeLines.push(`],})`);

    codeLines.push("]");
    return prettifyCode(codeLines.join("\n"));
  }

  return "undefined";
};
