/* eslint-disable react-hooks/exhaustive-deps */

import { AudioConfigType, AudioSettingsTypeEnum } from "game-engine/types";
import { useCallback, useEffect, useRef, useState } from "react";

import GAME_CONFIG from "game-files/gameConfig";
import useGame from "./useGame";

type ActiveAudioType = {
  audioConfig?: AudioConfigType;
  volume?: number;
  loop?: boolean;
  onEnd?: () => void;
};

const useAudio = (props: { type: AudioSettingsTypeEnum }) => {
  const { type } = props;
  const { gamePlay, logger } = useGame();

  const [userInteracted, setUserInteracted] = useState<boolean>(false);
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const hasStartedPlayingRef = useRef<boolean>(false);

  //
  // ACTIVE AUDIO
  //
  const [activeAudio, setActiveAudioRaw] = useState<ActiveAudioType>({
    audioConfig: undefined,
  });

  const setActiveAudio = (a: ActiveAudioType) => {
    resetAudio();
    setActiveAudioRaw(a);
  };

  //
  // FUNCTIONS
  //
  const resetAudio = useCallback(() => {
    hasStartedPlayingRef.current = false;
    const audio = audioRef.current;
    if (audio) {
      audio.pause(); // Pause the audio
      audio.currentTime = 0; // Reset the time to 0 (start from beginning)
    }
  }, [activeAudio]);

  const stopAudio = useCallback(() => {
    if (activeAudio?.audioConfig?.id) {
      logger.audio(
        `audio stopped (${activeAudio?.audioConfig?.id})`,
        activeAudio
      );
      setActiveAudio({
        audioConfig: undefined,
      });
    }
  }, [resetAudio]);

  //
  // USER INTERACTION *REQUIRED* BY BROWSERS, MUSIC CANNOT BE PLAYED WITHOUT IT!
  //
  useEffect(() => {
    const handleUserInteraction = () => {
      if (!userInteracted) {
        setUserInteracted(true);
        logger.audio(
          `audio (${type}) - user interaction detected -> can play sounds/music`
        );
      }
    };

    // Attach event listeners for user interactions
    document.addEventListener("mousedown", handleUserInteraction, {
      once: true,
    });
    document.addEventListener("keydown", handleUserInteraction, { once: true });
    document.addEventListener("touchstart", handleUserInteraction, {
      once: true,
    });

    // Cleanup event listeners
    return () => {
      document.removeEventListener("mousedown", handleUserInteraction);
      document.removeEventListener("keydown", handleUserInteraction);
      document.removeEventListener("touchstart", handleUserInteraction);
    };
  }, [userInteracted]);

  // Play audio when user interacts and active audio is set
  useEffect(() => {
    const audio = audioRef.current;

    if (audio && activeAudio?.audioConfig) {
      let volumeFromConfig = GAME_CONFIG.audio.backgroundSoundVolume;
      let isAllowed = gamePlay.state.settings.playSounds;

      if (type === AudioSettingsTypeEnum.music) {
        volumeFromConfig = GAME_CONFIG.audio.backgroundMusicVolume;
        isAllowed = gamePlay.state.settings.playMusic;
      }

      // Set audio properties
      audio.loop = activeAudio.loop;
      audio.volume = Math.min(
        (activeAudio.volume ?? 1) *
          (activeAudio.audioConfig.volume ?? 1) *
          (volumeFromConfig ?? 1) *
          (GAME_CONFIG.audio.globalVolume ?? 1),
        1 // volume is limited to 1, otherwise an error is thrown
      );

      // Optional callback when the audio ends
      if (!activeAudio.loop && activeAudio.onEnd) {
        audio.onended = activeAudio.onEnd;
      }

      // Play audio if autoPlay is enabled and user has interacted
      if (isAllowed && userInteracted && !hasStartedPlayingRef.current) {
        audio.currentTime = 0; // Reset the time to 0 (start from beginning)
        audio
          .play()
          .then(() => {
            hasStartedPlayingRef.current = true;
          })
          .catch((error) => {
            console.error("error playing audio:", error);
          });

        logger.audio(
          `audio started (${activeAudio?.audioConfig?.id})`,
          activeAudio
        );
      }
    }

    // Cleanup
    return () => {
      if (audio) {
        resetAudio();
      }
    };
  }, [
    activeAudio,
    type === AudioSettingsTypeEnum.music
      ? gamePlay.state.settings.playMusic
      : gamePlay.state.settings.playSounds,
    userInteracted,
  ]);

  return {
    audioRef,
    activeAudio,
    setActiveAudio,
    resetAudio,
    stopAudio,
  };
};

export default useAudio;
