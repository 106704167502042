/* eslint-disable react-hooks/exhaustive-deps */

import { getEditorFromImages, getImageSize } from "../../utils/files";
import { useEffect, useState } from "react";

import { EditorType } from "../..";
import FileInput from "game-engine/_dev/basic-components/inputs/FileInput";
import GAME_CONFIG from "game-files/gameConfig";
import Tutorial from "game-engine/_dev/basic-components/Tutorial";
import styled from "styled-components";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
`;

const Warning = styled.div`
  color: #ff6b6b99;
`;

const InputFilesDescription = styled.div`
  padding: 10px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

export type ImageFileType = { file: any; imgWidth: number; imgHeight: number };

//
// COMPONENT
//
const SectionUploadImages = (props: { setEditor: (e: EditorType) => void }) => {
  const { setEditor } = props;

  const [imageFiles, setImageFiles] = useState<ImageFileType[]>();
  const [filesWarning, setFilesWarning] = useState<string>();

  //
  // DATA
  //
  useEffect(() => {
    if (imageFiles?.length) {
      setFilesWarning(undefined);
      setEditor(
        getEditorFromImages(imageFiles, {
          depthSettings: GAME_CONFIG.scene.defaultDepthSettings,
        })
      );
    }
  }, [imageFiles]);

  const onImagesLoaded = async (files: any[]) => {
    const processedFiles = await Promise.all(
      files.map(async (file) => {
        const { width, height } = await getImageSize(file);
        return {
          file: file,
          imgWidth: width,
          imgHeight: height,
        };
      })
    );
    setImageFiles(processedFiles);
  };

  //
  // RENDER
  //
  return (
    <Wrapper>
      <FileInput onFilesLoaded={onImagesLoaded}>
        <InputFilesDescription>Upload Scene Images</InputFilesDescription>
      </FileInput>

      <Tutorial
        items={[
          "Create scene images and render them to 'assets' folder",
          "Upload all scene images (preview, maps, background etc.) using the button above",
          "Configure scene settings in the right sidebar",
          "Use button in the footer to copy the final code",
          "Paste the code into 'index.ts' in scene folder",
          "(Do not forget to add the scene to SCENE_CONFIGS)",
        ]}
      />

      {filesWarning && <Warning>{filesWarning}</Warning>}
    </Wrapper>
  );
};

export default SectionUploadImages;
