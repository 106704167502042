//
// DOWNLOAD
//
export const downloadImage = (url: string, filename: string = "image.png") => {
  const link = document.createElement("a");
  link.href = url;
  link.download = filename;

  // Append the link to the body
  document.body.appendChild(link);

  // Programmatically click the link to trigger the download
  link.click();

  // Remove the link from the document
  document.body.removeChild(link);
};

//
// DOWNLOAD JSON
//
export const downloadJson = (props: {
  jsonString: string;
  filename: string;
}): void => {
  const { jsonString, filename } = props;

  // Create a blob from the JSON string
  const blob = new Blob([jsonString], { type: "application/json" });

  // Create an anchor element and set the download attribute
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = `${filename}.json`;

  // Append the anchor to the body, click it to trigger the download, and remove it
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

//
// UPLOAD JSON
//
const readJson = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      try {
        const text = event.target?.result as string;
        resolve(text); // Resolve with the file content as string
      } catch (error) {
        reject(new Error("Error parsing JSON"));
      }
    };

    reader.onerror = () => {
      reject(new Error("Failed to read file"));
    };

    reader.readAsText(file);
  });
};

export const uploadJson = (): Promise<string> => {
  return new Promise((resolve, reject) => {
    // Create a file input element
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.style.display = "none";
    fileInput.accept = ".json, application/json";

    fileInput.addEventListener("change", async (event) => {
      const file = (event.target as HTMLInputElement).files?.[0];
      if (file) {
        if (file.type === "application/json" || file.name.endsWith(".json")) {
          try {
            const fileContent = await readJson(file);
            resolve(fileContent); // Resolve with the JSON file content
          } catch (error) {
            reject(error);
          }
        } else {
          reject(new Error("Selected file is not a JSON file."));
        }
      } else {
        reject(new Error("No file selected."));
      }
    });

    // Append the file input to the body and trigger the click
    document.body.appendChild(fileInput);
    fileInput.click();
    document.body.removeChild(fileInput);
  });
};
