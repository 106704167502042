import {
  ActionFacingProps,
  ActionId,
  Action_PutNewItemIntoCursor,
} from "game-engine/types";

import { ItemConfigId } from "game-files/common/ids";
import { createActionName } from ".";

//
// CREATE BASIC ACTION THAT GENERATES A NEW ITEM AND PUTS IT INTO THE CURSOR
//
type PutNewItemIntoCursorProps = {
  newItemConfigId: ItemConfigId;
  blockGui?: boolean; // putNewItemIntoCursor is only 1 frame in duration, which causes CURSOR FLICKER if it is placed between two pauses -> fix with blockGui
  unskippable?: boolean;
} & ActionFacingProps;

type PutNewItemIntoCursorReturn = () => Action_PutNewItemIntoCursor;

export const putNewItemIntoCursor: (
  props: PutNewItemIntoCursorProps
) => PutNewItemIntoCursorReturn = (props) => () => {
  return {
    ...props,
    _name: createActionName({
      name: `put item into cursor`,
      value: `(${props.newItemConfigId})`,
    }),
    id: ActionId.putNewItemIntoCursor,
    blockGui: props.blockGui,
    unskippable: props.blockGui,
  };
};
