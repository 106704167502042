//
// IMAGE IMPORTS
//
export const getCode_ImageImports = (
  images: { name: string; filename: string }[]
) => {
  let codeLines = [];

  // Use a Map to ensure uniqueness based on both name and filename
  const uniqueImages = images.reduce((map, image) => {
    const key = `${image.name}-${image.filename}`;
    if (!map.has(key)) {
      map.set(key, image);
    }
    return map;
  }, new Map());

  // Sort and generate code lines
  Array.from(uniqueImages.values())
    .sort((a, b) => (a.name < b.name ? -1 : 1))
    .forEach((image) => {
      codeLines.push(`import ${image.name} from './assets/${image.filename}';`);
    });

  return codeLines.join("\n");
};
