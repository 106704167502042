import { Direction, Position, SceneType } from "game-engine/types";
import { EditorType, OnSceneMouseClick } from "../..";
import { useEffect, useState } from "react";

import Box from "game-engine/_dev/basic-components/Box";
import Checkbox from "game-engine/_dev/basic-components/inputs/Checkbox";
import { GameObjective } from "game-files/gameObjectives";
import WalkPathSettings from "./WalkPathSettings";
import styled from "styled-components";
import useGame from "game-engine/hooks/useGame";

const BoxHeader = styled.div`
  flex: 1;
  display: flex;
  align-items: flex-start;
  gap: 10px;
`;

const BoxName = styled.div`
  min-width: 50%;
`;

const SectionSceneTransitions = (props: {
  isOpen?: boolean;
  onOpenChange?: (v) => void;
  editor: EditorType;
  setEditor: (e: EditorType) => void;
  updateScene: (scene: SceneType) => void;
  onSceneMouseClick: OnSceneMouseClick;
  setOnSceneMouseClick: (v: OnSceneMouseClick) => void;
  setForceWalkIn: (v: { fromDirection: Direction }) => void;
  setForceWalkOut: (v: { walkTo: Position; direction: Direction }) => void;
}) => {
  const { engineConfig } = useGame();

  const [isOpen, setIsOpen] = useState(props.isOpen);

  useEffect(() => {
    setIsOpen(props.isOpen);
  }, [props.isOpen]);

  const {
    editor,
    setEditor,
    updateScene,
    onSceneMouseClick,
    setOnSceneMouseClick,
    setForceWalkIn,
    setForceWalkOut,
  } = props;

  //
  // ENGINE CONFIG
  //
  const setRenderWalkPositions = (v: boolean) => {
    engineConfig.setState((prevState) => ({
      ...prevState,
      renderSceneWalkOutPositions: v,
    }));
  };

  const renderBoxName = () => {
    const title = "Walk-Out Areas";

    if (isOpen) {
      return (
        <BoxHeader>
          <BoxName>{title}</BoxName>

          <Checkbox
            label="Render Walk Positions"
            value={engineConfig.state.renderSceneWalkOutPositions}
            onChange={setRenderWalkPositions}
          />
        </BoxHeader>
      );
    }
    return title;
  };

  //
  // RENDER
  //
  return (
    <Box
      label={renderBoxName()}
      level={1}
      childMinWidth="60%"
      gap="10px"
      isOpen={isOpen}
      onOpenChange={(isOpen) => {
        setIsOpen(isOpen);
        if (props.onOpenChange) {
          props.onOpenChange(isOpen);
        }
      }}
    >
      {["left", "right", "up", "down"].map((direction) => (
        <WalkPathSettings
          key={direction}
          editor={editor}
          setEditor={setEditor}
          direction={direction as any}
          walkPaths={editor.scene?.sceneWalkPaths[GameObjective.GAME_START]}
          setWalkPaths={(walkPaths) =>
            updateScene({
              ...editor.scene,
              sceneWalkPaths: { [GameObjective.GAME_START]: walkPaths },
            })
          }
          onSceneMouseClick={onSceneMouseClick}
          setOnSceneMouseClick={setOnSceneMouseClick}
          setForceWalkIn={setForceWalkIn}
          setForceWalkOut={setForceWalkOut}
        />
      ))}
    </Box>
  );
};

export default SectionSceneTransitions;
