import { AnimationColorType, AnimationConfigType } from "game-engine/types";

import AssetBox from "game-engine/_dev/basic-components/Box/AssetBox";
import Box from "game-engine/_dev/basic-components/Box";
import Button from "game-engine/_dev/basic-components/Button";
import Checkbox from "game-engine/_dev/basic-components/inputs/Checkbox";
import Divider from "game-engine/_dev/basic-components/Divider";
import JsonView from "game-engine/_dev/basic-components/JsonView";
import SpriteAnimationPreview from "game-engine/_dev/basic-components/SpriteAnimationPreview";
import styled from "styled-components";
import { useState } from "react";

const Preview = styled.div<{ width: number; height: number }>`
  width: 100%;
  height: 100%;

  background: blue;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const PlayButtons = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  gap: 5px;

  & > * {
    flex: 1;
  }
`;

const AnimationItem = (props: {
  animationConfig: AnimationConfigType;
  thumbnail?: { width?: number; height?: number; imgFillPercentage?: string };
  isOpen?: boolean;
}) => {
  const { thumbnail, animationConfig } = props;

  const [playPreviewAnimation, setPlayPreviewAnimation] = useState<
    "once" | "loop"
  >();

  const [activeColor, setActiveColor] = useState<{
    id: string;
    colorizeBW: AnimationColorType;
  }>();

  const previewWidth = 200;
  const previewHeight = 200;

  const thumbnailFrameIndex = Math.floor(
    animationConfig.spriteConfig.frameCount / 2
  );

  //
  // RENDER
  //
  return (
    <AssetBox
      label={animationConfig.name}
      /* subLabel={item.id} */
      spriteThumbnail={{
        src: animationConfig.sprite.src,
        spriteConfig: animationConfig.spriteConfig,
        frameIndex: thumbnailFrameIndex,
        width: thumbnail?.width,
        height: thumbnail?.height,
      }}
      level={2}
      useFlex
      isOpen={props.isOpen}
    >
      {/* PREVIEW */}
      <Box label="Preview" level={3} isOpen contentCenteredVertical>
        <Preview width={previewWidth} height={previewHeight}>
          <SpriteAnimationPreview
            src={animationConfig.sprite.src}
            spriteConfig={{
              ...animationConfig.spriteConfig,
              playCount: playPreviewAnimation === "once" ? 1 : undefined,
            }}
            playSpritePreview={playPreviewAnimation !== undefined}
            onAnimationEnd={() => setPlayPreviewAnimation(undefined)}
            maxWidth={previewWidth}
            maxHeight={previewHeight}
            frameIndex={thumbnailFrameIndex}
            filters={
              activeColor?.colorizeBW
                ? { colorizeBW: activeColor.colorizeBW }
                : undefined
            }
          />
        </Preview>
      </Box>

      <Box label="Controls" level={3} childMinWidth="90%" isOpen>
        <Divider name="Animation Preview:" marginTop="0px" />

        <Box renderContentOnly childMinWidth="90%" isOpen>
          {playPreviewAnimation ? (
            <Button onClick={() => setPlayPreviewAnimation(undefined)}>
              Stop Animation
            </Button>
          ) : (
            <PlayButtons>
              <Button onClick={() => setPlayPreviewAnimation("loop")}>
                Play In Loop
              </Button>

              <Button onClick={() => setPlayPreviewAnimation("once")}>
                Play Once
              </Button>
            </PlayButtons>
          )}
        </Box>

        <Divider name="Colors:" marginTop="20px" />

        <Box renderContentOnly childMinWidth="90%" isOpen>
          {Object.entries(animationConfig.colors || {})?.map((entry) => {
            const id = entry[0];
            const colorizeBW = entry[1];

            return (
              <Checkbox
                key={id}
                label={`${id}`}
                value={activeColor?.id === id}
                onChange={(v) => {
                  if (v) {
                    setActiveColor({ id, colorizeBW });
                  } else {
                    setActiveColor(undefined);
                  }
                }}
              />
            );
          })}
        </Box>
      </Box>

      {/* CONFIG */}
      <Box label="Config" level={3} isOpen childMinWidth="90%">
        <JsonView json={animationConfig} autoExpandToLevel={1} hideRoot />
      </Box>
    </AssetBox>
  );
};

export default AnimationItem;
