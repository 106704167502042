import {
  ActionAudioTypeEnum,
  ActionId,
  Action_Audio,
  GamePlayOverrideMusicType,
} from "game-engine/types";

import { createActionName } from ".";

//
// CREATE SET BACKGROUND MUSIC ACTION
//
type SetOverrideMusicProps = GamePlayOverrideMusicType & {
  blockGui?: true;
};

type SetOverrideMusicReturn = () => Action_Audio;

export const playMusic: (
  props: SetOverrideMusicProps
) => SetOverrideMusicReturn = (props) => () => {
  return {
    ...props,
    _name: createActionName({
      name: `play music`,
      value: `'${props.musicConfigId}'`,
    }),
    id: ActionId.audio,
    type: ActionAudioTypeEnum.setOverrideMusic,
    overrideMusic: props,
    blockGui: props.blockGui ?? true,
    unskippable: props.blockGui ?? true,
  };
};

//
// CREATE STOP BACKGROUND MUSIC ACTION
//
type StopOverrideMusicProps = {
  blockGui?: true;
};

type StopOverrideMusicReturn = () => Action_Audio;

export const stopCustomMusic: (
  props?: StopOverrideMusicProps
) => StopOverrideMusicReturn = (props) => () => {
  return {
    _name: createActionName({ name: "stop music" }),
    id: ActionId.audio,
    type: ActionAudioTypeEnum.setOverrideMusic,
    overrideMusic: undefined,
    blockGui: props.blockGui ?? true,
    unskippable: props.blockGui ?? true,
  };
};
