import AssetPreview from "../AssetPreview";
import CursorGlyph from "game-engine/components/game-elements/Cursor/cursor-glyph";
import { CursorGlyphType } from "game-engine/types";
import { Rect } from "react-konva";
import styled from "styled-components";

const GlyphPreview = styled.div<{ minWidth?: string }>`
  min-width: ${(props) => props.minWidth};
`;

const AssetPreviewCursor = (props: {
  cursorGlyph: CursorGlyphType;
  width: number;
  height: number;
  scale?: number;
  background?: string;
}) => {
  const { cursorGlyph, width, height, scale = 1, background = "#0000" } = props;

  const originSize = 3;
  const originColor = "#8f85";

  //
  // RENDER
  //
  return (
    <GlyphPreview minWidth={`${width * scale}px`}>
      <AssetPreview
        width={width}
        height={height}
        scale={scale}
        background={background}
      >
        <Rect
          x={Math.round(width / 2 - originSize / 2)}
          y={Math.round(height / 2 - originSize / 2)}
          width={originSize}
          height={originSize}
          fill={originColor}
        />
        <CursorGlyph
          cursorGlyph={cursorGlyph}
          x={Math.round(width / 2)}
          y={Math.round(height / 2)}
        />
      </AssetPreview>
    </GlyphPreview>
  );
};

export default AssetPreviewCursor;
