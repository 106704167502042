import GameViewport, {
  GUIBlockerProps,
} from "game-engine/components/game-viewport/GameViewport";
import {
  RunActionsType,
  StopActionsType,
} from "game-engine/components/game-elements/Scene/logic/useSceneActions";
import { useRef, useState } from "react";

import Cinematic from "game-engine/components/game-elements/Cinematic";
import { CinematicId } from "game-files/common/ids";
import { Position } from "game-engine/types";

const CinematicPreview = (props: { cinematicId: CinematicId }) => {
  const { cinematicId } = props;

  const [guiBlocker, setGuiBlocker] = useState<GUIBlockerProps>(undefined);
  const onGuiBlockerClickRef = useRef<(clickedPosition: Position) => void>();

  const runActionsRef = useRef<RunActionsType>();
  const stopActionsRef = useRef<StopActionsType>();

  //
  // RENDER
  //
  return (
    <GameViewport
      renderGUI={{ onExitGame: undefined, onNewGame: undefined }}
      guiBlocker={guiBlocker}
      guiBlockerForced={false}
      guiBlockerClickRef={onGuiBlockerClickRef}
      runActionsRef={runActionsRef}
      stopActionsRef={stopActionsRef}
      canvasClickSettings={undefined}
    >
      <Cinematic
        key={cinematicId}
        cinematicId={cinematicId}
        stopActionsRef={stopActionsRef}
        isVisible={true}
        setGuiBlocker={setGuiBlocker}
        onGuiBlockerClickRef={onGuiBlockerClickRef}
        setForceGuiBlockerRef={undefined}
      />
    </GameViewport>
  );
};

export default CinematicPreview;
