import {
  GAME_OBJECTIVES_TEST_NAMES,
  GameObjective,
} from "game-files/gameObjectives";
import { useContext, useEffect, useMemo, useState } from "react";

import { ACTION } from "game-engine/utils";
import Box from "../../../../basic-components/Box";
import Checkbox from "game-engine/_dev/basic-components/inputs/Checkbox";
import { DevToolsContext } from "game-engine";
import { getSceneByUniqueId } from "game-files/scenes/SCENE_LAYOUTS";
import useGame from "game-engine/hooks/useGame";

const SectionCurrentObjective = (props: { isOpen?: boolean }) => {
  const { gamePlay, gameFns } = useGame();
  const devTools = useContext(DevToolsContext);

  const runActions = devTools.state.actions.runActionsRef?.current;

  const [isDisabled, setIsDisabled] = useState(!runActions);
  useEffect(() => {
    setIsDisabled(!runActions);
  }, [runActions]);

  //
  // CURRENT SCENE DATA
  //
  const currentScene = useMemo(() => {
    return getSceneByUniqueId(gamePlay?.state?.currentScene?.uniqueSceneId);
  }, [gamePlay?.state?.currentScene?.uniqueSceneId]);

  //
  // ACTIONS - BACKGROUND MUSIC
  //
  const actionSetCurrentObjective = (objective: GameObjective) => {
    runActions([ACTION.setCurrentObjective(objective)]);
  };

  //
  // RENDER
  //
  return (
    <Box
      label="Game Objectives"
      childMinWidth="80%"
      contentPaddingBottom="20px"
      isOpen={props.isOpen}
      level={2}
      gap="0px"
    >
      <Box renderContentOnly childMinWidth="80%" isOpen>
        {Object.values(GameObjective)
          .filter((value) => typeof value === "string")
          .map((objective) => {
            const isTest = currentScene?.isUsingTestObjectives;

            const objectiveName = `${objective}`;

            const objectiveNameTest = GAME_OBJECTIVES_TEST_NAMES[
              GameObjective[objective]
            ]
              ? `TEST: ${GAME_OBJECTIVES_TEST_NAMES[GameObjective[objective]]}`
              : "/";

            const label = isTest ? objectiveNameTest : objectiveName;

            const subLabel = isTest && objectiveName;

            return (
              <Checkbox
                key={objective}
                isDisabled={isDisabled}
                label={label}
                subLabel={subLabel}
                value={gameFns.objectiveIsActive(GameObjective[objective])}
                onChange={(v) => {
                  actionSetCurrentObjective(GameObjective[objective]);
                }}
              />
            );
          })}
      </Box>
    </Box>
  );
};

export default SectionCurrentObjective;
