/* eslint-disable react-hooks/exhaustive-deps */

import {
  getDistance,
  getItemDimensions,
  getItemRender,
} from "game-engine/utils";
import { useEffect, useMemo, useRef, useState } from "react";

import DepthLine from "game-engine/components/basic-elements/DepthLine";
import { Group } from "react-konva";
import ItemImageDefault from "./images/default";
import { ItemType } from "game-engine/types/item";
import Origin from "game-engine/components/basic-elements/Origin";
import { Position } from "game-engine/types";
import { getItemConfigById } from "game-files/items/ITEM_CONFIGS";
import useGame from "game-engine/hooks/useGame";

export type ItemProps = {
  item: ItemType;
  onClick?: (e?: any) => void;
  position?: Position;
  scale?: number;
  dropAnimation?: {
    from: Position;
  };
  renderOrigin?: boolean;
  originColor?: string;
  isHidden?: boolean;
  cursorRender?: {
    decayIndex?: number;
  };
};

const Item = (props: ItemProps) => {
  const {
    item,
    dropAnimation,
    renderOrigin,
    originColor,
    isHidden,
    cursorRender,
  } = props;

  const { gamePlay, engineConfig, logger } = useGame();

  const itemConfig = useMemo(() => {
    const config = getItemConfigById(item.configId);
    if (!config) {
      console.error(`Item config "${item.configId}" not found!`);
    }
    return config;
  }, [props.item.configId]);

  const { render, width, height } = useMemo(() => {
    const decayIndex = cursorRender?.decayIndex ?? item.sceneChangeCounter;

    const render = getItemRender({ itemConfig, decayIndex });
    const { width, height } = getItemDimensions({ itemConfig, decayIndex });

    return { render, width, height };
  }, [itemConfig, item.sceneChangeCounter, gamePlay.state.currentScene]);

  //
  // LOGIC
  //
  const onClick = (e) => {
    logger.info(`item clicked (${props.item.configId})`, props.item);

    if (props.onClick) {
      props.onClick(e);
    }
  };

  const onImageLoaded = () => {
    // TODO - WAIT FOR ITEM IMAGES TO LOAD? (CURRENTLY IGNORED)
  };

  //
  // DROP ANIMATION
  //
  const dropAnimFrameMilliseconds = 8;

  const [animPositionSequence, setAnimPositionSequence] =
    useState<Position[]>();
  const [dropPosition, setDropPosition] = useState<Position>();
  const setDropPositionRef = useRef(setDropPosition);
  const dropPositionIndex = useRef(0);
  const timerRef = useRef<any>();

  useEffect(() => {
    if (!dropPosition) {
      clearInterval(timerRef.current);
    } else {
      putNextDropPosition();
    }
  }, [dropPosition]);

  useEffect(() => {
    if (animPositionSequence) {
      putNextDropPosition();
    }
    return () => {
      clearInterval(timerRef.current);
    };
  }, [animPositionSequence]);

  const putNextDropPosition = () => {
    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      setDropPositionRef.current(
        animPositionSequence[dropPositionIndex.current]
      );
      dropPositionIndex.current = dropPositionIndex.current + 1;
    }, dropAnimFrameMilliseconds);
  };

  //
  // DROP PATH
  //
  useEffect(() => {
    if (dropAnimation) {
      const from = dropAnimation.from;
      const to = item.inScene.position;
      const travelPath = getFromToPath({ from, to });
      const bouncePath = getBouncePath({ to });
      const dropPath = [...travelPath, ...bouncePath];

      setAnimPositionSequence(dropPath);
    }
  }, []);

  const getBouncePath = (options: { to: Position }) => {
    const p = options.to;
    const coeff = 1;
    const path = [
      { x: p.x, y: Math.round(p.y - 16 * coeff) },
      { x: p.x, y: Math.round(p.y - (16 + 8) * coeff) },
      { x: p.x, y: Math.round(p.y - (16 + 8 + 4) * coeff) },
      { x: p.x, y: Math.round(p.y - (16 + 8 + 4 + 2) * coeff) },
      { x: p.x, y: Math.round(p.y - (16 + 8 + 4 + 2 + 1) * coeff) },
      { x: p.x, y: Math.round(p.y - (16 + 8 + 4 + 2) * coeff) },
      { x: p.x, y: Math.round(p.y - (16 + 8 + 4) * coeff) },
      { x: p.x, y: Math.round(p.y - (16 + 8) * coeff) },
      { x: p.x, y: Math.round(p.y - 16 * coeff) },
    ];
    return path;
  };

  const getFromToPath = (options: { from: Position; to: Position }) => {
    const { from, to } = options;

    const distance = getDistance(from, to);
    const vector = { x: from.x - to.x, y: from.y - to.y };
    const vNorm = Math.max(Math.abs(vector.x), Math.abs(vector.y));
    const normalizedVector = { x: vector.x / vNorm, y: vector.y / vNorm };
    const travelPath = [];
    let lastPathPoint;
    let i = 0;

    while (i < 1000) {
      if (lastPathPoint) {
        travelPath.push(lastPathPoint);
      }
      const coeff = i * i * 2;
      const pathPoint = {
        x: Math.round(from.x - normalizedVector.x * coeff),
        y: Math.round(from.y - normalizedVector.y * coeff),
      };
      if (getDistance(pathPoint, from) > distance) {
        break;
      }
      lastPathPoint = pathPoint;
      i++;
    }
    travelPath.push(to);
    return travelPath;
  };

  //
  // RENDER
  //
  return itemConfig ? (
    <>
      {/* RENDER IMAGES */}
      {/* conditional image rendering should be done via opacity
       *   -> otherwise the rendered image will (re)load on image component mount, causing flickering
       */}
      <Group
        position={dropPosition || props.position || { x: 0, y: 0 }}
        onClick={onClick}
        onTap={onClick}
        scaleY={props.scale || 1}
        scaleX={props.scale || 1}
        listening={!engineConfig.state.hideSceneItems}
        opacity={isHidden || engineConfig.state.hideSceneItems ? 0 : 1}
      >
        <ItemImageDefault
          item={item}
          itemConfig={itemConfig}
          render={render}
          width={width}
          height={height}
          onImageLoaded={onImageLoaded}
          isHidden={isHidden}
        />

        {(renderOrigin || engineConfig.state.renderItemOrigin) && (
          <Origin color={originColor || engineConfig.state.itemOriginColor} />
        )}
        {engineConfig.state.renderItemDepthLine && (
          <DepthLine color={engineConfig.state.itemDepthLineColor} />
        )}
      </Group>
    </>
  ) : null;
};

export default Item;
