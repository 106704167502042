import ItemBox from "game-engine/_dev/basic-components/ItemList/ItemBox";
import { ItemConfigId } from "game-files/common/ids";
import { getItemConfigById } from "game-files/items/ITEM_CONFIGS";

const AssetsItemPage = (props: { itemConfigId: ItemConfigId }) => {
  const { itemConfigId } = props;

  const thumbnailSize = 46;
  const itemConfig = getItemConfigById(itemConfigId);

  //
  // RENDER
  //
  return (
    <ItemBox
      item={{ id: undefined, configId: itemConfig.configId }}
      thumbnail={{ width: thumbnailSize, height: thumbnailSize }}
      preview={{ width: 100, height: 100, scale: 3 }}
      infoInScene={undefined}
      isAssetPreview
      isOpen
      isDetailPage
    />
  );
};

export default AssetsItemPage;
