import { ACTION, createActionName } from ".";
import {
  ActionFacingProps,
  ActionId,
  Action_CharacterAnimation,
  Action_Pause,
} from "game-engine/types";

import { CharacterId } from "game-files/common/ids";

//
// CREATE BASIC CHARACTER ANIMATION ACTION
//
type CharacterAnimationProps = {
  characterId?: CharacterId;
  animationId: string;
  keepLastFrame?: boolean;
} & ActionFacingProps;

type CharacterAnimationReturn = () => [Action_CharacterAnimation, Action_Pause];

export const characterAnimation: (
  props: CharacterAnimationProps
) => CharacterAnimationReturn = (props) => () => {
  return [
    {
      ...props,
      _name: createActionName({
        name: `animation`,
        value: `'${props.animationId}'`,
        prefix: props.characterId || CharacterId.MainCharacter,
      }),
      id: ActionId.characterAnimation,
      blockGui: true,
      unskippable: true,
      // animations cannot be skipped, animation interruption with correct state reset
      //      currently doesn't work (once stopped, animation won't repeat ever again)
    },

    // fixes screwing up next action's timing + feels more natural when there's a little pause
    ACTION.pause({ durationSec: 0.1 })(),
  ];
};
