import { ACTION } from "game-engine/utils";
import { GameObjective } from "game-files/gameObjectives";
import ImagePreview from "./assets/preview_00.png";
import Layer_01_Background from "./assets/layer - 01 - background_00.png";
import Layer_02_Background_Rocks from "./assets/layer - 02 - background - rocks_00.png";
import Layer_03_Background_Trees from "./assets/layer - 03 - background - trees_00.png";
import Layer_04_Background_Rocks from "./assets/layer - 04 - background - rocks_00.png";
import Layer_05_Tree_Middle from "./assets/layer - 05 - tree middle_00.png";
import Layer_05_Tree_Right_Young from "./assets/layer - 05 - tree right young_00.png";
import Layer_06_Tree_Right from "./assets/layer - 06 - tree right_00.png";
import Layer_07_Rock_Middle from "./assets/layer - 07 - rock middle_00.png";
import Layer_08_Trees_Left from "./assets/layer - 08 - trees left_00.png";
import Layer_09_Front_Bushes_And_Rocks from "./assets/layer - 09 - front - bushes and rocks_00.png";
import Layer_10_Front_Trees from "./assets/layer - 10 - front - trees_00.png";
import Layer_Map_Depth from "./assets/layer - 00 - DEPTH MAP_00.png";
import Layer_Map_Drop from "./assets/layer - 00 - DROP MAP_00.png";
import Layer_Map_Walk from "./assets/layer - 00 - WALK MAP_00.png";
import MUSIC_CONFIGS from "game-files/audio/MUSIC_CONFIGS";
import SHARED_DATA from "../../SHARED_DATA";
import { SceneType } from "game-engine/types";

const Test_Forest_L_D: SceneType = {
  configId: "Test_Forest_L_D",
  name: {
    [GameObjective.GAME_START]: {
      en: "Ancient Forest 01",
      cz: "Prastarý les 01",
    },
  },

  preview: { src: ImagePreview },
  uniqueId: undefined, // IS GENERATED FROM SCENES LAYOUT
  sceneNeighbors: undefined, // IS GENERATED FROM SCENES LAYOUT

  sceneWalkPaths: {
    [GameObjective.GAME_START]: {
      // NOT ALL WALK-PATHS ARE NECESSARILY USED
      // -> ACCESSIBLE WALK-PATHS ARE SPECIFIED IN SCENES LAYOUT MATRIX BY HAVING A NEIGHBOR
      left: {
        edgeWalkY: 110,
        walkTo: { x: 38, y: 117 },
      },
      down: {
        edgeWalkX: 89,
        walkTo: { x: 89, y: 138 },
      },
    },
  },

  audio: {
    [GameObjective.GAME_START]: {
      music: {
        musicConfigId: MUSIC_CONFIGS.Test_02.id,
      },
    },
  },

  images: {
    background: {
      dataByCurrentObjective: {
        [GameObjective.GAME_START]: {
          image: { src: Layer_01_Background },
          dropMap: { src: Layer_Map_Drop },
          walkMap: { src: Layer_Map_Walk },
          depthMap: { src: Layer_Map_Depth },
          depthSettings: {
            //scaleMin: 0.5,
            //scaleMax: 1,
          },
        },
      },
      actionsByCurrentObjective: undefined,
    },

    layers: [
      {
        id: "Layer_02_Background_Rocks",
        dataByCurrentObjective: {
          [GameObjective.GAME_START]: {
            image: { src: Layer_02_Background_Rocks },
          },
        },
        actionsByCurrentObjective: {
          [GameObjective.GAME_START]: {
            actions: [
              ACTION.talkRandom({
                dialogOptions: [
                  ...SHARED_DATA.dialogOptions.rockLarge,
                  ...SHARED_DATA.dialogOptions.obstacleNoSceneWalkOut,
                ],
              }),
            ],
          },
        },
      },
      {
        id: "Layer_03_Background_Trees",
        dataByCurrentObjective: {
          [GameObjective.GAME_START]: {
            image: { src: Layer_03_Background_Trees },
          },
        },
        actionsByCurrentObjective: {
          [GameObjective.GAME_START]: {
            actions: [
              ACTION.talkRandom({
                dialogOptions: [
                  ...SHARED_DATA.dialogOptions.tree,
                  ...SHARED_DATA.dialogOptions.forest,
                ],
              }),
            ],
          },
        },
      },
      {
        id: "Layer_04_Background_Rocks",
        dataByCurrentObjective: {
          [GameObjective.GAME_START]: {
            image: { src: Layer_04_Background_Rocks },
          },
        },
        actionsByCurrentObjective: {
          [GameObjective.GAME_START]: {
            actions: [
              ACTION.talkRandom({
                dialogOptions: [...SHARED_DATA.dialogOptions.rockLarge],
              }),
            ],
          },
        },
      },

      {
        id: "Layer_05_Tree_Middle",
        depthY: 55,
        dataByCurrentObjective: {
          [GameObjective.GAME_START]: {
            image: { src: Layer_05_Tree_Middle },
          },
        },
        actionsByCurrentObjective: {
          [GameObjective.GAME_START]: {
            actions: [
              ACTION.talkRandom({
                dialogOptions: [
                  ...SHARED_DATA.dialogOptions.tree,
                  ...SHARED_DATA.dialogOptions.treeOld,
                ],
              }),
            ],
          },
        },
      },

      {
        id: "Layer_05_Tree_Right_Young",
        depthY: 75,
        dataByCurrentObjective: {
          [GameObjective.GAME_START]: {
            image: { src: Layer_05_Tree_Right_Young },
          },
        },
        actionsByCurrentObjective: {
          [GameObjective.GAME_START]: {
            actions: [
              ACTION.talkRandom({
                dialogOptions: [...SHARED_DATA.dialogOptions.treeYoung],
              }),
            ],
          },
        },
      },

      {
        id: "Layer_06_Tree_Right",
        depthY: 95,
        dataByCurrentObjective: {
          [GameObjective.GAME_START]: {
            image: { src: Layer_06_Tree_Right },
          },
        },
        actionsByCurrentObjective: {
          [GameObjective.GAME_START]: {
            actions: [
              ACTION.talkRandom({
                dialogOptions: [
                  ...SHARED_DATA.dialogOptions.tree,
                  ...SHARED_DATA.dialogOptions.treeOld,
                ],
              }),
            ],
          },
        },
      },

      {
        id: "Layer_07_Rock_Middle",
        depthY: 75,
        dataByCurrentObjective: {
          [GameObjective.GAME_START]: {
            image: { src: Layer_07_Rock_Middle },
          },
        },
        actionsByCurrentObjective: {
          [GameObjective.GAME_START]: {
            actions: [
              ACTION.talkRandom({
                dialogOptions: [
                  ...SHARED_DATA.dialogOptions.rockSmall,
                  ...SHARED_DATA.dialogOptions.rockLarge,
                ],
              }),
            ],
          },
        },
      },

      {
        id: "Layer_08_Trees_Left",
        depthY: 100,
        dataByCurrentObjective: {
          [GameObjective.GAME_START]: {
            image: { src: Layer_08_Trees_Left },
          },
        },
        actionsByCurrentObjective: {
          [GameObjective.GAME_START]: {
            actions: [
              ACTION.talkRandom({
                dialogOptions: [
                  ...SHARED_DATA.dialogOptions.tree,
                  ...SHARED_DATA.dialogOptions.forest,
                ],
              }),
            ],
          },
        },
      },

      {
        id: "Layer_09_Front_Bushes_And_Rocks",
        depthY: 10000,
        dataByCurrentObjective: {
          [GameObjective.GAME_START]: {
            image: { src: Layer_09_Front_Bushes_And_Rocks },
          },
        },
        actionsByCurrentObjective: {
          [GameObjective.GAME_START]: {
            actions: [
              ACTION.talkRandom({
                dialogOptions: [...SHARED_DATA.dialogOptions.rocksAndBushes],
              }),
            ],
          },
        },
      },

      {
        id: "Layer_10_Front_Trees",
        depthY: 10000,
        dataByCurrentObjective: {
          [GameObjective.GAME_START]: {
            image: { src: Layer_10_Front_Trees },
          },
        },
        actionsByCurrentObjective: {
          [GameObjective.GAME_START]: {
            actions: [
              ACTION.talkRandom({
                dialogOptions: [
                  ...SHARED_DATA.dialogOptions.tree,
                  ...SHARED_DATA.dialogOptions.forest,
                ],
              }),
            ],
          },
        },
      },
    ],
  },
};

export default Test_Forest_L_D;
