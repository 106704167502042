import { CharacterDialogType } from "game-engine/types";
import DIALOG_OPTIONS from "game-files/common/dialogs";

const SHARED_DATA: {
  dialogOptions: {
    tree: CharacterDialogType[];
    treeOld: CharacterDialogType[];
    treeYoung: CharacterDialogType[];
    forest: CharacterDialogType[];
    forestBackground: CharacterDialogType[];
    rockSmall: CharacterDialogType[];
    rockLarge: CharacterDialogType[];
    rocksAndBushes: CharacterDialogType[];
    bush: CharacterDialogType[];
    notGoingToWalk: CharacterDialogType[];
    obstacleNoSceneWalkOut: CharacterDialogType[];
  };
} = {
  dialogOptions: { ...DIALOG_OPTIONS.sceneLayers },
};

export default SHARED_DATA;
