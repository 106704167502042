import {
  BlendingMode,
  CharacterDialogPosition,
  FrameSequenceValue,
  GameConfigType,
  SpriteDirection,
  TextAlign,
} from "game-engine/types";

import COLORS from "./common/colors";

const canvasX = 340;
const canvasY = 250;

const GAME_CONFIG: GameConfigType = {
  name: { en: "Story of Valentiris", cz: "Příběh o Valentiris" },

  saveFilename: "valentiris-saved-games",
  saveVersion: "1.0",

  render: {
    blur: 0,
  },

  window: {
    canvasDimensions: {
      // canvas (pixel art) dimensions will be scaled to display dimensions
      x: canvasX,
      y: canvasY,
    },
    displayDimensions: {
      x: canvasX * 3,
      y: canvasY * 3, // may not work as expected due to keeping canvas aspect-ratio
    },
  },

  scene: {
    initFadeInDurationSec: 0.5,

    dimensions: {
      x: 320,
      y: 160,
      offsetY: 10, // 'x' is centered, offsetY is for positioning in GUI
    },

    defaultDepthSettings: {
      scaleMin: 0.5,
      scaleMax: 1,
    },

    walkOutAreas: {
      widthInsideGame: 8,
      widthOutsideGame: 20,
    },

    animations: {
      frameDuration: 100, // milliseconds
    },

    idle: {
      idleAfterMinSec: 10,
      idleAfterMaxSec: 30,
    },

    dynamicLighting: {
      vignette: {
        opacity: 0.8,
        size: 0.5,
        color: COLORS.black,
        blendingMode: BlendingMode.Multiply,
      },

      filters: {
        light: {
          brightness: 1,
          contrast: 1,
          saturation: 0.5,
        },
        shadowLayers: {
          brightness: 0.92,
          contrast: 0.92,
          saturation: 0.5,
        },
        shadowForeground: {
          brightness: 0.7,
          contrast: 0.7,
          saturation: 0.5,
        },
      },
    },
  },

  actions: {
    // seconds until (e.g) 'item dropped' labels are reset
    labelOverrideTimeoutSec: 2,

    walkSpeed: {
      // milliseconds per step animation frame
      slow: 60,
      fast: 30,
    },

    talkSpeed: {
      millisecondsBase: 500,
      // milliseconds per character in text added to millisecondsBase
      millisecondsPerCharInText: 70,
      // speed coefficients
      slow: 2,
      medium: 1,
      fast: 0.5,
    },

    death: {
      byPoison: {
        timeBeforeDeathSec: 10,
        checkEverySec: 2,
      },
      byDarkness: {
        warningCount: 1,
      },
    },
  },

  sprites: {
    defaultSpriteConfig: {
      direction: SpriteDirection.Horizontal,
      frameWidth: 100,
      frameHeight: 100,
      frameCount: 1,
      frameDurationMilliseconds: 100,
      frameSequence: [FrameSequenceValue.original],
      originOffset: { x: 0, y: 0 },
      clipOffset: { x: 0, y: 0 },
      playCount: -1, // = infinite loop
    },
  },

  character: {
    dialogConfig: {
      aboveOffsetMax: 70, // measured from origin
      aboveOffsetMin: 50,
      offsetBelowMax: -5,
      paddingX: 5,
      paddingY: 1,
      verticalPosition: CharacterDialogPosition.auto,
      align: TextAlign.center,
      maxWidth: 160,
      color: COLORS.white,
      outlined: true,
    },
    animations: {
      talk: {
        frameDuration: 140, // milliseconds
      },
      idle: {
        idleAfterMinSec: 10, // seconds, min random wait until idle (for randomizer)
        idleAfterMaxSec: 40, // seconds, max random wait until idle (for randomizer)
      },
    },
  },

  skills: {
    resetAfter: {
      sceneChangeCount: 5, // reset after 5 scene changes
      durationSec: 60, // reset after 1 minute
    },
  },

  gui: {
    fadeDurationSec: 0.5,

    label: {
      origin: { x: 14, y: 176 },
    },

    inventory: {
      origin: { x: 100, y: 199 },
      rows: 2,
      cols: 6,
      slotWidth: 18,
      slotHeight: 20,
      gapX: 3,
      gapY: 3,
      fillColor: COLORS.black,
    },

    skillSlots: {
      origin: { x: 260, y: 200 },
      slotWidth: 18,
      slotHeight: 40,
      gap: 4,
    },
  },

  menu: {
    colorMain: COLORS.blue,
    colorHighlight: COLORS.blueLight,
    colorShadow: COLORS.blueShadow,
    colorText: COLORS.white,
    colorTextHighlight: COLORS.cyan,
    colorTextWarning: COLORS.orange,
    colorTextError: COLORS.red,
    textOpacityDefault: 0.8,
    textOpacityActive: 1,
  },

  audio: {
    globalVolume: 0.5,
    // sub-volumes:
    backgroundMusicVolume: 1,
    backgroundSoundVolume: 1,
    itemSoundVolume: 0.6,
  },

  cinematics: {
    textSpeed: {
      speed: 1.5,
      millisecondsBase: 500,
      millisecondsPerCharInText: 70, // milliseconds per character in text added to millisecondsBase
    },
    textPauseBefore: {
      durationSec: 0.5,
    },
    textPauseAfter: {
      durationSec: 0.5,
    },
    textPauseBetweenItems: {
      durationSec: 0.2,
    },

    fadeFillColor: COLORS.black,

    fadeTextDurationSec: 0.5,
    fadeSceneDurationSec: 0.5,
    fadeViewportDurationSec: 1,
  },
};

export default GAME_CONFIG;
