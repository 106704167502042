import styled, { css } from "styled-components";

import { GameDisplayModeEnum } from "game-engine/types";
import TabAssets from "./TabAssets";
import TabCinematicEditor from "./TabCinematicEditor";
import TabDeveloper from "./TabDeveloper";
import TabPlayer from "./TabPlayer";
import TabSceneEditor from "./TabSceneEditor";
import TabScenesLayoutEditor from "./TabScenesLayoutEditor";
import TabSpriteEditor from "./TabSpriteEditor";
import { capitalizeFirstLetter } from "game-engine/utils";
import devToolsConfig from "game-engine/configs/devtools-config";
import { useEffect } from "react";
import useGame from "game-engine/hooks/useGame";

const Wrapper = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  width: 100%;
  min-height: 100vh;
  max-height: 100vh;
`;

const TabSelection = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 10px;
  padding: 2px 10px;

  background: ${devToolsConfig.background};
  border-bottom: 1px solid ${devToolsConfig.borderColor};
`;

const Tab = styled.a<{ isActive: boolean }>`
  cursor: pointer;
  padding: 2px 10px;

  font-size: 12px;
  font-weight: 600;
  text-decoration: none;
  color: #fff8;

  ${(props) =>
    props.isActive &&
    css`
      background: #fffa;
      color: #000;
    `}
`;

export enum TabType {
  "player" = "player",
  "developer" = "developer",
  "scenesLayoutEditor" = "scenesLayoutEditor",
  "sceneEditor" = "sceneEditor",
  "cinematicEditor" = "cinematicEditor",
  "spriteEditor" = "spriteEditor",
  "assets" = "assets",
}

const Tabs = (props?: { activeTab?: string }) => {
  const { gamePlay } = useGame();

  const activeTab = props?.activeTab;

  const isFullscreen =
    gamePlay.state.settings.displayMode === GameDisplayModeEnum.fullscreen;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [isFullscreen]);

  const renderTab = () => {
    if (!activeTab) {
      return <TabPlayer />;
    }
    switch (activeTab) {
      case TabType.player:
        return <TabPlayer />;

      case TabType.developer:
        return <TabDeveloper />;

      case TabType.scenesLayoutEditor:
        return <TabScenesLayoutEditor />;

      case TabType.sceneEditor:
        return <TabSceneEditor />;

      case TabType.cinematicEditor:
        return <TabCinematicEditor />;

      case TabType.spriteEditor:
        return <TabSpriteEditor />;

      case TabType.assets:
        return <TabAssets />;

      default:
        return <div>Something went seriously wrong dude...</div>;
    }
  };

  //
  // RENDER
  //
  return (
    <Wrapper>
      {activeTab && (
        <TabSelection>
          {Object.values(TabType).map((tab) => (
            <Tab key={tab} isActive={activeTab === tab} href={`?tab=${tab}`}>
              {capitalizeFirstLetter(tab.replace(/([a-z])([A-Z])/g, "$1 $2"))}
            </Tab>
          ))}
        </TabSelection>
      )}

      {renderTab()}
    </Wrapper>
  );
};

export default Tabs;
