import Box from "game-engine/_dev/basic-components/Box";
import ITEM_CONFIGS from "game-files/items/ITEM_CONFIGS";
import ItemList from "game-engine/_dev/basic-components/ItemList";

const AssetsItems = (props: { isOpen?: boolean }) => {
  //
  // RENDER
  //
  return (
    <Box
      label="Items"
      useFlex
      gap="10px"
      childMinWidth="60%"
      isOpen={props.isOpen}
      level={1}
    >
      <ItemList
        items={Object.values(ITEM_CONFIGS).map((itemConfig) => {
          return { id: itemConfig.configId, configId: itemConfig.configId };
        })}
        preview={{ width: 90, height: 90, scale: 4 }}
        isAssetPreview
      />
    </Box>
  );
};

export default AssetsItems;
