import styled, { css } from "styled-components";

import AnimateHeight from "react-animate-height";
import Button from "../../Button";
import InputLabel from "../_InputLabel";
import RadioList from "../RadioList";
import { useState } from "react";

const Wrapper = styled.div`
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
`;

const DropWrapper = styled.div`
  min-width: 100%;
  display: flex;
  flex-direction: column;

  color: #fffa;
  font-size: 14px;
`;

const ValueWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Value = styled.div`
  font-size: 13px;
`;

const Prefix = styled.div<{ width: string }>`
  padding-right: 5px;
  color: #fff4;
  width: ${(props) => props.width};
  font-size: 13px;
`;

const Suffix = styled.div`
  padding-left: 5px;
  color: #fff4;
`;

const Caret = styled.div<{ isOpen?: boolean }>`
  width: 20px;
  height: 20px;

  transition: 0.2s ease all;

  ${(props) =>
    props.isOpen &&
    css`
      transform: rotate(90deg);
    `}

  & > div {
    color: #fff4;
    font-size: 10px;
    margin-top: 5px;
    margin-left: 5px;
  }
`;

const Dropdown = (props: {
  prefix?: string;
  prefixWidth?: string;
  suffix?: string;
  showCaret?: boolean;
  label?: string;
  labelWhenEmpty?: string;
  subLabel?: string;
  selectedId: string;
  items: { id: string; data: any; label: any; isDisabled?: boolean }[];
  isActive?: boolean;
  onChange: (id: string, data?: any) => void;
  style?: any;
  keepOpen?: boolean;
  hideButton?: boolean;
  isDisabled?: boolean;
  backgroundColor?: string;
  padding?: string;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  //
  // RENDER
  //
  return (
    <Wrapper style={props.style}>
      {props.label && <InputLabel label={props.label} />}

      {!props.hideButton && (
        <Button
          subLabel={props.subLabel}
          subLabelIndent={undefined}
          isActive={props.isActive}
          onClick={() => setIsOpen(!isOpen)}
          alignLeft
          noBorder
          style={{
            minWidth: "100%",
            ...(props.padding ? { padding: props.padding } : {}),
            ...(props.isDisabled ? { cursor: "default" } : {}),
          }}
          backgroundColor={props.backgroundColor}
        >
          <ValueWrapper>
            {props.prefix && (
              <Prefix width={props.prefixWidth}>{`${props.prefix}`}</Prefix>
            )}

            <Value>
              {" "}
              {props.items.find((item) => item.id === props.selectedId)
                ?.label ||
                props.labelWhenEmpty ||
                "/"}
            </Value>

            {props.suffix && <Suffix>{`${props.suffix}`}</Suffix>}

            {!props.isDisabled && props.showCaret && (
              <Caret isOpen={isOpen}>
                <div>{`▼`}</div>
              </Caret>
            )}
          </ValueWrapper>
        </Button>
      )}

      {!props.isDisabled && (
        <AnimateHeight
          duration={200}
          height={isOpen || props.keepOpen ? "auto" : 0}
        >
          <DropWrapper>
            <RadioList
              selectedId={props.selectedId}
              items={props.items}
              onChange={(id, data) => {
                props.onChange(id, data);
                setIsOpen(false);
              }}
            />
          </DropWrapper>
        </AnimateHeight>
      )}
    </Wrapper>
  );
};

export default Dropdown;
