export type GameEffectShakeViewport = {
  isActive: boolean;
  durationSec: number;
  shakeOffset?: number;
  shakeSpeed?: number;
};

export enum GameEffectFadeEnum {
  "fadeIn" = "fadeIn",
  "fadeOut" = "fadeOut",
}

export type GameEffectFadeViewport = {
  type: GameEffectFadeEnum;
  durationSec: number;
};

export type GameEffectFadeScene = {
  type: GameEffectFadeEnum;
  durationSec: number;
};

export type GameEffectsType = {
  viewportShake: GameEffectShakeViewport;
  fadeViewport: GameEffectFadeViewport;
  fadeScene: GameEffectFadeScene;
};
