import {
  EditorActiveSceneItem,
  EditorType,
  OnSceneClickMode,
  OnSceneClickType,
  SCENES_LAYOUT_DEFAULT,
} from '../..'

import Box from 'game-engine/_dev/basic-components/Box'
import Button from 'game-engine/_dev/basic-components/Button'
import Checkbox from 'game-engine/_dev/basic-components/inputs/Checkbox'
import Divider from 'game-engine/_dev/basic-components/Divider'
import NumberInput from 'game-engine/_dev/basic-components/inputs/NumberInput'
import { ScenesLayoutGridHoverMode } from 'game-engine/_dev/dev-components/ScenesLayoutGrid'
import { ScenesLayoutType } from 'game-engine/types'
import Sidebar from 'game-engine/_dev/basic-components/Sidebar'
import { cleanScenesLayout } from 'game-engine/utils'

//
// COMPONENT
//
const SidebarLeft = (props: {
  editor: EditorType
  setEditor: (e: EditorType) => void
  updateScenesLayout: (options: {
    updatedScenesLayout: ScenesLayoutType
    activeSceneItem?: EditorActiveSceneItem
    resetOnSceneClick?: boolean
  }) => void
  getActiveScenesLayout: () => ScenesLayoutType
}) => {
  const { editor, setEditor, updateScenesLayout, getActiveScenesLayout } = props

  const hoverColorWarning = '#f004'
  const hoverColorHighlight = undefined //'#fff5'

  const updateOnSceneClick = (onSceneClick: OnSceneClickType) =>
    setEditor({
      ...editor,
      onSceneClick,
    })

  const setDefaultOnSceneClick = () => {
    updateOnSceneClick({
      mode: OnSceneClickMode.select,
      hoverMode: ScenesLayoutGridHoverMode.item,
      hoverColor: undefined,
    })
  }

  //
  // RENDER
  //
  return (
    <Sidebar name="Viewport" position="left" width="min(100%, 230px)" isOpen>
      <Box label="Display Settings" level={2} isOpen contentPaddingTop="10px">
        <NumberInput
          label="Scene Width"
          min={0}
          value={editor.sceneItemWidth}
          onChange={(v) =>
            setEditor({
              ...editor,
              sceneItemWidth: v,
            })
          }
          rangeController={{ min: 100, max: 500, step: 20 }}
        />
      </Box>

      {editor.activeSceneLayoutId &&
      editor.activeSceneLayoutId !== SCENES_LAYOUT_DEFAULT ? (
        <>
          <Box label="On Scene Click" level={2} isOpen childMinWidth="60%">
            <Checkbox
              label="Select"
              value={editor.onSceneClick?.mode === OnSceneClickMode.select}
              onChange={setDefaultOnSceneClick}
            />

            <Checkbox
              label="Unassign Scene"
              value={editor.onSceneClick?.mode === OnSceneClickMode.reset}
              onChange={(v) =>
                editor.onSceneClick?.mode === OnSceneClickMode.reset
                  ? setDefaultOnSceneClick()
                  : updateOnSceneClick({
                      mode: OnSceneClickMode.reset,
                      hoverMode: ScenesLayoutGridHoverMode.item,
                      hoverColor: hoverColorWarning,
                    })
              }
            />

            <Divider name="Row" />
            <Checkbox
              label="Row - Delete"
              value={editor.onSceneClick?.mode === OnSceneClickMode.rowDelete}
              onChange={(v) =>
                editor.onSceneClick?.mode === OnSceneClickMode.rowDelete
                  ? setDefaultOnSceneClick()
                  : updateOnSceneClick({
                      mode: OnSceneClickMode.rowDelete,
                      hoverMode: ScenesLayoutGridHoverMode.row,
                      hoverColor: hoverColorWarning,
                    })
              }
            />
            <Checkbox
              label="Row - Insert Above"
              value={
                editor.onSceneClick?.mode === OnSceneClickMode.rowInsertAbove
              }
              onChange={(v) =>
                editor.onSceneClick?.mode === OnSceneClickMode.rowInsertAbove
                  ? setDefaultOnSceneClick()
                  : updateOnSceneClick({
                      mode: OnSceneClickMode.rowInsertAbove,
                      hoverMode: ScenesLayoutGridHoverMode.row,
                      hoverColor: hoverColorHighlight,
                    })
              }
            />
            <Checkbox
              label="Row - Insert Below"
              value={
                editor.onSceneClick?.mode === OnSceneClickMode.rowInsertBelow
              }
              onChange={(v) =>
                editor.onSceneClick?.mode === OnSceneClickMode.rowInsertBelow
                  ? setDefaultOnSceneClick()
                  : updateOnSceneClick({
                      mode: OnSceneClickMode.rowInsertBelow,
                      hoverMode: ScenesLayoutGridHoverMode.row,
                      hoverColor: hoverColorHighlight,
                    })
              }
            />

            <Divider name="Column" />
            <Checkbox
              label="Column - Delete"
              value={
                editor.onSceneClick?.mode === OnSceneClickMode.columnDelete
              }
              onChange={(v) =>
                editor.onSceneClick?.mode === OnSceneClickMode.columnDelete
                  ? setDefaultOnSceneClick()
                  : updateOnSceneClick({
                      mode: OnSceneClickMode.columnDelete,
                      hoverMode: ScenesLayoutGridHoverMode.column,
                      hoverColor: hoverColorWarning,
                    })
              }
            />
            <Checkbox
              label="Column - Insert Left"
              value={
                editor.onSceneClick?.mode === OnSceneClickMode.columnInsertLeft
              }
              onChange={(v) =>
                editor.onSceneClick?.mode === OnSceneClickMode.columnInsertLeft
                  ? setDefaultOnSceneClick()
                  : updateOnSceneClick({
                      mode: OnSceneClickMode.columnInsertLeft,
                      hoverMode: ScenesLayoutGridHoverMode.column,
                      hoverColor: hoverColorHighlight,
                    })
              }
            />
            <Checkbox
              label="Column - Insert Right"
              value={
                editor.onSceneClick?.mode === OnSceneClickMode.columnInsertRight
              }
              onChange={(v) =>
                editor.onSceneClick?.mode === OnSceneClickMode.columnInsertRight
                  ? setDefaultOnSceneClick()
                  : updateOnSceneClick({
                      mode: OnSceneClickMode.columnInsertRight,
                      hoverMode: ScenesLayoutGridHoverMode.column,
                      hoverColor: hoverColorHighlight,
                    })
              }
            />
          </Box>

          <Box label="Functions" level={2} isOpen childMinWidth="60%">
            <Button
              onClick={() =>
                updateScenesLayout({
                  updatedScenesLayout: cleanScenesLayout(
                    getActiveScenesLayout()
                  ),
                  activeSceneItem: undefined,
                  resetOnSceneClick: true,
                })
              }
            >
              {'Delete Empty Rows and Columns'}
            </Button>
          </Box>
        </>
      ) : (
        <></>
      )}
    </Sidebar>
  )
}

export default SidebarLeft
