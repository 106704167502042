import { Direction, SceneType, ScenesLayoutLinkType } from "game-engine/types";
import {
  findLinkBetweenSceneIds,
  getAllWalkableSceneWalkPaths,
} from "game-engine/utils";
import styled, { css } from "styled-components";

import { EditorType } from "../..";
import { SelectedArea } from ".";
import { useMemo } from "react";

const NeighborHighlight = styled.div<{
  direction: Direction;
  isSceneLink: boolean;
  isHighlighted: boolean;
  isInvalidSceneLink: boolean;
  connectionLineLength: number;
  onClick: any;
}>`
  position: absolute;
  width: 100%;
  height: 100%;

  ${(props) =>
    props.onClick &&
    css`
      cursor: pointer;
      pointer-events: auto;
    `}

  transition: all 0.1s ease;
  background: ${(props) =>
    props.isInvalidSceneLink
      ? props.isHighlighted
        ? "#ff2f2f"
        : "#ff5858"
      : props.isHighlighted
      ? "#6bff6b"
      : props.isSceneLink
      ? "#888"
      : `#fff1`};

  &:hover {
    background: ${(props) =>
      props.isInvalidSceneLink
        ? "#ff6363"
        : props.isHighlighted
        ? "#a7ffa7"
        : props.isSceneLink
        ? "#BBB"
        : `#fff4`};
  }

  --size: ${(props) => props.connectionLineLength / 2}px;

  ${(props) =>
    props.direction === Direction.up
      ? css`
          max-height: var(--size);
          transform: translateY(${-props.connectionLineLength / 2}px);
          top: -4px;
        `
      : props.direction === Direction.down
      ? css`
          max-height: var(--size);
          transform: translateY(${props.connectionLineLength / 2}px);
          bottom: -4px;
        `
      : props.direction === Direction.left
      ? css`
          max-width: var(--size);
          transform: translateX(${-props.connectionLineLength / 2}px);
          left: -4px;
        `
      : css`
          max-width: var(--size);
          transform: translateX(${props.connectionLineLength / 2}px);
          right: -4px;
        `}
`;

//
// COMPONENT
//
const NeighborArea = (props: {
  editor: EditorType;
  setEditor: (e: EditorType) => void;
  scene?: SceneType;
  direction: Direction;
  selectedAreas?: SelectedArea[];
  setSelectedAreas?: (a: SelectedArea[]) => void;
  connectionLineLength: number;
  sceneLinks: ScenesLayoutLinkType[];
  onSceneLinkClick?: (options: { neighborId: string }) => void;
}) => {
  const {
    editor,
    setEditor,
    scene,
    direction,
    selectedAreas,
    setSelectedAreas,
    sceneLinks,
    onSceneLinkClick,
  } = props;

  const neighborId =
    scene?.sceneNeighbors && scene.sceneNeighbors[`sceneId_${direction}`];

  const sceneWalkPaths = useMemo(() => {
    return getAllWalkableSceneWalkPaths({
      dataByCurrentObjective: scene?.sceneWalkPaths,
    });
  }, [scene]);

  if (!scene) {
    return null;
  }

  const isSelected =
    selectedAreas && selectedAreas[0]
      ? selectedAreas[0].sceneId === scene.uniqueId &&
        selectedAreas[0].direction === direction
      : false;

  let sceneLinkWithScene: ScenesLayoutLinkType;
  let sceneLink: ScenesLayoutLinkType;
  let isHighlighted;

  sceneLinkWithScene = findLinkBetweenSceneIds({
    sceneId1: scene.uniqueId,
    sceneId2: neighborId,
    sceneLinks,
  });

  let isInvalidSceneLink = false;

  if (
    sceneLinkWithScene &&
    ((sceneLinkWithScene.scene1.uniqueId === scene.uniqueId &&
      sceneLinkWithScene.scene1.direction === direction) ||
      (sceneLinkWithScene.scene2.uniqueId === scene.uniqueId &&
        sceneLinkWithScene.scene2.direction === direction))
  ) {
    sceneLink = sceneLinkWithScene;
    isHighlighted = editor?.highlightedSceneLinkIds?.includes(
      sceneLinkWithScene.sceneLinkId
    );
  }

  isInvalidSceneLink = !!sceneLink && !sceneWalkPaths[direction];

  if (neighborId && !sceneLink) {
    // cannot transition to a different scenes layout if there already is a neighbor defined, and it is not a scene link
    return null;
  }

  if (isInvalidSceneLink || sceneWalkPaths[direction]) {
    // walk path is unused and can be linked to a different scenes layout
    return !editor ? (
      isInvalidSceneLink || !!sceneLink ? (
        <NeighborHighlight
          id={`area_${props.scene?.uniqueId}_${props.direction}`}
          direction={direction}
          isSceneLink={isInvalidSceneLink || !!sceneLink}
          connectionLineLength={props.connectionLineLength}
          isHighlighted={isHighlighted || isSelected}
          isInvalidSceneLink={isInvalidSceneLink}
          onClick={() => {
            if (onSceneLinkClick) {
              onSceneLinkClick({ neighborId });
            }
          }}
        />
      ) : null
    ) : (
      <NeighborHighlight
        id={`area_${props.scene?.uniqueId}_${props.direction}`}
        direction={direction}
        isSceneLink={
          isInvalidSceneLink ||
          !!sceneLink ||
          (selectedAreas?.length &&
            selectedAreas[0].direction === direction &&
            selectedAreas[0].sceneId === scene.uniqueId)
        }
        onClick={() => {
          if (sceneLink) {
            if (!isHighlighted) {
              // set link as highlighted
              setEditor({
                ...editor,
                highlightedSceneLinkIds: [sceneLink.sceneLinkId],
              });
            } else {
              // set link as not highlighted
              setEditor({
                ...editor,
                highlightedSceneLinkIds: [
                  ...editor.highlightedSceneLinkIds,
                ].filter((id) => id !== sceneLink.sceneLinkId),
              });
            }
          } else {
            setEditor({
              ...editor,
              highlightedSceneLinkIds: [],
            });
            setSelectedAreas([
              ...selectedAreas,
              { sceneId: scene.uniqueId, direction: direction },
            ]);
          }
        }}
        connectionLineLength={props.connectionLineLength}
        isHighlighted={isHighlighted || isSelected}
        isInvalidSceneLink={isInvalidSceneLink}
      />
    );
  }
  return null;
};

export default NeighborArea;
