import styled, { css } from "styled-components";
import { useEffect, useRef, useState } from "react";

import InputSubLabel from "../inputs/_InputSubLabel";
import ModalConfirm from "../ModalWindow/ModalConfirm";
import devToolsConfig from "game-engine/configs/devtools-config";

const Wrapper = styled.button<{
  isActive?: boolean;
  alignLeft?: boolean;
  noBorder?: boolean;
  padding?: string;
  isDisabled?: boolean;
  onClick: any;
  backgroundColor?: string;
  noBackground?: boolean;
}>`
  --padding: ${(props) => props.padding || "9px"};

  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;

  outline: none;
  border: none;
  color: #fffa;
  padding: var(--padding);

  background: ${devToolsConfig.elemBackground};
  border: 1px solid ${devToolsConfig.borderColor};

  &:hover {
    background: ${devToolsConfig.elemBackgroundHover};
  }

  ${(props) =>
    props.noBorder &&
    css`
      border: none;
      padding: calc(var(--padding) + 1px); // to make-up for the border
    `}

  ${(props) =>
    props.backgroundColor &&
    css`
      background: ${props.backgroundColor};
    `}

  ${(props) =>
    props.isActive &&
    css`
      background: ${devToolsConfig.inputBackgroundActive};
    `}

  ${(props) =>
    props.alignLeft &&
    css`
      text-align: left;
      align-items: flex-start;
    `}

  ${(props) =>
    props.isDisabled &&
    css`
      pointer-events: none !important;
      opacity: 0.4;
    `}

${(props) =>
    props.noBackground &&
    css`
      background: none;
    `}
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  font-size: 13px;
`;

const Button = (props: {
  subLabel?: string;
  subLabelIndent?: string;
  clickFeedbackMessage?: string;
  onClick?: () => void;
  isActive?: boolean;
  alignLeft?: boolean;
  noBorder?: boolean;
  padding?: string;
  children?: any;
  style?: Object;
  isDisabled?: boolean;
  backgroundColor?: string;
  noBackground?: boolean;
  confirm?: {
    width?: string;
    height?: string;
    windowTitle: string;
    title?: string;
    description?: string;
  };
}) => {
  const confirmRequired = !!props.confirm;

  //
  // FEEDBACK
  //
  const [clickFeedback, setClickFeedback] = useState<string>();
  const setClickFeedbackRef = useRef(setClickFeedback);

  let copyFeedbackTimer = useRef<any>();
  useEffect(() => {
    if (clickFeedback) {
      clearTimeout(copyFeedbackTimer.current);
      copyFeedbackTimer.current = setTimeout(
        () => setClickFeedbackRef.current(undefined),
        600
      );
    }
    return () => {
      clearTimeout(copyFeedbackTimer.current);
    };
  }, [clickFeedback]);

  //
  // BUTTON ACTION
  //
  const doAction = () => {
    if (props.onClick) {
      if (props.clickFeedbackMessage) {
        setClickFeedback(props.clickFeedbackMessage);
      }
      props.onClick();
    }
  };

  const onClick = (e) => {
    e.stopPropagation(); // prevents boxes from toggling open/close when inside their header

    if (confirmRequired) {
      setIsConfirmOpen(true);
    } else {
      doAction();
    }
  };

  //
  // CONFIRM DIALOG
  //
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);

  const onConfirm = () => {
    doAction();
    setIsConfirmOpen(false);
  };

  //
  // RENDER
  //
  return (
    <>
      <Wrapper
        onClick={onClick}
        isActive={props.isActive}
        alignLeft={props.alignLeft}
        noBorder={props.noBorder}
        padding={props.padding}
        isDisabled={props.isDisabled}
        backgroundColor={props.backgroundColor}
        noBackground={props.noBackground}
        style={props.style}
      >
        {clickFeedback ? (
          clickFeedback
        ) : (
          <>
            <Row>{props.children}</Row>
            {props.subLabel && (
              <InputSubLabel
                label={props.subLabel}
                indent={props.subLabelIndent}
              />
            )}
          </>
        )}
      </Wrapper>

      {confirmRequired ? (
        <ModalConfirm
          isOpen={isConfirmOpen}
          close={() => setIsConfirmOpen(false)}
          onConfirm={onConfirm}
          width={props.confirm.width || "500px"}
          height={props.confirm.height || "300px"}
          windowTitle={props.confirm.windowTitle}
          title={props.confirm.title}
          description={props.confirm.description}
        />
      ) : null}
    </>
  );
};

export default Button;
