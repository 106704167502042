import { useContext, useEffect, useState } from "react";

import { ACTION } from "game-engine/utils";
import Box from "../../../../basic-components/Box";
import Button from "game-engine/_dev/basic-components/Button";
import { CustomPopUpOptionsType } from "game-engine/types";
import { DevToolsContext } from "game-engine";
import Divider from "game-engine/_dev/basic-components/Divider";
import GAME_CONFIG from "game-files/gameConfig";
import NumberInput from "game-engine/_dev/basic-components/inputs/NumberInput";
import TextInput from "game-engine/_dev/basic-components/inputs/TextInput";

const SectionUserInterface = (props: { isOpen?: boolean }) => {
  const devTools = useContext(DevToolsContext);

  const runActions = devTools.state.actions.runActionsRef?.current;

  const [isDisabled, setIsDisabled] = useState(!runActions);
  useEffect(() => {
    setIsDisabled(!runActions);
  }, [runActions]);

  //
  // ACTIONS - CUSTOM POP-UP
  //
  const [customPopUp, setCustomPopUp] = useState<CustomPopUpOptionsType>({
    title: { en: "This is an example of a custom pop-up.", cz: undefined },
    height: 70,
    buttonClose: {
      en: "Close",
      cz: undefined,
    },
  });

  const actionCustomPopUp = () => {
    runActions([ACTION.showPopUp(customPopUp)]);
  };

  //
  // RENDER
  //
  return (
    <Box
      label="User Interface"
      childMinWidth="80%"
      contentPaddingTop="10px"
      contentPaddingBottom="40px"
      isOpen={props.isOpen}
      level={2}
      gap="0px"
    >
      <Divider name="Custom Pop-Up:" marginTop="0px" />

      <Box renderContentOnly childMinWidth="80%" isOpen>
        <TextInput
          prefix="title"
          prefixWidth="74px"
          value={customPopUp.title.en}
          onChange={(v) =>
            setCustomPopUp((prev) => {
              return { ...prev, title: { ...prev.title, en: v } };
            })
          }
        />

        <TextInput
          prefix="buttonClose"
          prefixWidth="74px"
          value={customPopUp.buttonClose.en}
          onChange={(v) =>
            setCustomPopUp((prev) => {
              return { ...prev, buttonClose: { ...prev.buttonClose, en: v } };
            })
          }
        />

        <NumberInput
          prefix="height"
          prefixWidth="74px"
          value={customPopUp.height}
          onChange={(v) =>
            setCustomPopUp((prev) => {
              return { ...prev, height: v };
            })
          }
          rangeController={{
            min: 0,
            max: GAME_CONFIG.window.canvasDimensions.y,
            step: 5,
          }}
        />

        <Button isDisabled={isDisabled} onClick={actionCustomPopUp}>
          {"Show Pop-Up"}
        </Button>
      </Box>
    </Box>
  );
};

export default SectionUserInterface;
