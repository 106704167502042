import styled from 'styled-components'

const Wrapper = styled.div`
  align-self: flex-end;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
`

const Col = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  gap: 2px;

  font-size: 11px;
  opacity: 0.3;
`

const Name = styled.div`
  font-size: 32px;
  opacity: 0.5;
`

const Credits = () => {
  return (
    <Wrapper>
      <Col>
        <div>Pixel Art Game Engine</div>
        <div>created by jan-hamernik.com</div>
      </Col>
      <Name>Pix</Name>
    </Wrapper>
  )
}

export default Credits
