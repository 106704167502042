import ANIMATION_CONFIGS from "game-files/animations/ANIMATION_CONFIGS";
import AnimationItem from "./AnimationItem";
import Box from "game-engine/_dev/basic-components/Box";

const AssetsAnimations = (props: { isOpen?: boolean }) => {
  //
  // RENDER
  //
  return (
    <Box
      label="Animations"
      isOpen={props.isOpen}
      level={1}
      childMinWidth="90%"
      gap="10px"
    >
      {Object.values(ANIMATION_CONFIGS).map((animationConfig) => (
        <AnimationItem
          key={animationConfig.id}
          animationConfig={animationConfig}
        />
      ))}
    </Box>
  );
};

export default AssetsAnimations;
