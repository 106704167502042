import { CinematicId, CinematicSequenceId } from "game-files/common/ids";

import { CinematicSequenceType } from "game-engine/types";

const CINEMATIC_SEQUENCES: {
  [id in CinematicSequenceId]: CinematicSequenceType;
} = {
  /* NEW-GAME CINEMATIC SEQUENCE */
  [CinematicSequenceId.NewGame]: {
    cinematicIds: [
      CinematicId.TestCinematicScreen04,
      CinematicId.TestCinematicScene02,
    ],
    pauseBetween: {
      durationSec: 0,
    },
  },
};

export default CINEMATIC_SEQUENCES;

export const getCinematicSequenceById = (
  cinematicSequenceId: CinematicSequenceId
) => {
  return CINEMATIC_SEQUENCES[cinematicSequenceId];
};
