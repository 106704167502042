import { PixelMatrixType } from "game-engine/utils";
import { Position } from ".";

export type CursorGlyphType = {
  pixels: PixelMatrixType;
  color?: string;
  outlined?: any;
  outlineNoDiagonal?: boolean;
  outlineColor?: string;
  offsetY?: number;
  offsetX?: number;
};

export enum CursorRenderType {
  default = "default",
  arrowLeft = "arrowLeft",
  arrowRight = "arrowRight",
  arrowUp = "arrowUp",
  arrowDown = "arrowDown",
  notAllowed = "notAllowed",
}

export type CursorGlyphsType = {
  [key in CursorRenderType]: CursorGlyphType;
};

export type CursorConfigType = {
  renderMode: CursorRenderType;
  isHidden: boolean;
  itemDropAnimation?: ItemDropAnimationType;
};

export type ItemDropAnimationType = {
  itemId: string;
  from: Position;
};
