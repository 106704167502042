import {
  CharacterConfigType,
  CharacterDevToolsPreviewType,
} from "game-engine/types";
import { CharacterId, ItemConfigId } from "game-files/common/ids";
import RenderDefault, { AnimationId_Default } from "./render-default/_render";
import RenderSick, { AnimationId_Sick } from "./render-sick/_render";

import { ACTION } from "game-engine/utils";
import { GameObjective } from "game-files/gameObjectives";

const THIS_ID = CharacterId.TestCharacter;

export const TestCharacterAnimationId = {
  ...AnimationId_Default,
  ...AnimationId_Sick,
};

const TestCharacterConfig: CharacterConfigType = {
  id: THIS_ID,
  name: { en: "TEST CHARACTER", cz: "TEST CHARACTER" },

  dialogConfig: {
    [GameObjective.GAME_START]: {
      color: `#94FFB0`,
    },
    [GameObjective.obj_03_get_floating_fire]: {
      color: `#ffffff`,
    },
  },

  idleConfig: {
    idleAfterMinSec: 6,
    idleAfterMaxSec: 12,
  },

  render: {
    [GameObjective.GAME_START]: {
      ...RenderSick,

      // EXAMPLE OF CONDITION-BASED RENDER
      conditional: ({ events }) => [
        {
          condition: events.test_testCharacterHealthy,
          state: RenderDefault,
        },
      ],
    },
    // EXAMPLE OF OBJECTIVE-BASED RENDER
    [GameObjective.obj_03_get_floating_fire]: RenderDefault,
  },

  onSceneIdle: {
    actionsByCurrentObjective: {
      [GameObjective.GAME_START]: {
        actions: [
          ACTION.talk({
            characterId: THIS_ID,
            dialog: [
              {
                en: `I'm feeling kinda sick today...`,
                cz: "Není mi dnes moc dobře...",
              },
            ],
          }),

          ACTION.talk({
            characterId: THIS_ID,
            dialog: [
              { en: `I think I'm going to puke.`, cz: "Myslím že se pobleju." },
            ],
          }),
        ],
      },
    },
  },

  onClick: {
    actionsByCurrentObjective: {
      [GameObjective.GAME_START]: {
        actions: [
          ACTION.random([
            ACTION.talkRandom({
              dialogOptions: [
                {
                  en: `That guy doesn't look very healthy.`,
                  cz: "Nevypadá zrovna zdravě.",
                },
                {
                  en: `Maybe I should keep my distance, I don't want to get sick.`,
                  cz: "Radši se nebudu moc přibližovat, nechci ještě něco chytnout.",
                },
                [
                  {
                    en: "Should I ask him for directions?",
                    cz: "Že bych se ho zeptal na cestu?",
                  },
                  {
                    en: `On second though, I'd rather not.`,
                    cz: "Když o tom tak přemýšlím, radši ne.",
                  },
                ],
              ],
            }),

            ACTION.conversation([
              {
                dialog: [
                  {
                    en: `Hello there!`,
                    cz: "Nazdar!",
                  },
                ],
              },
              {
                characterId: THIS_ID,
                dialog: [
                  {
                    en: `I'm in no mood to entertain you at the moment.`,
                    cz: "Nemám náladu se teď s někým bavit",
                  },
                ],
              },
              {
                dialog: [
                  {
                    en: `Alright then...`,
                    cz: "Jak myslíš...",
                  },
                ],
              },
            ]),

            ACTION.sequence([
              ACTION.random([
                ACTION.characterAnimation({
                  characterId: THIS_ID,
                  animationId: "shrug",
                }),
                ACTION.talk({
                  characterId: THIS_ID,
                  dialog: [
                    {
                      en: `This is an alternative to me shrugging.`,
                      cz: "Tohle je alternativa ke krčení ramen.",
                    },
                  ],
                }),
              ]),

              ACTION.talk({
                characterId: THIS_ID,
                dialog: [
                  {
                    en: `I really don't know what you're expecting me to do.`,
                    cz: "Nemám potuchy co si představuješ že budu dělat.",
                  },
                ],
              }),
            ]),

            ACTION.sequence([
              ACTION.talk({
                characterId: THIS_ID,
                dialog: [
                  {
                    en: `Here, take this flask.`,
                    cz: "Tady máš láhev.",
                  },
                ],
              }),
              ACTION.putNewItemIntoCursor({
                newItemConfigId: ItemConfigId.Test_Flask,
              }),
              ACTION.talk({
                dialog: [
                  {
                    en: `Wow, thank you kind green man!`,
                    cz: "Wow, děkuji hodný zelený pane!",
                  },
                ],
              }),
            ]),
          ]),
        ],
      },
    },
  },
};

export const TestCharacterDevtoolsPreview: CharacterDevToolsPreviewType = {
  config: TestCharacterConfig,
  renderAssets: [
    {
      name: "Default",
      render: RenderDefault,
      animationIds: AnimationId_Default,
    },
    {
      name: "Sick",
      render: RenderSick,
      animationIds: AnimationId_Sick,
    },
  ],
};

export default TestCharacterConfig;
