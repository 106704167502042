import { CursorConfigType, CursorRenderType, GamePlayType } from "./types";
import { EngineConfigType, engineConfig_Player } from "./configs/engine-config";
import { createContext, useState } from "react";
import { getNewGameItems, getNewGamePlay } from "./utils";

import { DevToolsType } from "./types/devTools";
import { GameItemsType } from "game-engine/types";
import Tabs from "./tabs";

export const EngineConfigContext = createContext<{
  state: EngineConfigType;
  setState: (
    s: EngineConfigType | ((prevState: EngineConfigType) => EngineConfigType)
  ) => void;
}>(null);
export const GamePlayContext = createContext<{
  state: GamePlayType;
  setState: (
    s: GamePlayType | ((prevState: GamePlayType) => GamePlayType)
  ) => void;
}>(null);
export const GameItemsContext = createContext<{
  state: GameItemsType;
  setState: (
    s: GameItemsType | ((prevState: GameItemsType) => GameItemsType)
  ) => void;
}>(null);
export const CursorContext = createContext<{
  state: CursorConfigType;
  setState: (
    s: CursorConfigType | ((prevState: CursorConfigType) => CursorConfigType)
  ) => void;
}>(null);
export const DevToolsContext = createContext<{
  state: DevToolsType;
  setState: (
    s: DevToolsType | ((prevState: DevToolsType) => DevToolsType)
  ) => void;
}>(null);

//
// GAME ENGINE
//
const GameEngine = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const activeTab = urlParams.get("tab");

  const [engineConfig, setEngineConfig] =
    useState<EngineConfigType>(engineConfig_Player);

  const [gameItems, setGameItems] = useState<GameItemsType>(getNewGameItems());

  const [gamePlay, setGamePlay] = useState<GamePlayType>(
    getNewGamePlay({
      currentSettings: undefined,
    })
  );

  const [cursor, setCursor] = useState({
    renderMode: CursorRenderType.default,
    isHidden: false,
  });

  const [devTools, setDevTools] = useState<DevToolsType>({
    isDeveloper: !!activeTab,
    actions: {
      runActionsRef: undefined,
      stopActionsRef: undefined,
      actionQueue: [],
      actionOrEventHistory: [],
    },
  });

  //
  // RENDER
  //
  return (
    <EngineConfigContext.Provider
      value={{
        state: { ...engineConfig },
        setState: setEngineConfig,
      }}
    >
      <GamePlayContext.Provider
        value={{ state: { ...gamePlay }, setState: setGamePlay }}
      >
        <GameItemsContext.Provider
          value={{
            state: { ...gameItems },
            setState: setGameItems,
          }}
        >
          <CursorContext.Provider
            value={{ state: cursor, setState: setCursor }}
          >
            <DevToolsContext.Provider
              value={{ state: devTools, setState: setDevTools }}
            >
              <Tabs activeTab={activeTab} />
            </DevToolsContext.Provider>
          </CursorContext.Provider>
        </GameItemsContext.Provider>
      </GamePlayContext.Provider>
    </EngineConfigContext.Provider>
  );
};

export default GameEngine;
