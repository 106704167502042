import {
  CinematicSceneType,
  CinematicType,
  CinematicTypeEnum,
} from "game-engine/types";

import CINEMATIC_CONFIGS from "game-files/cinematics/CINEMATIC_CONFIGS";
import GAME_CONFIG from "game-files/gameConfig";
import devToolsConfig from "game-engine/configs/devtools-config";
import styled from "styled-components";

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
`;

const CinematicList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, 250px);
  gap: 10px;
  align-items: center;
  justify-content: center;
  padding: 10%;
`;

const CinematicThumbnail = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const CinematicOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${devToolsConfig.background}AA;

  transition: all 0.2s ease;
`;
const CinematicName = styled.div`
  font-size: 12px;
  font-weight: 600;
  color: #fffd;

  transition: all 0.2s ease;
`;

const CinematicItem = styled.div`
  position: relative;
  cursor: pointer;
  aspect-ratio: ${GAME_CONFIG.scene.dimensions.x} /
    ${GAME_CONFIG.scene.dimensions.y};

  background: #fff2;

  &:hover {
    ${CinematicOverlay} {
      background: ${devToolsConfig.background}00;
    }
    ${CinematicName} {
      margin-top: 35%;
    }
  }
`;

//
// COMPONENT
//
const CinematicSelection = (props: {
  onEditCinematic: (cinematicId: string) => void;
}) => {
  const { onEditCinematic } = props;

  const renderThumbnail = (cinematic: CinematicType) => {
    switch (cinematic?.type) {
      case CinematicTypeEnum.scene:
        return (
          <CinematicThumbnail
            src={(cinematic as CinematicSceneType)?.scene?.preview?.src}
          />
        );

      case CinematicTypeEnum.screen:
        return <div />;

      default:
        return null;
    }
  };

  //
  // RENDER
  //
  return (
    <Wrapper>
      <CinematicList>
        {Object.values(CINEMATIC_CONFIGS).map((cinematic) => {
          return (
            <CinematicItem
              key={cinematic.id}
              onClick={() => onEditCinematic(cinematic.id)}
            >
              {renderThumbnail(cinematic)}

              <CinematicOverlay>
                <CinematicName>{cinematic.id}</CinematicName>
              </CinematicOverlay>
            </CinematicItem>
          );
        })}
      </CinematicList>
    </Wrapper>
  );
};

export default CinematicSelection;
